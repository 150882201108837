import fetchJsonp from 'fetch-jsonp';
import {
  ADD_INSTAGRAM_PHOTOS,
  SET_INSTAGRAM_ACCESS_TOKEN,
  SET_INSTAGRAM_ACTIVE,
  SET_INSTAGRAM_MAIN_ALBUM,
  SET_INSTAGRAM_PLACEHOLDERS,
  SET_INSTAGRAM_NEXT_URL,
  INSTAGRAM_REQUESTING,
} from './constants';
import { hideUploadPhotosModal, showInstagramAlbum } from '../ui/actions';
import { UI_INSTAGRAM_ALBUM } from '../ui/constants';
import { selectedSource } from '../userPhotos/actions';
import { buildRequestParams } from '../../services/galleryApi';

const addInstagramPhotos = (newImages) => ({
  type: ADD_INSTAGRAM_PHOTOS,
  payload: {
    newImages,
  },
});

const setIGPlaceholders = (instagramObjects) => ({
  type: SET_INSTAGRAM_PLACEHOLDERS, payload: instagramObjects,
});

const setAccessToken = (accessToken) => ({
  type: SET_INSTAGRAM_ACCESS_TOKEN,
  payload: accessToken,
});

const igGraphBaseUrl = 'https://graph.instagram.com';
const igFields = ['id', 'media_type', 'media_url', 'timestamp'];
// we fetch 7 stories(10 photos max per story) with a maximum of 70 photos and a minimum of 0 
const igLimit = 7;

export const setInstagramActive = (value) => ({ type: SET_INSTAGRAM_ACTIVE, payload: value });

const setMainInstagramAlbum = (profilePic) => (dispatch) => {
  const igAlbum = { albumId: UI_INSTAGRAM_ALBUM, thumbURL: profilePic };
  dispatch({ type: SET_INSTAGRAM_MAIN_ALBUM, payload: igAlbum });
};

const fetchIgChildIds = async (carouselId, accessToken) => {
  const igChildEdgeUrl = `${igGraphBaseUrl}/${carouselId}/children?access_token=${accessToken}`;
  const response = await fetch(igChildEdgeUrl);
  const data = await response.json();
  return data;
}

const fetchIgChildData = async (childId, accessToken) => {
  const childUrl = `${igGraphBaseUrl}/${childId}?fields=${String(igFields)}&access_token=${accessToken}`;
  const response = await fetch(childUrl);
  const data = await response.json();
  return data;
}

const expandCarouselAlbums = async (igImages, accessToken) => {
  const expandedCarouselAlbums = await Promise.all(igImages.map(async (igImage) => {
    if (igImage.media_type === 'CAROUSEL_ALBUM') {
      const childIds = await fetchIgChildIds(igImage.id, accessToken);
      return Promise.all(childIds.data.map(async (child) => {
        const childImage = await fetchIgChildData(child.id, accessToken);
        return childImage;
      }));
    }
    return igImage;
  }));

  return expandedCarouselAlbums.reduce((flattenedArray, currentImage) => flattenedArray.concat(currentImage), []);
}

const buildInstagramAlbum = (igImages) => async (dispatch, getState) => {
  const state = getState();
  const accessToken = state.instagramPhotos.instagramAccessToken;

  const scrubIgVideos = igImages.filter((image) => image.media_type === 'IMAGE' || image.media_type === 'CAROUSEL_ALBUM');
  const expandedAlbums = await expandCarouselAlbums(scrubIgVideos, accessToken);

  const formattedIGphotos = expandedAlbums.map((image) => ({
    source: 'instagram',
    isLoaded: true,
    id: image.id,
    cdnUrl: image.media_url,
    thumbURL: image.media_url,
  }));

  dispatch(addInstagramPhotos(formattedIGphotos));
  if (!getState().instagramPhotos.instagramMainAlbum) {
    dispatch(setMainInstagramAlbum(formattedIGphotos[0].cdnUrl));
  }
};

const setInstagramNextUrl = (nextUrl) => ({
  type: SET_INSTAGRAM_NEXT_URL, payload: nextUrl,
});

const setInstagramRequesting = (value) => ({
  type: INSTAGRAM_REQUESTING, payload: value,
});

export const paginateInstagram = () => async (dispatch, getState) => {
  const { images, nextUrl } = getState().instagramPhotos;
  if (nextUrl) {
    dispatch(setInstagramRequesting(true));

    await fetchJsonp(nextUrl)
      .then((response) => response.json())
      .then((json) => {
        const next = json?.paging?.next;
        dispatch(setInstagramNextUrl(next));
        dispatch(buildInstagramAlbum(json.data));
      })
      .catch((err) => {
        console.log('paginate Instagram failed', err);
        dispatch(setInstagramRequesting(false));
      });
  }
};

export const getInstagramPhotos = (accessToken) => async (dispatch) => {

  const igUrl = `${igGraphBaseUrl}/me/media?fields=${String(igFields)}&access_token=${accessToken}&limit=${igLimit}`;

  fetch(igUrl)
    .then((response) => response.json())
    .then((json) => {
      const nextUrl = json?.paging?.next || '';
      if (nextUrl) {
        dispatch(setInstagramNextUrl(nextUrl));
      }
      dispatch(buildInstagramAlbum(json.data));
      dispatch(showInstagramAlbum());
      dispatch(hideUploadPhotosModal());
    })
    .catch((err) => {
      console.log('Failed to get Instagram photos', err);
    });
};

const exchangeIgTempCodeForLongLivedToken = async (tempAuthCode, flashId, flashToken) => {
  const url = 'https://api.instagram.com/oauth/access_token';
  const redirectUri = `${process.env.REACT_APP_PUBLIC_URL}/`;

  try {
    const igLongLivedAccessToken = await requestLongLivedToken(flashId, flashToken, tempAuthCode);
    return igLongLivedAccessToken;
  } catch (error) {
    console.error('Error exchanging temporary auth token: '+ error);
  }

};

const requestLongLivedToken = async (flashId: String, flashToken: String, tempAuthCode: String) => {
    try {
      const igLongLivedTokenUrl = `${process.env.REACT_APP_CRUD_CORE_BASE_URL}/api/user/third-party-auth/instagram/access-token?flashId=${flashId}&&temporaryToken=${tempAuthCode}`;
      const params = buildRequestParams(flashToken, 'GET');
      const igLongLivedAccessToken = await fetch(igLongLivedTokenUrl, params);

      if(igLongLivedAccessToken){
        const parsedToken = await igLongLivedAccessToken.text();
        return parsedToken;
      }
    } catch (e){
      console.log('Error requesting long lived token to crud-core: '+ e)
    }
};



const igAuthFlow = (flashId, flashToken, dispatch) => {
  dispatch(selectedSource('instagram')); // dispatched for analytics :()
  const instagramOauthBase = 'https://api.instagram.com/oauth/authorize?client_id=';
  const redirectUri = `${process.env.REACT_APP_PUBLIC_URL}/`;

  const url = `${instagramOauthBase}${process.env.REACT_APP_INSTAGRAM_APP_ID}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code`;

  const igWindow = window.open(url, 'igWindow', 'width=700,height=500');

  // The only reliable way to exchange message with popup on a different domain, see:
  // http://stackoverflow.com/questions/18625733

  if (igWindow !== null) {

    const stillLogginIn = setInterval(async () => {
      if (igWindow.closed) {
        // user closed popup
        clearInterval(stillLogginIn);
      } else {
     
        try {
          if (igWindow.window.location.href.includes('code') && igWindow.document.readyState === 'complete') {

            clearInterval(stillLogginIn);
            // check if auth token is present in window hash. This will throw but we silently ignore it
            const queryString = igWindow.window.location.search;
            const urlParams = new URLSearchParams(queryString);

            const tempAuthCode = urlParams.get('code');

            igWindow.window.close();

            const accessToken = await exchangeIgTempCodeForLongLivedToken(tempAuthCode, flashId, flashToken);
            if(!accessToken){
              throw new Error('Error exchanging temporary code for long lived token: '+ accessToken);
            }

            dispatch(setAccessToken(accessToken));

            await dispatch(getInstagramPhotos(accessToken));
          }
        } catch (e) {
          console.log('Window not yet closed '+e)
          // ignore
        }
      }
    }, 250);
  }
}

export const requestInstagramAccessToken = () => async (dispatch, getState) => {
  const state = getState();
  const flashId = state.user.flashId;
  const flashToken = state.user.flashToken;
  const getIgTokenUrl = `${process.env.REACT_APP_CRUD_CORE_BASE_URL}/api/user/third-party-auth/instagram/access-token?flashId=${flashId}`;
  try {
    const params = buildRequestParams(flashToken, 'GET');
    const checkIgAccessToken = await fetch(getIgTokenUrl, params)
      .then((res) => res.json())
      .then((data) => data);
    if (checkIgAccessToken.access_token) {
      dispatch(setAccessToken(checkIgAccessToken.access_token));
      await dispatch(getInstagramPhotos(checkIgAccessToken.access_token));
    } else {

      igAuthFlow(flashId, flashToken, dispatch);
    }
  } catch (e) {
    console.log('There has been a problem checking for the IG Auth Token', e);
  }
};

