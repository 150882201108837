// @flow

import {
  MAGENTO_LOGIN_ENDPOINT,
  MAGENTO_USER_ME_ENDPOINT,
  MAGENTO_USER_PASSWORD_RESET_ENDPOINT,
  MAGENTO_USER_CART_ENDPOINT,
  MAGENTO_USER_REGISTER_ENDPOINT,
  MAGENTO_COUNTRIES_ENDPOINT,
  MAGENTO_PRODUCTS_ENDPOINT
} from '../constants/magento';

import { FETCH_INVALID_RESPONSE_ERROR } from '../constants/errors';
import { attributesToArray, qtyFromAttributesArray } from '../helpers/product';
import { fromNullable } from 'fp-ts/lib/Option';
import { prop } from '../helpers/functions';
import { type MagentoRegion } from '../types/address';
import { type PriceTier } from '../types/product';
import has from '../helpers/has';
import { getAUProductsUrl } from '../helpers/urls';

const OUT_OF_STOCK_MESSAGES = [
  'This product is out of stock.',
  'The product option is out of stock',
  'Product that you are trying to add is not available.',
];

export const isProductOptionOOSMessage = (str: string) => str.includes('The product option is out of stock');

export const magentoResponseIsOOS = responseJson => has(responseJson)('message')
  && OUT_OF_STOCK_MESSAGES.reduce((acc, curr) => (
    responseJson.message.includes(curr) || acc
  ), false);

export class MagentoRequestError extends Error {
  constructor(...args) {
    super(...args);

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, MagentoRequestError);
    } else {
      Error().stack = MagentoRequestError;
    }
  }
}

export const ERROR_NAME_OOS = 'OOSERROR';
export class OOSError extends MagentoRequestError {
  constructor(...args) {
    super(...args);
    this.name = ERROR_NAME_OOS;
  }
}

const getMagentoFetchOptions = inject => ({
  credentials: 'include',
  method: 'GET',
  mode: 'cors',
  headers: {
    'content-type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  ...(inject || {}),
});

export function requestMagentoLogin(email, password) {
  return fetch(MAGENTO_LOGIN_ENDPOINT, getMagentoFetchOptions({
    method: 'POST',
    body: JSON.stringify({
      username: email,
      password,
      context: 'checkout',
    }),
  })).then(response =>
    response.json().then(json => ({ json, response })).catch(() => {
      throw new MagentoRequestError(FETCH_INVALID_RESPONSE_ERROR);
    }),
  ).then(({ response, json }) => {
    if (response.status >= 200 && response.status < 300) {
      if (json.errors === true) {
        throw new MagentoRequestError();
      }
      return json;
    }
    throw new MagentoRequestError();
  });
}

export function requestMagentoCustomer() {
  return fetch(MAGENTO_USER_ME_ENDPOINT, getMagentoFetchOptions()).then((response) =>
    response.json().then((json) => ({ json, response })).catch(() => {
      throw new MagentoRequestError(FETCH_INVALID_RESPONSE_ERROR);
    }),
  ).then(({ response, json }) => {
    if (response.status >= 200 && response.status < 300) {
      if (json.errors === true) {
        throw new MagentoRequestError();
      }
      return json;
    }
    throw new MagentoRequestError();
  });
}

export const requestCountries = () =>
  fetch(MAGENTO_COUNTRIES_ENDPOINT, getMagentoFetchOptions()).then(response =>
    response.json().then(json => ({ json, response })).catch(() => {
      throw new MagentoRequestError(FETCH_INVALID_RESPONSE_ERROR);
    }),
  ).then(({ response, json }) => {
    if (response.status >= 200 && response.status < 300) {
      if (json.errors === true) {
        throw new MagentoRequestError();
      }
      return json;
    }
    throw new MagentoRequestError();
  });

export const requestRegions = (countryId: string): Promise<MagentoRegion> =>
  fetch(`${MAGENTO_COUNTRIES_ENDPOINT}/${countryId}`, getMagentoFetchOptions()).then(response =>
    response.json().then(json => ({ json, response })).catch(() => {
      throw new MagentoRequestError(FETCH_INVALID_RESPONSE_ERROR);
    }),
  ).then(({ response, json }) => {
    if (response.status >= 200 && response.status < 300) {
      if (json.errors === true) {
        throw new MagentoRequestError();
      }
      return json;
    }
    throw new MagentoRequestError();
  });

export const requestAddUserAddress = ({ email, password, addresses, storeId }, _address, isUpdate = false) => {
  const address = _address;

  // magento will be mad if we don't remove this
  delete address.regionText;
  if (!isUpdate) {
    delete address.id;
  }
  return fetch(MAGENTO_USER_ME_ENDPOINT, getMagentoFetchOptions({
    method: 'PUT',
    body: JSON.stringify({
      customer: {
        email,
        firstname: address.firstname,
        lastname: address.lastname,
        websiteId: storeId,
        addresses: [
          ...addresses
            .filter(x => x.id !== address.region_id)
            .filter(x => x.id !== address.id),
          address,
        ],
      },
      password,
      redirectUrl: false,
    }),
  }))
    .then(response =>
      response.json().then(json => ({ json, response })).catch(() => {
        throw new MagentoRequestError(FETCH_INVALID_RESPONSE_ERROR);
      }),
    ).then(({ response, json }) => {
      if (response.status >= 200 && response.status < 300) {
        if (json.errors === true) {
          throw new MagentoRequestError();
        }
        return json;
      }
      throw new MagentoRequestError();
    });
};

export const requestMagentoPriceTiers = (sku: string): Promise<Array<PriceTier>> =>
  fetch(`${MAGENTO_PRODUCTS_ENDPOINT}/${sku}/price-tiers`, getMagentoFetchOptions())
    .then(r => r.json())
    .then(tiers => (
      (Array.isArray(tiers) && tiers.length > 0 ? tiers : []).map(tier => (
        {
          ...tier,
          value: tier.quantity,
        }
      ))
    ))
    .catch((e) => {
      window.newrelic.noticeError(e);

      return [];
    });

export function requestPasswordReset(email) {
  return fetch(MAGENTO_USER_PASSWORD_RESET_ENDPOINT, getMagentoFetchOptions({
    method: 'PUT',
    body: JSON.stringify({
      email,
      template: 'email_reset',
    }),
  })).then(response =>
    response.json().then(json => ({ json, response })).catch(() => {
      throw new MagentoRequestError(FETCH_INVALID_RESPONSE_ERROR);
    }),
  ).then(({ response, json }) => {
    if (response.status >= 200 && response.status < 300) {
      if (json.errors === true) {
        throw new MagentoRequestError();
      }
      return json;
    }
    throw new MagentoRequestError();
  });
}


export function requestMagentoRegister(userData) {
  const { email, firstName: firstname, lastName: lastname, password } = userData;
  return fetch(MAGENTO_USER_REGISTER_ENDPOINT, getMagentoFetchOptions({
    method: 'POST',
    body: JSON.stringify({
      customer: {
        email,
        firstname,
        lastname,
      },
      password,
      redirectUrl: false,
    }),
  })).then(response =>
    response.json().then(json => ({ json, response })).catch(() => {
      throw new MagentoRequestError(FETCH_INVALID_RESPONSE_ERROR);
    }),
  ).then(({ response, json }) => {
    if (response.status >= 200 && response.status < 300) {
      if (json.errors === true) {
        throw new MagentoRequestError();
      }
      return json;
    }

    const err = fromNullable(json)
      .chain(prop('message'))
      .getOrElseValue(null);

    throw new MagentoRequestError(err);
  });
}

export function requestMagentoCart() {
  return fetch(MAGENTO_USER_CART_ENDPOINT, getMagentoFetchOptions()).then(response =>
    response.json().then(json => ({ json, response })).catch(() => {
      throw new MagentoRequestError(FETCH_INVALID_RESPONSE_ERROR);
    }),
  ).then(({ response, json }) => {
    if (response.status >= 200 && response.status < 300) {
      if (json.errors === true) {
        throw new MagentoRequestError('Error requesting cart', { cause: response });
      }
      return json;
    }
    throw new MagentoRequestError('Error requesting cart', { cause: response });
  });
}

export function requestMagentoChildProduct(product, pageCountMinusCover) {
  const attributes = attributesToArray(product.attributes, pageCountMinusCover, product.category);
  const encodedOptions = encodeURI(JSON.stringify(attributes));
  const qty = product.qty || qtyFromAttributesArray(attributes);

  return fetch(`${getAUProductsUrl()}/${product.sku}/child?qty=${qty}&options=${encodedOptions}`,
    getMagentoFetchOptions()).then(response =>
      response.json().then(json => ({ json, response })).catch(() => {
        throw new MagentoRequestError(FETCH_INVALID_RESPONSE_ERROR);
      }),
  ).then(({ response, json }) => {
    if (response.status >= 200 && response.status < 300) {
      if (json.errors === true) {
        throw new MagentoRequestError();
      }
      return json;
    }
    throw new MagentoRequestError();
  });
}

export const convertChildProductResponse = productJson => ({
  childSku: productJson.sku,
  magento: {
    attributes: productJson.configurable_attributes,
    options: productJson.options,
    productCustomAttributes: productJson.product_attributes,
    price: `$${productJson.base_price.toFixed(2)}`,
  },
  reportingProductProperties: {
    sku: productJson.sku,
    name: productJson.product_name,
    basePrice: productJson.base_price,
    productId: productJson.product_id,
    reportingProductCategory: productJson.reporting_product_category,
    reportingProductType: productJson.reporting_product_type,
    reportingProductLine: productJson.reporting_product_line,
    reportingProductSku: productJson.reporting_product_sku,
  },
});

export function requestQuoteItemUpdate(
  itemId,
  quoteId,
  projectId,
  attributes,
  options = false,
  productOptions = [],
  pageCountMinusCover,
  quantity,
  sku
) {
  const body = {
    cartItem: {
      item_id: itemId,
      qty: quantity,
      quote_id: quoteId,
      sku,
      extension_attributes: {
        au_project_id: projectId,
        au_project_version: '1',
        ...(pageCountMinusCover ? { page_count: pageCountMinusCover } : {}),
      },
    },
  };

  if (options !== null) {
    body.cartItem.product_option = {
      extension_attributes: {
        readable: attributesToArray(
          attributes,
          pageCountMinusCover,
          undefined,
          'name'
        ),
      },
    };
  }

  return fetch(`${MAGENTO_USER_CART_ENDPOINT}/${itemId}`, getMagentoFetchOptions({
    method: 'PUT',
    body: JSON.stringify(body),
  })).then(response =>
    response.json().then(json => ({ json, response })).catch(() => {
      throw new MagentoRequestError(FETCH_INVALID_RESPONSE_ERROR);
    }),
  ).then(({ response, json }) => {
    if (magentoResponseIsOOS(json)) {
      throw new OOSError(json.message);
    }
    if (response.status >= 200 && response.status < 300) {
      if (json.errors === true) {
        throw new MagentoRequestError();
      }
      return json;
    }
    throw new MagentoRequestError();
  });
}
