export const USER_SET_FLASH_ID = 'USER/SET/FLASHID';
export const USER_SET_FLASH_TOKEN = 'USER/SET/FLASHTOKEN';
export const MAGENTO_USER_ME_REQUEST = 'MAGENTO_USER_ME_REQUEST';
export const MAGENTO_USER_ME_SUCCESS = 'MAGENTO_USER_ME_SUCCESS';
export const MAGENTO_USER_ME_FAILURE = 'MAGENTO_USER_ME_FAILURE';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const MAGENTO_ADMIN_ME_REQUEST = 'MAGENTO_ADMIN_ME_REQUEST';
export const MAGENTO_ADMIN_ME_SUCCESS = 'MAGENTO_ADMIN_ME_SUCCESS';
export const MAGENTO_ADMIN_ME_FAILURE = 'MAGENTO_ADMIN_ME_FAILURE';
export const ADD_USER_ADDRESS = 'ADD_USER_ADDRESS';
export const EDIT_USER_ADDRESS = 'EDIT_USER_ADDRESS';

