// @flow
import modalReducer from './modal/reducer';
import {
  type ModalState,
  initState as initialModalState,
  UI_MODAL_SET_STATUS,
  UI_MODAL_CLOSE,
} from './modal/constants';

import {
  UI_SET_SORT_BY,
  UI_SET_CURRENT_PAGE,
  UI_SET_TOOL_VISIBILITY,
  UI_UNSET_TOOL_VISIBILITY,
  UI_SET_CROP_REGION_VISIBILITY,
  UI_UNSET_CROP_REGION_VISIBILITY,
  UI_TOGGLE_KABOB_MENU_ON,
  UI_TOGGLE_KABOB_MENU_OFF,
  UI_SET_PREVIEW_VISIBILITY,
  UI_UNSET_PREVIEW_VISIBILITY,
  UI_SET_PROMPT_USER_TO_LOGIN_MODAL,
  UI_SET_CURRENT_ALBUM,
  UI_UNSET_CURRENT_ALBUM,
  UI_SET_UPLOAD_PHOTOS_VISIBILITY,
  UI_UNSET_UPLOAD_PHOTOS_VISIBILITY,
  UI_SET_SIDEBAR_EXPANDED,
  UI_SET_SIDEBAR_LOCKED,
  UI_SET_TEXT_EDITOR_VISIBILITY,
  UI_UNSET_TEXT_EDITOR_VISIBILITY,
  UI_CONFIRMATION_SET_PAGE_REMOVAL,
  UI_CONFIRMATION_SET_CROP_RESET,
  UI_CONFIRMATION_UNSET,
  UI_SHOW_DROP_TARGETS,
  UI_SHOW_DROP_TARGETS_HIGHLIGHT,
  UI_RESET_CURRENT_GALLERY_VALUES,
  UI_MODAL_PHOTO_EDITOR,
  UI_FONTS_LOADED,
  UI_SET_DRAWER_VISIBILITY,
  UI_SET_FULL_GALLERY_VIEW,
  UI_UNSET_FULL_GALLERY_VIEW,
  UI_SET_TOOLTIP_POSITION,
  UI_SET_TOOLTIP_DATA,
  UI_SET_TOOLTIP_TYPE,
  UI_SET_TOOLTIP_STATE,
  UI_OPEN_TOOLTIP_WITH_DATA,
  UI_SET_MANAGE_PAGES_VISIBILITY,
  UI_SET_DRAWERGRID_SCROLL_CACHE,
  UI_SET_PARTIAL_SELECTION,
  UI_SET_SURFACE_CENTER_POINT,
  UI_SET_PREVIOUS_PAGE,
  UI_SET_SELECTED_LAYER,
  UI_SET_AUTOFILL_IMG_COUNT,
  UI_SET_SCREEN_SIZE,
  UI_DRAWER_MODE_DEFAULT,
  UI_SET_DRAWER_MODE,
  UI_SET_ZENDESK_LOADED,
  UI_CONFIRMATION_SET_USER_PAGE_REMOVAL,
  UI_TOGGLE_SKIP_CONFIRM_FOR_STATUS,
  UI_TOGGLE_HIDE_USED_IMAGES,
  UI_SET_USER_INPUT_DATA,
  UI_OPEN_SIDEBAR_TUT,
  UI_CLOSE_SIDEBAR_TUT,
  SET_APPLY_TO_ALL_TOGGLE,
  SET_LOADING_APPLY_TO_ALL,
  UI_LAYOUT_DROPDOWN,
  UI_SET_GALLERY_PAGE_NUMBER,
  UI_LOCAL_GALLERY_HAS_MORE_PHOTOS,
  UI_SET_UPDATE_PROJECT_TOOLTIP, UI_INC_AUTOFILL_IMG_COUNT
} from './constants';

import {
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_PROCESSING,
} from '../product/constants';

import { type DrawerMode } from '../../types/ui';

export type UIstate = {
  appliedToAllToggle: boolean,
  loadingAppliedAll: boolean,
  sidebarExpanded: boolean,
  gallerySortBy: string,
  sidebarLocked: boolean,
  currentPage: string | null,
  partialSelection: 'left' | 'right' | null,
  surfaceCenterPoint: number | null,
  toolsVisible: boolean,
  cropRegionVisible: boolean,
  addingToCart: boolean,
  message: string | null,
  menuVisible: boolean,
  previewVisible: boolean,
  currentAlbum: string,
  uploadPhotosModalVisible: boolean,
  promptUserToLoginModalVisible: boolean,
  showDropTargets: boolean,
  showDropTargetsHighlight: boolean,
  editorFontsLoaded: boolean,
  confirmation: {
    status: string,
    data: Object | null,
    skipConfirmationForStatuses: Array<string>,
  },
  modal: ModalState,
  drawerVisible: boolean,
  drawerMode: DrawerMode,
  tooltip: {
    x: number,
    y: number,
    type: string,
    data: Object | null,
  },
  textEditorPageId: string,
  textEditorLayerId: string,
  drawerGridScrollCache: {
    [string]: number,
  },
  selectedLayer: {
    layerId: string | null,
    pageId: string | null,
  },
  autoFillImgCount: number,
  screenSize: {
    width: number,
    height: number,
  },
  isZendeskLoaded: boolean,
  showSideBarTut: boolean,
  showUpdateProjectTooltip: boolean,
};

export const defaultUi = {
  appliedToAllToggle: false,
  loadingAppliedAll: false,
  sidebarExpanded: false,
  sidebarLocked: false,
  currentPage: null,
  partialSelection: null,
  surfaceCenterPoint: null,
  toolsVisible: false,
  gallerySortBy: 'CREATED_AT_DESC',
  galleryPageNumber: 1,
  galleryHasMoreImages: true,
  cropRegionVisible: false,
  addingToCart: true,
  message: null,
  menuVisible: false,
  previewVisible: false,
  currentAlbum: '',
  uploadPhotosModalVisible: false,
  promptUserToLoginModalVisible: false,
  showDropTargets: false,
  showDropTargetsHighlight: false,
  editorFontsLoaded: false,
  userInputData: [],
  confirmation: {
    status: '',
    data: null,
    skipConfirmForStatuses: [],
  },
  modal: initialModalState,
  drawerVisible: true,
  drawerMode: UI_DRAWER_MODE_DEFAULT,
  tooltip: {
    x: 0,
    y: 0,
    type: '',
    data: null,
  },
  textEditorPageId: '',
  textEditorLayerId: '',
  drawerGridScrollCache: {},
  selectedLayer: {
    layerId: null,
    pageId: null,
  },
  autoFillImgCount: 0,
  screenSize: {
    width: 0,
    height: 0,
  },
  isZendeskLoaded: false,
  isUndoRedoEnabled: false,
  isGooglePhotosEnabled: false,
  isInstagramEnabled: false,
  hideUsedImages: false,
  showSideBarTut: false,
  showUpdateProjectTooltip: false,
};

export default function uiReducer(
  state: UIstate = defaultUi,
  action: { type: string, payload: any }
): UIstate {
  switch (action.type) {
    case UI_SET_CURRENT_PAGE: {
      const { currentPage } = action.payload;
      const { selectedLayer } = state;

      return {
        ...state,
        currentPage,
        // Reset selectedLayer if the page new page doesn't include the selectedLayer.
        selectedLayer: {
          layerId:
            selectedLayer && selectedLayer.pageId === currentPage
              ? selectedLayer.layerId
              : null,
          pageId:
            selectedLayer && selectedLayer.pageId === currentPage
              ? selectedLayer.pageId
              : null,
        },
      };
    }

    case UI_SET_SORT_BY: {
      const { gallerySortBy } = action.payload;
      return {
        ...state,
        gallerySortBy,
      };
    }

    case UI_SET_PREVIOUS_PAGE: {
      const { prevPage } = action.payload;
      return {
        ...state,
        prevPage,
      };
    }

    case UI_SET_PARTIAL_SELECTION: {
      const { partialSelection } = action.payload;
      return {
        ...state,
        partialSelection,
      };
    }

    case UI_SET_SURFACE_CENTER_POINT: {
      const { surfaceCenterPoint } = action.payload;
      return {
        ...state,
        surfaceCenterPoint,
      };
    }
    
    case UI_RESET_CURRENT_GALLERY_VALUES: {
      return {
        ...state,
        galleryPageNumber: 1,
        galleryHasMoreImages: true,
      };
    }

    case UI_SET_TOOL_VISIBILITY: {
      return {
        ...state,
        toolsVisible: true,
      };
    }

    case UI_UNSET_TOOL_VISIBILITY: {
      return {
        ...state,
        toolsVisible: false,
      };
    }

    case UI_SET_GALLERY_PAGE_NUMBER: {
      const { galleryPageNumber } = action.payload;
      return {
        ...state,
        galleryPageNumber,
      };
    }
    
    case UI_SET_CROP_REGION_VISIBILITY: {
      return {
        ...state,
        toolsVisible: false,
        cropRegionVisible: true,
      };
    }

    case UI_UNSET_CROP_REGION_VISIBILITY: {
      return {
        ...state,
        toolsVisible: false,
        cropRegionVisible: false,
      };
    }

    case ADD_TO_CART_REQUEST: {
      return {
        ...state,
        addingToCart: true,
        message: 'Hold tight while we add this project to your cart.',
      };
    }

    case ADD_TO_CART_PROCESSING: {
      if (action.payload.cartState === ADD_TO_CART_FAILURE) {
        return {
          ...state,
          addingToCart: true,
          message:
            'Oops. Something went wrong. Please contact the AU Community Team.',
        };
      }

      return state;
    }

    case ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        addingToCart: false,
      };
    }

    case UI_TOGGLE_KABOB_MENU_ON: {
      return {
        ...state,
        menuVisible: true,
      };
    }

    case UI_TOGGLE_KABOB_MENU_OFF: {
      return {
        ...state,
        menuVisible: false,
      };
    }

    case UI_TOGGLE_SKIP_CONFIRM_FOR_STATUS: {
      const { status } = action.payload;
      return {
        ...state,
        confirmation: {
          ...state.confirmation,
          skipConfirmForStatuses: state.confirmation.skipConfirmForStatuses.includes(
            status
          )
            ? state.confirmation.skipConfirmForStatuses.filter(
              skipped => skipped !== status
            )
            : [...state.confirmation.skipConfirmationForStatuses, status],
        },
      };
    }

    case UI_SET_PREVIEW_VISIBILITY: {
      return {
        ...state,
        previewVisible: true,
      };
    }

    case UI_UNSET_PREVIEW_VISIBILITY: {
      return {
        ...state,
        previewVisible: false,
      };
    }

    case UI_OPEN_SIDEBAR_TUT: {
      return {
        ...state,
        showSideBarTut: true,
      };
    }

    case UI_CLOSE_SIDEBAR_TUT: {
      return {
        ...state,
        showSideBarTut: false,
      };
    }

    case UI_SET_UPLOAD_PHOTOS_VISIBILITY: {
      const { immediateUpload, title } = action.payload;
      return {
        ...state,
        uploadPhotosModalVisible: true,
        uploadStraightToAlbum: immediateUpload,
        progressBarTitle: title.getOrElseValue('Add Photos'),
      };
    }

    case UI_UNSET_UPLOAD_PHOTOS_VISIBILITY: {
      return {
        ...state,
        uploadPhotosModalVisible: false,
      };
    }

    case UI_SET_CURRENT_ALBUM: {
      return {
        ...state,
        currentAlbum: action.payload.currentAlbum,
      };
    }

    case UI_UNSET_CURRENT_ALBUM: {
      return {
        ...state,
        currentAlbum: '',
      };
    }

    case UI_SET_PROMPT_USER_TO_LOGIN_MODAL: {
      return {
        ...state,
        promptUserToLoginModalVisible: true,
      };
    }

    case UI_SET_SIDEBAR_EXPANDED: {
      const { sidebarExpanded } = action.payload;

      // If the sidebar isn't locked, update the sidebarExpanded value.
      if (!state.sidebarLocked) {
        return {
          ...state,
          sidebarExpanded,
        };
      }

      // Otherwise, return the state, unmodified.
      return state;
    }

    case UI_SET_SIDEBAR_LOCKED: {
      const { sidebarLocked } = action.payload;

      return {
        ...state,
        sidebarLocked,
      };
    }

    case UI_CONFIRMATION_SET_PAGE_REMOVAL: {
      return {
        ...state,
        confirmation: {
          ...state.confirmation,
          status: UI_CONFIRMATION_SET_PAGE_REMOVAL,
          data: action.payload,
        },
      };
    }

    case UI_CONFIRMATION_UNSET: {
      return {
        ...state,
        confirmation: {
          ...state.confirmation,
          status: '',
          data: null,
        },
      };
    }

    case UI_CONFIRMATION_SET_USER_PAGE_REMOVAL: {
      return {
        ...state,
        confirmation: {
          ...state.confirmation,
          status: UI_CONFIRMATION_SET_USER_PAGE_REMOVAL,
          data: {
            pageIds: action.payload.pageIds,
          },
        },
      };
    }

    case UI_CONFIRMATION_SET_CROP_RESET: {
      return {
        ...state,
        confirmation: {
          ...state.confirmation,
          status: UI_CONFIRMATION_SET_CROP_RESET,
          data: {
            attribute: action.payload.attribute,
            undoableItemId: action.payload.undoableItemId,
          },
        },
      };
    }

    case UI_SHOW_DROP_TARGETS: {
      return {
        ...state,
        showDropTargets: action.payload,
      };
    }

    case UI_SHOW_DROP_TARGETS_HIGHLIGHT: {
      return {
        ...state,
        showDropTargetsHighlight: action.payload,
      };
    }

    case UI_SET_TEXT_EDITOR_VISIBILITY: {
      return {
        ...state,
        textEditorVisible: true,
        textEditorPageId: action.payload.pageId,
        textEditorLayerId: action.payload.layerId,
      };
    }

    case UI_UNSET_TEXT_EDITOR_VISIBILITY: {
      return {
        ...state,
        textEditorVisible: false,
        textEditorPageId: '',
        textEditorLayerId: '',
      };
    }

    case UI_FONTS_LOADED: {
      return {
        ...state,
        editorFontsLoaded: true,
      };
    }

    case UI_MODAL_PHOTO_EDITOR:
    case UI_MODAL_SET_STATUS:
    case UI_MODAL_CLOSE: {
      return {
        ...state,
        modal: modalReducer(state.modal, action),
      };
    }

    case UI_SET_DRAWER_VISIBILITY: {
      const { drawerVisible } = action.payload;

      return {
        ...state,
        drawerVisible,
        // Reset drawerMode if the drawer won't be visible.
        drawerMode:
          drawerVisible === false ? UI_DRAWER_MODE_DEFAULT : state.drawerMode,
      };
    }

    case UI_SET_DRAWER_MODE: {
      const { drawerMode } = action.payload;

      return {
        ...state,
        drawerMode,
      };
    }

    case UI_SET_FULL_GALLERY_VIEW: {
      return {
        ...state,
        fullGalleryViewVisible: true,
      };
    }
    case UI_UNSET_FULL_GALLERY_VIEW: {
      return {
        ...state,
        fullGalleryViewVisible: false,
      };
    }

    case UI_TOGGLE_HIDE_USED_IMAGES: {
      const { hideUsedImages } = action.payload;
      return {
        ...state,
        hideUsedImages,
      };
    }

    case UI_SET_TOOLTIP_POSITION: {
      const { posX, posY } = action.payload;
      return {
        ...state,
        tooltip: {
          ...state.tooltip,
          x: posX,
          y: posY,
        },
      };
    }

    case UI_SET_TOOLTIP_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        tooltip: {
          ...state.tooltip,
          data,
        },
      };
    }

    case UI_SET_TOOLTIP_TYPE: {
      const { type } = action.payload;
      return {
        ...state,
        tooltip: {
          ...state.tooltip,
          type,
        },
      };
    }

    case UI_SET_TOOLTIP_STATE: {
      const { state: tooltipState } = action.payload;
      return {
        ...state,
        tooltip: {
          ...state.tooltip,
          state: tooltipState,
        },
      };
    }

    case UI_OPEN_TOOLTIP_WITH_DATA: {
      const { state: tooltipState, posX, posY, data, type } = action.payload;
      return {
        ...state,
        tooltip: {
          ...state.tooltip,
          state: tooltipState,
          x: posX,
          y: posY,
          data,
          type,
        },
      };
    }

    case UI_SET_MANAGE_PAGES_VISIBILITY: {
      return {
        ...state,
        managePagesVisible: action.payload,
      };
    }

    case UI_SET_DRAWERGRID_SCROLL_CACHE: {
      const { key, value } = action.payload;

      return {
        ...state,
        drawerGridScrollCache: {
          ...state.drawerGridScrollCache,
          [key]: value,
        },
      };
    }

    case UI_SET_SELECTED_LAYER: {
      const { selectedLayer } = action.payload;

      return {
        ...state,
        selectedLayer,
      };
    }

    case UI_SET_AUTOFILL_IMG_COUNT: {
      return {
        ...state,
        autoFillImgCount: action.payload,
      };
    }

    case UI_INC_AUTOFILL_IMG_COUNT: {
      return {
        ...state,
        autoFillImgCount: state.autoFillImgCount + 1,
      };
    }

    case UI_SET_SCREEN_SIZE: {
      const { width, height } = action.payload;
      return {
        ...state,
        screenSize: {
          width,
          height,
        },
      };
    }

    case UI_SET_ZENDESK_LOADED: {
      return {
        ...state,
        isZendeskLoaded: true,
      };
    }

    case UI_SET_USER_INPUT_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        userInputData: [...data],
      };
    }

    case SET_APPLY_TO_ALL_TOGGLE: {
      return {
        ...state,
        appliedToAllToggle: action.payload,
      };
    }

    case SET_LOADING_APPLY_TO_ALL: {
      return {
        ...state,
        loadingAppliedAll: action.payload,
      };
    }

    case UI_LAYOUT_DROPDOWN: {
      return {
        ...state,
        layoutDropdownOption: action.payload,
      };
    }

    case UI_SET_UPDATE_PROJECT_TOOLTIP: {
      return {
        ...state,
        showUpdateProjectTooltip: action.payload,
      }
    }

    default: {
      return state;
    }
  }
}

export const uiStateFilter = (state: UIstate) => ({
  ...state,
  message: null,
  menuVisible: null,
  sidebarExpanded: false,
  drawerGridScrollCache: {},
  partialSelection: null,
});
