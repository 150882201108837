// api.
export const getMagentoApiBaseUrl = () => process.env.REACT_APP_MAGENTO_API_BASE_URL;

// www.
export const getFastlyBaseUrl = () => process.env.REACT_APP_FASTLY_BASE_URL;

const MAGENTO_API_SUFFIX = '/index.php/rest/V1';

// Gives you something like https://www.artifactuprising.com/index.php/rest/V1
export const getMagentoApiThroughFastlyUrl = () => `${getFastlyBaseUrl()}${MAGENTO_API_SUFFIX}`;

// Gives you something like https://api.artifactuprising.com/index.php/rest/V1
export const getMagentoApiUrl = () => `${getMagentoApiBaseUrl()}${MAGENTO_API_SUFFIX}`;

export const getAUAdminMeUrl = () => `${getMagentoApiUrl()}/au/admin/me`;
export const getAUAddToCartUrl = () => `${getMagentoApiUrl()}/au/carts/mine/items`;
export const getAUProductsUrl = () => `${getMagentoApiThroughFastlyUrl()}/au/products`;

export const getCustomerAccountUrl = () => `${getFastlyBaseUrl()}/customer/account`;
export const getCustomerAccountLoginUrl = () => `${getCustomerAccountUrl()}/login/referer`;

export const getCheckoutCartUrl = () => `${getFastlyBaseUrl()}/checkout/cart`;
export const getAccountProjectsUrl = () => `${getFastlyBaseUrl()}/account/project/index`;

export const getProjectUrl = (projectId: string, flashId: string, flashToken: string) =>
  `${window.origin}${window.location.pathname}?projectId=${projectId}&flashId=${flashId}&flashToken=${flashToken}`;
