import {
  SET_DROPBOX_PHOTOS,
  SET_DROPBOX_ACTIVE,
  SET_DROPBOX_MAIN_ALBUM,
} from './constants';

const INITIAL_STATE = {
  dropboxImgArray: [],
  dropboxMainAlbum: null,
  dropboxActive: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DROPBOX_PHOTOS: {
      return { ...state, dropboxImgArray: action.payload };
    }

    case SET_DROPBOX_ACTIVE: {
      return { ...state, dropboxActive: action.payload };
    }

    case SET_DROPBOX_MAIN_ALBUM: {
      return { ...state, dropboxMainAlbum: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const dropboxStateFilter = () => INITIAL_STATE;
