/* eslint-disable no-use-before-define */
// @flow
import * as actionTypes from './actionTypes';
import { Gallery } from 'au-js-sdk/lib/models/Gallery';

interface GalleryState {
  galleries: Gallery[];

  // Both of these can be removed once an image upload queue is made
  pendingActions: string[];
  errors: any[];
}

export const initialState: GalleryState = {
  galleries: [],
  pendingActions: [],
  errors: [],
};

export default (state: GalleryState = initialState, action: actionTypes.Action): GalleryState => {
  if (
    action.type.includes(actionTypes.FETCH_GALLERIES) ||
    action.type.includes(actionTypes.CREATE_GALLERY) ||
    action.type.includes(actionTypes.UPDATE_GALLERY) ||
    action.type.includes(actionTypes.FETCH_GALLERY_IMAGES) ||
    action.type.includes(actionTypes.UPLOAD_IMAGES_TO_GALLERY) ||
    action.type.includes(actionTypes.AUTO_ORIENT_IMAGE_IN_GALLERY) ||
    action.type.includes(actionTypes.REMOVE_IMAGE_FROM_GALLERY)
  ) {
    return {
      ...state,
      pendingActions: state.pendingActions.concat(action.type),
    };
  } else if (action.type.includes(actionTypes.GALLERY_ACTION_SUCCEEDED)) {
    return handleActionSucceeded(state, action.payload);
  } else if (action.type.includes(actionTypes.GALLERY_ACTION_FAILED)) {
    return handleActionFailed(state, action.payload);
  }

  return state;
};

const handleActionSucceeded = (state: GalleryState, payload: any): GalleryState => {
  const newPendingActions = [...state.pendingActions];
  const completedActionIdx = newPendingActions.findIndex((pa) => pa === payload.actionType);
  newPendingActions.splice(completedActionIdx, 1);
  const newState: GalleryState = {
    ...state,
    pendingActions: newPendingActions,
  };

  if (payload.actionType === actionTypes.FETCH_GALLERIES) {
    // Merge existing galleries in state with galleries that come back from the server
    // Since they contain no images
    newState.galleries = payload.galleries.map((payloadGallery) => {
      const galleryInState = state.galleries.find((stateGallery) => stateGallery.id === payloadGallery.id);
      if (galleryInState) {
        return {
          ...payloadGallery,
          images: galleryInState.images,
        };
      }
      return payloadGallery;
    });
  } else if (payload.actionType === actionTypes.CREATE_GALLERY) {
    newState.galleries = [...state.galleries, payload.gallery];
  } else if (payload.actionType === actionTypes.GET_MORE_PHOTOS) {
    const updatedGalleries = state.galleries.map((gallery) => {
      if(gallery.id === payload.galleryId){
        return {
          ...gallery,
          images: payload.newImages
        }
      } else return gallery
    })
    return {
      ...state,
      galleries: updatedGalleries,
    };
  } else if (payload.actionType === actionTypes.UPDATE_GALLERY) {
    const newGalleries = [...state.galleries];
    const galleryIdx = newGalleries.findIndex((g) => g.id === payload.gallery.id);
    const gallery = newGalleries[galleryIdx];
    if (gallery.name !== payload.gallery.name || gallery.thumbnailUrl !== payload.gallery.thumbnailUrl) {
      const updatedGallery = {
        ...gallery,
        name: payload.gallery.name,
        thumbnailUrl: payload.gallery.thumbnailUrl,
      };
      newGalleries.splice(galleryIdx, 1, updatedGallery);
      newState.galleries = newGalleries;
    }
  } else if (payload.actionType === actionTypes.FETCH_GALLERY_IMAGES && payload.galleryImages.length !== 0) {
    const newGalleries = [...state.galleries];
    const galleryIdx = newGalleries.findIndex((g) => g.id === payload.galleryImages[0].galleryId);
    const updatedGallery = {
      ...newGalleries[galleryIdx],
      images: payload.galleryImages,
    };
    newGalleries.splice(galleryIdx, 1, updatedGallery);
    newState.galleries = newGalleries;
  } else if (payload.actionType.includes(actionTypes.UPLOAD_IMAGES_TO_GALLERY)) {
    newState.galleries = addOrReplaceImageForGalleryInGalleries(state.galleries, payload.galleryImage);
  } else if (payload.actionType.includes(actionTypes.AUTO_ORIENT_IMAGE_IN_GALLERY)) {
    newState.galleries = addOrReplaceImageForGalleryInGalleries(state.galleries, payload.galleryImage);
  } else if (payload.actionType === actionTypes.REMOVE_IMAGE_FROM_GALLERY) {
    const newGalleries = [...state.galleries];
    const galleryIdx = newGalleries.findIndex((g) => g.id === payload.galleryId);

    const newGalleryImages = [...newGalleries[galleryIdx].images];
    const imageIdx = newGalleryImages.findIndex((gi) => gi.id === payload.imageId);
    newGalleryImages.splice(imageIdx, 1);

    const updatedGallery = {
      ...newGalleries[galleryIdx],
      images: newGalleryImages,
    };
    newGalleries.splice(galleryIdx, 1, updatedGallery);
    newState.galleries = newGalleries;
  }

  return newState;
};

const addOrReplaceImageForGalleryInGalleries = (galleries: Gallery[], galleryImage: GalleryImage): Gallery[] => {
  const newGalleries = [...galleries];

  // Find the gallery
  const galleryIdx = newGalleries.findIndex((g) => g.id === galleryImage.galleryId);

  // Find image in gallery
  const imageIdx = newGalleries[galleryIdx].images.findIndex((i) => i.id === galleryImage.id);

  let updatedImages = [...newGalleries[galleryIdx].images];
  if (imageIdx === -1) {
    updatedImages = [...updatedImages, galleryImage];
  } else {
    updatedImages.splice(imageIdx, 1, galleryImage);
  }

  // Build new gallery obj
  const updatedGallery = {
    ...newGalleries[galleryIdx],
    images: updatedImages,
  };

  // Replace old gallery obj with new one
  newGalleries.splice(galleryIdx, 1, updatedGallery);

  return newGalleries;
};

const handleActionFailed = (state: GalleryState, payload: any): GalleryState => {
  const newPendingActions = [...state.pendingActions];
  const completedActionIdx = newPendingActions.findIndex((pa) => pa === payload.actionType);
  newPendingActions.splice(completedActionIdx, 1);
  return {
    ...state,
    pendingActions: newPendingActions,
    errors: [payload.error, ...state.errors],
  };
};
