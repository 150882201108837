import ajv from 'ajv';
import ajvErrors from 'ajv-errors';

const EMAIL_ERROR = 'Please enter a valid email.';
const MISSING_FIELD_ERROR = 'Missing data. All fields are required.';
const PASSWORD_REGISTER_ERROR = 'Password must be at least 7 characters long.';
const PASSWORD_ERROR = 'Password must be at least 6 characters long.';

const userLoginSchema = {
  id: 'userLoginSchema',
  type: 'object',
  properties: {
    email: {
      format: 'email',
    },
    password: {
      type: 'string',
      minLength: 6,
    },
  },
  required: ['email', 'password'],
  errorMessage: {
    properties: {
      email: EMAIL_ERROR,
      password: PASSWORD_ERROR,
    },
  },
};

const emailSchema = {
  id: 'email',
  type: 'object',
  properties: {
    email: {
      format: 'email',
    },
  },
  required: ['email'],
  errorMessage: {
    properties: {
      email: EMAIL_ERROR,
    },
  },
};


const userRegisterSchema = {
  id: 'userRegisterSchema',
  type: 'object',
  properties: {
    firstName: {
      type: 'string',
      minLength: 1,
    },
    lastName: {
      type: 'string',
      minLength: 1,
    },
    email: {
      format: 'email',
    },
    password: {
      type: 'string',
      minLength: 7,
    },
    confirmPassword: {
      type: 'string',
    },
  },
  required: ['email', 'password', 'confirmPassword', 'firstName', 'lastName'],
  errorMessage: {
    properties: {
      email: EMAIL_ERROR,
      password: PASSWORD_REGISTER_ERROR,
      firstName: MISSING_FIELD_ERROR,
      lastName: MISSING_FIELD_ERROR,
      confirmPassword: MISSING_FIELD_ERROR,
    },
  },
};


const validator = new ajv({ allErrors: true, jsonPointers: true, $data: true });
ajvErrors(validator);

validator.addSchema(userLoginSchema, '#/userLoginSchema');
validator.addSchema(userRegisterSchema, '#/userRegisterSchema');
validator.addSchema(emailSchema, '#/email');

export default validator;
