import { getMagentoApiBaseUrl, getMagentoApiThroughFastlyUrl, getMagentoApiUrl } from '../helpers/urls';

export const MAGENTO_LOGIN_ENDPOINT = `${getMagentoApiBaseUrl()}/account/ajax/login`;
export const MAGENTO_USER_ME_ENDPOINT = `${getMagentoApiUrl()}/customers/me`;
export const MAGENTO_USER_PASSWORD_RESET_ENDPOINT = `${getMagentoApiUrl()}/customers/password`;
export const MAGENTO_USER_CART_ENDPOINT = `${getMagentoApiUrl()}/carts/mine/items`;
export const MAGENTO_USER_REGISTER_ENDPOINT = `${getMagentoApiUrl()}/customers`;

// We want these going through Fastly so we can cache them
export const MAGENTO_COUNTRIES_ENDPOINT = `${getMagentoApiThroughFastlyUrl()}/directory/countries`;
export const MAGENTO_PRODUCTS_ENDPOINT = `${getMagentoApiThroughFastlyUrl()}/au/products`;
