/* eslint-disable no-use-before-define */
import { find, isEqual } from 'lodash';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import * as asyncInitialState from 'redux-async-initial-state';
import thunk from 'redux-thunk';
import { option } from 'fp-ts';
import { tryCatch } from 'fp-ts/lib/Option';
import has from '../helpers/has';
import { prop, optionGet } from '../helpers/functions';
import uuid4 from 'uuid/v4';
import { setNewRelicAttribute } from '../helpers/newrelic';
import { convertAttrsToSpaces } from '../helpers/urlParameters';
import { requestMagentoCart, requestMagentoChildProduct, convertChildProductResponse, requestMagentoPriceTiers } from '../services/magento';
import { saveProjectAndUnlock, setProjectLock } from '../services/projectApi';
import { getPropertyFromParamStore } from '../services/awsService';
import defaultStateCreator from './defaultState';
import actionObserver from './middleware/actionObserver';
import logObserver from './middleware/logObserver';
import api from './middleware/api';

import { createAdminLockNotification, createCartLockNotification, createGenericNotification } from './notifications/actions';
import { PROJECT_STATE_ADMIN_EDITING, PROJECT_STATE_IN_CART, PROJECT_STATE_IN_PROGRESS } from './project/constants';
import { currentVisiblePageCountMinusCoverSelector } from './project/pages/selectors';
import reducers from './reducers';
import { rootHistoryReducer } from './history/reducer';
import { getCookie } from '../helpers/cookies';
import { AB_COOKIE_GOOGLE_PHOTOS } from './addPhotos/constants';
import { fixFlashPhotoDimensions, setAdminCheckForUserPhotoLayers } from './adminCheckHelper';
import { attributesToMap, mapProductAttributes } from '../helpers/store';
import { getZenDeskTreatment } from '../services/splitio';

// TODO: REWRITE/REPLACE THIS ENTIRE FILE

/**
 * applyProjectToState marks the default state generated on page load as authorized.
 * The user and UI states are also reset to the defaultStates changes to reduce side effects
 * that may have accidentally been saved to project service.
 * @param {Object} currentState: Default state based on page load and user action
 * @param {Object} project: Project store pulled in from project service
 */
const applyProjectToState = (getState, project, json) => ({
  ...project,
  product: {
    ...project.product,
    qty: getState().product.qty ? getState().product.qty : project.product.qty,
    priceTiers: getState().product.priceTiers,
  },
  envelopeAddressing: {
    ...project.envelopeAddressing,
  },
  project: {
    ...project.project,
    authorized: true,
  },
  notifications: [...(json.state === PROJECT_STATE_ADMIN_EDITING ? [createAdminLockNotification()] : []), ...getState().notifications],
  ui: {
    ...getState().ui,
  },
});

/**
 * authorizeEmptyProject transforms the current state to mark the user as authorized.
 * This happens as a result of verifying that the user is logged in and the flash id matches
 * the loaded project.
 * @param {Object} currentState: Default state based on page load and user action
 */
const authorizeEmptyProject = (currentState) => ({
  ...currentState,
  project: {
    ...currentState.project,
    authorized: true,
  },
  user: {
    ...currentState.user,
    authorized: true,
  },
});

/**
 * addInCartNotificationToState is used to add a cart notification the asynchronously loaded state.
 * As this is the point at which we are setting the state async, we don't have access to dispatch.
 * @param {Object} state
 */
const addInCartNotificationToState = (state, quoteItem) => ({
  ...state,
  notifications: [...state.notifications, createCartLockNotification()],
  project: {
    ...state.project,
    lockState: PROJECT_STATE_IN_CART,
  },
  product: {
    ...state.product,
    quoteItemId: quoteItem.item_id,
    quoteId: quoteItem.quote_id,
    qty: quoteItem.qty,
    skippedChecks: [],
  },
});

const addUuidsToProjectPages = (projectState: Object) =>
  optionGet('project.pages')(projectState)
    .map((x) => x[0])
    .chain(prop('uuid'))
    .getOrElseValue(false)
    ? projectState
    : {
      ...projectState,
      project: {
        ...projectState.project,
        pages: [...projectState.project.pages.map((x) => ({ ...x, uuid: uuid4() }))],
      },
    };

/**
 * transformStateWithProject determines if a project is empty (just started)
 * or in progress (is being edited) and applies the proper state transformation
 * for each condition.
 *
 * project being edited calls applyProjectToState()
 * project that was just started calls authorizeEmptyProject()
 *
 * @param {*} getState
 * @param {*} projectJson: json data loaded from project service
 */
const transformStateWithProject = (getState, projectJson) => {
  const projectData = JSON.parse(projectJson.projectData);
  if (has(projectData)('data')) {
    const projectCreatedAtDate = new Date((projectJson.createdAt || 0)).toISOString();
    const projectState = projectData.data;
    const projectStateWithUuids = addUuidsToProjectPages(projectState);
    projectStateWithUuids.userPhotos = fixFlashPhotoDimensions(projectStateWithUuids.userPhotos);
    const projectPagesWithCleanedCropperData = setAdminCheckForUserPhotoLayers(projectCreatedAtDate, projectStateWithUuids.project.pages, projectStateWithUuids.userPhotos);

    const fixedProjectData = {
      ...projectStateWithUuids,
      project: {
        ...projectStateWithUuids.project,
        pages: projectPagesWithCleanedCropperData,
      },
    };

    return applyProjectToState(getState, fixedProjectData, projectJson);
  }

  return authorizeEmptyProject(getState());
};

/**
 * verifyItemInCartAndTransform requests the user's cart from Magento and iterates the items
 * in the cart to ensure that the current item is indeed in the cart. This is necessary
 * because Magento carts can expire which would result in a locked project that can't be added to cart.
 *
 * If a cart item is found not to be in cart, we must updated the project in project service to mark
 * it as 'IN_PROGRESS'.
 *
 * @param {function} getState
 * @param {string} projectJson: json string loaded from project service
 * @returns
 */
function verifyItemInCartAndTransform(getState, projectJson) {
  return requestMagentoCart()
    .then((json) => {
      const projectId = getState().project.id;
      const projectItem = find(json, (cartItem) => {
        const itemProjectId = find(cartItem.extension_attributes, (attr, key) => key === 'au_project_id');
        return projectId === itemProjectId;
      });

      if (projectItem !== undefined) {
        return addInCartNotificationToState(transformStateWithProject(getState, projectJson), projectItem);
      }

      const { flashId, flashToken } = getState().user;
      const projectSaveBody = {
        projectId,
        state: PROJECT_STATE_IN_PROGRESS,
        user: flashId,
      };

      saveProjectAndUnlock(projectId, flashId, flashToken, projectSaveBody).catch((e) => {
        window.newrelic.noticeError(e);
      });

      return transformStateWithProject(getState, projectJson);
    })
    .catch((e) => {
      window.newrelic.noticeError({ message: 'Editor cart request error', error: e });

      // If we get a 401, we're returning to the login page, don't unlock the project.
      if (e.cause && e.cause.status === 401) {
        return;
      }
      const projectId = getState().project.id;

      const { flashId, flashToken } = getState().user;
      const projectSaveBody = {
        projectId,
        state: PROJECT_STATE_IN_PROGRESS,
        user: flashId,
      };

      saveProjectAndUnlock(projectId, flashId, flashToken, projectSaveBody).catch((e) => {
        window.newrelic.noticeError(e);
      });

      return transformStateWithProject(getState, projectJson);
    });
}

/* Modifies the state loaded from the project service to include any product data loaded from Magento.
   Also converts any previously-set attribute values with '+' characters in them to use actual spaces instead. */
const withMagentoProductData = (
  projectData,
  magentoProductData,
  priceTiers,
  getState,
  [isUndoRedoEnabled, isGooglePhotosEnabled, isInstagramEnabled, notifications, promo, isZenDeskEnabled]
) => {
  const { ui: currentUi, user: currentUser } = getState();
  const { qty } = projectData.product;

  const updatedQty = option
    .fromPredicate((tiers) => tiers.length > 0)(priceTiers)
    .map((tiers) => (tiers.some((tier) => tier.quantity === qty) ? qty : tiers[0].quantity))
    .getOrElseValue(qty);

  const mappedAttributes = attributesToMap(projectData.product); //will return 2D array [[key, originalValue]] or empty array
  const attributes = mappedAttributes ? mapProductAttributes(projectData.product, mappedAttributes) : projectData.product.attributes;

  return {
    ...projectData,
    product: {
      ...projectData.product,
      priceTiers,
      qty: updatedQty,
      attributes: convertAttrsToSpaces(attributes),
      mothersDayPromo: promo,
      ...magentoProductData,
    },
    ui: {
      ...projectData.ui,
      ...currentUi,
      isUndoRedoEnabled,
      isGooglePhotosEnabled,
      isInstagramEnabled,
      isZenDeskEnabled,
    },
    notifications: [...getState().notifications, ...notifications],
    user: {
      ...currentUser,
    },
    zendesk: {
      ...getState().zendesk,
    },
  };
};

// Async helper function which returns a promise for the appropriate API action, depending on project state.
const obtainProject = async (projectData, currentState, getState) => {
  if (projectData !== undefined) {
    if (projectData.state === PROJECT_STATE_IN_CART && !currentState.user.admin) {
      return verifyItemInCartAndTransform(getState, projectData);
    }

    if (currentState.user.admin && projectData.state !== PROJECT_STATE_ADMIN_EDITING) {
      setProjectLock(currentState.project.id, currentState.user.flashId, currentState.user.flashToken, PROJECT_STATE_ADMIN_EDITING);
    }

    return transformStateWithProject(getState, projectData);
  }

  return {
    ...currentState,
    project: {
      ...currentState.project,
      authorized: false,
    },
  };
};

const UNDO_REDO_PARAM_KEY = '/editor/isUndoRedoEnabled';
const GOOGLE_PHOTOS_PARAM_KEY = '/editor/isGooglePhotosEnabled';
const INSTAGRAM_PARAM_KEY = '/editor/isInstagramEnabled';
const ADDITIONAL_CONFIG_PARAM_KEY = '/editor/additionalConfig';
const ENABLE_ZENDESK_CHAT_PARAM_KEY = '/editor/enableZenDeskChat'
const requestUndoRedoEnabled = async () => getPropertyFromParamStore(UNDO_REDO_PARAM_KEY);
const requestGooglePhotosEnabled = async () => getPropertyFromParamStore(GOOGLE_PHOTOS_PARAM_KEY);
const requestInstagramEnabled = async () => getPropertyFromParamStore(INSTAGRAM_PARAM_KEY);
const requestAdditionalConfig = async () => getPropertyFromParamStore(ADDITIONAL_CONFIG_PARAM_KEY);

const loadMagentoData = async (currentState) => {
  const pageCountMinusCover = currentVisiblePageCountMinusCoverSelector(currentState);

  return Promise.all([
    requestMagentoChildProduct(currentState.product, pageCountMinusCover)
      .then(convertChildProductResponse)
      .catch((e) => {
        window.newrelic.noticeError(e);
        return { childSku: null, magento: null };
      }),
    ...(has(currentState.product.attributes)('card_quantity') ? [[]] : [requestMagentoPriceTiers(currentState.product.sku)]),
  ]);
};

/**
 * loadStore asynchronously requests the project data from project service and
 * conditionally replaces state depending on the response. getState is passed
 * as a function to ensure any store updates that happen between request
 * and response are maintained.
 *
 * @export
 * @param {function} getState
 * @returns
 */
export async function loadStore(getState) {
  const currentState = getState();

  // Move getUserDataFromWebStore() from App.js `componentDidMount` into here
  // so that we load customer data before we render anything on the page

  const [isUndoRedoEnabled, isGooglePhotosEnabled, isInstagramEnabled, notifications, promo] = await Promise.all([
    requestUndoRedoEnabled(),
    requestGooglePhotosEnabled(),
    requestInstagramEnabled(),
    requestAdditionalConfig()
  ]).then(([undoRedoEnabled, googlePhotosEnabled, instagramEnabled, config, isZenDeskEnabled]) => [
    // undo/redo value
    prop(UNDO_REDO_PARAM_KEY)(undoRedoEnabled)
      .map((x) => x === 'true')
      .getOrElseValue(false),
    // Google Photos Enabled value
    prop(GOOGLE_PHOTOS_PARAM_KEY)(googlePhotosEnabled)
      .map((x) => !!(x === 'true' && getCookie(AB_COOKIE_GOOGLE_PHOTOS) === 'false'))
      .getOrElseValue(false),
    // Instagram Enabled value
    prop(INSTAGRAM_PARAM_KEY)(instagramEnabled)
      .map((x) => x === 'true')
      .getOrElseValue(false),
    // notifications plucked from additional config value
    prop(ADDITIONAL_CONFIG_PARAM_KEY)(config)
      .chain((cfg) => tryCatch(() => JSON.parse(cfg)))
      .chain(prop('notifications'))
      .map((messages) => messages.map(createGenericNotification))
      .getOrElseValue([]),
    // mothers day promo
    prop(ADDITIONAL_CONFIG_PARAM_KEY)(config)
      .chain((cfg) => tryCatch(() => JSON.parse(cfg)))
      .chain(prop('mothersDayPromo'))
      .map((val) => val === true)
      .getOrElseValue(false)
  ]);
  
  const isZenDeskEnabled = await getZenDeskTreatment();

  if (currentState.user.anonymous || currentState.project.id === null) {
    const [magentoProductData, priceTiers] = await loadMagentoData(currentState);
    const state = getState();
    const { product } = state;
    const { qty } = product;

    const updatedQty = option
      .fromPredicate((tiers) => tiers.length > 0)(priceTiers)
      .map((tiers) => (tiers.some((tier) => tier.quantity === qty) ? qty : tiers[0].quantity))
      .getOrElseValue(qty);

    return {
      ...state,
      product: {
        ...product,
        priceTiers,
        qty: updatedQty,
        ...magentoProductData,
        mothersDayPromo: promo,
      },
      ui: {
        ...state.ui,
        isUndoRedoEnabled,
        isGooglePhotosEnabled: true,
        isInstagramEnabled,
        isZenDeskEnabled,
      },
      notifications: [...state.notifications, ...notifications],
    };
  }
  const project = await obtainProject(window.project, currentState, getState);

  const [magentoProductData, priceTiers] = await loadMagentoData(project);

  return withMagentoProductData(project, magentoProductData, priceTiers, getState, [
    isUndoRedoEnabled,
    isGooglePhotosEnabled,
    isInstagramEnabled,
    notifications,
    promo,
    isZenDeskEnabled,
  ]);
}

/**
 * loadInitialConfigs is called from asyncInitialState middleware, so we
 * need to be careful what types of things we load from this flow
 */
export async function loadInitialConfigs(getState) {
  const [isUndoRedoEnabled, isGooglePhotosEnabled, isInstagramEnabled, config] = await Promise.all([
    requestUndoRedoEnabled(),
    requestGooglePhotosEnabled(),
    requestInstagramEnabled(),
    requestAdditionalConfig(),
  ]).then(([undoRedoEnabled, googlePhotosEnabled, instagramEnabled, additionalConfig]) => [
    prop(UNDO_REDO_PARAM_KEY)(undoRedoEnabled)
      .map((x) => x === 'true')
      .getOrElseValue(false),
    prop(GOOGLE_PHOTOS_PARAM_KEY)(googlePhotosEnabled)
      .map((x) => x === 'true')
      .getOrElseValue(false),
    prop(INSTAGRAM_PARAM_KEY)(instagramEnabled)
      .map((x) => x === 'true')
      .getOrElseValue(false),
    prop(ADDITIONAL_CONFIG_PARAM_KEY)(additionalConfig)
      .map((x) => {
        try {
          return JSON.parse(x);
        } catch (e) {
          return {};
        }
      })
      .getOrElseValue({}),
  ]);
  const currentState = getState();
  const notifications = prop('notifications')(config)
    .map((x) => x.map(createGenericNotification))
    .getOrElseValue([]);

  return {
    ...currentState,
    ui: {
      ...currentState.ui,
      isUndoRedoEnabled,
      isGooglePhotosEnabled,
      isInstagramEnabled
    },
    notifications: [...currentState.notifications, ...notifications],
  };
}

const composeEnhancers = process.env.NODE_ENV !== 'production' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

// We need outerReducer to replace full state as soon as it loaded
const appReducer = asyncInitialState.outerReducer(
  combineReducers({
    ...reducers,
    asyncInitialState: asyncInitialState.innerReducer,
  })
);

const reducer = rootHistoryReducer(appReducer);

/**
 * Array of middlewares to be applied to the store
 */
const middlewares = [asyncInitialState.middleware(loadStore), thunk, api, actionObserver, logObserver];

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares)
  // Add other enhancers here
);

const storeCreator = (sku, queryParams, productData, qty) => {
  const initialState = defaultStateCreator(sku, queryParams, productData, qty);

  setNewRelicAttribute('userId', initialState.user.flashId);
  setNewRelicAttribute('projectId', initialState.project.id);
  setNewRelicAttribute('product', JSON.stringify(initialState.product));

  return createStore(reducer, initialState, enhancer);
};

export default storeCreator;
