// @flow

import { identity } from 'fp-ts/lib/Identity';
import isEqual from 'lodash.isequal';
import set from 'lodash.set';
import { optionGet, prop } from '../functions';

// Given a predicate and an object, return the Object filtered by that predicate.
export const filterObj = (f: ((key: string, value: any) => boolean)) => (o: Object): Object => (
  Object.entries(o).reduce((result: Object, [key, value]: [string, any]): Object => (
    f(key, value) ? (
      {
        ...result,
        [key]: value,
      }
    ) : result
  ), {})
);

// Takes an array of key-value pairs (e.g. the type returned by Object.entries) and converts it into an object.
export const entriesToObj = (entries: Array<[string, any]>): Object => (
  entries.reduce((result: Object, [key, value]: [string, any]): Object => (
    {
      ...result,
      [key]: value,
    }
  ), {})
);

/* Takes a function that takes a key-value pair and returns a key-value pair, and applies it
   to each of the key-value entries contained in the given object. */
export const mapObj = (f: ([string, any]) => [string, any]) => (obj: Object) => (
  identity.of(obj)
    .map(Object.entries)
    .map(entries => entries.map(f))
    .map(entriesToObj)
    .extract()
);

// Given an object, returns the stringified URI component representation of that object
export const urlEncodeJson = (obj: Object) => encodeURIComponent(JSON.stringify(obj));

// Takes an array of object paths and removes them from the object
export const omit = (filterRules: Array<string>) => (obj: Object) => filterRules
  .reduce((acc, x) => set(acc, x, undefined), Object.assign({}, obj));

// Takes two objects and returns an array of keys that have different values in each object.
export const compareObjects = (o1: Object) => (o2: Object) => (
  Object.entries(o1).reduce((result, [key, value]: [string, any]) => (
    !isEqual(value, o2[key]) ? [...result, key] : result
  ), [])
);

// given a list of properties to check for changes, return an array of tuples of the shape [didChange, value]
export const compareObjectsToTuple = (propsToTest: Array<string>) => (sourceObject: Object) => (newObject: Object): Array<[property, boolean, any]> => {
  const changes = compareObjects(sourceObject)(newObject).filter(x => propsToTest.includes(x));
  return propsToTest.map(x => [x, changes.includes(x), prop(x)(newObject).getOrElseValue(false)]);
};

export const isPathString = (x: String) => x.charAt(0) === '{' && x.charAt(x.length - 1) === '}';

export const isDynamicValue = (x: mixed) => typeof x === 'string' && isPathString(x);

export const getDynamicValue = (x: string) => (y: Object) => optionGet(x.replace('{', '').replace('}', ''))(y).getOrElseValue('');

export const runFnForObject = (objectHandler: Function<Object>) => (x: mixed) => (y: Object) => (typeof x === 'object' ? objectHandler(x)(y) : x);

// Given an object of arbitrary depth that includes values that are paths to the data source object,
// return an object populated with the data from the source object
export const buildObjectFromPaths = (objectWithPaths: Object) => (dataSource: Object): Object => (
  Object.entries(objectWithPaths).reduce((result, [key, value]) => ({
    ...result,
    [key]: isDynamicValue(value) ? getDynamicValue(value)(dataSource) : runFnForObject(buildObjectFromPaths)(value)(dataSource),
  }), {})
);

export const areRequiredFieldsSet = (fields: string[]) => (obj: {[key: string]: any}) => fields.filter(x => !obj[x] || obj[x] === '').length === 0;