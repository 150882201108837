import update from 'immutability-helper';
import findIndex from 'lodash.findindex';
import { optionGet } from '../../helpers/functions';

import {
  DISMISS_NOTIFICATION,
  SEND_NOTIFICATION,
} from './constants';

export const NotificationReducer = (state = [], action) => {
  switch (action.type) {
    case SEND_NOTIFICATION: {
      const newNotification = {
        ...action.payload,
      };

      const index = findIndex(state, notification => notification.key === newNotification.key);

      if (index === -1) {
        return [
          ...state,
          newNotification,
        ];
      }

      return state.map(notification => (notification.key === newNotification.key ? { ...notification, ...newNotification } : notification));
    }

    case DISMISS_NOTIFICATION: {
      const { notificationKey } = action.payload;
      const index = findIndex(state, notification => notification.key === notificationKey);

      if (index === -1) {
        return state;
      }

      return update(state, {
        [index]: {
          $merge: {
            dismissed: true,
          },
        },
      });
    }

    default: {
      return state;
    }
  }
};
