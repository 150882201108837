export const ADD_PHOTOS_CHANGE_STEP = 'ADD_PHOTOS_CHANGE_STEP';
export const ADD_PHOTOS_SET_ACTIVE_BUTTON = 'ADD_PHOTOS_SET_ACTIVE_BUTTON';
export const ADD_TO_STORED_IMAGES = 'ADD_TO_STORED_IMAGES';
export const ADD_PHOTOS_RESET_TITLE_AND_ERROR = 'ADD_PHOTOS_RESET_TITLE_AND_ERROR';
export const ADD_PHOTOS_SET_GALLERY_TITLE = 'ADD_PHOTOS_SET_GALLERY_TITLE';
export const ADD_PHOTOS_SET_GALLERY_TITLE_ERROR = 'ADD_PHOTOS_SET_GALLERY_TITLE_ERROR';
export const ADD_PHOTOS_SET_SELECTED_OPTION = 'ADD_PHOTOS_SET_SELECTED_OPTION';
export const SHOW_UPLOAD_IMAGE_ERROR_MODAL = 'SHOW_UPLOAD_IMAGE_ERROR_MODAL';
export const UI_TEMPORARY_ALBUM = 'UI_TEMPORARY_ALBUM';

export const AB_COOKIE_GOOGLE_PHOTOS = 'AU_EDITOR_AB_GP';
export const PHOTO_SOURCE_ABOUT = 'aboutPhotoSources';
export const ABOUT_PHOTOS_URL = 'https://help.artifactuprising.com/hc/en-us/articles/360002362832-Uploading-Images-via-Instagram-Facebook-or-Dropbox';
