import { option } from 'fp-ts';

import match from './match';
import {
  element,
  textNode,
  withAttributes,
  appendChild,
  finalAppendChild,
  convertSpaces,
} from './DOM';
import { setFontWeight } from './fonts';
import { cssify } from './strings';
import { throwWhen } from './functions';
import { __DEV__ } from './constants';

export const calculateLineHeightWithVerticalAlign = (fontSize, height, verticalAlignment, lineHeight = 1) =>
  match(
    'top', () => parseInt(fontSize, 10),
    'middle', () => (height / 2) + (parseInt(fontSize, 10) / 4),
    'bottom', () => parseInt(fontSize, 10),
    'block-middle', () => lineHeight * parseInt(fontSize, 10),
    match.default, () => throwWhen(
      `verticalAlignment value '${verticalAlignment}' not supported`
    )(__DEV__)(parseInt(fontSize, 10)), // Warn in development, default to baseline.
  )(verticalAlignment.trim());

export const calculateLineHeightDefault = (fontSize, lineHeight) => parseInt(fontSize, 10) * parseFloat(lineHeight || 1);

export const calculateLineHeight = (fontSize, lineHeight, height = null, verticalAlignment = null) =>
  option.fromNullable(verticalAlignment)
    .map(x => calculateLineHeightWithVerticalAlign(fontSize, height, x, lineHeight))
    .getOrElseValue(calculateLineHeightDefault(fontSize, lineHeight));

// Gets the SVG textAnchor value from the textAlign style value
export const textAnchorFromTextAlign = textAlign => match(
  'center', () => 'middle',
  'right', () => 'end',
  match.default, () => 'start',
)(textAlign);

// Gets the x position for text, based on the width and the SVG textAnchor value.
export const alignedXfromTextAnchor = (width, textAnchor) => match(
  'middle', () => width / 2,
  'end', () => width,
  match.default, () => 0,
)(textAnchor);

export const textDX = (spacing, str) => (
  spacing ? (
    str.split('').reduce((dx, char, i) => (
      i === 0 ? '0' : `${dx} ${spacing}`
    ), '')
  ) : (
    ''
  )
);

export const textDXtotal = (spacing, str) => (
  (str.length) * spacing
);

export const buildSVG = (width, height, letterSpacingPX) => formattedStyle => (blocks) => {
  const { lineHeight, fontSize, textAlign, verticalAlign } = formattedStyle;
  const lineHeightPX = calculateLineHeight(fontSize, lineHeight, height, verticalAlign);
  const textAnchor = textAnchorFromTextAlign(textAlign);
  const textX = alignedXfromTextAnchor(width, textAnchor);
  const fontFamily = formattedStyle.fontFamily;
  const viewbox = `${formattedStyle.textAlign === 'left' ? '-20' : '0'} 0 ${width} ${height}`;
  // Create invisible SVG element.
  const svg = withAttributes(
    ['width', width],
    ['height', height],
    ['viewBox', viewbox],
    ['xmlns', 'http://www.w3.org/2000/svg'],
    ['style', cssify({
      width: `${width}px`,
      height: `${height}px`,
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 0,
      backgroundColor: '#000000',
    })],
  )(element('svg'));

  const textWrapper = withAttributes(
    ['x', 0],
    ['y', 0],
    ['style', cssify({
      ...formattedStyle,
      fill: formattedStyle.color,
    })]
  )(element('text'));

  const blank = withAttributes()(element('tspan'));

  const appendToMainSvg = appendChild(document.body)(svg);

  const blockNode = blocks.reduce((accLines, lines, i) => {
    const lineNode = lines.map((line) => {
      const _lineNode = withAttributes(
        ['x', textX],
        ['y', `${lineHeightPX * (i + (blocks.length === 0 ? 0 : 1))}`],
        ['text-anchor', textAnchor],
      )(element('tspan'));

      return line.map((styleLine) => {
        const [text, styles] = styleLine;

        const lineStyleNode = withAttributes(
          ['style', cssify({
            fontWeight: setFontWeight(styles)(fontFamily).getOrElseValue(styles && styles.includes('BOLD') ? 'bold' : 'normal'),
            fontStyle: (styles && styles.includes('ITALIC') ? 'italic' : 'normal'),
            textDecoration: (styles && styles.includes('UNDERLINE') ? 'underline' : 'none'),
          })],
          ['dx', textDX(letterSpacingPX, text)]
        )(element('tspan'));
        return finalAppendChild(lineStyleNode)(textNode(convertSpaces(text)));
      })
        .reduce((styleLines, styleLine) => finalAppendChild(styleLines)(styleLine), _lineNode);
    });

    return {
      element: [...accLines.element, ...lineNode],
      count: accLines.count + lineNode.length,
    };
  }, { element: [blank], count: 0 });

  blockNode.element.reduce((_, x) => appendToMainSvg(textWrapper)(x), blank);

  // Get the bounding box of the SVG text element.
  let textBounds = textWrapper.getBoundingClientRect();
  let finalLines = blocks;

  // While the text is still overflowing vertically, and there are still lines to remove, remove a line and re-measure.
  while (textBounds.height > height && textWrapper.lastChild && blocks.length > 0) {
    // Remove the last line from the DOM
    textWrapper.lastChild.remove();

    // Remove the last line from the lines array.
    finalLines = finalLines.slice(0, -1);

    // Re-measure the text
    textBounds = textWrapper.getBoundingClientRect();
  }

  svg.removeAttribute('style');

  if(verticalAlign === 'block-middle') {
    svg.setAttribute('viewBox', getVerticallyCenteredViewbox(lineHeightPX, finalLines.length, viewbox));
  } else if (verticalAlign === 'bottom') {
    svg.setAttribute('viewBox', getBottomAlignedViewbox(lineHeightPX, finalLines.length, viewbox));
  }

  const finalSvg = svg.outerHTML.replace(/&nbsp;/g, '\u00A0');
  option.fromNullable(document.body).map(x => x.removeChild(svg));

  return [finalSvg, finalLines];
};

export const getVerticallyCenteredViewbox = (lineHeight, lineCount, viewbox) => {
  // eslint-disable-next-line
  const [vbX, vbY, vbWidth, vbHeight] = viewbox.split(' ');
  const spaceConsumedByText = lineHeight * lineCount + (lineHeight / 2);
  const halfOfUnconsumedSpace = (vbHeight - spaceConsumedByText) / -2; // divide by -2 to move viewbox up for centering
  return  `${vbX} ${halfOfUnconsumedSpace} ${vbWidth} ${vbHeight}`;
}

// Aligns text to the bottom of the SVG's viewbox (See Wedding Layflat > Namesake Cover Design)
export const getBottomAlignedViewbox = (lineHeight, lineCount, viewbox) => {
  // eslint-disable-next-line
  const [vbX, vbY, vbWidth, vbHeight] = viewbox.split(' ');
  let verticalOffset = -vbHeight + 30 // adding some padding so letters like 'Q' do not get cut off at the bottom
  for (let i = 0; i < lineCount; i++) {
    verticalOffset += lineHeight
  }
  return  `${vbX} ${verticalOffset} ${vbWidth} ${vbHeight}`;
}