// @flow

/* eslint-disable dot-notation */
/* eslint-disable no-use-before-define */

import { type User, type Photo } from '../types/photo';
import { GalleryImage } from 'au-js-sdk/lib/models/GalleryImage';
import { isGallery } from 'au-js-sdk/lib/models/Gallery';
import { debugLog } from '../helpers/DOM';
import { waitForAutoOrientedImage } from './apiHelpers';

const uploadsBaseUrl = process.env.REACT_APP_CRUD_CORE_BASE_URL || '';
const API_URL = `${uploadsBaseUrl}/api/galleries/images`;

export const buildQueryStringForSortOp = (sortBy: string) => {
  switch (sortBy) {
    case 'CREATED_AT_ASC': {
      return '&sortBy=CREATED_AT&asc=true';
    }
    case 'DATE_TAKEN_DESC': {
      return '&sortBy=DATE_TAKEN&asc=false';
    }
    case 'DATE_TAKEN_ASC': {
      return '&sortBy=DATE_TAKEN&asc=true';
    }
    case 'FILE_NAME_ASC': {
      return '&sortBy=FILENAME&asc=true';
    }
    default: {
      return '&sortBy=CREATED_AT&asc=false';
    }
  }
};

export const getGalleryImages = async (user: User, galleryId: string, page: Number, sortBy: string): Promise<GalleryImage[]> => {
  const queryStr = `?flashId=${user.flashId}&galleryId=${galleryId}&page=${page && page.toString()}${buildQueryStringForSortOp(sortBy)}`;
  const params = buildRequestParams(user.flashToken, 'GET');
  const response = await fetch(API_URL + queryStr, params);
  const responseJson = await response.json();
  return responseJson && responseJson.length
    ? responseJson.filter((gi) => gi.metadata.width && gi.metadata.height).map((gi) => hydrateGalleryImageType(gi))
    : [];
};

const buildRequestParams = (flashToken: string, method: 'GET' | 'POST' | 'PATCH' | 'DELETE', body?: any) => {
  const params = {
    headers: {
      Origin: process.env.REACT_APP_PUBLIC_URL,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${flashToken}`,
    },
    method,
    mode: 'cors',
    body: body ? JSON.stringify(body) : undefined,
  };

  return params;
};

const hydrateGalleryImageType = (obj: any): GalleryImage => {
  if (isGallery(obj)) {
    window.newrelic.addPageAction('s3_direct_upload_error', {
      error: 'Invalid gallery image object.',
      action: 'hydrateGalleryImageType',
      responseJson: JSON.stringify(obj),
    });
    throw new Error('Invalid gallery image object.');
  }
  const galleryImg: GalleryImage = {
    id: obj.id,
    mediaId: obj.id, // TODO: REMOVE AFTER SPLIT AT 100%
    galleryId: obj.galleryId,
    createdBy: obj.createdBy, // user flashId
    createdAt: new Date(obj.createdAt),
    source: obj.source,
    lastModifiedAt: new Date(obj.lastModifiedAt),
    autoOriented: obj.autoOriented,
    metadata: obj.metadata,
  };
  return galleryImg;
};

export const autoOrientImage = async (user: User, image: Photo) => {
  const reqBody = {
    imageId: image.id,
    flashId: user.flashId,
    galleryId: image.galleryId,
  };
  const params = buildRequestParams(user.flashToken, 'POST', reqBody);
  const response = await fetch(`${API_URL}/auto-orient`, params);
  if (response.status !== 202) {
    debugLog(response);
    throw new Error(`Post to auto orient failed with status code ${response.status}`);
  }

  const autoOrientedGalleryImage = await waitForAutoOrientedImage(user, image);
  return autoOrientedGalleryImage;
};
