import uuid4 from 'uuid/v4';
import {
  MAGENTO_USER_ME_FAILURE,
  MAGENTO_USER_ME_SUCCESS,
  SET_USER_DATA,
  USER_SET_FLASH_ID,
  USER_SET_FLASH_TOKEN,
  MAGENTO_ADMIN_ME_REQUEST,
  MAGENTO_ADMIN_ME_SUCCESS,
  MAGENTO_ADMIN_ME_FAILURE,
  ADD_USER_ADDRESS,
  EDIT_USER_ADDRESS,
} from './constants';
import { RETURN_ADDRESS_STATE_KEY } from '../envelopeAddressing/constants';
import { closeModal } from '../ui/modal/actions';
import { setEnvelopeAddressingData } from '../envelopeAddressing/actions';
import { requestAddUserAddress } from '../../services/magento';
import { getSplitUserKey, initSplitIO } from '../../services/splitio';
import {
  sendAnalyticsForUserEditAddress,
  sendAnalyticsForAddAddress,
  itlyIdentify, itlyProjectStartedEvent,
} from '../analytics/actions';
import { createProjectForUser } from '../../services/projectApi';
import { setNewProjectData, manualSave } from '../project/actions';
import { getFlashIdFromCustomerAttrs, getFlashTokenFromCustomerAttrs } from '../../helpers/user';
import { fetchGalleries } from '../v2/galleries/actions';
import { debugLog } from '../../helpers/DOM';
import { MAGENTO_USER_ME_ENDPOINT } from '../../constants/magento';
import { getAUAdminMeUrl, getFastlyBaseUrl } from '../../helpers/urls';
import find from 'lodash.find';

export function setUserFlashId(flashId) {
  return {
    type: USER_SET_FLASH_ID,
    payload: {
      flashId,
    },
  };
}

export function setUserFlashToken(flashToken) {
  return {
    type: USER_SET_FLASH_TOKEN,
    payload: {
      flashToken,
    },
  };
}

const userRequestSuccessAction = (customerJson) => ({
  type: MAGENTO_USER_ME_SUCCESS,
  payload: customerJson,
});

const userRequestFailureAction = () => ({
  type: MAGENTO_USER_ME_FAILURE,
});

export function getUserDataFromWebStore() {
  return (dispatch, getState) => {
    const { user } = getState();

    if (user.admin) {
      dispatch({
        type: MAGENTO_ADMIN_ME_REQUEST,
      });

      return fetch(getAUAdminMeUrl(), {
        method: 'GET',
        // credentials: 'include',
        headers: {
          Authorization: `Bearer ${user.flashToken}`,
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
        .then((response) => response.json())
        .then((adminUser) => {
          dispatch({
            type: MAGENTO_ADMIN_ME_SUCCESS,
            payload: adminUser,
          });

          dispatch(fetchGalleries());
        })
        .catch((_) => {
          dispatch({
            type: MAGENTO_ADMIN_ME_FAILURE,
          });
        });
    }

    fetch(MAGENTO_USER_ME_ENDPOINT, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then((response) => response.json())
      .then((customer) => {
        if (!customer || !customer.id) {
          if (customer.message) {
            throw new Error(customer.message);
          }
          throw new Error('Unable to load customer');
        }
        dispatch(userRequestSuccessAction(customer));
        dispatch(itlyIdentify(customer));

        const customerAttrs = customer.custom_attributes || [];
        const flashId = getFlashIdFromCustomerAttrs(customerAttrs);
        const flashToken = getFlashTokenFromCustomerAttrs(customerAttrs);

        initSplitIO(getSplitUserKey(), customer.email.toString());

        dispatch(fetchGalleries());

        if (user.anonymous) {
          if (flashId && flashToken) {
            createProjectForUser(flashId, flashToken).then((response) => {
              dispatch(setNewProjectData(response));
              dispatch(itlyProjectStartedEvent());
              if (window.history && window.history.pushState) {
                const updatedProjectUrl = `${window.origin}${window.location.pathname}` +
                  `?projectId=${response.projectId}&flashId=${flashId}&flashToken=${flashToken}`;
                window.history.replaceState({ path: updatedProjectUrl }, '', updatedProjectUrl);
              }

              dispatch(fetchGalleries());

              dispatch(manualSave());
            })
              .catch((e) => {
                window.newrelic.noticeError(e);
              });
          }
        }
      })
      .catch((e) => {
        // MAGENTO_ADMIN_ME_REQUEST

        const pageActionData = {
          exceptionMessage: (e && e.message) ? e.message : e,
        };
        window.newrelic.addPageAction(
          'auUserRedirectedOnError',
          pageActionData
        );
        redirectToGatsbyLogin();
        dispatch(userRequestFailureAction()); // will never happen...
      });
  };
}

export const redirectToGatsbyLogin = () => {
  const { href } = window.location;
  const baseUrl = process.env.REACT_APP_FASTLY_BASE_URL;
  const target = `${baseUrl}/login?redirectTo=${encodeURIComponent(href)}`;

  window.location.replace(target);
};

export const setUserData = (user) => (dispatch) => {
  dispatch({
    type: SET_USER_DATA,
    payload: {
      user,
    },
  });

  // If logging in from an unauthorized state, we should re-initialize
  // the split client to ensure we are delivering the correct split for the user
  debugLog('Login detected, re-initializing split.io client');

  const splitClient = initSplitIO(user.id.toString(), user.email);

  dispatch(fetchGalleries());

  return {
    type: SET_USER_DATA,
    payload: {
      user,
    },
  };
};

const addUserAddress = (payload) => ({
  type: ADD_USER_ADDRESS,
  payload,
});

export const addUserAddressMagento = (
  _address,
  sendAnalytics: boolean = false
) => (dispatch, getState) => {
  const address = {
    ..._address,
    prefix: '',
    suffix: '',
    middlename: '',
    region_id: _address.region.region_id,
    id: _address.id || `LOCAL/${uuid4()}`,
  };

  requestAddUserAddress(getState().user, address)
    .then((x) => {
      const flashId = find(x.custom_attributes, { attribute_code: 'flash_id' }).value;
      const flashToken = find(x.custom_attributes, { attribute_code: 'flash_auth_token' }).value;
      validateIncomingFlashAuth(getState().user, flashId, flashToken);

      if (sendAnalytics) {
        dispatch(sendAnalyticsForAddAddress());
      }
      dispatch(addUserAddress(x.addresses[x.addresses.length - 1]));
      dispatch(
        setEnvelopeAddressingData(RETURN_ADDRESS_STATE_KEY)(
          x.addresses[x.addresses.length - 1]
        )
      );
    })
    .catch(window.newrelic.noticeError);
  dispatch(closeModal());
};

const editUserAddress = (address) => ({
  type: EDIT_USER_ADDRESS,
  payload: address,
});

export const editUserAddressMagento = (
  _address,
  sendAnalytics: boolean = false
) => (dispatch, getState) => {
  const address = {
    ..._address,
    prefix: '',
    suffix: '',
    middlename: '',
    region_id: _address.region.region_id,
    id: _address.id || uuid4(),
  };

  dispatch(editUserAddress(address));
  requestAddUserAddress(getState().user, address, true)
    .then((x) => {
      const flashId = find(x.custom_attributes, { attribute_code: 'flash_id' }).value;
      const flashToken = find(x.custom_attributes, { attribute_code: 'flash_auth_token' }).value;
      validateIncomingFlashAuth(getState().user, flashId, flashToken);

      if (sendAnalytics) {
        dispatch(sendAnalyticsForUserEditAddress());
      }
    })
    .catch(window.newrelic.noticeError);
  dispatch(closeModal());
};

export const validateIncomingFlashAuth = (state, flashId = null, flashToken = null) => {
  try {
    if (state.flashId && flashId && state.flashId !== flashId) {
      throw new Error('Incoming flash id does not match the one in state!');
    }

    if (state.flashToken && flashToken && state.flashToken !== flashToken) {
      throw new Error('Incoming flash token does not match the one in state!');
    }
  } catch (e) {
    window.newrelic.addPageAction('V1 editor - flash auth mismatch', {
      'state.flashId': state.flashId,
      'state.flashToken': state.flashToken,
      'action.flashId': flashId,
      'action.flashToken': flashToken,
    });
    window.newrelic.noticeError(e);

    // This is here so we can ensure the requests to NR get out before the refresh happens since some browsers
    // cancel in flight requests (safari)
    setTimeout(() => {
      window.location.href = `${getFastlyBaseUrl()}/customer/account/logout`;
    }, 300);
  }
};
