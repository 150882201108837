// @flow
import type { Dispatch, Action, GetState } from 'redux';
import {
  UNDOABLE_USER_ACTION_START,
  UNDOABLE_USER_ACTION_END,
  UNDO_USER_ACTION,
  REDO_USER_ACTION,
  ADD_HISTORY_ITEM,
  UNDOABLE_USER_ACTION_UPDATE,
  UNDOABLE_CHANGE_ATTRIBUTE,
  UNDOABLE_REMOVE_HISTORY_ITEM,
  UNDOABLE_USER_ACTION_ADD_PATCHES_TO_APPLY,
  UNDOABLE_CHANGE_QUANTITY,
} from './constants';
import uuid4 from 'uuid/v4';
import { type PatchOperation, createUndoRedoPatch, applyPatch } from '../../helpers/store';
import type { UndoableActions, UndoableActionStartAction, UndoableActionEndAction } from './constants';
import { manualSave } from '../project/actions';
import match from '../../helpers/match';
import { updateTemplateByAttributes } from '../template/actions';
import { compareObjects } from '../../helpers/objects';
import { setCurrentPage } from '../ui/actions';
import { __PRODUCTION__, __TEST__ } from '../../helpers/constants';
import { findIndex, findFirst } from 'fp-ts/lib/Array';
import { fromPredicate as optionFromPredicate } from 'fp-ts/lib/Option';
import { setChildProductData } from '../product/actions';
import { ATTRIBUTES_THAT_AFFECT_COVER } from '../../helpers/product';

type UndoableStartAction = {
  type: UndoableActionStartAction,
  payload: {
    userAction: UndoableActions,
    actionId: string,
  }
}

type UndoableEndAction = {
  type: UndoableActionEndAction,
  payload: {
    actionId: string,
  }
}

/**
 * Create history item with default values
 */
export const createHistoryItem = (id: string, pageId: ?string = null) => (patch: PatchOperation = []) => (userAction: UndoableActions): HistoryItem => ({
  id,
  userAction,
  patch,
  pageId,
  changePatch: [],
  patchesToApply: [],
});

/**
 * Create action that indicates start of user action for history middleware
 */
const startUndoableUserAction = (): UndoableStartAction => ({
  type: UNDOABLE_USER_ACTION_START,
});

/**
 * Create action that indicates end of user action for history middleware
 */
const endUndoableUserAction = (): UndoableEndAction => ({
  type: UNDOABLE_USER_ACTION_END,
});

// Promisify the start of an undoable action
const startUndoable = () => (dispatch: Dispatch) => Promise.resolve(dispatch(startUndoableUserAction()));

// Promisify the end of an undoable action
const endUndoable = () => (dispatch: Dispatch) => Promise.resolve(dispatch(endUndoableUserAction()));

const undoUserActionCreator = () => ({
  type: UNDO_USER_ACTION,
});

const redoUserActionCreator = () => ({
  type: REDO_USER_ACTION,
});

// Action creator to add a history item to state given id, pageId and a patch
export const insertHistoryItemAction = (id: string, pageId: ?string = null) => (patch: PatchOperation) => (userAction: UndoableActions) => ({
  type: ADD_HISTORY_ITEM,
  payload: {
    item: createHistoryItem(id, pageId)(patch)(userAction),
  },
});

// Paths that the generated diff should ignore
const filteredStatePaths = [
  'history',
  'ui.modal',
  'ui.currentAlbum',
  'ui.partialSelection',
  'template',
  'product.childRequestStatus',
  'zendesk',
  'userPhotos',
  'userAlbums',
  'project.saveState',
  'project.uploadStatus',
  'project.lockState',
  'ui.drawerGridScrollCache',
  'notifications',
  'galleries'
];

// After applying an undo/redo item, this applies any side-effects needed
export const aroundHistoryChange = (userAction: UndoableActions, stateBefore: Object) => (dispatch: Function, getState: Function) => {
  match(
    UNDO_USER_ACTION, () => {
      dispatch(updateTemplateByAttributes());
      dispatch(setChildProductData(getState().product, getState().product.attributes));

      const { product } = getState();
      const { product: oldProduct } = stateBefore;
      const didChangedAttributesAffectCover = compareObjects(product.attributes)(oldProduct.attributes)
        .filter(x => ATTRIBUTES_THAT_AFFECT_COVER.includes(x));

      if (didChangedAttributesAffectCover.length > 0) {
        dispatch(setCurrentPage(getState().project.pages[0].id));
      }
    },
    REDO_USER_ACTION, () => {
      dispatch(updateTemplateByAttributes());
      dispatch(setChildProductData(getState().product, getState().product.attributes));

      const { product } = getState();
      const { product: oldProduct } = stateBefore;
      const didChangedAttributesAffectCover = compareObjects(product.attributes)(oldProduct.attributes)
        .filter(x => ATTRIBUTES_THAT_AFFECT_COVER.includes(x));

      if (didChangedAttributesAffectCover.length > 0) {
        dispatch(setCurrentPage(getState().project.pages[0].id));
      }
    },
    UNDOABLE_CHANGE_ATTRIBUTE, () => {
      dispatch(updateTemplateByAttributes());
      dispatch(setChildProductData(getState().product, getState().product.attributes));

      const { product } = getState();
      const { product: oldProduct } = stateBefore;
      const didChangedAttributesAffectCover = compareObjects(product.attributes)(oldProduct.attributes)
        .filter(x => ATTRIBUTES_THAT_AFFECT_COVER.includes(x));

      if (didChangedAttributesAffectCover.length > 0) {
        dispatch(setCurrentPage(getState().project.pages[0].id));
      }
    },
    UNDOABLE_CHANGE_QUANTITY, () => {
      dispatch(updateTemplateByAttributes());
      dispatch(setChildProductData(getState().product, getState().product.attributes));
    },
    match.default, () => {
      const { product } = getState();
      const { product: oldProduct } = stateBefore;
      const changedKeys = compareObjects(product)(oldProduct).filter(x => x !== 'design');
      if (changedKeys.length) {
        dispatch(updateTemplateByAttributes());
        dispatch(setChildProductData(getState().product, getState().product.attributes));
      }
    },
  )(userAction);
};

// Add patch and change patch to undoable item
const updateUndoableActionWithPatch = (undoableId: string) => (patch: PatchOperation) => (changes: PatchOperation) => ({
  type: UNDOABLE_USER_ACTION_UPDATE,
  payload: {
    undoableId,
    patch,
    changes,
  },
});

// Notify all undo items that there is a newly completed change patch that will need to be applied
const updateInflightWithPatchesToApply = (undoableIdsWithPatch: Array<string>) => ({
  type: UNDOABLE_USER_ACTION_ADD_PATCHES_TO_APPLY,
  payload: {
    patchesToApply: undoableIdsWithPatch,
  },
});

// Action to remove an undoable item from state
export const removeUndoableItem = (undoableId: string) => ({
  type: UNDOABLE_REMOVE_HISTORY_ITEM,
  payload: {
    undoableId,
  },
});

/**
 * Given an undoable item id and an action to execute, update the undoable item's patch with the newly updatee state
 */
export const updateUndoableItem = (undoableId: string) => (action: Function) => (dispatch: Function, getState: Function) => {
  const stateAtActionStart = getState();
  dispatch(action());
  const patch = createUndoRedoPatch(filteredStatePaths)(getState())(stateAtActionStart);
  dispatch(updateUndoableActionWithPatch(undoableId)(patch));
};

/**
 * Given an item ID and an array of items, find the index of an item in state
 */
export const getHistoryIndex = items => itemId => findIndex(x => x.id === itemId)(items);

/**
 * Given global state and a history item id, return the history item associated to that ID.
 */
const getUndoableItemFromState = (state: Object) => (itemId: string): HistoryItem => findFirst(x => x.id === itemId)([...state.history.past, ...state.history.future]).getOrElse(() => {
  throw new Error('Could not find undoable item in state');
});

/**
 * For all undo items, we need to store a patch that would undo the change as well as a patch that is the change itself.
 * We need the change patch to synchronize changes while changes might be in-flight
 */
const getUndoAndChangePatches = (currentState: Object) => (originalState: Object) => ([
  createUndoRedoPatch(filteredStatePaths)(originalState)(currentState),
  createUndoRedoPatch(filteredStatePaths)(currentState)(originalState),
]);

/**
 * undoableUserAction wraps a redux action with calls to start/end history collection.
 *
 * Any action that performs asynchronous operations should return a promise that resolves
 * upon completion. See autoFillPhotos in src/store/userAlbums/actions.js for example.
 */
export const undoableUserAction = (userActionType: UndoableActions, pageId?: string | boolean) => (action: Action) => (dispatch: Dispatch, getState: GetState) => {
  const stateBefore = getState();
  // Don't store history for anonymous users
  if (stateBefore.user.anonymous || !stateBefore.ui.isUndoRedoEnabled) {
    return Promise.resolve(dispatch(typeof action === 'function' ? action() : action)).catch((e) => {
      window.newrelic.noticeError(e);
    });
  }

  const undoableActionId = uuid4();
  return dispatch(startUndoable()).then(() => {
    const historyPageId = pageId === true ? stateBefore.ui.currentPage : pageId;
    dispatch(insertHistoryItemAction(undoableActionId, historyPageId)([])(userActionType));
    // Synchronous and plain object  actions will work just fine here
    return dispatch(typeof action === 'function' ? action(undoableActionId) : action);
  })
    .then(() => {
      // Get synchronized state (catch up with anything that may have finished while in flight)
      const currentState = getState();
      const currentUndoableItem = getUndoableItemFromState(currentState)(undoableActionId);
      return currentUndoableItem.patchesToApply.reduce((state, itemId) => {
        const undoableItemWithPatch = getUndoableItemFromState(currentState)(itemId);
        return applyPatch(undoableItemWithPatch.changePatch)(state);
      }, stateBefore);
    })
    .then((baseState) => {
      // Determine if the history item that resolved is the most recent thing that happened
      const currentState = getState();
      return getHistoryIndex(currentState.history.past)(undoableActionId)
        .chain(optionFromPredicate(x => x > 0)) // item was not the most recent
        .map((pastIndex) => {
          const itemsSincePatch = getState().history.past.slice(0, pastIndex); // All items that are more recent than the one in question
          const newState = itemsSincePatch.reduce((state, undoableItem) => applyPatch(undoableItem.patch)(state), currentState);
          const [changePatch, undoPatch] = getUndoAndChangePatches(newState)(baseState);
          dispatch(updateInflightWithPatchesToApply([undoableActionId]));
          return dispatch(updateUndoableActionWithPatch(undoableActionId)(undoPatch)(changePatch));
        })
        .getOrElse(() => {
          const [changePatch, undoPatch] = getUndoAndChangePatches(currentState)(baseState);
          dispatch(updateUndoableActionWithPatch(undoableActionId)(undoPatch)(changePatch));
        });
    })
    .then(() => {
      // Save and wrap-up
      dispatch(manualSave());
      return dispatch(endUndoable());
    })
    .catch(
      (e) => {
        if (!__PRODUCTION__ && !__TEST__) {
          throw e;
        }
        window.newrelic.noticeError(e);

        // @todo figure out what we should do in the event that an exception occurs during action execution
        // For now, this just ends the undoable grouping.
        dispatch(manualSave());
        return dispatch(endUndoable());
      }
    );
};

/**
 * Action used for undoing/redoing a history item.
 * State is eagerly patched to determine what the diff would be once state is actually updated. This is used
 * to create a history item in the reverse direction.
 * @todo Look into memoizing apply patch
 */
const applyHistoryItem = (direction: 'past' | 'future') => () => (dispatch: Function, getState: Function) => {
  const state = getState();
  const newState = applyPatch(state.history[direction][0].patch)(state);
  const patch = createUndoRedoPatch(filteredStatePaths)(newState)(state);
  const { userAction, pageId } = state.history[direction][0];
  dispatch(insertHistoryItemAction(uuid4(), pageId)(patch)(direction === 'past' ? UNDO_USER_ACTION : REDO_USER_ACTION));
  dispatch(direction === 'past' ? undoUserActionCreator() : redoUserActionCreator());
  dispatch(aroundHistoryChange(userAction, state));
  dispatch(manualSave());
  if (pageId && pageId !== null) {
    dispatch(setCurrentPage(pageId));
  }
};

export const undoUserAction = applyHistoryItem('past');
export const redoUserAction = applyHistoryItem('future');
