import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux';
import {getPDFPreview} from '../../services/pdf-service'
import PropTypes from 'prop-types';
import Icon from '../Presentational/Icon'
import Loadable from 'react-loadable'
import GenericOverlay from '../Presentational/GenericOverlay'

const LoadingViewer = () => (
  <div className="inner-modal-content">
    <p>Hang tight while we grab your proof...</p>
    <Icon icon="loading" color="#000" size="medium" className="margin--top1" animation="rotate" />
  </div>
);

const LoadableViewer = Loadable({
  loader: () => import('./PdfProofViewer'),
  render(loaded, props) {
    let Viewer = loaded.default;
    const {previewBlob} = props
    if(previewBlob === null) {
      return LoadingViewer();
    }
    return <Viewer blob={previewBlob} loading={LoadingViewer()} />;
  },
  loading: LoadingViewer
})

const PdfErrorModalContent = (
  <div className="inner-modal-content">
    <p>Uh-oh, there was an error loading your proof.</p>
    <p>Make sure the current page is not empty and try again.</p>
  </div>
);

class PdfPreviewModal extends React.Component {
  static propTypes = {
    flashId: PropTypes.string.isRequired,
    flashToken: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    toggleVisibility: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.modalElement = document.createElement('div');
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      previewBlob: null,
      pdfError: false,
    };
  }

  componentDidMount() {
    document.body.appendChild(this.modalElement);
    getPDFPreview(this.props.flashId, this.props.flashToken, this.props.projectId, this.props.pageId)
      .then(response => response.blob()
        .then(blob => {
          this.setState({previewBlob: blob});
        })
      ).catch(e => {
        window.newrelic.noticeError(e);
        this.setState({pdfError: true});
    });
  }

  componentWillUnmount() {
    document.body.removeChild(this.modalElement);
    if(this.state.previewBlob) {
      const url = (window.URL || window.webkitURL);
      url.revokeObjectURL(this.state.previewBlob);
    }
  }

  closeModal() {
    if(typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
    this.props.toggleVisibility();
  }

  render() {
    const { pdfError } = this.state;
    const PreviewModal = (
      <GenericOverlay className="generic-overlay--dynamic-width" onClickOutside={this.closeModal}>
          {
            !pdfError ?
              <LoadableViewer previewBlob={this.state.previewBlob} />
              : PdfErrorModalContent
          }
          <button onClick={this.closeModal} className="generic-overlay__close">&times;</button>
      </GenericOverlay>
    );
    return ReactDOM.createPortal(
      PreviewModal,
      this.modalElement
    );
  }

}

const mapStateToProps = (state, ownProps) => ({
  pageId: ownProps.pageId ? ownProps.pageId: state.ui.currentPage,
  flashId: state.user.flashId,
  flashToken: state.user.flashToken,
  projectId: state.project.id,
})

export default connect(mapStateToProps)(PdfPreviewModal);
