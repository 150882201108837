import {
  SET_FACEBOOK_USER,
  SET_FACEBOOK_ALBUMS,
  SET_FACEBOOK_ACTIVE,
  SET_FB_MAIN_ALBUM,
  SET_FB_VIEWING_ALBUM,
  SET_FACEBOOK_NEXT_URL,
  SET_FACEBOOK_REQUESTING,
  ADD_FACEBOOK_PHOTOS,
  UNSET_FACEBOOK_PHOTOS,
} from './constants';

const INITIAL_STATE = {
  facebookAccessToken: '',
  facebookUserID: '',
  facebookAlbums: [],
  facebookProfilePic: '',
  facebookMainAlbum: null,
  facebookViewingAlbum: false,
  facebookActive: false,
  facebookRequesting: false,
  images: [],
  nextUrl: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_FACEBOOK_PHOTOS: {
      const { images } = state;
      const newImages = action.payload.map(d => ({ ...d.images[0], id: d.id }));
      const combined = [...images, ...newImages];
      return { ...state, images: combined, facebookRequesting: false };
    }

    case UNSET_FACEBOOK_PHOTOS: {
      const images = action.payload;
      return { ...state, images };
    }

    case SET_FACEBOOK_USER: {
      const { facebookAccessToken, facebookUserID } = action.payload.data;
      return { ...state, facebookUserID, facebookAccessToken };
    }

    case SET_FB_MAIN_ALBUM: {
      return { ...state, facebookMainAlbum: action.payload };
    }

    case SET_FB_VIEWING_ALBUM: {
      return { ...state, facebookViewingAlbum: action.payload };
    }

    case SET_FACEBOOK_ALBUMS: {
      return { ...state, facebookAlbums: action.payload };
    }

    case SET_FACEBOOK_ACTIVE: {
      return { ...state, facebookActive: action.payload };
    }

    case SET_FACEBOOK_NEXT_URL: {
      return { ...state, nextUrl: action.payload };
    }

    case SET_FACEBOOK_REQUESTING: {
      return { ...state, facebookRequesting: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const facebookStateFilter = () => INITIAL_STATE;
