import { Style, StyleRestrictions } from './style';

export type Category = 'calendars' | 'cards' | 'prints' | 'books' | 'spread books' | 'frames' | 'other' | 'folded_cards'
export type LayerType = 'user_photo' | 'editable_text' | 'design_asset' | 'artifact';
export type Bleed = number | [number] | [number, number] | [number, number, number] | [number, number, number, number];
export type Bleed4 = [number, number, number, number];

export type Design = {
  id: string,
  allowedPages: string,
  default: boolean,
  pretty_name: string,
  surfaceId?: string,
  layers: Array<string>,
  isComposite?: boolean,
  partialDesignIds?: Array<string>,
  tags?: Array<string>,
};

export type DesignGroup = {
  default: string,
  id: string,
  designs: Array<String> | Array<Design>,
};

export type Tags = {
  [key: string]: string
};

export type LayerDefaultData = {
  content: string,
  style: Style,
  styleRestrictions: StyleRestrictions,
  tags: Tags
};

export type Layer = {
  id: string,
  x: number,
  y: number,
  width: number,
  height: number,
  placement?: string,
  type: LayerType,
  zIndex: number,
  rotate: number,
  printType: string,
  userCanMove?: boolean,
  userCanResize?: boolean,
  data?: {
    style?: Object,
    content?: string,
    visibleText?: string,
    tags?: Tags,
    userPhotoId?: string;
  },
  defaultData?: LayerDefaultData
};

export type Surface = {
  id?: string,
  min_resolution: number,
  x: number,
  y: number,
  bleed: Bleed,
  safeOffset?: Bleed,
  width: number,
  height: number,
  supportsLayerTranslation?: boolean,
};

export type TemplatePages = {
  max: number,
  min: number,
};

export type TemplateSize = {
  width: number,
  height: number,
};

export type Template = {
  cssClass: string,
  size?: TemplateSize,
  pages: TemplatePages,
  surface: Surface,
  additionalSurfaces?: Array<Surface>,
  layers: Array<Layer>,
  designs: Array<Design>,
  mask?: {
    id: string,
    name: string,
    layers: Array<Layer>,
    surface: Surface,
  },
  envelope?: {
    id: string,
    name: string,
    layers: Array<Layer>,
    surface: Surface,
  }
};

export type ProductData = {
  name: string,
  category: Category,
  nameScheme: string,
  attributeRestrictions?: Object,
  attributes?: Object,
  templates: { [string]: Template },
};

export const spineTags = [
  'SpineFont',
  'SpineFontSize',
  'SpinePosition',
  'SpineText',
];
