import {
  UI_MODAL_CLOSE,
  UI_MODAL_SET_STATUS,
} from './constants';

export const closeModal = () => ({
  type: UI_MODAL_CLOSE,
});

// showModal :: String -> {type: String, payload: {status: String, title: String}}
export const showModal = (status, title, meta, data) => ({
  type: UI_MODAL_SET_STATUS,
  payload: { status, title, meta, data },
});

