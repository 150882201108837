// @flow
import store from 'store';

const ENV = process.env.REACT_APP_ZENDESK_ENV;
const PREFIX = 'z.wdgt.';

export const get = (key: string) => {
  if (!store.enabled) {
    return;
  }
  return store.get(PREFIX + key);
}

export const set = (key: string, value: any) => {
  if (!store.enabled) {
    return;
  }
  return store.set(PREFIX + key, value);
}

export function log() {
	if (ENV === 'dev') {
		console.log.apply(console, arguments); // eslint-disable-line no-console
	}
}

export function isAgent(nick){
	return nick.startsWith('agent:');
}

export function isTrigger(nick) {
	return nick.startsWith('agent:trigger');
}

export default {
  isAgent,
  isTrigger,
  log,
  get,
  set,
};
