// @flow

import match from '../match';
import { round } from '../numbers';
import photo, { type PhotoModifications } from '../../types/photo';
import { Layer, Surface } from '../../types/templates';
import { smallBreakpoint } from '../breakpoints';
import { urlEncodeJson } from '../objects';
import { option } from 'fp-ts';
import { type Option } from 'fp-ts/lib/Option';
import { optionGet, optionFind } from '../functions';
import has from '../has';
import { isUserPhotoLayer } from '../layers';
import { getLayerFromPage } from '../../store/project/pages/selectors';
import uuid4 from 'uuid/v4';

const WINDOW = typeof window !== 'undefined' ? window : {};

export const IMAGE_URL_BASE: string = process.env.REACT_APP_S3_IMAGE_BASE_URL || '';
export const FLASH_URL_BASE = process.env.REACT_APP_FLASH_BASE_URL || ''

export const thumbnailSizes = {
  small: 300,
  medium: 600,
  large: 1200,
};

export const getDimensionsFromUrl = (url: string): Promise<Object> => (
  new Promise((resolve: Function, reject: Function) => {
    const img = new Image();

    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = () => reject();

    img.src = url;
  })
);

export const getFileDimensions = (file: Object) => (
  getDimensionsFromUrl(window.URL.createObjectURL(file))
);

export const thumbnailSuffix = match(
  'small', () => `_original?width=${thumbnailSizes.small}&auto=webp`,
  'medium', () => `_original?width=${thumbnailSizes.medium}&auto=webp`,
  'large', () => `_original?width=${thumbnailSizes.large}&auto=webp`,
  match.default, () => '',
);

export const photoModsThumbnailSuffix = match(
  'small', () => `_${thumbnailSizes.small}`,
  'medium', () => `_${thumbnailSizes.medium}`,
  'large', () => `_${thumbnailSizes.large}`,
  match.default, () => '',
);

export const photoModSuffix = (mods: PhotoModifications) => {
  if (!mods) {
    return ''
  }

  let photoModObj = { brightness: mods.brightness }

  if (mods.filter === "BlackAndWhite") {
    photoModObj = { ...photoModObj, grayscaleFilter: true }
  }

  return `?mod=${urlEncodeJson(photoModObj)}`
}

export const hasValidPhotoMods = (photoMods: Object): boolean => {
  if (Array.isArray(photoMods) || !Object.keys(photoMods)) {
    return false
  }
  return (
    (photoMods.brightness != undefined && photoMods.brightness !== 100) ||
    (photoMods.filter != undefined && photoMods.filter !== 'Original')
  );
}

export const getImageURL = (flashId: string, photo: Object, size: string = 'small', photoMods?: Object) => {
  const { mediaId, createdAt } = photo;

  let imgBaseUrl = `${IMAGE_URL_BASE}/${flashId}/${mediaId}${thumbnailSuffix(size)}`;

  if (createdAt && typeof createdAt === `string`) {
    const autoOrientFixDate = new Date('12/06/2020');
    const imageUploadDate = new Date(createdAt);

    // If an image was uploaded before we started auto-orienting images,
    // add a cache buster to ensure the user is seeing the correct image
    if (imageUploadDate.getTime() < autoOrientFixDate.getTime()) {
      imgBaseUrl += `?t=${uuid4()}`
    }
  }

  return imgBaseUrl;
};

export const getAlbumThumbnailUrls = (flashId: string) => (mediaId: string, size: string = 'small') => {
  return `${IMAGE_URL_BASE}/${flashId}/${mediaId}${thumbnailSuffix(size)}`;
}

// from cropper.js
const { navigator } = WINDOW;
export const IS_SAFARI_OR_UIWEBVIEW = navigator && /(Macintosh|iPhone|iPod|iPad).*AppleWebKit/i.test(navigator.userAgent);

export const getContainerWidthHeight = (layer: Layer, surface: Surface) => {
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  const surfaceWidth = surface.width;
  const surfaceHeight = surface.height;
  const vhPixels = 0.5 * windowHeight;
  const aspectRatio = (layer.width * surfaceWidth) / (layer.height * surfaceHeight);

  const containerWidth = aspectRatio * vhPixels;
  const containerHeight = vhPixels;

  const offset = smallBreakpoint(() => 64)(() => 480);

  // Final rounded containerWidth, plus an offset to account for padding (and tools sidebar above 781px screen width).
  const renderedContainerWidth = Math.abs(Math.round((aspectRatio * vhPixels) + offset));

  if (renderedContainerWidth <= windowWidth) {
    return { containerWidth: round(containerWidth, 4), containerHeight: round(containerHeight, 4) };
  }

  const fittedContainerWidth = windowWidth - offset;
  const fittedScaleFactor = fittedContainerWidth / containerWidth;

  return { containerWidth: round(fittedContainerWidth, 4), containerHeight: round(containerHeight * fittedScaleFactor, 4) };
};

// scaleCropData :: Object -> number -> number -> Object
export const scaleCropData = (cropData: Object) => (containerWidth: number) => (containerHeight: number) => {
  const scaleFactor = containerHeight / cropData.containerData.height;

  return {
    ...cropData,
    canvasData: {
      ...cropData.canvasData,
      left: cropData.canvasData.left * scaleFactor,
      top: cropData.canvasData.top * scaleFactor,
      width: cropData.canvasData.width * scaleFactor,
      height: cropData.canvasData.height * scaleFactor,
    },
    cropBoxData: {
      left: cropData.cropBoxData.left * scaleFactor,
      top: cropData.cropBoxData.top * scaleFactor,
      width: cropData.cropBoxData.width * scaleFactor,
      height: cropData.cropBoxData.height * scaleFactor,
    },
    imageData: {
      ...cropData.imageData,
      left: cropData.imageData.left * scaleFactor,
      top: cropData.imageData.top * scaleFactor,
      width: cropData.imageData.width * scaleFactor,
      height: cropData.imageData.height * scaleFactor,
    },
    containerData: {
      height: containerHeight,
      width: containerWidth,
    },
  };
};

export const isFileSmallEnough = (limit: number) => (file: Object) => file.size <= limit;

// UTILITY
// Utility selectors that can be used directly in action creators.
export const getPhotoFromLayer = (userPhotos: Array<Object>) => (layer: Layer) => (
  getUserPhotoIdFromLayer(layer).chain(getPhotoFromId(userPhotos))
);

export const getPhotoFromId = (userPhotos: Array<Object>) => (id: number | string) => (
  getUserPhotoFromId(id, userPhotos) // gets photo object from userPhotos
    .chain((o: Object) => option.fromNullable(photo.construct(o)))
);

export const getUserPhotoIdFromLayer = (layer: Object): Option<number | string> => optionGet('data.userPhotoId')(layer);

export const getUserPhotoFromId = (id: number | string, userPhotos: Array<Object>): Option<Object> => (
  optionFind((u: Object) => (has(u)('id') && u.id === id), userPhotos)
);

export const getPhotosInPage = (userPhotos: Array<Object>) => (pages: Array<any>) : Array<Photo> => {
  return (
    option.fromNullable(pages)
      .map((pgs) => pgs.map((p) => p.layers
        .filter(l => isUserPhotoLayer(l))
        .map(l => getPhotoFromLayer(userPhotos)(l).map(foundphoto => photo.extract(foundphoto)))
        .filter(match => match.isSome())
        .map(match => match.toNullable())
      )
        .reduce((acc, currentValue) => acc.concat(currentValue), [])
      )
      .getOrElseValue([])
  );
}

export const getPhotoFromLayerPath = (state: Object, pageId: string, layerId: string) => getPhotoFromLayer(state.userPhotos)(getLayerFromPage(pageId)(layerId)(state));
