// @flow
import reduce from 'lodash.reduce';
import { fromNullable, type Option } from 'fp-ts/lib/Option';
import { compose } from 'fp-ts/lib/function';
import { chain, optionGet, prop } from '../functions';
import set from '../set';

import { SUPPORTED_ATTRIBUTES_DISPLAY_TYPES } from '../../constants/products';
import { type Attribute, type Attributes } from '../../types/product';

// Test if a given attribute has a supported display type.
export const attributeShouldDisplay = (attribute: Attribute) =>
  SUPPORTED_ATTRIBUTES_DISPLAY_TYPES.includes(attribute.display);

export const attributesToArray = (attributes: Object) =>
  reduce(
    attributes,
    (result, attribute, name) => [...result, set(attribute)('name', name)],
    []
  ).filter(attributeShouldDisplay);

// Predicate indicating whether or not the given productData attributes object contains any attributes that should be displayed.
export const anyAttributesToDisplay = (attributes: Attributes): boolean =>
  // $FlowFixMe Object.values doesn't play nice with Flow, so ignoring this until that's fixed. https://github.com/facebook/flow/issues/2221
  attributes && Object.values(attributes).some(attributeShouldDisplay);

// Gets the productData pages attribute and compares to current page count
export const pagesAttrRange = (pageCount: number): Option<number> =>
  fromNullable(window.productData)
    // drill down to attr options array
    .chain(
      compose(
        chain(prop('options')),
        chain(prop('pages')),
        prop('attributes')
      )
    )
    .map(
      (xs: Array<{ label: string, value: string }>): Array<number> =>
        xs.map(x => parseInt(x.value, 10))
    )
    // sort them low to high
    .map((xs: Array<number>): Array<number> => xs.sort((a, b) => a - b))
    // compare based on the current page count
    .map(
      (xs: Array<number>): number =>
        xs.reduce((acc: number, x: number) => (pageCount < x ? acc : x), xs[0])
    );

/* eslint-disable no-unused-vars */
const getRestrictedIfAttribute = o =>
  optionGet('if.attribute')(o).getOrElseValue(false);
const getRestrictedIfEquals = o =>
  optionGet('if.equals')(o).getOrElseValue(false);
const getRestrictedDisabledOption = o =>
  optionGet('disable.optionsFor')(o).getOrElseValue(false);
const getRestrictedDisabledValues = o =>
  optionGet('disable.options')(o).getOrElseValue([]);
/* eslint-enable no-unused-vars */

export const validateProductAttributesByRestrictions = (attributes: Object, attributeRestrictions: Array): Object => {
  const validatedAttributes = {
    ...attributes,
  };

  if (typeof attributeRestrictions !== 'undefined' && attributeRestrictions && attributes) {
    const attributeKeys = Object.keys(attributes);
    attributeKeys.forEach((key) => {
      const attributeRestriction = attributeRestrictions.filter((restrictedAttribute) =>
        restrictedAttribute.disable.optionsFor?.includes(key));

      if (attributeRestriction.length) {
        attributeRestriction.forEach((restrictedAttribute) => {
          const { optionsFor: disabledAttribute, options: disabledAttributeOptions } = restrictedAttribute.disable;
          const { attribute: attributeDisabledForAttribute, equals: attributeDisabledForAttributeEquals } = restrictedAttribute.if;

          const doesContainRestrictedAttribute = attributes.hasOwnProperty(attributeDisabledForAttribute)
            && attributes[attributeDisabledForAttribute] === attributeDisabledForAttributeEquals;

          const shouldRestrictedAttributeBeDisabled = disabledAttributeOptions.find((option) => option === attributes[key]);

          if (shouldRestrictedAttributeBeDisabled && doesContainRestrictedAttribute) {
            delete validatedAttributes.[disabledAttribute];
          }
        });
      }
    });
  }

  return validatedAttributes;
};

export const getFoilColor = (productData: Object): string | undefined => productData?.attributes?.foil_color?.options[0]?.value;
