/* eslint-disable no-use-before-define */
import { generateInitCropData, getExifRotationDegrees } from '../helpers/crop';
import { debugLog } from '../helpers/DOM';
import { getPhotoFromLayer } from '../helpers/images';
import photoHelper, { FlashImageOrientation } from '../types/photo';

export function fixFlashPhotoDimensions(userPhotos) {
  return userPhotos.map((userPhoto) => {
    if (isFlashImageThatHadRotation(userPhoto)) {
      if (
        userPhoto.dimensions &&
        userPhoto.dimensions.fixed &&
        (userPhoto.metadata.orientation === FlashImageOrientation.ROTATE_90 ||
          userPhoto.metadata.orientation === FlashImageOrientation.ROTATE_270)
      ) {
        // We were previously swapping these values under the impression that all Flash images did not have EXIF data
        // Now that it is no longer the case, we need to swap all of the `fixed` image orientations back to what they were
        return {
          ...userPhoto,
          dimensions: {
            width: userPhoto.dimensions.height,
            height: userPhoto.dimensions.width,
            fixed: undefined,
          },
        };
      }
    }
    return userPhoto;
  });
}

function isFlashImageThatHadRotation(userPhoto) {
  const flashRotationVals = [FlashImageOrientation.ROTATE_90, FlashImageOrientation.ROTATE_180, FlashImageOrientation.ROTATE_270];
  return userPhoto && userPhoto.metadata && flashRotationVals.includes(userPhoto.metadata.orientation);
}

export function setAdminCheckForUserPhotoLayers(projectCreatedAtDate, pages, userPhotos) {
  return pages.map((page) => ({
    ...page,
    layers: page.layers.map((layer) => {
      // If the layer has a user photo
      if (layer.type !== 'user_photo' || !layer.data || !layer.data.userPhotoId) {
        return layer;
      }

      const photo = getPhotoFromLayer(userPhotos)(layer).value;
      if (photo == null) {
        window.newrelic.addPageAction('undefined_userPhoto', {
          projectCreatedAtDate,
          pageId: page.id,
          layerId: layer.id,
        });
        // If the photo was not found in the userPhotos array, remove the reference in the layer so it doesn't break the editor.
        // The user will have to re-add the photo anyway, as having it missing in the userPhotos array will break other things down the pipeline.
        return {
          ...layer,
          data: {
            ...layer.data,
            userPhotoId: undefined,
          },
        };
      }
      const extractedPhoto = photoHelper.extract(photo);
      const userPhotoExifRotation = getExifRotationDegrees(extractedPhoto);
      const currentPhotoModifications = layer.data.photoModifications;
      const hasPhotoMods = currentPhotoModifications != null && Object.keys(currentPhotoModifications).length !== 0;
      const currentCropData = layer.data.cropData;

      let initialCropData = {};
      let errorGeneratingInitCropData = false;
      try {
        initialCropData = generateInitCropData(extractedPhoto, layer, page.surface);
      } catch (e) {
        debugLog(e);
        errorGeneratingInitCropData = true;
      }

      // Due to inconsistencies in cropper data over time, this will clean and verify the cropper data is there and correct on project load
      // Cropper data is likely to be corrupted if...
      // 1. There is no cropper data at all (a rare occurrence)
      // 2. There are no photo mods, but the current cropper data doesn't match the initial cropper data generate
      // 3. The photo used in the layer is a flash uploaded photo that had exif rotation 90 or 270 on it
      const cropDataExists =
        currentCropData != null &&
        currentCropData.canvasData != null &&
        Object.keys(currentCropData.canvasData).length !== 0 &&
        currentCropData.cropBoxData != null &&
        Object.keys(currentCropData.cropBoxData).length !== 0 &&
        currentCropData.imageData != null &&
        Object.keys(currentCropData.imageData).length !== 0 &&
        currentCropData.containerData != null &&
        Object.keys(currentCropData.containerData).length !== 0 &&
        currentCropData.cropperData != null &&
        Object.keys(currentCropData.cropperData).length !== 0;

      // When photos w/ mods was coming straight from s3 it was setting image rotation but mods
      // We switched back to flash because of this, but that caused projects created within that timeframe to have image data rotation
      // then users would correct for exif and inadvertently over rotate the photo
      // This is the source of problems with customers getting books with upside down images in them.
      const currentImageRotateMismatch =
        cropDataExists &&
        hasPhotoMods &&
        extractedPhoto.metadata &&
        typeof extractedPhoto.metadata.orientation === 'number' &&
        (currentCropData.cropperData.rotate || 0) !== 0 &&
        (currentCropData.imageData.rotate || 0) === (currentCropData.cropperData.rotate || 0) &&
        (currentCropData.imageData.rotate || 0) === userPhotoExifRotation;

      // this check fixes the bug where photo dims dont match canvasData/imageData dims resulting in the image displaying
      // partially off the image well and the crop box appears to be zoomed in on the image
      let currentCanvasImageAspectRatioMismatch = false;
      if (
        cropDataExists &&
        !!extractedPhoto.dimensions &&
        (userPhotoExifRotation + (currentCropData.cropperData.rotate || 0)) % 180 !== 0
      ) {
        //                    userPhoto has dimensions     userPhoto has exif 90 or 270
        const canvasDataAspectRatio = currentCropData.canvasData.naturalWidth / currentCropData.canvasData.naturalHeight;
        const imageDataAspectRatio = currentCropData.imageData.naturalWidth / currentCropData.imageData.naturalHeight;
        const imageAspectRatio = extractedPhoto.dimensions.height / extractedPhoto.dimensions.width;
        currentCanvasImageAspectRatioMismatch =
          !numbersMatchWithinTolerance(canvasDataAspectRatio, imageDataAspectRatio, 0.01) ||
          !numbersMatchWithinTolerance(canvasDataAspectRatio, imageAspectRatio, 0.01);
      }

      const layerNeedsAdminCheck =
        !cropDataExists || currentImageRotateMismatch || currentCanvasImageAspectRatioMismatch || errorGeneratingInitCropData;

      if (layerNeedsAdminCheck) {
        debugLog(
          `Setting adminCheck for page ${page.id}, layer ${layer.id}.
          !cropDataExists: ${!cropDataExists}
          currentImageRotateMismatch: ${currentImageRotateMismatch}
          currentCanvasImageAspectRatioMismatch: ${currentCanvasImageAspectRatioMismatch}
          errorGeneratingInitCropData: ${errorGeneratingInitCropData}`
        );
        window.newrelic.addPageAction('admin_check', {
          projectCreatedAtDate,
          pageId: page.id,
          layerId: layer.id,
          cropDataDoesntExists: Number(!cropDataExists),
          currentImageRotateMismatch: Number(currentImageRotateMismatch),
          currentCanvasImageAspectRatioMismatch: Number(currentCanvasImageAspectRatioMismatch),
          photoModsIsAnArray: Array.isArray(currentPhotoModifications) ? 1 : 0,
          errorGeneratingInitCropData: Number(errorGeneratingInitCropData),
        });
      }

      return {
        ...layer,
        data: {
          ...layer.data,
          cropData: {
            ...(cropDataExists ? layer.data.cropData : initialCropData),
            adminCheck: layerNeedsAdminCheck,
          },
        },
      };
    }),
  }));
}

function numbersMatchWithinTolerance(n1: number, n2: number, tolerance = 1) {
  const difference = Math.abs(n1 - n2);
  return difference < tolerance;
}
