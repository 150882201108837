import {
  ADD_PHOTOS_CHANGE_STEP,
  ADD_PHOTOS_SET_ACTIVE_BUTTON,
  ADD_TO_STORED_IMAGES,
  ADD_PHOTOS_RESET_TITLE_AND_ERROR,
  ADD_PHOTOS_SET_GALLERY_TITLE,
  ADD_PHOTOS_SET_GALLERY_TITLE_ERROR,
  ADD_PHOTOS_SET_SELECTED_OPTION,
  SHOW_UPLOAD_IMAGE_ERROR_MODAL,
} from './constants';

const INITIAL_STATE = {
  activeButton: 'newGallery',
  activeStep: 1,
  activeText: 'Creating a Gallery lets you save and organize your photos in one place to access them later for other projects.',
  storedImages: [],
  gallerySelectedOption: '',
  galleryTitle: '',
  galleryTitleError: false,
  uploadImageCount: 0,
  uploadImageCountTotal: 0,
  uploadImageErrors: [],
  showUploadImageErrorModal: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_PHOTOS_CHANGE_STEP: {
      return { ...state, activeStep: action.payload };
    }

    case ADD_PHOTOS_SET_ACTIVE_BUTTON: {
      const { activeButton, activeText } = action.payload;

      return { ...state, activeButton, activeText };
    }

    case ADD_TO_STORED_IMAGES: {
      return { ...state, storedImages: action.payload };
    }

    case ADD_PHOTOS_RESET_TITLE_AND_ERROR: {
      return { ...state, galleryTitle: '', galleryTitleError: '' };
    }

    case ADD_PHOTOS_SET_GALLERY_TITLE: {
      return { ...state, galleryTitle: action.payload };
    }

    case ADD_PHOTOS_SET_GALLERY_TITLE_ERROR: {
      return { ...state, galleryTitleError: action.payload };
    }

    case ADD_PHOTOS_SET_SELECTED_OPTION: {
      return { ...state, gallerySelectedOption: action.payload };
    }

    case SHOW_UPLOAD_IMAGE_ERROR_MODAL:
      return { ...state, showUploadImageErrorModal: action.payload };

    default: {
      return state;
    }
  }
}

export const addPhotosStateFilter = () => INITIAL_STATE;
