import { PROJECT_SET_LOCK_STATE } from './constants';
export default function projectIdReducer(state = '', action) {
  switch (action.type) {
    case PROJECT_SET_LOCK_STATE:
      const { lockState } = action.payload;
      return lockState;

    default:
      // Do nothing if we didn't match action type
      return state;
  }
}
