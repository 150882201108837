import { productStateFilter } from './product/reducer';
import { projectStateFilter, manipulateProjectData } from './project/reducer';
import { uiStateFilter } from './ui/reducer';
import { userPhotoStateFilter } from './userPhotos/reducer';
import { userAlbumsStateFilter } from './userAlbums/reducer';
import { addPhotosStateFilter } from './addPhotos/reducer';
import { instagramStateFilter } from './instagramPhotos/reducer';
import { facebookStateFilter } from './facebook/reducer';
import { googlePhotosStateFilter } from './googlePhotos/reducer';
import { dropboxStateFilter } from './dropbox/reducer';
import { userStateFilter } from './user/reducer';
import { envelopeAddressingStateFilter } from './envelopeAddressing/reducer';

export const storeFilter = (state) => {
  const newState = { ...state };
  Object.keys(state).forEach((key) => {
    switch (key) {
      case 'project':
        newState.project = manipulateProjectData(projectStateFilter(state[key]), state.product);
        break;
      case 'user':
        newState.user = userStateFilter(state[key]);
        break;
      case 'ui':
        newState.ui = uiStateFilter(state[key]);
        break;
      case 'history':
        newState.history = { past: [], future: [] };
        break;
      case 'product':
        newState.product = productStateFilter(state[key]);
        break;
      case 'userPhotos':
        newState.userPhotos = userPhotoStateFilter(state);
        break;
      case 'galleries':
        newState.galleries = undefined;
        break;
      case 'userAlbums':
        newState.userAlbums = userAlbumsStateFilter();
        break;
      case 'asyncInitialState':
        newState.asyncInitialState = {};
        break;
      case 'addPhotos':
        newState.addPhotos = addPhotosStateFilter();
        break;
      case 'instagramPhotos':
        newState.instagramPhotos = instagramStateFilter();
        break;
      case 'dropbox':
        newState.dropbox = dropboxStateFilter();
        break;
      case 'facebook':
        newState.facebook = facebookStateFilter();
        break;
      case 'googlePhotos':
        newState.googlePhotos = googlePhotosStateFilter();
        break;
      case 'zendesk':
        newState.zendesk = {};
        break;
      case 'envelopeAddressing':
        newState.envelopeAddressing = envelopeAddressingStateFilter(state[key]);
        break;
      default:
        break;
    }
  });
  return newState;
};
