export const UI_MODAL_PHOTO_EDITOR = 'UI_MODAL_PHOTO_EDITOR';
export const UI_MODAL_CLOSE = 'UI_MODAL_CLOSE';
export const UI_MODAL_SET_STATUS = 'UI_MODAL_SET_STATUS';
export const UI_MODAL_COFFEE_TABLE = 'UI_MODAL_COFFEE_TABLE';
export const UI_MODAL_AUTOFILL_START = 'UI_MODAL_AUTOFILL_START';
export const UI_MODAL_AUTOFILL_PROGRESS = 'UI_MODAL_AUTOFILL_PROGRESS';
export const UI_MODAL_AUTOFILL_ADDED_PAGES = 'UI_MODAL_AUTOFILL_ADDED_PAGES';
export const UI_MODAL_AUTOFILL_TOO_MANY_IMAGES = 'UI_MODAL_AUTOFILL_TOO_MANY_IMAGES';
export const UI_MODAL_RECIPIENT_ADDRESSES_UPLOAD = 'UI_MODAL_RECIPIENT_ADDRESSES_UPLOAD';
export const UI_MODAL_RECIPIENT_ADDRESSES_FORM = 'UI_MODAL_RECIPIENT_ADDRESSES_FORM';
export const UI_MODAL_RETURN_ADDRESS_FORM = 'UI_MODAL_RETURN_ADDRESS_FORM';
export const UI_MODAL_DATA_COLLECTION_FORM = 'UI_MODAL_DATA_COLLECTION_FORM';

export type ModalState = {
  title: string,
  status: string,
  meta: string,
  data: Object,
};

export const initState = {
  title: '',
  status: '',
  meta: '',
  data: {},
};
