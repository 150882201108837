// @flow

import {
  ADD_GOOGLE_PHOTOS,
  SET_GOOGLE_PHOTOS_ACTIVE,
  SET_GOOGLE_PHOTOS_MAIN_ALBUM,
  SET_GOOGLE_PHOTOS_NEXT_URL,
  GOOGLE_PHOTOS_REQUESTING,
  SET_GOOGLE_ACCESS_TOKEN,
  DISABLE_GOOGLE_PHOTOS,
  SET_GOOGLE_PHOTOS_ACCOUNT_LINKED,
} from './constants';

const INITIAL_STATE = {
  images: [],
  baseGooglePhotos: [],
  googleAccessToken: null,
  selectedImages: [],
  googlePhotosActive: false,
  googlePhotosAccountLinked: false,
  googlePhotosMainAlbum: null,
  googlePhotosRequesting: false,
  nextUrl: null,
};

export const DISABLED_STATE_GOOGLE_PHOTOS = {
  googlePhotosActive: false,
  googlePhotosAccountLinked: false,
  images: [],
  googlePhotosRequesting: false,
  googlePhotosMainAlbum: null,
  nextUrl: null,
};

export default function (state:any = INITIAL_STATE, action:any) {
  switch (action.type) {
    case ADD_GOOGLE_PHOTOS: {
      const { images } = state;

      const combined = [...images, ...action.payload];

      return { ...state, images: combined, googlePhotosRequesting: false };
    }

    case SET_GOOGLE_PHOTOS_MAIN_ALBUM: {
      return { ...state, googlePhotosMainAlbum: action.payload };
    }

    case SET_GOOGLE_ACCESS_TOKEN: {
      return { ...state, googleAccessToken: action.payload };
    }

    case SET_GOOGLE_PHOTOS_ACTIVE: {
      return { ...state, googlePhotosActive: action.payload };
    }

    case SET_GOOGLE_PHOTOS_ACCOUNT_LINKED: {
      return { ...state, googlePhotosAccountLinked: action.payload };
    }

    case SET_GOOGLE_PHOTOS_NEXT_URL: {
      return { ...state, nextUrl: action.payload };
    }

    case GOOGLE_PHOTOS_REQUESTING: {
      return { ...state, googlePhotosRequesting: action.payload };
    }

    case DISABLE_GOOGLE_PHOTOS: {
      return {
        ...state,
        ...DISABLED_STATE_GOOGLE_PHOTOS,
      };
    }

    default: {
      return state;
    }
  }
}

export const googlePhotosStateFilter = () => INITIAL_STATE;
