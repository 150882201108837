/* eslint-disable */
var validate = (function() {
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    var vErrors = null;
    var errors = 0;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      {
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !( validate.schema.properties.hasOwnProperty(key0));
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
          }
        }
        if (valid1) {
          if (data.au_project_version === undefined) {
            valid1 = true;
          } else {
            var errs_1 = errors;
            if (typeof data.au_project_version !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.au_project_version',
                schemaPath: '#/properties/au_project_version/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.au_split_editorV2_books === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (typeof data.au_split_editorV2_books !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.au_split_editorV2_books',
                  schemaPath: '#/properties/au_split_editorV2_books/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.au_split_editorV2_feature_flag_autofill === undefined) {
                valid1 = true;
              } else {
                var errs_1 = errors;
                if (typeof data.au_split_editorV2_feature_flag_autofill !== "boolean") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.au_split_editorV2_feature_flag_autofill',
                    schemaPath: '#/properties/au_split_editorV2_feature_flag_autofill/type',
                    params: {
                      type: 'boolean'
                    },
                    message: 'should be boolean'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.au_split_editorV2_guestbookset === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (typeof data.au_split_editorV2_guestbookset !== "string") {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.au_split_editorV2_guestbookset',
                      schemaPath: '#/properties/au_split_editorV2_guestbookset/type',
                      params: {
                        type: 'string'
                      },
                      message: 'should be string'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.au_split_editorV2_spread_books === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (typeof data.au_split_editorV2_spread_books !== "string") {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.au_split_editorV2_spread_books',
                        schemaPath: '#/properties/au_split_editorV2_spread_books/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data['au_split_featureflag-chooseimagemodalflow'] === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (typeof data['au_split_featureflag-chooseimagemodalflow'] !== "boolean") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '[\'au_split_featureflag-chooseimagemodalflow\']',
                          schemaPath: '#/properties/au_split_featureflag-chooseimagemodalflow/type',
                          params: {
                            type: 'boolean'
                          },
                          message: 'should be boolean'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.au_split_feature_flag_google_sign_in === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (typeof data.au_split_feature_flag_google_sign_in !== "boolean") {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.au_split_feature_flag_google_sign_in',
                            schemaPath: '#/properties/au_split_feature_flag_google_sign_in/type',
                            params: {
                              type: 'boolean'
                            },
                            message: 'should be boolean'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.au_split_feature_flag_product_search === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.au_split_feature_flag_product_search !== "boolean") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.au_split_feature_flag_product_search',
                              schemaPath: '#/properties/au_split_feature_flag_product_search/type',
                              params: {
                                type: 'boolean'
                              },
                              message: 'should be boolean'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          if (data.editor_version === undefined) {
                            valid1 = true;
                          } else {
                            var errs_1 = errors;
                            if (typeof data.editor_version !== "string") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.editor_version',
                                schemaPath: '#/properties/editor_version/type',
                                params: {
                                  type: 'string'
                                },
                                message: 'should be string'
                              }];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                            if (data.is_mobile_view === undefined) {
                              valid1 = true;
                            } else {
                              var errs_1 = errors;
                              if (typeof data.is_mobile_view !== "boolean") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.is_mobile_view',
                                  schemaPath: '#/properties/is_mobile_view/type',
                                  params: {
                                    type: 'boolean'
                                  },
                                  message: 'should be boolean'
                                }];
                                return false;
                              }
                              var valid1 = errors === errs_1;
                            }
                            if (valid1) {
                              if (data.platform === undefined) {
                                valid1 = true;
                              } else {
                                var errs_1 = errors;
                                if (typeof data.platform !== "string") {
                                  validate.errors = [{
                                    keyword: 'type',
                                    dataPath: (dataPath || '') + '.platform',
                                    schemaPath: '#/properties/platform/type',
                                    params: {
                                      type: 'string'
                                    },
                                    message: 'should be string'
                                  }];
                                  return false;
                                }
                                var valid1 = errors === errs_1;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate.schema = {
  "$id": "https://data.amplitude.com/5ad78ff3-fd26-4b3e-95c7-ab219e3a30e0/context",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "Context",
  "description": "",
  "type": "object",
  "properties": {
    "au_project_version": {
      "description": "Editor version (1 or 2). Required for any events in Editor",
      "type": "string"
    },
    "au_split_editorV2_books": {
      "description": "INACTIVE",
      "type": "string"
    },
    "au_split_editorV2_feature_flag_autofill": {
      "description": "",
      "type": "boolean"
    },
    "au_split_editorV2_guestbookset": {
      "description": "",
      "type": "string"
    },
    "au_split_editorV2_spread_books": {
      "description": "Split for Spread Books testing",
      "type": "string"
    },
    "au_split_featureflag-chooseimagemodalflow": {
      "description": "",
      "type": "boolean"
    },
    "au_split_feature_flag_google_sign_in": {
      "description": "Property containing the split treatment for whether the Google log in/sign up buttons are shown to the user",
      "type": "boolean"
    },
    "au_split_feature_flag_product_search": {
      "description": "Property containing the split treatment for the new product search experience at commerce",
      "type": "boolean"
    },
    "editor_version": {
      "description": "Editor minor version (e.g. 1.62.1)",
      "type": "string"
    },
    "is_mobile_view": {
      "description": "Is the user using a mobile device?",
      "type": "boolean"
    },
    "platform": {
      "description": "User's operating system",
      "type": "string"
    }
  },
  "additionalProperties": false,
  "required": []
};
validate.errors = null;

var validate$1 = (function() {
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    var vErrors = null;
    var errors = 0;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      {
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !( key0 == 'email' || key0 == 'first_name' || key0 == 'last_name' || key0 == 'unsubscribed');
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
          }
        }
        if (valid1) {
          if (data.email === undefined) {
            valid1 = true;
          } else {
            var errs_1 = errors;
            if (typeof data.email !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.email',
                schemaPath: '#/properties/email/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.first_name === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (typeof data.first_name !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.first_name',
                  schemaPath: '#/properties/first_name/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.last_name === undefined) {
                valid1 = true;
              } else {
                var errs_1 = errors;
                if (typeof data.last_name !== "string") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.last_name',
                    schemaPath: '#/properties/last_name/type',
                    params: {
                      type: 'string'
                    },
                    message: 'should be string'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.unsubscribed === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (typeof data.unsubscribed !== "boolean") {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.unsubscribed',
                      schemaPath: '#/properties/unsubscribed/type',
                      params: {
                        type: 'boolean'
                      },
                      message: 'should be boolean'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
              }
            }
          }
        }
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate$1.schema = {
  "$id": "https://data.amplitude.com/5ad78ff3-fd26-4b3e-95c7-ab219e3a30e0/identify",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "Identify",
  "description": "",
  "type": "object",
  "properties": {
    "email": {
      "description": "",
      "type": "string"
    },
    "first_name": {
      "description": "",
      "type": "string"
    },
    "last_name": {
      "description": "",
      "type": "string"
    },
    "unsubscribed": {
      "description": "Used downstream for Blueshift. Set to false in the case of a Gleam pop-up or other indicator that they should be resubscribed to our mailing list.",
      "type": "boolean"
    }
  },
  "additionalProperties": false,
  "required": []
};
validate$1.errors = null;

var validate$2 = (function() {
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    var vErrors = null;
    var errors = 0;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      {
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !( key0 == 'email' || key0 == 'site_id' || key0 == 'user_id');
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
          }
        }
        if (valid1) {
          if (data.email === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'email'
              },
              message: 'should have required property \'email\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data.email !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.email',
                schemaPath: '#/properties/email/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.site_id === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'site_id'
                },
                message: 'should have required property \'site_id\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              if (typeof data.site_id !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.site_id',
                  schemaPath: '#/properties/site_id/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.user_id === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'user_id'
                  },
                  message: 'should have required property \'user_id\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                if ((typeof data.user_id !== "number")) {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.user_id',
                    schemaPath: '#/properties/user_id/type',
                    params: {
                      type: 'number'
                    },
                    message: 'should be number'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
            }
          }
        }
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate$2.schema = {
  "$id": "https://data.amplitude.com/5ad78ff3-fd26-4b3e-95c7-ab219e3a30e0/event/Account%20Created/version/1.0.0",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "Account Created",
  "description": "User created a new account",
  "type": "object",
  "properties": {
    "email": {
      "description": "The customer's email address",
      "type": "string"
    },
    "site_id": {
      "description": "Used by Blueshift; default to us:web or us:iphoneapp",
      "type": "string"
    },
    "user_id": {
      "description": "The customer's Magento ID",
      "type": "number"
    }
  },
  "additionalProperties": false,
  "required": ["email", "site_id", "user_id"]
};
validate$2.errors = null;

var validate$3 = (function() {
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    var vErrors = null;
    var errors = 0;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      {
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !( validate.schema.properties.hasOwnProperty(key0));
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
          }
        }
        if (valid1) {
          if (data.au_project_version === undefined) {
            valid1 = true;
          } else {
            var errs_1 = errors;
            if (typeof data.au_project_version !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.au_project_version',
                schemaPath: '#/properties/au_project_version/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.au_split_editorV2_books === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (typeof data.au_split_editorV2_books !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.au_split_editorV2_books',
                  schemaPath: '#/properties/au_split_editorV2_books/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.au_split_editorV2_feature_flag_autofill === undefined) {
                valid1 = true;
              } else {
                var errs_1 = errors;
                if (typeof data.au_split_editorV2_feature_flag_autofill !== "boolean") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.au_split_editorV2_feature_flag_autofill',
                    schemaPath: '#/properties/au_split_editorV2_feature_flag_autofill/type',
                    params: {
                      type: 'boolean'
                    },
                    message: 'should be boolean'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.au_split_editorV2_guestbookset === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (typeof data.au_split_editorV2_guestbookset !== "string") {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.au_split_editorV2_guestbookset',
                      schemaPath: '#/properties/au_split_editorV2_guestbookset/type',
                      params: {
                        type: 'string'
                      },
                      message: 'should be string'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.au_split_editorV2_spread_books === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (typeof data.au_split_editorV2_spread_books !== "string") {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.au_split_editorV2_spread_books',
                        schemaPath: '#/properties/au_split_editorV2_spread_books/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data['au_split_featureflag-chooseimagemodalflow'] === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (typeof data['au_split_featureflag-chooseimagemodalflow'] !== "boolean") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '[\'au_split_featureflag-chooseimagemodalflow\']',
                          schemaPath: '#/properties/au_split_featureflag-chooseimagemodalflow/type',
                          params: {
                            type: 'boolean'
                          },
                          message: 'should be boolean'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.au_split_feature_flag_google_sign_in === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (typeof data.au_split_feature_flag_google_sign_in !== "boolean") {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.au_split_feature_flag_google_sign_in',
                            schemaPath: '#/properties/au_split_feature_flag_google_sign_in/type',
                            params: {
                              type: 'boolean'
                            },
                            message: 'should be boolean'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.au_split_feature_flag_product_search === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.au_split_feature_flag_product_search !== "boolean") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.au_split_feature_flag_product_search',
                              schemaPath: '#/properties/au_split_feature_flag_product_search/type',
                              params: {
                                type: 'boolean'
                              },
                              message: 'should be boolean'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          if (data.category === undefined) {
                            valid1 = false;
                            validate.errors = [{
                              keyword: 'required',
                              dataPath: (dataPath || '') + "",
                              schemaPath: '#/required',
                              params: {
                                missingProperty: 'category'
                              },
                              message: 'should have required property \'category\''
                            }];
                            return false;
                          } else {
                            var errs_1 = errors;
                            if (typeof data.category !== "string") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.category',
                                schemaPath: '#/properties/category/type',
                                params: {
                                  type: 'string'
                                },
                                message: 'should be string'
                              }];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                            if (data.editor_version === undefined) {
                              valid1 = true;
                            } else {
                              var errs_1 = errors;
                              if (typeof data.editor_version !== "string") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.editor_version',
                                  schemaPath: '#/properties/editor_version/type',
                                  params: {
                                    type: 'string'
                                  },
                                  message: 'should be string'
                                }];
                                return false;
                              }
                              var valid1 = errors === errs_1;
                            }
                            if (valid1) {
                              if (data.is_customizable_product === undefined) {
                                valid1 = true;
                              } else {
                                var errs_1 = errors;
                                if (typeof data.is_customizable_product !== "boolean") {
                                  validate.errors = [{
                                    keyword: 'type',
                                    dataPath: (dataPath || '') + '.is_customizable_product',
                                    schemaPath: '#/properties/is_customizable_product/type',
                                    params: {
                                      type: 'boolean'
                                    },
                                    message: 'should be boolean'
                                  }];
                                  return false;
                                }
                                var valid1 = errors === errs_1;
                              }
                              if (valid1) {
                                if (data.is_mobile_view === undefined) {
                                  valid1 = true;
                                } else {
                                  var errs_1 = errors;
                                  if (typeof data.is_mobile_view !== "boolean") {
                                    validate.errors = [{
                                      keyword: 'type',
                                      dataPath: (dataPath || '') + '.is_mobile_view',
                                      schemaPath: '#/properties/is_mobile_view/type',
                                      params: {
                                        type: 'boolean'
                                      },
                                      message: 'should be boolean'
                                    }];
                                    return false;
                                  }
                                  var valid1 = errors === errs_1;
                                }
                                if (valid1) {
                                  if (data.name === undefined) {
                                    valid1 = false;
                                    validate.errors = [{
                                      keyword: 'required',
                                      dataPath: (dataPath || '') + "",
                                      schemaPath: '#/required',
                                      params: {
                                        missingProperty: 'name'
                                      },
                                      message: 'should have required property \'name\''
                                    }];
                                    return false;
                                  } else {
                                    var errs_1 = errors;
                                    if (typeof data.name !== "string") {
                                      validate.errors = [{
                                        keyword: 'type',
                                        dataPath: (dataPath || '') + '.name',
                                        schemaPath: '#/properties/name/type',
                                        params: {
                                          type: 'string'
                                        },
                                        message: 'should be string'
                                      }];
                                      return false;
                                    }
                                    var valid1 = errors === errs_1;
                                  }
                                  if (valid1) {
                                    if (data.platform === undefined) {
                                      valid1 = true;
                                    } else {
                                      var errs_1 = errors;
                                      if (typeof data.platform !== "string") {
                                        validate.errors = [{
                                          keyword: 'type',
                                          dataPath: (dataPath || '') + '.platform',
                                          schemaPath: '#/properties/platform/type',
                                          params: {
                                            type: 'string'
                                          },
                                          message: 'should be string'
                                        }];
                                        return false;
                                      }
                                      var valid1 = errors === errs_1;
                                    }
                                    if (valid1) {
                                      if (data.price === undefined) {
                                        valid1 = true;
                                      } else {
                                        var errs_1 = errors;
                                        if ((typeof data.price !== "number")) {
                                          validate.errors = [{
                                            keyword: 'type',
                                            dataPath: (dataPath || '') + '.price',
                                            schemaPath: '#/properties/price/type',
                                            params: {
                                              type: 'number'
                                            },
                                            message: 'should be number'
                                          }];
                                          return false;
                                        }
                                        var valid1 = errors === errs_1;
                                      }
                                      if (valid1) {
                                        var data1 = data.product_id;
                                        if (data1 === undefined) {
                                          valid1 = false;
                                          validate.errors = [{
                                            keyword: 'required',
                                            dataPath: (dataPath || '') + "",
                                            schemaPath: '#/required',
                                            params: {
                                              missingProperty: 'product_id'
                                            },
                                            message: 'should have required property \'product_id\''
                                          }];
                                          return false;
                                        } else {
                                          var errs_1 = errors;
                                          if ((typeof data1 !== "number" || (data1 % 1) || data1 !== data1)) {
                                            validate.errors = [{
                                              keyword: 'type',
                                              dataPath: (dataPath || '') + '.product_id',
                                              schemaPath: '#/properties/product_id/type',
                                              params: {
                                                type: 'integer'
                                              },
                                              message: 'should be integer'
                                            }];
                                            return false;
                                          }
                                          var valid1 = errors === errs_1;
                                        }
                                        if (valid1) {
                                          if (data.product_line === undefined) {
                                            valid1 = false;
                                            validate.errors = [{
                                              keyword: 'required',
                                              dataPath: (dataPath || '') + "",
                                              schemaPath: '#/required',
                                              params: {
                                                missingProperty: 'product_line'
                                              },
                                              message: 'should have required property \'product_line\''
                                            }];
                                            return false;
                                          } else {
                                            var errs_1 = errors;
                                            if (typeof data.product_line !== "string") {
                                              validate.errors = [{
                                                keyword: 'type',
                                                dataPath: (dataPath || '') + '.product_line',
                                                schemaPath: '#/properties/product_line/type',
                                                params: {
                                                  type: 'string'
                                                },
                                                message: 'should be string'
                                              }];
                                              return false;
                                            }
                                            var valid1 = errors === errs_1;
                                          }
                                          if (valid1) {
                                            var data1 = data.products;
                                            if (data1 === undefined) {
                                              valid1 = true;
                                            } else {
                                              var errs_1 = errors;
                                              if (Array.isArray(data1)) {
                                                var valid1;
                                                for (var i1 = 0; i1 < data1.length; i1++) {
                                                  var data2 = data1[i1];
                                                  var errs_2 = errors;
                                                  if ((!data2 || typeof data2 !== "object" || Array.isArray(data2))) {
                                                    validate.errors = [{
                                                      keyword: 'type',
                                                      dataPath: (dataPath || '') + '.products[' + i1 + ']',
                                                      schemaPath: '#/properties/products/items/type',
                                                      params: {
                                                        type: 'object'
                                                      },
                                                      message: 'should be object'
                                                    }];
                                                    return false;
                                                  }
                                                  var valid2 = errors === errs_2;
                                                  if (!valid2) break;
                                                }
                                              } else {
                                                validate.errors = [{
                                                  keyword: 'type',
                                                  dataPath: (dataPath || '') + '.products',
                                                  schemaPath: '#/properties/products/type',
                                                  params: {
                                                    type: 'array'
                                                  },
                                                  message: 'should be array'
                                                }];
                                                return false;
                                              }
                                              var valid1 = errors === errs_1;
                                            }
                                            if (valid1) {
                                              if (data.product_type === undefined) {
                                                valid1 = true;
                                              } else {
                                                var errs_1 = errors;
                                                if (typeof data.product_type !== "string") {
                                                  validate.errors = [{
                                                    keyword: 'type',
                                                    dataPath: (dataPath || '') + '.product_type',
                                                    schemaPath: '#/properties/product_type/type',
                                                    params: {
                                                      type: 'string'
                                                    },
                                                    message: 'should be string'
                                                  }];
                                                  return false;
                                                }
                                                var valid1 = errors === errs_1;
                                              }
                                              if (valid1) {
                                                if (data.project_id === undefined) {
                                                  valid1 = false;
                                                  validate.errors = [{
                                                    keyword: 'required',
                                                    dataPath: (dataPath || '') + "",
                                                    schemaPath: '#/required',
                                                    params: {
                                                      missingProperty: 'project_id'
                                                    },
                                                    message: 'should have required property \'project_id\''
                                                  }];
                                                  return false;
                                                } else {
                                                  var errs_1 = errors;
                                                  if (typeof data.project_id !== "string") {
                                                    validate.errors = [{
                                                      keyword: 'type',
                                                      dataPath: (dataPath || '') + '.project_id',
                                                      schemaPath: '#/properties/project_id/type',
                                                      params: {
                                                        type: 'string'
                                                      },
                                                      message: 'should be string'
                                                    }];
                                                    return false;
                                                  }
                                                  var valid1 = errors === errs_1;
                                                }
                                                if (valid1) {
                                                  if (data.sku === undefined) {
                                                    valid1 = false;
                                                    validate.errors = [{
                                                      keyword: 'required',
                                                      dataPath: (dataPath || '') + "",
                                                      schemaPath: '#/required',
                                                      params: {
                                                        missingProperty: 'sku'
                                                      },
                                                      message: 'should have required property \'sku\''
                                                    }];
                                                    return false;
                                                  } else {
                                                    var errs_1 = errors;
                                                    if (typeof data.sku !== "string") {
                                                      validate.errors = [{
                                                        keyword: 'type',
                                                        dataPath: (dataPath || '') + '.sku',
                                                        schemaPath: '#/properties/sku/type',
                                                        params: {
                                                          type: 'string'
                                                        },
                                                        message: 'should be string'
                                                      }];
                                                      return false;
                                                    }
                                                    var valid1 = errors === errs_1;
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate$3.schema = {
  "$id": "https://data.amplitude.com/5ad78ff3-fd26-4b3e-95c7-ab219e3a30e0/event/Image%20Added/version/15.0.1",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "Image Added",
  "description": "Fires whenever an image is added to a project",
  "type": "object",
  "properties": {
    "au_project_version": {
      "description": "Editor version (1 or 2). Required for any events in Editor",
      "type": "string"
    },
    "au_split_editorV2_books": {
      "description": "INACTIVE",
      "type": "string"
    },
    "au_split_editorV2_feature_flag_autofill": {
      "description": "",
      "type": "boolean"
    },
    "au_split_editorV2_guestbookset": {
      "description": "",
      "type": "string"
    },
    "au_split_editorV2_spread_books": {
      "description": "Split for Spread Books testing",
      "type": "string"
    },
    "au_split_featureflag-chooseimagemodalflow": {
      "description": "",
      "type": "boolean"
    },
    "au_split_feature_flag_google_sign_in": {
      "description": "Property containing the split treatment for whether the Google log in/sign up buttons are shown to the user",
      "type": "boolean"
    },
    "au_split_feature_flag_product_search": {
      "description": "Property containing the split treatment for the new product search experience at commerce",
      "type": "boolean"
    },
    "category": {
      "description": "The reporting product category in Magento",
      "type": "string"
    },
    "editor_version": {
      "description": "Editor minor version (e.g. 1.62.1)",
      "type": "string"
    },
    "is_customizable_product": {
      "description": "True if the product can be customized in editor; false if it goes straight to the cart",
      "type": "boolean"
    },
    "is_mobile_view": {
      "description": "Is the user using a mobile device?",
      "type": "boolean"
    },
    "name": {
      "description": "Product name for the parent product",
      "type": "string"
    },
    "platform": {
      "description": "User's operating system",
      "type": "string"
    },
    "price": {
      "description": "Price as displayed to the customer; in some events, such as Product Viewed and Product List Viewed, this will be set to the Lowest Price since price can change based on options selected",
      "type": "number"
    },
    "product_id": {
      "description": "The product_id for the parent product",
      "type": "integer"
    },
    "product_line": {
      "description": "The reporting product line in Magento",
      "type": "string"
    },
    "products": {
      "description": "Products in the event. This is used by at least Blueshift to manage campaigns. Should contain the following properties:\n\nproduct\\_id\nsku\nname\nprice\ncategory (aka: Reporting Product Category)\nproduct\\_line (aka: Reporting Product Line)",
      "type": "array",
      "items": {
        "type": "object"
      }
    },
    "product_type": {
      "description": "The reporting product type in Magento",
      "type": "string"
    },
    "project_id": {
      "description": "",
      "type": "string"
    },
    "sku": {
      "description": "SKU for the parent product",
      "type": "string"
    }
  },
  "additionalProperties": false,
  "required": ["category", "name", "product_id", "product_line", "project_id", "sku"]
};
validate$3.errors = null;

var validate$4 = (function() {
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    var vErrors = null;
    var errors = 0;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      {
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !( validate.schema.properties.hasOwnProperty(key0));
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
          }
        }
        if (valid1) {
          if (data.au_project_version === undefined) {
            valid1 = true;
          } else {
            var errs_1 = errors;
            if (typeof data.au_project_version !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.au_project_version',
                schemaPath: '#/properties/au_project_version/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.au_split_editorV2_books === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (typeof data.au_split_editorV2_books !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.au_split_editorV2_books',
                  schemaPath: '#/properties/au_split_editorV2_books/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.au_split_editorV2_feature_flag_autofill === undefined) {
                valid1 = true;
              } else {
                var errs_1 = errors;
                if (typeof data.au_split_editorV2_feature_flag_autofill !== "boolean") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.au_split_editorV2_feature_flag_autofill',
                    schemaPath: '#/properties/au_split_editorV2_feature_flag_autofill/type',
                    params: {
                      type: 'boolean'
                    },
                    message: 'should be boolean'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.au_split_editorV2_guestbookset === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (typeof data.au_split_editorV2_guestbookset !== "string") {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.au_split_editorV2_guestbookset',
                      schemaPath: '#/properties/au_split_editorV2_guestbookset/type',
                      params: {
                        type: 'string'
                      },
                      message: 'should be string'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.au_split_editorV2_spread_books === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (typeof data.au_split_editorV2_spread_books !== "string") {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.au_split_editorV2_spread_books',
                        schemaPath: '#/properties/au_split_editorV2_spread_books/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data['au_split_featureflag-chooseimagemodalflow'] === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (typeof data['au_split_featureflag-chooseimagemodalflow'] !== "boolean") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '[\'au_split_featureflag-chooseimagemodalflow\']',
                          schemaPath: '#/properties/au_split_featureflag-chooseimagemodalflow/type',
                          params: {
                            type: 'boolean'
                          },
                          message: 'should be boolean'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.au_split_feature_flag_google_sign_in === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (typeof data.au_split_feature_flag_google_sign_in !== "boolean") {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.au_split_feature_flag_google_sign_in',
                            schemaPath: '#/properties/au_split_feature_flag_google_sign_in/type',
                            params: {
                              type: 'boolean'
                            },
                            message: 'should be boolean'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.au_split_feature_flag_product_search === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.au_split_feature_flag_product_search !== "boolean") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.au_split_feature_flag_product_search',
                              schemaPath: '#/properties/au_split_feature_flag_product_search/type',
                              params: {
                                type: 'boolean'
                              },
                              message: 'should be boolean'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          if (data.category === undefined) {
                            valid1 = false;
                            validate.errors = [{
                              keyword: 'required',
                              dataPath: (dataPath || '') + "",
                              schemaPath: '#/required',
                              params: {
                                missingProperty: 'category'
                              },
                              message: 'should have required property \'category\''
                            }];
                            return false;
                          } else {
                            var errs_1 = errors;
                            if (typeof data.category !== "string") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.category',
                                schemaPath: '#/properties/category/type',
                                params: {
                                  type: 'string'
                                },
                                message: 'should be string'
                              }];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                            if (data.editor_version === undefined) {
                              valid1 = true;
                            } else {
                              var errs_1 = errors;
                              if (typeof data.editor_version !== "string") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.editor_version',
                                  schemaPath: '#/properties/editor_version/type',
                                  params: {
                                    type: 'string'
                                  },
                                  message: 'should be string'
                                }];
                                return false;
                              }
                              var valid1 = errors === errs_1;
                            }
                            if (valid1) {
                              if (data.is_customizable_product === undefined) {
                                valid1 = true;
                              } else {
                                var errs_1 = errors;
                                if (typeof data.is_customizable_product !== "boolean") {
                                  validate.errors = [{
                                    keyword: 'type',
                                    dataPath: (dataPath || '') + '.is_customizable_product',
                                    schemaPath: '#/properties/is_customizable_product/type',
                                    params: {
                                      type: 'boolean'
                                    },
                                    message: 'should be boolean'
                                  }];
                                  return false;
                                }
                                var valid1 = errors === errs_1;
                              }
                              if (valid1) {
                                if (data.is_mobile_view === undefined) {
                                  valid1 = true;
                                } else {
                                  var errs_1 = errors;
                                  if (typeof data.is_mobile_view !== "boolean") {
                                    validate.errors = [{
                                      keyword: 'type',
                                      dataPath: (dataPath || '') + '.is_mobile_view',
                                      schemaPath: '#/properties/is_mobile_view/type',
                                      params: {
                                        type: 'boolean'
                                      },
                                      message: 'should be boolean'
                                    }];
                                    return false;
                                  }
                                  var valid1 = errors === errs_1;
                                }
                                if (valid1) {
                                  if (data.name === undefined) {
                                    valid1 = false;
                                    validate.errors = [{
                                      keyword: 'required',
                                      dataPath: (dataPath || '') + "",
                                      schemaPath: '#/required',
                                      params: {
                                        missingProperty: 'name'
                                      },
                                      message: 'should have required property \'name\''
                                    }];
                                    return false;
                                  } else {
                                    var errs_1 = errors;
                                    if (typeof data.name !== "string") {
                                      validate.errors = [{
                                        keyword: 'type',
                                        dataPath: (dataPath || '') + '.name',
                                        schemaPath: '#/properties/name/type',
                                        params: {
                                          type: 'string'
                                        },
                                        message: 'should be string'
                                      }];
                                      return false;
                                    }
                                    var valid1 = errors === errs_1;
                                  }
                                  if (valid1) {
                                    if (data.platform === undefined) {
                                      valid1 = true;
                                    } else {
                                      var errs_1 = errors;
                                      if (typeof data.platform !== "string") {
                                        validate.errors = [{
                                          keyword: 'type',
                                          dataPath: (dataPath || '') + '.platform',
                                          schemaPath: '#/properties/platform/type',
                                          params: {
                                            type: 'string'
                                          },
                                          message: 'should be string'
                                        }];
                                        return false;
                                      }
                                      var valid1 = errors === errs_1;
                                    }
                                    if (valid1) {
                                      if (data.price === undefined) {
                                        valid1 = true;
                                      } else {
                                        var errs_1 = errors;
                                        if ((typeof data.price !== "number")) {
                                          validate.errors = [{
                                            keyword: 'type',
                                            dataPath: (dataPath || '') + '.price',
                                            schemaPath: '#/properties/price/type',
                                            params: {
                                              type: 'number'
                                            },
                                            message: 'should be number'
                                          }];
                                          return false;
                                        }
                                        var valid1 = errors === errs_1;
                                      }
                                      if (valid1) {
                                        var data1 = data.product_id;
                                        if (data1 === undefined) {
                                          valid1 = false;
                                          validate.errors = [{
                                            keyword: 'required',
                                            dataPath: (dataPath || '') + "",
                                            schemaPath: '#/required',
                                            params: {
                                              missingProperty: 'product_id'
                                            },
                                            message: 'should have required property \'product_id\''
                                          }];
                                          return false;
                                        } else {
                                          var errs_1 = errors;
                                          if ((typeof data1 !== "number" || (data1 % 1) || data1 !== data1)) {
                                            validate.errors = [{
                                              keyword: 'type',
                                              dataPath: (dataPath || '') + '.product_id',
                                              schemaPath: '#/properties/product_id/type',
                                              params: {
                                                type: 'integer'
                                              },
                                              message: 'should be integer'
                                            }];
                                            return false;
                                          }
                                          var valid1 = errors === errs_1;
                                        }
                                        if (valid1) {
                                          if (data.product_line === undefined) {
                                            valid1 = false;
                                            validate.errors = [{
                                              keyword: 'required',
                                              dataPath: (dataPath || '') + "",
                                              schemaPath: '#/required',
                                              params: {
                                                missingProperty: 'product_line'
                                              },
                                              message: 'should have required property \'product_line\''
                                            }];
                                            return false;
                                          } else {
                                            var errs_1 = errors;
                                            if (typeof data.product_line !== "string") {
                                              validate.errors = [{
                                                keyword: 'type',
                                                dataPath: (dataPath || '') + '.product_line',
                                                schemaPath: '#/properties/product_line/type',
                                                params: {
                                                  type: 'string'
                                                },
                                                message: 'should be string'
                                              }];
                                              return false;
                                            }
                                            var valid1 = errors === errs_1;
                                          }
                                          if (valid1) {
                                            var data1 = data.products;
                                            if (data1 === undefined) {
                                              valid1 = true;
                                            } else {
                                              var errs_1 = errors;
                                              if (Array.isArray(data1)) {
                                                var valid1;
                                                for (var i1 = 0; i1 < data1.length; i1++) {
                                                  var data2 = data1[i1];
                                                  var errs_2 = errors;
                                                  if ((!data2 || typeof data2 !== "object" || Array.isArray(data2))) {
                                                    validate.errors = [{
                                                      keyword: 'type',
                                                      dataPath: (dataPath || '') + '.products[' + i1 + ']',
                                                      schemaPath: '#/properties/products/items/type',
                                                      params: {
                                                        type: 'object'
                                                      },
                                                      message: 'should be object'
                                                    }];
                                                    return false;
                                                  }
                                                  var valid2 = errors === errs_2;
                                                  if (!valid2) break;
                                                }
                                              } else {
                                                validate.errors = [{
                                                  keyword: 'type',
                                                  dataPath: (dataPath || '') + '.products',
                                                  schemaPath: '#/properties/products/type',
                                                  params: {
                                                    type: 'array'
                                                  },
                                                  message: 'should be array'
                                                }];
                                                return false;
                                              }
                                              var valid1 = errors === errs_1;
                                            }
                                            if (valid1) {
                                              if (data.product_type === undefined) {
                                                valid1 = true;
                                              } else {
                                                var errs_1 = errors;
                                                if (typeof data.product_type !== "string") {
                                                  validate.errors = [{
                                                    keyword: 'type',
                                                    dataPath: (dataPath || '') + '.product_type',
                                                    schemaPath: '#/properties/product_type/type',
                                                    params: {
                                                      type: 'string'
                                                    },
                                                    message: 'should be string'
                                                  }];
                                                  return false;
                                                }
                                                var valid1 = errors === errs_1;
                                              }
                                              if (valid1) {
                                                if (data.project_id === undefined) {
                                                  valid1 = false;
                                                  validate.errors = [{
                                                    keyword: 'required',
                                                    dataPath: (dataPath || '') + "",
                                                    schemaPath: '#/required',
                                                    params: {
                                                      missingProperty: 'project_id'
                                                    },
                                                    message: 'should have required property \'project_id\''
                                                  }];
                                                  return false;
                                                } else {
                                                  var errs_1 = errors;
                                                  if (typeof data.project_id !== "string") {
                                                    validate.errors = [{
                                                      keyword: 'type',
                                                      dataPath: (dataPath || '') + '.project_id',
                                                      schemaPath: '#/properties/project_id/type',
                                                      params: {
                                                        type: 'string'
                                                      },
                                                      message: 'should be string'
                                                    }];
                                                    return false;
                                                  }
                                                  var valid1 = errors === errs_1;
                                                }
                                                if (valid1) {
                                                  if (data.sku === undefined) {
                                                    valid1 = false;
                                                    validate.errors = [{
                                                      keyword: 'required',
                                                      dataPath: (dataPath || '') + "",
                                                      schemaPath: '#/required',
                                                      params: {
                                                        missingProperty: 'sku'
                                                      },
                                                      message: 'should have required property \'sku\''
                                                    }];
                                                    return false;
                                                  } else {
                                                    var errs_1 = errors;
                                                    if (typeof data.sku !== "string") {
                                                      validate.errors = [{
                                                        keyword: 'type',
                                                        dataPath: (dataPath || '') + '.sku',
                                                        schemaPath: '#/properties/sku/type',
                                                        params: {
                                                          type: 'string'
                                                        },
                                                        message: 'should be string'
                                                      }];
                                                      return false;
                                                    }
                                                    var valid1 = errors === errs_1;
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate$4.schema = {
  "$id": "https://data.amplitude.com/5ad78ff3-fd26-4b3e-95c7-ab219e3a30e0/event/Image%20Edited/version/14.0.1",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "Image Edited",
  "description": "Fire this event whenever a user edits an image within a project",
  "type": "object",
  "properties": {
    "au_project_version": {
      "description": "Editor version (1 or 2). Required for any events in Editor",
      "type": "string"
    },
    "au_split_editorV2_books": {
      "description": "INACTIVE",
      "type": "string"
    },
    "au_split_editorV2_feature_flag_autofill": {
      "description": "",
      "type": "boolean"
    },
    "au_split_editorV2_guestbookset": {
      "description": "",
      "type": "string"
    },
    "au_split_editorV2_spread_books": {
      "description": "Split for Spread Books testing",
      "type": "string"
    },
    "au_split_featureflag-chooseimagemodalflow": {
      "description": "",
      "type": "boolean"
    },
    "au_split_feature_flag_google_sign_in": {
      "description": "Property containing the split treatment for whether the Google log in/sign up buttons are shown to the user",
      "type": "boolean"
    },
    "au_split_feature_flag_product_search": {
      "description": "Property containing the split treatment for the new product search experience at commerce",
      "type": "boolean"
    },
    "category": {
      "description": "The reporting product category in Magento",
      "type": "string"
    },
    "editor_version": {
      "description": "Editor minor version (e.g. 1.62.1)",
      "type": "string"
    },
    "is_customizable_product": {
      "description": "True if the product can be customized in editor; false if it goes straight to the cart",
      "type": "boolean"
    },
    "is_mobile_view": {
      "description": "Is the user using a mobile device?",
      "type": "boolean"
    },
    "name": {
      "description": "Product name for the parent product",
      "type": "string"
    },
    "platform": {
      "description": "User's operating system",
      "type": "string"
    },
    "price": {
      "description": "Price as displayed to the customer; in some events, such as Product Viewed and Product List Viewed, this will be set to the Lowest Price since price can change based on options selected",
      "type": "number"
    },
    "product_id": {
      "description": "The product_id for the parent product",
      "type": "integer"
    },
    "product_line": {
      "description": "The reporting product line in Magento",
      "type": "string"
    },
    "products": {
      "description": "Products in the event. This is used by at least Blueshift to manage campaigns. Should contain the following properties:\n\nproduct\\_id\nsku\nname\nprice\ncategory (aka: Reporting Product Category)\nproduct\\_line (aka: Reporting Product Line)",
      "type": "array",
      "items": {
        "type": "object"
      }
    },
    "product_type": {
      "description": "The reporting product type in Magento",
      "type": "string"
    },
    "project_id": {
      "description": "",
      "type": "string"
    },
    "sku": {
      "description": "SKU for the parent product",
      "type": "string"
    }
  },
  "additionalProperties": false,
  "required": ["category", "name", "product_id", "product_line", "project_id", "sku"]
};
validate$4.errors = null;

// do not edit .js files directly - edit src/index.jst



var fastDeepEqual = function equal(a, b) {
  if (a === b) return true;

  if (a && b && typeof a == 'object' && typeof b == 'object') {
    if (a.constructor !== b.constructor) return false;

    var length, i, keys;
    if (Array.isArray(a)) {
      length = a.length;
      if (length != b.length) return false;
      for (i = length; i-- !== 0;)
        if (!equal(a[i], b[i])) return false;
      return true;
    }



    if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags;
    if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf();
    if (a.toString !== Object.prototype.toString) return a.toString() === b.toString();

    keys = Object.keys(a);
    length = keys.length;
    if (length !== Object.keys(b).length) return false;

    for (i = length; i-- !== 0;)
      if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;

    for (i = length; i-- !== 0;) {
      var key = keys[i];

      if (!equal(a[key], b[key])) return false;
    }

    return true;
  }

  // true if both NaN, false otherwise
  return a!==a && b!==b;
};

// do NOT remove this file - it would break pre-compiled schemas
// https://github.com/ajv-validator/ajv/issues/889
var equal = fastDeepEqual;

var validate$5 = (function() {
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    var vErrors = null;
    var errors = 0;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      {
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !( validate.schema.properties.hasOwnProperty(key0));
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
          }
        }
        if (valid1) {
          if (data.au_project_version === undefined) {
            valid1 = true;
          } else {
            var errs_1 = errors;
            if (typeof data.au_project_version !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.au_project_version',
                schemaPath: '#/properties/au_project_version/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.au_split_editorV2_books === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (typeof data.au_split_editorV2_books !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.au_split_editorV2_books',
                  schemaPath: '#/properties/au_split_editorV2_books/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.au_split_editorV2_feature_flag_autofill === undefined) {
                valid1 = true;
              } else {
                var errs_1 = errors;
                if (typeof data.au_split_editorV2_feature_flag_autofill !== "boolean") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.au_split_editorV2_feature_flag_autofill',
                    schemaPath: '#/properties/au_split_editorV2_feature_flag_autofill/type',
                    params: {
                      type: 'boolean'
                    },
                    message: 'should be boolean'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.au_split_editorV2_guestbookset === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (typeof data.au_split_editorV2_guestbookset !== "string") {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.au_split_editorV2_guestbookset',
                      schemaPath: '#/properties/au_split_editorV2_guestbookset/type',
                      params: {
                        type: 'string'
                      },
                      message: 'should be string'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.au_split_editorV2_spread_books === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (typeof data.au_split_editorV2_spread_books !== "string") {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.au_split_editorV2_spread_books',
                        schemaPath: '#/properties/au_split_editorV2_spread_books/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data['au_split_featureflag-chooseimagemodalflow'] === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (typeof data['au_split_featureflag-chooseimagemodalflow'] !== "boolean") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '[\'au_split_featureflag-chooseimagemodalflow\']',
                          schemaPath: '#/properties/au_split_featureflag-chooseimagemodalflow/type',
                          params: {
                            type: 'boolean'
                          },
                          message: 'should be boolean'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.au_split_feature_flag_google_sign_in === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (typeof data.au_split_feature_flag_google_sign_in !== "boolean") {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.au_split_feature_flag_google_sign_in',
                            schemaPath: '#/properties/au_split_feature_flag_google_sign_in/type',
                            params: {
                              type: 'boolean'
                            },
                            message: 'should be boolean'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.au_split_feature_flag_product_search === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.au_split_feature_flag_product_search !== "boolean") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.au_split_feature_flag_product_search',
                              schemaPath: '#/properties/au_split_feature_flag_product_search/type',
                              params: {
                                type: 'boolean'
                              },
                              message: 'should be boolean'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          if (data.category === undefined) {
                            valid1 = false;
                            validate.errors = [{
                              keyword: 'required',
                              dataPath: (dataPath || '') + "",
                              schemaPath: '#/required',
                              params: {
                                missingProperty: 'category'
                              },
                              message: 'should have required property \'category\''
                            }];
                            return false;
                          } else {
                            var errs_1 = errors;
                            if (typeof data.category !== "string") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.category',
                                schemaPath: '#/properties/category/type',
                                params: {
                                  type: 'string'
                                },
                                message: 'should be string'
                              }];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                            if (data.editor_version === undefined) {
                              valid1 = true;
                            } else {
                              var errs_1 = errors;
                              if (typeof data.editor_version !== "string") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.editor_version',
                                  schemaPath: '#/properties/editor_version/type',
                                  params: {
                                    type: 'string'
                                  },
                                  message: 'should be string'
                                }];
                                return false;
                              }
                              var valid1 = errors === errs_1;
                            }
                            if (valid1) {
                              if (data.gallery_name === undefined) {
                                valid1 = false;
                                validate.errors = [{
                                  keyword: 'required',
                                  dataPath: (dataPath || '') + "",
                                  schemaPath: '#/required',
                                  params: {
                                    missingProperty: 'gallery_name'
                                  },
                                  message: 'should have required property \'gallery_name\''
                                }];
                                return false;
                              } else {
                                var errs_1 = errors;
                                if (typeof data.gallery_name !== "string") {
                                  validate.errors = [{
                                    keyword: 'type',
                                    dataPath: (dataPath || '') + '.gallery_name',
                                    schemaPath: '#/properties/gallery_name/type',
                                    params: {
                                      type: 'string'
                                    },
                                    message: 'should be string'
                                  }];
                                  return false;
                                }
                                var valid1 = errors === errs_1;
                              }
                              if (valid1) {
                                if (data.image_source === undefined) {
                                  valid1 = true;
                                } else {
                                  var errs_1 = errors;
                                  var schema1 = validate.schema.properties.image_source.enum;
                                  var valid1;
                                  valid1 = false;
                                  for (var i1 = 0; i1 < schema1.length; i1++)
                                    if (equal(data.image_source, schema1[i1])) {
                                      valid1 = true;
                                      break;
                                    } if (!valid1) {
                                    validate.errors = [{
                                      keyword: 'enum',
                                      dataPath: (dataPath || '') + '.image_source',
                                      schemaPath: '#/properties/image_source/enum',
                                      params: {
                                        allowedValues: schema1
                                      },
                                      message: 'should be equal to one of the allowed values'
                                    }];
                                    return false;
                                  }
                                  var valid1 = errors === errs_1;
                                }
                                if (valid1) {
                                  if (data.is_customizable_product === undefined) {
                                    valid1 = true;
                                  } else {
                                    var errs_1 = errors;
                                    if (typeof data.is_customizable_product !== "boolean") {
                                      validate.errors = [{
                                        keyword: 'type',
                                        dataPath: (dataPath || '') + '.is_customizable_product',
                                        schemaPath: '#/properties/is_customizable_product/type',
                                        params: {
                                          type: 'boolean'
                                        },
                                        message: 'should be boolean'
                                      }];
                                      return false;
                                    }
                                    var valid1 = errors === errs_1;
                                  }
                                  if (valid1) {
                                    if (data.is_mobile_view === undefined) {
                                      valid1 = true;
                                    } else {
                                      var errs_1 = errors;
                                      if (typeof data.is_mobile_view !== "boolean") {
                                        validate.errors = [{
                                          keyword: 'type',
                                          dataPath: (dataPath || '') + '.is_mobile_view',
                                          schemaPath: '#/properties/is_mobile_view/type',
                                          params: {
                                            type: 'boolean'
                                          },
                                          message: 'should be boolean'
                                        }];
                                        return false;
                                      }
                                      var valid1 = errors === errs_1;
                                    }
                                    if (valid1) {
                                      if (data.name === undefined) {
                                        valid1 = false;
                                        validate.errors = [{
                                          keyword: 'required',
                                          dataPath: (dataPath || '') + "",
                                          schemaPath: '#/required',
                                          params: {
                                            missingProperty: 'name'
                                          },
                                          message: 'should have required property \'name\''
                                        }];
                                        return false;
                                      } else {
                                        var errs_1 = errors;
                                        if (typeof data.name !== "string") {
                                          validate.errors = [{
                                            keyword: 'type',
                                            dataPath: (dataPath || '') + '.name',
                                            schemaPath: '#/properties/name/type',
                                            params: {
                                              type: 'string'
                                            },
                                            message: 'should be string'
                                          }];
                                          return false;
                                        }
                                        var valid1 = errors === errs_1;
                                      }
                                      if (valid1) {
                                        if (data.platform === undefined) {
                                          valid1 = true;
                                        } else {
                                          var errs_1 = errors;
                                          if (typeof data.platform !== "string") {
                                            validate.errors = [{
                                              keyword: 'type',
                                              dataPath: (dataPath || '') + '.platform',
                                              schemaPath: '#/properties/platform/type',
                                              params: {
                                                type: 'string'
                                              },
                                              message: 'should be string'
                                            }];
                                            return false;
                                          }
                                          var valid1 = errors === errs_1;
                                        }
                                        if (valid1) {
                                          if (data.price === undefined) {
                                            valid1 = true;
                                          } else {
                                            var errs_1 = errors;
                                            if ((typeof data.price !== "number")) {
                                              validate.errors = [{
                                                keyword: 'type',
                                                dataPath: (dataPath || '') + '.price',
                                                schemaPath: '#/properties/price/type',
                                                params: {
                                                  type: 'number'
                                                },
                                                message: 'should be number'
                                              }];
                                              return false;
                                            }
                                            var valid1 = errors === errs_1;
                                          }
                                          if (valid1) {
                                            var data1 = data.product_id;
                                            if (data1 === undefined) {
                                              valid1 = false;
                                              validate.errors = [{
                                                keyword: 'required',
                                                dataPath: (dataPath || '') + "",
                                                schemaPath: '#/required',
                                                params: {
                                                  missingProperty: 'product_id'
                                                },
                                                message: 'should have required property \'product_id\''
                                              }];
                                              return false;
                                            } else {
                                              var errs_1 = errors;
                                              if ((typeof data1 !== "number" || (data1 % 1) || data1 !== data1)) {
                                                validate.errors = [{
                                                  keyword: 'type',
                                                  dataPath: (dataPath || '') + '.product_id',
                                                  schemaPath: '#/properties/product_id/type',
                                                  params: {
                                                    type: 'integer'
                                                  },
                                                  message: 'should be integer'
                                                }];
                                                return false;
                                              }
                                              var valid1 = errors === errs_1;
                                            }
                                            if (valid1) {
                                              if (data.product_line === undefined) {
                                                valid1 = false;
                                                validate.errors = [{
                                                  keyword: 'required',
                                                  dataPath: (dataPath || '') + "",
                                                  schemaPath: '#/required',
                                                  params: {
                                                    missingProperty: 'product_line'
                                                  },
                                                  message: 'should have required property \'product_line\''
                                                }];
                                                return false;
                                              } else {
                                                var errs_1 = errors;
                                                if (typeof data.product_line !== "string") {
                                                  validate.errors = [{
                                                    keyword: 'type',
                                                    dataPath: (dataPath || '') + '.product_line',
                                                    schemaPath: '#/properties/product_line/type',
                                                    params: {
                                                      type: 'string'
                                                    },
                                                    message: 'should be string'
                                                  }];
                                                  return false;
                                                }
                                                var valid1 = errors === errs_1;
                                              }
                                              if (valid1) {
                                                var data1 = data.products;
                                                if (data1 === undefined) {
                                                  valid1 = true;
                                                } else {
                                                  var errs_1 = errors;
                                                  if (Array.isArray(data1)) {
                                                    var valid1;
                                                    for (var i1 = 0; i1 < data1.length; i1++) {
                                                      var data2 = data1[i1];
                                                      var errs_2 = errors;
                                                      if ((!data2 || typeof data2 !== "object" || Array.isArray(data2))) {
                                                        validate.errors = [{
                                                          keyword: 'type',
                                                          dataPath: (dataPath || '') + '.products[' + i1 + ']',
                                                          schemaPath: '#/properties/products/items/type',
                                                          params: {
                                                            type: 'object'
                                                          },
                                                          message: 'should be object'
                                                        }];
                                                        return false;
                                                      }
                                                      var valid2 = errors === errs_2;
                                                      if (!valid2) break;
                                                    }
                                                  } else {
                                                    validate.errors = [{
                                                      keyword: 'type',
                                                      dataPath: (dataPath || '') + '.products',
                                                      schemaPath: '#/properties/products/type',
                                                      params: {
                                                        type: 'array'
                                                      },
                                                      message: 'should be array'
                                                    }];
                                                    return false;
                                                  }
                                                  var valid1 = errors === errs_1;
                                                }
                                                if (valid1) {
                                                  if (data.product_type === undefined) {
                                                    valid1 = true;
                                                  } else {
                                                    var errs_1 = errors;
                                                    if (typeof data.product_type !== "string") {
                                                      validate.errors = [{
                                                        keyword: 'type',
                                                        dataPath: (dataPath || '') + '.product_type',
                                                        schemaPath: '#/properties/product_type/type',
                                                        params: {
                                                          type: 'string'
                                                        },
                                                        message: 'should be string'
                                                      }];
                                                      return false;
                                                    }
                                                    var valid1 = errors === errs_1;
                                                  }
                                                  if (valid1) {
                                                    if (data.project_id === undefined) {
                                                      valid1 = false;
                                                      validate.errors = [{
                                                        keyword: 'required',
                                                        dataPath: (dataPath || '') + "",
                                                        schemaPath: '#/required',
                                                        params: {
                                                          missingProperty: 'project_id'
                                                        },
                                                        message: 'should have required property \'project_id\''
                                                      }];
                                                      return false;
                                                    } else {
                                                      var errs_1 = errors;
                                                      if (typeof data.project_id !== "string") {
                                                        validate.errors = [{
                                                          keyword: 'type',
                                                          dataPath: (dataPath || '') + '.project_id',
                                                          schemaPath: '#/properties/project_id/type',
                                                          params: {
                                                            type: 'string'
                                                          },
                                                          message: 'should be string'
                                                        }];
                                                        return false;
                                                      }
                                                      var valid1 = errors === errs_1;
                                                    }
                                                    if (valid1) {
                                                      if (data.sku === undefined) {
                                                        valid1 = false;
                                                        validate.errors = [{
                                                          keyword: 'required',
                                                          dataPath: (dataPath || '') + "",
                                                          schemaPath: '#/required',
                                                          params: {
                                                            missingProperty: 'sku'
                                                          },
                                                          message: 'should have required property \'sku\''
                                                        }];
                                                        return false;
                                                      } else {
                                                        var errs_1 = errors;
                                                        if (typeof data.sku !== "string") {
                                                          validate.errors = [{
                                                            keyword: 'type',
                                                            dataPath: (dataPath || '') + '.sku',
                                                            schemaPath: '#/properties/sku/type',
                                                            params: {
                                                              type: 'string'
                                                            },
                                                            message: 'should be string'
                                                          }];
                                                          return false;
                                                        }
                                                        var valid1 = errors === errs_1;
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate$5.schema = {
  "$id": "https://data.amplitude.com/5ad78ff3-fd26-4b3e-95c7-ab219e3a30e0/event/Image%20Uploaded/version/16.0.1",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "Image Uploaded",
  "description": "Called when an image is uploaded to a gallery",
  "type": "object",
  "properties": {
    "au_project_version": {
      "description": "Editor version (1 or 2). Required for any events in Editor",
      "type": "string"
    },
    "au_split_editorV2_books": {
      "description": "INACTIVE",
      "type": "string"
    },
    "au_split_editorV2_feature_flag_autofill": {
      "description": "",
      "type": "boolean"
    },
    "au_split_editorV2_guestbookset": {
      "description": "",
      "type": "string"
    },
    "au_split_editorV2_spread_books": {
      "description": "Split for Spread Books testing",
      "type": "string"
    },
    "au_split_featureflag-chooseimagemodalflow": {
      "description": "",
      "type": "boolean"
    },
    "au_split_feature_flag_google_sign_in": {
      "description": "Property containing the split treatment for whether the Google log in/sign up buttons are shown to the user",
      "type": "boolean"
    },
    "au_split_feature_flag_product_search": {
      "description": "Property containing the split treatment for the new product search experience at commerce",
      "type": "boolean"
    },
    "category": {
      "description": "The reporting product category in Magento",
      "type": "string"
    },
    "editor_version": {
      "description": "Editor minor version (e.g. 1.62.1)",
      "type": "string"
    },
    "gallery_name": {
      "description": "",
      "type": "string"
    },
    "image_source": {
      "description": "Facebook, Google Photos, Instagram, Direct Upload, etc.",
      "enum": ["dropbox", "facebook", "google_photos", "instagram", "direct-upload"]
    },
    "is_customizable_product": {
      "description": "True if the product can be customized in editor; false if it goes straight to the cart",
      "type": "boolean"
    },
    "is_mobile_view": {
      "description": "Is the user using a mobile device?",
      "type": "boolean"
    },
    "name": {
      "description": "Product name for the parent product",
      "type": "string"
    },
    "platform": {
      "description": "User's operating system",
      "type": "string"
    },
    "price": {
      "description": "Price as displayed to the customer; in some events, such as Product Viewed and Product List Viewed, this will be set to the Lowest Price since price can change based on options selected",
      "type": "number"
    },
    "product_id": {
      "description": "The product_id for the parent product",
      "type": "integer"
    },
    "product_line": {
      "description": "The reporting product line in Magento",
      "type": "string"
    },
    "products": {
      "description": "Products in the event. This is used by at least Blueshift to manage campaigns. Should contain the following properties:\n\nproduct\\_id\nsku\nname\nprice\ncategory (aka: Reporting Product Category)\nproduct\\_line (aka: Reporting Product Line)",
      "type": "array",
      "items": {
        "type": "object"
      }
    },
    "product_type": {
      "description": "The reporting product type in Magento",
      "type": "string"
    },
    "project_id": {
      "description": "",
      "type": "string"
    },
    "sku": {
      "description": "SKU for the parent product",
      "type": "string"
    }
  },
  "additionalProperties": false,
  "required": ["category", "gallery_name", "name", "product_id", "product_line", "project_id", "sku"]
};
validate$5.errors = null;

var validate$6 = (function() {
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    var vErrors = null;
    var errors = 0;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      {
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !( key0 == 'page_name' || key0 == 'page_type');
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
          }
        }
        if (valid1) {
          if (data.page_name === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'page_name'
              },
              message: 'should have required property \'page_name\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data.page_name !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.page_name',
                schemaPath: '#/properties/page_name/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.page_type === undefined) {
              valid1 = false;
              validate.errors = [{
                keyword: 'required',
                dataPath: (dataPath || '') + "",
                schemaPath: '#/required',
                params: {
                  missingProperty: 'page_type'
                },
                message: 'should have required property \'page_type\''
              }];
              return false;
            } else {
              var errs_1 = errors;
              if (typeof data.page_type !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.page_type',
                  schemaPath: '#/properties/page_type/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
          }
        }
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate$6.schema = {
  "$id": "https://data.amplitude.com/5ad78ff3-fd26-4b3e-95c7-ab219e3a30e0/event/Page%20Viewed/version/1.0.0",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "Page Viewed",
  "description": "Called when any page is viewed. Should be called whenever \"Page\" is called. Only call once when in the editor.",
  "type": "object",
  "properties": {
    "page_name": {
      "description": "Home, Floating Picture Frame, Shopping Cart, etc.",
      "type": "string"
    },
    "page_type": {
      "description": "Product Description Page, Category Listing Page, My Account, Checkout, HomePage",
      "type": "string"
    }
  },
  "additionalProperties": false,
  "required": ["page_name", "page_type"]
};
validate$6.errors = null;

var validate$7 = (function() {
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    var vErrors = null;
    var errors = 0;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      {
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !( validate.schema.properties.hasOwnProperty(key0));
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
          }
        }
        if (valid1) {
          var data1 = data.ab_test;
          if (data1 === undefined) {
            valid1 = true;
          } else {
            var errs_1 = errors;
            if ((!data1 || typeof data1 !== "object" || Array.isArray(data1))) {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.ab_test',
                schemaPath: '#/properties/ab_test/type',
                params: {
                  type: 'object'
                },
                message: 'should be object'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.au_project_version === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (typeof data.au_project_version !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.au_project_version',
                  schemaPath: '#/properties/au_project_version/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.au_split_editorV2_books === undefined) {
                valid1 = true;
              } else {
                var errs_1 = errors;
                if (typeof data.au_split_editorV2_books !== "string") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.au_split_editorV2_books',
                    schemaPath: '#/properties/au_split_editorV2_books/type',
                    params: {
                      type: 'string'
                    },
                    message: 'should be string'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.au_split_editorV2_feature_flag_autofill === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (typeof data.au_split_editorV2_feature_flag_autofill !== "boolean") {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.au_split_editorV2_feature_flag_autofill',
                      schemaPath: '#/properties/au_split_editorV2_feature_flag_autofill/type',
                      params: {
                        type: 'boolean'
                      },
                      message: 'should be boolean'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.au_split_editorV2_guestbookset === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (typeof data.au_split_editorV2_guestbookset !== "string") {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.au_split_editorV2_guestbookset',
                        schemaPath: '#/properties/au_split_editorV2_guestbookset/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data.au_split_editorV2_spread_books === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (typeof data.au_split_editorV2_spread_books !== "string") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.au_split_editorV2_spread_books',
                          schemaPath: '#/properties/au_split_editorV2_spread_books/type',
                          params: {
                            type: 'string'
                          },
                          message: 'should be string'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data['au_split_featureflag-chooseimagemodalflow'] === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (typeof data['au_split_featureflag-chooseimagemodalflow'] !== "boolean") {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '[\'au_split_featureflag-chooseimagemodalflow\']',
                            schemaPath: '#/properties/au_split_featureflag-chooseimagemodalflow/type',
                            params: {
                              type: 'boolean'
                            },
                            message: 'should be boolean'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.au_split_feature_flag_google_sign_in === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.au_split_feature_flag_google_sign_in !== "boolean") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.au_split_feature_flag_google_sign_in',
                              schemaPath: '#/properties/au_split_feature_flag_google_sign_in/type',
                              params: {
                                type: 'boolean'
                              },
                              message: 'should be boolean'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          if (data.au_split_feature_flag_product_search === undefined) {
                            valid1 = true;
                          } else {
                            var errs_1 = errors;
                            if (typeof data.au_split_feature_flag_product_search !== "boolean") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.au_split_feature_flag_product_search',
                                schemaPath: '#/properties/au_split_feature_flag_product_search/type',
                                params: {
                                  type: 'boolean'
                                },
                                message: 'should be boolean'
                              }];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                            if (data.category === undefined) {
                              valid1 = false;
                              validate.errors = [{
                                keyword: 'required',
                                dataPath: (dataPath || '') + "",
                                schemaPath: '#/required',
                                params: {
                                  missingProperty: 'category'
                                },
                                message: 'should have required property \'category\''
                              }];
                              return false;
                            } else {
                              var errs_1 = errors;
                              if (typeof data.category !== "string") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.category',
                                  schemaPath: '#/properties/category/type',
                                  params: {
                                    type: 'string'
                                  },
                                  message: 'should be string'
                                }];
                                return false;
                              }
                              var valid1 = errors === errs_1;
                            }
                            if (valid1) {
                              if (data.editor_version === undefined) {
                                valid1 = true;
                              } else {
                                var errs_1 = errors;
                                if (typeof data.editor_version !== "string") {
                                  validate.errors = [{
                                    keyword: 'type',
                                    dataPath: (dataPath || '') + '.editor_version',
                                    schemaPath: '#/properties/editor_version/type',
                                    params: {
                                      type: 'string'
                                    },
                                    message: 'should be string'
                                  }];
                                  return false;
                                }
                                var valid1 = errors === errs_1;
                              }
                              if (valid1) {
                                if (data.is_customizable_product === undefined) {
                                  valid1 = true;
                                } else {
                                  var errs_1 = errors;
                                  if (typeof data.is_customizable_product !== "boolean") {
                                    validate.errors = [{
                                      keyword: 'type',
                                      dataPath: (dataPath || '') + '.is_customizable_product',
                                      schemaPath: '#/properties/is_customizable_product/type',
                                      params: {
                                        type: 'boolean'
                                      },
                                      message: 'should be boolean'
                                    }];
                                    return false;
                                  }
                                  var valid1 = errors === errs_1;
                                }
                                if (valid1) {
                                  if (data.is_mobile_view === undefined) {
                                    valid1 = true;
                                  } else {
                                    var errs_1 = errors;
                                    if (typeof data.is_mobile_view !== "boolean") {
                                      validate.errors = [{
                                        keyword: 'type',
                                        dataPath: (dataPath || '') + '.is_mobile_view',
                                        schemaPath: '#/properties/is_mobile_view/type',
                                        params: {
                                          type: 'boolean'
                                        },
                                        message: 'should be boolean'
                                      }];
                                      return false;
                                    }
                                    var valid1 = errors === errs_1;
                                  }
                                  if (valid1) {
                                    if (data.name === undefined) {
                                      valid1 = false;
                                      validate.errors = [{
                                        keyword: 'required',
                                        dataPath: (dataPath || '') + "",
                                        schemaPath: '#/required',
                                        params: {
                                          missingProperty: 'name'
                                        },
                                        message: 'should have required property \'name\''
                                      }];
                                      return false;
                                    } else {
                                      var errs_1 = errors;
                                      if (typeof data.name !== "string") {
                                        validate.errors = [{
                                          keyword: 'type',
                                          dataPath: (dataPath || '') + '.name',
                                          schemaPath: '#/properties/name/type',
                                          params: {
                                            type: 'string'
                                          },
                                          message: 'should be string'
                                        }];
                                        return false;
                                      }
                                      var valid1 = errors === errs_1;
                                    }
                                    if (valid1) {
                                      if (data.platform === undefined) {
                                        valid1 = true;
                                      } else {
                                        var errs_1 = errors;
                                        if (typeof data.platform !== "string") {
                                          validate.errors = [{
                                            keyword: 'type',
                                            dataPath: (dataPath || '') + '.platform',
                                            schemaPath: '#/properties/platform/type',
                                            params: {
                                              type: 'string'
                                            },
                                            message: 'should be string'
                                          }];
                                          return false;
                                        }
                                        var valid1 = errors === errs_1;
                                      }
                                      if (valid1) {
                                        if (data.price === undefined) {
                                          valid1 = true;
                                        } else {
                                          var errs_1 = errors;
                                          if ((typeof data.price !== "number")) {
                                            validate.errors = [{
                                              keyword: 'type',
                                              dataPath: (dataPath || '') + '.price',
                                              schemaPath: '#/properties/price/type',
                                              params: {
                                                type: 'number'
                                              },
                                              message: 'should be number'
                                            }];
                                            return false;
                                          }
                                          var valid1 = errors === errs_1;
                                        }
                                        if (valid1) {
                                          var data1 = data.product_id;
                                          if (data1 === undefined) {
                                            valid1 = false;
                                            validate.errors = [{
                                              keyword: 'required',
                                              dataPath: (dataPath || '') + "",
                                              schemaPath: '#/required',
                                              params: {
                                                missingProperty: 'product_id'
                                              },
                                              message: 'should have required property \'product_id\''
                                            }];
                                            return false;
                                          } else {
                                            var errs_1 = errors;
                                            if ((typeof data1 !== "number" || (data1 % 1) || data1 !== data1)) {
                                              validate.errors = [{
                                                keyword: 'type',
                                                dataPath: (dataPath || '') + '.product_id',
                                                schemaPath: '#/properties/product_id/type',
                                                params: {
                                                  type: 'integer'
                                                },
                                                message: 'should be integer'
                                              }];
                                              return false;
                                            }
                                            var valid1 = errors === errs_1;
                                          }
                                          if (valid1) {
                                            if (data.product_line === undefined) {
                                              valid1 = false;
                                              validate.errors = [{
                                                keyword: 'required',
                                                dataPath: (dataPath || '') + "",
                                                schemaPath: '#/required',
                                                params: {
                                                  missingProperty: 'product_line'
                                                },
                                                message: 'should have required property \'product_line\''
                                              }];
                                              return false;
                                            } else {
                                              var errs_1 = errors;
                                              if (typeof data.product_line !== "string") {
                                                validate.errors = [{
                                                  keyword: 'type',
                                                  dataPath: (dataPath || '') + '.product_line',
                                                  schemaPath: '#/properties/product_line/type',
                                                  params: {
                                                    type: 'string'
                                                  },
                                                  message: 'should be string'
                                                }];
                                                return false;
                                              }
                                              var valid1 = errors === errs_1;
                                            }
                                            if (valid1) {
                                              var data1 = data.products;
                                              if (data1 === undefined) {
                                                valid1 = true;
                                              } else {
                                                var errs_1 = errors;
                                                if (Array.isArray(data1)) {
                                                  var valid1;
                                                  for (var i1 = 0; i1 < data1.length; i1++) {
                                                    var data2 = data1[i1];
                                                    var errs_2 = errors;
                                                    if ((!data2 || typeof data2 !== "object" || Array.isArray(data2))) {
                                                      validate.errors = [{
                                                        keyword: 'type',
                                                        dataPath: (dataPath || '') + '.products[' + i1 + ']',
                                                        schemaPath: '#/properties/products/items/type',
                                                        params: {
                                                          type: 'object'
                                                        },
                                                        message: 'should be object'
                                                      }];
                                                      return false;
                                                    }
                                                    var valid2 = errors === errs_2;
                                                    if (!valid2) break;
                                                  }
                                                } else {
                                                  validate.errors = [{
                                                    keyword: 'type',
                                                    dataPath: (dataPath || '') + '.products',
                                                    schemaPath: '#/properties/products/type',
                                                    params: {
                                                      type: 'array'
                                                    },
                                                    message: 'should be array'
                                                  }];
                                                  return false;
                                                }
                                                var valid1 = errors === errs_1;
                                              }
                                              if (valid1) {
                                                if (data.product_type === undefined) {
                                                  valid1 = true;
                                                } else {
                                                  var errs_1 = errors;
                                                  if (typeof data.product_type !== "string") {
                                                    validate.errors = [{
                                                      keyword: 'type',
                                                      dataPath: (dataPath || '') + '.product_type',
                                                      schemaPath: '#/properties/product_type/type',
                                                      params: {
                                                        type: 'string'
                                                      },
                                                      message: 'should be string'
                                                    }];
                                                    return false;
                                                  }
                                                  var valid1 = errors === errs_1;
                                                }
                                                if (valid1) {
                                                  if (data.project_id === undefined) {
                                                    valid1 = true;
                                                  } else {
                                                    var errs_1 = errors;
                                                    if (typeof data.project_id !== "string") {
                                                      validate.errors = [{
                                                        keyword: 'type',
                                                        dataPath: (dataPath || '') + '.project_id',
                                                        schemaPath: '#/properties/project_id/type',
                                                        params: {
                                                          type: 'string'
                                                        },
                                                        message: 'should be string'
                                                      }];
                                                      return false;
                                                    }
                                                    var valid1 = errors === errs_1;
                                                  }
                                                  if (valid1) {
                                                    if (data.sku === undefined) {
                                                      valid1 = false;
                                                      validate.errors = [{
                                                        keyword: 'required',
                                                        dataPath: (dataPath || '') + "",
                                                        schemaPath: '#/required',
                                                        params: {
                                                          missingProperty: 'sku'
                                                        },
                                                        message: 'should have required property \'sku\''
                                                      }];
                                                      return false;
                                                    } else {
                                                      var errs_1 = errors;
                                                      if (typeof data.sku !== "string") {
                                                        validate.errors = [{
                                                          keyword: 'type',
                                                          dataPath: (dataPath || '') + '.sku',
                                                          schemaPath: '#/properties/sku/type',
                                                          params: {
                                                            type: 'string'
                                                          },
                                                          message: 'should be string'
                                                        }];
                                                        return false;
                                                      }
                                                      var valid1 = errors === errs_1;
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate$7.schema = {
  "$id": "https://data.amplitude.com/5ad78ff3-fd26-4b3e-95c7-ab219e3a30e0/event/Product%20Added/version/15.0.1",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "Product Added",
  "description": "User added a product to their shopping cart",
  "type": "object",
  "properties": {
    "ab_test": {
      "description": "List of AB tests in this format: upload_experience = \"new_experience\"",
      "type": "object"
    },
    "au_project_version": {
      "description": "Editor version (1 or 2). Required for any events in Editor",
      "type": "string"
    },
    "au_split_editorV2_books": {
      "description": "INACTIVE",
      "type": "string"
    },
    "au_split_editorV2_feature_flag_autofill": {
      "description": "",
      "type": "boolean"
    },
    "au_split_editorV2_guestbookset": {
      "description": "",
      "type": "string"
    },
    "au_split_editorV2_spread_books": {
      "description": "Split for Spread Books testing",
      "type": "string"
    },
    "au_split_featureflag-chooseimagemodalflow": {
      "description": "",
      "type": "boolean"
    },
    "au_split_feature_flag_google_sign_in": {
      "description": "Property containing the split treatment for whether the Google log in/sign up buttons are shown to the user",
      "type": "boolean"
    },
    "au_split_feature_flag_product_search": {
      "description": "Property containing the split treatment for the new product search experience at commerce",
      "type": "boolean"
    },
    "category": {
      "description": "The reporting product category in Magento",
      "type": "string"
    },
    "editor_version": {
      "description": "Editor minor version (e.g. 1.62.1)",
      "type": "string"
    },
    "is_customizable_product": {
      "description": "True if the product can be customized in editor; false if it goes straight to the cart",
      "type": "boolean"
    },
    "is_mobile_view": {
      "description": "Is the user using a mobile device?",
      "type": "boolean"
    },
    "name": {
      "description": "Product name for the parent product",
      "type": "string"
    },
    "platform": {
      "description": "User's operating system",
      "type": "string"
    },
    "price": {
      "description": "Price as displayed to the customer; in some events, such as Product Viewed and Product List Viewed, this will be set to the Lowest Price since price can change based on options selected",
      "type": "number"
    },
    "product_id": {
      "description": "The product_id for the parent product",
      "type": "integer"
    },
    "product_line": {
      "description": "The reporting product line in Magento",
      "type": "string"
    },
    "products": {
      "description": "Products in the event. This is used by at least Blueshift to manage campaigns. Should contain the following properties:\n\nproduct\\_id\nsku\nname\nprice\ncategory (aka: Reporting Product Category)\nproduct\\_line (aka: Reporting Product Line)",
      "type": "array",
      "items": {
        "type": "object"
      }
    },
    "product_type": {
      "description": "The reporting product type in Magento",
      "type": "string"
    },
    "project_id": {
      "description": "",
      "type": "string"
    },
    "sku": {
      "description": "SKU for the parent product",
      "type": "string"
    },
    "email": {
      "description": "Customer's email address",
      "type": "string"
    },
    "customer_ip": {
      "description": "Customer's IP address",
      "type": "string"
    },
    "location": {
      "description": "Location of add product event. In this case it will always be editor.",
      "type": "string"
    }
  },
  "additionalProperties": false,
  "required": ["category", "name", "product_id", "product_line", "sku"]
};
validate$7.errors = null;

var validate$8 = (function() {
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    var vErrors = null;
    var errors = 0;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      {
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !( validate.schema.properties.hasOwnProperty(key0));
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
          }
        }
        if (valid1) {
          if (data.category === undefined) {
            valid1 = false;
            validate.errors = [{
              keyword: 'required',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/required',
              params: {
                missingProperty: 'category'
              },
              message: 'should have required property \'category\''
            }];
            return false;
          } else {
            var errs_1 = errors;
            if (typeof data.category !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.category',
                schemaPath: '#/properties/category/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.is_customizable_product === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (typeof data.is_customizable_product !== "boolean") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.is_customizable_product',
                  schemaPath: '#/properties/is_customizable_product/type',
                  params: {
                    type: 'boolean'
                  },
                  message: 'should be boolean'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.name === undefined) {
                valid1 = false;
                validate.errors = [{
                  keyword: 'required',
                  dataPath: (dataPath || '') + "",
                  schemaPath: '#/required',
                  params: {
                    missingProperty: 'name'
                  },
                  message: 'should have required property \'name\''
                }];
                return false;
              } else {
                var errs_1 = errors;
                if (typeof data.name !== "string") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.name',
                    schemaPath: '#/properties/name/type',
                    params: {
                      type: 'string'
                    },
                    message: 'should be string'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.price === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if ((typeof data.price !== "number")) {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.price',
                      schemaPath: '#/properties/price/type',
                      params: {
                        type: 'number'
                      },
                      message: 'should be number'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  var data1 = data.product_id;
                  if (data1 === undefined) {
                    valid1 = false;
                    validate.errors = [{
                      keyword: 'required',
                      dataPath: (dataPath || '') + "",
                      schemaPath: '#/required',
                      params: {
                        missingProperty: 'product_id'
                      },
                      message: 'should have required property \'product_id\''
                    }];
                    return false;
                  } else {
                    var errs_1 = errors;
                    if ((typeof data1 !== "number" || (data1 % 1) || data1 !== data1)) {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.product_id',
                        schemaPath: '#/properties/product_id/type',
                        params: {
                          type: 'integer'
                        },
                        message: 'should be integer'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data.product_line === undefined) {
                      valid1 = false;
                      validate.errors = [{
                        keyword: 'required',
                        dataPath: (dataPath || '') + "",
                        schemaPath: '#/required',
                        params: {
                          missingProperty: 'product_line'
                        },
                        message: 'should have required property \'product_line\''
                      }];
                      return false;
                    } else {
                      var errs_1 = errors;
                      if (typeof data.product_line !== "string") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '.product_line',
                          schemaPath: '#/properties/product_line/type',
                          params: {
                            type: 'string'
                          },
                          message: 'should be string'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      var data1 = data.products;
                      if (data1 === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (Array.isArray(data1)) {
                          var valid1;
                          for (var i1 = 0; i1 < data1.length; i1++) {
                            var data2 = data1[i1];
                            var errs_2 = errors;
                            if ((!data2 || typeof data2 !== "object" || Array.isArray(data2))) {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.products[' + i1 + ']',
                                schemaPath: '#/properties/products/items/type',
                                params: {
                                  type: 'object'
                                },
                                message: 'should be object'
                              }];
                              return false;
                            }
                            var valid2 = errors === errs_2;
                            if (!valid2) break;
                          }
                        } else {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.products',
                            schemaPath: '#/properties/products/type',
                            params: {
                              type: 'array'
                            },
                            message: 'should be array'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.product_type === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.product_type !== "string") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.product_type',
                              schemaPath: '#/properties/product_type/type',
                              params: {
                                type: 'string'
                              },
                              message: 'should be string'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          if (data.project_id === undefined) {
                            valid1 = false;
                            validate.errors = [{
                              keyword: 'required',
                              dataPath: (dataPath || '') + "",
                              schemaPath: '#/required',
                              params: {
                                missingProperty: 'project_id'
                              },
                              message: 'should have required property \'project_id\''
                            }];
                            return false;
                          } else {
                            var errs_1 = errors;
                            if (typeof data.project_id !== "string") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.project_id',
                                schemaPath: '#/properties/project_id/type',
                                params: {
                                  type: 'string'
                                },
                                message: 'should be string'
                              }];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                            if (data.sku === undefined) {
                              valid1 = false;
                              validate.errors = [{
                                keyword: 'required',
                                dataPath: (dataPath || '') + "",
                                schemaPath: '#/required',
                                params: {
                                  missingProperty: 'sku'
                                },
                                message: 'should have required property \'sku\''
                              }];
                              return false;
                            } else {
                              var errs_1 = errors;
                              if (typeof data.sku !== "string") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.sku',
                                  schemaPath: '#/properties/sku/type',
                                  params: {
                                    type: 'string'
                                  },
                                  message: 'should be string'
                                }];
                                return false;
                              }
                              var valid1 = errors === errs_1;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate$8.schema = {
  "$id": "https://data.amplitude.com/5ad78ff3-fd26-4b3e-95c7-ab219e3a30e0/event/Project%20Edited/version/2.0.1",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "Project Edited",
  "description": "User edited a project",
  "type": "object",
  "properties": {
    "category": {
      "description": "The reporting product category in Magento",
      "type": "string"
    },
    "is_customizable_product": {
      "description": "True if the product can be customized in editor; false if it goes straight to the cart",
      "type": "boolean"
    },
    "name": {
      "description": "Product name for the parent product",
      "type": "string"
    },
    "price": {
      "description": "Price as displayed to the customer; in some events, such as Product Viewed and Product List Viewed, this will be set to the Lowest Price since price can change based on options selected",
      "type": "number"
    },
    "product_id": {
      "description": "The product_id for the parent product",
      "type": "integer"
    },
    "product_line": {
      "description": "The reporting product line in Magento",
      "type": "string"
    },
    "products": {
      "description": "Products in the event. This is used by at least Blueshift to manage campaigns. Should contain the following properties:\n\nproduct\\_id\nsku\nname\nprice\ncategory (aka: Reporting Product Category)\nproduct\\_line (aka: Reporting Product Line)",
      "type": "array",
      "items": {
        "type": "object"
      }
    },
    "product_type": {
      "description": "The reporting product type in Magento",
      "type": "string"
    },
    "project_id": {
      "description": "",
      "type": "string"
    },
    "sku": {
      "description": "SKU for the parent product",
      "type": "string"
    }
  },
  "additionalProperties": false,
  "required": ["category", "name", "product_id", "product_line", "project_id", "sku"]
};
validate$8.errors = null;

var validate$9 = (function() {
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    var vErrors = null;
    var errors = 0;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      {
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !( validate.schema.properties.hasOwnProperty(key0));
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
          }
        }
        if (valid1) {
          if (data.au_project_version === undefined) {
            valid1 = true;
          } else {
            var errs_1 = errors;
            if (typeof data.au_project_version !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.au_project_version',
                schemaPath: '#/properties/au_project_version/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.au_split_editorV2_books === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (typeof data.au_split_editorV2_books !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.au_split_editorV2_books',
                  schemaPath: '#/properties/au_split_editorV2_books/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.au_split_editorV2_feature_flag_autofill === undefined) {
                valid1 = true;
              } else {
                var errs_1 = errors;
                if (typeof data.au_split_editorV2_feature_flag_autofill !== "boolean") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.au_split_editorV2_feature_flag_autofill',
                    schemaPath: '#/properties/au_split_editorV2_feature_flag_autofill/type',
                    params: {
                      type: 'boolean'
                    },
                    message: 'should be boolean'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.au_split_editorV2_guestbookset === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (typeof data.au_split_editorV2_guestbookset !== "string") {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.au_split_editorV2_guestbookset',
                      schemaPath: '#/properties/au_split_editorV2_guestbookset/type',
                      params: {
                        type: 'string'
                      },
                      message: 'should be string'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.au_split_editorV2_spread_books === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (typeof data.au_split_editorV2_spread_books !== "string") {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.au_split_editorV2_spread_books',
                        schemaPath: '#/properties/au_split_editorV2_spread_books/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data['au_split_featureflag-chooseimagemodalflow'] === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (typeof data['au_split_featureflag-chooseimagemodalflow'] !== "boolean") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '[\'au_split_featureflag-chooseimagemodalflow\']',
                          schemaPath: '#/properties/au_split_featureflag-chooseimagemodalflow/type',
                          params: {
                            type: 'boolean'
                          },
                          message: 'should be boolean'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.au_split_feature_flag_google_sign_in === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (typeof data.au_split_feature_flag_google_sign_in !== "boolean") {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.au_split_feature_flag_google_sign_in',
                            schemaPath: '#/properties/au_split_feature_flag_google_sign_in/type',
                            params: {
                              type: 'boolean'
                            },
                            message: 'should be boolean'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.au_split_feature_flag_product_search === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.au_split_feature_flag_product_search !== "boolean") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.au_split_feature_flag_product_search',
                              schemaPath: '#/properties/au_split_feature_flag_product_search/type',
                              params: {
                                type: 'boolean'
                              },
                              message: 'should be boolean'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          if (data.category === undefined) {
                            valid1 = false;
                            validate.errors = [{
                              keyword: 'required',
                              dataPath: (dataPath || '') + "",
                              schemaPath: '#/required',
                              params: {
                                missingProperty: 'category'
                              },
                              message: 'should have required property \'category\''
                            }];
                            return false;
                          } else {
                            var errs_1 = errors;
                            if (typeof data.category !== "string") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.category',
                                schemaPath: '#/properties/category/type',
                                params: {
                                  type: 'string'
                                },
                                message: 'should be string'
                              }];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                            if (data.editor_version === undefined) {
                              valid1 = true;
                            } else {
                              var errs_1 = errors;
                              if (typeof data.editor_version !== "string") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.editor_version',
                                  schemaPath: '#/properties/editor_version/type',
                                  params: {
                                    type: 'string'
                                  },
                                  message: 'should be string'
                                }];
                                return false;
                              }
                              var valid1 = errors === errs_1;
                            }
                            if (valid1) {
                              if (data.is_customizable_product === undefined) {
                                valid1 = true;
                              } else {
                                var errs_1 = errors;
                                if (typeof data.is_customizable_product !== "boolean") {
                                  validate.errors = [{
                                    keyword: 'type',
                                    dataPath: (dataPath || '') + '.is_customizable_product',
                                    schemaPath: '#/properties/is_customizable_product/type',
                                    params: {
                                      type: 'boolean'
                                    },
                                    message: 'should be boolean'
                                  }];
                                  return false;
                                }
                                var valid1 = errors === errs_1;
                              }
                              if (valid1) {
                                if (data.is_mobile_view === undefined) {
                                  valid1 = true;
                                } else {
                                  var errs_1 = errors;
                                  if (typeof data.is_mobile_view !== "boolean") {
                                    validate.errors = [{
                                      keyword: 'type',
                                      dataPath: (dataPath || '') + '.is_mobile_view',
                                      schemaPath: '#/properties/is_mobile_view/type',
                                      params: {
                                        type: 'boolean'
                                      },
                                      message: 'should be boolean'
                                    }];
                                    return false;
                                  }
                                  var valid1 = errors === errs_1;
                                }
                                if (valid1) {
                                  if (data.name === undefined) {
                                    valid1 = false;
                                    validate.errors = [{
                                      keyword: 'required',
                                      dataPath: (dataPath || '') + "",
                                      schemaPath: '#/required',
                                      params: {
                                        missingProperty: 'name'
                                      },
                                      message: 'should have required property \'name\''
                                    }];
                                    return false;
                                  } else {
                                    var errs_1 = errors;
                                    if (typeof data.name !== "string") {
                                      validate.errors = [{
                                        keyword: 'type',
                                        dataPath: (dataPath || '') + '.name',
                                        schemaPath: '#/properties/name/type',
                                        params: {
                                          type: 'string'
                                        },
                                        message: 'should be string'
                                      }];
                                      return false;
                                    }
                                    var valid1 = errors === errs_1;
                                  }
                                  if (valid1) {
                                    if (data.platform === undefined) {
                                      valid1 = true;
                                    } else {
                                      var errs_1 = errors;
                                      if (typeof data.platform !== "string") {
                                        validate.errors = [{
                                          keyword: 'type',
                                          dataPath: (dataPath || '') + '.platform',
                                          schemaPath: '#/properties/platform/type',
                                          params: {
                                            type: 'string'
                                          },
                                          message: 'should be string'
                                        }];
                                        return false;
                                      }
                                      var valid1 = errors === errs_1;
                                    }
                                    if (valid1) {
                                      if (data.price === undefined) {
                                        valid1 = true;
                                      } else {
                                        var errs_1 = errors;
                                        if ((typeof data.price !== "number")) {
                                          validate.errors = [{
                                            keyword: 'type',
                                            dataPath: (dataPath || '') + '.price',
                                            schemaPath: '#/properties/price/type',
                                            params: {
                                              type: 'number'
                                            },
                                            message: 'should be number'
                                          }];
                                          return false;
                                        }
                                        var valid1 = errors === errs_1;
                                      }
                                      if (valid1) {
                                        var data1 = data.product_id;
                                        if (data1 === undefined) {
                                          valid1 = false;
                                          validate.errors = [{
                                            keyword: 'required',
                                            dataPath: (dataPath || '') + "",
                                            schemaPath: '#/required',
                                            params: {
                                              missingProperty: 'product_id'
                                            },
                                            message: 'should have required property \'product_id\''
                                          }];
                                          return false;
                                        } else {
                                          var errs_1 = errors;
                                          if ((typeof data1 !== "number" || (data1 % 1) || data1 !== data1)) {
                                            validate.errors = [{
                                              keyword: 'type',
                                              dataPath: (dataPath || '') + '.product_id',
                                              schemaPath: '#/properties/product_id/type',
                                              params: {
                                                type: 'integer'
                                              },
                                              message: 'should be integer'
                                            }];
                                            return false;
                                          }
                                          var valid1 = errors === errs_1;
                                        }
                                        if (valid1) {
                                          if (data.product_line === undefined) {
                                            valid1 = false;
                                            validate.errors = [{
                                              keyword: 'required',
                                              dataPath: (dataPath || '') + "",
                                              schemaPath: '#/required',
                                              params: {
                                                missingProperty: 'product_line'
                                              },
                                              message: 'should have required property \'product_line\''
                                            }];
                                            return false;
                                          } else {
                                            var errs_1 = errors;
                                            if (typeof data.product_line !== "string") {
                                              validate.errors = [{
                                                keyword: 'type',
                                                dataPath: (dataPath || '') + '.product_line',
                                                schemaPath: '#/properties/product_line/type',
                                                params: {
                                                  type: 'string'
                                                },
                                                message: 'should be string'
                                              }];
                                              return false;
                                            }
                                            var valid1 = errors === errs_1;
                                          }
                                          if (valid1) {
                                            var data1 = data.products;
                                            if (data1 === undefined) {
                                              valid1 = true;
                                            } else {
                                              var errs_1 = errors;
                                              if (Array.isArray(data1)) {
                                                var valid1;
                                                for (var i1 = 0; i1 < data1.length; i1++) {
                                                  var data2 = data1[i1];
                                                  var errs_2 = errors;
                                                  if ((!data2 || typeof data2 !== "object" || Array.isArray(data2))) {
                                                    validate.errors = [{
                                                      keyword: 'type',
                                                      dataPath: (dataPath || '') + '.products[' + i1 + ']',
                                                      schemaPath: '#/properties/products/items/type',
                                                      params: {
                                                        type: 'object'
                                                      },
                                                      message: 'should be object'
                                                    }];
                                                    return false;
                                                  }
                                                  var valid2 = errors === errs_2;
                                                  if (!valid2) break;
                                                }
                                              } else {
                                                validate.errors = [{
                                                  keyword: 'type',
                                                  dataPath: (dataPath || '') + '.products',
                                                  schemaPath: '#/properties/products/type',
                                                  params: {
                                                    type: 'array'
                                                  },
                                                  message: 'should be array'
                                                }];
                                                return false;
                                              }
                                              var valid1 = errors === errs_1;
                                            }
                                            if (valid1) {
                                              if (data.product_type === undefined) {
                                                valid1 = true;
                                              } else {
                                                var errs_1 = errors;
                                                if (typeof data.product_type !== "string") {
                                                  validate.errors = [{
                                                    keyword: 'type',
                                                    dataPath: (dataPath || '') + '.product_type',
                                                    schemaPath: '#/properties/product_type/type',
                                                    params: {
                                                      type: 'string'
                                                    },
                                                    message: 'should be string'
                                                  }];
                                                  return false;
                                                }
                                                var valid1 = errors === errs_1;
                                              }
                                              if (valid1) {
                                                if (data.project_id === undefined) {
                                                  valid1 = false;
                                                  validate.errors = [{
                                                    keyword: 'required',
                                                    dataPath: (dataPath || '') + "",
                                                    schemaPath: '#/required',
                                                    params: {
                                                      missingProperty: 'project_id'
                                                    },
                                                    message: 'should have required property \'project_id\''
                                                  }];
                                                  return false;
                                                } else {
                                                  var errs_1 = errors;
                                                  if (typeof data.project_id !== "string") {
                                                    validate.errors = [{
                                                      keyword: 'type',
                                                      dataPath: (dataPath || '') + '.project_id',
                                                      schemaPath: '#/properties/project_id/type',
                                                      params: {
                                                        type: 'string'
                                                      },
                                                      message: 'should be string'
                                                    }];
                                                    return false;
                                                  }
                                                  var valid1 = errors === errs_1;
                                                }
                                                if (valid1) {
                                                  if (data.sku === undefined) {
                                                    valid1 = false;
                                                    validate.errors = [{
                                                      keyword: 'required',
                                                      dataPath: (dataPath || '') + "",
                                                      schemaPath: '#/required',
                                                      params: {
                                                        missingProperty: 'sku'
                                                      },
                                                      message: 'should have required property \'sku\''
                                                    }];
                                                    return false;
                                                  } else {
                                                    var errs_1 = errors;
                                                    if (typeof data.sku !== "string") {
                                                      validate.errors = [{
                                                        keyword: 'type',
                                                        dataPath: (dataPath || '') + '.sku',
                                                        schemaPath: '#/properties/sku/type',
                                                        params: {
                                                          type: 'string'
                                                        },
                                                        message: 'should be string'
                                                      }];
                                                      return false;
                                                    }
                                                    var valid1 = errors === errs_1;
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate$9.schema = {
  "$id": "https://data.amplitude.com/5ad78ff3-fd26-4b3e-95c7-ab219e3a30e0/event/Project%20Started/version/19.0.1",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "Project Started",
  "description": "User started a new project",
  "type": "object",
  "properties": {
    "au_project_version": {
      "description": "Editor version (1 or 2). Required for any events in Editor",
      "type": "string"
    },
    "au_split_editorV2_books": {
      "description": "INACTIVE",
      "type": "string"
    },
    "au_split_editorV2_feature_flag_autofill": {
      "description": "",
      "type": "boolean"
    },
    "au_split_editorV2_guestbookset": {
      "description": "",
      "type": "string"
    },
    "au_split_editorV2_spread_books": {
      "description": "Split for Spread Books testing",
      "type": "string"
    },
    "au_split_featureflag-chooseimagemodalflow": {
      "description": "",
      "type": "boolean"
    },
    "au_split_feature_flag_google_sign_in": {
      "description": "Property containing the split treatment for whether the Google log in/sign up buttons are shown to the user",
      "type": "boolean"
    },
    "au_split_feature_flag_product_search": {
      "description": "Property containing the split treatment for the new product search experience at commerce",
      "type": "boolean"
    },
    "category": {
      "description": "The reporting product category in Magento",
      "type": "string"
    },
    "editor_version": {
      "description": "Editor minor version (e.g. 1.62.1)",
      "type": "string"
    },
    "is_customizable_product": {
      "description": "True if the product can be customized in editor; false if it goes straight to the cart",
      "type": "boolean"
    },
    "is_mobile_view": {
      "description": "Is the user using a mobile device?",
      "type": "boolean"
    },
    "name": {
      "description": "Product name for the parent product",
      "type": "string"
    },
    "platform": {
      "description": "User's operating system",
      "type": "string"
    },
    "price": {
      "description": "Price as displayed to the customer; in some events, such as Product Viewed and Product List Viewed, this will be set to the Lowest Price since price can change based on options selected",
      "type": "number"
    },
    "product_id": {
      "description": "The product_id for the parent product",
      "type": "integer"
    },
    "product_line": {
      "description": "The reporting product line in Magento",
      "type": "string"
    },
    "products": {
      "description": "Products in the event. This is used by at least Blueshift to manage campaigns. Should contain the following properties:\n\nproduct\\_id\nsku\nname\nprice\ncategory (aka: Reporting Product Category)\nproduct\\_line (aka: Reporting Product Line)",
      "type": "array",
      "items": {
        "type": "object"
      }
    },
    "product_type": {
      "description": "The reporting product type in Magento",
      "type": "string"
    },
    "project_id": {
      "description": "",
      "type": "string"
    },
    "sku": {
      "description": "SKU for the parent product",
      "type": "string"
    },
    "email": {
      "description": "Customer's email address",
      "type": "string"
    },
    "customer_ip": {
      "description": "Customer's IP address",
      "type": "string"
    },
  },
  "additionalProperties": false,
  "required": ["category", "name", "product_id", "product_line", "project_id", "sku"]
};
validate$9.errors = null;

var validate$a = (function() {
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    var vErrors = null;
    var errors = 0;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      {
        var valid1 = true;
        for (var key0 in data) {
          var isAdditional0 = !( validate.schema.properties.hasOwnProperty(key0));
          if (isAdditional0) {
            valid1 = false;
            validate.errors = [{
              keyword: 'additionalProperties',
              dataPath: (dataPath || '') + "",
              schemaPath: '#/additionalProperties',
              params: {
                additionalProperty: '' + key0 + ''
              },
              message: 'should NOT have additional properties'
            }];
            return false;
          }
        }
        if (valid1) {
          if (data.au_project_version === undefined) {
            valid1 = true;
          } else {
            var errs_1 = errors;
            if (typeof data.au_project_version !== "string") {
              validate.errors = [{
                keyword: 'type',
                dataPath: (dataPath || '') + '.au_project_version',
                schemaPath: '#/properties/au_project_version/type',
                params: {
                  type: 'string'
                },
                message: 'should be string'
              }];
              return false;
            }
            var valid1 = errors === errs_1;
          }
          if (valid1) {
            if (data.au_split_editorV2_books === undefined) {
              valid1 = true;
            } else {
              var errs_1 = errors;
              if (typeof data.au_split_editorV2_books !== "string") {
                validate.errors = [{
                  keyword: 'type',
                  dataPath: (dataPath || '') + '.au_split_editorV2_books',
                  schemaPath: '#/properties/au_split_editorV2_books/type',
                  params: {
                    type: 'string'
                  },
                  message: 'should be string'
                }];
                return false;
              }
              var valid1 = errors === errs_1;
            }
            if (valid1) {
              if (data.au_split_editorV2_feature_flag_autofill === undefined) {
                valid1 = true;
              } else {
                var errs_1 = errors;
                if (typeof data.au_split_editorV2_feature_flag_autofill !== "boolean") {
                  validate.errors = [{
                    keyword: 'type',
                    dataPath: (dataPath || '') + '.au_split_editorV2_feature_flag_autofill',
                    schemaPath: '#/properties/au_split_editorV2_feature_flag_autofill/type',
                    params: {
                      type: 'boolean'
                    },
                    message: 'should be boolean'
                  }];
                  return false;
                }
                var valid1 = errors === errs_1;
              }
              if (valid1) {
                if (data.au_split_editorV2_guestbookset === undefined) {
                  valid1 = true;
                } else {
                  var errs_1 = errors;
                  if (typeof data.au_split_editorV2_guestbookset !== "string") {
                    validate.errors = [{
                      keyword: 'type',
                      dataPath: (dataPath || '') + '.au_split_editorV2_guestbookset',
                      schemaPath: '#/properties/au_split_editorV2_guestbookset/type',
                      params: {
                        type: 'string'
                      },
                      message: 'should be string'
                    }];
                    return false;
                  }
                  var valid1 = errors === errs_1;
                }
                if (valid1) {
                  if (data.au_split_editorV2_spread_books === undefined) {
                    valid1 = true;
                  } else {
                    var errs_1 = errors;
                    if (typeof data.au_split_editorV2_spread_books !== "string") {
                      validate.errors = [{
                        keyword: 'type',
                        dataPath: (dataPath || '') + '.au_split_editorV2_spread_books',
                        schemaPath: '#/properties/au_split_editorV2_spread_books/type',
                        params: {
                          type: 'string'
                        },
                        message: 'should be string'
                      }];
                      return false;
                    }
                    var valid1 = errors === errs_1;
                  }
                  if (valid1) {
                    if (data['au_split_featureflag-chooseimagemodalflow'] === undefined) {
                      valid1 = true;
                    } else {
                      var errs_1 = errors;
                      if (typeof data['au_split_featureflag-chooseimagemodalflow'] !== "boolean") {
                        validate.errors = [{
                          keyword: 'type',
                          dataPath: (dataPath || '') + '[\'au_split_featureflag-chooseimagemodalflow\']',
                          schemaPath: '#/properties/au_split_featureflag-chooseimagemodalflow/type',
                          params: {
                            type: 'boolean'
                          },
                          message: 'should be boolean'
                        }];
                        return false;
                      }
                      var valid1 = errors === errs_1;
                    }
                    if (valid1) {
                      if (data.au_split_feature_flag_google_sign_in === undefined) {
                        valid1 = true;
                      } else {
                        var errs_1 = errors;
                        if (typeof data.au_split_feature_flag_google_sign_in !== "boolean") {
                          validate.errors = [{
                            keyword: 'type',
                            dataPath: (dataPath || '') + '.au_split_feature_flag_google_sign_in',
                            schemaPath: '#/properties/au_split_feature_flag_google_sign_in/type',
                            params: {
                              type: 'boolean'
                            },
                            message: 'should be boolean'
                          }];
                          return false;
                        }
                        var valid1 = errors === errs_1;
                      }
                      if (valid1) {
                        if (data.au_split_feature_flag_product_search === undefined) {
                          valid1 = true;
                        } else {
                          var errs_1 = errors;
                          if (typeof data.au_split_feature_flag_product_search !== "boolean") {
                            validate.errors = [{
                              keyword: 'type',
                              dataPath: (dataPath || '') + '.au_split_feature_flag_product_search',
                              schemaPath: '#/properties/au_split_feature_flag_product_search/type',
                              params: {
                                type: 'boolean'
                              },
                              message: 'should be boolean'
                            }];
                            return false;
                          }
                          var valid1 = errors === errs_1;
                        }
                        if (valid1) {
                          if (data.editor_version === undefined) {
                            valid1 = true;
                          } else {
                            var errs_1 = errors;
                            if (typeof data.editor_version !== "string") {
                              validate.errors = [{
                                keyword: 'type',
                                dataPath: (dataPath || '') + '.editor_version',
                                schemaPath: '#/properties/editor_version/type',
                                params: {
                                  type: 'string'
                                },
                                message: 'should be string'
                              }];
                              return false;
                            }
                            var valid1 = errors === errs_1;
                          }
                          if (valid1) {
                            if (data.is_mobile_view === undefined) {
                              valid1 = true;
                            } else {
                              var errs_1 = errors;
                              if (typeof data.is_mobile_view !== "boolean") {
                                validate.errors = [{
                                  keyword: 'type',
                                  dataPath: (dataPath || '') + '.is_mobile_view',
                                  schemaPath: '#/properties/is_mobile_view/type',
                                  params: {
                                    type: 'boolean'
                                  },
                                  message: 'should be boolean'
                                }];
                                return false;
                              }
                              var valid1 = errors === errs_1;
                            }
                            if (valid1) {
                              if (data.platform === undefined) {
                                valid1 = true;
                              } else {
                                var errs_1 = errors;
                                if (typeof data.platform !== "string") {
                                  validate.errors = [{
                                    keyword: 'type',
                                    dataPath: (dataPath || '') + '.platform',
                                    schemaPath: '#/properties/platform/type',
                                    params: {
                                      type: 'string'
                                    },
                                    message: 'should be string'
                                  }];
                                  return false;
                                }
                                var valid1 = errors === errs_1;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate$a.schema = {
  "$id": "https://data.amplitude.com/5ad78ff3-fd26-4b3e-95c7-ab219e3a30e0/event/Signed%20In/version/13.0.0",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "Signed In",
  "description": "User signs in",
  "type": "object",
  "properties": {
    "au_project_version": {
      "description": "Editor version (1 or 2). Required for any events in Editor",
      "type": "string"
    },
    "au_split_editorV2_books": {
      "description": "INACTIVE",
      "type": "string"
    },
    "au_split_editorV2_feature_flag_autofill": {
      "description": "",
      "type": "boolean"
    },
    "au_split_editorV2_guestbookset": {
      "description": "",
      "type": "string"
    },
    "au_split_editorV2_spread_books": {
      "description": "Split for Spread Books testing",
      "type": "string"
    },
    "au_split_featureflag-chooseimagemodalflow": {
      "description": "",
      "type": "boolean"
    },
    "au_split_feature_flag_google_sign_in": {
      "description": "Property containing the split treatment for whether the Google log in/sign up buttons are shown to the user",
      "type": "boolean"
    },
    "au_split_feature_flag_product_search": {
      "description": "Property containing the split treatment for the new product search experience at commerce",
      "type": "boolean"
    },
    "editor_version": {
      "description": "Editor minor version (e.g. 1.62.1)",
      "type": "string"
    },
    "is_mobile_view": {
      "description": "Is the user using a mobile device?",
      "type": "boolean"
    },
    "platform": {
      "description": "User's operating system",
      "type": "string"
    }
  },
  "additionalProperties": false,
  "required": []
};
validate$a.errors = null;

var validate$b = (function() {
  return function validate(data, dataPath, parentData, parentDataProperty, rootData) {
    var vErrors = null;
    var errors = 0;
    if ((data && typeof data === "object" && !Array.isArray(data))) {
      {
        for (var key0 in data) {
          validate.errors = [{
            keyword: 'additionalProperties',
            dataPath: (dataPath || '') + "",
            schemaPath: '#/additionalProperties',
            params: {
              additionalProperty: '' + key0 + ''
            },
            message: 'should NOT have additional properties'
          }];
          return false;
        }
      }
    } else {
      validate.errors = [{
        keyword: 'type',
        dataPath: (dataPath || '') + "",
        schemaPath: '#/type',
        params: {
          type: 'object'
        },
        message: 'should be object'
      }];
      return false;
    }
    validate.errors = vErrors;
    return errors === 0;
  };
})();
validate$b.schema = {
  "$id": "https://data.amplitude.com/5ad78ff3-fd26-4b3e-95c7-ab219e3a30e0/event/Sign%20In%20Displayed/version/1.0.0",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "Sign In Displayed",
  "description": "Fire this event when the Sign In screen is displayed",
  "type": "object",
  "properties": {},
  "additionalProperties": false,
  "required": []
};
validate$b.errors = null;

export { validate$2 as AccountCreatedValidator, validate as ContextValidator, validate$1 as IdentifyValidator, validate$3 as ImageAddedValidator, validate$4 as ImageEditedValidator, validate$5 as ImageUploadedValidator, validate$6 as PageViewedValidator, validate$7 as ProductAddedValidator, validate$8 as ProjectEditedValidator, validate$9 as ProjectStartedValidator, validate$b as SignInDisplayedValidator, validate$a as SignedInValidator };
