// @flow

import type { RangeSpecifier } from '../helpers/contained';

// Constants
export const FIXED: string = 'fixed';

// fontSize-specific constants
export const MIN_FONT_SIZE: number = 11;
export const MAX_FONT_SIZE: number = 600;

// Supported font size values in points
// Uses extended typographic scale found here: http://spencermortensen.com/articles/typographic-scale/
export const SUPPORTED_FONT_SIZE = [192, 167, 146, 127, 110, 96, 72, 60, 48, 36, 30, 24, 18, 14, 12, 11, 10, 9, 8];

// lineHeight-specific constants
export const MIN_LINE_HEIGHT: number = 1;
export const MAX_LINE_HEIGHT: number = 4;

export const ATTRIBUTES_SUPPORTING_RANGE: Array<string> = [
  'fontSize',
  'lineHeight',
  'letterSpacing',
];

/* Predicate indicating whether or not the given attribute key is included in the array of style attributes that
   can be a RangeSpecifier */
export const attributeSupportsRange = (attrKey: string) => ATTRIBUTES_SUPPORTING_RANGE.includes(attrKey);

// TYPES
export type StyleRestriction = 'fixed' | RangeSpecifier;

export type StyleRestrictions = {
  fontFamily?: StyleRestriction,
  textAlign?: StyleRestriction,
  fontSize?: StyleRestriction,
  textTransform?: StyleRestriction,
  color?: StyleRestriction,
  letterSpacing?: StyleRestriction,
  lineHeight?: StyleRestriction,
};

export type TextRestrictions = {
  invalidChars: ?string,
  charLength: ?number,
};

export type Style = {
  color?: string,
  fontFamily?: string,
  fontSize?: string,
  textAlign?: string,
  textTransform?: string,
  verticalAlign?: string,
};
