export const getCookie = (cookie: string) => {
  const cookieString = document.cookie.split(';').filter(item => item.includes(cookie));
  const regex = /=(.+)/g;

  if (cookieString.length) {
    return regex.exec(cookieString[0])[1];
  }
  return false;
};

export const setCookie = (name: string, value: string | boolean, maxAge: number = 2592000): void => {
  document.cookie = [
    `${name}=${encodeURIComponent(value)}`,
    `path=/`,
    `domain=${process.env.REACT_APP_BASE_DOMAIN}`,
    `max-age=${maxAge}`
  ].join(';');
};