// @flow

import { createSelector } from 'reselect';
import { type EnvelopeAddressingState } from './reducer';
import { type MagentoAddress } from '../../types/address';
import { prop, composeK, optionGet } from '../../helpers/functions';
import { selectedAttributesSelector, productAttributesSelector } from '../product/selectors';
import { ENVELOPE_ADDRESSING_ATTRIBUTE, ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY, ENVELOPE_RECIPIENT_ADDRESS_PLACEMENT } from '../../constants/envelopes';
import { CSV_KEY_STATE_KEY, MANUAL_RECIPIENT_ADDRESS } from './constants';
import { findFirst } from 'fp-ts/lib/Array';
import { none, some } from 'fp-ts/lib/Option';

export const returnAddressSelector = (state: { envelopeAddressing: EnvelopeAddressingState }) => state.envelopeAddressing.returnAddress;

// Returns the id of the Magento Address that is currently selected, returning -1 if there is none.
export const returnAddressIdSelector = createSelector(
  returnAddressSelector,
  (returnAddress: ?MagentoAddress): number => (
    prop('id')(returnAddress).getOrElseValue(-1)
  ),
);

export const csvKeySelector = createSelector(
  state => state.envelopeAddressing,
  prop(CSV_KEY_STATE_KEY),
);

export const envelopeAddressingOptionsSelector = createSelector(
  productAttributesSelector,
  composeK(
    prop('options'),
    prop('envelope_addressing')
  ),
);

export const hasReplyAddressing = createSelector(
  envelopeAddressingOptionsSelector,
  opts => (opts.map(x => x.map(y => y.value))
    .map(x => x.includes(ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY))
    .getOrElseValue(false))
);


const getReturnOrReplyText = (hasReply: boolean) => (hasReply ? 'Reply Address' : 'Return Address');
export const getReplyOrReturnAddressingText = createSelector(
  hasReplyAddressing,
  hasReply => getReturnOrReplyText(hasReply),
);

export const getManualReturnAddressing = (state: any) => {
  const envelopeLayers = optionGet('template.envelope.layers')(state).getOrElseValue([]);
  return findFirst(x => x.placement === ENVELOPE_RECIPIENT_ADDRESS_PLACEMENT && x.manual === true)(envelopeLayers);
}

export const isManualReturnAddress = createSelector(
  getManualReturnAddressing,
  (manualLayer) => manualLayer.map(() => true).getOrElseValue(false)
);

export const envelopeAddressingValueSelector = state => {
  const attrs = selectedAttributesSelector(state);
  const manual = isManualReturnAddress(state);
  return prop(ENVELOPE_ADDRESSING_ATTRIBUTE)(attrs).alt(manual ? some(MANUAL_RECIPIENT_ADDRESS) : none);
};