import ajv from 'ajv';
import ajvErrors from 'ajv-errors';

import {
  pageSchema,
  photoDataSchema,
  photoLayerSchema,
  project,
  singlePhotoProductSchema,
  singlePhotoProjectSchema,
  userPhotoSchema,
  userPhotosSchema,
  editableTextLayerSchema,
  editableTextStyleSchema,
  designAssetLayerSchema,
} from './schema';

const validator = new ajv({ allErrors: true, jsonPointers: true, $data: true, useDefaults: true });
ajvErrors(validator);

validator.addKeyword('isTrue', {
  $data: true,
  validate: data => data === true,
});

validator.addKeyword('isFalse', {
  $data: true,
  validate: data => data === false,
});

validator.addSchema(editableTextStyleSchema, '#/editableTextStyle');
validator.addSchema(designAssetLayerSchema, '#/designAssetLayer');
validator.addSchema(editableTextLayerSchema, '#/editableTextLayer');
validator.addSchema(photoDataSchema, '#/photoData');
validator.addSchema(photoLayerSchema, '#/photoLayer');
validator.addSchema(userPhotoSchema, '#/userPhoto');
validator.addSchema(pageSchema, '#/projectPage');
validator.addSchema(userPhotosSchema, '#/userPhotos');
validator.addSchema(singlePhotoProductSchema, '#/singlePhotoProduct');
validator.addSchema(project, '#/project');
validator.addSchema(singlePhotoProjectSchema, '#/singlePhotoProject');

export default validator;
