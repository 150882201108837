// @flow

import flatten from 'lodash.flatten';
import isEqual from 'lodash.isequal';
import {
  ContentState,
  ContentBlock,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import { type Option } from 'fp-ts/lib/Option';
import { index, findFirst } from 'fp-ts/lib/Array';
import { not } from 'fp-ts/lib/function';

import has from '../has';
import match from '../match';
import {
  USER_PHOTO,
  FULL_BLEED,
  EDITABLE_TEXT,
  EDITABLE_DESIGN_ASSET,
  SHIFTED_LAYER_INDICATOR,
  CUSTOMIZED_LAYER_INDICATOR,
  TRANSLATED_LAYER_INDICATOR,
} from '../../constants/layers';
import { cap, floor, getRandomArb } from '../numbers';
import { sort, changeArrayElement, withId } from '../arrays';
import reflow, { reflowSync, defaultContentStrToContentState } from '../reflow';
import { generateCalendarLayer } from '../calendar';
import { generateInitCropData, getExifRotationDegrees } from '../crop';
import { getPhotoFromLayer } from '../images';
import {
  prop,
  div2,
  mult2,
  optionsEqual,
  optionFind,
  compare,
  optionGet,
} from '../functions';
import photo, { type Photo } from '../../types/photo';
import { filterObj } from '../objects';
import {
  type Design,
  type Layer,
  type Surface,
  type LayerType,
  type Category,
} from '../../types/templates';
import { fontOptions } from '../fonts';
import { any } from '../conditionals';
import { applyTextRestrictionsToString } from '../restrictions';

import {
  type StyleRestriction,
  type StyleRestrictions,
  FIXED,
  MIN_FONT_SIZE,
  MAX_FONT_SIZE,
  attributeSupportsRange,
} from '../../types/style';
import { clampToRange } from '../contained';
import { parseBleedValue, parseSafeOffset } from '../templates';
import { withUpdatedTags } from '../tags';
import { option } from 'fp-ts';
import {
  designIsComposite,
  createCompositeDesign,
  designIsPartial, layerIsSpine,
} from '../pages';
import { shouldAddMatPrefix } from '../product';
import { clampW, clampH, clampX, clampY } from '../customization';
import { stripHiddenChars } from '../strings';

type LayerCounts = {
  user_photo?: number,
  editable_text?: number,
};
type DesignIdLayerCount = {
  id: string,
  layerCounts: LayerCounts,
};

/* Takes a dimension (w, h, x, y), a surface, and a layer, and returns the physical measurement
   of the layer in that dimension on that surface. */
export const layerPcToIn = (dim: 'w' | 'h' | 'x' | 'y') => (
  surface: Surface
) => (layer: Layer): number => {
  switch (dim) {
    case 'h':
      return layer.height * surface.height;
    case 'x':
      return layer.x * surface.width;
    case 'y':
      return layer.y * surface.height;
    default:
      return layer.width * surface.width;
  }
};

export const layerPcToInW = layerPcToIn('w');
export const layerPcToInH = layerPcToIn('h');
export const layerPcToInX = layerPcToIn('x');
export const layerPcToInY = layerPcToIn('y');

/* Takes a dimension (width or height), a surface, and a physical measurement of a layer in that dimension (in inches),
   and returns the measurement as a percentage of that dimension of the surface. */
export const layerInToPc = (dim: 'h' | 'w' | 'x' | 'y') => (
  surface: Surface
) => (m: number): number => {
  if (dim === 'x' || dim === 'w') {
    return m / surface.width;
  }

  return m / surface.height;
};

export const layerInToPcW = layerInToPc('w');
export const layerInToPcH = layerInToPc('h');
export const layerInToPcX = layerInToPc('x');
export const layerInToPcY = layerInToPc('y');

/* Given a surface (an object with width and height properties specified in inches) and a layer
   (an object with width and height properties specified in percentages of the surface's dimensions),
   returns the aspect ratio (width / height) of the layer, as an Option. */
export const layerAspectRatio = (surface: Surface) => (
  layer: Layer
): Option<number> =>
  div2(mult2(prop('width')(surface))(prop('width')(layer)))(
    mult2(prop('height')(surface))(prop('height')(layer))
  );

// Computes the physical area (in square inches) occupied by a layer.
export const layerPhysicalArea = (surface: Surface) => (
  layer: Layer
): Option<number> =>
  mult2(mult2(prop('width')(surface))(prop('width')(layer)))(
    mult2(prop('height')(surface))(prop('height')(layer))
  );

// Compares two layers, returning a boolean indicating whether or not the two layers have the exact same dimensions.
export const dimensionsAreSame = (surface: Surface) => (l1: Layer) => (
  l2: Layer
) =>
  optionsEqual(layerAspectRatio(surface)(l1))(layerAspectRatio(surface)(l2)) &&
  optionsEqual(layerPhysicalArea(surface)(l1))(layerPhysicalArea(surface)(l2));

// Predicate that returns true if the two given surfaces have the same width and height.
export const surfacesAreSame = (s1: Surface) => (s2: Surface) =>
  optionsEqual(prop('width')(s1))(prop('width')(s2)) &&
  optionsEqual(prop('height')(s1))(prop('height')(s2));

// Calls a layer's 'callable' function, if it's specified and exists.
export const createDynamicLayer = (layer: Layer) => (
  pageNum: number,
  attrs: Object = {}
) =>
  match(
    'getMonth',
    () => generateCalendarLayer(layer, pageNum, attrs.start_month),
    'getFoilColor',
    () =>
      option
        .fromNullable(window.productData)
        .chain(prop('attributes'))
        .chain(prop('foil_color'))
        .chain(prop('options'))
        .map((x) => x.find((opt) => opt.value === attrs.foil_color))
        .chain(prop('hexCode'))
        .map((x) => ({
          ...layer,
          defaultData: {
            ...layer.defaultData,
            style: {
              ...layer.defaultData.style,
              color: x,
            },
          },
        }))
        .getOrElseValue(layer),
    match.default,
    () => layer
  )(layer.callable);

/* Given a page number, and a product sku string, returns a function that
   takes an array of layers and itself returns a copy of that array of
   layers with any dynamic layers created. */
export const withCallables = (pageNum: number) => (layers: Array<Layer>) => (
  attrs: Object
): Array<Layer> =>
  layers.map((layer) => createDynamicLayer(layer)(pageNum, attrs));

// Returns an array of layer objects specified by the given design object
export const layersInDesign = (design: Design, layers: Array<Layer>) =>
  design.layers
    .map((layerId) => layers.find((layer) => layer.id === layerId))
    .filter((layer) => layer !== undefined);

// Returns the default design from the given array of designs.
export const defaultDesign = (designs: Array<Design>) =>
  optionFind(
    (design) => has(design)('default') && design.default === true,
    designs
  ).getOrElseValue(designs[0]);

// Returns all of the designs in a given array that are marked as 'default'.
export const allDefaultDesigns = (designs: Array<Design>) =>
  designs.filter((design) => has(design)('default') && design.default === true);

// PREDICATES
export const isUserPhotoLayer = (layer: Object) => layer.type === USER_PHOTO;
export const isNotUserPhotoLayer = (layer: Object) => layer.type !== USER_PHOTO;

export const isEditableTextLayer = (layer: Object) =>
  layer.type === EDITABLE_TEXT;
export const isNotEditableTextLayer = (layer: Object) =>
  layer.type !== EDITABLE_TEXT;

export const isEditableDesignAssetLayer = (layer: Object) =>
  layer.type === EDITABLE_DESIGN_ASSET;
export const isNotEditableDesignAssetLayer = (layer: Object) =>
  layer.type !== EDITABLE_DESIGN_ASSET;

export const isPhotoLayerEmpty = (layer: Object) =>
  !(
    has(layer)('data') &&
    has(layer.data)('userPhotoId') &&
    layer.data.userPhotoId !== null
  );

export const isTextLayerEmpty = (layer: Object) =>
  !(
    has(layer)('data') &&
    has(layer.data)('content') &&
    (layer.data.content !== null || layer.data.content !== '')
  );

// determines the side a photo layer resides on
export const determinePhotoLayerSide = (layer: Layer): 'right' | 'left' =>
  (layer.x >= 0.5 ? 'right' : 'left');

// Predicate indicating whether or not the given layer can be resized and/or moved by the user.
export const canCustomizeLayer = (layer: Layer) =>
  layer.userCanMove === true || layer.userCanResize === true;

// Predicate indicating whether or not the given layerId contains the customized layer indicator substring.
export const layerIdIsCustomized = (layerId: string) =>
  layerId.includes(CUSTOMIZED_LAYER_INDICATOR);

// Predicate indicating whether or not the given layerId contains the translated layer indicator substring.
export const layerIdIsTranslated = (layerId: string) =>
  layerId.includes(TRANSLATED_LAYER_INDICATOR);

// Predicate indicating whether or not the given layerId contains the shifted layer indicator substring.
export const layerIdIsShifted = (layerId: string) =>
  layerId.includes(SHIFTED_LAYER_INDICATOR);

// CUSTOM LAYER ID FUNCTIONS
// Appends the customized layer indicator string to the end of a given layerId, if it does not contain it already.
export const markLayerIdCustomized = (layerId: string) =>
  (!layerIdIsCustomized(layerId)
    ? layerId.concat(CUSTOMIZED_LAYER_INDICATOR)
    : layerId);

// Takes a layerId and an array of layerIds, and marks the matching layerId in that array as being customized, if necessary.
export const markLayerIdInArrayCustomized = (layerId: string) =>
  changeArrayElement(markLayerIdCustomized)((el) => el === layerId);

// Takes a layerId that may contain a customized layer indicator substring, and removes the substring, if necessary.
export const layerIdWithoutCustomization = (layerId: string) =>
  layerId.replace(CUSTOMIZED_LAYER_INDICATOR, '');

// Appends the customized layer indicator string to the end of a given layerId, if it does not contain it already.
export const markLayerIdTranslated = (layerId: string) =>
  (!layerIdIsTranslated(layerId)
    ? layerId.concat(TRANSLATED_LAYER_INDICATOR)
    : layerId);

// Takes a layerId that may contain a translated layer indicator substring, and removes the substring, if necessary.
export const layerIdWithoutTranslation = (layerId: string) =>
  layerId.replace(TRANSLATED_LAYER_INDICATOR, '');

// FILTER FUNCTIONS
export const userPhotoLayers = (layers: Array<Layer>) =>
  layers.filter(isUserPhotoLayer);
export const editableTextLayers = (layers: Array<Layer>) =>
  layers.filter(isEditableTextLayer);
export const editableDesignAssetLayers = (layers: Array<Layer>) =>
  layers.filter(isEditableDesignAssetLayer);

export const otherLayers = (layers: Array<Layer>): Array<Layer> =>
  layers
    .filter(isNotUserPhotoLayer)
    .filter(isNotEditableTextLayer)
    .filter(isNotEditableDesignAssetLayer);

/* Takes an empty layer of type USER_PHOTO from a design, an existing user_photo layer that has data,
   a surface object, and the userPhotos array. Returns either the complete data from the existing layer,
   or a data object containing the existing userPhotoId alongside newly-generated crop data. */
const updateUserPhotoData = (
  designLayer,
  existingLayer,
  prevSurface,
  newSurface,
  userPhotos
) => {
  const designAspectRatio = layerAspectRatio(newSurface)(designLayer);
  const existingAspectRatio = layerAspectRatio(prevSurface)(existingLayer);
  if (optionsEqual(designAspectRatio)(existingAspectRatio)) {
    return existingLayer.data;
  }
  return getPhotoFromLayer(userPhotos)(existingLayer)
    .map((p) => {
      const extractedPhoto = photo.extract(p);
      return ({
        ...existingLayer.data,
        cropData: generateInitCropData(extractedPhoto, designLayer, newSurface),
      });
    })
    .getOrElseValue({
      ...existingLayer.data,
    });
};

const isPartialToPartial = (prevLayers: Array<Layer>) => (
  newLayers: Array<Layer>
): boolean =>
  any(prevLayers.map((x: Layer) => layerIdIsShifted(x.id))) &&
  any(newLayers.map((x: Layer) => layerIdIsShifted(x.id)));

const getShiftedLayers = (flag: boolean) => (layers: Array<Layer>) => {
  switch (true) {
    case flag:
      return layers.filter((x) => layerIdIsShifted(x.id));
    default:
      return layers.filter((x) => !layerIdIsShifted(x.id));
  }
};
const getRightLayers = getShiftedLayers(true);
const getLeftLayers = getShiftedLayers(false);

const makeUserPhotoLayerUpdate = (
  userPhotos,
  prevSurface: Surface,
  newSurface: Surface
) => (prevLayers: Array<Layer>) => (newLayers: Array<Layer>) =>
  newLayers.map((layer, i) => {
    if (i < prevLayers.length && !isPhotoLayerEmpty(prevLayers[i])) {
      return {
        ...layer,
        data: updateUserPhotoData(
          layer,
          prevLayers[i],
          prevSurface,
          newSurface,
          userPhotos
        ),
      };
    }
    return layer;
  });

// Copies data over from any existing user photo layers to the given user photo layers from the design.
export const updateUserPhotoLayers = (
  designUserPhotoLayers: Array<Object>,
  existingUserPhotoLayers: Array<Object>,
  prevSurface?: Surface,
  newSurface: Surface,
  userPhotos: Array<Object> = []
): Array<Layer> => {
  const _makeUserPhotoLayerUpdate = makeUserPhotoLayerUpdate(
    userPhotos,
    prevSurface,
    newSurface
  );
  // if we are going from 2 partial layouts to 2 partial layouts,
  // keep the image data on either the left of right of the page
  if (isPartialToPartial(existingUserPhotoLayers)(designUserPhotoLayers)) {
    // our existing layers sorted to right or left
    const existingLeftLayers = getLeftLayers(existingUserPhotoLayers);
    const existingRightLayers = getRightLayers(existingUserPhotoLayers);

    // our new layers sorted to right or left
    const newLeftLayers = getLeftLayers(designUserPhotoLayers);
    const newRightLayers = getRightLayers(designUserPhotoLayers);

    // update the left layers
    const updatedLeftLayers = _makeUserPhotoLayerUpdate(existingLeftLayers)(
      newLeftLayers
    );
    // update the right layers
    const updatedRightLayers = _makeUserPhotoLayerUpdate(existingRightLayers)(
      newRightLayers
    );
    // combine all the layers
    return [...updatedLeftLayers, ...updatedRightLayers];
  }

  return _makeUserPhotoLayerUpdate(existingUserPhotoLayers)(
    designUserPhotoLayers
  );
};

// Takes an array of Layers and returns that same array with all editable text layers it contains rendered.
export const renderEditableTextLayers = (layers: Array<Layer>) => (
  surface: Surface
): Array<Layer> =>
  layers.map(
    (layer: Layer): Layer => {
      if (isEditableTextLayer(layer) && layer.data) {
        const { svg, text } = reflowSync(
          layer.data.content,
          layer.data.style,
          layer.width * surface.width * 300,
          layer.height * surface.height * 300,
          layer.data.contentState
        );

        return {
          ...layer,
          data: {
            ...layer.data,
            renderedContent: svg,
            formattedText: text,
          },
        };
      }

      return layer;
    }
  );

// Renders the content in a given layer, if possible.
export const renderEditableTextLayer = (surface: Surface) => (
  layer: Layer
): Layer => {
  if (isEditableTextLayer(layer) && layer.data) {
    const { svg, text } = reflowSync(
      layer.data.content,
      layer.data.style,
      layer.width * surface.width * 300,
      layer.height * surface.height * 300,
      layer.data.contentState
    );

    return {
      ...layer,
      data: {
        ...layer.data,
        renderedContent: svg,
        formattedText: text,
      },
    };
  }

  return layer;
};

// Applys the given style restriction to the passed style value.
const applyStyleRestriction = (defaultStyleValue: string | number) => (
  styleValue: string | number
) => (
  restriction: StyleRestriction,
  supportsRange: ?boolean = false
): string | number => {
  if (
    restriction === FIXED ||
    (Array.isArray(restriction) && !restriction.includes(styleValue))
  ) {
    return defaultStyleValue;
  } else if (supportsRange) {
    return clampToRange(restriction, MIN_FONT_SIZE, MAX_FONT_SIZE)(
      parseFloat(styleValue)
    );
  }
  return styleValue;
};

// Ensures that the updated style object conforms to any restrictions specified in the styleRestrictions object.
const applyStyleRestrictions = (defaultData: {
  style: Object,
  styleRestrictions?: StyleRestrictions,
}) => (style: Object): Object =>
  prop('styleRestrictions')(defaultData)
    .map((sr: StyleRestrictions) =>
      Object.entries(sr).reduce(
        (result: Object, [key: string, restriction: StyleRestriction]) => ({
          ...result,
          ...prop(key)(style)
            .map((styleValue: string | number) => ({
              [key]: match(
                'fontSize',
                () =>
                  `${applyStyleRestriction(defaultData.style[key])(styleValue)(
                    restriction,
                    true
                  )}${restriction !== FIXED ? 'px' : ''}`,
                match.default,
                () =>
                  applyStyleRestriction(defaultData.style[key])(styleValue)(
                    restriction,
                    attributeSupportsRange(key)
                  )
              )(key),
            }))
            .getOrElseValue({}),
        }),
        style
      )
    )
    .getOrElseValue(style);

/** Certain styles are design specific and should not be transfered to a newly selected design */
const isTransferableStyle = (styleKey: string) =>
  !['lineHeight', 'letterSpacing'].includes(styleKey);

/* Filter the new layer's default style object to only include style attributes with values that
   differ from the existing layer's default style. */
const getDifferingStyles = (
  existingDefaultData: Object,
  newDefaultData: Object
) =>
  (has(existingDefaultData)('style') && has(newDefaultData)('style')
    ? filterObj(
      (key, newValue) =>
      // If there is no value for this key in the existing style object, or if the two values differ, include it in the overrides
        (!has(existingDefaultData.style)(key) ||
            existingDefaultData.style[key] !== newValue) &&
          isTransferableStyle(key)
    )(newDefaultData.style)
    : {});

// Takes a raw Draft.js ContentState object and returns an object of the same type with all of the inline styles (i.e. bold/italic) removed.
const clearInlineStylesFromRaw = (contentStateRaw: Object) => {
  const contentState = convertFromRaw(contentStateRaw);

  const blocks = contentState.getBlockMap().map((block: ContentBlock) => {
    const characterList = block.getCharacterList();

    const updatedCharacterList = characterList.map((c) =>
      c.set('style', c.get('style').clear())
    );

    return block.set('characterList', updatedCharacterList);
  });

  return convertToRaw(ContentState.createFromBlockArray(blocks.toArray()));
};

export const setContentOnLayer = (
  pageId: string,
  layer: Layer,
  surface: Surface,
  content: str
) => {
  const style = optionGet('defaultData.style')(layer).getOrElseValue({});
  const updatedContent = option
    .fromNullable(content)
    .map((x) => {
      if (
        style.fontStyle &&
        style.fontStyle.toLowerCase() === 'italic' &&
        x !== ''
      ) {
        return `_${x}_`;
      }
      return x;
    })
    .getOrElseValue('');
  console.log({ updatedContent });
  const newContentState = defaultContentStrToContentState(updatedContent);

  // re-render the text to clear inline styles and display the new content
  return reflow(
    content,
    style,
    layer.width * surface.width * 300,
    layer.height * surface.height * 300,
    newContentState
  ).then(({ svg, text }) => [
    withUpdatedTags(layer.defaultData)({
      ...(layer.defaultData || {}),
      content,
      renderedContent: svg,
      formattedText: text,
      contentState: newContentState,
      style,
    }),
    pageId,
    layer.id,
  ]);
};

/* Takes an existing Editable Text layer and a new Editable Text layer and moves any data that can be moved
   from the existing layer to the new layer and returns the new layer. */
export const updateEditableTextLayerData = (
  existingLayer: Layer,
  newLayer: Layer,
  surface: Surface,
  fontsLoaded: boolean
) => {
  // Grabbing the category from the window feels dirty, but it would add a ton more
  // code to pass this through the ridiculous chain of functions, plus we are
  // using the hammer-data-in-the-window pattern everywhere else ¯\_(ツ)_/¯

  const productCategory = window.productData && window.productData.category;

  let style;

  if (productCategory === 'cards' && existingLayer.id !== newLayer.id) {
    // Copy layout default styles for card products only
    style = applyStyleRestrictions(newLayer.defaultData)(newLayer.defaultData.style);
  } else {
    // Copy existing layer styles for all other products
    style = applyStyleRestrictions(newLayer.defaultData)({
      // Copy over the existing layer's styling
      ...existingLayer.data.style,
      // override existing layer's style with any defaultData styling that differs between it and the new layer.
      ...getDifferingStyles(existingLayer.defaultData, newLayer.defaultData),
    });
  }

  const textRestrictionsUpdate: boolean = option
    .of((x) => (y) => !isEqual(x, y))
    .ap_(prop('textRestrictions')(newLayer))
    .ap_(prop('textRestrictions')(existingLayer))
    .getOrElseValue(false);

  const isContentDefault = optionGet('data.content')(existingLayer)
    .map((x) => [
      x,
      optionGet('defaultData.content')(existingLayer).getOrElseValue(''),
    ])
    .map(([content, defaultContent]) => content === defaultContent)
    .getOrElseValue(false);

  // If the fonts are loaded and the existing and new styling are not the same, OR if the dimensions are different, re-render the text.
  if (
    isContentDefault ||
    (fontsLoaded &&
      (!isEqual(existingLayer.data.style, style) ||
        !dimensionsAreSame(surface)(existingLayer)(newLayer)))
  ) {
    // Get the inline styles available for the existing font family.
    const existingFontOptions = optionFind(
      (fo) => fo.value === existingLayer.data.style.fontFamily,
      fontOptions
    ).chain(prop('availableStyles'));

    // Get the inline styles avilable for the new font family.
    const newFontOptions = optionFind(
      (fo) => fo.value === style.fontFamily,
      fontOptions
    ).chain(prop('availableStyles'));

    // Compare the two arrays of available font styles.
    const fontOptionsSame = optionsEqual(existingFontOptions)(newFontOptions);

    // Compare the two layers' defaultData.content value.
    const defaultContentSame =
      existingLayer.defaultData.content === newLayer.defaultData.content;

    // If the default content is the same for both layers, transfer over the existing content.
    if (defaultContentSame) {
      // do we need to run content through textRestrictions
      const content = textRestrictionsUpdate
        ? applyTextRestrictionsToString(existingLayer.data.content)(
          newLayer.textRestrictions
        ).getOrElseValue(existingLayer.data.content)
        : existingLayer.data.content;

      // If the available font styles differ, we clear any inline styles from the existing ContentState. Otherwise, we can just re-use it.
      const contentState = textRestrictionsUpdate
        ? defaultContentStrToContentState(content)
        : !fontOptionsSame
          ? clearInlineStylesFromRaw(existingLayer.data.contentState)
          : existingLayer.data.contentState;
      // Render the updated layer.
      const { svg, text, hiddenText } = reflowSync(
        content,
        style,
        newLayer.width * surface.width * 300,
        newLayer.height * surface.height * 300,
        contentState
      );

      return withUpdatedTags(newLayer.defaultData)({
        ...existingLayer.data,
        content,
        style,
        visibleText: stripHiddenChars(content, hiddenText),
        renderedContent: svg,
        formattedText: text,
        contentState,
      });
    }

    // Get new layer's content if present, otherwise get new layer's default content
    const newContent = prop('data')(newLayer)
      .chain(prop('content'))
      .alt(optionGet('defaultData.content')(newLayer))
      .getOrElseValue('');

    // Get existing layer's content unless it's unchanged from default or is an empty string, otherwise set to new content
    const existingContent = prop('data')(existingLayer)
      .chain(prop('content'))
      .chain((x) =>
        (x === optionGet('defaultData.content')(existingLayer).getOrElseValue('')
          ? option.none
          : option.some(x))
      )
      .chain((x) => (x === '' ? option.none : option.some(x)))
      .alt(option.fromNullable(newContent))
      .getOrElseValue('');

    const content = textRestrictionsUpdate
      ? applyTextRestrictionsToString(existingContent)(
        newLayer.textRestrictions
      ).getOrElseValue(existingContent)
      : existingContent;

    // Otherwise, use the new layer's content
    const newContentState = defaultContentStrToContentState(content);

    const { svg, text, hiddenText } = reflowSync(
      content,
      style,
      newLayer.width * surface.width * 300,
      newLayer.height * surface.height * 300,
      newContentState
    );

    return withUpdatedTags(newLayer.defaultData)({
      ...existingLayer.data,
      content,
      style,
      visibleText: stripHiddenChars(content, hiddenText),
      renderedContent: svg,
      formattedText: text,
      contentState: newContentState,
    });
  }

  // If we don't need to render, or if we can't because the fonts aren't loaded yet, we just return the updated layer.
  return withUpdatedTags(newLayer.defaultData)({
    ...existingLayer.data,
    style,
  });
};

export const makeEditableTextLayerUpdates = (
  fontsLoaded: boolean,
  attrs: Object,
  surface: Surface
) => (prevLayers: Array<Layer>) => (newLayers: Array<Layer>) =>
  newLayers.map((layer, i) => {
    const dynamicLayer = createDynamicLayer(layer)(0, attrs);

    // If there is an existing Editable Text layer that we can pull data from...
    if (i < prevLayers.length && has(prevLayers[i])('data')) {
      const existingLayer = prevLayers[i];
      // Update the existing data with data from the new layer (this also re-renders the text, if need be).
      const updatedData = updateEditableTextLayerData(
        existingLayer,
        dynamicLayer,
        surface,
        fontsLoaded
      );

      // Return the updated layer.
      return {
        ...dynamicLayer,
        data: updatedData,
      };
    }
    // Otherwise, we'll just create a new layer:
    // Convert the content string from the new layer's defaultData into a raw Draft.js ContentState object.
    const contentState = defaultContentStrToContentState(
      dynamicLayer.defaultData.content
    );

    if (fontsLoaded === true) {
      const { svg, text } = reflowSync(
        dynamicLayer.defaultData.content,
        dynamicLayer.defaultData.style,
        dynamicLayer.width * surface.width * 300,
        dynamicLayer.height * surface.height * 300,
        contentState
      );

      return {
        ...dynamicLayer,
        data: withUpdatedTags(dynamicLayer.defaultData)({
          ...dynamicLayer.defaultData,
          contentState,
          renderedContent: svg,
          formattedText: text,
        }),
      };
    }

    return {
      ...dynamicLayer,
      data: withUpdatedTags(dynamicLayer.defaultData)({
        ...dynamicLayer.defaultData,
        contentState,
      }),
    };
  });

/* Copies data over from any existing editable text layers that have the same id as a new editable text layer.
   Otherwise, copy the default data over into a new data object, and render the default data. */
export const updateEditableTextLayers = (
  designEditableTextLayers: Array<Object>,
  existingEditableTextLayers: Array<Object>,
  surface: Object,
  fontsLoaded: boolean,
  attrs: Object
): Array<Layer> => {
  const _makeLayerUpdates = makeEditableTextLayerUpdates(
    fontsLoaded,
    attrs,
    surface
  );
  if (
    isPartialToPartial(existingEditableTextLayers)(designEditableTextLayers)
  ) {
    // our existing layers sorted to right or left
    const existingLeftLayers = getLeftLayers(existingEditableTextLayers);
    const existingRightLayers = getRightLayers(existingEditableTextLayers);

    // our new layers sorted to right or left
    const newLeftLayers = getLeftLayers(designEditableTextLayers);
    const newRightLayers = getRightLayers(designEditableTextLayers);

    // update the left layers
    const updatedLeftLayers = _makeLayerUpdates(existingLeftLayers)(
      newLeftLayers
    );
    // update the right layers
    const updatedRightLayers = _makeLayerUpdates(existingRightLayers)(
      newRightLayers
    );

    return [...updatedLeftLayers, ...updatedRightLayers];
  }
  return _makeLayerUpdates(existingEditableTextLayers)(
    designEditableTextLayers
  );
};

const matchingRegion = (r) => (rs) =>
  optionFind(
    (region) => region.className === r.className && has(region)('data'),
    rs
  );

const updateEditableDesignAssetLayerRegions = (
  existingRegions: Array<Object>,
  newRegions: Array<Object>
) =>
  newRegions.map((newRegion) =>
    matchingRegion(newRegion)(existingRegions)
      .map((r) => ({
        ...newRegion,
        data: r.data,
      }))
      .getOrElseValue({
        ...newRegion,
        data: newRegion.defaultData,
      })
  );

const shouldApplyDefaultRegions = (incomingLayer, outgoingLayer) => {
  const incomingFoil = ['foil_stamp', 'digital_foil'].includes(incomingLayer?.printType);
  const outgoingFoil = ['foil_stamp', 'digital_foil'].includes(outgoingLayer?.printType);

  return incomingFoil || (!incomingFoil && outgoingFoil);
};

export const updateEditableDesignAssetLayers = (
  designEDAlayers: Array<Object>,
  existingEDAlayers: Array<Object>
): Array<Layer> =>
  designEDAlayers.map((layer) => {
    const matchingLayer = withId(layer.id)(existingEDAlayers);

    return {
      ...layer,
      regions: updateEditableDesignAssetLayerRegions(
        prop('regions')(shouldApplyDefaultRegions(layer, matchingLayer) ? [] : matchingLayer).getOrElseValue([]),
        prop('regions')(layer).getOrElseValue([])
      ),
    };
  });

// Updates any existing user photo layers to use the given design layers, and includes any other design layers.
export const updateLayers = (
  designLayers: Array<Object>,
  existingLayers: Array<Object>,
  pageNum: number,
  prevSurface: Surface,
  newSurface: Surface,
  fontsLoaded: boolean,
  attrs: Object,
  userPhotos: Array<Object>
) => [
  ...updateUserPhotoLayers(
    userPhotoLayers(designLayers),
    userPhotoLayers(existingLayers),
    prevSurface,
    newSurface,
    userPhotos
  ),
  ...updateEditableTextLayers(
    editableTextLayers(designLayers),
    editableTextLayers(existingLayers),
    newSurface,
    fontsLoaded,
    attrs
  ),
  ...updateEditableDesignAssetLayers(
    editableDesignAssetLayers(designLayers),
    editableDesignAssetLayers(existingLayers)
  ),
  ...withCallables(pageNum)(otherLayers(designLayers))(attrs),
];

// Gets an object of the number of LayerType in a given array of Layers
export const numOfLayerType = (layerTypes: Array<LayerType>) => (
  xs: Array<Layer>
): LayerCounts =>
  layerTypes.reduce(
    (acc, layerType: LayerType) => ({
      ...acc,
      [layerType]: xs.reduce(
        (count, x) => (layerType === x.type ? count + 1 : count),
        0
      ),
    }),
    {}
  );

// Returns a list of Design id's and the LayerType counts within that Design
export const getDesignsLayerCount = (layerTypes: Array<LayerType>) => (
  designs: Array<Design>,
  layers: Array<Layer>
): Array<DesignIdLayerCount> =>
  designs
    .map((design: Design) => ({
      id: design.id,
      _layers: layersInDesign(design, layers),
    }))
    .map(
      ({
        id,
        _layers,
      }: {
        id: string,
        _layers: Array<Layer>,
      }): DesignIdLayerCount => ({
        id,
        layerCounts: numOfLayerType(layerTypes)(_layers),
      })
    );

// Gets a list of Design id's that have equal counts of LayerType
export const comparableLayers = (
  currentNumberOfLayerType: Option<LayerCounts>,
  designsLayerCount: Array<DesignIdLayerCount>
): Option<Array<string>> =>
  currentNumberOfLayerType.map((x: LayerCounts) =>
    designsLayerCount
      .filter((y: DesignIdLayerCount) => isEqual(x, y.layerCounts))
      .map(({ id }) => id)
  );

// Gets a random Design that has the same LayerType counts, or none
export const getFallbackDesign = (
  designs: Array<Design>,
  layers: Array<Layer>,
  currentLayers?: Array<Layer>
) => (layerTypes: Array<LayerType>): Option<Design> => {
  const currentNumberOfLayerType: Option<LayerCounts> = option
    .fromNullable(currentLayers)
    .map(numOfLayerType(layerTypes));

  return comparableLayers(
    currentNumberOfLayerType,
    getDesignsLayerCount(layerTypes)(designs, layers)
  )
    .chain((xs) =>
      (xs.length === 1
        ? index(0)(xs)
        : index(Math.round(getRandomArb(0, xs.length)))(xs))
    )
    .chain((id) => findFirst((x) => x.id === id)(designs));
};

/* Takes a surface, a target surface, and an array of layers and recalculates the size and position percentages
   of the layer, which should be based on the first surface, to place it on the second surface. Also shifts the
   layer's x and y coordinates to place it in the same spot relative to the start of the safe area for each
   dimension (e.g. bleed_left + safeOffset_left for x). */
const translateLayers = (prevSurface: Surface) => (nextSurface: Surface) => (
  layers: Array<Layer>
): Array<Layer> =>
  layers.map(
    (layer: Layer): Layer => {
      const wIn = layerPcToInW(prevSurface)(layer);
      const hIn = layerPcToInH(prevSurface)(layer);
      const xIn = layerPcToInX(prevSurface)(layer);
      const yIn = layerPcToInY(prevSurface)(layer);

      const prevBleed = parseBleedValue(prevSurface);
      const nextBleed = parseBleedValue(nextSurface);

      const prevOffset = parseSafeOffset(prevSurface);
      const nextOffset = parseSafeOffset(nextSurface);

      const xInFromSafe = xIn - prevOffset[3] - prevBleed[3];
      const yInFromSafe = yIn - prevOffset[0] - prevBleed[0];

      const nextXin = xInFromSafe + nextOffset[3] + nextBleed[3];
      const nextYin = yInFromSafe + nextOffset[0] + nextBleed[0];

      const x = clampX(nextSurface)(0)(layerInToPcX(nextSurface)(nextXin));
      const y = clampY(nextSurface)(0)(layerInToPcY(nextSurface)(nextYin));
      const width = clampW(nextSurface)(x)(layerInToPcW(nextSurface)(wIn));
      const height = clampH(nextSurface)(y)(layerInToPcH(nextSurface)(hIn));

      return {
        ...layer,
        id: markLayerIdTranslated(layer.id),
        width,
        height,
        x,
        y,
      };
    }
  );

/* Given an array of new designs and an optional currently-selected design,
   returns a design from the new array that has the same ID as the currently-selected design,
   if there is one. Otherwise, attempts to find a design that resembles the current design.
   If one can't be found, it returns the default design from the given designs array. */
export const matchingDesignOrDefault = (
  availableDesigns: Array<Design>,
  currentDesign: Design = null,
  availableLayers?: Array<Layer> = [],
  currentLayers?: Array<Layer> = [],
  prevSurface?: Surface = {},
  defaultSurface?: Surface = {},
  additionalSurfaces?: Array<Surface> = []
): [Design, Array<Layer>] => {
  const availableDesignIds = availableDesigns.map((design) => design.id);

  if (currentDesign.customized === true) {
    /* Checks if a design with a matching ID and surface ID (or no surface ID) exists, and if the previous and
       new default surfaces are the same dimensions. */
    const identicalDesignExists =
      (designIsComposite(currentDesign)
        ? currentDesign.partialDesignIds.every((id) =>
          availableDesignIds.includes(id)
        )
        : availableDesignIds.includes(currentDesign.id)) &&
      (availableDesigns.find(
        (design) => design.surfaceId && design.surfaceId === prevSurface.id
      ) ||
        surfacesAreSame(prevSurface)(defaultSurface));

    // If a design identical to the customized one exists...
    if (identicalDesignExists) {
      // Get all of the customized or shifted layers (i.e. layers that aren't part of the template's static layers array).
      const customizedLayers = currentLayers.filter(
        (layer) =>
          layerIdIsCustomized(layer.id) ||
          layerIdIsTranslated(layer.id) ||
          layerIdIsShifted(layer.id)
      );

      // Return the current design - unaltered - and the array of generated, non-pre-existing, layers.
      return [currentDesign, customizedLayers];
    }

    // Look for a design that has the same ID as the current design, though not necessarily the same surface.
    const matchingDesign = availableDesigns.find(
      (design) => design.id === currentDesign.id
    );

    /* If there is a matching design, and the previous design and matching design both reference surfaceIds
       that are included in the next additionalSurfaces array... */
    if (
      matchingDesign &&
      additionalSurfaces.some((s) => s.id === matchingDesign.surfaceId) &&
      additionalSurfaces.some((s) => s.id === currentDesign.surfaceId)
    ) {
      // Get the surface called for by the matching design.
      const nextSurface = additionalSurfaces.find(
        (surface) => surface.id === matchingDesign.surfaceId
      );

      /* If there is a surface found for the next design, and both the previous and next surface support layer translation
         (i.e. shifting the position of layers on a design to account for changes in dimensions between two surfaces),
         translate the layers. */
      if (
        nextSurface &&
        prevSurface.supportsLayerTranslation &&
        nextSurface.supportsLayerTranslation
      ) {
        // Translate the current layers from the previous surface to the next one.
        const shiftableLayers = currentLayers.filter(
          (l) => l.userCanMove && l.userCanResize
        );
        const matchingDesignLayers = layersInDesign(
          matchingDesign,
          availableLayers
        );
        const unshiftableLayers = matchingDesignLayers.filter(
          (l) => !l.userCanMove || !l.userCanResize
        );

        const shiftedLayers = translateLayers(prevSurface)(nextSurface)(
          shiftableLayers
        );

        const nextLayers = unshiftableLayers.concat(...shiftedLayers);

        /* Return the contents of the matching design - marked as customized and with an array of layer IDs
          equal to those of the shifted layers - and the array of shifted layers. */
        return [
          {
            ...matchingDesign,
            customized: true,
            layers: nextLayers.map((l) => l.id),
          },
          shiftedLayers,
        ];
      }
    }
  }

  if (designIsComposite(currentDesign)) {
    // Try to find designs with matching ids to the current left and right designs from the new designs array.
    const [matchingLeft, matchingRight]: [
      Option<Design>,
      Option<Design>,
    ] = currentDesign.partialDesignIds
      .map((id) => withId(id)(availableDesigns))
      .map(option.fromNullable);

    const currentLayersBySide: [Array<Layer>, Array<Layer>] = [
      currentLayers.filter((layer) => !layerIdIsShifted(layer.id)),
      currentLayers.filter((layer) => layerIdIsShifted(layer.id)),
    ];

    const partialDesigns = availableDesigns.filter(designIsPartial);

    const [fallbackDesignLeft, fallbackDesignRight]: Array<
      Option<Design>
    > = currentLayersBySide.map((layersOnSide) =>
      getFallbackDesign(partialDesigns, availableLayers, layersOnSide)([
        USER_PHOTO,
        EDITABLE_TEXT,
      ])
        .alt(
          getFallbackDesign(partialDesigns, availableLayers, layersOnSide)([
            EDITABLE_TEXT,
          ])
        )
        .alt(
          getFallbackDesign(partialDesigns, availableLayers, layersOnSide)([
            USER_PHOTO,
          ])
        )
    );

    const finalDesignLeft = matchingLeft.alt(fallbackDesignLeft);
    const finalDesignRight = matchingRight.alt(fallbackDesignRight);

    /* If both final designs are present, return a composite design of the two.
       Otherwise, continue on to picking a full coverage design. */
    if (finalDesignLeft.isSome() && finalDesignRight.isSome()) {
      return createCompositeDesign(availableLayers, prevSurface)(
        finalDesignLeft.toNullable()
      )(finalDesignRight.toNullable());
    }
  }

  // Get only the full coverage designs.
  const fullDesigns = availableDesigns.filter(not(designIsPartial));

  const _getFallbackDesign = getFallbackDesign(
    fullDesigns,
    availableLayers,
    currentLayers
  );

  // Try to match on all layer types, then by text, then photo layer count.
  const fallbackDesigns: Option<Design> = _getFallbackDesign([
    USER_PHOTO,
    EDITABLE_TEXT,
  ])
    .alt(_getFallbackDesign([EDITABLE_TEXT]))
    .alt(_getFallbackDesign([USER_PHOTO]));

  const design: Design = prop('id')(currentDesign)
    .chain((id) => option.fromNullable(withId(id)(fullDesigns)))
    .alt(fallbackDesigns)
    .getOrElseValue(defaultDesign(fullDesigns));

  return [design, []];
};

// Takes a design id string and returns a properly-formatted design attribute name (for Magento).
const designAttributeNameWithPrefix = (prefix: string) => (
  design: Design
): string =>
  ('magentoOptionValue' in design && design.magentoOptionValue.length > 0
    ? design.magentoOptionValue
    : design.id.includes(FULL_BLEED)
      ? 'fullbleed'
      : `${prefix}${design.id}`);

export const designAttributeNameByCategory = (category: Category) => (
  design: Design
): string =>
  designAttributeNameWithPrefix(shouldAddMatPrefix(category) ? 'mat' : '')(
    design
  );

// Takes a layer object and returns an object of style tags for positioning that layer correctly.
export const layerPosition = (layer: Layer) => ({
  left: `${layer.x * 100}%`,
  top: `${layer.y * 100}%`,
  width: `${layer.width * 100}%`,
  height: `${layer.height * 100}%`,
  zIndex: layer.zIndex || 1,
  ...(has(layer)('rotate') && layer.rotate > 0
    ? {
      transform: `rotate(${layer.rotate}deg)`,
      transformOrigin: 'top left',
    }
    : {}),
});

/* Takes a surface object and a layer object, and returns an object containing position information for
   a box representing the visible portion of the provided layer on the given surface. */
export const getBleedBox = (surface: Surface, layer: Layer) => {
  const bleed = parseBleedValue(surface);
  const [
    templateBleedTop,
    templateBleedRight,
    templateBleedBottom,
    templateBleedLeft,
  ] = bleed;

  const safeWidth = surface.width - (templateBleedLeft + templateBleedRight);
  const safeHeight = surface.height - (templateBleedTop + templateBleedBottom);

  // Safe bounds
  const safeTop = templateBleedTop;
  const safeBottom = templateBleedBottom + safeHeight;
  const safeLeft = templateBleedLeft;
  const safeRight = templateBleedRight + safeWidth;

  // Converted layer dimensions
  const layerXin = layer.x * surface.width;
  const layerYin = layer.y * surface.height;
  const layerWin = layer.width * surface.width;
  const layerHin = layer.height * surface.height;

  // Layer bounds
  const layerTop = layerYin;
  const layerBottom = layerYin + layerHin;
  const layerLeft = layerXin;
  const layerRight = layerXin + layerWin;

  // Bleed Box Positioning
  const bleedTop = floor(0)(safeTop - layerTop) / layerHin;
  const bleedBottom = floor(0)(layerBottom - safeBottom) / layerHin;

  const bleedLeft = floor(0)(safeLeft - layerLeft) / layerWin;
  const bleedRight = floor(0)(layerRight - safeRight) / layerWin;

  const bleedWidth = cap(1 - (bleedLeft + bleedRight))(safeWidth / layerWin);
  const bleedHeight = cap(1 - (bleedTop + bleedBottom))(safeHeight / layerHin);

  return {
    left: `${bleedLeft * 100}%`,
    top: `${bleedTop * 100}%`,
    width: `${bleedWidth * 100}%`,
    height: `${bleedHeight * 100}%`,
  };
};

// Sorts an array of layers by their z-indices
// sortByZindex :: Array -> Array
export const sortByZindex = (layers: Array<Layer>) =>
  sort((a, b) => compare(a.zIndex || 1)(b.zIndex || 1))(layers);

// Returns an array of [pageId, layerId] pairs for all layers in the given pages for which the predicate f is true.
export const filterAllLayers = (f: Object => boolean) => (
  pages: Array<Object>
): Array<Array<string>> =>
  flatten(
    pages
      .map((page) =>
        page.layers.reduce(
          (result, layer) =>
            (f(layer) ? [...result, [page.id, layer.id]] : result),
          []
        )
      )
      .filter((pageResult) => pageResult.length > 0)
  );

/* Returns a boolean indicating whether or not the given Photo, after being cropped in accordance
   with the given cropData, is high enough resolution for the given surface. */
export const croppedPhotoIsHighRes = (
  extractedPhoto: Object,
  cropData: Object,
  surface: Object,
  layer: Object
) => {
  const { width: surfaceWidth, height: surfaceHeight } = surface;
  const { width: layerWidth, height: layerHeight } = layer;

  const photoWellWidth = surfaceWidth * layerWidth;
  const photoWellHeight = surfaceHeight * layerHeight;

  let imageScaleFactor = extractedPhoto.dimensions.width / cropData.imageData.naturalWidth;
  const userPhotoExifRotation = getExifRotationDegrees(extractedPhoto);
  if (userPhotoExifRotation + (cropData.cropperData?.rotate || 0) !== 0) {
    imageScaleFactor = extractedPhoto.dimensions.width / cropData.imageData.naturalHeight;
  }
  const scaledCropWidth = cropData.cropperData.width * imageScaleFactor;
  const scaledCropHeight = cropData.cropperData.height * imageScaleFactor;
  const croppedPPIWidth = scaledCropWidth / photoWellWidth;
  const croppedPPIHeight = scaledCropHeight / photoWellHeight;
  const croppedPPI = croppedPPIWidth * croppedPPIHeight;
  const minPPI = 180 * 180;
  return croppedPPI >= minPPI;
};

// Takes an object with width and height props, and returns the width divided by the height.
export const getAspectRatio = (d: { width: number, height: number }) =>
  d.width / d.height;

export const layerIsEditableTextAndNotSpine = (layer: Layer): boolean => (isEditableTextLayer(layer) && !layerIsSpine(layer));
