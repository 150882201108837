export const SUPPORTED_ATTRIBUTES_DISPLAY_TYPES = ['dropdown', 'swatch'];

export const LEGACY_CARD_ATTRIBUTE_KEYS = ['card_quantity'];

// Category constants
export const CALENDARS = 'calendars';
export const CARDS = 'cards';
/**
 * @todo
 * 1. Handle individual cards category
 * 2. Display like books, a card per page
 * 3. Force data into layers through an action
 * 4. Build UI
 * 5. Add to cart checking
 * 6. Character limiting
 */
export const INDIVIDUAL_CARDS = 'individual cards';
export const PRINTS = 'prints';
export const BOOKS = 'books';
export const SPREAD_BOOKS = 'spread books';
export const FRAMES = 'frames';
export const OTHER = 'other';
export const ESCORT_CARDS = 'colorblock-escort-cards';
export const FOLDED_CARDS = 'folded_cards';
export const SIGNATURE_SPREAD_BOOKS = 'signature spread books';

// Unique Page IDs
export const WOODEN_BOX_PAGE_ID = 'complete_your_set-walnut-box';
export const END_SHEET_PAGE_ID = 'back_endsheet';

// Product SKUs with special instructions
export const SKU_SIG_LAY = 'signature-layflat-photo-album';

// CATEGORY FEATURES
// Categories that should include a cover
export const CATEGORIES_HIDE_PRICE = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS, CARDS];

// Categories that includes envelopes
export const CATEGORIES_WITH_ENVELOPES = [CARDS, FOLDED_CARDS];

export const CATEGORIES_WITH_CUTOUT_MASK = [FOLDED_CARDS];

// Categories that should include a cover
export const CATEGORIES_WITH_COVERS = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

// Categories that support manipulated page order and count
export const CATEGORIES_WITH_PAGE_MANIPULATION = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

// Categories that should show a welcome screen upon initial editor open
export const CATEGORIES_WITH_WELCOME = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

// Categories that should enable clicking half of the page
export const CATEGORIES_WITH_PARTIAL_PAGE_SELECTION = [SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

// Categories that should display inside covers
export const CATEGORIES_WITH_INSIDE_COVERS = [BOOKS];

// Categories that should display endsheets that are editable
export const CATEGORIES_WITH_ENDSHEETS = [SIGNATURE_SPREAD_BOOKS];

// Categories that should display two pages side-by-side
export const CATEGORIES_WITH_TWO_UP = [BOOKS];

// Categories that should include random designs
export const CATEGORIES_WITH_RANDOM_DESIGNS = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

// Categories that should show the filter dropdown in the layout picker
export const CATEGORIES_WITH_LAYOUT_FILTER = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

// Categories that are any book
export const CATEGORIES_BOOK = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

export const CATEGORIES_WITH_WOODEN_BOX = [SIGNATURE_SPREAD_BOOKS];

// Categories that should have a coffee table preview
export const CATEGORIES_WITH_PREVIEW = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

export const DO_NOT_SHOW_PREVIEW_SKUS = ['baby-board-book', 'acrylic-wall-calendar'];

// Categories that should prefix design ids with 'mat'
export const CATEGORIES_WITH_MAT_PREFIX = [FRAMES];

// Categories that should match page count to quantity
export const CATEGORIES_PAGE_COUNT_MATCH_QUANTITY = [INDIVIDUAL_CARDS];

// Products that should have the same color for every single page
export const SKUS_WITH_UNIFORM_COLOR = [ESCORT_CARDS];

export const SKUS_WITH_COVER_TEXT_V3 = [SKU_SIG_LAY];

export const MOONCHILD_FONT_ENABLED_SKUS = [
  'handwritten-ampersand-save-the-date',
  'modern-details-save-the-date',
  'be-our-guest-save-the-date',
  'simply-elegant-save-the-date',
  'classic-botanical-save-the-date',
  'sweet-and-simple-save-the-date',
  'details-first-save-the-date',
  'scripted-initials-save-the-date',
  'big-announcement-save-the-date',
  'our-big-day-save-the-date',
  'encircled-save-the-date',
  'subtle-botanical-save-the-date',
  'hardcover-wedding-photo-book',
  'hardcover-wedding-photo-book-new',
  'hardcover-travel-photo-book',
  'hardcover-milestone-photo-book',
  'hardcover-annual-photo-book',
  'photo-wrapped-hardcover',
  'hardcover',
  'vow-book',
  'acrylic-wall-calendar',
  'foil-arches-holiday-card',
  'be-light-holiday-card',
  'only-upside-holiday-card',
  'tell-on-holiday-card',
  'simple-window-holiday-card',
  'joyful-deluxe-holiday-card',
  'bold-handwritten-christmas-card',
  'year-in-review-holiday-card',
  'arched-window-holiday-card',
  'multi-merry-photo-card',
  'vintage-vignette-holiday-card',
  'ornamental-foil-holiday-card',
  'arched-botanical-greeting-card',
  'retro-windows-holiday-card',
  'retro-wavy-holiday-card',
  'falling-together-holiday-card',
  'handwritten-wishes-holiday-card',
  'merry-me-holiday-card',
  'holiday-flow-greeting-card',
  'photo-capsule-holiday-card',
  'retro-ornament-holiday-card',
  'half-and-half-holiday-card',
  'art-deco-holiday-card',
  'capsule-greeting-holiday-card',
  'botanic-window-holiday-card',
  'cheerful-checkerboard-holiday-card',
  'fa-la-la-holiday-card',
  'merry-merry-holiday-card',
  'arches-around-holiday-card',
  'modern-links-holiday-card',
  'merry-mail-holiday-card',
  'be-merry-holiday-card',
  'bold-merry-card-foil',
  'brushed-border-holiday-card',
  'cheers-hand-lettered-holiday-card-foil',
  'classic-corners-holiday-card',
  'duo-image-love-card',
  'foil-arches-holiday-card',
  'foil-stamped-happy-foil-card',
  'foil-stamped-merry-card',
  'foil-stamped-snowfall-card',
  'gallery-style-holiday-card',
  'geometric-connection-holiday-card',
  'hand-drawn-stars-card',
  'hand-lettered-joy-card',
  'handwritten-trio-holiday-card',
  'inner-circle-holiday-card',
  'merry-christmas-card-foil',
  'merry-me-holiday-card',
  'modern-merry-christmas-card',
  'new-adventures-new-year-card',
  'oh-what-fun-card',
  'ornamental-foil-holiday-card',
  'oval-window-holiday-card',
  'script-duo-holiday-card',
  'script-film-strip-holiday-card',
  'simple-things-card',
  'sketched-pines-holiday-card',
  'starry-holiday-card',
  'trio-photo-strip-holiday-card',
  'warmest-wishes-holiday-card',
  'well-wishes-holiday-card',
  'bold-banner-holiday-card',
  'bold-bright-holiday-card',
  'bold-joyeux-holiday-card',
  'border-grid-holiday-card',
  'borderedlove',
  'broad-brushed-holiday-card',
  'cheerful-checkerboard-holiday-card',
  'clean-line-multi-image-card',
  'cursive-colorblock-holiday-card',
  'effortless-holiday-card',
  'heaven-nature-holiday-card',
  'layered-multi-image-holiday-card',
  'merry-and-bright-multi-image-card',
  'merry-mail-holiday-card',
  'merry-merry-holiday-card',
  'modern-letters-holiday-card',
  'multicolored-greeting-holiday-card',
  'retro-wavy-holiday-card',
  'shifted-shapes-holiday-card',
  'simple-branchlet-holiday-card',
  'simple-contour-holiday-card',
  'simple-photo-holiday-card',
  'simple-square-holiday-card',
  'simple-trio-holiday-card',
  'simply-handwritten-holiday-card',
  'square-gratitude-holiday-card',
  'square-multi-image-holiday-card',
  'stacked-serif-holiday-card',
  'symmetry-multi-image-card',
  'tell-on-multi-photo-holiday-card',
  'tis-the-season-4-image-card',
  'tis-the-season-card',
  'to-all-that-will-be-card',
  'trio-script-holiday-card',
  'abstract-holly-holiday-card',
  'bold-merry-bright-holiday-card',
  'botanic-window-holiday-card',
  'cheersmultimage',
  'falling-together-holiday-card',
  'geometric-holiday-card',
  'hand-lettered-holiday-card',
  'hand-lettered-merriest-christmas-card',
  'hand-lettered-merry-holiday-card',
  'hand-lettered-multi-image-joy-card',
  'hand-lettered-new-year-card',
  'hand-lettered-oh-what-fun-card',
  'hellonewyearmultiimage',
  'herestothegood',
  'in-all-things-joy-card',
  'joy-to-the-world-holiday-card',
  'joyful-deluxe-holiday-card',
  'joymultiimage',
  'merriest-christmas-card',
  'merry-script-holiday-card',
  'minimal-bordered-new-year-card',
  'modern-lines-holiday-card',
  'modern-links-holiday-card',
  'modern-new-years-card',
  'peaceholiday',
  'season-ahead-holiday-card',
  'side-by-side-holiday-card',
  'simple-2022-new-years-card',
  'simplejoyeux',
  'withlovegrid',
  'simple-oval-holiday-card',
  'christmas-script-holiday-card',
  'simple-line-holiday-card',
  'minimal-handwritten-holiday-card',
  'festive-stamp-holiday-card',
  'pastel-multi-image-holiday-card',
  'classic-holiday-card',
  'wavy-line-holiday-card',
  'bold-tis-the-season-holiday-card',
  'elegant-wishes-holiday-card',
  'half-arch-holiday-card',
  'classic-9-image-holiday-card',
  '3-image-photo-strip-holiday-card',
  '9-image-photo-strip-holiday-card',
  'merry-stitches-holiday-card',
  'modern-magazine-holiday-card',
  'scripted-greeting-holiday-card',
  'hand-drawn-line-holiday-card',
  'fun-circles-holiday-card',
  'editorial-christmas-holiday-card',
  'handwritten-capsule-holiday-card',
  'elegant-window-holiday-card',
  'modern-christmas-holiday-card',
  'squiggle-portrait-holiday-card',
  'ornament-arch-holiday-card',
  'merry-capsule-holiday-card',
  'scallop-square-holiday-card',
  'arched-window-holiday-card',
  'arches-around-holiday-card',
  'be-light-holiday-card',
  'bold-handwritten-christmas-card',
  'fa-la-la-holiday-card',
  'handwritten-wishes-holiday-card',
  'happy-half-and-half-holiday-card',
  'holiday-flow-greeting-card',
  'minimal-ribbon-holiday-card',
  'minimalist-sketch-holiday-card',
  'multi-merry-photo-card',
  'multiimage2017',
  'only-upside-holiday-card',
  'photo-capsule-holiday-card',
  'simple-multi-image-grid-card',
  'vintage-vignette-holiday-card',
  'year-in-review-holiday-card',
];
export const UPTON_FONT_ENABLED_SKUS = [
  'marquee-lettered-holiday-card',
  'backdrop-feature-holiday-card',
  'modern-frame-holiday-card',
  'merry-christmas-bow-card',
  'our-favorite-things-christmas-card',
  'classic-frame-christmas-card',
  'four-photo-holiday-card',
  'modern-trio-holiday-card',
  'snapshot-holiday-card',
  'tied-with-a-bow-holiday-card',
  'snowbank-holiday-card',
  'special-moments-holiday-card',
  'simple-happy-holidays-card',
  'merry-christmas-script-card',
  'photo-captions-holiday-card',
  'retro-checkerboard-holiday-card',
  'name-in-lights-holiday-card',
  'layered-love-holiday-card',
  'taped-collage-holiday-card',
  'happy-holidays-stamp-card',
  'window-panes-holiday-card',
  'mood-board-holiday-card',
  'ripple-holiday-card',
  'half-and-half-holiday-card',
  'ribbon-accent-holiday-card',
  'stacked-letter-holiday-card',
  'ho-ho-ho-debossed-christmas-card',
  'joyeux-noel-card',
  'middle-ground-holiday-card',
  'scrapbook-holiday-card',
  'merry-bright-collage-card',
];
