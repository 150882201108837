// @flow
import { type MagentoAddress } from '../../types/address';
import {
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY_V2, ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY_V2
} from '../../constants/envelopes';

export const ENVELOPE_COLORS_WITH_WHITE_INK = [
  'EVERGREEN',
  'JUNIPER',
  'WINTERBRY',
];

export const SET_RETURN_ADDRESS = 'ENVELOPE_ADDRESSING/SET_RETURN_ADDRESS';
export const SET_RECIPIENT_ADDRESS = 'ENVELOPE_ADDRESSING/SET_RECIPIENT_ADDRESS';
export const SET_RECIPIENTS_CSV = 'ENVELOPE_ADDRESSING/SET_RECIPIENTS_CSV';
export const UNSET_ENVELOPE_ADDRESSING = 'ENVELOPE_ADDRESSING/UNSET_ENVELOPE_ADDRESSING';
export const SET_COUNTRIES = 'ENVELOPE_ADDRESSING/SET_COUNTRIES';
export const SET_REGIONS = 'ENVELOPE_ADDRESSING/SET_REGIONS';
export const SET_ENVELOPE_FONT = 'ENVELOPE_ADDRESSING/SET_ENVELOPE_FONT';

export const RETURN_ADDRESS_STATE_KEY = 'returnAddress';
export const RECIPIENT_ADDRESS_STATE_KEY = 'recipientAddress';
export const CSV_KEY_STATE_KEY = 'csvKey';
export const CSV_FILE_NAME_STATE_KEY = 'csvFilename';
export const ENVELOPE_FONT = 'font';

/** Manual Recipient Addressing */
export const MANUAL_RECIPIENT_ADDRESS = 'MANUAL_RECIPIENT_ADDRESS';
export const SET_MANUAL_RECIPIENT_ADDRESS = 'ENVELOPE_ADDRESSING/SET_MANUAL_RECIPIENT_ADDRESS';
/** End Manual Recipient Addressing */

export const USA_COUNTRY_CODE = 'US';

export const addressingValuesWithReturnAddress = [
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY_V2,
];

// Used for fallback when no address data is present
export const noDataAddress: MagentoAddress = {
  id: -1,
  region: {
    region_code: 'State',
    region: 'Colorado',
  },
  country_id: 'Country',
  street: ['Street 1', 'Street 2'],
  postcode: 'Postal Code',
  city: 'City',
  firstname: 'Name',
  lastname: '',
  middlename: null,
  prefix: null,
  suffix: null,
  default_shipping: false,
};
