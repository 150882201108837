import { optionFind } from './functions';
import { FLASH_ID, FLASH_TOKEN } from '../constants/user';
import type { MagentoCustomerResponseAttr } from '../types/user';

export const getFromCustomerAttrs = (
  property: string,
  customerAttrs: MagentoCustomerResponseAttr[]
) => optionFind(
  (attr: MagentoCustomerResponseAttr) => attr.attribute_code === property,
  customerAttrs
)
  .map((attr: MagentoCustomerResponseAttr) => attr.value)
  .getOrElseValue('');

export const getFlashIdFromCustomerAttrs = (
  customerAttrs: MagentoCustomerResponseAttr[]
) => getFromCustomerAttrs(FLASH_ID, customerAttrs);

export const getFlashTokenFromCustomerAttrs = (
  customerAttrs: MagentoCustomerResponseAttr[]
) => getFromCustomerAttrs(FLASH_TOKEN, customerAttrs);
