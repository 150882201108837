import { type Surface, type Layer } from './templates';

export type Page = {
  id: string,
  name: string,
  layers: Array<Layer>,
  surface: Surface,
  isEditable?: boolean,
  collapsedInteriorPage?: boolean,
  unselectable?: boolean,
}

// To everyone wondering why we have to do this, it is because Marathon is unable to parse
// objects on their end, so we need to transmit each property individually
export const V3_TEXT = 'CoverText';
export const V3_ALIGNMENT = 'CoverAlignment';
export const V3_FONT = 'CoverFont';
export const V3_FONT_SIZE = 'CoverFontSize';
export const V3_POSITION = 'CoverPosition';

export type CoverTextV3 = {
  CoverText1: string,
  CoverAlignment1: string,
  CoverFont1: string,
  CoverFontSize1: string,
  CoverPosition1: string,

  CoverText2: string,
  CoverAlignment2: string,
  CoverFont2: string,
  CoverFontSize2: string,
  CoverPosition2: string,

  CoverText3: string,
  CoverAlignment3: string,
  CoverFont3: string,
  CoverFontSize3: string,
  CoverPosition3: string,

  CoverText4: string,
  CoverAlignment4: string,
  CoverFont4: string,
  CoverFontSize4: string,
  CoverPosition4: string,

  CoverText5: string,
  CoverAlignment5: string,
  CoverFont5: string,
  CoverFontSize5: string,
  CoverPosition5: string,

  CoverText6: string,
  CoverAlignment6: string,
  CoverFont6: string,
  CoverFontSize6: string,
  CoverPosition6: string
}
