// @flow

import { prop } from "./functions";

export const isString = (val) => val && typeof val === "string";

// Data-last wrapper for String#split
export const split = (splitOn: string) => (str: string): Array<string> =>
  String.prototype.split.call(str, splitOn);

// Joins two strings/numbers together with an 'x' to create a dimension string.
export const dimensionsString = (
  x: number | string,
  y: number | string
): string => `${x}x${y}`;

/* Takes any number of arguments, either strings or nulls, and returns a string
   containing all of the non-null, non-empty strings joined together by spaces.
   Intended for use in React components when there are css classes to be applied conditionally. */
export const classNames = (...strs: Array<?string>): string =>
  strs.filter((str) => typeof str === "string" && str.length > 0).join(" ");

/* Converts from camelCase to kebab-case. Useful for converting React-style css property names into
   their actual CSS format. From https://gist.github.com/nblackburn/875e6ff75bc8ce171c758bf75f304707 */
export const camelToKebab = (str: string): string =>
  str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();

/* converts camel cased strings to spaces */

export const camelToSpaces = (str: string): string =>
  str.replace(/([A-Z])/g, " $1").replace(/^./, (s) => s.toUpperCase());

// Converts an any to snake_case.
export const snakecase = (str: any): string =>
  str
    .toString()
    .replace(/ /g, "_")
    .toLowerCase();

// Takes a React-syle object of CSS declarations and turns it into a standard inline CSS string
export const cssify = (reactStyles: { [string]: number | string }) =>
  Object.keys(reactStyles)
    .map(
      (styleName) => `${camelToKebab(styleName)}: ${reactStyles[styleName]};`
    )
    .join(" ");

// Applies a transformation function to a template string, where replaceable parts are demarcated by `{` and `}`.
export const templateStr = (f: (string, string) => string) => (str: string) =>
  isString(str) ? str.replace(/{([^{}]*)}/g, f) : str;

// Takes an object and a template string and replaces the template placeholders with the values of the indicated object property.
export const strReplace = (o: Object) => (str: string): string =>
  templateStr((a, b) => {
    if (b && b[b.length - 1] === "?" && !o.hasOwnProperty(b.replace("?", ""))) {
      return "";
    }
    const r = o[b.replace("?", "")];
    return typeof r === "string" || typeof r === "number" ? r.toString() : a;
  })(str).replace(/_$/, "");

// Converts strings individual words to be uppercased. AKA titleCase.
export const startCase = (str: string): string =>
  str
    .split(" ")
    .map((y: string): string => y[0].toUpperCase() + y.slice(1))
    .join(" ");

// Trims whitespace from the end of a string
export const trimEnd = (str: string): string => str.replace(/\s*$/, "");

// Takes a number of bytes, and optionally a precision, and returns a string in the appropriate format.
export const formatBytes = (a: number, b?: number = 2) => {
  if (a === 0) {
    return "0 Bytes";
  }

  const c = 1024;
  const e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const f = Math.floor(Math.log(a) / Math.log(c));

  return parseFloat((a / c ** f).toFixed(b)) + e[f];
};

// Concats the first passed string to the end of the second passed string.
export const concat = (s2: string) => (s1: string) => `${s1}${s2}`;

/* Takes a prop and an object and returns an Option possibly containing the given property's
   key/value pair in a human-readable format. */
export const propToString = (k: string) => (obj: Object) =>
  prop(k)(obj).map((v) => `${k}: ${v}`);

// Data-last wrapper for String#includes
export const strIncludes = (search: string) => (str: string) =>
  str.includes(search);

export const validString = (err: string) => (val: any) =>
  isString(val) ? "" : err;

export const validStringLength = (strLength: number) => (err: string) => (
  val: any
) => (isString(val) && val.length === strLength ? "" : err);

export const extractNumbersFromStr = (str: string): number =>
  parseInt(str.replace(/[^0-9]/g, ""), 10);

export const stripHiddenChars = (text = "", arr) => {
  //since the array of hidden characters coming in could have more lines
  // than the text due to the SVG rendering, we are removing the hidden
  // characters by deleting them from the end of the text using regex

  //standarize function used on both the text and hiddenChars array
  // so that the quotes and double quotes line up for the regex.
  const standardizeQuotes = (string: string) => {
    return string
      .replace(/[\u02BB\u02BC\u066C\u2018-\u201A\u275B\u275C]/gm, "'")
      .replace(/[\u201C-\u201E\u2033\u275D\u275E\u301D\u301E]/gm, '"')
      .replace(
        /[[\u0027\u02B9\u02BB\u02BC\u02BE\u02C8\u02EE\u0301\u0313\u0315\u055A\u05F3\u07F4\u07F5\u1FBF\u2018\u2019\u2032\uA78C\uFF07]/gm,
        "'"
      );
  };

  text = standardizeQuotes(text);

  const array = [...arr];
  array.reverse();
  array.forEach((val) => {
    val = standardizeQuotes(val);
    //escaping regex special characters in value: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
    const escapedValueForRegex = val.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    var re = new RegExp(`${escapedValueForRegex}(\n)*$`);
    text = text.trim().replace(re, "");
  });
  text = text.trim();

  return text;
};
