// @flow

import _sortBy from 'lodash.sortby';
import flowRight from 'lodash.flowright';
import map from 'lodash.map';
import flatten from 'lodash.flatten';
import { type Option } from 'fp-ts/lib/Option';
import { compose } from 'fp-ts/lib/function';
import { tail as fpTail, reverse as fpReverse, drop } from 'fp-ts/lib/Array';

import { optionFind, prop, equals } from '../functions';
import { option } from 'fp-ts';

/* takes an array, a starting index, number of items to remove at index moving forward (including starting index), and an array of items
   and returns a new array with the items placed at start point */
// From https://vincent.billey.me/pure-javascript-immutable-array/#splice
export const splice = <T>(
  arr: Array<T>,
  start: number,
  deleteCount: number,
  ...items: Array<T>
) => [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)];

// Data-last wrapper for Array.prototype.slice
export const slice = (start: number, end: ?number) => (
  arr: Array<any>
): Array<any> => arr.slice(start, end || arr.length);

// Takes an array and a compare function and returns a sorted array. "Pure" wrapper for Array#sort
export const sort = (f: (any, any) => number) => (
  arr: Array<any>
): Array<any> => arr.slice(0).sort(f);

// Reverse the given array. "Pure" wrapper for Array#reverse
export const reverse = <T>(arr: Array<T>): Array<T> => arr.slice(0).reverse();

// Find the index of the last item in an array that satisfies the given predicate.
export const findLastIndex = <T>(predicate: T => boolean) => (arr: Array<T>) =>
  arr.length - 1 - reverse(arr).findIndex(predicate);

export const findIndices = (predicate: any => boolean) => (arr: Array<any>) =>
  arr.reduce(
    (indices, element, index) =>
      predicate(element) ? [...indices, index] : indices,
    []
  );

type PredicateActionTuple<T> = [boolean, (x: T) => T];

export const applyActionsToArray = <T>(
  actions: Array<PredicateActionTuple<T>>
) => (data: Array<T>) =>
  actions.reduce((accum, current) => {
    const [predicate, action] = current;
    return [...(predicate === true ? action(accum) : accum)];
  }, data);

// elementExists :: Function -> Array -> Boolean
export const elementExists = (predicate: any => boolean) => (arr: Array<any>) =>
  arr.findIndex(predicate) > -1;

// findIndexOr :: Function -> Array -> Function -> Function -> Any
export const findIndexOr = (predicate: any => boolean) => (arr: Array<any>) => (
  withIndex: any => any
) => (otherwise: any => any) => {
  const index = Array.prototype.findIndex.call(arr, predicate);

  if (index > -1) {
    return withIndex(index);
  }

  return otherwise();
};

export const arrayNotEmptyOr = (arr: Array<any>) => (
  withLength: any => any
) => (withoutLength: any => any) =>
  arr && arr.length > 0 ? withLength(arr) : withoutLength(arr);

// Maps an array and flattens it
export const flatMap: (
  Array<any>,
  (any) => Array<any>
) => Array<any> = flowRight(
  flatten,
  map
);

// last :: [a] -> a
export const last = (xs: Array<any>) => xs[xs.length - 1];

// head :: [a] -> a
export const head = (xs: Array<any>) => xs[0];

// tail :: [a] -> [a]
export const tail = (xs: Array<any>): Array<any> => xs.slice(1);

export const allButLast = compose(
  x => x.map(fpReverse),
  fpTail,
  fpReverse
);

/* Takes a function for modifying an array element, a predicate for finding that element,
   and an array to find the element in, and returns a modified version of the array. */
export const changeArrayElement = (f: (x: any) => any) => (
  p: (y: any) => boolean
) => (xs: Array<any>) =>
  optionFind(p, xs)
    .map(f)
    .map(y => splice(xs, xs.findIndex(p), 1, y))
    .getOrElseValue(xs);

// For a given id and array, returns an element in the array with the given id. Otherwise, returns undefined.
export const withId = (id: any) => (arr: Array<{ id: any }>): ?{ id: any } =>
  arr.find(e => e.id === id);

// Wrapper for changeArrayElement that looks for an object in the given array that has the given id.
export const changeElementWithId = (f: (x: Object) => Object) => (
  ...ids: Array<string>
) => (os: Array<Object>) => changeArrayElement(f)(o => ids.includes(o.id))(os);

// Wrapper for changeArrayElement that looks for an object in the given array that has the given id.
export const changeElementWithPropValue = (key: string) => (
  f: (x: Object) => Object
) => (...values: Array<string>) => (os: Array<Object>) =>
  changeArrayElement(f)(o => values.includes(o[key]))(os);

// Takes a key, a value, and an array of objects, and returns the element that has a matching key/value pair.
export const withPropValue = (k: string) => (v: any) => (
  xs: Array<Object>
): Option<Object> =>
  optionFind(
    x =>
      prop(k)(x)
        .map(equals(v))
        .getOrElseValue(false),
    xs
  );

// removes the number of elements specified from the end of an array.
export const removeFromEnd = (num: number) =>
  compose(
    drop(num),
    fpReverse
  );

// Returns a random element from the given array.
export const getRandomElement = (arr: Array<any>) =>
  arr[Math.floor(arr.length * Math.random())];

// Data-last wrapper for Array#fill
export const fillArray = (x: any) => (len: number): Array<any> =>
  new Array(len).fill(x);

// Creates an array of nulls of the given length and then maps over it, calling the given function with each index.
// TODO: Figure out why flow doesn't like this being curried.
export const fillArrayBy = <T>(f: number => T, len: number): Array<T> =>
  fillArray(null)(len).map((_, i) => f(i));

// Creates a sequential array of all of the numbers, with the given scale, in the inclusive range between two positive numbers.
export const fillRange = (scale: number = 0) => (min: number) => (
  max: number
): Array<number> => {
  // Multiplier for converting between integer and floats of a given scale.
  const scaleMultiplier = 10 ** scale;

  // Scale the max and min values, and convert to integers to avoid floating-point rounding errors.
  const scaledMin = Math.floor(min * scaleMultiplier);
  const scaledMax = Math.floor(max * scaleMultiplier);

  // Create an array where each element is equal to the scaled index plus the scaled minimum.
  const scaledArray = fillArrayBy(
    n => n + scaledMin,
    scaledMax - scaledMin + 1
  );

  // Convert the array of scaled numbers to an array of numbers with the given scale.
  return scaledArray.map(n => parseFloat((n / scaleMultiplier).toFixed(scale)));
};

export const uniqConcat = <T>(x: T) => (arr: Array<T>): Array<T> =>
  arr.includes(x) ? arr : arr.concat(x);

// Given an option producing function, find the first element in a collection that returns a some, otherwise, return a none
// @todo figure out if the performance here is okay. This uses was fp-ts uses under the hood for array filtering
export const firstSomeInArray = <T>(optionFn: T => Option<T>) => (
  collection: Array<mixed>
): Option<T> => {
  const length = collection.length;
  // eslint-disable-next-line
  for (let i = 0; i < length; ++i) {
    const v = optionFn(collection[i]);
    if (option.isSome(v)) {
      return v;
    }
  }
  return option.none;
};

// checks if item is in array
export const isInArray = <A>(x: A) => (xs: Array<A>): boolean => xs.includes(x);

/* Data-last wrapper for lodash.sort. Using 'any' type here as lodash's sortBy doesn't care too much about types,
   and also because Flow's generics are misbehaving with this. Remember to specify the return type when calling this. */
export const sortBy = (x: string | Array<string> | (any => any)) => (
  arr: Array<any>
): Array<any> => _sortBy(arr, x);

// Predicate indicating whether or not the array `a` is a subset of `b` (i.e. every element in `a` is also present in `b`).
export const isSubset = (b: Array<any>) => (a: Array<any>): boolean =>
  a.every(x => b.includes(x));

// Predicate indicating whether or not the array `a` is a superset of `b` (i.e. every element in `b` is also present in `a`).
export const isSuperset = (b: Array<any>) => (a: Array<any>): boolean =>
  isSubset(a)(b);

// Returns an array containing all of the values in `b` that aren't in `a`.
export const difference = (b: Array<any>) => (a: Array<any>): Array<any> =>
  b.filter(x => !a.includes(x));

// Given a function to get the value of a property and an array of objects, this removes duplicates based on that property
export const dedupeBy = (getValue: any => any) => (
  array: Array<any>
): Array<any> => {
  const set = new Set();
  return array.filter(x => {
    const value = getValue(x);
    return !set.has(value) ? set.add(value) && true : false;
  });
};

/**
 * Given an array of objects, invert the array to an object that has
 * shared keys across the objects with an array of values
 *
 * ex: [{a: 1, b: 2}, {a:3, b:4}] => {a: [1,3], b: [3,4]}
 */
export const invertArrayToObject = (arr: Object[]): Object => {
  const obj = {};
  for (let i = 0; i < arr.length; i++) {
    const element = arr[i];
    // eslint-disable-next-line
    Object.keys(element).map(x => {
      if (obj && obj.hasOwnProperty(x)) {
        obj[x].push(element[x]);
      } else {
        obj[x] = [element[x]];
      }
    });
  }
  return obj;
};
