// @flow

// Stops propagation and prevents default behavior for a given event.
const stopEvent = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();
};

/* Returns a function that takes an event, stops that event,
   and calls the original function with that event. */
export const stoppedHandler = (f: Event => void) => (e: Event) => {
  stopEvent(e);
  f(e);
};

export default stopEvent;
