import { findIndexOr } from '../arrays';
import match from '../match';

import {
  USER_PHOTO,
  DESIGN_ASSET,
  EDITABLE_TEXT,
  EDITABLE_DESIGN_ASSET,
  ADDRESS_LAYER,
  CUTOUT_LAYER,
  MASK_LAYER,
} from '../../constants/layers';
import { getPhotoFromId } from '../images';

// getPathElement :: Array -> String -> Any
export const getPathElement = pathComponents => elementName => otherwise => (
  findIndexOr(item => item === elementName)(pathComponents)(
    index => pathComponents[index + 1]
  )(
    () => otherwise
  )
);

// parseDataPath :: String -> { pageIndex :: Number, layerIndex :: Number }
export const parseDataPath = (dataPath) => {
  const pathComponents = dataPath.split('/');

  const pageIndex = parseInt(getPathElement(pathComponents)('pages')(-1), 10);
  const layerIndex = parseInt(getPathElement(pathComponents)('layers')(-1), 10);

  return {
    pageIndex: isNaN(pageIndex) ? -1 : pageIndex,
    layerIndex: isNaN(layerIndex) ? -1 : layerIndex,
  };
};

// validUserPhoto :: [Object] -> Number -> Boolean
export const validUserPhoto = userPhotos => userPhotoId => (
  getPhotoFromId(userPhotos)(userPhotoId).isSome()
);

/* Takes an array of userPhoto objects and an array of page objects, and returns an array of [pageId, layerId] pairs,
   one for each of the layers pointing at an invalid userPhoto ID. */
// validateUserPhotos :: [Object] -> [Object] -> [Object]
export const getInvalidUserPhotoLayerPaths = userPhotos => pages => pages.reduce((pairs, page) => (
  [
    ...pairs,
    ...page.layers.reduce((pagePairs, layer) => (
      layer.type === 'user_photo' && layer.data && !validUserPhoto(userPhotos)(layer.data.userPhotoId) ? (
        [
          ...pagePairs,
          [
            page.id,
            layer.id,
          ],
        ]
      ) : (
        pagePairs
      )
    ), []),
  ]
), []);

/* Filters an array of ajv errors, returning an array that contains only the errors that apply to their respective layers,
   as well as any errors that do not concern a particular layer. */
// getApplicableErrors :: [Object] -> [Object] -> [Object]
export const getApplicableErrors = errors => pages => (
  Array.isArray(errors) ? (
    errors.filter((error) => {
      const { pageIndex, layerIndex } = parseDataPath(error.dataPath);

      const errorIsNotPageRelated = (pageIndex === -1 || layerIndex === -1);

      const layerType = !errorIsNotPageRelated ? pages[pageIndex].layers[layerIndex].type : 'none';

      const errorIsForLayerType = match(
        USER_PHOTO, () => error.message.includes('photoLayer'),
        DESIGN_ASSET, () => error.message.includes('designAsset'),
        EDITABLE_TEXT, () => error.message.includes('editableText'),
        EDITABLE_DESIGN_ASSET, () => error.message.includes('editableDesignAsset'),
        ADDRESS_LAYER, () => false,
        CUTOUT_LAYER, () => false,
        MASK_LAYER, () => false,
        match.default, () => true,
      )(layerType);

      return errorIsNotPageRelated || errorIsForLayerType;
    })
  ) : (
    []
  )
);
