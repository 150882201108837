// @flow
import {
  SET_RETURN_ADDRESS,
  SET_RECIPIENT_ADDRESS,
  SET_RECIPIENTS_CSV,
  UNSET_ENVELOPE_ADDRESSING,
  SET_COUNTRIES,
  SET_REGIONS,
  SET_MANUAL_RECIPIENT_ADDRESS,
  SET_ENVELOPE_FONT,
} from './constants';
import { type MagentoAddress, type Country, type Region } from '../../types/address';

/** Added in support of Free Mother's Day Card */
export type ManualAddress = {
  firstName: string,
  lastName: string,
  street1: string,
  street?: string,
  city: string,
  state: string,
  zipcode: number;
}

export type EnvelopeAddressingState = {
  returnAddress: ?MagentoAddress,
  recipientAddress: ?MagentoAddress,
  manualRecipientAddress?: ManualAddress,
  csvKey: ?string,
  csvFilename: ?string,
  countries: ?Array<Country>,
  regions: { [string]: Array<Region> }
};

type SetAddressAction = {
  type: string,
  payload: {
    address: MagentoAddress,
  }
}

type SetCsvUrlAction = {
  type: string,
  payload: {
    csvKey: ?string,
    csvFilename: ?string,
  }
};

type SetManualRecipientFieldAction = {
  type: string,
  payload: [
    string,
    string
  ]
}

type EnvelopeAddressingActions = SetAddressAction | SetCsvUrlAction | SetManualRecipientFieldAction;

export const defaultEnvelopeAddressingState: EnvelopeAddressingState = {
  returnAddress: null,
  recipientAddress: null,
  csvFilename: null,
  csvKey: null,
  countries: [],
  regions: {},
};

export const envelopeAddressingReducer = (
  state: EnvelopeAddressingState = defaultEnvelopeAddressingState,
  action: EnvelopeAddressingActions
) => {
  switch (action.type) {
    case SET_RETURN_ADDRESS: {
      const { address } = action.payload;

      return {
        ...state,
        returnAddress: address,
      };
    }

    case SET_RECIPIENT_ADDRESS: {
      const { address } = action.payload;

      return {
        ...state,
        recipientAddress: address,
      };
    }

    case SET_RECIPIENTS_CSV: {
      const { csvKey, csvFilename, contactGroupName } = action.payload;

      return {
        ...state,
        csvKey,
        csvFilename,
        contactGroupName,
      };
    }

    case UNSET_ENVELOPE_ADDRESSING: {
      return {
        // Reset everyting to null
        ...defaultEnvelopeAddressingState,
      };
    }

    case SET_COUNTRIES: {
      return {
        ...state,
        countries: action.payload,
      };
    }

    case SET_REGIONS: {
      const { id, available_regions } = action.payload; // eslint-disable-line camelcase
      return {
        ...state,
        regions: {
          ...state.regions,
          [id]: available_regions,
        },
      };
    }

    case SET_MANUAL_RECIPIENT_ADDRESS: {
      const { address } = action.payload;

      return {
        ...state,
        manualRecipientAddress: address,
      };
    }

    case SET_ENVELOPE_FONT: {
      const { font } = action.payload;
      return {
        ...state,
        font,
      };
    }

    default: {
      return state;
    }
  }
};

export const envelopeAddressingStateFilter = (state: EnvelopeAddressingState) => ({
  ...state,
  countries: null,
  regions: null,
});
