// @flow

import smartquotes from 'smartquotes';
import type { Option } from 'fp-ts/lib/Option';
import { option, fromNullable, none, some } from 'fp-ts/lib/Option';
import { flatten, head as fpHead } from 'fp-ts/lib/Array';
import {
  convertFromRaw,
  convertToRaw,
  ContentBlock,
  ContentState,
} from 'draft-js';
import immutable from 'immutable';
import isEqual from 'lodash.isequal';

import { last, head, tail, slice } from './arrays';
import { cssify, split } from './strings';
import { floor } from './numbers';
import { memoize, compose, pathEquals, chain } from './functions';
import {
  element,
  textNode,
  withAttributes,
  appendChild,
  convertSpaces,
} from './DOM';
import { calculateLineHeight, buildSVG, textDXtotal } from './svg';

type InlineStyles = 'BOLD' | 'ITALIC' | 'UNDERLINE' | 'NONE';

// a pair of text and list of styles
type StyleLine = [string, Array<InlineStyles>];

// a triple of text, list of styles, and width
type LinePart = [string, Array<InlineStyles>, number];

// an object of LineParts and the full width of that line
type Line = { lineStyles: Array<LinePart>, width: number };

type FormattedStyle = {
  color?: string,
  fontFamily?: string,
  fontSize?: string,
  lineHeight?: number,
  textAlign?: string,
  textTransform?: string,
  letterSpacing?: any,
  letterSpacingPX?: number,
  verticalAlign?: 'top' | 'middle' | 'bottom' | 'block-middle',
};

// HELPERS
const widthWithSpacing = (offset: number) => elem =>
  elem.getComputedTextLength() + offset;

/* Returns the sum of the given width and a "safety margin" that is computed as the logarithm of the given fontSize times 10.
   The goal of this is to compute a safety factor that is progressively less influenced by the given fontsize. */
const widthWithSafetyMargin = (fontSizePX: number) => (width: number) =>
  width + Math.log(fontSizePX) * 10;

const splitSpaces = split(' ');

const prevListItem = (i: number, xs): Option<any> =>
  xs.length === 0 ? none : fromNullable(xs[i - 1]);
const getAllButLast = (i: number, xs: Array<any>): Array<any> =>
  xs.length === 0 ? [] : xs.slice(0, i - 1);

// Returns the maximum number of lines that can fit into the given height.
const maxLines = (lineHeightPX: number, heightPX: number) =>
  Math.floor(heightPX / lineHeightPX);

/* Parses a defaultData string line, and returns an array of StyleLines with the appropriate styles
   applied in accordance with the string's 'markdown'. */
const parseDefaultBlock = (block: string): Array<StyleLine> =>
  block
    // Parse italic segments
    .split('_')
    .map((segment: string, i: number) =>
      i % 2 ? [segment, ['ITALIC']] : [segment, ['NONE']]
    )
    .reduce(
      (result: Array<StyleLine>, styleLine: StyleLine) =>
        // If there isn't already styling applied to this styleLine...
        styleLine[1][0] === 'NONE'
          ? [
              ...result,
              ...styleLine[0] // Parse bold segments
                .split('**')
                .map((segment, i) =>
                  i % 2 ? [segment, ['BOLD']] : [segment, ['NONE']]
                ),
            ]
          : [...result, styleLine],
      []
    );

// parses the initial default text into the correct format
export const parseInitialStrToStyleLines = (
  initString: ?string
): Array<Array<StyleLine>> =>
  fromNullable(initString)
    .map((str: string) =>
      str
        // Break on newline characters
        .split(/\r?\n/)
        .map(
          (block: string): StyleLine[] =>
            block !== '' ? parseDefaultBlock(block) : []
        )
    )
    .getOrElseValue([[['', ['NONE']]]]);

// Parses the default 'content' string into an object of the same format as the 'raw' ContentState object output by Draft.js.
export const defaultContentStrToContentState = (content: ?string): Object => {
  const rawBlocks: Array<Object> = parseInitialStrToStyleLines(content).map(
    (line: StyleLine, i: number) =>
      line
        .filter(([text: string]) => text.length > 0)
        .reduce(
          (result: Object, [text, styles]) => ({
            // Keep the results existing values...
            ...result,

            // Add the new chunk of text to the existing string...
            text: result.text.concat(text),

            // Add an inline style range, if this styleLine is actually styled...
            inlineStyleRanges: [
              ...result.inlineStyleRanges,

              // Arrays used here for conditional concatination using the spread operator.
              ...(!styles.includes('NONE')
                ? [
                    {
                      offset: result.text.length,
                      length: text.length,
                      style: styles[0],
                    },
                  ]
                : []),
            ],
          }),

          // Initial value
          {
            key: i.toString(),
            text: '',
            type: 'unstyled',
            inlineStyleRanges: [],
          }
        )
  );

  const contentBlockArray = rawBlocks.map(
    (block: Object) => new ContentBlock(immutable.Map(block))
  );

  // Create a Draft ContentState from the block array.
  const contentState = ContentState.createFromBlockArray(contentBlockArray);

  // Convert that back to raw.
  const contentStateRaw = convertToRaw(contentState);

  // Add the inlineStyleRanges back, since they get stripped out by the createFromBlockArray call.
  return {
    ...contentStateRaw,
    blocks: contentStateRaw.blocks.map((block: Object, i: number) => ({
      ...block,
      inlineStyleRanges: rawBlocks[i].inlineStyleRanges,
    })),
  };
};

// MAIN
// Reduces ContentState to an array of pairs of containing the string of text and an array of styles applied to that text
const applyStylesToChars = charStylesList => (acc, x, i): Array<StyleLine> => {
  const [prevChar, prevStyle] = acc[acc.length - 1] || ['', []];

  // convert from Immutable to Array
  const charStyle = charStylesList
    .get(i)
    .get('style')
    .toArray();
  const arr = isEqual(prevStyle, charStyle)
    ? acc.slice(0, acc.length - 1)
    : acc;

  // if these styles are the same as the last, just add strings together
  return isEqual(prevStyle, charStyle)
    ? [...arr, [prevChar + x, charStyle]]
    : [...arr, [x, charStyle]];
};

// ensures our whitespace doesn't get lost between words
const preserveEmptySpace = (line: string) => (
  breakout: LinePart[]
): Array<LinePart> => {
  const spaceStart = head(line) === ' ';
  const spaceEnd = last(line) === ' ';

  if (spaceStart) {
    const _fst = head(breakout);
    const fst = [
      head(head(_fst)) === ' ' ? head(_fst) : ` ${head(_fst)}`,
      ...tail(_fst),
    ];

    return [fst, ...tail(breakout)];
  } else if (spaceEnd) {
    const _lst = last(breakout);
    const lst = [`${head(_lst)}`, ...tail(_lst)];

    return [...breakout.slice(0, breakout.length - 1), lst];
  }
  return breakout;
};

// calculates how much can fit in a given line
const computeLineWidth = (
  width: number,
  height: number,
  formattedStyle,
  styles,
  [line],
  word: string
) => {
  // Create invisible SVG element.
  const svg = withAttributes(
    ['width', width],
    ['height', height],
    [
      'viewBox',
      `${
        formattedStyle.textAlign === 'left' ? '-20' : '0'
      } 0 ${width} ${height}`,
    ],
    ['xmlns', 'http://www.w3.org/2000/svg'],
    [
      'style',
      cssify({
        width: `${width}px`,
        height: `${height}px`,
        opacity: 0,
        overflow: 'hidden',
        pointerEvents: 'none',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 0,
      }),
    ]
  )(element('svg'));
  // Create SVG text container element.
  const text = withAttributes(
    ['x', 0],
    ['y', 0],
    [
      'style',
      cssify({
        ...formattedStyle,
        fill: formattedStyle.color,
      }),
    ]
  )(element('text'));

  // Create one SVG tspan element to go inside the text element.
  const initialTspan = withAttributes(
    ['x', 0],
    [
      'y',
      calculateLineHeight(
        formattedStyle.fontSize,
        formattedStyle.lineHeight,
        height,
        formattedStyle.verticalAlign
      ),
    ],
    [
      'style',
      cssify({
        fontWeight: styles && styles.includes('BOLD') ? 'bold' : 'normal',
        fontStyle: styles && styles.includes('ITALIC') ? 'italic' : 'initial',
        fontSize: `${formattedStyle.fontSize}px`,
        fontFamily: formattedStyle.fontFamily,
        textDecoration:
          styles && styles.includes('UNDERLINE') ? 'underline' : 'none',
      }),
    ]
  )(element('tspan'));

  const combinedLine = `${line} ${word}`;

  // Append all of those elements to each other and then the document body.
  appendChild(document.body)(svg)(text)(initialTspan)(
    textNode(convertSpaces(combinedLine))
  );

  const tspan = Array.from(svg.getElementsByTagName('tspan'))[0];

  const totalOffset = textDXtotal(formattedStyle.letterSpacingPX, combinedLine);

  const widthWithWord = widthWithSpacing(totalOffset)(tspan);
  fromNullable(document.body).map(x => x.removeChild(svg));

  return [combinedLine, widthWithWord];
};

// breaks out lines to fit with the given width
const breakoutLines = (
  remainingWidth: number,
  height: number,
  formattedStyle: Object,
  styles: InlineStyles[],
  fullWidth: number
) =>
  memoize(
    (all, line: string): Array<LinePart> => {
      const breakout = splitSpaces(line)
        .reduce(
          (acc: Array<Array<string | number>>, word: string, i: number) => {
            const [words] = acc[acc.length - 1];

            // calculates the width of the current line combined with the next word
            const widthPlusThis = computeLineWidth(
              remainingWidth,
              height,
              formattedStyle,
              styles,
              acc[acc.length === 0 ? 0 : acc.length - 1],
              word
            )[1];

            const fontSizePX = parseInt(formattedStyle.fontSize, 10);

            if (
              widthWithSafetyMargin(fontSizePX)(widthPlusThis) > remainingWidth
            ) {
              const widthAlone = computeLineWidth(
                remainingWidth,
                height,
                formattedStyle,
                styles,
                ['', 0],
                word
              )[1];

              const widthAloneSafe = widthWithSafetyMargin(fontSizePX)(
                widthAlone
              );

              // If the word on its own is too big for one line, break it apart into pieces that will (roughly) fit.
              if (widthAloneSafe > fullWidth) {
                const charsInWord = word.length;
                const charWidth = widthAloneSafe / charsInWord;
                const charsPerLine = Math.max(
                  0,
                  Math.floor(fullWidth / charWidth)
                );

                // To avoid divide-by-zero weirdness, we'll just return the accumulator here when zero characters can fit on a line.
                if (charsPerLine === 0) {
                  return acc;
                }

                const linesNeeded = Math.max(
                  0,
                  Math.ceil(charsInWord / charsPerLine)
                );

                // Create an array of arrays with a length equal to the number of lines needed.
                const blankLines = new Array(linesNeeded).fill([]);

                const newLines = blankLines.map((_, n) => {
                  const wordSegment = word.slice(
                    charsPerLine * n,
                    charsPerLine * (n + 1)
                  );

                  return [
                    wordSegment,
                    computeLineWidth(
                      fullWidth,
                      height,
                      formattedStyle,
                      styles,
                      ['', 0],
                      wordSegment
                    )[1],
                  ];
                });

                return words.length > 0 ? [...acc, ...newLines] : newLines;
              }

              return [...acc, [i === 0 ? word : ` ${word}`, widthAlone]];
            }

            const allButLast = getAllButLast(acc.length, acc);
            return [
              ...allButLast,
              [i === 0 ? word : `${words} ${word}`, widthPlusThis],
            ];
          },
          [['', 0]]
        )
        .map(([words, wordsWidth]) => [words, styles, wordsWidth]);

      return preserveEmptySpace(line)(breakout);
    }
  );

// if a previous line doesn't fill the width, this adds to that line
const addToSmallLine = (
  block: Line,
  width: number,
  height: number,
  formattedStyle: FormattedStyle,
  acc: Array<Line>,
  styles: Array<InlineStyles>,
  allLinesButLast: Array<Line>
) =>
  memoize(
    (line): Option<Array<Line>> => {
      // breakout the lines based on our smaller width (the existing block width - the total spaces width)
      const lineBreakoutSmall: Array<LinePart> = breakoutLines(
        width - block.width,
        height,
        formattedStyle,
        styles,
        width
      )(acc, line);
      const lineToCombine: LinePart = lineBreakoutSmall[0];

      const remainingLine: StyleLine = lineBreakoutSmall
        .slice(1)
        .reduce((_acc, x) => [`${_acc[0]}${x[0]}`, styles], ['', []]);

      const remainingBreakout =
        remainingLine[0] === '' && remainingLine[1].length === 0
          ? []
          : breakoutLines(width, height, formattedStyle, styles, width)(
              acc,
              remainingLine[0]
            ).map((x: LinePart) => ({ lineStyles: [x], width: x[2] }));

      return option.of([
        ...allLinesButLast,
        {
          lineStyles: [...block.lineStyles, lineToCombine],
          width: block.width + lineToCombine[2],
        },
        ...remainingBreakout,
      ]);
    }
  );

// recursively loops through lines to build them into the broken out widths with their styles
const buildLines = memoize(
  ({ width, formattedStyle, height }) => (
    acc: Array<Line>,
    styleLine: StyleLine
  ): Array<Line> => {
    const [line, styles] = styleLine;

    // lastBlock :: { lineStyles :: [[String, [Styles], Width ~ Int]], width :: Int }
    const lastBlock = prevListItem(acc.length, acc);
    const allLinesButLast = getAllButLast(acc.length, acc);

    // if our last line can fit more text, try to fit as much as possible
    // combinedWithLast :: Option { lineStyles :: ..., width :: Int }
    const combinedWithLast: Option<Array<Line>> = lastBlock.chain(block => {
      // Our previous line is too small, let's add to it
      if (block.width < width) {
        return addToSmallLine(
          block,
          width,
          height,
          formattedStyle,
          acc,
          styles,
          allLinesButLast
        )(line);
      }
      return none;
    });

    // Return the combined line(s), or...
    return combinedWithLast.getOrElse(() => {
      // Do something
      const lineBreakout = breakoutLines(
        width,
        height,
        formattedStyle,
        styles,
        width
      )(acc, line);

      return [
        ...acc,
        ...lineBreakout.map(x => ({
          lineStyles: [x],
          width: x[2],
        })),
      ];
    });
  }
);

// if the line starts with a space, remove it
const removeSpaceLineStart = (lines: Line[]): Array<Line> =>
  lines.map(
    (line: Line): Line => ({
      ...line,
      lineStyles: [
        ...(compose(
          head,
          head,
          head
        )(line.lineStyles) === ' '
          ? [
              [
                compose(
                  head,
                  head
                )(line.lineStyles).substring(1),
                ...compose(
                  tail,
                  head
                )(line.lineStyles),
              ],
            ]
          : [head(line.lineStyles)]),
        ...tail(line.lineStyles),
      ],
    })
  );

// drills down blocks (paragraphs) to their lines to rebuild the lines to fit within the given space
const buildUpStylesWidth = (widthHeightStyles: {
  width: number,
  height: number,
  formattedStyle: FormattedStyle,
}) => (styleBlocks: Array<Array<StyleLine>>) =>
  styleBlocks.map(lines => lines.reduce(buildLines(widthHeightStyles), []));

// finds and counts the number of hidden charaters that will not fit
const findHidden = (visibleLines: number) => blocks =>
  slice(visibleLines)(blocks).reduce(
    (acc, blockLine) => [
      ...acc,
      compose(
        chain(fpHead),
        chain(fpHead),
        fpHead
      )(blockLine).getOrElseValue(''),
    ],
    []
  );

// Transforms the text in the styledBlocks array in accordance with the textTransform property of the given FormattedStyle object.
const transformText = (formattedStyle: FormattedStyle) => (
  styledBlocks: Array<Array<StyleLine>>
) =>
  styledBlocks.map(line =>
    line.map(([text, styles]) => [
      pathEquals('textTransform')('uppercase')(formattedStyle)
        ? text.toUpperCase()
        : text,
      styles,
    ])
  );

/*
  A function for calculating how to wrap words from a given string or ContentState in an SVG of provided dimensions.
  Takes a string, a react-like style object, a width number, and a height number, then creates an
  invisible SVG with which to calculate word wrapping. Returns an object containing the following:
    text: a nested group of arrays representing paragraphs and groups of lines and groups of styles,
    svg: a serialized SVG element, for storage in the project state, used by the PDF generator.
    hiddenText: a list of strings that will not be rendered since they will not fit
*/
export const reflowSync = (
  initialStr: string,
  style: FormattedStyle,
  width: number,
  height: number,
  rawContentState: ?Object
) => {
  const { letterSpacing, ...formattedStyle } = style;
  const formattedLetterSpacing = parseFloat(letterSpacing) || 1;

  const letterSpacingPX = floor(0)(
    formattedLetterSpacing * parseInt(formattedStyle.fontSize, 10) -
      parseInt(formattedStyle.fontSize, 10)
  );

  formattedStyle.letterSpacingPX = letterSpacingPX;

  const lineHeightPX = calculateLineHeight(
    formattedStyle.fontSize,
    formattedStyle.lineHeight,
    height,
    formattedStyle.verticalAlign
  );

  // gets the ContentState (if it exists) and converts from raw json
  const contentState = fromNullable(rawContentState).map(convertFromRaw);

  // pulls out the blocks or paragraphs of the text
  const contentBlocks = contentState.map(x => x.getBlockMap());

  const lineContents = contentBlocks.map(blocks =>
    // convert from Immutable type to an Array type
    blocks
      .toArray()
      // reduce the blocks to their characters and styles for that block
      .reduce(
        (acc, contentBlock) => [
          ...acc,
          {
            charList: contentBlock.getText().split(''),
            charStyles: contentBlock.getCharacterList(),
          },
        ],
        []
      )
  );

  const styledBlocks: Option<Array<Array<StyleLine>>> = lineContents.map(xs =>
    // combine the characters and the styles into a pair (StyleLine) of the text with the style for that text
    xs.map(x => x.charList.reduce(applyStylesToChars(x.charStyles), []))
  );

  // if our ContentState exists, else parse our initial string into the correct format
  const formattedLines = styledBlocks
    .alt(some(parseInitialStrToStyleLines(initialStr)))
    // Apply text-transform: uppercase, if needed.
    .map(transformText(formattedStyle))

    // Actually wrap the text
    .map(buildUpStylesWidth({ width, formattedStyle, height }))

    // Clean up wrapped text
    .map(lines =>
      lines
        .map(removeSpaceLineStart)
        .map(line =>
          line
            // Convert from an object to an array for the renderer. Also apply "smart" quotes.
            .map(({ lineStyles }) => [
              lineStyles.map(([words, styles]) => [smartquotes(words), styles]),
            ])
        )
        // Add an array to each empty line array so that empty lines get preserved after flattening.
        .map(line => (line.length === 0 ? [[]] : line))
    )

    .map(flatten);

  // The subset of lines that are likely to be at least partially visible
  const visibleLines = formattedLines.map(
    slice(0, maxLines(lineHeightPX, height))
  );

  // if anything comes back as a None, we don't want the renderer to break, here is a blank default for the renderer
  const emptyLines = [[[['', ['NONE']]]]];

  const [svg, finalLines] = buildSVG(width, height, letterSpacingPX)(
    formattedStyle
  )(visibleLines.getOrElseValue(emptyLines));

  // Build the result object
  const result = {
    text: finalLines,

    svg,

    hiddenText: formattedLines
      .map(findHidden(finalLines.length))
      .getOrElseValue(['']),
  };

  return result;
};

const reflow = async (
  str: string,
  style: FormattedStyle,
  width: number,
  height: number,
  contentState: Object
) => reflowSync(str, style, width, height, contentState);

export default reflow;
