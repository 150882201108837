// element creators
import { strIncludes } from './strings';
import { getFastlyBaseUrl } from './urls';

export const element = tagName => document.createElementNS('http://www.w3.org/2000/svg', tagName);
export const textNode = str => document.createTextNode(str);

// Attributes
export const withAttributes = (...pairs) => (node) => {
  pairs.forEach(pair => (pair.length === 0 ? null : node.setAttribute(...pair)));

  return node;
};

export const appendChild = parent => (child) => {
  if(child.nodeType){
    parent.appendChild(child);
   return appendChild(child);
  } else if(child && child[0] && child[0].nodeType === 1){
    parent.appendChild(child[0]);
    return appendChild(child[0]);
  }
};

export const finalAppendChild = parent => (child) => {
  parent.appendChild(child);

  return parent;
};

export const insertAfter = (parentNode, newNode, referenceNode) => {
  parentNode.insertBefore(newNode, referenceNode.nextSibling);
  return parentNode;
};

export const removeAllChildren = (parent) => {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild);
  }
};

export const convertSpaces = str => str.replace(/\s/g, '\u00A0').replace(/ /g, '\u00A0');

export const getElByClass = x => document.querySelectorAll(x);

export const getElementXY = (o) => {
  let z = o;
  let x = 0;
  let y = 0;
  let c;

  while (z && !isNaN(z.offsetLeft) && !isNaN(z.offsetTop)) {
    c = isNaN(window.globalStorage) ? 0 : window.getComputedStyle(z, null);
    // eslint-disable-next-line
    x += z.offsetLeft - z.scrollLeft + (c ? parseInt(c.getPropertyValue('border-left-width'), 10) : 0);
    // eslint-disable-next-line
    y += z.offsetTop - z.scrollTop + (c ? parseInt(c.getPropertyValue('border-top-width'), 10) : 0);
    z = z.offsetParent;
  }

  // eslint-disable-next-line
  const elementOffsets = { x: o.X = x, y: o.Y = y };
  return elementOffsets;
};

export const preventCloseEventListener = event => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
  event.preventDefault();
  event.returnValue = 'prevent close';
  return 'prevent close';
}

export const debugLog = (...args: any[]) => {
  const params = new URLSearchParams(window.location.search);
  const _debugLog = params.get('debugLog') === 'true';
  if (process.env.REACT_APP_ENV !== 'prod' || _debugLog) {
    console.debug(...args);
  }
};

export const magentoIsReferrer = () => strIncludes(getFastlyBaseUrl())(document.referrer);
