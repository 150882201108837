import {
  SET_TEMPLATE,
  UPDATE_TEMPLATE_LAYER_REGION_DATA_ALL,
} from './constants';
import { updateRegionInLayer } from '../../helpers/pages';

const defaultTemplate = {};

export default function template(state = defaultTemplate, action) {
  switch (action.type) {
    case SET_TEMPLATE:
      return action.payload.template;
    case UPDATE_TEMPLATE_LAYER_REGION_DATA_ALL:
      const { layerId, className, data } = action.payload;

    return updateRegionInLayer({
      page: state,
      layerId,
      className,
    }, { defaultData: data })
    default:
      // Do nothing if we didn't match action type
      return state;
  }
}
