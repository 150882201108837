// @flow

import {
  CALENDARS,
  CARDS,
  PRINTS,
  FRAMES,
  FOLDED_CARDS,
  BOOKS,
  SPREAD_BOOKS,
  SIGNATURE_SPREAD_BOOKS,
} from '../../constants/products';

import { strReplace } from '../strings';
import {
  calendarPageName,
  getMonthsAndYearsFromNext,
  type MonthYearPair,
  getMonthAndYearString,
} from '../calendar';
import match from '../match';
import {
  UI_PAGE_SELECTION_SIDE_LEFT,
  UI_PAGE_SELECTION_SIDE_RIGHT,
} from '../../store/ui/constants';
import '../../components/Workspace/Design.css';
import { smallBreakpoint } from '../breakpoints';

import {
  type Design,
  type Bleed,
  type Bleed4,
  type Surface,
  type Template,
  type ProductData,
  type Layer,
} from '../../types/templates';
import { __PRODUCTION__, __TEST__ } from '../constants';
import { optionGet } from '../functions';
import {
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY_V2
} from '../../constants/envelopes';

export const getPageNameForCategory = (category: string) => (
  pageIndex: number,
  attrs: Object = {}
) =>
  match(
    CALENDARS,
    () => calendarPageName(pageIndex, attrs.start_month),
    BOOKS,
    () => (pageIndex === 0 ? 'Cover' : `Page ${pageIndex}`),
    SPREAD_BOOKS,
    () => (pageIndex === 0 ? 'Cover' : `Spread ${pageIndex}`),
    SIGNATURE_SPREAD_BOOKS,
    () => (pageIndex === 0 ? 'Cover' : `Spread ${pageIndex}`),
    CARDS,
    () => (pageIndex === 0 ? 'Front' : 'Back'),
    FOLDED_CARDS,
    () => (pageIndex === 0 ? 'Inside' : 'Outside'),
    PRINTS,
    () => `Print ${pageIndex + 1}`,
    FRAMES,
    () => `Frame ${pageIndex + 1}`,
    match.default,
    () => `Page ${pageIndex + 1}`
  )(category);

export const getTemplate = (productData: ProductData) => (
  attributes: Object
): Template | null => productData.templates[strReplace(attributes)(productData.nameScheme)];

/* Takes a productData object and, based on the object's 'category' property,
   returns a productData object formatted to include any category-specific attributes. */
export const formatProductDataByCategory = (productData: Object): Object =>
  match(
    CALENDARS,
    () => ({
      ...productData,
      attributes: {
        ...productData.attributes,
        start_month: {
          label: 'Start Month',
          type: 'secondary',
          display: 'dropdown',
          options: getMonthsAndYearsFromNext().map((my: MonthYearPair) => ({
            label: getMonthAndYearString(my, true),
            value: getMonthAndYearString(my),
          })),
        },
      },
    }),
    match.default,
    () => productData
  )(productData.category);

// Normalizes a `bleed` value into a 4-element tuple of bleed values for each side of a rectangle: [top, right, bottom, left]
export const bleedToBleed4 = (defaultVal: number) => (bleed: Bleed): Bleed4 => {
  if (Array.isArray(bleed)) {
    switch (bleed.length) {
      case 1: {
        const [bleedVal] = bleed.map(parseFloat);
        return [bleedVal, bleedVal, bleedVal, bleedVal];
      }
      case 2: {
        const [bleedY, bleedX] = bleed.map(parseFloat);
        return [bleedY, bleedX, bleedY, bleedX];
      }
      case 3: {
        const [bleedTop, bleedRight, bleedBottom] = bleed.map(parseFloat);
        return [bleedTop, bleedRight, bleedBottom, bleedRight];
      }
      case 4:
        return bleed.map(parseFloat);
      default: {
        return [defaultVal, defaultVal, defaultVal, defaultVal];
      }
    }
  }

  if (typeof bleed === 'number') {
    return new Array(4).fill(parseFloat(bleed));
  }

  if (!__PRODUCTION__ && !__TEST__) {
    throw new Error(`Bleed value of ${bleed} should be numeric.`);
  }

  return [defaultVal, defaultVal, defaultVal, defaultVal];
};

// Normalizes a surface's `bleed` value into a 4-element tuple of bleed values for each side of a surface: [top, right, bottom, left]
export const parseBleedValue = (surface: Surface): Bleed4 =>
  bleedToBleed4(0)(surface.bleed);

/* Offset of 0.25 inches from bleed region, so the user doesn't end up putting the text box in
   a configuration where it's difficult to interact with. */
export const DEFAULT_SAFE_OFFSET = 0.25;

/* Normalizes a surface's `safeOffset` value into a 4-element tuple of safeOffset values for
   each side of a surface: [top, right, bottom, left] */
export const parseSafeOffset = (surface: Surface): Bleed4 =>
  bleedToBleed4(DEFAULT_SAFE_OFFSET)(surface.safeOffset);

// Compares target side with provided selection (ui.partialSelection) and returns
// designToApply if they match, otherwise, current design is maintained
const determineDesignForSide = (
  side: UI_PAGE_SELECTION_SIDE_LEFT | UI_PAGE_SELECTION_SIDE_RIGHT
) => (currentDesign: Design) => (designToApply: Design) => (
  selection?: String
): Design => (selection === side ? designToApply : currentDesign);

// Partially applied left and right side design determining fns
export const determineLeftDesign = determineDesignForSide(
  UI_PAGE_SELECTION_SIDE_LEFT
);
export const determineRighttDesign = determineDesignForSide(
  UI_PAGE_SELECTION_SIDE_RIGHT
);

const maxProductOffsets = (drawerVisible: boolean, productHasArrow: boolean) =>
  smallBreakpoint(() => {
    const isLandscape = window.innerWidth > window.innerHeight;
    const landscapeOffset = isLandscape ? 195 : 0;
    switch (true) {
      case productHasArrow && drawerVisible:
        return 365 - landscapeOffset;
      case !productHasArrow && drawerVisible:
        return 320 - landscapeOffset;
      case productHasArrow && !drawerVisible:
        return 150 - landscapeOffset;
      case !productHasArrow && !drawerVisible:
        return 190 - landscapeOffset;
      default:
        return 0;
    }
  })(() => {
    switch (true) {
      case productHasArrow && drawerVisible:
        return 425;
      case !productHasArrow && drawerVisible:
        return 375;
      case productHasArrow && !drawerVisible:
        return 200;
      case !productHasArrow && !drawerVisible:
        return 190;
      default:
        return 0;
    }
  });

/* Calculates the maximum width an element can display in the viewport given an aspect
   ratio and whether or not the bottom drawer is visible.

   For portrait products on mobile, the height offset is increased to ensure the product is always shown above the drawer
*/
export const getMaxProductWidth = (
  aspectRatio: number,
  drawerVisible: boolean,
  productHasArrow: boolean = true
): number => {
  const wh = window.innerHeight;
  const ww = window.innerWidth;
  const hOffset = maxProductOffsets(drawerVisible, productHasArrow);
  // Calculate width as percentage knowing the height
  const hPixels = wh - hOffset;
  const elemWidth = Math.ceil((ww > wh ? hPixels : hPixels) / aspectRatio);
  return elemWidth;
};

// Converts percentage-based layer dimensions into pixel-based dimensions.
const getDim = (surfaceWidthPx: number, surfaceHeightPx: number) => (
  isPartial: boolean
) => (dim: 'x' | 'y' | 'width' | 'height') => ({
  x,
  y,
  width,
  height,
}: Layer) =>
  match(
    'x',
    () => x * (isPartial ? 2 : 1) * surfaceWidthPx,
    'y',
    () => y * surfaceHeightPx,
    'width',
    () => width * (isPartial ? 2 : 1) * surfaceWidthPx,
    'height',
    () => height * surfaceHeightPx,
    match.default,
    () => 0
  )(dim);

export const calculateSurfaceSafeSpecs = (
  surface: Surface,
  templateSize,
  surfaceWidth: number,
  surfaceHeight: number
) => {
  const bleed = parseBleedValue(surface);
  const [bleedTop, bleedRight, bleedBottom, bleedLeft] = bleed;

  const middleY = getDim(surfaceWidth, surfaceHeight)(false)('y')(
    'y' in surface ? surface : { y: 0.5 }
  );
  const middleX = getDim(surfaceWidth, surfaceHeight)(false)('x')(surface);

  const _width = surface.width - (bleedLeft + bleedRight);
  const _height =
    (surface.height - (bleedTop + bleedBottom)) / templateSize.height;
  const width = (_height > _width ? _width / _height : 1) * 100;
  const height = (_width > _height ? _height / _width : 1) * 100;

  return {
    width,
    height,
    offsetX: middleX,
    offsetY: middleY,
  };
};

type HAMMEROption = {
  value: string,
  label: string,
};

export const getEnvelopeAddressingValueFromHAMMERTemplate = (
  valueIncludes: string,
  defaultValue: string,
  valueExcludes?: string,
): string => optionGet('productData.attributes.envelope_addressing.options')(window)
  .map((options: Array<HAMMEROption>) =>
    options.find((_option: HAMMEROption) => {
      if (valueExcludes) {
        return _option.value.toLowerCase().includes(valueIncludes) && !_option.value.toLowerCase().includes(valueExcludes);
      }
      return _option.value.toLowerCase().includes(valueIncludes);
    })
  )
  .filter((_option: HAMMEROption) => !!_option)
  .map((_option: HAMMEROption) => _option.value)
  .getOrElseValue(defaultValue);

export const getEnvelopeAddressingNoneValue = () => getEnvelopeAddressingValueFromHAMMERTemplate(
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE
);

export const getEnvelopeAddressingReplyValue = () => getEnvelopeAddressingValueFromHAMMERTemplate(
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY
);

// This one is special because the OG style of "both" addressing includes the word "return" and "both". So if
// we are looking for the return only value we need to exclude options that have a value that includes the word "both"
export const getEnvelopeAddressingReturnOnlyValue = () => getEnvelopeAddressingValueFromHAMMERTemplate(
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH_V2
);

export const getEnvelopeAddressingBothValue = () => getEnvelopeAddressingValueFromHAMMERTemplate(
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH
);
