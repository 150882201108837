// @flow

import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';
import { option } from 'fp-ts';
import { span, index, head, catOptions } from 'fp-ts/lib/Array';

import has from '../../../helpers/has';
import {
  getDesignsForPage,
  isInsideCover,
  stripHiddenPages,
  getPageCountMinusCover,
  getDesignGroupsForPage,
  groupPages,
} from '../../../helpers/pages';
import { PROJECT_STATE_IN_CART, PROJECT_STATE_UPDATED_IN_CART } from '../constants';
import {
  optionGet,
  prop,
  optionFind,
  optionPair,
  optionFromEmpty,
} from '../../../helpers/functions';
import { flatMap, firstSomeInArray } from '../../../helpers/arrays';
import { canManipulatePages } from '../../../helpers/product';

import { type DesignGroup, type Layer, type Category } from '../../../types/templates';
import { type Page } from '../../../types/page';
import { type Option } from 'fp-ts/lib/Option';
import { USER_PHOTO } from '../../../constants/layers';
import { WOODEN_BOX_PAGE_ID } from '../../../constants/products';
import { layersWithPendingUploadsSelector } from '../../v2/galleries/selectors';

// NOTE: Re-declared from ui/selectors.js to fix a circular dependency
// Returns the id of the currently-selected page.
const currentPageIdSelector = (state: StoreWithUIstate): string | null => state.ui.currentPage;

// NOTE: Re-declared from ui/selectors.js to fix a circular dependency
// Returns the value of the partialSelection state property, either 'left', 'right', or null.
const partialSelectionSelector = (state: StoreWithUIstate): 'left' | 'right' | null => state.ui.partialSelection;

// NOTE: This selector is in this file, and not in template/selectors.js, to avoid a circular dependency between that module and this one.
// Get the { max, min } pages object from the template.
export const templatePagesMinMaxSelector = (state: Object) => state.template.pages;

// NOTE: Re-declared from product/selectors.js to fix a circular dependency
const productCategorySelector = (state: Object) => state.product.category;

// NOTE: Re-declared from helpers/layers/index.js to fix a circular dependency
const isPhotoLayerEmpty = (layer: Object) => (
  !(has(layer)('data') && has(layer.data)('userPhotoId') && layer.data.userPhotoId !== null)
);

// NOTE: Re-declared from helpers/layers/index.js to fix a circular dependency
// Check whether or not the given layer is on the given side.
const isPhotoLayerOnSide = (side: 'left' | 'right') => (layer: Layer) => (layer.x >= 0.5 ? 'right' : 'left') === side;

// NOTE: Re-declared from helpers/layers/index.js to fix a circular dependency
// Filter the given layers array, returning an array of only user photo layers.
const userPhotoLayers = (layers: Array<Layer>) => layers.filter(l => l.type === USER_PHOTO);

/**
 * projectPagesSelector returns an array of all pages in the project
 * @param  {[object]} state redux state
 * @return {[array]} array of pages object. See mock state for sample page object.
 */
export const projectPagesSelector = (state: Object) => state.project.pages.filter(x => !x.static);
export const projectInCartSelector = (state: Object) =>
  state.project.lockState === PROJECT_STATE_IN_CART
  || state.project.lockState === PROJECT_STATE_UPDATED_IN_CART;

// Returns the an array of arrays of pages, each sub-array containing either one or two elements, depending on the category.
export const groupedPagesSelector = createSelector(
  [
    projectPagesSelector,
    productCategorySelector,
  ],
  (pages, category) => groupPages(category)(pages)
);

/**
 * layersInProjectSelector returns all layers that live in a project
 * @type {[array]}
 */
export const layersInProjectSelector = createSelector(
  projectPagesSelector,
  (pages) => {
    let layers = [];
    pages.forEach((page) => {
      layers = layers.concat(page.layers);
    });
    return layers;
  },
);

/**
 * occupiedPagesSelector returns an array of pages in the project that do not have empty layers.
 * Eventually, we will want to add a key to each layer as to whether or not to enforce validation.
 * @type {[array]}
 */
export const occupiedPagesSelector = createSelector(
  projectPagesSelector,
  pages => pages.filter((page) => {
    const { layers } = page;
    const occupiedLayers = layers.filter((layer) => {
      if (has(layer)('data') && layer.data.userPhotoId !== null) {
        return true;
      }
      return false;
    });
    if (occupiedLayers.length > 0) {
      return true;
    }
    return false;
  }),
);

/**
 * occupiedLayersSelector returns all layers in the current project that are occupied (not empty)
 * @type {[array]}
 */
export const occupiedLayersSelector = createSelector(
  layersInProjectSelector,
  layers => layers.filter((layer) => {
    if (has(layer)('data') && layer.data.userPhotoId !== null) {
      return true;
    }
    return false;
  }),
);

/**
 * availablePhotoLayerCountSelector returns a count of photo layers that are empty.
 * @type {number}
 */
export const availablePhotoLayerCountSelector = createSelector(
  projectPagesSelector,
  pages =>
    option
      .fromNullable(pages)
      .map((pgs) => {
        const layers = pgs
          .map(p =>
            p.layers
              .filter(l => (!has(l)('data') || !l.data.userPhotoId) && l.type === 'user_photo')
          )
          .reduce((acc, currentValue) => acc.concat(currentValue), []);
        return layers.length;
      })
      .getOrElseValue([]),
);

/**
 * availableLayersSelector returns an array of pages in the project that have empty layers.
 * @type {[array]}
 */
export const availablePhotoLayersSelector = createSelector(
  projectPagesSelector,
  pages =>
    option
      .fromNullable(pages)
      .map((pgs) => {
        const layers = pgs
          .map(p =>
            p.layers
              .filter(l => (!has(l)('data') || !l.data.userPhotoId) && l.type === 'user_photo')
              .map(l => ({ ...l, pageId: p.id }))
          )
          .reduce((acc, currentValue) => acc.concat(currentValue), []);
        return layers;
      })
      .getOrElseValue([]),
);

/**
 * photoLayersInProjectSelector returns all userPhoto players that live in a project with page id
 * @type {[array]}
 */
export const photoLayersInProjectSelector = createSelector(
  projectPagesSelector,
  pages =>
    option
      .fromNullable(pages)
      .map((pgs) => {
        const layers = pgs
          .map(p =>
            p.layers
              .filter(l => (l.type === 'user_photo'))
              .map(l => ({ ...l, pageId: p.id }))
          )
          .reduce((acc, currentValue) => acc.concat(currentValue), []);
        return layers;
      })
      .getOrElseValue([]),
);
// returns all userPhotoId's that are used in the project
export const userPhotoIdsInProjectSelector = createSelector(
  occupiedLayersSelector,
  layers => catOptions(layers.map(optionGet('data.userPhotoId')))
);

// Returns the currently-selected page.
export const currentPageSelector = createSelector(
  [
    projectPagesSelector,
    currentPageIdSelector,
  ],
  (pages, selectedPageId) => pages.find(page => page.id === selectedPageId),
);

// Returns the design object belonging to the currently-selected page.
export const currentDesignSelector = createSelector(
  currentPageSelector,
  currentPage => currentPage.surface.design,
);

// Returns the design id of the currently-selected page (or partial page).
export const currentDesignIdSelector = createSelector(
  [
    currentPageSelector,
    partialSelectionSelector,
  ],
  (currentPage, partialSelection) => (
    optionPair(option.fromNullable(partialSelection))(optionGet('surface.design.partialDesignIds')(currentPage))
      .chain(([selection, partialDesignIds]) => index(selection === 'left' ? 0 : 1)(partialDesignIds))
      .getOrElseValue(currentPage.surface.design.id)
  ),
);

export const currentSurfaceSelector = createSelector(
  currentPageSelector,
  currentPage => currentPage.surface,
);

// Returns the layers array fo the currently-selected page.
export const currentPageLayersSelector = createSelector(
  [
    currentPageSelector,
  ],
  currentPage => currentPage.layers,
);

// Returns a boolean indicating whether or not to show the page arrows.
export const pageArrowsVisibleSelector = createSelector(
  projectPagesSelector,
  pages => pages.length > 1
);

/**
 * Returns the project page array
 */
export const currentPages = (state: Object) => state.project.pages;

/**
 * Returns the template surface object
 */
export const templateSurface = (state: Object) => state.template.surface;

/**
 * Returns the template designs array
 */
export const templateDesigns = (state: Object) => state.template.designs;

/**
 * Returns the template design groups array
 */
export const templateDesignGroups = (state: Object): Array<DesignGroup> => state.template.designGroups;

// Returns the number of pages in the project
export const currentPageCountSelector = createSelector(
  currentPages,
  pages => pages.length,
);

/* Returns the number of "visible" (from a data perspective) pages in the project,
   i.e. not including the "inner cover" pages. */
export const currentVisiblePageCountSelector = createSelector(
  currentPages,
  pages => stripHiddenPages(pages).length,
);

// Returns the number of "visible" pages in a project, minus any front cover.
export const currentVisiblePageCountMinusCoverSelector = createSelector(
  [
    currentVisiblePageCountSelector,
    productCategorySelector,
  ],
  (visiblePageCount, category) => getPageCountMinusCover(category)(visiblePageCount),
);

// Returns the current "visible" (from a data perspective) pages in the project, i.e. not including the "inner cover" pages
export const currentVisiblePagesSelector = createSelector(
  currentPages,
  pages => stripHiddenPages(pages),
);

// Returns the index, starting from 1, of the currently-selected page.
export const currentPageIndexSelector = createSelector(
  [
    currentVisiblePagesSelector,
    currentPageIdSelector,
  ],
  (visiblePages, pageId) => (
    visiblePages.findIndex(page => page.id === pageId) + 1
  )
);

// Returns the designs available for a given page
export const currentPageAvailableDesignsSelector = createSelector(
  [
    currentPageIndexSelector,
    templateDesigns,
    currentVisiblePageCountSelector,
    currentPageSelector,
    productCategorySelector,
  ],
  (currentPageIndex, designs, pageCountWithCover, currentPage, category) => (
    isInsideCover(currentPage)
      ? []
      : getDesignsForPage(designs, pageCountWithCover, currentPageIndex, getPageCountMinusCover(category)(pageCountWithCover))
  ),
);

/**
 * getEmptyPhotoLayersOnPage :: {page} => [{layer}]
 */
export const getEmptyPhotoLayersOnPage = (page: Page, layersWithPendingUploads: string[]) => {
  return userPhotoLayers(page.layers).reduce((allEmptyLayersOnPage, layer) => {
    if (isPhotoLayerEmpty(layer) && !layersWithPendingUploads.includes(layer.id)) {
      return [...allEmptyLayersOnPage, layer];
    }
    return allEmptyLayersOnPage;
  }, []);
};

/**
 * getEmptyPhotoLayersOnPage :: [{page}] => {layer}
 */
export const getNextEmptyPhotoLayer = (pages: Array<Page>, layersWithPendingUploads: string[]) => {
  let pageIdWithEmptyLayer: string;
  let emptyLayerId: string;
  pages.forEach((page) => {
    const emptyLayer = userPhotoLayers(page.layers).find((layer) => isPhotoLayerEmpty(layer) && !layersWithPendingUploads.includes(layer.id));
    if (!emptyLayerId && emptyLayer) {
      pageIdWithEmptyLayer = page.id;
      emptyLayerId = emptyLayer.id;
    }
  });

  if (emptyLayerId) {
    return {
      pageId: pageIdWithEmptyLayer,
      layerId: emptyLayerId,
    };
  }

  return {};
};

// naming is hard
const optionFromPredicateOnPageWithEmptyLayers = (layersPredicate: Layer => bool, layersWithPendingUploads: string[]) => (page: Page): Option<{
  pageId: string,
  layerId: string,
}> => {
  return optionFromEmpty(getEmptyPhotoLayersOnPage(page, layersWithPendingUploads).filter(layersPredicate))
  .map(layers => ({
    pageId: page.id,
    layerId: layers[0].id,
  }));
}

// get next empty photo layer with priority for the current side of the current page, falling back to any subsequent empty photo layer.
export const getNextEmptyPhotoLayerBySide = (pages: Array<Page>, side: 'left' | 'right', layersWithPendingUploads: string[]) => {
  const firstPage = head(pages);
  return firstPage
    .chain(optionFromPredicateOnPageWithEmptyLayers(isPhotoLayerOnSide(side), layersWithPendingUploads))
    .alt(firstSomeInArray(optionFromPredicateOnPageWithEmptyLayers(() => true, layersWithPendingUploads))(pages))
    .getOrElseValue({});
};

export const getNextEmptyPhotoLayerStartingAt = (
  currPage: string | null,
  pages: Array<Page>,
  partialSelection: 'left' | 'right' | null = null,
  layersWithPendingUploads: string[],
) => {
  const { init, rest } = span((page) => page.id !== currPage)(pages);
  if (partialSelection) {
    return getNextEmptyPhotoLayerBySide([...rest, ...init], partialSelection, layersWithPendingUploads);
  }
  return getNextEmptyPhotoLayer([...rest, ...init], layersWithPendingUploads);
};

// getNextEmptyPhotoLayer memoized
export const getNextEmptyPhotoLayerSelector = createSelector(
  [
    currentPages,
    layersWithPendingUploadsSelector,
  ],
  (pages: Pages[], layersWithPendingUploads: string[]) => getNextEmptyPhotoLayer(pages, layersWithPendingUploads),
);

/* Selects the next empty photo layer, either from anywhere in the project, or starting
   on the currently-selected page, depending on product category. */
export const nextAppropriateEmptyPhotoLayerSelector = createSelector(
  [
    currentPageIdSelector,
    currentPages,
    productCategorySelector,
    partialSelectionSelector,
    layersWithPendingUploadsSelector,
  ],
  (pageId: string | null, pages: Array<Page>, category: Category, partialSelection: 'left' | 'right' | null, layersWithPendingUploads: string[]) => {
    return canManipulatePages(category) ? getNextEmptyPhotoLayerStartingAt(pageId, pages, partialSelection, layersWithPendingUploads)
      : getNextEmptyPhotoLayer(pages, layersWithPendingUploads);
  }
);

// given state, returns a boolean as to whether the current page has an empty photo layer
export const currentPageHasEmptyPhotoLayerSelector = createSelector(
  [
    currentPageIdSelector,
    getNextEmptyPhotoLayerSelector,
  ],
  (currentPageId, nextEmptyPage) => has(nextEmptyPage)('pageId') && nextEmptyPage.pageId === currentPageId,
);

// Returns a memoized function that takes a pageId and returns that page.
export const givenPageSelector = createSelector(
  projectPagesSelector,
  pages => memoize(pageId => pages.find(page => page.id === pageId)),
);

export const getLayerFromPage = (pageId: string) => (layerId: string) => (state: Object) => (
  givenPageSelector(state)(pageId).layers.find(layer => layer.id === layerId)
);

export const getStyleRestrictionsFromLayer = (layer: Object) => (
  optionGet('defaultData.styleRestrictions')(layer).getOrElseValue({})
);

export const textEditorPageSelector = createSelector(
  [
    state => state.ui.textEditorPageId,
    projectPagesSelector,
  ],
  (pageId, pages) => (
    optionFind(page => page.id === pageId, pages)
      .getOrElseValue({})
  ),
);

export const textEditorLayerSelector = createSelector(
  [
    state => state.ui.textEditorLayerId,
    textEditorPageSelector,
  ],
  (layerId, page) => (
    option.fromNullable(page)
      .chain(prop('layers'))
      .chain(layers => optionFind(layer => layer.id === layerId, layers))
      .getOrElseValue({})
  ),
);

export const canAddPagesSelector = createSelector(
  [
    currentVisiblePageCountSelector,
    productCategorySelector,
    templatePagesMinMaxSelector,
  ],
  (currentVisiblePages, category, minMaxPages) => (
    (getPageCountMinusCover(category)(currentVisiblePages) + 2) <= minMaxPages.max
  )
);

/**
 * Derives the available design groups for the current page by
 * checking the intersection of designs in a group and designs
 * availble for the current page. Design IDs are replaced with
 * their corresponding design objects.
 *
 * Ex: A design group that uses design_1, design_2 and design_3
 * should only be useable of any of design_1, design_2 and design_3
 * are available on the current page
 */
export const currentDesignGroupsAvailableSelector = createSelector(
  [
    currentPageAvailableDesignsSelector,
    templateDesignGroups,
  ],
  (availableDesigns, designGroups) => (
    designGroups && designGroups.length && availableDesigns.length
      ? getDesignGroupsForPage(availableDesigns, designGroups)
      : []
  ),
);

// Selector that returns a boolean indicating whether or not the current page has any design groups.
export const currentPageHasDesignGroupsSelector = createSelector(
  currentDesignGroupsAvailableSelector,
  (currentDesignGroups: Array<DesignGroup>) => currentDesignGroups.length > 0,
);

// Selector that returns a boolean indicating whether or not the current page is an envelope.
export const currentPageIsEnvelopeSelector = createSelector(
  currentPageIdSelector,
  currentPageId => currentPageId && currentPageId.includes('envelope'),
);

export const currentPageIsMaskedSelector = createSelector(
  currentPageIdSelector,
  currentPageId => currentPageId && currentPageId.includes('mask'),
);

export const currentPageIsWoodenBoxSelector = createSelector(
  currentPageIdSelector,
  currentPageId => currentPageId && currentPageId === WOODEN_BOX_PAGE_ID
)

export const containingGroupedLayout = (state: Object) => (designId: string) => (
  currentDesignGroupsAvailableSelector(state).find(group => group.designs.find(d => d.id === designId) !== undefined)
);

export const currentPageNonGroupedDesignsSelector = createSelector(
  [
    currentPageAvailableDesignsSelector,
    currentDesignGroupsAvailableSelector,
  ],
  (designs, designGroups) => (
    designs.filter(({ id }) => (
      !flatMap(designGroups, dg => dg.designs.map(d => d.id)).includes(id)
    ))
  ),
);

export const emptyPhotoLayersInProjectSelector = createSelector(
  [
    currentPages,
    layersWithPendingUploadsSelector,
  ],
  (pages: Page[], layersWithPendingUploads: string[]) => pages.map((page) => getEmptyPhotoLayersOnPage(page, layersWithPendingUploads))
);

export const currentPageLayersWithColorSyncSelector = (pageId: string) => createSelector(
  state => projectPagesSelector(state).filter(x => x.id === pageId),
  ([page]) => page ? page.layers.filter(x => x.syncColor === true) : []
)

// CartValidation  helpers, mostly used in CartValidation component
export const getLayersWithEmptyText = createSelector(currentPages, (pages) =>
  pages.reduce((acc, page) => {
    const defaultTextLayers = page.layers
      .map((layer) => ({ ...layer, pageId: page.id }))
      .filter((layer) => layer.type === 'editable_text')
      .filter((layer) => layer.data.content === "")
    return [...acc, ...defaultTextLayers];
  }, [])
);

// This will return a list of any layers that have unchanged placeholder text, with added property pageId
export const getLayersWithDefaultText = createSelector(currentPages, (pages) =>
  pages.reduce((acc, page) => {
    const defaultTextLayers = page.layers
      .map((layer) => ({ ...layer, pageId: page.id }))
      .filter((layer) => layer.type === 'editable_text')
      .filter((layer) => !layer.allowDefault)
      .filter((layer) => layer.data.content === layer.defaultData.content)
    return [...acc, ...defaultTextLayers];
  }, [])
);

export const getLayersThatNeedAdminCheck = createSelector(currentPages, (pages) =>
  pages.reduce((acc, page) => {
    const adminCheckLayers = page.layers
      .map((layer) => ({ ...layer, pageId: page.id }))
      .filter((layer) => layer.type === 'user_photo')
      .filter((userPhotoLayer) => userPhotoLayer.data && userPhotoLayer.data.userPhotoId != null)
      .filter((layerWithPhoto) => !!layerWithPhoto.data.cropData.adminCheck);
    return [...acc, ...adminCheckLayers];
  }, [])
);
