// @flow

// import fonts
import 'typeface-crimson-text';
import 'typeface-lato';

import 'babel-polyfill';
import './css/index.css';

import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { option } from 'fp-ts';

import {
  isParameterPresent,
  getQueryParams,
  convertSpaces,
} from './helpers/urlParameters';
import { unregister } from './registerServiceWorker';
import has from './helpers/has';
import polyfills from './helpers/polyfills';
import { deref, optionGet } from './helpers/functions';
import storeCreator from './store/store';
import { formatProductDataByCategory } from './helpers/templates';
import { getProject } from './services/projectApi';
import { __ENV_PROD__ } from './helpers/constants';
import Loadable from 'react-loadable';
import analyticsInit from './store/analytics/itly/init';
import { getSplitUserKey, initSplitIO } from './services/splitio';
import { getCustomerAccountUrl, getFastlyBaseUrl } from './helpers/urls';

analyticsInit();

const compression = require('faunctions/lib/compression');

const { inflateTemplateData } = compression;

polyfills();

const LoadableApp = Loadable({
  loader: () => import('./components/App'),
  loading: () => null,
});

const provideMonetateData = (product) => {
  window.monetateQ = window.monetateQ || [];
  window.monetateQ.push([
    'setPageType',
    'editor',
  ]);
  window.monetateQ.push([
    'addCategories',
    [product.category],
  ]);
  window.monetateQ.push([
    'addProductDetails',
    [{ sku: product.sku }],
  ]);
};

// After the product data has been loaded, this will be called to mount the app.
const postLoad = (sku, queryParams, productData, qty) => {
  const store = storeCreator(
    sku.getOrElseValue('none'),
    queryParams,
    productData,
    qty.getOrElseValue(null)
  );


  const { product } = store.getState();
  provideMonetateData(product);

  const Editor = (
    <Provider store={store}>
      <LoadableApp />
    </Provider>
  );

  /**
   * Patch to redirect editor if no projectId exists
   * We will eventually remove this.
   */
  const isRequestValid = () => {
    if (isParameterPresent('projectId')) {
      return true;
    }
    if (isParameterPresent('sku')) {
      return true;
    }
    return false;
  };

  const noRedirect =
    has(queryParams)('noRedirect') && queryParams.noRedirect === 'true';

  if (!noRedirect) {
    if (!isRequestValid()) {
      window.location = getFastlyBaseUrl();
    } else {
      ReactDOM.render(Editor, document.getElementById('root'));
    }
  }

  /**
   * This adds a kill switch to our service worker so that we are able to enforce latest assets
   * if we find a problematic bug.
   */
  if (process.env.REACT_APP_SERVICE_WORKER_KILLSWITCH === 'true') {
    unregister();
  }
};

// Loads the productData object from the templates API.
// loadProductData :: (option String, option String, option String, option String, Object) -> Promise
const loadProductData = (
  sku,
  projectId,
  flashId,
  flashToken,
  queryParams,
  qty
) =>
  (sku.isSome()
    ? fetch(
      `${process.env.REACT_APP_TEMPLATE_API}/template?sku=${sku.getOrElseValue('')}`
    )
      .then((response) => response.json())
      .then((json) => {
        const inflated = inflateTemplateData(json.productData);
        const productJson = JSON.parse(inflated);
        const productData = formatProductDataByCategory(productJson);
        window.productData = productData;
        postLoad(sku, queryParams, productData, qty);
      })
      .catch((e) => {
        if (!__ENV_PROD__) {
          throw e;
        }
        window.newrelic.addPageAction('failed to load product data', {
          projectId: projectId.getOrElseValue('none'),
          userId: flashId.getOrElseValue('anonymous'),
          sku: sku.getOrElseValue('none'),
          error: e,
        });
      })
    : Promise.reject(new Error('No Sku'))
  ).catch((e) => {
    if (!__ENV_PROD__) {
      throw e;
    }
    window.newrelic.addPageAction('No SKU provided', {
      projectId: projectId.getOrElseValue('none'),
      userId: flashId.getOrElseValue('anonymous'),
      sku: sku.getOrElseValue('none'),
      error: e,
    });
  });

const queryParams = getQueryParams() || {};
const { projectId, flashId, flashToken, qty: qtyStr } = deref(
  'projectId',
  'flashId',
  'flashToken',
  'qty',
)(queryParams);

// A sinful hack to account for the fact that we currently don't know how to send users to the correct experience
// The spreadsheet that contains all of these lives here:
// https://docs.google.com/spreadsheets/d/1POeB1aogstWjBUhUoFcEni0mkXUGzMZ09L8w6h0HGHw/edit#gid=827091526
const SKUS_ON_V2 = [
  // This will eventually go to Editor-v2 but for now, we're keeping it in v1
  // 'baby-board-book',

  'elopement-layflat-new',
  'photo-strip-guest-book-new',
  'guestbook-new',
  'guest-book-new',
  'layflat-new',
  'wedding-layflat-photo-album-new',
  'signature-layflat-photo-album-new',
  'photo-wrapped-layflat',
  'hardcover-milestone-photo-book',
  'elopement-layflat',
  'photo-wrapped-hardcover',
  'hardcover-annual-photo-book',
  'hardcover-travel-photo-book',
  'hardcover-wedding-photo-book',
  'ornament-2022',
  'softcover',
  'hardcover',
  'photo-strip-guest-book',
  'color-series-photo-book',
  'instagramsoftcover',
  'everyday-photo-book',
  'guestbookset',
  'guestbook',
  'layflat',
  'mini-photo-book',
  'guest-book',
  'signature-layflat-photo-album',
  'wedding-layflat-photo-album',
  'scallop-tabletop-frame',
  'modern-wall-tile',
  'pressed-glass-frame',
  'instagramfriendlyframe',
  'framed-canvas',
  'tabletop-frames',
  'deepframe',
  'modernframe',
  'floatingframe',
  'galleryframe',
  'wedding-canvas-print',
  'wedding-guest-book-frame',
  'metal-tabletop-picture-frames',
  'ornament-add-on',
  'brassprints',
  'brass-wood-display-box',
  'PHP2',
  'BPR1',
  'CRP1',
  'WBR1',
  'WBP1',
  'modern-wall-tile-refill',
  'signatureprints',
  'square print sets',
  'brassprints-legacy20230824',
  'largeformatprints',
  'everyday print sets',
  'giclee-art-prints',
];

// Redirect only for NEW projects since we don't know how to translate v1 projects to v2 projects
if ('sku' in queryParams && SKUS_ON_V2.includes(queryParams.sku) && !('projectId' in queryParams)) {
  window.location.href = process.env.REACT_APP_EDITOR_V2_PUBLIC_URL + window.location.search;
}


const token = option.fromNullable(
  flashToken.getOrElseValue(queryParams.magentoToken)
);
const qty = qtyStr.map((str) => parseInt(str, 10));

const skuOption = option.fromNullable(
  queryParams.sku !== undefined ? convertSpaces(queryParams.sku) : undefined
);

// ====== Initialize Split
initSplitIO(getSplitUserKey(), '');

// If all the necessary properties are defined...
if ([projectId, flashId, token].every(option.isSome)) {
  getProject(
    projectId.getOrElseValue(''),
    flashId.getOrElseValue(''),
    token.getOrElseValue('')
  )
    .then((project) => {
      window.project = project;
      return JSON.parse(project.projectData);
    })
    .then((projectData) => {
      const projectSku = optionGet('data.product.sku')(projectData);

      // Load in the qty value from the project, as a back up if the qty isn't given in the URL params.
      const projectQty = optionGet('data.product.qty')(projectData);

      loadProductData(
        projectSku.alt(skuOption),
        projectId,
        flashId,
        token,
        queryParams,
        qty.alt(projectQty)
      );
    })
    .catch((e) => {
      window.newrelic.addPageAction('auFailedToInitializeProject', {
        flashId: flashId.getOrElseValue(''),
        projectId: projectId.getOrElseValue(''),
        error: e.message,
      });
      window.location = getCustomerAccountUrl();
    });
} else {
  loadProductData(skuOption, projectId, flashId, token, queryParams, qty);
}

