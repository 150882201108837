export const PROJECT_PAGES_APPLY_TEMPLATE = 'PROJECT/PAGES/APPLY_TEMPLATE';
export const PROJECT_PAGES_APPLY_PHOTO_TO_LAYER = 'PROJECT/PAGES/APPLY_PHOTO_TO_LAYER';
export const PROJECT_PAGES_APPLY_CROP_DATA_TO_LAYER = 'PROJECT/PAGES/APPLY/CROP_DATA_TO_LAYER';
export const PROJECT_PAGES_APPLY_PHOTO_MOD_TO_LAYER = 'PROJECT/PAGES/APPLY_PHOTO_MOD_TO_LAYER';
export const PROJECT_SAVE_REQUEST = 'PROJECT/SAVE/REQUEST';
export const PROJECT_SAVE_SUCCESS = 'PROJECT/SAVE/SUCCESS';
export const PROJECT_SAVE_FAILURE = 'PROJECT/SAVE/FAILURE';
export const PROJECT_PAGES_REMOVE_IMAGE = 'PROJECT/PAGES/REMOVE/IMAGE';
export const PROJECT_PAGES_REMOVE_PAGES = 'PROJECT/PAGES/REMOVE/PAGES';
export const PROJECT_PAGES_APPLY_DESIGN_TO_PAGE = 'PROJECT/PAGES/APPLY/DESIGN_TO_PAGE';
export const PROJECT_PAGES_APPLY_PAGE_COUNT = 'PROJECT/PAGES/APPLY/PAGE_COUNT';
export const PROJECT_PAGES_INSERT_PAGES = 'PROJECT/PAGES/INSERT/PAGES';
export const SET_LAYER_DATA = 'SET_LAYER_DATA';
export const SET_DATA_ON_LAYERS = 'SET_DATA_ON_LAYERS';
export const SET_LAYER_REGION_DATA = 'SET_LAYER_REGION_DATA';
export const SET_LAYER_REGION_DATA_ALL = 'SET_LAYER_REGION_DATA_ALL';
export const PROJECT_PAGES_REMOVE_USERPHOTOS = 'PROJECT_PAGES_REMOVE_USERPHOTOS';
export const SET_IMAGE_META_TO_LAYER = 'SET_IMAGE_META_TO_LAYER';
export const SET_LAYER_DIMS = 'SET_LAYER_DIMS';
export const PROJECT_PAGES_ORDER = 'PROJECT/PAGES/ORDER';
export const USER_PHOTOS_REMOVE_ALL = 'USER_PHOTOS_REMOVE_ALL';
export const SET_LAYER_AUTO_ENHANCE = 'SET_LAYER_AUTO_ENHANCE';
export const SET_LAYER_IMAGE = 'PROJECT/PAGES/LAYERS/SET_LAYER_IMAGE'
export const defaultPages = [];
