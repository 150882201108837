import { INITIAL, IN_PROGRESS, SUCCESS, FAILURE } from './constants';
import { PROJECT_SAVE_REQUEST, PROJECT_SAVE_SUCCESS, PROJECT_SAVE_FAILURE } from '../pages/constants';
import { FINAL_SAVE_ADD_TO_CART_SUCCESS, FINAL_SAVE_ADD_TO_CART_FAILURE } from '../constants';

export const INITIAL_STATE = {
  status: INITIAL,
  lastSave: 0,
};

export default function saveStateReducer (state = INITIAL_STATE, action) {
  switch(action.type) {
    case PROJECT_SAVE_REQUEST: {
      return { ...state, status: IN_PROGRESS };
    }

    case PROJECT_SAVE_SUCCESS: {
      return { status: SUCCESS, lastSave: action.payload.modifiedAt };
    }

    case PROJECT_SAVE_FAILURE: {
      return { ...state, status: FAILURE };
    }

    case FINAL_SAVE_ADD_TO_CART_FAILURE: {
      return {
        ...state,
        status: FAILURE,
      };
    }

    case FINAL_SAVE_ADD_TO_CART_SUCCESS: {
      return { status: SUCCESS, lastSave: action.payload.modifiedAt };
    }

    case 'redux-async-initial-state/STATE_LOADING_DONE': {
      return INITIAL_STATE;
    }

    default: {
      return state;
    }
  }
};
