import {
  SCHEMA_ERROR_EMPTY_PAGE,
  SCHEMA_ERROR_MISSING_MEDIA_ID,
  SCHEMA_ERROR_DEFAULT_TEXT,
  SCHEMA_ERROR_EMPTY_TEXT,
  SCHEMA_ERROR_MISSING_DATA,
} from './errors';

export const designAssetLayerSchema = {
  id: 'designAssetLayer',
  type: 'object',

  properties: {
    name: { type: 'string' },
    type: { enum: ['design_asset'] },
    placeholder: { type: 'boolean' },
    x: { type: 'number' },
    y: { type: 'number' },
    width: { type: 'number' },
    height: { type: 'number' },
    image: { type: 'string' },
  },

  required: ['image'],
};

export const editableTextStyleSchema = {
  id: '/editableTextStyle',
  type: 'object',

  properties: {
    fontFamily: { type: 'string' },
    textAlign: { type: 'string' },
    color: { type: 'string' },
    fontSize: { type: 'string' },
  },

  required: ['fontFamily', 'textAlign', 'color', 'fontSize'],
};

export const editableTextLayerSchema = {
  id: 'editableTextLayer',
  type: 'object',
  properties: {
    name: { type: 'string' },
    type: { enum: ['editable_text'] },
    x: { type: 'number' },
    y: { type: 'number' },
    width: { type: 'number' },
    height: { type: 'number' },
    allowDefault: {
      type: 'boolean',
      default: false,
    },
    rotate: { type: 'number' },

    data: {
      type: 'object',

      properties: {
        style: { $ref: '/editableTextStyle' },
        content: {
          type: 'string',
          // eslint-disable-next-line
          not: { enum: [''] },
          anyOf: [
            { not: { const: { $data: '2/defaultData/content' } } },
            { isTrue: { $data: '2/allowDefault' } },
          ],
          errorMessage: {
            anyOf: SCHEMA_ERROR_DEFAULT_TEXT,
            not: SCHEMA_ERROR_EMPTY_TEXT,
          },
        },
        renderedContent: { type: 'string' },
      },

      required: ['style', 'content', 'renderedContent'],
    },

    defaultData: {
      type: 'object',

      properties: {
        style: { $ref: '/editableTextStyle' },
        content: { type: 'string' },
      },

      required: ['style', 'content'],
    },
  },

  required: ['data'],
  errorMessage: {
    required: {
      data: SCHEMA_ERROR_MISSING_DATA,
    },
  },
};

export const photoDataSchema = {
  id: '/photoData',
  type: 'object',
  properties: {
    userPhotoId: {
      oneOf: [
        { type: 'string' },
        { type: 'integer' },
      ],
    },
  },
  required: ['userPhotoId'],
  errorMessage: {
    required: {
      userPhotoId: SCHEMA_ERROR_EMPTY_PAGE,
    },
    properties: {
      userPhotoId: SCHEMA_ERROR_EMPTY_PAGE,
    },
  },
};

export const photoLayerSchema = {
  id: 'photoLayer',
  type: 'object',
  properties: {
    name: { type: 'string' },
    type: { enum: ['user_photo'] },
    x: { type: 'number' },
    y: { type: 'number' },
    width: { type: 'number' },
    height: { type: 'number' },
    data: { $ref: '/photoData' },
  },
  required: ['data'],
  errorMessage: {
    required: {
      data: SCHEMA_ERROR_EMPTY_PAGE,
    },
  },
};

export const userPhotoSchema = {
  id: 'userPhoto',
  type: 'object',
  properties: {
    id: {
      oneOf: [
        { type: 'string' },
        { type: 'integer' },
      ],
    },
    cdnUrl: { type: 'string' },
    mediaId: { type: 'string' },
  },
  required: ['id', 'mediaId'],
  errorMessage: {
    properties: {
      mediaId: SCHEMA_ERROR_MISSING_MEDIA_ID,
    },
    required: {
      mediaId: SCHEMA_ERROR_MISSING_MEDIA_ID,
    },
  },
};

export const userPhotosSchema = {
  id: 'userPhotos',
  type: 'array',
  items: { $ref: 'userPhoto' },
};

export const pageSchema = {
  id: 'projectPage',
  type: 'object',
  properties: {
    id: { type: 'string' },
    surface: { type: 'object' },
    layers: {
      type: 'array',
      items: {
        anyOf: [
          { $ref: 'photoLayer' },
          { $ref: 'editableTextLayer' },
          { $ref: 'designAssetLayer' },
        ],
      },
    },
  },
};

export const singlePhotoProductSchema = {
  id: 'singlePhotoProduct',
  type: 'array',
  items: { $ref: 'projectPage' },
  minItems: 1,
  maxItems: 1,
};

export const project = {
  id: 'project',
  type: 'object',
  properties: {
    pages: { $ref: 'singlePhotoProduct' },
  },
  required: ['pages'],
};

export const singlePhotoProjectSchema = {
  id: 'singlePhotoProject',
  type: 'object',
  properties: {
    project: {
      $ref: 'project',
    },
    userPhotos: {
      $ref: 'userPhotos',
    },
  },
  required: ['project', 'userPhotos'],
};
