export const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE';
export const REMOVE_ATTRIBUTE = 'REMOVE_ATTRIBUTE';
export const UPDATE_MAGENTO_DATA = 'UPDATE_MAGENTO_DATA';
export const PRODUCT_UPDATE_PAGE_COUNT = 'PRODUCT_UPDATE_PAGE_COUNT';
export const UPDATE_QTY = 'PRODUCT_UPDATE_QTY';

export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';
export const ADD_TO_CART_FAILURE_MISMATCHED_USER = 'ADD_TO_CART_FAILURE_MISMATCHED_USER';

export const UPDATE_PRODUCT_CHILD_REQUEST_STATUS = 'UPDATE_PRODUCT_CHILD_REQUEST_STATUS';
export const CHILD_REQUEST_STATUS_IN_FLIGHT = 'CHILD_REQUEST_STATUS_IN_FLIGHT';
export const CHILD_REQUEST_STATUS_SUCCESS = 'CHILD_REQUEST_STATUS_SUCCESS';
export const CHILD_REQUEST_STATUS_FAILURE = 'CHILD_REQUEST_STATUS_FAILURE';

export const ADD_TO_CART_PROCESSING = 'ADD_TO_CART_PROCESSING';
export const PROCESSING_CART_STATE = 'PROCESSING_CART_STATE';
export const CONFIRMING_EMPTY_CART_STATE = 'CONFIRMING_EMPTY_CART_STATE';
export const CONFIRMING_DEFAULT_TEXT_CART_STATE = 'CONFIRMING_DEFAULT_TEXT_CART_STATE';
export const ADD_TO_CART_AND_OPEN_NEW_DEFAULT_PROJECT_CART_STATE = 'ADD_TO_CART_AND_OPEN_NEW_DEFAULT_PROJECT_CART_STATE';
export const CONFIRMING_EMPTY_TEXT_CART_STATE = 'CONFIRMING_EMPTY_TEXT_CART_STATE';
export const CONFIRMING_UNUSED_FAILED_PHOTOS_CART_STATE = 'CONFIRMING_UNUSED_FAILED_PHOTOS_CART_STATE';
export const UPLOADS_IN_FLIGHT_CART_STATE = 'UPLOADS_IN_FLIGHT_CART_STATE';
export const CHILD_PRODUCT_REQUESTING_CART_STATE = 'CHILD_PRODUCT_REQUESTING_CART_STATE';
export const DO_NOT_ADD_TO_CART = '';
export const FINAL_SAVE_IN_PROGRESS_CART_STATE = 'FINAL_SAVE_IN_PROGRESS_CART_STATE';
export const FINAL_SAVE_FAILED_CART_STATE = 'FINAL_SAVE_FAILED_CART_STATE';
export const ADD_TO_CART_FAILURE_OUT_OF_STOCK = 'ADD_TO_CART_FAILURE_OUT_OF_STOCK';
export const ADD_TO_CART_MAX_LIMIT_REACHED = 'ADD_TO_CART_MAX_LIMIT_REACHED';
export const ADD_TO_CART_FAILURE_USED_PHOTO_FAILURES = 'ADD_TO_CART_FAILURE_USED_PHOTO_FAILURES';
export const CONFIRMING_MISSING_ADDRESSING = 'CONFIRMING_MISSING_ADDRESSING';
export const CONFIRMING_MISSING_MANUAL_ADDRESS = 'CONFIRMING_MISSING_MANUAL_ADDRESS';
export const ADD_TO_CART_ADDED_TO_CART = 'ADD_TO_CART_ADDED_TO_CART';
export const UPDATE_SHOULD_ADD_TO_CART_AND_START_NEW_PROJECT = 'UPDATE_SHOULD_ADD_TO_CART_AND_START_NEW_PROJECT';

export const PROJECT_MISSING_PHOTOS = 'PROJECT_MISSING_PHOTOS';
export const UPLOADS_IN_FLIGHT = 'UPLOADS_IN_FLIGHT';
export const CUSTOMER_ACK_EMPTY_PAGE = 'CUSTOMER_ACK_EMPTY_PAGE';

export const EMPTY_PAGES_VALIDATION = 'EMPTY_PAGES_VALIDATION';
export const EMPTY_TEXT_VALIDATION = 'EMPTY_TEXT_VALIDATION';
export const DEFAULT_TEXT_LAYERS_VALIDATION = 'DEFAULT_TEXT_LAYERS_VALIDATION';
export const FAILED_PROJECT_SERVICE_SAVE_VALIDATION = 'FAILED_PROJECT_SERVICE_SAVE_VALIDATION';
export const UNUSED_FAILED_PHOTOS_VALIDATION = 'UNUSED_FAILED_PHOTOS_VALIDATION';

export const CART_FAILURE_STATES = [
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_FAILURE_USED_PHOTO_FAILURES,
  ADD_TO_CART_FAILURE_OUT_OF_STOCK,
  ADD_TO_CART_FAILURE_MISMATCHED_USER,
  FINAL_SAVE_FAILED_CART_STATE,
];
