// @flow

import React, { type Node as ReactNode } from 'react';
import { connect } from 'react-redux';
import { stoppedHandler } from '../../../../helpers/stopEvent';
import has from '../../../../helpers/has';
import Show from '../../../Functional/Show';
import { canManipulatePages } from '../../../../helpers/product';
import { type Category } from '../../../../types/templates';
import { nextAppropriateEmptyPhotoLayerSelector, currentPageHasEmptyPhotoLayerSelector } from '../../../../store/project/pages/selectors';
import { changePageAndAddPhoto as _changePageAndAddPhoto } from '../../../../store/userAlbums/actions';
import { undoableUserAction } from '../../../../store/history/actions';
import { UNDOABLE_ADD_IMAGE_TO_LAYER } from '../../../../store/history/constants';
import { buildTargetedActionTypeFromParts } from '../../../../store/v2/galleries/actions';
import * as actionTypes from '../../../../store/v2/galleries/actionTypes';

type PhotoActionsProps = {
  albumPhoto: Object,
  nextAvailablePhotoLayer: { pageId?: string, layerId?: string },
  emptyPhotoOnCurrentPage: boolean,
  addPhotoToNextAvailable: Object => void,
  replaceFirstPhotoOnPage: Object => void,
  category: Category,
};

export const PhotoActions = ({
  albumPhoto,
  nextAvailablePhotoLayer,
  emptyPhotoOnCurrentPage,
  addPhotoToNextAvailable,
  replaceFirstPhotoOnPage,
  category,
}: PhotoActionsProps) => (
  <div className="align-flex-center--rows fill-parent">
    <Show when={has(nextAvailablePhotoLayer)('pageId')}>
      <PhotoActionButton onClick={() => addPhotoToNextAvailable(albumPhoto)}>
        Add
      </PhotoActionButton>
    </Show>

    <Show when={!emptyPhotoOnCurrentPage && !canManipulatePages(category)}>
      <PhotoActionButton
        onClick={() => replaceFirstPhotoOnPage(albumPhoto)}
      >
        Replace
      </PhotoActionButton>
    </Show>
  </div>
);

/**
 * dumb button wrapper with styling for button actions that display on photo item hover
 * using a <div> instead of <button> due to a FF bug with dragon drop
 * https://bugzilla.mozilla.org/show_bug.cgi?id=568313
 */
export const PhotoActionButton = ({ onClick, children }: { onClick: Event => void, children: ReactNode }) => (
  <div
    role="button"
    tabIndex={0}
    onClick={stoppedHandler(onClick)}
    className="btn btn--ghost-white btn--inline btn--stack"
  >
    {children}
  </div>
);


const mapStateToProps = (state) => ({
  category: state.product.category,
  nextAvailablePhotoLayer: nextAppropriateEmptyPhotoLayerSelector(state),
  emptyPhotoOnCurrentPage: currentPageHasEmptyPhotoLayerSelector(state),
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setPendingAction: (photo, nextAvailable) => {
    if (!photo.autoOriented) {
      const imgId = photo.id;
      let actionType;
      // if photo has a created by then we know it's been uploaded
      if (photo.createdBy != null) {
        actionType = buildTargetedActionTypeFromParts(actionTypes.AUTO_ORIENT_IMAGE_IN_GALLERY, imgId, nextAvailable.pageId, nextAvailable.layerId);
      } else {
        actionType = buildTargetedActionTypeFromParts(actionTypes.UPLOAD_IMAGES_TO_GALLERY, imgId, nextAvailable.pageId, nextAvailable.layerId);
      }
      dispatch({ type: actionType });
    }
  },
  changePageAndAddPhoto: (photo, nextAvailable) => dispatch(undoableUserAction(UNDOABLE_ADD_IMAGE_TO_LAYER, nextAvailable.pageId)(() => _changePageAndAddPhoto(photo, nextAvailable, true))),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  addPhotoToNextAvailable: (albumPhoto: Object) => {
    dispatchProps.setPendingAction(albumPhoto, stateProps.nextAvailablePhotoLayer);
    dispatchProps.changePageAndAddPhoto(albumPhoto, stateProps.nextAvailablePhotoLayer);
  },
  // Replace action is wrapped by "undoableUserAction" in PhotoElements.js
  replaceFirstPhotoOnPage: (albumPhoto: Object) => {
    dispatchProps.setPendingAction(albumPhoto, stateProps.nextAvailablePhotoLayer);
    ownProps.selectPhoto(albumPhoto, stateProps.nextAvailablePhotoLayer.layerId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PhotoActions);
