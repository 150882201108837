import loadScript from 'load-script';
import {
  SET_DROPBOX_PHOTOS,
  SET_DROPBOX_ACTIVE,
  SET_DROPBOX_MAIN_ALBUM,
  UI_DROPBOX_ALBUM,
} from './constants';
import { hideUploadPhotosModal, showDropboxAlbum } from '../ui/actions';

const isDropboxReady = () => !!window.Dropbox;

const setDropboxPhotos = photos => ({
  type: SET_DROPBOX_PHOTOS,
  payload: photos,
});

export const setDropboxActive = value => ({ type: SET_DROPBOX_ACTIVE, payload: value });

const setMainDropboxAlbum = profilePic => (dispatch) => {
  const igAlbum = { albumId: UI_DROPBOX_ALBUM, thumbURL: profilePic };
  dispatch({ type: SET_DROPBOX_MAIN_ALBUM, payload: igAlbum });
};

const buildDropboxAlbum = dropboxObjects => (dispatch, getState) => {
  const currentDBImages = getState().dropbox.dropboxImgArray;
  const formattedDBphotos = dropboxObjects.map(image => ({
    source: 'dropbox',
    isLoaded: true,
    mediaId: '',
    id: image.id,
    thumbURL: (image.thumbnailLink).replace('bounding_box=75', 'bounding_box=1280'),
    cdnUrl: image.link,
  }));
  const allDBImages = [...formattedDBphotos, ...currentDBImages];
  dispatch(setDropboxPhotos(allDBImages));
  dispatch(setMainDropboxAlbum(allDBImages[0].thumbURL));
  dispatch(showDropboxAlbum());
  dispatch(hideUploadPhotosModal());
};

export const loadDropbox = () => () => {
  const DROPBOX_SDK_URL = 'https://www.dropbox.com/static/api/2/dropins.js';
  const SCRIPT_ID = 'dropboxjs';
  if (!isDropboxReady()) {
    loadScript(DROPBOX_SDK_URL, {
      attrs: {
        id: SCRIPT_ID,
        'data-app-key': process.env.REACT_APP_DROPBOX_APP_KEY,
      },
    });
  }
};

export const openDropboxChooser = () => (dispatch) => {
  if (!isDropboxReady()) {
    return null;
  }

  window.Dropbox.choose({
    cancel: () => {},
    multiselect: true,
    disabled: false,
    linkType: 'direct',
    extensions: ['.jpg', '.png', '.jpeg'],
    success: files => dispatch(buildDropboxAlbum(files)),
  });
};
