// @flow

import match from '../match';
import { inRange } from '../numbers';
import { fillRange } from '../arrays';

// Type
export type RangeSpecifier = string | Array<string | number>;

// Constants
const ALL = 'all';
const NONE = 'none';
const FIRST = 'first';
const LAST = 'last';

/*
  Given a range string of the format described in the comment for the main 'contained' function, a minimum,
  and a maximum, this function will return a function, taking an index as its argument, which will itself
  return true or false to indicate whether or not the provided index is contained by the range specified
  by the range string.
*/
export const indexInRange = (rangeStr: string | number, min: number, max: number) => (index: number) => {
  if (typeof rangeStr === 'string') {
    const splitRange = rangeStr.split('...');

    if (splitRange.length === 2) {
      const range: Array<number> = splitRange.map(n => (
        match(
          FIRST, () => min,
          LAST, () => max,
          match.default, () => parseFloat(n),
        )(n)
      ));

      return inRange(...range)(index);
    } else if (splitRange.length === 1) {
      return parseFloat(splitRange[0]) === index;
    }
  }

  return false;
};

/*
  Given a range specifier (array or string), a min value, and a max value, this function will return
  a function, taking an index as its argument, which will itself return true or false to indicate whether
  or not the given index is inclusively contained by the range provided for by the range specifier.

  The range specifier format is as follows:
    - Can be a string or an array.
    - If a string, the specifier can be one of the following:
      - 'all': allows any index. must be the only substring.
      - 'none': allows no index. must be the only substring.
      - 'first': refers to the first index in the range (i.e. the value of the 'min' argument).
      - 'last': refers to the last index in the range (i.e. the value of the 'max' argument).
      - An inclusive range between any two numbers, in the form of '<min>...<max>'.
        - <min> and <max> can also be 'first' and 'last', respectively.
      - A single stringified number

    - If an array, the specifier can be comprised of any mix of the following:
      - An allowable number.
      - A string of the same format as that described above.
*/
const contained = (specifier: RangeSpecifier, min: number, max: number) => (index: number): boolean =>
  match(
    ALL, () => true,
    NONE, () => false,
    FIRST, () => (index === min),
    LAST, () => (index === max),
    match.default, () => {
      if (Array.isArray(specifier)) {
        return (
          (specifier.includes(FIRST) && index === min) ||
          (specifier.includes(LAST) && index === max) ||
          (specifier.includes(index)) ||
          (specifier.findIndex(item => indexInRange(item, min, max)(index)) > -1)
        );
      } else if (typeof specifier === 'string') {
        // This is some straight up horky borky but I spent 2 hours trying to make everything consistent with indexes/numbers and
        // almost lost my sanity along the way. If you are reading this message and the year is no longer 2021, that means that
        // we were unable to sunset this godforsaken codebase and we are still carrying this garbage pile into the future. I am sorry.
        // - Nick Graziano
        if ((index - 1) === max) {
          return indexInRange(specifier, min, max)(index - 1);
        }
        return indexInRange(specifier, min, max)(index);
      }

      return false;
    },
  )(specifier);

/* Given a range specifier, a min, and a max, this function will return the array of all numbers,
   with a specified scale, that are contained within the given range. */
export const arrayFromRange = (specifier: RangeSpecifier, min: number, max: number, scale: number = 0): Array<number> => (
  fillRange(scale)(min)(max).filter(contained(specifier, min, max))
);

/* Given a range specifier, a min, a max, and an index, this function will return the whole
   number in the specified range that is closest to the index. */
export const clampToRange = (specifier: RangeSpecifier, min: number, max: number) => (index: number): number => (
  !contained(specifier, min, max)(index) ? (
    arrayFromRange(specifier, min, max)
      .reduce(([value: number, distance: number], n) => {
        const newDistance = Math.abs(n - index);

        if (newDistance < distance) {
          return [n, newDistance];
        }

        return [value, distance];
      }, [-1, Infinity])[0]
  ) : index
);

export default contained;
