// @flow

import isEqual from 'lodash.isequal';

// String constant for default case.
const DEFAULT: string = 'default';

// Checks that every even-indexed element is a function.
const casesValid = (cases: Array<any | Function>): boolean => (
  cases.every((element, index) => (
    (index % 2 === 0) || (typeof element === 'function')
  ))
);

// Returns the index of the first case that matches the expression. Otherwise returns -1.
const findMatchIndex = (expr: any, cases: Array<any | Function>): number => (
  cases.findIndex((element, index) => (
    (index % 2 === 0) && isEqual(expr, element)
  ))
);

/*
  A mostly-pure match function, inspired by Rust's match statement.
  Cases of any type can be used.

  Example usage:

    match(
      'hello', () => 'howdy partner',
      'goodbye', () => 'see you around',
      match.default, () => 'still here?',
    )('goodbye');

  That would evaluate to 'see you around'.
*/
const match = (...cases: Array<any | Function>) => (expr: any): any => {
  if (cases.length % 2) {
    // Throw error if cases array isn't even
    throw new Error('Match: Passed array contains an uneven number of elements.');
  } else if (!cases.includes(DEFAULT)) {
    // Throw error if cases array doesn't include a default case
    throw new Error('Match: Passed array does not contain a default case.');
  } else if (!casesValid(cases)) {
    // Throw error if cases array is otherwise invalid
    throw new Error('Match: Passed array is invalid.');
  } else {
    const matchedIndex = findMatchIndex(expr, cases);

    return (
      matchedIndex === -1 ? (
        (cases[findMatchIndex(DEFAULT, cases) + 1]: () => any)()
      ) : (
        (cases[matchedIndex + 1]: () => any)()
      )
    );
  }
};

match.default = DEFAULT;

export default match;
