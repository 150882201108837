import Itly from '../../../itly';

const { REACT_APP_ITLY_ENABLED, REACT_APP_ENV } = process.env;

const ITLY_DISABLED = REACT_APP_ITLY_ENABLED === 'false'
const ITLY_ENV = REACT_APP_ENV === 'prod' ? 'production' : 'development'

export default function analyticsInit() {
  Itly.init({
    context: { platform: 'editor' },
    disabled: ITLY_DISABLED,
    environment: ITLY_ENV,
    destinations: {
      segment: {
        config: { flushAt: 1, flushInterval: 1 },
      },
    },
    logger: {
      debug: message => console.log(message),
      info: message => console.log(message),
      warn: message => console.log(message),
      error: message => console.error(message),
    },
  });
}
