// @flow

import find from 'lodash.find';
import uuid4 from 'uuid/v4';
import { option } from 'fp-ts';
import { not } from 'fp-ts/lib/function';

import {
  isParameterPresent,
  formatAttributes,
  getDefaultAttributes,
  disableAttributesIfRestricted,
} from '../helpers/urlParameters';
import { getTemplate, getPageNameForCategory } from '../helpers/templates';

import {
  updateLayers,
  defaultDesign,
  designAttributeNameByCategory,
  layersInDesign,
  isUserPhotoLayer,
  allDefaultDesigns,
} from '../helpers/layers';
import has from '../helpers/has';
import {
  getDesignsForPage,
  getSurfaceForDesign,
  generatePageId,
  getPageCountWithCover,
  getPageCountMinusCover,
  getDefaultPageCountWithCover,
  designIsPartial,
  createCompositeDesign,
  modifyInitialPagesByCategory, getStartingPagesValue,
} from '../helpers/pages';
import { INITIAL_STATE } from './project/saveState/reducer';
import { UNTITLED_PROJECT } from '../constants/project';
import { getRandomElement } from '../helpers/arrays';
import {
  shouldHaveRandomDesigns,
  shouldHaveCover,
  shouldEnableHalfPageSelection,
  hasEnvelopeAddressing,
  shouldHaveWoodenBox,
} from '../helpers/product';
import { defaultUi } from './ui/reducer';
import { LAYOUT_FILTER_DEFAULT_OPTION } from './ui/constants';
import type { Surface, Layer, Design } from '../types/templates';
import { Template } from 'au-js-sdk/lib/models/Template';
import { optionFromEmpty } from '../helpers/functions';
import { defaultZendeskState } from './zendesk/reducer';
import { validateProductAttributesByRestrictions } from '../helpers/attributes';
import { initialState as initialGalleriesState } from './v2/galleries/reducer';

// Returns whether or not the current template has a design that includes a user photo layer.
// Replicating functionality from templates/selectors to avoid circular dependency weirdness.
const someDesignHasPhoto = (designs: Array<Design>, layers: Array<Layer>) =>
  designs.some(design => layersInDesign(design, layers).some(isUserPhotoLayer));

/**
 * getPageLayersFromTemplate creates an array of layers using a template's default design.
 * @param  {array} templateLayers   array of layers in a template
 * @param  {object} design    an arrangement of layers on a surface
 * @return {array} array of layers
 */
export function getPageLayersFromTemplate(
  templateLayers: Array<Layer>,
  design: Design,
  pageNum: number,
  surface: Surface,
  attrs: { [string]: string } = {},
  fontsLoaded: boolean = false
) {
  const layers = design.layers.map(designLayer =>
    find(templateLayers, layer => designLayer === layer.id)
  );

  return updateLayers(
    layers,
    [],
    pageNum,
    null,
    surface,
    fontsLoaded,
    attrs,
    []
  );
}

// Retrieves a design from the given designsForPage in the manner that the product category calls for.
const pickDesign = (category: string) => (pageNum: number) => (
  designsForPage: Array<Design>
) => {
  const hasOrderedDesigns = designsForPage.find(design => design.order);
  if (hasOrderedDesigns) {
    const orderedDesign = designsForPage.filter(design => design.order.includes(pageNum - 1));
    return orderedDesign.length > 0 ? orderedDesign[0] : defaultDesign(designsForPage);
  }

  if (
    shouldHaveRandomDesigns(category) &&
    ((shouldHaveCover(category) && pageNum !== 1) || !shouldHaveCover(category))
  ) {
    const usableDesigns = optionFromEmpty(
      allDefaultDesigns(designsForPage)
    ).getOrElseValue(designsForPage);

    // If this category supports partial pages...
    if (shouldEnableHalfPageSelection(category)) {
      // If there is only one design, only use that design for every page
      if (usableDesigns.length === 1) {
        return usableDesigns[0];
      }

      /* Make every third page a random non-composite design, and the rest random composite designs
         (i.e. comprised of two partial designs, if there are any) */
      return (pageNum + 1) % 3 === 0
        ? getRandomElement(usableDesigns.filter(not(designIsPartial)))
        : getRandomElement(
          optionFromEmpty(
            usableDesigns.filter(designIsPartial)
          ).getOrElseValue(usableDesigns)
        );
    }

    // Otherwise, get any random design
    return getRandomElement(usableDesigns);
  }
  // Get the default design
  return defaultDesign(designsForPage);
};

/**
 * generatePages returns an array of pages (length specified by templates min pages) that utilize a template's surface and default design.
 * @param  {object} template a template object (see src/data/product/floatframe/templates for examples)
 * @return {array}          An array of page objects (see state.project.pages for example)
 */
export function generatePages(
  template: Template,
  category: string,
  attrs: { [string]: string } = {},
  desiredPageCountWithCover: number,
  fontsLoaded: boolean = false
) {
  let addedPages = 0;
  const pages = [];

  const pageCountWithoutCover = getPageCountMinusCover(category)(desiredPageCountWithCover);
  const pageCountWithCover = getPageCountWithCover(template)(category)(desiredPageCountWithCover);

  while (addedPages < pageCountWithCover) {
    const pageNum = addedPages;
    addedPages += 1;
    const designsForPage = getDesignsForPage(
      template.designs,
      pageCountWithCover,
      addedPages,
      pageCountWithoutCover,
    );

    // If the current product category should have random designs, get a random design. Otherwise, get the default design.
    const design = pickDesign(category)(addedPages)(designsForPage);

    const surface = getSurfaceForDesign(design, template);

    // Get a composite design and the accompanying additionalLayers, if the selected design is a partial design.
    const [finalDesign, additionalLayers] = option
      .fromPredicate(designIsPartial)(design)
      // eslint-disable-next-line
      .map(() => pickDesign(category)(addedPages)(designsForPage)) // (create-react-app eslint config thinks this is a nested loop)
      .map(rightDesign =>
        createCompositeDesign(template.layers, surface)(design)(rightDesign)
      )
      .getOrElseValue([design, []]);

    pages.push({
      id: generatePageId(addedPages, category),
      uuid: uuid4(),
      name: getPageNameForCategory(category)(pageNum, attrs),
      layers: getPageLayersFromTemplate(
        template.layers.concat(additionalLayers),
        finalDesign,
        pageNum,
        surface,
        attrs,
        fontsLoaded
      ),
      surface: {
        ...surface,
        design: finalDesign,
      },
    });
  }

  if (template.staticPages) {
    pages.push(
      ...template.staticPages.map(x => ({
        ...x,
        static: true,
        uuid: uuid4(),
        name: x.id,
      }))
    );
  }

  return pages;
}

export const createDefaultState = (
  sku: string,
  queryParams: Object,
  productData: Object,
  qty: ?number = 1
) => {
  /**
   * Get attributes object from query parameters
   * @type object
   */
  const attrs =
    queryParams !== undefined && queryParams.attributes !== undefined
      ? {
        ...disableAttributesIfRestricted(
          getDefaultAttributes(productData.attributes)(productData.category)
        ),
        ...queryParams.attributes,
      }
      : // If no attributes are present, default to the first option for each attribute, skipping 'passThrough' attributes.
      disableAttributesIfRestricted(
        getDefaultAttributes(productData.attributes)(productData.category)
      );

  /**
   * Get project id from query parameters
   * @type string
   */
  const projectId =
    queryParams !== undefined && queryParams.projectId !== undefined
      ? queryParams.projectId
      : `anon-${uuid4()}`;

  const formattedAttrs = formatAttributes(attrs, productData.attributes);

  const product = {
    sku,
    attributes: formattedAttrs,
  };

  /**
   * Get flash user and token from query params
   */
  const flashUser =
    queryParams !== undefined && queryParams.flashId !== undefined
      ? queryParams.flashId
      : null;

  let flashToken =
    queryParams !== undefined && queryParams.flashToken !== undefined
      ? queryParams.flashToken
      : null;

  let userIsAdmin = false;

  if (
    flashToken === null &&
    (queryParams !== undefined && queryParams.magentoToken)
  ) {
    flashToken = queryParams.magentoToken;
    userIsAdmin = true;
  }

  const anonymous = !isParameterPresent('projectId');

  const template = getTemplate(productData)(product.attributes);

  if (template && has(template)('pages')) {
    const productName = productData.name;
    const pageCount = attrs.pages
      ? parseInt(attrs.pages, 10) +
        (shouldHaveCover(productData.category) ? 1 : 0)
      : getDefaultPageCountWithCover(productData.category, productData)(
        template,
        attrs,
        qty
      );

    const pages = generatePages(
      template,
      productData.category,
      attrs,
      pageCount,
      false
    );

    const validatedAttributes = validateProductAttributesByRestrictions(attrs, productData.attributeRestrictions);

    const defaultState = {
      product: {
        ...product,
        name: productName,
        // Add the design attribute to the attributes object, if the template says it's needed.
        attributes: {
          ...validatedAttributes,
          ...(template.needsDesignAttribute
            ? {
              design: designAttributeNameByCategory(productData.category)(
                pages[0].surface.design
              ),
            }
            : {}),
          ...getStartingPagesValue(template, attrs),
        },
        cartState: '',
        skippedChecks: [],
        category: productData.category,
        priceTiers: [],
        qty,
      },
      template,
      project: {
        pages: modifyInitialPagesByCategory(
          pages,
          productData.category,
          template,
          product.attributes,
          shouldHaveWoodenBox(productData.category, attrs)
        ),
        id: projectId,
        name: UNTITLED_PROJECT,
        saveState: INITIAL_STATE,
      },
      ui: {
        ...defaultUi,
        currentPage: pages[0].id,
        surfaceCenterPoint: null,
        message: null,
        menuVisible: false,
        messageBannerVisible: false,
        sidebarExpanded: false,
        layoutDropdownOption: LAYOUT_FILTER_DEFAULT_OPTION,
        drawerVisible:
          someDesignHasPhoto(template.designs, template.layers) ||
          hasEnvelopeAddressing(product.attributes),
      },
      history: {
        past: [],
        future: [],
      },
      user: {
        flashId: flashUser,
        flashToken,
        anonymous,
        admin: userIsAdmin,
        loginStatus: null,
        groupId: 0,
        storeId: 1,
      },
      zendesk: defaultZendeskState,
      galleries: initialGalleriesState,
    };
    return defaultState;
  }

  window.newrelic.addPageAction('missingTemplateForProduct', {
    product,
  });

  console.error('Unable to retrieve template for product', product);
};

export default createDefaultState;
