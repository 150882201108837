import { head } from 'fp-ts/lib/Array';

import {
  SET_FACEBOOK_USER, SET_FACEBOOK_ALBUMS, UI_FACEBOOK_ALBUM,
  SET_FB_MAIN_ALBUM, SET_FACEBOOK_ACTIVE, SET_FB_VIEWING_ALBUM,
  SET_FACEBOOK_NEXT_URL, SET_FACEBOOK_REQUESTING, ADD_FACEBOOK_PHOTOS,
  UNSET_FACEBOOK_PHOTOS,
} from './constants';
import { hideUploadPhotosModal } from '../ui/actions';
import { optionFind, prop } from '../../helpers/functions';

const setFBPhotosRequesting = value => ({
  type: SET_FACEBOOK_REQUESTING, payload: value,
});

const getCurrentAlbum = state => state.facebook.facebookAlbums.find(a => a.albumId === state.ui.currentAlbum);

export const unsetFacebookPhotos = () => dispatch => dispatch({ type: UNSET_FACEBOOK_PHOTOS, payload: [] });
export const setFBNextUrl = url => dispatch => dispatch({ type: SET_FACEBOOK_NEXT_URL, payload: url });

export const setFacebookPhotos = photos => (dispatch, getState) => {
  const photoData = photos || getCurrentAlbum(getState()).photos;
  dispatch(setFBNextUrl(photoData.paging.next));
  dispatch({
    type: ADD_FACEBOOK_PHOTOS,
    payload: photoData.data,
  });
};

export const paginateFacebook = () => (dispatch, getState) => {
  dispatch(setFBPhotosRequesting(true));
  const { nextUrl } = getState().facebook;
  if (nextUrl) {
    fetch(nextUrl, { method: 'GET' })
      .then(response => response.json())
      .then((resp) => {
        dispatch(setFacebookPhotos(resp));
      });
  }
};

export const loadFbLoginApi = () => () => {
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: `${process.env.REACT_APP_FACEBOOK_APP_ID}`,
      cookie: true,
      xfbml: true,
      version: 'v8.0',
    });
    window.FB.AppEvents.logPageView();
  };

  // This is just Facebook's snippet, I think, so we'll leave it alone.
  /* eslint-disable */
  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = '//connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
  /* eslint-enable */
};

const setMainFacebookAlbum = profilePic => (dispatch) => {
  const fbAlbum = { albumId: UI_FACEBOOK_ALBUM, thumbURL: profilePic };
  dispatch({ type: SET_FB_MAIN_ALBUM, payload: fbAlbum });
};

export const buildFacebookAlbums = facebookAlbums => (dispatch) => {
  const formattedFacebookAlbums = facebookAlbums
    .map((album) => {
      if (album.photos) {
        const albumThumb = optionFind(photo => photo.id === album.cover_photo.id, album.photos.data)
          .chain(prop('images'))
          .chain(head)
          .chain(prop('source'))
          .getOrElseValue('');

        const modAlbum = {
          source: 'facebook',
          thumbURL: albumThumb,
          albumId: album.name,
          photos: album.photos,
          id: album.id,
        };

        return modAlbum;
      }

      return null;
    })
    .filter(Boolean);

  dispatch({ type: SET_FACEBOOK_ALBUMS, payload: formattedFacebookAlbums });
};

const setFacebookUser = data => ({ type: SET_FACEBOOK_USER, payload: data });

export const setFbViewingAlbumStatus = value => ({ type: SET_FB_VIEWING_ALBUM, payload: value });

export const setFacebookActiveStatus = value => (dispatch, getState) => {
  const { facebookViewingAlbum } = getState().facebook;

  if (facebookViewingAlbum) {
    dispatch(setFbViewingAlbumStatus(false));
  }

  dispatch({ type: SET_FACEBOOK_ACTIVE, payload: value });
};

const requestFBAlbums = (dispatch, userAccessToken) => {
  dispatch(setFBPhotosRequesting(true));
  window.FB.api(
    `/me?access_token=${userAccessToken}&fields=picture.height(1200),albums{name, cover_photo, photos{name, images{source}}}`,
    async (response) => {
      if (response && !response.error) {
        const facebookProfilePic = response.picture.data.url;
        const albums = response.albums.data;

        await dispatch(buildFacebookAlbums(albums));
        dispatch(setMainFacebookAlbum(facebookProfilePic));
        dispatch(setFacebookActiveStatus(true));
        dispatch(hideUploadPhotosModal());
      }
    }
  );
};

const statusChangeCallback = (response) => {
  if (response.status === 'connected') {
    return response.authResponse;
  } else if (response.status === 'not_authorized') {
    console.log('Please log into this app.');
  } else {
    console.log('Please log into this facebook.');
  }
};

const checkLoginState = dispatch => () => {
  if (window.FB) {
    window.FB.login((response) => {
      const res = statusChangeCallback(response);
      if (res) {
        const data = { data: { facebookAccessToken: res.accessToken, facebookUserID: res.userID } };
        dispatch(setFacebookUser(data));
        requestFBAlbums(dispatch, res.accessToken);
      } else {
        console.log('no permissions');
      }
    }, { scope: 'user_photos' });
  } else {
    dispatch(loadFbLoginApi());
  }
};

export const handleFacebookLogin = () => (dispatch) => {
  dispatch(checkLoginState(dispatch));
};
