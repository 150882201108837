/* eslint-disable no-use-before-define */
// @flow

import { getContainerWidthHeight } from '../images';
import { type Layer } from '../../types/templates';
import { type Photo, FlashImageOrientation } from '../../types/photo';

// calculates the position offset for the image in comparison to its container
const calcImgOffset = (item, container) => item / 2 - container / 2;

export const generateInitCropData = (extractedPhoto: Object, layer: Layer, surface: Object) => {
  // const extractedPhoto = photoHelper.extract(photo);
  const dimensions = extractedPhoto.dimensions;
  const exifRotationDegrees = getExifRotationDegrees(extractedPhoto);

  const photoWidth = exifRotationDegrees % 180 === 0 ? dimensions.width : dimensions.height;
  const photoHeight = exifRotationDegrees % 180 === 0 ? dimensions.height : dimensions.width;
  if (!photoWidth || !photoHeight) {
    throw new Error('Could not get dimensions.');
  }

  const { containerWidth, containerHeight } = getContainerWidthHeight(layer, surface);

  // natural size aspect ratio
  const aspectRatio = photoWidth / photoHeight;
  // container aspect ratio
  const containerAspectRatio = containerWidth / containerHeight;

  // natural image scales
  const naturalScaledWidth = containerHeight * aspectRatio;
  const naturalScaledHeight = containerWidth / aspectRatio;

  // container based image scales
  const containerScaledWidth = photoHeight * containerAspectRatio;
  const containerScaledHeight = photoWidth / containerAspectRatio;

  const largerWidth = naturalScaledWidth >= containerWidth;

  const scaledWidth = !largerWidth ? containerWidth : naturalScaledWidth;
  const scaledHeight = largerWidth ? containerHeight : naturalScaledHeight;

  const canvasData = {
    left: largerWidth ? calcImgOffset(containerWidth, scaledWidth) : 0,
    top: largerWidth ? 0 : calcImgOffset(containerHeight, scaledHeight),
    width: scaledWidth,
    height: scaledHeight,
    naturalWidth: photoWidth,
    naturalHeight: photoHeight,
  };

  const cropperData = {
    x: largerWidth ? calcImgOffset(photoWidth, containerScaledWidth) : 0,
    y: largerWidth ? 0 : calcImgOffset(photoHeight, containerScaledHeight),
    rotate: 0,
    scaleX: 1,
    scaleY: 1,
    width: largerWidth ? containerScaledWidth : photoWidth,
    height: largerWidth ? photoHeight : containerScaledHeight,
  };

  return {
    canvasData,
    cropBoxData: {
      left: 0,
      top: 0,
      width: containerWidth,
      height: containerHeight,
    },
    imageData: {
      naturalWidth: photoWidth,
      naturalHeight: photoHeight,
      aspectRatio,
      width: scaledWidth,
      height: scaledHeight,
      left: 0,
      top: 0,
    },
    containerData: {
      width: containerWidth,
      height: containerHeight,
    },
    cropperData,
  };
};

/**
 * https://sirv.com/help/articles/rotate-photos-to-be-upright/
 * 1 = 0 degrees: the correct orientation, no adjustment is required.
 * 2 = 0 degrees, mirrored: image has been flipped back-to-front.
 * 3 = 180 degrees: image is upside down.
 * 4 = 180 degrees, mirrored: image has been flipped back-to-front and is upside down.
 * 5 = 90 degrees: image has been flipped back-to-front and is on its side.
 * 6 = 90 degrees, mirrored: image is on its side.
 * 7 = 270 degrees: image has been flipped back-to-front and is on its far side.
 * 8 = 270 degrees, mirrored: image is on its far side.
 * @param {Photo} photo
 */
export const getExifRotationDegrees = (p: Photo) => {
  let exifRotationDegrees = 0;
  if (p && p.metadata) {
    if (typeof p.metadata.orientation === 'number') {
      switch (p.metadata.orientation) {
        case 3:
        case 4:
          exifRotationDegrees = 180;
          break;
        case 5:
        case 6:
          exifRotationDegrees = 90;
          break;
        case 7:
        case 8:
          exifRotationDegrees = 270;
          break;
        default:
          break;
      }
    } else if (typeof p.metadata.orientation === 'string') {
      switch (p.metadata.orientation) {
        case FlashImageOrientation.ROTATE_180:
          exifRotationDegrees = 180;
          break;
        case FlashImageOrientation.ROTATE_90:
          exifRotationDegrees = 90;
          break;
        case FlashImageOrientation.ROTATE_270:
          exifRotationDegrees = 270;
          break;
        default:
          break;
      }
    }
  }
  return exifRotationDegrees;
};
