// @flow
import { type EnvelopeAddressingState, type ManualAddress } from './reducer';
import { type Dispatch } from 'redux';
import { type State } from 'react-redux';
import {
  type MagentoAddress,
  type CsvAddress,
} from '../../types/address';
import {
  SET_RETURN_ADDRESS,
  SET_RECIPIENT_ADDRESS,
  SET_MANUAL_RECIPIENT_ADDRESS,
  SET_RECIPIENTS_CSV,
  RETURN_ADDRESS_STATE_KEY,
  RECIPIENT_ADDRESS_STATE_KEY,
  CSV_KEY_STATE_KEY,
  UNSET_ENVELOPE_ADDRESSING,
  SET_COUNTRIES,
  SET_REGIONS,
  ENVELOPE_FONT,
  SET_ENVELOPE_FONT,
} from './constants';
import match from '../../helpers/match';
import { findFirst } from 'fp-ts/lib/Array';
import { option } from 'fp-ts';
import { prop, composeK } from '../../helpers/functions';
import {
  envelopeAddressingOptionsSelector,
  returnAddressSelector,
  getManualReturnAddressing,
} from './selectors';
import {
  ENVELOPE_ADDRESSING_ATTRIBUTE,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE,
} from '../../constants/envelopes';
import { preUpdateAttribute } from '../product/actions';
import { requestCountries, requestRegions } from '../../services/magento';
import { sendAnalyticsForRemoveCsv } from '../analytics/actions';
import { showModal } from '../ui/modal/actions';
import { UI_MODAL_RECIPIENT_ADDRESSES_UPLOAD, UI_MODAL_RECIPIENT_ADDRESSES_FORM } from '../ui/modal/constants';
import { validStringLength, validString } from '../../helpers/strings';
import { areRequiredFieldsSet } from '../../helpers/objects';
import {
  getEnvelopeAddressingBothValue,
  getEnvelopeAddressingNoneValue,
  getEnvelopeAddressingReplyValue,
  getEnvelopeAddressingReturnOnlyValue,
} from '../../helpers/templates';


export const getDefaultAddress = (addresses: Array<MagentoAddress>, addressId: ?string) => {
  if (!addresses.length) {
    return null;
  }

  return option.fromNullable(addressId)
    .chain((addr) => findFirst((x) => x.id === parseInt(addr, 10))(addresses))
    .getOrElseValue(addresses[0]);
};

// Do not export - Use setEnvelopeAddressingData to update envelopeAddressing data
const setReturnAddress = (address: MagentoAddress) => ({
  type: SET_RETURN_ADDRESS,
  payload: {
    address,
  },
});

// Do not export - Use setEnvelopeAddressingData to update envelopeAddressing data
const setRecipientAddress = (address: ?MagentoAddress | ?CsvAddress) => ({
  type: SET_RECIPIENT_ADDRESS,
  payload: {
    address,
  },
});

// Do not export - Use setEnvelopeAddressingData to update envelopeAddressing data
const setRecipientsCsv = (csvKey: ?string, csvFilename: ?string, contactGroupName: ?string) => ({
  type: SET_RECIPIENTS_CSV,
  payload: {
    csvKey,
    csvFilename,
    contactGroupName,
  },
});

// Do not export - Use setEnvelopeAddressingData to update envelopeAddressing data
const setEnvelopeFont = (font: string) => ({
  type: SET_ENVELOPE_FONT,
  payload: {
    font,
  },
});

const unsetEnvelopeAddressingAction = () => ({
  type: UNSET_ENVELOPE_ADDRESSING,
});

export const unsetEnvelopeAddressing = () => (dispatch: Function) => {
  dispatch(unsetEnvelopeAddressingAction());
  dispatch(preUpdateAttribute({
    key: ENVELOPE_ADDRESSING_ATTRIBUTE, value: ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE,
  }));
};

export const setEnvelopeAddressingData = (
  key: string,
) => (
  ...values: Array<any>
) => (
  dispatch: Function,
  getState: Function,
) => {
  match(
    RETURN_ADDRESS_STATE_KEY, () => {
      // First, set the return address with the given data
      dispatch(setReturnAddress(...values));
      const [address] = values;
      const state = getState();
      const csvKey = state.envelopeAddressing.csvKey;

      const replyValue = getEnvelopeAddressingReplyValue();
      const hasReplyAddressing = envelopeAddressingOptionsSelector(state)
        .map((x) => x.map((y) => y.value))
        .map((x) => x.includes(replyValue))
        .getOrElseValue(false);

      // This was failing sometimes because some products are using the newer "none|return|reply|both"
      // as opposed to the "None|Return Address Only ($0.40 per Envelope)|etc..." nonsense
      // We need to grab the value out of the available values for the product instead of hard coding them
      if (address === null && csvKey === null) {
        dispatch(preUpdateAttribute({
          key: ENVELOPE_ADDRESSING_ATTRIBUTE, value: getEnvelopeAddressingNoneValue(),
        }));
      } else if (hasReplyAddressing) {
        dispatch(preUpdateAttribute({
          key: ENVELOPE_ADDRESSING_ATTRIBUTE, value: replyValue,
        }));
      } else if (csvKey === null) {
        dispatch(preUpdateAttribute({
          key: ENVELOPE_ADDRESSING_ATTRIBUTE, value: getEnvelopeAddressingReturnOnlyValue(),
        }));
      }
    },

    RECIPIENT_ADDRESS_STATE_KEY, () => {
      // Recipient address is only a UI concern, no need to update attributes
      dispatch(setRecipientAddress(...values));
    },

    ENVELOPE_FONT, () => {
      dispatch(setEnvelopeFont(...values));
    },

    CSV_KEY_STATE_KEY, () => {
      // values in this case would be csvKey and csvFilename
      dispatch(setRecipientsCsv(...values));
      const [csvKey] = values;

      // A few checks are happening here
      // 1. Is the new csvKey value not null, if so having a CSV dictates the attribute being "both"
      // 2. Is the csvKey null && the return address not null, if so "return only"
      // 3. Csv is null and return address is null, set addressing to "none"
      option.fromNullable(csvKey)
        .chain(option.fromPredicate((x) => x !== ''))
        // eslint-disable-next-line
        .map(() => {
          dispatch(preUpdateAttribute({
            key: ENVELOPE_ADDRESSING_ATTRIBUTE, value: getEnvelopeAddressingBothValue(),
          }));
        })
        // If there's a return address, set envelope_addressing to return only
        .alt(
          option.fromNullable(returnAddressSelector(getState()))
            // eslint-disable-next-line
            .map(() => {
              const csvExists = option.fromNullable(csvKey)
                .chain(option.fromPredicate((x) => x !== ''));
              if (csvExists.isNone()) {
                dispatch(preUpdateAttribute({
                  key: ENVELOPE_ADDRESSING_ATTRIBUTE, value: getEnvelopeAddressingReturnOnlyValue(),
                }));
                dispatch(setRecipientAddress(null));
              }
            })
        )
        .getOrElse(() => {
          dispatch(setRecipientAddress(null));
          // No csv, no return address - set envelope addressing to none
          dispatch(preUpdateAttribute({
            key: ENVELOPE_ADDRESSING_ATTRIBUTE, value: getEnvelopeAddressingNoneValue(),
          }));
        });
    },

    match.default, () => {
    },
  )(key);
};

export const removeRecipientsCsv = (sendAnalytics: boolean = false) => (dispatch: Function) => {
  if (sendAnalytics) {
    dispatch(sendAnalyticsForRemoveCsv());
  }
  dispatch(setEnvelopeAddressingData(CSV_KEY_STATE_KEY)(null, null));
};

const setCountries = (payload) => ({
  type: SET_COUNTRIES,
  payload,
});

export const getCountries = () => (dispatch: Dispatch, getState: State) => {
  if (
    composeK(prop('countries'), prop('envelopeAddressing'))(getState()).isNone()
    || composeK(prop('countries'), prop('envelopeAddressing'))(getState()).map((xs) => xs.length === 0).getOrElseValue(false)
  ) {
    requestCountries()
      .then((xs) => xs.sort((a, b) => (a.full_name_english > b.full_name_english ? 1 : -1)))
      .then((xs) => dispatch(setCountries(xs)))
      .catch(window.newrelic.noticeError);
  }
};

const setRegions = (payload) => ({
  type: SET_REGIONS,
  payload,
});

export const getRegions = (countryId: string) => (dispatch: Dispatch, getState: State) => {
  if (
    composeK(prop(countryId), prop('regions'), prop('envelopeAddressing'))(getState()).isNone()
    || composeK(prop(countryId), prop('regions'), prop('envelopeAddressing'))(getState())
      .map((obj) => Object.keys(obj).length === 0)
      .getOrElseValue(false)
  ) {
    requestRegions(countryId)
      .then((obj) => ({
        ...obj,
        available_regions: obj.available_regions
          .map((x) => ({
            region_id: x.id,
            region_code: x.code,
            region: x.name,
          })),
      }))
      .then((xs) => dispatch(setRegions(xs)))
      .catch(window.newrelic.noticeError);
  }
};

export const showRecipientAddressModal = () => (dispatch: Dispatch, getState: () => { template: any, envelopeAddressing: EnvelopeAddressingState }) => {
  const manualRecipientAddress = getManualReturnAddressing(getState());
  // eslint-disable-next-line
  manualRecipientAddress.map(x => {
    dispatch(showModal(UI_MODAL_RECIPIENT_ADDRESSES_FORM));
  }).getOrElse(() => {
    dispatch(showModal(UI_MODAL_RECIPIENT_ADDRESSES_UPLOAD));
  });
};

export const setManualRecipientAddress = (address) => ({
  type: SET_MANUAL_RECIPIENT_ADDRESS,
  payload: {
    address,
  },
});


export const isAddressPopulated = areRequiredFieldsSet(['firstName', 'lastName', 'street1', 'city', 'state', 'zipcode']);

export const validateManualAddress = (address: ManualAddress) => {
  if (!isAddressPopulated(address)) {
    return ['Please fill out all fields'];
  }
  const errors = Object.keys(address).map((field) => {
    const val = address[field];
    switch (field) {
      case 'firstName':
        return validString('First Name must not be empty')(val);
      case 'lastName':
        return validString('Last Name must not be empty')(val);
      case 'street1':
        return validString('Street Address must not be empty')(val);
      case 'city':
        return validString('City must not be empty')(val);
      case 'state':
        return validStringLength(2)('State abbreviation must be two characters')(val);
      case 'zipcode':
        return validString('Zip code must not be empty')(val);
      default:
        return '';
    }
  });

  return errors.filter((x) => x !== '');
};
