// @flow

import { option, apply } from 'fp-ts';
import type { Option } from 'fp-ts/lib/Option';
import type { Monad } from 'fp-ts/lib/Monad';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';

import has from '../has';
import {
  multiply,
  divide,
  add,
  subtract,
} from '../numbers';

export type OptionsArray<T> = Array<Option<T>>;
export type OptionsObject<T> = { [string]: Option<T> };

export const isChainable = (m: Object) => typeof m.chain === 'function';

export const compose = (...fns: Array<Function>) => fns.reduce((f, g) => (...args) => f(g(...args)));

export const composeK = (...args: Array<Function>): Function => {
  const fns = args.slice().reverse();
  const head = fns[0];

  if (fns.length === 1) {
    return head;
  }

  const tail: Function = fns.slice(1).reduce((comp, fn) => (m) => {
    if (!isChainable(m)) {
      throw new TypeError('composeK: chain returning functions required.');
    }
    return comp(m).chain(fn);
  }, x => x);

  return x => tail(head(x));
};

// chain :: (a -> mb) -> ma -> mb
export const chain = <A, B>(f: (a: A) => Monad<B>) => (ma: Monad<A>): Monad<B> => ma.chain(f);
// export const chain = <A, B>(f: (a: A) => Chain<B>) => (ma: Chain<A>): Chain<B> => ma.chain(f);

// Extracts an objects propery into a Option
export const prop = (name: string) => (obj: ?Object): Option<any> => (
  obj && has(obj)(name) ? option.fromNullable(obj[name]) : option.none
);

// Extracts any number of properties from an Object into an Object of Options.
// Useful for dereferencing multiple properties at the same time.
export const deref = (...names: Array<string>) => (obj: Object): OptionsObject<*> => names.reduce((result, name) => ({
  ...result,
  [name]: prop(name)(obj),
}), {});

// Given a path and an object, returns a Option of the value at that path in the object.
export const optionGet = (p: string) => (obj: ?Object): Option<any> => option.fromNullable(get(obj, p, undefined));

/* Takes a function - which takes any two values of the same type and returns one value of the same type - and returns a
   curried function that takes two Options and then applys the given function to both, returning an Option. */
const combineOptions = (f: any => any => any) => (o1: Option<any>) => (o2: Option<any>): Option<any> => (
  apply.liftA2(option.option)(f)(o1)(o2)
);

export function memoize(f: Function) {
  function fn(...args: Array<any>) {
    const argsHash = args.map(x => (typeof x === 'object' ? JSON.stringify(x) : x)).toString();
    f.memo = f.memo || {}; // eslint-disable-line no-param-reassign
    const val = (argsHash in f.memo) ? f.memo[argsHash] : f.memo[argsHash] = f.apply(this, args); // eslint-disable-line no-param-reassign
    return val;
  }

  return fn;
}
// Logger. Logs passed value and returns it.
export const logger = <T>(a: string) => (x: T): T => {
  console.log(a, x); // eslint-disable-line no-console
  return x;
};

// throwWhen throws an exception with provided message when a given predicate returns true
export const throwWhen = <T>(message: string) => (predicate: T => bool) => (x: T): T => {
  if (predicate(x)) {
    throw new Error(message);
  }
  return x;
};

// Option math functions.
export const mult2: Option<number> => Option<number> => Option<number> = combineOptions(multiply);
export const div2: Option<number> => Option<number> => Option<number> = combineOptions(divide);
export const add2: Option<number> => Option<number> => Option<number> = combineOptions(add);
export const sub2: Option<number> => Option<number> => Option<number> = combineOptions(subtract);

// Comparison functions
export const equals = (a: any) => (b: any): boolean => isEqual(b, a);
export const notEquals = (a: any) => (b: any): boolean => !isEqual(b, a);

export const lessThan = (a: number) => (b: number): boolean => b < a;
export const lessThanOrEqual = (a: number) => (b: number): boolean => b <= a;

export const greaterThan = (a: number) => (b: number): boolean => b > a;
export const greaterThanOrEqual = (a: number) => (b: number): boolean => b >= a;

// Comparison Accessor
/* Given a path string, a curried comparison function, a value, and an object that may contain the given path,
   returns the result of the comparison function called with the value at that path, if any. */
export const pathCompare = (p: string) => (f: (x: mixed) => (y: mixed) => boolean) => (v: mixed) => (o: Object) => (
  optionGet(p)(o).map(f(v)).getOrElseValue(false)
);

// Evaluates whether the value at the given path in the object is equal to the given string.
export const pathEquals = (p: string) => (v: mixed) => (o: Object): boolean => pathCompare(p)(equals)(v)(o);

// Options comparisons
export const optionsEqual = <T, U>(o1: Option<T>) => (o2: Option<U>): boolean => combineOptions(equals)(o1)(o2).getOrElseValue(false);

export const optionFind = <T>(f: (x: T) => boolean, arr: Array<T>): Option<T> => option.fromNullable(arr.find(f));

export const optionPair = combineOptions((a: mixed) => (b: mixed): Array<mixed> => [a, b]);

export const optionFromEmpty = (val: String | Array<any> | boolean): Option<any> => {
  switch (true) {
    case typeof val === 'boolean':
      return val === true ? option.some(val) : option.none;
    default:
      return (val.length ? option.some(val) : option.none);
  }
};

// Compares two values, a and b, returning -1 if a < b, +1 if a > b, and 0 otherwise.
// This can compare both numbers and strings, thanks to Javascripts built-in support for string comparison.
export const compare = (a: any) => (b: any): 0 | 1 | -1 => {
  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
};
