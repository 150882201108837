import find from 'lodash.find';
import get from 'lodash.get';

import {
  MAGENTO_USER_ME_FAILURE,
  MAGENTO_USER_ME_SUCCESS,
  SET_LOGIN_STATUS,
  SET_USER_DATA,
  USER_SET_FLASH_ID,
  USER_SET_FLASH_TOKEN,
  MAGENTO_ADMIN_ME_SUCCESS,
  ADD_USER_ADDRESS,
  EDIT_USER_ADDRESS,
} from './constants';
import { isParameterPresent } from '../../helpers/urlParameters';
import { SET_NEW_PROJECT_DATA } from '../project/constants';
import { validateIncomingFlashAuth } from './actions';

const defaultUser = {
  flashId: null,
  flashToken: null,
  anonymous: isParameterPresent('projectId'),
  authorized: null,
  admin: false,
  loginStatus: 'LOGIN',
  promptUserToLogin: false,
  addresses: [],
};

export default function userReducer(state = defaultUser, action) {
  switch (action.type) {
    case USER_SET_FLASH_ID: {
      const { flashId } = action.payload;

      validateIncomingFlashAuth(state, flashId, null);

      return {
        ...state,
        flashId,
      };
    }

    case USER_SET_FLASH_TOKEN: {
      const { flashToken } = action.payload;

      validateIncomingFlashAuth(state, null, flashToken);

      return {
        ...state,
        flashToken,
      };
    }

    case SET_NEW_PROJECT_DATA: {
      return {
        ...state,
        anonymous: false,
        authorized: true,
      };
    }

    case MAGENTO_USER_ME_SUCCESS: {
      const { payload: user } = action;
      const groupId = get(user, 'group_id', 0);
      const email = get(user, 'email', '');
      const id = get(user, 'id', null);
      const flashIdAttribute = find(user.custom_attributes, { attribute_code: 'flash_id' });
      const flashTokenAttribute = find(user.custom_attributes, { attribute_code: 'flash_auth_token' });
      const addresses = get(user, 'addresses', []);

      if (flashIdAttribute && flashTokenAttribute) {
        validateIncomingFlashAuth(state, flashIdAttribute.value, flashTokenAttribute.value);
      }

      return {
        ...state,
        email,
        id,
        flashId: flashIdAttribute ? flashIdAttribute.value : null,
        flashToken: flashTokenAttribute ? flashTokenAttribute.value : null,
        authorized: flashIdAttribute !== undefined && flashIdAttribute.value === state.flashId,
        groupId,
        addresses,
      };
    }

    case MAGENTO_ADMIN_ME_SUCCESS: {
      const { payload: user } = action;
      const groupId = get(user, 'group_id', 2);

      return {
        ...state,
        authorized: true,
        groupId,
      };
    }

    case MAGENTO_USER_ME_FAILURE: {
      return {
        ...state,
        authorized: false,
      };
    }

    case SET_LOGIN_STATUS: {
      const { loginStatus } = action.payload;

      return {
        ...state,
        loginStatus,
      };
    }

    case SET_USER_DATA: {
      const { user } = action.payload;
      const flashId = find(user.custom_attributes, { attribute_code: 'flash_id' }).value;
      const flashToken = find(user.custom_attributes, { attribute_code: 'flash_auth_token' }).value;
      const groupId = get(user, 'group_id', 0);
      const email = get(user, 'email', '');
      const id = get(user, 'id', null);
      const addresses = get(user, 'addresses', []);
      const storeId = get(user, 'store_id', 1);

      if (flashId && flashToken) {
        validateIncomingFlashAuth(state, flashId, flashToken);
      }

      return {
        ...state,
        anonymous: false,
        authorized: true,
        flashId,
        flashToken,
        groupId,
        email,
        id,
        storeId,
        addresses,
      };
    }

    case ADD_USER_ADDRESS: {
      const { payload } = action;
      return {
        ...state,
        addresses: [...state.addresses, payload],
      };
    }

    case EDIT_USER_ADDRESS: {
      return {
        ...state,
        addresses: state.addresses
          .map((x) => (x.id === action.payload.id ? action.payload : x)),
      };
    }

    default: {
      return state;
    }
  }
}


export const userStateFilter = (state) => ({
  ...state,
  authorized: null,
  flashToken: null,
});
