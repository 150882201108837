import { MANUAL_SAVE, PROJECT_STATE_IN_CART, PROJECT_STATE_PRINTED, SET_IS_WDS_PROJECT } from '../project/constants';
import {
  PROJECT_PAGES_APPLY_CROP_DATA_TO_LAYER,
  PROJECT_PAGES_APPLY_PHOTO_TO_LAYER,
  PROJECT_PAGES_APPLY_TEMPLATE,
  PROJECT_PAGES_REMOVE_IMAGE,
  PROJECT_PAGES_REMOVE_USERPHOTOS,
  PROJECT_PAGES_ORDER,
  SET_LAYER_DATA,
  PROJECT_SAVE_REQUEST,
  PROJECT_SAVE_SUCCESS,
  PROJECT_SAVE_FAILURE,
  SET_LAYER_REGION_DATA,
  PROJECT_PAGES_APPLY_DESIGN_TO_PAGE,
  SET_LAYER_DIMS,
} from '../project/pages/constants';
import { USER_SET_FLASH_ID, USER_SET_FLASH_TOKEN } from '../user/constants';

import { CALL_API } from './api';
import { PROJECT_DATA_SET_PROJECT_NAME } from '../project/name/constants';
import { SET_TEMPLATE } from '../template/constants';
import { UPDATE_ATTRIBUTE, CUSTOMER_ACK_EMPTY_PAGE, UPDATE_QTY } from '../product/constants';
import { cartStateSelector } from '../product/selectors';
import clonedeep from 'lodash.clonedeep';
import { storeFilter } from '../storeFilter';
import debounce from 'lodash.debounce';
import { SET_RECIPIENT_ADDRESS, SET_RECIPIENTS_CSV } from '../envelopeAddressing/constants';
import { projectInCartSelector } from '../project/pages/selectors';

const SAVE_REQUEST_TYPES = [PROJECT_SAVE_REQUEST, PROJECT_SAVE_SUCCESS, PROJECT_SAVE_FAILURE];

const SAVEABLE_TYPES = [
  UPDATE_ATTRIBUTE,
  CUSTOMER_ACK_EMPTY_PAGE,
  PROJECT_PAGES_APPLY_TEMPLATE,
  PROJECT_PAGES_APPLY_PHOTO_TO_LAYER,
  PROJECT_PAGES_APPLY_CROP_DATA_TO_LAYER,
  PROJECT_PAGES_APPLY_DESIGN_TO_PAGE,
  PROJECT_PAGES_REMOVE_IMAGE,
  PROJECT_DATA_SET_PROJECT_NAME,
  PROJECT_PAGES_ORDER,
  SET_TEMPLATE,
  USER_SET_FLASH_ID,
  USER_SET_FLASH_TOKEN,
  MANUAL_SAVE,
  SET_LAYER_DATA,
  SET_LAYER_REGION_DATA,
  PROJECT_PAGES_REMOVE_USERPHOTOS,
  SET_LAYER_DIMS,
  SET_RECIPIENT_ADDRESS,
  SET_RECIPIENTS_CSV,
  SET_RECIPIENT_ADDRESS,
  UPDATE_QTY,
  SET_IS_WDS_PROJECT,
];

let debounced;
const debouncer = fn =>
  debounce(fn, 1000, {
    leading: true,
    trailing: true,
  });

const getDebouncedDispatch = dispatch => {
  if (typeof debounced === 'function') {
    return debounced;
  }

  debounced = debouncer(dispatch);
  return debounced;
};

// actionObserver observes all actions enacted on components
// and will push each ACTION to he Project Service via REST call
export default store => next => action => {
  const debouncedDispatch = getDebouncedDispatch(store.dispatch);
  const state = store.getState();
  const { user, asyncInitialState } = state;
  const cartState = cartStateSelector(state);
  const projectIsAuthorized = state.project.id !== null && !user.anonymous && user.authorized;
  const projectIsLocked = cartState === PROJECT_STATE_IN_CART || cartState === PROJECT_STATE_PRINTED;
  const projectLoadIsComplete = asyncInitialState.loading !== true;
  const isValidAction = SAVEABLE_TYPES.includes(action.type);

  // Move along if this is our original dispatch, or if the user is not authorized.
  if (
    !projectIsAuthorized ||
    projectIsLocked ||
    !isValidAction ||
    !projectLoadIsComplete ||
    action.shouldSave === false ||
    user.admin === true ||
    projectInCartSelector(state)
  ) {
    return next(action);
  }

  const nState = next(action);

  const body = {};
  body.projectId = store.getState().project.id;
  body.user = store.getState().user.flashId;

  const cleanState = storeFilter(clonedeep(store.getState()));

  body.projectData = JSON.stringify({ data: cleanState });
  window.newrelic.addPageAction('auAutoSaveStarted');
  const saveAction = {
    [CALL_API]: {
      url: `${process.env.REACT_APP_PROJECT_SERVICE_BASE_URL}/project/${store.getState().project.id}?user=${store.getState().user.flashId}`,
      options: {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${store.getState().user.flashToken}`,
        },
        body: JSON.stringify(body),
      },
      types: SAVE_REQUEST_TYPES,
    },
  };

  debouncedDispatch(saveAction);

  return nState;
};
