// @flow

import {
  applyReducer,
  compare,
  applyPatch as _applyPatch,
} from 'fast-json-patch';
import cloneDeep from 'lodash.clonedeep';
import { type Option, some, fromNullable as optionFromNullable } from 'fp-ts/lib/Option';
import { omit } from '../objects';
import { sortBy } from '../arrays';
import { INSIDE_COVER_ID, BACK_INSIDE_COVER_ID, FRONT_ENDSHEET_ID, BACK_ENDSHEET_ID } from '../pages';
import { WOODEN_BOX_PAGE_ID } from '../../constants/products';

export type FilterRule = string;

export type Patch = {
  op: string,
  path: string,
  value: ?any,
};

export type PatchOperation = Array<Patch>;

export const filterObject = (filterRules: Array<FilterRule>) => (y: Object): Object => omit(filterRules)(cloneDeep(y));

export const createPatch = (filterRules: Array<FilterRule>) => (x: Option<Object>) => (y: Option<Object>): Option<PatchOperation> => {
  const filterState = filterObject(filterRules);
  return some(doc1 => doc2 => compare(doc1, doc2))
    .ap_(x.map(filterState))
    .ap_(y.map(filterState));
};

export const toStateWithPagesMap = (state: Object) => ({
  ...state,
  project: {
    ...state.project,
    pages: state.project.pages.reduce((pages, currentPage) => ({
      ...pages,
      [currentPage.uuid]: cloneDeep(currentPage),
    }), {}),
  },
});

export const sortBookPages = pageCount => sortBy([(o) => {
  switch (true) {
    case o.id === 'cover': {
      return -2;
    }
    case o.id === INSIDE_COVER_ID: {
      return -1;
    }
    case o.id === BACK_INSIDE_COVER_ID: {
      return pageCount - 1;
    }
    case o.id === FRONT_ENDSHEET_ID: {
      return -1;
    }
    case o.id === BACK_ENDSHEET_ID: {
      return pageCount - 1;
    }
    case o.id === WOODEN_BOX_PAGE_ID: {
      return pageCount;
    }
    default: {
      return parseInt(o.id.replace('page_', ''), 10);
    }
  }
}]);

export const fromStateWithPagesMap = (state: Object) => ({
  ...state,
  project: {
    ...state.project,
    pages: sortBookPages(
      Object.values(state.project.pages).length
    )(
      Object.values(state.project.pages)
    ),
  },
});

/**
 * Wrapped around createPatch that provides state objects as options
 */
export const createUndoRedoPatch = (
  filterRules: Array<FilterRule>
) => (
  newState: ?Object = null
) => (
  oldState: ?Object = null
) => createPatch(
  filterRules
)(
  optionFromNullable(toStateWithPagesMap(newState))
)(optionFromNullable(toStateWithPagesMap(oldState))).getOrElseValue([]);

// takes an array of patches and state, it will return a new state based on the applied patches
export const applyPatch = (patches: PatchOperation) => (state: Object): Object => {
  const withPagesMap = toStateWithPagesMap(cloneDeep(state));
  return fromStateWithPagesMap(_applyPatch(withPagesMap, patches.reduce(applyReducer, withPagesMap)).newDocument);
};

const PRINT_SET_ATTRIBUTE_MAP = {
  print_size: {
    '3.25by3.25': '3.25x3.25',
    '3.25by4.25': '3.25x4.25',
    '4.25by3.25': '4.25x3.25',
    '3.5by5': '3.5x5',
    '5by3.5': '5by3.5',
    '4by6': '4x6',
    '6by4': '6x4',
    '5by7': '5x7',
    '7by5': '7x5',
    '4by4': '4x4',
    '5by5': '5x5'
  },
  print_quantity: {
    'set of 10': '10',
    'set of 25': '25',
    'set of 50': '50'
  }
}

const ATTRIBUTE_MAP = {
  softcover: {
    softcover_book_size: {
      '5x5': '5.5x5.5',
      '8x8': '8.5x8.5',
      '8x11': '8.25x11',
      '11x8': '11x8.25'
    }
  },
  'square print sets': PRINT_SET_ATTRIBUTE_MAP,
  'everyday print sets': PRINT_SET_ATTRIBUTE_MAP,
  signatureprints: {
    print_size: {
      'one11x14': '11x14',
      'two8x8': '8x8',
      'two8x10': '8x10',
      'four5x7': '5x7'
    }
  },
  'framed-canvas': {
    'frame_finish': {
      'canvas_silver': 'silver',
      'canvas_black': 'black',
      'canvas_brass': 'brass'
    }
  }
}

export const attributesToMap = (product) => {
  if (!product || !product.attributes) {
    return [];
  }

  return Object.keys(product.attributes).reduce((needsMap, attr) => {
    attr = attr.toLowerCase();
    if (ATTRIBUTE_MAP[product.sku] && ATTRIBUTE_MAP[product.sku][attr] && ATTRIBUTE_MAP[product.sku][attr][product.attributes[attr]]) {
      needsMap.push([attr, product.attributes[attr]]);
    }
    return needsMap;
  }, []);
}

export const mapProductAttributes = (product, mappedAttributes) => {
  let newAttributes = { ...product.attributes };
  Array.isArray(mappedAttributes) && mappedAttributes.forEach(([key, value]) => {
    newAttributes[key] = ATTRIBUTE_MAP[product.sku][key][value];
  })
  return newAttributes;
}
