import {
  ADD_INSTAGRAM_PHOTOS,
  SET_INSTAGRAM_ACCESS_TOKEN,
  SET_INSTAGRAM_ACTIVE,
  SET_INSTAGRAM_MAIN_ALBUM,
  SET_INSTAGRAM_PLACEHOLDERS,
  SET_INSTAGRAM_NEXT_URL,
  INSTAGRAM_REQUESTING,
} from './constants';

const INITIAL_STATE = {
  selectedImages: [],
  images: [],
  instagramAccessToken: null,
  instagramMainAlbum: null,
  instagramActive: false,
  instagramPhotoCount: 0,
  nextUrl: null,
  IgRequesting: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case INSTAGRAM_REQUESTING: {
      return { ...state, IgRequesting: action.payload };
    }

    case ADD_INSTAGRAM_PHOTOS: {
      const { images } = state;
      const { newImages } = action.payload;

      const combined = [...images, ...newImages];

      return { ...state, images: combined, IgRequesting: false };
    }

    case SET_INSTAGRAM_PLACEHOLDERS: {
      return { ...state, instagramPhotoCount: action.payload.length };
    }

    case SET_INSTAGRAM_NEXT_URL: {
      return { ...state, nextUrl: action.payload };
    }

    case SET_INSTAGRAM_ACCESS_TOKEN: {
      return { ...state, instagramAccessToken: action.payload };
    }

    case SET_INSTAGRAM_ACTIVE: {
      return { ...state, instagramActive: action.payload };
    }

    case SET_INSTAGRAM_MAIN_ALBUM: {
      return { ...state, instagramMainAlbum: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const instagramStateFilter = () => INITIAL_STATE;
