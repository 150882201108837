import {
  UI_MODAL_CLOSE,
  UI_MODAL_SET_STATUS,
  initState,
} from './constants';

export default (state = initState, action) => {
  switch (action.type) {
    case UI_MODAL_CLOSE: {
      return {
        ...state,
        status: '',
      };
    }

    case UI_MODAL_SET_STATUS: {
      const { status, title, meta, data } = action.payload;
      return {
        ...state,
        status,
        title,
        meta,
        data,
      };
    }

    default:
      return state;
  }
};
