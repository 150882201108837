export const USER_PHOTO = 'user_photo';
export const DESIGN_ASSET = 'design_asset';
export const EDITABLE_TEXT = 'editable_text';
export const EDITABLE_DESIGN_ASSET = 'editable_design_asset';
export const ADDRESS_LAYER = 'address';
export const CUTOUT_LAYER = 'cutout';
export const MASK_LAYER = 'mask';
export const AUTOFILLED_TEXT = 'autofilled_text';
export const FULL_BLEED = 'full_bleed';
export const SHIFTED_LAYER_INDICATOR = '--SHIFTED_RIGHT';
export const CUSTOMIZED_LAYER_INDICATOR = '--CUSTOMIZED';
export const TRANSLATED_LAYER_INDICATOR = '--TRANSLATED';
export const DIGITAL_FOIL = 'digital_foil';
