// @flow

import match from './match';
import { methods, type APImethod } from '../constants/api';
import shortid from 'shortid';
import { split } from './strings';

const {
  GET_CDN_URL,
  POST_IMAGE,
  DELETE_IMAGE,
  GET_IMAGE,
  GET_IMAGE_FROM_S3,
  GET_IMAGES,
  DELETE_ALBUM,
  POST_ALBUM,
  GET_ALBUMS,
  GET_SIGNED_CSV_UPLOAD,
  GET_SIGNED_PHOTO_UPLOAD,
} = methods;

const splitUnderscore = split('_');
const httpMethod = str => splitUnderscore(str)[0];

/* Takes a baseURL, yields a function that takes a method string, in turn returning a
function that takes various arguments needed to construct the correct endpoint URL for a given API method. */
export const endpointFromBaseURL = (baseURL: string) => (method: APImethod) => match(
  GET_CDN_URL, () => (userID, mediaID, thumbSize = 'large') => `${baseURL}/cdn/image/${mediaID}?user=${userID}&thumb=${thumbSize}`,

  POST_IMAGE, () => () => `${baseURL}/image`,

  DELETE_IMAGE, () => (userID, mediaID) => `${baseURL}/image/${mediaID}?user=${userID}`,

  GET_IMAGE, () => (userID, mediaID, thumbSize = 'large') => `${baseURL}/image/${mediaID}?user=${userID}&thumb=${thumbSize}`,

  GET_IMAGE_FROM_S3, () => (userID, mediaID, thumbSize = 1200) => `${baseURL}/${userID}/${mediaID}_${thumbSize}`,

  GET_IMAGES, () => (userID, albumID, deleted = false) => (
    albumID.includes('Archive Gallery')
      ? `${baseURL}/images?user=${userID}&album=${encodeURIComponent(albumID)}`
      : `${baseURL}/images?user=${userID}&album=${encodeURIComponent(albumID)}&sort=desc&deleted=${deleted.toString()}`
  ),

  DELETE_ALBUM, () => (userID, albumID) => `${baseURL}/album/${albumID}?user=${userID}`,

  POST_ALBUM, () => (userID, albumID) => `${baseURL}/album/${albumID}?user=${userID}`,

  GET_ALBUMS, () => userID => `${baseURL}/albums?user=${userID}`,

  GET_SIGNED_CSV_UPLOAD, () => (flashId: string, objectKey: string) => (
    `${baseURL}/csv/signed-upload?objectKey=${objectKey}&user=${flashId}`
  ),

  GET_SIGNED_PHOTO_UPLOAD, () => (objectKey: string, flashId: string, albumId: string, uploadSource: string) => (
    `${baseURL}/image/signed-upload?objectKey=${objectKey}&user=${flashId}&albumId=${albumId}&uploadSource=${uploadSource}`
  ),

  match.default, () => () => '',
)(method);

// Returns an options object for use in a fetch call.
export const options = (method: APImethod) => (flashToken: string, body: ?Object = null) => ({
  headers: {
    Authorization: `Bearer ${flashToken}`,
  },
  method: httpMethod(method),
  ...(body ? { body } : {}),
});

// Resolves a promise with the requested json object, or throws an error.
export const fetchJSON = (url: string, optionsObj: Object, validator: ?(Object, Object) => boolean = null): Promise<Object> => (
  fetch(url, optionsObj)
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (response.status >= 200 && response.status < 300 && (validator ? validator(json, response) : true)) {
        return json;
      }

      return {};
    })
);

// Returns an array of empty photo objects, with a random mediaId for keying purposes.
export const placeholderPhotoArray = (count: number): Array<Object> => (
  new Array(count)
    .fill({
      thumbURL: '',
      isPlaceholder: true,
    })
    .map(placeholder => ({
      ...placeholder,
      mediaId: `placeholder-${shortid.generate()}`,
    }))
);
