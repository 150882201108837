import { UNTITLED_PROJECT } from '../../../constants/project';
import { PROJECT_DATA_SET_PROJECT_NAME } from './constants';

export default function projectNameReducer(state = UNTITLED_PROJECT, action) {
  switch (action.type) {
    case PROJECT_DATA_SET_PROJECT_NAME:
      const { projectName } = action.payload;
      return projectName;

    default:
      // Do nothing if we didn't match action type
      return state;
  }
}
