/* eslint-disable no-else-return */
/* eslint-disable no-use-before-define */
// @flow
import React, { PureComponent, type Node as ReactNode } from 'react';
import { connect } from 'react-redux';
import { DragSource } from 'react-dnd';

import { classNames } from '../../../../helpers/strings';
import { smallBreakpoint } from '../../../../helpers/breakpoints';
import Icon from '../../../Presentational/Icon';
import { UI_DRAWER_MODE_EXPANDED, UI_DRAWER_MODE_SHRUNKEN } from '../../../../store/ui/constants';
import { type DrawerMode } from '../../../../types/ui';
import PhotoActions from './PhotoActions';
import Show from '../../../Functional/Show';
import { makeAlbumPhotoIsUsedSelector } from '../../../../store/userPhotos/selectors';
import { galleryImageIsProcessing } from '../../../../store/v2/galleries/selectors';

import {
  setDropTargetsHighlight as _setDropTargetsHighlight,
  setDropTargetsVisible as _setDropTargetsVisible,
  setDrawerMode as _setDrawerMode,
} from '../../../../store/ui/actions';
import { IMAGE_URL_BASE } from '../../../../helpers/images';

const cardSource = {
  beginDrag({ setDropTargetsHighlight, setDropTargetsVisible, albumPhoto, selectPhoto, setDrawerMode, drawerMode }) {
    setDropTargetsHighlight(true);
    setDropTargetsVisible(true);

    // If the drawer is expanded, we'll collapse the drawer so the user can drag to the project surface.
    if (drawerMode === UI_DRAWER_MODE_EXPANDED) {
      /* Running this in a timeout since this function needs to return before the dragged
         image element gets changed in the DOM by the drawer collapsing. */
      setTimeout(() => {
        /* Setting to SHRUNKEN mode to avoid expanding the drawer when a user drags a photo
        from a DEFAULT/unexpanded drawer. This way it only re-expands if a photo was dragged
        from an already expanded drawer */
        setDrawerMode(UI_DRAWER_MODE_SHRUNKEN);
      }, 0);
    }

    return {
      albumPhoto,
      selectPhoto,
    };
  },
  endDrag({ setDropTargetsHighlight, setDropTargetsVisible, setDrawerMode, drawerMode }) {
    setDropTargetsHighlight(false);
    setDropTargetsVisible(false);

    if (drawerMode === UI_DRAWER_MODE_SHRUNKEN) {
      /* Running this in a timeout since this function needs to return before the dragged
         image element gets changed in the DOM by the drawer expanding. */
      setTimeout(() => {
        setDrawerMode(UI_DRAWER_MODE_EXPANDED);
      }, 0);
    }
  },
};

/**
 * dragon drop props to inject into your component.
 */
const collect = (_connect) => ({
  connectDragSource: _connect.dragSource(),
});

const noop = (anything) => anything;

type Props = {
  albumPhoto: Object,
  connectDragSource: (ReactNode) => ReactNode,
  showIcon: boolean,
  connectDrag: boolean,
  onMouseOver: (Event) => void,
  onMouseLeave: (Event) => void,
  selectPhoto: (albumPhoto: Object, layerId: string, pageId: string) => void,
  albumPhotoIsUsed: boolean,
  isProcessing: boolean
};

type State = {
  isHovered: boolean,
};

export class PhotoItem extends PureComponent<Props, State> {
  handleItemHover: (boolean) => void;
  toggleActions: () => void;

  static defaultProps = {
    connectDragSource: noop,
    showIcon: false,
    connectDrag: false,
    onMouseOver: noop,
    onMouseLeave: noop,
    selectPhoto: noop,
    albumPhotoIsUsed: false,
  };

  constructor(props: Props) {
    super(props);

    this.handleItemHover = this.handleItemHover.bind(this);
    this.toggleActions = this.toggleActions.bind(this);

    this.state = {
      isHovered: false,
    };
  }

  handleItemHover(isMouseOver: boolean) {
    smallBreakpoint(() => null)(() => {
      this.setState({ isHovered: isMouseOver });
    });
  }

  toggleActions() {
    smallBreakpoint(() => {
      this.setState({
        isHovered: !this.state.isHovered,
      });
    })(() => null);
  }

  canAddPhotoToProject() {
    const { isProcessing } = this.props;
    return !isProcessing;
  }

  render() {
    const { albumPhoto, connectDragSource, showIcon, connectDrag, onMouseOver, onMouseLeave, selectPhoto, albumPhotoIsUsed, isProcessing } = this.props;
    const { isHovered } = this.state;

    const cssClasses = classNames(
      'AlbumsBrowser__album-button',
      'album-button--photo',
      albumPhotoIsUsed ? 'in-project' : null,
      isHovered ? 'album-button--photo--hovered' : null
    );

    const dragWrapper = connectDrag ? connectDragSource : noop;
    return dragWrapper(
      <div
        style={{
          backgroundImage: `url('${getImageThumbnailUrl(albumPhoto)}')`,
          backgroundSize: 'cover',
        }}
        tabIndex={0}
        className={cssClasses}
        role="button"
        data-testid="album-photo-in-album"
        onMouseEnter={(e) => {
          onMouseOver(e);
          this.handleItemHover(true);
        }}
        onMouseLeave={(e) => {
          onMouseLeave(e);
          this.handleItemHover(false);
        }}
        onClick={this.toggleActions}
        onMouseDown={onMouseLeave}
      >
        <Show when={isHovered && this.canAddPhotoToProject() && !isProcessing}>
          <PhotoActions albumPhoto={albumPhoto} selectPhoto={selectPhoto} />
        </Show>

        <Show when={isProcessing}>
          <div
            style={{
              top: '50%',
              transform: 'translateY(-50%)',
              position: 'relative',
            }}
          >
            <Icon icon="loading" size="medium" color="#fff" animation="rotate" />
          </div>
        </Show>

        <Show when={albumPhotoIsUsed && showIcon}>
          <Icon color="white" className="in-project-icon" iconClassName="" icon="check" fill="white" />
        </Show>
      </div>
    );
  }
}

function getImageThumbnailUrl(photo) {
    if (photo.source === 'google' && photo.thumbURL) return photo.thumbURL;
    return `${IMAGE_URL_BASE}/${photo.createdBy}/${photo.id}_original?width=300&auto=webp`;
}

// This will be called once per instance of this component, when it is mounted, to create a unique memoization cache for each instance.
// See https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances for more details.
const makeMapStateToProps = () => {
  const albumPhotoIsUsedSelector = makeAlbumPhotoIsUsedSelector();

  return (state, props) => ({
    ...props,
    drawerMode: state.ui.drawerMode,
    albumPhotoIsUsed: albumPhotoIsUsedSelector(state, props),
    isProcessing: galleryImageIsProcessing(state, props.albumPhoto.id),
  });
};

const mapDispatchToProps = (dispatch) => ({
  setDropTargetsVisible: (x: boolean) => dispatch(_setDropTargetsVisible(x)),
  setDropTargetsHighlight: (x: boolean) => dispatch(_setDropTargetsHighlight(x)),
  setDrawerMode: (x: DrawerMode) => dispatch(_setDrawerMode(x)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(DragSource('PHOTO', cardSource, collect)(PhotoItem));
