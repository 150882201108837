// @flow

import { optionGet } from '../../helpers/functions';
import { ADD_TO_CART_PROCESSING, CART_FAILURE_STATES } from '../product/constants';

// Watches for notable actions, and creates New Relic events to track them.
const logObserver = (store: { getState: () => Object, dispatch: () => any }) => (next: Object => Object) => (
  (action: { type: string, payload: Object }): Object => {
    switch (action.type) {
      case ADD_TO_CART_PROCESSING: {
        if (action.payload && CART_FAILURE_STATES.includes(action.payload.cartState)) {
          const state = store.getState();

          window.newrelic.addPageAction('Add to Cart Failure', {
            projectId: optionGet('project.id')(state).getOrElseValue('none'),
            userId: optionGet('user.flashId')(state).getOrElseValue('anonymous'),
            sku: optionGet('product.sku')(state).getOrElseValue('none'),
            cartState: action.payload.cartState,
          });
        }

        break;
      }

      default: {
        break;
      }
    }

    return next(action);
  }
);

export default logObserver;
