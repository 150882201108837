// @flow

export const CARD_ENVELOPE_PAGE_ID = 'card_envelope';
export const ENVELOPE_RETURN_ADDRESS_PLACEMENT = 'return';
export const ENVELOPE_RECIPIENT_ADDRESS_PLACEMENT = 'recipient';
export const ENVELOPE_REPLY_ADDRESS_PLACEMENT = 'reply_address';
export const ENVELOPE_NO_ADDRESS_PLACEMENT = 'no_address';
export const ENVELOPE_ADDRESSING_ATTRIBUTE = 'envelope_addressing';
export const RETURN_ONLY_ENVELOPE_DESIGN = 'envelope_addressing_return_only';
export const REPLY_ONLY_ENVELOPE_DESIGN = 'envelope_addressing_reply';
export const RETURN_AND_RECIPIENT_ENVELOPE_DESIGN = 'envelope_addressing_both';
export const NO_ENVELOPE_ADDRESSING_DESIGN = 'envelope_addressing_none';

// V1 Envelope Attribute Codes
export const ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE = 'None';
export const ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH = 'Both Return and Recipient Address ($0.40 per Envelope)';
export const ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY = 'Return Address Only ($0.40 per Envelope)';
export const ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY = 'Reply Address Only ($0.40 per Envelope)';

// V2 Envelope Attribute Codes
export const ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE_V2 = 'none';
export const ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH_V2 = 'both';
export const ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY_V2 = 'return';
export const ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY_V2 = 'reply';

