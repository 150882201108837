import authorizedReducer from './authorized/reducer';
import { combineReducers } from 'redux';
import lockStateReducer from './lockState/reducer';
import pageReducer from './pages/reducer';
import projectIdReducer from './id/reducer';
import projectNameReducer from './name/reducer';
import saveStateReducer from './saveState/reducer';
import uploadStatusReducer from './uploadStatus/reducer';
import { buildCoverTextV3, scrubOldCoverText } from '../../helpers/pages';
import { productNeedsCoverTextV3 } from '../../helpers/product';
import { SET_IS_WDS_PROJECT } from './constants';

const wDSProjectReducer = (state = false, action) => {
  switch (action.type) {
    case SET_IS_WDS_PROJECT: {
      return action.payload
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  pages: pageReducer,
  id: projectIdReducer,
  name: projectNameReducer,
  authorized: authorizedReducer,
  saveState: saveStateReducer,
  uploadStatus: uploadStatusReducer,
  lockState: lockStateReducer,
  wds_project: wDSProjectReducer,
});

export const coverTextV3PostProjectManipulations = [
  scrubOldCoverText,
  buildCoverTextV3,
];

export const manipulateProjectForCoverTextV3 = projectData => coverTextV3PostProjectManipulations.reduce(
  (acc, curr) => ({
    ...acc,
    ...curr(acc),
  }),
  projectData
);

export const manipulateProjectData = (projectData, productData) => (
  productNeedsCoverTextV3(productData)
    ? manipulateProjectForCoverTextV3(projectData)
    : projectData
);

export const projectStateFilter = state => ({
  ...state,
  authorized: null,
  uploadStatus: null,
  saveState: {
    ...state.saveState,
    lastSave: null,
  },
  lockState: null,
});
