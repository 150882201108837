export const getPDFPreview = (flashId, flashToken, projectId, page) => {
  const REQUEST_URL = `${process.env.REACT_APP_PDF_SERVICE_BASE_URL}/proof?projectId=${projectId}&userId=${flashId}&page=${page}`;
  const REQUEST = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      'content-type': 'application/json',
      Authorization: `Bearer ${flashToken}`,
    }),
  };

  return fetch(REQUEST_URL, REQUEST).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    }
    return Promise.reject(response);
  }).catch((error) => {
    window.newrelic.noticeError(error);
    console.warn('Error generating preview');
  });
};

export const emailPDFProof = (flashId, flashToken, projectId, recipientEmail) => {
  const REQUEST_URL = `${process.env.REACT_APP_NISAUBA_BASE_URL}/${flashId}/email` +
    `?projectId=${projectId}` +
    `&emailAddress=${recipientEmail}` +
    '&editorVersion=1' +
    '&forceRegen=1' +
    '&watermark=1';
  const REQUEST = {
    method: 'POST',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${flashToken}`,
    }),
  };

  return fetch(REQUEST_URL, REQUEST).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    }
    return Promise.reject(response);
  }).catch((error) => {
    window.newrelic.noticeError(error);
    console.warn('Error generating preview');
  });
};
