import qs from 'qs';
import has from './has';
import match from './match';
import { nextMonthsDateMonthYearString } from './calendar';
import { CALENDARS } from '../constants/products';

// Determines if a url parameter (param: string) is present
export const isParameterPresent = (param) => {
  const url = window.location.href;
  if (url.indexOf(`?${param}=`) !== -1) {
    return true;
  } else if (url.indexOf(`&${param}=`) !== -1) {
    return true;
  }
  return false;
};

/**
 * Converts spaces in string to +
 * @param  {string} x
 * @return {string}
 */
export const convertSpaces = x => decodeURI(x).replace(/ /g, '+');

export const convertToSpaces = x => x.replace(/\+/g, ' ');

// Converts all attribute values to have spaces instead of '+' characters. For backwards-compatibility with old projects.
export const convertAttrsToSpaces = attrs =>
  Object.entries(attrs).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: convertToSpaces(value),
    }),
    {}
  );

/* Format the attribute parameters object, returning an object containing only attributes
   that are specified in the current product's template. */
export const formatAttributes = (attrs, templateAttrs) =>
  Object.entries(attrs).reduce(
    (formattedAttrs, [attrName, attr]) =>
      (!has(templateAttrs)(attrName)
        ? formattedAttrs
        : {
          ...formattedAttrs,
          [attrName]: attr,
        }),
    {}
  );

export const getDefaultAttributeOptionForCategory = (
  productCategory,
  attrName,
  options
) =>
  match(
    CALENDARS,
    () =>
      (attrName === 'start_month'
        ? nextMonthsDateMonthYearString()
        : options[0].value),
    match.default,
    () => options[0].value
  )(productCategory);

export const disableAttributesIfRestricted = (attributes) => {
  const { attributeRestrictions } = window.productData || {};
  if (!attributeRestrictions) {
    return attributes;
  }
  return attributeRestrictions.reduce((attrs, restriction) => {
    if (
      restriction.disable &&
      restriction.disable.attribute &&
      attrs[restriction.if.attribute] &&
    attrs[restriction.if.attribute] === restriction.if.equals
    ) {
      // eslint-disable-next-line no-param-reassign
      delete attrs[restriction.disable.attribute];
    }
    return attrs;
  }, attributes);
};

// Given an ART attributes object, returns an object of attribute: value pairs for the default (i.e. first) options
export const getDefaultAttributes = templateAttrs => productCategory =>
  (templateAttrs
    ?
    Object.entries(templateAttrs).reduce(
      (defaultAttrs, [attrName, attr]) =>
        (attr.passThrough === true ||
            !attr.options ||
            attr.options.length < 1
          ? defaultAttrs
          : {
            ...defaultAttrs,
            [attrName]: getDefaultAttributeOptionForCategory(
              productCategory,
              attrName,
              attr.options
            ),
          }),
      {}
    )
    : {});

// getQueryParams :: () -> Object
export const getQueryParams = () => {
  const queryString = window.location.search.replace(/^\?/, '');

  const query = decodeURIComponent(queryString);

  const queryParams = query !== '' ? qs.parse(query) : {};

  return queryParams;
};

// Removes the given query paramenter from the url
export const removeUrlQueryParam = (param) => {
  // eslint-disable-next-line
  const [head, tail] = window.location.href.split('?');
  const modifiedTail = tail.split('&').filter(p => !p.startsWith(`${param}=`)).join('&');

  window.history.pushState(null, '', `?${modifiedTail}`);
};
