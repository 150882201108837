// @flow
import { type Dispatch, type GetState } from 'react-redux';

import {
  UI_SET_CURRENT_PAGE,
  UI_SET_TOOL_VISIBILITY,
  UI_UNSET_TOOL_VISIBILITY,
  UI_SET_CROP_REGION_VISIBILITY,
  UI_UNSET_CROP_REGION_VISIBILITY,
  UI_TOGGLE_KABOB_MENU_ON,
  UI_TOGGLE_KABOB_MENU_OFF,
  UI_SET_PREVIEW_VISIBILITY,
  UI_UNSET_PREVIEW_VISIBILITY,
  UI_SET_PROMPT_USER_TO_LOGIN_MODAL,
  UI_SET_CURRENT_ALBUM,
  UI_UNSET_CURRENT_ALBUM,
  UI_SET_UPLOAD_PHOTOS_VISIBILITY,
  UI_UNSET_UPLOAD_PHOTOS_VISIBILITY,
  UI_INSTAGRAM_ALBUM,
  UI_SET_SIDEBAR_EXPANDED,
  UI_SET_SIDEBAR_LOCKED,
  UI_SET_TEXT_EDITOR_VISIBILITY,
  UI_UNSET_TEXT_EDITOR_VISIBILITY,
  UI_CONFIRMATION_SET_PAGE_REMOVAL,
  UI_CONFIRMATION_SET_CROP_RESET,
  UI_RESET_CURRENT_GALLERY_VALUES,
  UI_CONFIRMATION_UNSET,
  UI_SHOW_DROP_TARGETS,
  UI_SHOW_DROP_TARGETS_HIGHLIGHT,
  UI_FONTS_LOADED,
  UI_SET_DRAWER_VISIBILITY,
  UI_SET_DRAWER_MODE,
  UI_SET_FULL_GALLERY_VIEW,
  UI_UNSET_FULL_GALLERY_VIEW,
  UI_SET_TOOLTIP_STATE,
  UI_TOOLTIP_OPENED,
  UI_TOOLTIP_CLOSED,
  UI_SET_TOOLTIP_DATA,
  UI_SET_TOOLTIP_POSITION,
  UI_SET_TOOLTIP_TYPE,
  UI_OPEN_TOOLTIP_WITH_DATA,
  UI_SET_MANAGE_PAGES_VISIBILITY,
  UI_SET_DRAWERGRID_SCROLL_CACHE,
  UI_SET_PARTIAL_SELECTION,
  UI_SET_SURFACE_CENTER_POINT,
  UI_PAGE_SELECTION_SIDE_LEFT,
  UI_PAGE_SELECTION_SIDE_RIGHT,
  UI_SET_PHOTO_SORT_OPERATION,
  UI_SET_PREVIOUS_PAGE,
  UI_SET_SORT_BY,
  UI_SET_SELECTED_LAYER,
  UI_SET_AUTOFILL_IMG_COUNT,
  UI_SET_SCREEN_SIZE,
  UI_SET_ZENDESK_LOADED,
  UI_CONFIRMATION_SET_USER_PAGE_REMOVAL,
  UI_TOGGLE_SKIP_CONFIRM_FOR_STATUS,
  UI_TOGGLE_HIDE_USED_IMAGES,
  UI_SET_USER_INPUT_DATA,
  UI_OPEN_SIDEBAR_TUT,
  UI_CLOSE_SIDEBAR_TUT, SET_APPLY_TO_ALL_TOGGLE, SET_LOADING_APPLY_TO_ALL,
  UI_LAYOUT_DROPDOWN,
  UI_SET_UPDATE_PROJECT_TOOLTIP,
  UI_SET_GALLERY_PAGE_NUMBER,
  UI_LOCAL_GALLERY_HAS_MORE_PHOTOS,
  UI_INC_AUTOFILL_IMG_COUNT
} from './constants';
import { UI_FACEBOOK_ALBUM } from '../facebook/constants';
import { UI_DROPBOX_ALBUM } from '../dropbox/constants';
import { UI_GOOGLE_PHOTOS_ALBUM } from '../googlePhotos/constants';
import {
  setFacebookActiveStatus,
  setFbViewingAlbumStatus,
  setFacebookPhotos,
} from '../facebook/actions';
import { setInstagramActive } from '../instagramPhotos/actions';
import { setDropboxActive } from '../dropbox/actions';
import { setGooglePhotosAccountLinked, setGooglePhotosActive } from '../googlePhotos/actions';
import { getAlbumPhotos } from '../userAlbums/actions';
import { updateAttribute } from '../product/actions';
import { type Option, none } from 'fp-ts/lib/Option';
import { getElementXY } from '../../helpers/DOM';
import { pageHasCompositeDesign, isInsideCoverId } from '../../helpers/pages';
import { productShouldEnableHalfPageSelectionSelector } from '../product/selectors';
import { givenPageSelector } from '../project/pages/selectors';
import { type PhotoSortOp } from '../../types/photo';
import { type DrawerMode } from '../../types/ui';
import { updateUndoableItem, removeUndoableItem } from '../history/actions';
import { removePages } from '../project/pages/actions';
import { sendAnalyticsForUserEditPages } from '../analytics/actions';
import { fetchGalleryImages } from '../v2/galleries/actions';

export const setPreviousPage = (prevPageId: string) => ({
  type: UI_SET_PREVIOUS_PAGE,
  payload: { prevPage: prevPageId },
});

export const setCurrentPageAction = (pageId: string) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  dispatch(setPreviousPage(getState().ui.currentPage));
  dispatch({
    type: UI_SET_CURRENT_PAGE,
    payload: {
      currentPage: pageId,
    },
  });
};

export const setCurrentGalleryPageNumber = (galleryPageNumber: number) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  dispatch({
    type: UI_SET_GALLERY_PAGE_NUMBER,
    payload: { galleryPageNumber}
  });
};

export const setCurrentGallerySortBy = (gallerySortBy: string, currentAlbum: string) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  dispatch(fetchGalleryImages(currentAlbum, gallerySortBy));
  dispatch({
    type: UI_SET_SORT_BY,
    payload: { gallerySortBy }
  });
};


export const resetCurrentGalleryValues = () => (
  dispatch: Dispatch,
  getState: GetState
) => {
  dispatch({type: UI_RESET_CURRENT_GALLERY_VALUES});
};

export const setSurfaceCenterPoint = (centerPoint: number) => ({
  type: UI_SET_SURFACE_CENTER_POINT,
  payload: {
    surfaceCenterPoint: centerPoint,
  },
});

// Sets UI state to having selected to one of left, right or neither side (null)
export const setPartialSelectionAction = (
  side?: UI_PAGE_SELECTION_SIDE_LEFT | UI_PAGE_SELECTION_SIDE_RIGHT
) => ({
  type: UI_SET_PARTIAL_SELECTION,
  payload: {
    partialSelection: side,
  },
});

// Sets UI state to unselect partially selected page
export const unsetPartialSelectionAction = () =>
  setPartialSelectionAction(null);

// Ensures that partialSelection state is synched up with the design on the current page.
export const updatePartialSelection = () => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const state = getState();
  const { ui } = state;
  const { currentPage, partialSelection } = ui;

  if (productShouldEnableHalfPageSelectionSelector(state)) {
    const pageData = givenPageSelector(state)(currentPage);

    if (pageHasCompositeDesign(pageData) && !partialSelection) {
      // If the page has a composite design and no partial selection, set one.
      dispatch(setPartialSelectionAction('left'));
    } else if (!pageHasCompositeDesign(pageData) && partialSelection) {
      // If the page doesn't have a composite design, and there is a partial selection set, unset it.
      dispatch(unsetPartialSelectionAction());
    }
  }
};

export const setCurrentPage = (
  pageId: string,
) => (dispatch: Dispatch, getState: GetState) => {
  if (isInsideCoverId(pageId)) {
    return;
  }

  const state = getState();
  const supportPartials = productShouldEnableHalfPageSelectionSelector(state);
  if (supportPartials) {
    const page = givenPageSelector(state)(pageId);

    if (pageHasCompositeDesign(page)) {
      dispatch(setPartialSelectionAction('left'));
    } else {
      dispatch(unsetPartialSelectionAction());
    }
  }

  dispatch(setCurrentPageAction(pageId));
};

export const setCurrentPageWithEventData = (pageId: string) => (
  event: Event
) => (dispatch: Dispatch, getState: GetState) => {
  if (isInsideCoverId(pageId)) {
    return;
  }
  const state = getState();
  const { ui } = state;
  const { partialSelection, surfaceCenterPoint } = ui;
  if (
    productShouldEnableHalfPageSelectionSelector(state) &&
    partialSelection !== null
  ) {
    const side = event.pageX < surfaceCenterPoint ? 'left' : 'right';
    return dispatch(setPartialSelectionAction(side));
  }

  dispatch(setCurrentPageAction(pageId));
};

export const setFontsLoaded = () => (
  dispatch: Dispatch,
  getState: GetState
) => {
  dispatch({
    type: UI_FONTS_LOADED,
    payload: {
      surface: getState().template.surface,
    },
  });
};

export function showTools() {
  return {
    type: UI_SET_TOOL_VISIBILITY,
  };
}

export function hideTools() {
  return {
    type: UI_UNSET_TOOL_VISIBILITY,
  };
}

export function showCropRegion() {
  return {
    type: UI_SET_CROP_REGION_VISIBILITY,
  };
}

export function hideCropRegion() {
  return {
    type: UI_UNSET_CROP_REGION_VISIBILITY,
  };
}

export function toggleKabobMenu() {
  return (dispatch: Dispatch, getState: GetState) => {
    const menuStatus = getState().ui.menuVisible;
    if (menuStatus) {
      dispatch({
        type: UI_TOGGLE_KABOB_MENU_OFF,
        payload: { menuVisible: false },
      });
    } else {
      dispatch({
        type: UI_TOGGLE_KABOB_MENU_ON,
        payload: { menuVisible: true },
      });
    }
  };
}

export const showPreviewInRoom = () => ({
  type: UI_SET_PREVIEW_VISIBILITY,
});

export const hidePreviewInRoom = () => ({
  type: UI_UNSET_PREVIEW_VISIBILITY,
});

export const showFullGalleryView = () => ({
  type: UI_SET_FULL_GALLERY_VIEW,
});

export const hideFullGalleryView = () => ({
  type: UI_UNSET_FULL_GALLERY_VIEW,
});

export function showPromptUserToSignInModal() {
  const type = UI_SET_PROMPT_USER_TO_LOGIN_MODAL;
  const payload = '';
  const action = { type, payload };
  return action;
}

export const setCurrentAlbum = (currentAlbum: string) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const { facebookActive } = getState().facebook;
  if (currentAlbum !== UI_FACEBOOK_ALBUM && !facebookActive) {
    if (currentAlbum === UI_INSTAGRAM_ALBUM) {
      dispatch(setInstagramActive(true));
    }
    if (currentAlbum === UI_DROPBOX_ALBUM) {
      dispatch(setDropboxActive(true));
    }
    if (currentAlbum === UI_GOOGLE_PHOTOS_ALBUM) {
      dispatch(setGooglePhotosActive(true));
    }
    dispatch({
      type: UI_SET_CURRENT_ALBUM,
      payload: { currentAlbum },
    });
    dispatch(fetchGalleryImages(currentAlbum));
  } else if (facebookActive) {
    dispatch({ type: UI_SET_CURRENT_ALBUM, payload: { currentAlbum } });
    dispatch(setFbViewingAlbumStatus(true));
    dispatch(setFacebookPhotos());
  } else {
    dispatch(setFacebookActiveStatus(true));
  }
};

export const setPhotoSortOperation = (sortOp: PhotoSortOp) => ({
  type: UI_SET_PHOTO_SORT_OPERATION,
  payload: {
    sortOp,
  },
});

export const unsetPhotoSortOperation = {
  type: UI_SET_PHOTO_SORT_OPERATION,
  payload: {
    sortOp: null,
  },
};

export const showInstagramAlbum = () => (dispatch) => {
  dispatch({
    type: UI_SET_CURRENT_ALBUM,
    payload: { currentAlbum: UI_INSTAGRAM_ALBUM },
  });

  dispatch(setInstagramActive(true));
};

export const showGooglePhotosAlbum = () => (dispatch) => {
  dispatch({
    type: UI_SET_CURRENT_ALBUM,
    payload: { currentAlbum: UI_GOOGLE_PHOTOS_ALBUM },
  });

  dispatch(setGooglePhotosActive(true));
  dispatch(setGooglePhotosAccountLinked(true));
};

export const showDropboxAlbum = () => (dispatch) => {
  dispatch({
    type: UI_SET_CURRENT_ALBUM,
    payload: { currentAlbum: UI_DROPBOX_ALBUM },
  });

  dispatch(setDropboxActive(true));
};

export const unsetCurrentAlbum = () => ({
  type: UI_UNSET_CURRENT_ALBUM,
});

export const showUploadPhotosModal = (
  immediateUpload: ?boolean = false,
  title: ?Option<string> = none
) => ({
  type: UI_SET_UPLOAD_PHOTOS_VISIBILITY,
  payload: {
    immediateUpload,
    title,
  },
});

export const hideUploadPhotosModal = () => ({
  type: UI_UNSET_UPLOAD_PHOTOS_VISIBILITY,
});

// Set whether or not the sidebar is expanded.
export const setSidebarExpanded = (sidebarExpanded: boolean) => ({
  type: UI_SET_SIDEBAR_EXPANDED,
  payload: {
    sidebarExpanded,
  },
});

export const setSidebar = () => setSidebarExpanded(true);

export const unsetSidebar = () => setSidebarExpanded(false);

export const openSidebarTut = () => ({
  type: UI_OPEN_SIDEBAR_TUT,
});

export const closeSidebarTut = () => ({
  type: UI_CLOSE_SIDEBAR_TUT,
});

// Set whether or not the sidebar state should be locked, preventing the sidebarExpanded state from being modified.
export const setSidebarLocked = (sidebarLocked: boolean) => ({
  type: UI_SET_SIDEBAR_LOCKED,
  payload: {
    sidebarLocked,
  },
});

export const showTextEditor = pageId => layerId => ({
  type: UI_SET_TEXT_EDITOR_VISIBILITY,
  payload: { pageId, layerId },
});

export const hideTextEditor = () => ({
  type: UI_UNSET_TEXT_EDITOR_VISIBILITY,
});

export const setDropTargetsVisible = x => ({
  type: UI_SHOW_DROP_TARGETS,
  payload: x,
});

export const setDropTargetsHighlight = x => ({
  type: UI_SHOW_DROP_TARGETS_HIGHLIGHT,
  payload: x,
});

// Confirmation
export const confirm = (attribute, undoableItemId) => (dispatch) => {
  dispatch({ type: UI_CONFIRMATION_UNSET });
  dispatch(
    updateUndoableItem(undoableItemId)(() => updateAttribute(attribute))
  );
};

export const cancelConfirmAction = () => ({
  type: UI_CONFIRMATION_UNSET,
});

export const cancelConfirm = (undoableId: ?string = null) => (
  dispatch: Function
) => {
  if (undoableId) {
    dispatch(removeUndoableItem(undoableId));
  }

  dispatch(cancelConfirmAction());
};

export const confirmPageRemoval = attribute => ({
  type: UI_CONFIRMATION_SET_PAGE_REMOVAL,
  payload: { attribute },
});

const displayConfirmUserPageRemovalAction = pageIds => ({
  type: UI_CONFIRMATION_SET_USER_PAGE_REMOVAL,
  payload: { pageIds },
});

export const displayConfirmUserPageRemoval = pageIds => (
  dispatch: Function,
  getState: Function
) => {
  const { ui } = getState();
  const skippedStatuses = ui.confirmation.skipConfirmForStatuses;
  if (skippedStatuses.includes(UI_CONFIRMATION_SET_USER_PAGE_REMOVAL)) {
    dispatch(removePages(pageIds));
  } else {
    dispatch(displayConfirmUserPageRemovalAction(pageIds));
  }
};

export const confirmUserPageRemoval = pageIds => (dispatch: Function) => {
  dispatch(removePages(pageIds));
  dispatch(cancelConfirmAction());
};

export const confirmCropReset = (attribute, undoableItemId) => ({
  type: UI_CONFIRMATION_SET_CROP_RESET,
  payload: { attribute, undoableItemId },
});

// Sets the drawer visibility
export const setDrawerVisibility = (drawerVisible: boolean) => ({
  type: UI_SET_DRAWER_VISIBILITY,
  payload: {
    drawerVisible,
  },
});

export const setDrawerMode = (drawerMode: DrawerMode) => ({
  type: UI_SET_DRAWER_MODE,
  payload: {
    drawerMode,
  },
});

export const toggleSkipConfirmForStatus = (status: string) => ({
  type: UI_TOGGLE_SKIP_CONFIRM_FOR_STATUS,
  payload: {
    status,
  },
});

export const toggleHideImages = (hideUsedImages: boolean) => ({
  type: UI_TOGGLE_HIDE_USED_IMAGES,
  payload: {
    hideUsedImages,
  },
});
/**
 * Tooltip Actions
 */
export const openTooltip = {
  type: UI_SET_TOOLTIP_STATE,
  payload: {
    state: UI_TOOLTIP_OPENED,
  },
};

export const closeTooltip = {
  type: UI_SET_TOOLTIP_STATE,
  payload: {
    state: UI_TOOLTIP_CLOSED,
  },
};

export const setTooltipData = data => ({
  type: UI_SET_TOOLTIP_DATA,
  payload: {
    data,
  },
});

export const setTooltipType = type => ({
  type: UI_SET_TOOLTIP_TYPE,
  payload: {
    type,
  },
});

export const setTooltipPosition = (x, y) => ({
  type: UI_SET_TOOLTIP_POSITION,
  payload: {
    posX: x,
    posY: y,
  },
});

export const openTooltipWithData = type => data => posX => posY => ({
  type: UI_OPEN_TOOLTIP_WITH_DATA,
  payload: {
    type,
    posX,
    posY,
    data,
    state: UI_TOOLTIP_OPENED,
  },
});

const imgPreviewHeight = 15 * 16; // 15rem = height of the img preview container, 16 is the root font size
const padding = 16;
const getXPos = (el, x) => {
  const { offsetWidth, offsetHeight } = el;
  const aspectRatio = offsetWidth / offsetHeight;
  const adjustedWidth = imgPreviewHeight * aspectRatio;
  const viewportWidth = document.documentElement.clientWidth;

  switch (true) {
    // eslint-disable-next-line no-mixed-operators
    case x + offsetWidth / 2 + adjustedWidth / 2 > viewportWidth:
      return viewportWidth - adjustedWidth - padding;
    // eslint-disable-next-line no-mixed-operators
    case x + offsetWidth / 2 < adjustedWidth / 2:
      return padding;
    default:
      // eslint-disable-next-line no-mixed-operators
      return x + (offsetWidth / 2 - adjustedWidth / 2);
  }
};

export const setTooltipImgPreview = type => data => (
  closestEl: string
) => e => (dispatch) => {
  const rootEl = e.target.closest(closestEl);

  if (!rootEl) {
    return;
  }

  const { x, y } = getElementXY(rootEl);

  dispatch(setTooltipType(type));
  dispatch(setTooltipData(data));
  dispatch(
    setTooltipPosition(getXPos(rootEl, x), y - imgPreviewHeight - padding)
  );
  dispatch(openTooltip);
};

export const setTooltipWithEvent = type => data => e => (dispatch) => {
  const button = e.target.closest('button');
  if (!button) {
    return;
  }

  button.focus();
  const { x, y } = getElementXY(button);
  const { offsetWidth, offsetHeight } = button;

  dispatch(setTooltipType(type));
  dispatch(setTooltipData(data));
  // eslint-disable-next-line no-mixed-operators
  dispatch(setTooltipPosition(x + offsetWidth / 2, y - offsetHeight / 2));
  dispatch(openTooltip);
};

export const hideToolTip = (dispatch) => {
  dispatch(setTooltipType(null));
  dispatch(setTooltipData(null));
  dispatch(setTooltipPosition(null, null));
  dispatch(closeTooltip);
};

export const setManagePagesVisibilityAction = payload => ({
  type: UI_SET_MANAGE_PAGES_VISIBILITY,
  payload,
});

export const setManagePagesVisibility = (
  payload,
  sendAnalytics: boolean = false
) => (dispatch: Function, getState: Function) => {
  if (payload === true && sendAnalytics) {
    dispatch(sendAnalyticsForUserEditPages());
  }
  dispatch(setManagePagesVisibilityAction(payload));
};

export const setDrawerGridScrollCache = (key: string, value: number) => ({
  type: UI_SET_DRAWERGRID_SCROLL_CACHE,
  payload: {
    key,
    value,
  },
});

export const setSelectedLayer = (
  layerId: string | null,
  pageId: string | null
) => ({
  type: UI_SET_SELECTED_LAYER,
  payload: {
    selectedLayer: {
      layerId,
      pageId,
    },
  },
});

export const unsetSelectedLayer = () => setSelectedLayer(null, null);

export const setAutoFillImgCount = (payload: number) => ({
  type: UI_SET_AUTOFILL_IMG_COUNT,
  payload,
});

export const incAutoFillImgCount = () => ({
  type: UI_INC_AUTOFILL_IMG_COUNT,
});

export const setScreenSize = (x: {
  innerWidth: number,
  innerHeight: number,
}) => ({
  type: UI_SET_SCREEN_SIZE,
  payload: {
    width: x.innerWidth,
    height: x.innerHeight,
  },
});

export const setZendeskLoaded = () => ({
  type: UI_SET_ZENDESK_LOADED,
});

/**
 * initialProductAction
 */
export const initialProductAction = () => (
  dispatch: (action: any) => void,
  getState: () => any
) => {
  // Use for product specific UI
};

export const setUserInputData = (userInputData: any[]) => ({
  type: UI_SET_USER_INPUT_DATA,
  payload: {
    data: userInputData,
  },
});

export const setAppliedToAllToggle = (appliedToAll: boolean) => ({
  type: SET_APPLY_TO_ALL_TOGGLE,
  payload: appliedToAll,
});

export const setLoadingAppliedToAll = (loadingAppliedAll: boolean) => ({
  type: SET_LOADING_APPLY_TO_ALL,
  payload: loadingAppliedAll,
});

export const setLayoutDropdown = (option: string) => ({
  type: UI_LAYOUT_DROPDOWN,
  payload: option
})

export const setUpdateProjectTooltip = (bool: boolean) => ({
  type: UI_SET_UPDATE_PROJECT_TOOLTIP,
  payload: bool,
})