import {
  PROJECT_STATE_IN_PROGRESS,
  FINAL_SAVE_ADD_TO_CART_REQUEST,
  FINAL_SAVE_ADD_TO_CART_SUCCESS,
  FINAL_SAVE_ADD_TO_CART_FAILURE,
  MANUAL_SAVE,
  SET_NEW_PROJECT_DATA,
  SET_IS_WDS_PROJECT
} from './constants';

import { FAILED_PROJECT_SERVICE_SAVE_VALIDATION } from '../product/constants';
import { CALL_API } from '../middleware/api';
import clonedeep from 'lodash.clonedeep';
import { createProjectForUser, saveProjectAndUnlock } from '../../services/projectApi';
import { storeFilter } from '../storeFilter';
import { uploadLocalPhotos } from '../userPhotos/actions';
import { setAppliedToAllToggle, setCurrentAlbum, setLoadingAppliedToAll } from '../ui/actions';
import { } from '../ui/selectors';
import { UI_TEMPORARY_ALBUM } from '../addPhotos/constants';
import { validateAndAddToCart } from '../product/actions';
import { applyTemplateToPages, applyPhotoToLayer, applyDesignToPage, setLayerData, removePhotoFromLayer } from './pages/actions';
import { projectPagesSelector } from './pages/selectors';
import { getTemplate } from '../../helpers/templates';
import { PROJECT_SAVE_SUCCESS } from './pages/constants';
import { sendAnalyticsForProjectConversion } from '../analytics/actions';
import { optionGet } from '../../helpers/functions';
import { getAllPhotos } from '../userPhotos/selectors';
import photo from '../../types/photo';

const FINAL_SAVE_REQUEST_TYPES = [
  FINAL_SAVE_ADD_TO_CART_REQUEST,
  FINAL_SAVE_ADD_TO_CART_SUCCESS,
  FINAL_SAVE_ADD_TO_CART_FAILURE,
];

export const manualSave = () => ({
  type: MANUAL_SAVE,
});

export const saveProject = () => (dispatch, getState) => {
  if (getState().user.authorized) {
    dispatch(manualSave());
  }
};

export const setNewProjectData = project => ({
  type: SET_NEW_PROJECT_DATA,
  payload: {
    project,
  },
});

export const createProjectAndUploadPhotos = () => (dispatch, getState) => {
  const { user } = getState();
  createProjectForUser(user.flashId, user.flashToken).then((json) => {
    dispatch(sendAnalyticsForProjectConversion({
      anonProjectId: '{state.project.id}',
      projectId: optionGet('projectId')(json).getOrElseValue(''),
      newProjectId: optionGet('projectId')(json).getOrElseValue(''),
    }));
    dispatch(setNewProjectData(json));
    dispatch(manualSave());
    dispatch(uploadLocalPhotos());
    if (getState().ui.currentAlbum === UI_TEMPORARY_ALBUM) {
      dispatch(setCurrentAlbum('My Photos'));
    }
    if (window.history && window.history.pushState) {
      const updatedProjectUrl =
        `${window.origin}${window.location.pathname}?projectId=${json.projectId}&flashId=${user.flashId}&flashToken=${user.flashToken}`;
      window.history.replaceState({ path: updatedProjectUrl }, '', updatedProjectUrl);
    }
  }).catch((e) => {
    window.newrelic.noticeError(e);
  });
};

export const saveProjectBeforeAddToCart = () => (dispatch, getState) => {
  const body = {};
  body.projectId = getState().project.id;
  body.user = getState().user.flashId;

  const cleanState = storeFilter(clonedeep(getState()));

  body.projectData = JSON.stringify({ data: cleanState });

  return dispatch({
    [CALL_API]: {
      url: `${process.env
        .REACT_APP_PROJECT_SERVICE_BASE_URL}/project/${getState().project
          .id}?user=${getState().user.flashId}`,
      options: {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${getState().user.flashToken}`,
        },
        body: JSON.stringify(body),
      },
      types: FINAL_SAVE_REQUEST_TYPES,
    },
  }).then(() => (dispatch(validateAndAddToCart(FAILED_PROJECT_SERVICE_SAVE_VALIDATION))));
};

export function forceSaveProject(state) {
  const body = {};
  body.projectId = state.project.id;
  body.user = state.user.flashId;

  const cleanState = storeFilter(clonedeep(state));

  body.projectData = JSON.stringify({ data: cleanState });

  return fetch(`${process.env.REACT_APP_PROJECT_SERVICE_BASE_URL}/project/${state.project.id}?user=${state.user.flashId}&overrideLock=true`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${state.user.flashToken}`,
    },
    body: JSON.stringify(body),
  });
}

export const adminForceSave = () => (dispatch, getState) => {
  const state = getState();
  const body = {};
  body.projectId = state.project.id;
  body.user = state.user.flashId;
  body.state = PROJECT_STATE_IN_PROGRESS;

  const cleanState = storeFilter(clonedeep(getState()));

  body.projectData = JSON.stringify({ data: cleanState });

  saveProjectAndUnlock(state.project.id, state.user.flashId, state.user.flashToken, body).then(() => {
    dispatch({
      type: PROJECT_SAVE_SUCCESS,
      payload: {
        modifiedAt: Date.now(),
      },
    });
    window.close();

    // eslint-disable-next-line
    alert('Save succeeded, you may close this window.');
  }).catch((e) => {
    throw e;
  });
};

export const adminUpdateTemplate = () => (dispatch, getState) => {
  const { userPhotos, ui, product } = getState();
  const { attributes } = product;

  const template = getTemplate(window.productData)(attributes);

  dispatch(applyTemplateToPages(
    template,
    ui.editorFontsLoaded,
    attributes,
    userPhotos,
    window.productData.category,
  ));
};

export const enableAddToAll = (checked, photoId) => async (dispatch, getState) => {
  const state = getState();
  const allPages = projectPagesSelector(state);
  const firstPage = allPages[0];
  const firstPageLayer = firstPage.layers[0];
  const firstPageDesign = firstPage.surface.design;
  const allPhotos = getAllPhotos(state);
  const constructedPhoto = allPhotos.find(photoObj => photo.extract(photoObj).id === photoId);
  const applyAll = async () => {
    dispatch(setAppliedToAllToggle(true));
    dispatch(setLoadingAppliedToAll(true));
    allPages.slice(1, allPages.length).forEach((page) => {
      const layerId = page.layers[0].id;
      if (optionGet('layers[0].id')(page).getOrElseValue(false)) {
        dispatch(applyPhotoToLayer(constructedPhoto, page.id, layerId)).then(() => {
          dispatch(setLayerData(page.id, layerId, firstPageLayer.data));
          dispatch(applyDesignToPage(firstPageDesign, page.id));
        });
      }
    });
    dispatch(setLoadingAppliedToAll(false));
  };
  const removeAll = () => {
    dispatch(setAppliedToAllToggle(false));
    allPages.slice(1, allPages.length).forEach((page) => {
      const layerId = page.layers[0].id;
      if (optionGet('layers[0].id')(page).getOrElseValue(false)) {
        dispatch(removePhotoFromLayer(page.id, layerId));
      }
    });
  };
  if (checked) {
    await applyAll();
  } else {
    removeAll();
  }
};

export const adminSetIsWDSProject = (isWDSProject) => (dispatch, getState) => {
  dispatch({
    type: SET_IS_WDS_PROJECT,
    payload: isWDSProject
  })
}
