import instagramPhotosReducer from './instagramPhotos/reducer';
import facebookReducer from './facebook/reducer';
import dropboxReducer from './dropbox/reducer';
import googlePhotoReducer from './googlePhotos/reducer';
import addPhotosReducer from './addPhotos/reducer';
import { NotificationReducer } from './notifications/reducer';
import productReducer from './product/reducer';
import projectReducer from './project/reducer';
import templateReducer from './template/reducer';
import uiReducer from './ui/reducer';
import userAuthenticationReducer from './userAuthentication/reducer';
import userPhotosReducer from './userPhotos/reducer';
import userAlbumsReducer from './userAlbums/reducer';
import userReducer from './user/reducer';
import { envelopeAddressingReducer } from './envelopeAddressing/reducer';
import historyReducer from './history/reducer';
import zendeskReducer from './zendesk/reducer';
import galleriesReducer from './v2/galleries/reducer';

export default {
  addPhotos: addPhotosReducer,
  instagramPhotos: instagramPhotosReducer,
  dropbox: dropboxReducer,
  facebook: facebookReducer,
  googlePhotos: googlePhotoReducer,
  product: productReducer,
  template: templateReducer,
  project: projectReducer,
  userPhotos: userPhotosReducer,
  userAlbums: userAlbumsReducer,
  galleries: galleriesReducer,
  ui: uiReducer,
  user: userReducer,
  userAuthentication: userAuthenticationReducer,
  notifications: NotificationReducer,
  envelopeAddressing: envelopeAddressingReducer,
  history: historyReducer,
  zendesk: zendeskReducer,
};
