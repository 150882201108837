// @flow

import { compose } from 'fp-ts/lib/function';
import map from 'lodash.map';
import intersection from 'lodash.intersection';
import { option } from 'fp-ts';
import { type Option, some } from 'fp-ts/lib/Option';
import { head, index, take, findIndex } from 'fp-ts/lib/Array';
import uuid4 from 'uuid/v4';

import has from '../has';
import contained from '../contained';
import {
  elementExists,
  withPropValue,
  splice,
  removeFromEnd,
  applyActionsToArray,
  withId,
  changeElementWithId,
  changeArrayElement,
} from '../arrays';
import { prop, chain, optionFind, optionGet, throwWhen } from '../functions';
import { clamp, floor } from '../numbers';
import {
  canManipulatePages,
  shouldHaveCover,
  shouldShowInsideCovers,
  shouldShowTwoUp,
  shouldAddEnvelope,
  shouldAddMaskPage,
  shouldShowEndsheets,
  isSpreadBook,
} from '../product';
import { PARTIAL, FULL } from '../../constants/designs';
import { SHIFTED_LAYER_INDICATOR, EDITABLE_TEXT } from '../../constants/layers';
import { Page, V3_ALIGNMENT, V3_FONT, V3_FONT_SIZE, V3_POSITION, V3_TEXT } from '../../types/page';
import {
  type Layer,
  type Category,
  type Design,
  type Template,
  type Surface,
  type TemplateSize,
  type DesignGroup,
  spineTags,
} from '../../types/templates';
import { __PRODUCTION__ } from '../constants';
import {
  CARD_ENVELOPE_PAGE_ID,
  ENVELOPE_ADDRESSING_ATTRIBUTE,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY,
  RETURN_ONLY_ENVELOPE_DESIGN,
  RETURN_AND_RECIPIENT_ENVELOPE_DESIGN,
  NO_ENVELOPE_ADDRESSING_DESIGN,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY,
  REPLY_ONLY_ENVELOPE_DESIGN,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY_V2,
  ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE_V2
} from '../../constants/envelopes';
import { MASK_ID } from '../../constants/mask';
import { PAGE_ID_COVER } from '../../constants/project';
import { type EnvelopeAddressingValue } from '../../types/address';
import { CATEGORIES_PAGE_COUNT_MATCH_QUANTITY, WOODEN_BOX_PAGE_ID } from '../../constants/products';
import { createDynamicLayer, layerIsEditableTextAndNotSpine, makeEditableTextLayerUpdates } from '../layers';
import type { CoverTextV3 } from '../../types/page';
import { getLayflatXmlFont, getLayflatXmlFontSize, getStyledLayerContent } from '../tags';

export const INSIDE_COVER_ID = 'inside_cover';
export const BACK_INSIDE_COVER_ID = 'back_inside_cover';
export const FRONT_ENDSHEET_ID = 'front_endsheet';
export const BACK_ENDSHEET_ID = 'back_endsheet';


/* Provided with an array of designs {array}, the number of total pages {number}, and a pageIndex {1-indexed int},
   returns an array of designs that are allowed for the given pageIndex */
export const getDesignsForPage = (designs: Array<Design>, pageCountWithCover: number, pageIndex: number, pageCountWithoutCover: number) => (
  designs.filter((design: Design) => (
    (has(design)('allowedPages') ? contained(design.allowedPages, 1, pageCountWithCover)(pageIndex) : true) &&
    (has(design)('allowedPageCounts') ? contained(design.allowedPageCounts, 1, 10000000)(pageCountWithoutCover) : true)
  ))
);

export const getAvailableDesignsInGroup = (designs: Array<String>) => (group: DesignGroup) => intersection(group.designs, designs);

export const getDesignGroupsForPage = (availableDesigns: Array<Design>, groups: Array<DesignGroup>) => {
  const designIds = map(availableDesigns, 'id');
  return groups
    .filter(g => getAvailableDesignsInGroup(designIds)(g).length)
    .map(g => ({
      ...g,
      designs: [
        ...availableDesigns.filter(d => getAvailableDesignsInGroup(designIds)(g).includes(d.id)),
      ],
    }));
};

// layerPathMatches :: String -> String -> ...String -> Boolean
export const layerPathMatches = (pageId: string) => (layerId: string) => (...paths: Array<String>) => (
  elementExists(path => path[0] === pageId && path[1] === layerId)(paths)
);

export const getSurfaceForDesign = (design: Design, template: Template): Surface =>
  prop('surfaceId')(design)
    .chain(() => prop('additionalSurfaces')(template))
    .chain(withPropValue('id')(design.surfaceId))
    .getOrElseValue(template.surface);

export const getSizeForDesign = (design: Design, template: Template): TemplateSize =>
  prop('sizeId')(design)
    .chain(() => prop('additionalSizes')(template))
    .chain(withPropValue('id')(design.sizeId))
    .getOrElseValue(template.size);

export const getPageCountByQuantity = (category: string, qty?: number) => {
  if (!CATEGORIES_PAGE_COUNT_MATCH_QUANTITY.includes(category) || !qty) {
    return option.none;
  }
  return option.some(qty);
};

/* Returns the default page count from a given template, plus one to account for a cover, if applicable.
   Optionally takes a productData and an attributes Object, and searches for the presence of an attribute that
   is meant to set the pageCount of the project, using it if found. */
export const getDefaultPageCountWithCover = (category: string, productData: { attributes?: Object }) => (
  (template: Template, attrs: Object, qty?: number): number => (
    option.fromPredicate(pd => (typeof pd !== 'undefined' && typeof attrs !== 'undefined'))(productData)
      .chain(prop('attributes'))
      .chain((pdAttrs: Object) => (
        optionFind(([key, attrObj]: [string, any]) => (
          Object.keys((attrs: Object)).includes(key) && attrObj.sets === 'pageCount'
        ), Object.entries(pdAttrs))
      ))
      .chain(([key]: [string, any]) => prop(key)(attrs))
      .map(str => parseInt(str, 10) + (shouldHaveCover(category) ? 1 : 0))
      .alt(getPageCountByQuantity(category, qty))
      .getOrElseValue(template.pages.min + (shouldHaveCover(category) ? 1 : 0))
  )
);

/* Takes a template and a desired number of pages, and returns the value of the
   desired pages argument clamped by the template's specified max and min page numbers. */
export const getPageCount = (template: Template) => (desiredPages: number): number => (
  clamp(template.pages.min, template.pages.max)(desiredPages)
);

/* Takes a template, an optional category string, and a desired number of pages including the cover, and
   returns the clamped value of the desired pages argument, plus 1 if the category should have a cover. */
export const getPageCountWithCover = (template: Template) => (category: ?string) => (desiredPagesWithCover: number): number => {
  const coverOffset = shouldHaveCover(category) ? 1 : 0;
  return clamp(
    template.pages.min + coverOffset,
    template.pages.max + coverOffset,
  )(
    desiredPagesWithCover
  );
};

/* Takes a category string and the current pageCount including the cover, and returns the
   adjusted pageCount, so that it is not including any present cover. */
export const getPageCountMinusCover = (category: ?string) => (pageCountWithCover: number): number => (
  floor(0)(pageCountWithCover - (shouldHaveCover(category) ? 1 : 0))
);

export const isPageId = (pageId: string) => (page: Page): boolean => page.id === pageId;

export const isCoverPage = isPageId(PAGE_ID_COVER);

export const getPage = (pageId: string) => (projectData): Page|undefined => option.fromNullable(projectData.pages)
  .map(
    (pages: Array<Page>) => pages.filter((page: Page) => isPageId(pageId)(page))
  )
  .getOrElse([])
  .pop();

export const getCoverPage = getPage(PAGE_ID_COVER);

export const generatePageId = (pageIndex: number, category: Category): string => (
  pageIndex === 1 && shouldHaveCover(category) ? 'cover' : `page_${getPageCountMinusCover(category)(pageIndex)}`
);

const convertPageToInsideCover = (page: Page, pageId: string): Page => ({
  ...page,
  id: pageId,
  uuid: uuid4(),
  name: 'Inside Cover',
  isEditable: false,
  layers: [],
});

const convertPageToEndsheet = (page: Page, pageId: string): Page => ({
  ...page,
  id: pageId,
  uuid: uuid4(),
  name: 'Endsheet',
  layers: [],
});

export const getInternalPageCount = pages => pages.filter(p => p[0].id.match(/page_(.*)/)).length;

export const isInsideCoverId = (pageId: string): boolean => pageId === INSIDE_COVER_ID || pageId === BACK_INSIDE_COVER_ID;
export const isFrontInsideCover = (pageId: string): boolean => pageId === INSIDE_COVER_ID;
export const isEndsheetId = (pageId: string): boolean => pageId === FRONT_ENDSHEET_ID || pageId === BACK_ENDSHEET_ID;
export const isInsideCover = (page: Page): boolean => isInsideCoverId(page.id);
export const isNotEndsheet = (page: Page): boolean => !isEndsheetId(page.id);
export const isNotInsideCover = (page: Page): boolean => !isInsideCover(page);
export const isBackInsideCover = (page: Page): boolean => page.id === BACK_INSIDE_COVER_ID;
export const isEnvelope = (page: Page): boolean => page.id === CARD_ENVELOPE_PAGE_ID;
export const isNotEnvelope = (page: Page): boolean => !isEnvelope(page);
export const isMaskPage = (page: Page): boolean => page.id === MASK_ID;
export const isNotMaskPage = (page: Page): boolean => !isMaskPage(page);
export const isWoodenBoxPage = (page: Page): boolean => page.id === WOODEN_BOX_PAGE_ID;
export const isNotWoodenBoxPage = (page: Page): boolean => !isWoodenBoxPage(page);


// Adds inside covers (back and front) to the pages array when a category needs it.
export const insertHiddenPagesForBooks = (pages: Array<Page>, category: ?string): Array<Page> => {
  // only enforce shouldShowInsideCovers(category) call if the optional category was supplied
  // this is for backwards compatibility with some functions that still call this
  if (option.fromNullable(category).isNone() || shouldShowInsideCovers(category) || shouldShowEndsheets(category)) {
    const referencePage = pages[1];
    const pagesWithInsideCover = splice(pages, 1, 0, convertPageToInsideCover(referencePage, INSIDE_COVER_ID));

    return splice(pagesWithInsideCover, pagesWithInsideCover.length, 0, convertPageToInsideCover(referencePage, BACK_INSIDE_COVER_ID));
  }
  return pages;
};

export const createEditableInsideCovers = (pages: Array<Page>): Array<Page> => {
  if (pages[pages?.length - 1] && pages[pages?.length - 1].name === "Back Inside Cover") { // page names have already been adjusted for editable inside-covers
    return pages;
  }
  let newPages = [...pages];
  
  newPages[1].name = "Inside Cover";
  newPages[1].isEditable = true;
  newPages[newPages?.length - 1].name = "Back Inside Cover";

  for (let i = 2; i < newPages.length - 1; i++) {
    newPages[i].name = "Page " + (i - 1);
  }

  return newPages;
}

export const collapseInteriorPages = (isSpreadBook: boolean) => (pages: Array<Page>): Array<Page> => {
  if (isSpreadBook) {
    return collapseSpreadBooksInteriors(pages);
  };

  const newPagesWithCollapsedInterior = pages.map((page, i) => {
    // 0: cover, 1: inside-cover, 2: right side of first spread, 3: first collapsed Interior page, ... ,
    // 25: last collapsed interior page, 26: left side of last spread, 27: back inside-cover

    if (i === 3 || i === 4) {
      page.name = "Interior Pages";
      page.unselectable = true;
    }

    if (i === 2 || i === (pages.length - 2)) { // right side of 1st spread and left of last spread should be visible, not selectable
      return {
        ...page,
        unselectable: true
      }
    }

    if (i > 4 && i < pages.length - 2) {
      // if (i != pages.length - 1) {
      //   page.unselectable = true;
      // }
      return {
        ...page,
        collapsedInteriorPage: true,
        unselectable: true,
      }
    }

    return page;
  })

  return newPagesWithCollapsedInterior;
}

const collapseSpreadBooksInteriors = (pages) => {
  const newPagesWithCollapsedInterior = pages.map((page, i) => {
    // 0: cover, 1: first spread, 2: collapsed Interior pages, 24: last spread

    if (i === 2) {
      page.name = "Interior Pages";
      page.unselectable = true;
    }

    if (i > 2 && i < pages.length - 1) {
      return {
        ...page,
        collapsedInteriorPage: true,
        unselectable: true,
      }
    }

    return page;
  })

  return newPagesWithCollapsedInterior;
}

export const findEndsheetPage = pages => pages.filter(p => p.id === FRONT_ENDSHEET_ID).shift();

export const insertEndsheetsForBooks = (pages: Array<Page>, category: ?string): Array<Page> => {
  // only enforce shouldShowInsideCovers(category) call if the optional category was supplied
  // this is for backwards compatibility with some functions that still call this
  // eslint-disable-next-line no-use-before-define
  const hasWoodenLid = findWoodenBoxPage(pages);
  const hasEndsheet = findEndsheetPage(pages);

  if (!hasEndsheet && (option.fromNullable(category).isNone() || shouldShowEndsheets(category))) {
    const referencePage = pages[1];
    const pagesWithInsideCover = splice(pages, 1, 0, convertPageToEndsheet(referencePage, FRONT_ENDSHEET_ID));

    const totalWithWoodenLid = hasWoodenLid ? pagesWithInsideCover.length - 1 : pagesWithInsideCover.length;

    return splice(pagesWithInsideCover, totalWithWoodenLid, 0, convertPageToEndsheet(referencePage, BACK_ENDSHEET_ID));
  }
  return pages;
};

export const selectEnvelopeDesignByAttribute = (
  envelopeAddressingAttribute: EnvelopeAddressingValue,
  designs: Array<Design> = []
) => {
  switch (envelopeAddressingAttribute) {
    case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH:
    case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_BOTH_V2:
      return designs.filter(x => x.id === RETURN_AND_RECIPIENT_ENVELOPE_DESIGN)[0];
    case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY:
    case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_RETURN_ONLY_V2:
      return designs.filter(x => x.id === RETURN_ONLY_ENVELOPE_DESIGN)[0];
    case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY:
    case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_REPLY_V2:
      return designs.filter(x => x.id === REPLY_ONLY_ENVELOPE_DESIGN)[0];
    case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE:
    case ENVELOPE_ADDRESSING_ATTRIBUTE_VALUE_NONE_V2:
      return designs.filter(x => x.id === NO_ENVELOPE_ADDRESSING_DESIGN)[0];
    default:
      return designs[0];
  }
};

const createEnvelopePage = (attributes: { envelope_addressing: string }, template: Template) => {
  if (process.env.NODE_ENV !== __PRODUCTION__) {
    throwWhen('Template is missing envelope data')((x: Template) => !x.envelope)(template);
  }
  const envelopeDesign = selectEnvelopeDesignByAttribute(attributes[ENVELOPE_ADDRESSING_ATTRIBUTE], template.envelope.designs);
  return {
    id: CARD_ENVELOPE_PAGE_ID,
    name: 'Envelope',
    surface: {
      ...template.envelope.surface,
      design: envelopeDesign,
    },
    layers: envelopeDesign.layers
      .map(layerId => (
        template.envelope.layers.find(layer => layer.id === layerId)
      ))
      .filter(layer => layer !== undefined),
  };
};

const createMaskPage = (template: Template) => {
  if (process.env.NODE_ENV !== __PRODUCTION__) {
    throwWhen('Template is missing mask data')((x: Template) => !x.mask)(template);
  }
  return {
    id: MASK_ID,
    name: 'DIE-CUT COVER',
    surface: {
      ...template.mask.surface,
      design: template.mask.designs[0],
    },
    layers: template.mask.designs[0].layers
      .map(layerId => (
        template.mask.layers.find(layer => layer.id === layerId)
      ))
      .filter(layer => layer !== undefined),
  };
};

const createWoodenBoxPage = (template: Template, attrs) => {
  if (process.env.NODE_ENV !== __PRODUCTION__) {
    throwWhen('Template is missing wooden box data')(
      (x: Template) => !x.woodenBox
    )(template);
  }

  /*
    This essentially creates a layer.data object from a layers `defaultData` object,
    and then creates a `dynamicLayer` (aka a text layer that derives its color from the current foil color)
  */
  const updatedLayers = makeEditableTextLayerUpdates(
    true,
    attrs,
    template.woodenBox.surface
  )([])(template.woodenBox.layers).map(x => createDynamicLayer(x)(null, attrs));

  return {
    uuid: uuid4(),
    id: WOODEN_BOX_PAGE_ID,
    name: 'Box Lid',
    surface: {
      ...template.woodenBox.surface,
      design: template.woodenBox.design,
    },
    layers: updatedLayers,
  };
};

const insertEnvelopesForCards = (template: Template, attributes?: {}) => (pages: Array<Page>) => ([
  ...pages,
  createEnvelopePage(attributes, template),
]);

const insertMaskPageForFoldedCards = (template: Template) => (pages:Array<Page>) => ([
  ...pages,
  createMaskPage(template),
]);

const insertWoodenBoxPage = (template: Template, attrs) => (pages:Array<Page>) => ([
  ...pages,
  createWoodenBoxPage(template, attrs),
]);

export const modifyInitialPagesByCategory = (
  pages: Array<Page>,
  category: ?string,
  template: Template,
  attributes: {} = {},
  shouldHaveWoodenBox: boolean,
): Array<Page> => applyActionsToArray([
  [
    !template?.insideCoversAreEditable && shouldShowInsideCovers(category),
    insertHiddenPagesForBooks,
  ],
  [
    shouldShowEndsheets(category),
    insertEndsheetsForBooks,
  ],
  [
    shouldAddMaskPage(template),
    insertMaskPageForFoldedCards(template),
  ],
  [
    shouldHaveWoodenBox,
    insertWoodenBoxPage(template, attributes),
  ],
  [
    template?.insideCoversAreEditable,
    createEditableInsideCovers,
  ],
  [
    template?.collapsedInteriorPages,
    collapseInteriorPages(isSpreadBook(category)),
  ],
  [
    (
      shouldAddEnvelope(category)
      && prop('envelope')(template).getOrElseValue(false) !== false
    ),
    insertEnvelopesForCards(template, attributes),
  ],
])(pages);

export const stripHiddenPages = (pages: Array<Page>): Array<Page> =>
  pages
    .filter(isNotInsideCover)
    .filter(isNotEnvelope)
    .filter(isNotMaskPage)
    .filter(isNotWoodenBoxPage)
    .filter(isNotEndsheet);

// returns the current page (if it still exists) after page removals
export const currentPageAfterRemoved = (pages: Page[], pageIds: string[], currentPage: String): Page[] =>
  removeFromEnd(pageIds.length)(stripHiddenPages(pages))
    .filter(x => x.id === currentPage);

// gets the last page id after pages are removed
export const newLastPage = (pages: Page[], pageIds: string[]): Option<string> =>
  compose(chain(prop('id')), head, removeFromEnd(pageIds.length))(stripHiddenPages(pages));

type GroupedPages = Array<Page>;

export const groupPages = (category: Category) => (pages: Array<Page>): Array<GroupedPages> => pages
  .reduce((acc: Array<GroupedPages>, page: Page, i: number): Array<GroupedPages> => {
    const prevPage = index(acc.length - 1)(acc).chain(head).toNullable();

    if (shouldHaveCover(category) && (i === 0)) {
      return [...acc, [page]];
    }

    if (shouldShowTwoUp(category) && (i % 2 === 0)) {
      return [
        ...(take(acc.length - 1)(acc)),
        [prevPage, page],
      ];
    }

    return [...acc, [page]];
  }, []);

// Checks if the given page's id contains the string 'cover'
export const containsCover = (page: Page): boolean => page.id.includes('cover');

// Checks if any of the given Pages are covers.
export const anyCover = (pages: Array<Page>): boolean => pages.some(containsCover);

// Checks if the given page's id is the WOODEN_BOX_PAGE_ID
export const containsWoodenBox = (page: Page): boolean => page.id === WOODEN_BOX_PAGE_ID;

// Checks if any of the given Pages are a wooden box.
export const anyWoodenBox = (pages: Array<Page>): boolean => pages.some(containsWoodenBox);

// Checks if the given page's id is the WOODEN_BOX_PAGE_ID
export const containsEndsheet = (page: Page): boolean => page.id === FRONT_ENDSHEET_ID || page.id === BACK_ENDSHEET_ID;

// Checks if any of the given Pages are an endsheet
export const anyEndsheet = (pages: Array<Page>): boolean => pages.some(containsEndsheet);

/* Returns the number of pages to delete when removing pages from a project with the given product category,
   and to add when adding pages to a project. */
export const pagesPerOperation = (category: Category): number => (
  shouldShowTwoUp(category) ? 2 : 1
);

// Predicate indicating whether or not the user should be able to delete pages from the project.
export const canDelete = (minPages: number, visiblePageCountMinusCover: number, category: string, pages: Page[]): boolean => (
  visiblePageCountMinusCover >= (minPages + pagesPerOperation(category)) &&
  !anyCover(pages) &&
  !anyWoodenBox(pages) &&
  !anyEndsheet(pages) &&
  canManipulatePages(category)
);

// Predicate indicating whether or not the user should be able to move the given pages.
export const canMove = (category: Category, pages: Array<Page>): boolean => (
  !anyCover(pages) &&
  !anyWoodenBox(pages) &&
  !anyEndsheet(pages) &&
  canManipulatePages(category)
);

// PARTIAL DESIGN SUPPORT

// Predicate that, given a Design, returns whether or not the Design is a partial Design
export const designIsPartial = (design?: Design): boolean => (
  prop('coverage')(design)
    .map(coverage => coverage === PARTIAL)
    .getOrElseValue(false)
);

// Predicate that, given a Design, returns whether or not the Design is a composite Design.
export const designIsComposite = (design?: Design): boolean => (
  prop('isComposite')(design)
    .map(isComposite => isComposite === true)
    .getOrElseValue(false)
);

/* Predicate that, given a Page, returns whether or not the Page has a
   compositeDesign (i.e. a Design comprised of one or more partial Designs) */
export const pageHasCompositeDesign = (page: Page): boolean => (
  optionGet('surface.design')(page)
    .map(designIsComposite)
    .getOrElseValue(false)
);

/* Given the surface width and bleed - in inches - and the percentage-based x position of a layer, returns the x
   position shifted half-way across the surface, accounting for bleed if necessary. */
export const calculateShiftedX = (surfaceW: number, bleed: number) => (x: number): number => {
  const xIn = x * surfaceW;
  return ((xIn + (surfaceW / 2))) / surfaceW;
};

/* Takes an array of all pre-defined Layers, along with a Design. Returns an array of the layers specified in the design,
   modified to have different IDs and x positions that have been shifted to the right by one-half of the surface. */
export const shiftDesignLayersRight = (templateLayers: Array<Layer>) => (surface: Surface) => (design: Design): Array<Layer> => (
  prop('layers')(design)
    .map((layerIds: Array<string>) => (
      layerIds.map((layerId: string): Layer => withId(layerId)(templateLayers))
    ))
    .map((layers: Array<Layer>) => (
      layers.map((layer: Layer): Layer => (
        {
          ...layer,
          x: calculateShiftedX(surface.width, surface.bleed)(layer.x),
          id: `${layer.id}${SHIFTED_LAYER_INDICATOR}`,
        }
      ))
    ))
    .getOrElseValue([])
);

/* Creates a composite design from the given left and right partial designs. Returns a tuple containing the
   composite design and an array of Layer objects that have been shifted to the right, if any. */
export const createCompositeDesign = (templateLayers: Array<Layer>, surface: Surface) => (
  (leftDesign: Design) => (rightDesign?: Design): [Design, Array<Layer>] => {
    // Get an array of all of the layers in the right design (if present), shifted to the right half of the surface.
    const shiftedLayers: Array<Layer> = option.fromNullable(rightDesign)
      .map(shiftDesignLayersRight(templateLayers)(surface))
      .getOrElseValue([]);

    // Get an array of the partial design ids
    const partialDesignIds: Array<string> = option.fromNullable(rightDesign)
      .chain(prop('id'))
      .map(rid => [leftDesign.id, rid])
      .getOrElseValue([leftDesign.id]);

    return [
      {
        ...leftDesign,
        id: `COMPOSITE_DESIGN--LEFT-${leftDesign.id}--RIGHT-${rightDesign ? rightDesign.id : 'NO_DESIGN'}`,
        coverage: FULL,
        isComposite: true,
        layers: [
          ...leftDesign.layers,
          ...shiftedLayers.map((layer: Layer): string => layer.id),
        ],
        partialDesignIds,
      },
      shiftedLayers,
    ];
  }
);

// Paging
const isTwoUp = (category: Category) => shouldShowTwoUp(category);
export const isTwoUpNotFirstPage = (cat: Category) => (pageId: string): boolean => isTwoUp(cat) && pageId !== 'cover';
export const evenPagesAfterCover = (cat: Category) => (numberOfPages: number): boolean => isTwoUp(cat) && ((numberOfPages - 1) % 2 === 0);
const isEven = x => is => isNot => (x % 2 === 0 ? is : isNot);
const getPrevPageIndex = x => x - (isEven(x)(2)(1));
const getNextPageIndex = x => x + (isEven(x)(1)(2));
const getPageIndexByDirection = direction => x => (direction === 'next' ? getNextPageIndex(x) : getPrevPageIndex(x));
const getNextPageNotCover = pages => direction => i => index(i)(pages)
  .chain(nextPage => { 
    return ((nextPage.name === 'Inside Cover' && !nextPage.isEditable) ? index(direction === 'next' ? i + 1 : i - 1)(pages) : some(nextPage))
  });

const getPageIndexByDirectionForFirstPage = direction => pages => _evenPagesAfterCover =>
  x => (
    direction === 'next'
      ? (x + 1 > pages.length - 1 ? 0 : x + 1)
      : (_evenPagesAfterCover ? pages.length - 2 : x - 1 < 0 ? pages.length - 1 : x - 1)
  );

export const skipTo = (
  pages: Array<Page>,
  currentPageId: string,
  _isTwoUpNotFirstPage: boolean,
  _evenPagesAfterCover: boolean,
) => (
  direction: 'next' | 'prev',
): Option<Page> => {
  const pagesWithoutCollapsedInteriors =   pages.filter((page, i) => {
    return !page.collapsedInteriorPage; // allows for 1 visible interior spread
  });

  return _isTwoUpNotFirstPage
    ? findIndex(x => currentPageId === x.id)(pagesWithoutCollapsedInteriors)
      .map(getPageIndexByDirection(direction))
      .chain(getNextPageNotCover(pagesWithoutCollapsedInteriors)(direction))
      .alt(some(pagesWithoutCollapsedInteriors[0]))
    : findIndex(x => currentPageId === x.id)(pagesWithoutCollapsedInteriors)
      .map(getPageIndexByDirectionForFirstPage(direction)(pagesWithoutCollapsedInteriors)(_evenPagesAfterCover))
      .alt(some(pagesWithoutCollapsedInteriors[pagesWithoutCollapsedInteriors.length - 1]))
      .chain(getNextPageNotCover(pagesWithoutCollapsedInteriors)(direction))
};

export const updateRegionInLayer = ({ page, layerId, className }, updates) => ({
  ...page,
  layers: changeElementWithId(layer => ({
    ...layer,
    regions: changeArrayElement(region => ({
      ...region,
      ...updates,
    }))(region => region.className === className)(layer.regions),
  }))(layerId)(page.layers),
});

export const findWoodenBoxPage = pages => pages.filter(p => p.id === WOODEN_BOX_PAGE_ID).shift();

export const tagsAreSpineTags = (
  tags: { [key: string]: string }
): boolean => spineTags.reduce((acc: boolean, curr: string) => !!(acc && tags[curr]), true);

export const layerIsSpine = (layer: Layer) => layer.data && layer.data.tags && tagsAreSpineTags(layer.data.tags);

export const isNotEmptySpineTextLayer = (layer: Layer) => layer.type === EDITABLE_TEXT && layer.data.content !== '' && layer.data.tags !== 'SpineText';

export const filterCoverTextLayers = (layer: Layer) => layer.type === EDITABLE_TEXT && !layerIsSpine(layer) && isNotEmptySpineTextLayer(layer);

export const getCoverTextLayers = (
  page: Page
): Array<Layer> => option.fromNullable(page)
  .map((_page: Page) => _page.layers.filter(filterCoverTextLayers))
  .getOrElseValue([]);

export const getCurrentIndexOfV3Text = object => Object.keys(object).filter(key => key.includes(V3_TEXT)).length;

export const reduceCoverTextLayersForV3 = (
  acc: CoverTextV3,
  curr: Layer
): CoverTextV3 => {
  // Multiple lines needs to get split up into multiple CoverText1, CoverText2, etc... keys
  const lines = getStyledLayerContent(curr.data).split('\n');

  // These properties are consistent across all lines for this editable_text layer
  const font = getLayflatXmlFont(curr.data);
  const fontSize = getLayflatXmlFontSize(curr.data);
  const alignment = optionGet('data.style.textAlign')(curr).getOrElseValue('');
  const position = optionGet('defaultData.tags.CoverPosition')(curr).getOrElseValue('');

  let currentIndex = getCurrentIndexOfV3Text(acc);
  return {
    ...acc,
    ...lines.reduce((_acc, _curr) => {
      currentIndex += 1;
      return ({
        ..._acc,
        [`${V3_TEXT}${currentIndex}`]: _curr,
        [`${V3_FONT}${currentIndex}`]: font,
        [`${V3_FONT_SIZE}${currentIndex}`]: fontSize,
        [`${V3_ALIGNMENT}${currentIndex}`]: alignment,
        [`${V3_POSITION}${currentIndex}`]: position,
      });
    }, {}),
  };
};

// Create the CoverTextV3 shape from the page data
export const createCoverTextV3FromPage = (page: Page) => ({
  ...getCoverTextLayers(page).reduce(reduceCoverTextLayersForV3, {}),
});

// Inject the CoverTextV3 shape into the layer tags
export const getCoverTextV3ForLayer = (
  layer: Layer,
  page: Page
): Layer => ({
  ...layer,
  data: {
    ...layer.data,
    tags: {
      ...layer.data.tags,
      ...createCoverTextV3FromPage(page),
    },
  },
});

// We need to add the CoverTextV3 tag to each of the non-spine editable_text layers
export const addCoverTextV3TagsToPage = (page: Page): Page => ({
  ...page,
  layers: page.layers.map((layer: Layer) => (!layerIsEditableTextAndNotSpine(layer)
    ? layer
    : getCoverTextV3ForLayer(layer, page)
  )),
});

// Modify the project data, inject the new CoverTextV3 into the cover page layer tags
export const buildCoverTextV3 = projectData => ({
  ...projectData,
  pages: projectData.pages.map((page: Page) => (!isCoverPage(page)
    ? page
    : addCoverTextV3TagsToPage(page)
  )),
});

// If the project has end-sheets, we subtract one from the new page index in order to keep it accurate
export const getNewPageIndex = (category, newIndex, pageIds) => (shouldShowEndsheets(category) ?
  (newIndex - 1) * pageIds.length - (pageIds.length > 1 ? pageIds.length : 0) :
  newIndex * pageIds.length - (pageIds.length > 1 ? pageIds.length : 0));

// Keys to remove for V3 cover text for Marathon foil stamped book products
export const oldCoverTextKeys = [
  'CoverText', 'CoverFont', 'CoverFontSize', 'CoverAlignment', 'CoverPosition',
];

// Remove any `CoverText` tags in the layer data if present
export const scrubOldCoverTextFromPage = (page: Page) => ({
  ...page,
  layers: page.layers.map((layer: Layer) => (layer.data && layer.data.tags
    ? {
      ...layer,
      data: {
        ...layer.data,
        tags: {
          ...(Object.keys(layer.data.tags).reduce((acc, currentKey) => ({
            ...acc,
            ...(!oldCoverTextKeys.includes(currentKey)
              ? { [currentKey]: layer.data.tags[currentKey] }
              : {}
            ),
          }), {})),
        },
      },
    }
    : layer
  )),
});

export const scrubOldCoverText = projectData => ({
  ...projectData,
  pages: projectData.pages.map((page: Page) => (!isCoverPage(page)
    ? page
    : scrubOldCoverTextFromPage(page)
  )),
});

export const getStartingPagesValue = (template, attrs) => {
  let pages;
  if (template.needsPagesAttribute) {
    pages = attrs.pages;
  } else if (template.pages.start) {
    pages = template.pages.start.toString();
  }

  return pages ? { pages } : {};
};

export const maybeCollapseInteriors = (groupedPages, shouldCollapse) => {
  if (!shouldCollapse) {
    return groupedPages;
  }

  const lastSpread = groupedPages[groupedPages?.length - 1]

  return [groupedPages[0], groupedPages[1], groupedPages[2], lastSpread]
}

export const isUnselectable = (page) => {
  return page.unselectable;
} 