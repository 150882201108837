import React from 'react';
import { showLoginForm, showRegisterForm } from '../../store/userAuthentication/actions';
import { connect } from 'react-redux';
import { dismissNotification } from '../../store/notifications/actions';
import PropTypes from 'prop-types';

const LoginOrSignUpModal = (props) => {
  function clearLoginBanner() {
    return props.dismissNotification('PROMPT_ANON_USER_LOGIN');
  }

  async function showLogin() {
    await clearLoginBanner();
    await props.showLoginForm();
  }

  async function showSignup() {
    await clearLoginBanner();
    await props.showRegisterForm();
  }

  return (
    <span>
      <button className="btn btn--link" onClick={() => showLogin()}>Login
      </button> or <button className="btn btn--link" onClick={() => showSignup()}>
      Sign up</button> to save your progress.
    </span>
  );
};

LoginOrSignUpModal.propTypes = {
  showLoginForm: PropTypes.func.isRequired,
  showRegisterForm: PropTypes.func.isRequired,
  dismissNotification: PropTypes.func.isRequired
};

LoginOrSignUpModal.defaultProps = {
  showLoginForm: showLoginForm,
  showRegisterForm: showRegisterForm
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showLoginForm: () => dispatch(showLoginForm()),
    showRegisterForm: () => dispatch(showRegisterForm()),
    dismissNotification: key => dispatch(dismissNotification(key)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginOrSignUpModal);
