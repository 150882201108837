const reduce = Function.bind.call(Function.call, Array.prototype.reduce);
const isEnumerable = Function.bind.call(Function.call, Object.prototype.propertyIsEnumerable);
const concat = Function.bind.call(Function.call, Array.prototype.concat);
const keys = Reflect.ownKeys;

const polyfills = () => {
  // Object.entries (from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries)
  if (!Object.entries) {
    Object.entries = (obj) => {
      const ownProps = Object.keys(obj);
      let i = ownProps.length;
      const resArray = new Array(i); // preallocate the Array
      // eslint-disable-next-line
      while (i--) { resArray[i] = [ownProps[i], obj[ownProps[i]]]; }

      return resArray;
    };
  }

  // Object.values (from https://github.com/tc39/proposal-object-values-entries/blob/master/polyfill.js)
  if (!Object.values) {
    Object.values = function values(O) {
      return reduce(keys(O), (v, k) => concat(v, typeof k === 'string' && isEnumerable(O, k) ? [O[k]] : []), []);
    };
  }

  /* Element.prototype.matches and Element.prototype.closest
     (from https://developer.mozilla.org/en-US/docs/Web/API/Element/closest) */
  if (!Element.prototype.matches) {
    Element.prototype.matches = (
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector
    );
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = (s) => {
      const el = this;
      let ancestor = this;
      if (!document.documentElement.contains(el)) return null;
      do {
        if (ancestor.matches(s)) return ancestor;
        ancestor = ancestor.parentElement;
      } while (ancestor !== null);
      return null;
    };
  }
};

export default polyfills;
