// @flow

// Return the given content if the value x is truthy. Otherwise return null.
export const showIf = (content: any) => (x: any) => (x ? content : null);

export const showIfOrElse = (content: any) => (fallbackContent: any) => (x: any) => (x ? content : fallbackContent);

// Return whether or not something is exactly equal to true.
export const isTrue = (x: any) => (x === true);

// Return the given content if all of the given booleans are exactly true. Otherwise return null.
export const showIfAll = (content: any) => (...bs: Array<boolean>) => (bs.every(isTrue) ? content : null);

// Checks if any values in an array are exactly true.
export const any = (xs: Array<any>) => xs.some(isTrue);

// Checks if all values in an array are exactly true.
export const all = (xs: Array<any>) => xs.every(isTrue);

// Wraps the given content in a given function if the given boolean is exactly true. Otherwise just returns the content.
export const wrapIf = (content: any) => (wrapper: any => any) => (b: boolean): any => (
  isTrue(b) ? wrapper(content) : content
);

export const productAttributeClasses = (product) => {
  const { sku, attributes } = product;

  return attributes && Object.keys(attributes).map(attr => {
    const value = attributes[attr];
    
    return `${sku}__${attr}__${value}`;
  }).join(' ');
}

// Takes an array of predicates and a value x and returns whether or not the value satifies every predicate.
export const meetsAll = <T>(...predicates: Array<T => boolean>) => (x: T) => (
  predicates.reduce((result, f) => result && f(x), true)
);


// Takes an array of predicates and a value x and returns whether or not the value satisfies at least one predicate.
export const meetsAny = <T>(...predicates: Array<T => boolean>) => (x: T) => (
  predicates.reduce((result, f) => result || f(x), false)
);
