import {
  ALBUMS_GET_REQUEST,
  ALBUMS_GET_SUCCESS,
  ALBUMS_GET_FAILURE,
  ALBUM_GET_PHOTOS_REQUEST,
  ALBUM_GET_PHOTOS_FAILURE,
  ALBUM_GET_PHOTOS_SUCCESS,
  ALBUM_SET_PHOTOS,
  ALBUM_ADD_ALBUM,
  SORT_ALBUM_PHOTOS_ACTION,
  MANUALLY_ADD_NEW_ALBUM_TO_ARRAY,
} from './constants';

import { albumIsSortable } from './selectors';
import { DATE_TAKEN_ASC, sortPhotosBy } from '../../types/photo';
import { optionFind } from '../../helpers/functions';
import { changeElementWithPropValue } from '../../helpers/arrays';

const initialState = {
  albums: [],
  albumPhotos: {},
};

const changeAlbumById = changeElementWithPropValue('albumId');

const albumsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALBUM_ADD_ALBUM: {
      return { ...state, albums: action.payload };
    } case ALBUMS_GET_REQUEST: {
      return state;
    } case ALBUMS_GET_FAILURE: {
      return state;
    } case ALBUMS_GET_SUCCESS: {
      return {
        ...state,
        albums: action.payload.albums,
        albumPhotos: action.payload.albumPhotos,
      };
    } case ALBUM_GET_PHOTOS_REQUEST: {
      const album = state.albumPhotos[action.payload.albumId] || [];
      return {
        ...state,
        albumPhotos: {
          ...state.albumPhotos,
          [action.payload.albumId]: album.map((photo, i) => (
            i >= action.payload.start && i < action.payload.end ? (
              {
                ...photo,
                isPlaceholder: false,
                isLoading: true,
              }
            ) : (
              photo
            )
          )),
        },
      };
    } case ALBUM_GET_PHOTOS_FAILURE: {
      return state;
    } case ALBUM_GET_PHOTOS_SUCCESS: {
      const { albumId, photos } = action.payload;

      return optionFind(a => a.albumId === albumId, state.albums)
        .map((album) => {
          const isSortable = albumIsSortable(albumId, album, true);

          return {
            ...state,
            albums: changeAlbumById(a => ({
              ...a,
              photosRetrieved: true,
              ...(isSortable ? { sortOperation: DATE_TAKEN_ASC } : {}),
            }))(albumId)(state.albums),
            albumPhotos: {
              ...state.albumPhotos,
              [albumId]: isSortable ? sortPhotosBy(DATE_TAKEN_ASC)(photos) : photos,
            },
          };
        })
        .getOrElseValue(state);
    } case MANUALLY_ADD_NEW_ALBUM_TO_ARRAY: {
      return {
        ...state,
        albums: [
          ...state.albums,
          {
            albumId: action.payload.albumId,
            photosRetrieved: true,
          },
        ],
      };
    } case SORT_ALBUM_PHOTOS_ACTION: {
      return {
        ...state,
        albums: state.albums.map(x => (
          x.albumId === action.payload.albumId ? {
            ...x,
            sortOperation: action.payload.sortOp,
          } : x)
        ),
        albumPhotos: {
          ...state.albumPhotos,
          [action.payload.albumId]: action.payload.photos,
        },
      };
    } case ALBUM_SET_PHOTOS: {
      return {
        ...state,
        albumPhotos: {
          ...state.albumPhotos,
          [action.payload.albumId]: action.payload.photos,
        },
      };
    } default: {
      return state;
    }
  }
};

export const userAlbumsStateFilter = () => initialState;

export default albumsReducer;
