export const UPLOAD_STATUS = 'UPLOAD_STATUS';
export const ALL_UPLOADS_COMPLETE_STATUS = 'ALL_UPLOADS_COMPLETE_STATUS';
export const UPLOAD_IN_FLIGHT_STATUS = 'UPLOAD_IN_FLIGHT_STATUS';
export const USER_SELECTED_UPLOAD_SOURCE = 'USER_SELECTED_UPLOAD_SOURCE';
export const ANALYTICS_SELECTED_SOURCE = 'ANALYTICS_SELECTED_SOURCE';
export const USER_PHOTOS_ADD_PHOTO = 'USER_PHOTOS_ADD_PHOTO';
export const USER_PHOTOS_REMOVE_PHOTO_BY_ID = 'USER_PHOTOS_REMOVE_PHOTO_BY_ID';
export const USER_PHOTOS_REMOVE_ALL = 'USER_PHOTOS_REMOVE_ALL';
export const UPLOAD_IMAGE_ACTION_FOR_ANALYTICS = 'UPLOAD_IMAGE_ACTION_FOR_ANALYTICS';

export const LARGE_FILE_ERROR_MESSAGE = 'File is larger than 25MB.';

export const SPLIT_IO_S3_DIRECT_UPLOAD = 's3DirectUpload';
export const SPLIT_IO_S3_DIRECT_UPLOAD_ON = 'on';
export const SPLIT_IO_S3_DIRECT_UPLOAD_OFF = 'off';

export const IMAGE_UPLOAD_REDESIGN_COOKIE = 'AU_UPLOAD_REDESIGN_MAY20';
