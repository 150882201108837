// @flow
import type { IClient, ISDK } from '@splitsoftware/splitio/types/splitio.d.ts';
import { SplitFactory } from '@splitsoftware/splitio';
import { getCookie, setCookie } from '../../helpers/cookies';
import { v4 as uuidv4 } from 'uuid';

const SPLIT_IO_TREATMENT_ON = 'on';
const SPLIT_IO_TREATMENT_CONTROL = 'control';

const SPLIT_IO_USER_KEY_COOKIE = 'au_split_user_key';
const SEGMENT_IO_ANONYMOUS_ID = 'ajs_anonymous_id';

const SPLIT_IO_FEATURE_FLAG_ZEN_DESK = 'FeatureFlag-ZenDesk';
const SPLIT_IO_FEATURE_FLAG_CARD_ADDRESSING = 'FeatureFlag-CardAddressing';



const AA_TEST = 'aa_test';

let userEmail;

export const initSplitIO = (splitKey: string, email: string) => {
  if (email) {
    userEmail = email;
  }

  if (window.splitioClient) {
    window.splitioClient.destroy();
  }

  const factory: ISDK = SplitFactory({
    core: {
      authorizationKey: process.env.REACT_APP_SPLITIO_API_KEY,
      key: splitKey,
    },
  });

  const client: IClient = factory.client();

  client.on(client.Event.SDK_READY, async () => {
    window.splitioClient = client;
  });

  return client;
};
const getSplitTreatment = (splitName: string): string => {
  if (!window.splitioClient) {
    const log = {
      message: 'Trying to get SplitIO treatment before client is ready!',
      splitName,
    };
    console.warn(log);
    window.newrelic.addPageAction('splitIO_getSplitTreatment', log);
    return '';
  }

  // Always serve control treatment to bots
  const userAgent = navigator.userAgent
  const botRegEx = /bot|google|baidu|bing|msn|teoma|slurp|yandex/i

  if (botRegEx.test(userAgent)) {
    return SPLIT_IO_TREATMENT_CONTROL
  }

  return window.splitioClient.getTreatment(splitName, { email: userEmail });
};

export const triggerAATest = () => {
  const treatment = getSplitTreatment(AA_TEST);
  return treatment === SPLIT_IO_TREATMENT_ON;
};

export const getZenDeskTreatment = () => {
  const treatment = getSplitTreatment(SPLIT_IO_FEATURE_FLAG_ZEN_DESK);

  return treatment === SPLIT_IO_TREATMENT_ON;
};

export const getCardAddressingTreatment = () => {
  const treatment = getSplitTreatment(SPLIT_IO_FEATURE_FLAG_CARD_ADDRESSING);

  return treatment === SPLIT_IO_TREATMENT_ON;
};

export const setSplitUserKeyCookie = () => {
  const uuid = uuidv4();
  setCookie(SPLIT_IO_USER_KEY_COOKIE, uuid);
  return uuid;
};

export const getSplitUserKey = () => {
  const segmentId = getCookie(SEGMENT_IO_ANONYMOUS_ID);

  if (!segmentId) {
    const key = getCookie(SPLIT_IO_USER_KEY_COOKIE);

    if (!key) {
      return setSplitUserKeyCookie();
    }

    return key;
  }

  return decodeURIComponent(segmentId).replace(/"/gi, '');
};
