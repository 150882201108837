// @flow
export interface Action {
  type: string;
  payload: any;
}

export const FETCH_GALLERIES = 'FETCH_GALLERIES';
export const CREATE_GALLERY = 'CREATE_GALLERY';
export const UPDATE_GALLERY = 'UPDATE_GALLERY';

export const FETCH_GALLERY_IMAGES = 'FETCH_GALLERY_IMAGES';
export const UPLOAD_IMAGES_TO_GALLERY = 'UPLOAD_IMAGES_TO_GALLERY';
export const AUTO_ORIENT_IMAGE_IN_GALLERY = 'AUTO_ORIENT_IMAGE_IN_GALLERY';
export const REMOVE_IMAGE_FROM_GALLERY = 'REMOVE_IMAGE_FROM_GALLERY';

export const GALLERY_ACTION_SUCCEEDED = 'GALLERY_ACTION_SUCCEEDED';
export const GALLERY_ACTION_FAILED = 'GALLERY_ACTION_FAILED';
export const GET_MORE_PHOTOS =  'GET_MORE_PHOTOS';
export const ADD_IMAGES_TO_GALLERY = 'ADD_IMAGES_TO_GALLERY';