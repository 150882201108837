// @flow

import { index, findIndex } from 'fp-ts/lib/Array';
import flatten from 'lodash.flatten';
import times from 'lodash.times';

import { startCase, strReplace } from '../strings';
import { fillArrayBy } from '../arrays';

import { type Option } from 'fp-ts/lib/Option';
import { type Layer } from '../../types/templates';

export type MonthYearPair = { month: string, year: number };

export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

// Takes a month/year pair and returns a space-delimited string like "{month} {year}", with the month optionally capitalize.
export const getMonthAndYearString = ({ month, year }: MonthYearPair, isLabel: boolean = false) => (
  `${isLabel ? startCase(month) : month} ${year}`
);

// Converts a month string to a month index.
export const monthIndexFromString = (str: string): Option<number> => findIndex(m => m === str)(months);

// Converts an index to a month string.
export const monthStringFromIndex = (n: number): Option<string> => index(n)(months);

// Order months based on the index of the starting month.
export const orderMonths = (startIndex: number): Array<number> => flatten(
  [startIndex, times(11 - startIndex, x => 1 + x + startIndex), times(startIndex)]
);

// Gets the index of the current month.
export const getCurrentMonth = (): number => {
  const date = new Date();

  return date.getMonth();
};

// Gets the year of the current month
export const currentDateYear = (): number => {
  const date = new Date();
  const nextMonth = date.setMonth(getCurrentMonth());

  return new Date(nextMonth).getFullYear();
};

// Returns the common name for the current month.
export const currentDateMonthYearString = (): string => (
  getMonthAndYearString(
    {
      month: monthStringFromIndex(getCurrentMonth()).getOrElseValue(months[0]),
      year: currentDateYear(),
    }
  )
);

// Returns the common name for the next month.
export const nextMonthsDateMonthYearString = (): string => {
  const currDate = new Date();
  // We need to update start month for Calendars to be January 2022 per EN-4310
  // TODO: In January/February 2022 we should rollback these changes by removing the frist part of the OR condition
  const nextMonth = currDate.getMonth() === 10 || currDate.getMonth() === 11 ? 0 : currDate.getMonth() + 1;
  const nextMonthsYear = nextMonth === 0 ? currDate.getFullYear() + 1 : currDate.getFullYear();

  return getMonthAndYearString({
    month: months[nextMonth],
    year: nextMonthsYear,
  });
};

// Gets an order from the given month string, or returns the default order, based on the next month.
export const orderFromMonthString = (startMonthStr: string): Array<number> => (
  monthIndexFromString(startMonthStr)
    .map(orderMonths)
    .getOrElseValue(orderMonths(getCurrentMonth()))
);

// Get the year for a given month index in a given order
export const getYear = (i: number, order: Array<number> = [], year: number): number => {
  if (order.length > 0) {
    return order.indexOf(0) > 0 && order.slice(order.indexOf(0)).indexOf(i) !== -1 ? year + 1 : year;
  }

  // create order of months based on the current month
  const _order = orderMonths(getCurrentMonth());

  // list of months from january and the rest of the year
  const janUp = _order.slice(_order.indexOf(0));
  return Array.isArray(janUp) && janUp.indexOf(_order[i]) !== -1 && janUp.length !== 12 ? year + 1 : year;
};

// Get an object containing both the month and the year associated with the month index located at index `n` in the given order array.
export const getMonthAndYearFromOrder = (order: Array<number>, startYear: number) => (n: number): MonthYearPair => {
  const monthIndex = order[n];

  return {
    month: monthStringFromIndex(monthIndex).getOrElseValue(months[n]),
    year: getYear(monthIndex, order, startYear),
  };
};

// Splits a given string, which possibly contains a year, and returns a MonthYearPair.
export const getMonthAndYearFromString = (monthYearStr: string): MonthYearPair => {
  const mySplit = monthYearStr.split(' ');

  if (mySplit.length > 1) {
    return {
      month: mySplit[0],
      year: parseInt(mySplit[1], 10),
    };
  }

  // If there's no year included in the start month value (older projects won't have it), we'll just use the current year.
  return {
    month: mySplit[0],
    year: new Date().getFullYear(),
  };
};

// Creates the calendar asset layer
export const generateCalendarLayer = (layer: Layer, pageNum: number, startMonthYearStr: string): Layer => {
  const { month, year } = getMonthAndYearFromString(startMonthYearStr);

  const order = orderFromMonthString(month);

  return {
    ...layer,
    image: strReplace(getMonthAndYearFromOrder(order, year)(pageNum))(layer.image),
  };
};

// Create the page name for the calendar
export const calendarPageName = (pageNum: number, startMonthYearStr: string): string => {
  const { month, year } = getMonthAndYearFromString(startMonthYearStr);
  const order = orderFromMonthString(month);

  return getMonthAndYearString(getMonthAndYearFromOrder(order, year)(pageNum), true);
};

// Gets an array of month/year pairs for the next 12 months.
export const getMonthsAndYearsFromNext = (): Array<MonthYearPair> => {
  const order = orderMonths(getCurrentMonth());
  const startYear = currentDateYear();

  return fillArrayBy(getMonthAndYearFromOrder(order, startYear), 12);
};

// Converts a date-like string into a Date object
export const strToDate = (date: string) => new Date(date);