/* eslint-disable no-use-before-define */
// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { currentAlbumPhotosSelector, albumPhotoCountSelector } from '../../../../store/userAlbums/selectors';
import { getMoreAlbumPhotos, addAlbumPhotoToProject } from '../../../../store/userAlbums/actions';
import DrawerGrid from '../../DrawerGrid';
import { PhotoElements } from '../PhotoElements';
import { setTooltipImgPreview, hideToolTip } from '../../../../store/ui/actions';
import { UI_TOOLTIP_IMAGE_PREVIEW, UI_INSTAGRAM_ALBUM, UI_DROPBOX_ALBUM } from '../../../../store/ui/constants';
import { isCollapsedView as _isCollapsedView } from '../../../../store/ui/selectors';

import { undoableUserAction } from '../../../../store/history/actions';
import { UI_GOOGLE_PHOTOS_ALBUM } from '../../../../store/googlePhotos/constants';
import { UNDOABLE_ADD_IMAGE_TO_LAYER } from '../../../../store/history/constants';
import { usedPhotosSelector } from '../../../../store/userPhotos/selectors';
import photo from '../../../../types/photo';
import { Gallery } from 'au-js-sdk/lib/models/Gallery';
import { gallerySelector } from '../../../../store/v2/galleries/selectors';

const DEFAULT_GALLERY_SORT_OPTION = 'CREATED_AT_DESC';

const AlbumViewer = ({
  albumId,
  photos,
  selectPhoto,
  flashId,
  setTooltip,
  hideTooltip,
  isCollapsedView,
  loadMorePhotos,
  itemCount,
  facebookActive,
  usedPhotos,
  hideUsedImages,
}) => (
  <DrawerGrid
    key={`DrawerGrid--${albumId}`}
    cacheKey={`DrawerGrid--${albumId}`}
    id="AlbumViewer"
    context="Images"
    itemWidth={isCollapsedView ? 120 : 190}
    itemHeight={isCollapsedView ? 80 : 150}
    loader={
      //@todo: add pagination work for Dropbox
      facebookActive || albumId === UI_INSTAGRAM_ALBUM || albumId === UI_GOOGLE_PHOTOS_ALBUM || albumId !== UI_DROPBOX_ALBUM  ? (id) => loadMorePhotos(albumId, id) : null
    }
    itemCount={itemCount}
  >

    {PhotoElements({
      photos,
      selectPhoto,
      flashId,
      imgClass: 'AlbumsBrowser__item--photo',
      inGalleryView: true,
      setTooltip,
      hideTooltip,
      usedPhotos: hideUsedImages ? usedPhotos : [],
    })}
  </DrawerGrid>
);


AlbumViewer.propTypes = {
  albumId: PropTypes.string.isRequired,
  flashId: PropTypes.string,
  photos: PropTypes.array.isRequired,
  selectPhoto: PropTypes.func.isRequired,
  loadMorePhotos: PropTypes.func.isRequired,
  setTooltip: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,
  isCollapsedView: PropTypes.bool.isRequired,
  itemCount: PropTypes.number.isRequired,
  facebookActive: PropTypes.bool.isRequired,
  usedPhotos: PropTypes.array.isRequired,
  hideUsedImages: PropTypes.bool.isRequired,
  gallerySortOp: PropTypes.string.isRequired,
};

AlbumViewer.defaultProps = {
  flashId: null,
};

const mapStateToProps = (state) => ({
  project: state.project,
  userPhotos: state.userPhotos,
  userAlbums: state.userAlbums.albumPhotos,
  albumId: state.ui.currentAlbum,
  photos: getPhotosWithSplit(state),
  flashId: state.user.flashId,
  isCollapsedView: _isCollapsedView(state),
  itemCount: albumPhotoCountSelector(state),
  facebookActive: state.facebook.facebookActive,
  usedPhotos: usedPhotosSelector(state).map((p) => photo.extract(p)),
  hideUsedImages: state.ui.hideUsedImages,
});


export const getSortedPhotos = (photos, gallerySortOp) => {
  const sortedPhotos = photos.map((p) => ({
    ...p,
    createdAt: new Date(p.createdAt || 0),
  }));

  switch (gallerySortOp) {
    case DEFAULT_GALLERY_SORT_OPTION: {
      sortedPhotos.sort((a, b) => (((a.createdAt && a.createdAt.getTime() || "") > (b.createdAt && b.createdAt.getTime() || "")) ? -1 : 1));
      return sortedPhotos;
    }
    case 'CREATED_AT_ASC': {
      sortedPhotos.sort((a, b) => (((a.createdAt && a.createdAt.getTime() || "") > (b.createdAt && b.createdAt.getTime() || "")) ? 1 : -1));
      return sortedPhotos;
    }
    case 'DATE_TAKEN_DESC': {
      sortedPhotos.sort((a, b) => ( (a.metadata?.additionalData?.createDate || "") >  (b.metadata?.additionalData?.createDate || "") ) ? -1 : 1);
      return sortedPhotos;
    }
    case 'DATE_TAKEN_ASC': {
      sortedPhotos.sort((a, b) => (((a.metadata?.additionalData?.createDate || "") > (b.metadata?.additionalData?.createDate || "")) ? 1 : -1));
      return sortedPhotos;
    }
    case 'FILE_NAME_ASC': {
      sortedPhotos.sort((a, b) => (((a.metadata?.filename || "") > (b.metadata?.filename || "")) ? 1 : -1));
      return sortedPhotos;
    }
    default: {
      return sortedPhotos;
    }
  }
};

const getPhotosWithSplit = (state) => {
  const selectedGallery: Gallery = gallerySelector(state, state.ui.currentAlbum);
  if (selectedGallery) {
    return selectedGallery.images;
  }
  return currentAlbumPhotosSelector(state);
};

const mapDispatchToProps = (dispatch) => ({
  loadMorePhotos: (albumId, visibleIndex) => dispatch(getMoreAlbumPhotos(albumId, visibleIndex)),
  selectPhoto: (albumPhoto, layerId, pageId) =>
    dispatch(undoableUserAction(UNDOABLE_ADD_IMAGE_TO_LAYER, pageId)(() => addAlbumPhotoToProject(albumPhoto, layerId, pageId, true))),
  setTooltip: (item) => (e) => dispatch(setTooltipImgPreview(UI_TOOLTIP_IMAGE_PREVIEW)(item)('.album-button--photo')(e)), // TODO
  hideTooltip: () => dispatch(hideToolTip),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlbumViewer);
