// @flow

import { option } from 'fp-ts';
import { type Option } from 'fp-ts/lib/Option';
import { createSelector } from 'reselect';
import { type UIstate } from './reducer';
import { isWithinSmall } from '../../helpers/breakpoints';
import { layerIdWithoutCustomization } from '../../helpers/layers';
import { type ScreenSize, type Orientation } from '../../types/mediaQuery';
import { prop } from '../../helpers/functions';
import { productHasAttributesToDisplaySelector, productCanHaveMultiplePagesSelector } from '../product/selectors';
import {
  __BREAKPOINT_TINY__,
  __BREAKPOINT_SMALL__,
  __BREAKPOINT_MEDIUM__,
  SCREEN_SIZE_TINY,
  SCREEN_SIZE_SMALL,
  SCREEN_SIZE_MEDIUM,
  SCREEN_SIZE_LARGE,
} from '../../constants/breakpoints';

type StoreWithUIstate = { ui: UIstate };

/* Predicate indicating whether or not a given state object contains a product that can have multiple pages,
   or if it will have attributes displayed. */
export const includeSidebarInEditor = createSelector(
  productHasAttributesToDisplaySelector,
  productCanHaveMultiplePagesSelector,
  (hasAttributesToDisplay, productCanHaveMultiplePages) => hasAttributesToDisplay || productCanHaveMultiplePages,
);

// Returns the id of the currently-selected page.
export const currentPageIdSelector = (state: StoreWithUIstate): string | null => state.ui.currentPage;

// Returns the current sortBy option
export const currentSortBySelector = (state: StoreWithUIstate): string => state.ui.gallerySortBy;

// Returns the status attached to the current confirmation state.
export const confirmationStatusSelector = (state: StoreWithUIstate): string => (state.ui.confirmation ? state.ui.confirmation.status : '');

// Returns the data attached to the current confirmation state.
export const confirmationDataSelector = (state: StoreWithUIstate): Object | null => (
  state.ui.confirmation ? state.ui.confirmation.data : null
);

// Returns the contents of the drawerGridScrollCache.
export const drawerGridScrollCacheSelector = (state: StoreWithUIstate): { [string]: number } => state.ui.drawerGridScrollCache;

// Returns the value of a given cacheKey in the drawerGridScrollCache, defaulting to 0 if the value is undefined.
export const drawerGridScrollCacheValueSelector = (cacheKey: string) => (state: StoreWithUIstate): number => (
  prop(cacheKey)(drawerGridScrollCacheSelector(state)).getOrElseValue(0)
);

// Returns the value of the partialSelection state property, either 'left', 'right', or null.
export const partialSelectionSelector = (state: StoreWithUIstate): 'left' | 'right' | null => state.ui.partialSelection;

export const isMobileSelector = () => isWithinSmall();

export const s3DirectUploadSelector = (state: StoreWithUIstate): boolean => state.ui.s3DirectUpload;

// Returns the layer and page ids of the selected layer.
export const selectedLayerSelector = (state: StoreWithUIstate): { layerId: string | null, pageId: string | null } => state.ui.selectedLayer;

// Returns a boolean indicating whether or not the given layer is selected in the given state.
export const layerSelectedSelector = (layerId: string, pageId: string) => (state: StoreWithUIstate): bool => {
  const selectedLayer = selectedLayerSelector(state);

  /* Return true if there is a selected layer AND
     there is a selected layerId for that layer AND
     there is a selected pageId for that layer AND
     the selected pageId is equal to the given pageId AND
      - the selected layerId is equal to the given layerId OR
      - the non-customized version of the selected layerId is equal to the non-customized version of the given layerId
  */
  return (
    selectedLayer &&
    selectedLayer.layerId !== null &&
    selectedLayer.pageId !== null &&
    selectedLayer.pageId === pageId &&
    (
      selectedLayer.layerId === layerId ||
      layerIdWithoutCustomization(selectedLayer.layerId) === layerIdWithoutCustomization(layerId)
    )
  );
};

export const autoFillImgCountSelector = (state: StoreWithUIstate) => state.ui.autoFillImgCount;

type ScreenDimensions = { width: number, height: number };

export const screenSizeSelector =
  (state: StoreWithUIstate): Option<ScreenDimensions> => option.fromNullable(state.ui.screenSize);

export const screenSizeTypeSelector = createSelector(
  screenSizeSelector,
  (x: Option<ScreenDimensions>): ScreenSize =>
    x
      .chain(prop('width'))
      .map((width: number) => {
        switch (true) {
          case width <= __BREAKPOINT_TINY__:
            return SCREEN_SIZE_TINY;
          case width > __BREAKPOINT_TINY__ && width <= __BREAKPOINT_SMALL__:
            return SCREEN_SIZE_SMALL;
          case width > __BREAKPOINT_SMALL__ && width <= __BREAKPOINT_MEDIUM__:
            return SCREEN_SIZE_MEDIUM;
          default:
            return SCREEN_SIZE_LARGE;
        }
      })
      .getOrElseValue(SCREEN_SIZE_LARGE)
);

export const isCollapsedView = createSelector(
  screenSizeTypeSelector,
  x => [SCREEN_SIZE_SMALL, SCREEN_SIZE_TINY].includes(x),
);

export const isAtLeastMediumScreenSize = createSelector(
  screenSizeTypeSelector,
  x => x !== SCREEN_SIZE_MEDIUM,
);

export const isAtLeastTinyScreenSize = createSelector(
  screenSizeTypeSelector,
  x => x !== SCREEN_SIZE_TINY,
);

const convertOrientation = screenOrientation => prop('type')(screenOrientation)
  .map(x => (x.includes('landscape') ? 'Landscape' : 'Portrait'));

export const screenOrientationSelector = createSelector([
  screenSizeSelector,
], (x: Option<ScreenDimensions>): Option<Orientation> =>
  x
    .chain(
      ({ width, height }: ScreenDimensions) =>
        convertOrientation(window.screen.orientation)
          .alt(option.of(width > height ? 'Landscape' : 'Portrait'))
    )
);

export const appliedToAllSelector = state => state.ui.appliedToAllToggle;

export const loadingAppliedAllSelector = state => state.ui.loadingAppliedAll;
