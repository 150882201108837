// @flow

import { createSelector } from 'reselect';
import get from 'lodash.get';
import find from 'lodash.find';
import { option, Option } from 'fp-ts';

import { restrictAttributeOptions, restrictedOptionsForAttribute } from '../../helpers/restrictions';
import { prop } from '../../helpers/functions';
import { CATEGORIES_WITH_PARTIAL_PAGE_SELECTION, CATEGORIES_WITH_PREVIEW } from '../../constants/products';
import { snakecase } from '../../helpers/strings';
import { anyAttributesToDisplay } from '../../helpers/attributes';
import { type PriceTier } from '../../types/product';

export const configuredProductSelector = (state: Object) => state.product;
export const cartStateSelector = (state: Object) => state.product.cartState;
export const productCategorySelector = (state: Object) => state.product.category;
export const minPagesSelector = (state: Object) => state.template.pages.min;
export const maxPagesSelector = (state: Object) => state.template.pages.max;
export const coverColorSelector = (state: Object) =>
  (state && state.product && state.product.attributes && state.product.attributes.cover_color) || '';
export const foilColorSelector = (state: Object) =>
  (state && state.product && state.product.attributes && state.product.attributes.foil_color) || '';
export const productSkuSelector = (state: Object) => (state && state.product && state.product.sku) || '';
export const productNameSelector = (state: Object) => (state && state.product && state.product.name) || '';
export const reportingProductCategorySelector = (state: Object) => state.product.reportingProductProperties?.reportingProductCategory;

export const productSelector = () => window.productData;
export const canAddToCartAndCreateNewProjectSelector = () => window.productData?.canAddToCartAndCreateNewProject || false;

export const isBookProportionalSizedSelector = createSelector(
  state => state.product.attributes.book_size,
  size => {
    if (size && typeof size === 'string') {
      const proportions = size.split('x');
      if (proportions[0] === proportions[1]) return true;
      return false;
    } else return false;
  }
);

export const additionalPagePriceSelector = createSelector(
  state => state.product.magento,
  mageProductData => {
    if (mageProductData != null && mageProductData.productCustomAttributes != null) {
      const attr = find(mageProductData.productCustomAttributes, a => a.attribute_code === 'additional_page_price');
      return attr ? Number(attr.value) : 0;
    }

    return 0;
  }
);

export const productAttributesSelector = createSelector(
  productSelector,
  product =>
    option
      .fromNullable(product)
      .chain(prop('attributes'))
      .getOrElseValue({}) || {}
);

export const hexCoverColorSelector = createSelector(productAttributesSelector, coverColorSelector, (attributes, coverColorLabel) => {
  try {
    if (attributes && Object.keys(attributes).length > 0) {
      const foundOption = attributes.cover_color.options.find(option => option.value === coverColorLabel);
      if (foundOption) {
        return foundOption.hexCode;
      } else return '#000000';
    }
  } catch (error) {
    console.log(`Could not found matching hexCode option ${error}`);
    return '#000000';
  }
});

// Selector that indicates whether or not the current product has any displayable attributes.
export const productHasAttributesToDisplaySelector = createSelector(productAttributesSelector, attrs => anyAttributesToDisplay(attrs));

export const productCanHaveMultiplePagesSelector = createSelector(maxPagesSelector, maxPages => maxPages && maxPages > 1);

export const optionFromAttributeData = option.fromNullable(productSelector()).chain(prop('attributes'));
export const templateAttributeDataSelector = optionFromAttributeData.getOrElseValue({});

export const productRestrictionsSelector = createSelector(
  productSelector,
  product =>
    option
      .fromNullable(product)
      .chain(prop('attributeRestrictions'))
      .getOrElseValue([]) || []
);

export const attributesWithRestrictionsSelector = createSelector(
  configuredProductSelector,
  productRestrictionsSelector,
  (configuredProduct, restrictions) =>
    restrictions.filter(restrictedAttr => {
      const { attribute, equals } = restrictedAttr.if;
      const attr = get(configuredProduct.attributes, [attribute], false);
      if (attr && attr === equals) {
        return true;
      }
      return false;
    })
);
export function selectedAttributesSelector(state: Object) {
  return option
    .fromNullable(state)
    .chain(prop('product'))
    .chain(prop('attributes'))
    .getOrElseValue(null);
}
export const productValidAttributesSelector = createSelector(
  productAttributesSelector,
  attributesWithRestrictionsSelector,
  selectedAttributesSelector,
  productSkuSelector,
  (attributes, restrictions, selectedAttributes, sku) =>
    Object.keys(attributes).reduce((updatedAttributes, attributeName) => {
      const matchedRestriction = restrictions.filter(
        x => x.disable && x.disable.attribute === attributeName && selectedAttributes[x.if.attribute] === x.if.equals
      );
      if (matchedRestriction.length) {
        return updatedAttributes;
      }
      const options = restrictAttributeOptions(attributes[attributeName])(restrictedOptionsForAttribute(restrictions)(attributeName));

      return options.filter(o => !o.disabled).length
        ? {
          ...updatedAttributes,
          [attributeName]: {
            ...attributes[attributeName],
            ...(matchedRestriction.length ? { disable: matchedRestriction } : {}),
            options,
          },
        }
        : updatedAttributes;
    }, {})
);

export const envelopeAddressingAttributesSelector = (state: Object): Option<string> =>
  option
    .fromNullable(state)
    .chain(prop('product'))
    .chain(prop('attributes'))
    .chain(prop('envelope_addressing'))
    .filter(value => value !== 'None');

// Takes a prefix and an attributes object and returns an array of CSS classes in the form {prefix}__{key}--{value}
const attrsToCss = (prefix: string) => (attrs: ?Object): Array<string> =>
  prefix
    ? Object.entries(attrs || {}).map(([attrKey, attrValue]) =>
        snakecase(prefix).concat('__', snakecase(attrKey), '--', snakecase(attrValue))
      )
    : [];

/**
 * attributeClassnameSelector converts a key value object
 * of attributes to a classname that looks like
 * "hardcover__fabric_color--orchidee hardcover__size--12x10"
 */
export const attributeClassnameSelector = createSelector(
  selectedAttributesSelector,
  productSkuSelector,
  productCategorySelector,
  (attrs, sku, category) => [...attrsToCss(category)(attrs), ...attrsToCss(sku)(attrs)].join(' ')
);

export const priceSelector = createSelector(
  state => state.template.price,
  state => option.fromNullable(state.product.magento).chain(prop('price')),
  (templatePrice, magentoPrice) => magentoPrice.getOrElseValue(templatePrice)
);

// Selector factory that takes a feature group and creates a selector determining if the current product is in that feature group
const productFeatureSelectorFactory = featureGroup => createSelector(productCategorySelector, category => featureGroup.includes(category));

export const productShouldEnableHalfPageSelectionSelector = productFeatureSelectorFactory(CATEGORIES_WITH_PARTIAL_PAGE_SELECTION);
export const productShouldShowPreviewSelector = productFeatureSelectorFactory(CATEGORIES_WITH_PREVIEW);

export const priceTiersSelector = (state: { product: { priceTiers: Array<PriceTier> } }) => state.product.priceTiers || [];

export const hasEnvelopeAddressing = (state) => state.envelopeAddressing.returnAddress || state.envelopeAddressing.recipientAddress || [];
