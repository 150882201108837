// @flow

import loadScript from 'load-script';
import { hideUploadPhotosModal, setCurrentAlbum, showGooglePhotosAlbum } from '../ui/actions';
import {
  SET_GOOGLE_PHOTOS_ACTIVE,
  SET_GOOGLE_PHOTOS_ACCOUNT_LINKED,
  ADD_GOOGLE_PHOTOS,
  UI_GOOGLE_PHOTOS_ALBUM,
  SET_GOOGLE_PHOTOS_MAIN_ALBUM,
  SET_GOOGLE_PHOTOS_NEXT_URL,
  GOOGLE_PHOTOS_REQUESTING,
  DISABLE_GOOGLE_PHOTOS,
} from './constants';
import { option } from 'fp-ts';
import { Dispatch } from 'redux-thunk';

export const loadGooglePhotosApi = () => {
  const GOOGLE_SDK_URL = 'https://apis.google.com/js/platform.js?onload=init';
  loadScript(GOOGLE_SDK_URL);
};

export const setGooglePhotosActive = (value:boolean) => ({ type: SET_GOOGLE_PHOTOS_ACTIVE, payload: value });
export const setGooglePhotosAccountLinked = (value:boolean) => ({ type: SET_GOOGLE_PHOTOS_ACCOUNT_LINKED, payload: value });

const setGooglePhotosMainAlbum = image => (dispatch) => {
  const gAlbum = { albumId: UI_GOOGLE_PHOTOS_ALBUM, thumbURL: image };
  dispatch({ type: SET_GOOGLE_PHOTOS_MAIN_ALBUM, payload: gAlbum });
};

const setGooglePhotos = googleObjects => ({
  type: ADD_GOOGLE_PHOTOS,
  payload: googleObjects,
});

const setGooglePhotosRequesting = value => ({
  type: GOOGLE_PHOTOS_REQUESTING, payload: value,
});

export const disableGooglePhotos = value => ({
  type: DISABLE_GOOGLE_PHOTOS,
});

const buildGooglePhotosAlbum = googleObjects => (dispatch, getState) => {
  const formattedGooglePhotos = option.fromNullable(googleObjects)
    .map(go =>
      go.map(image => ({
        source: 'google',
        isLoaded: true,
        id: image.id,
        thumbURL: image.baseUrl,
        cdnUrl: `${image.baseUrl}=w${image.mediaMetadata.width}-h${image.mediaMetadata.height}`,
      }))
    )
    .getOrElseValue([]);

  dispatch(setGooglePhotos(formattedGooglePhotos));
  if (!getState().googlePhotos.googlePhotosMainAlbum && formattedGooglePhotos.length > 0) {
    dispatch(setGooglePhotosMainAlbum(formattedGooglePhotos[0].cdnUrl));
  }
};

const fetchGoogleAlbums = () => (dispatch, getState) => {
  const { nextUrl } = getState().googlePhotos;
  const photosUrl = `https://photoslibrary.googleapis.com/v1/mediaItems${nextUrl ? `?pageToken=${nextUrl}` : ''}`;

  const request = window.gapi.client.request({
    method: 'GET',
    path: photosUrl,
    params: {
      pageSize: 100,
    },
  });
  // Execute the API request.
  request.execute((json) => {
    dispatch(buildGooglePhotosAlbum(json.mediaItems));
    dispatch({ type: SET_GOOGLE_PHOTOS_NEXT_URL, payload: json.nextPageToken });
  });
};

export const paginateGooglePhotos = () => (dispatch:any, getState:any) => {
  const { nextUrl } = getState().googlePhotos;
  if (nextUrl) {
    dispatch(setGooglePhotosRequesting(true));
    dispatch(fetchGoogleAlbums());
  }
};

export const openGoogleAuthWindow = () => (dispatch:any) => {
  window.gapi.load('client:auth2', () => {
    window.gapi.client.init({
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/photoslibrary.readonly',
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
    }).then(async () => {
      const GoogleAuth = window.gapi.auth2.getAuthInstance();
      // Listen for sign-in state changes.
      await GoogleAuth.signIn();
      await dispatch(fetchGoogleAlbums());
      await dispatch(showGooglePhotosAlbum());
      dispatch(hideUploadPhotosModal());
    });
  });
};

export const disconnectGoogleAuthWindow = () => (dispatch: Dispatch) => {
  window.gapi.load('client:auth2', () => {
    window.gapi.client.init({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/photoslibrary.readonly',
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
    }).then(async () => {
      const GoogleAuth = window.gapi.auth2.getAuthInstance();
      if (!GoogleAuth.isSignedIn.get()) {
        await GoogleAuth.signIn();
      }

      // Listen for sign-in state changes.
      await GoogleAuth.disconnect();
      dispatch(disableGooglePhotos());
      dispatch(setCurrentAlbum(''));
    });
  });
};
