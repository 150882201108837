import { SET_TEMPLATE, UPDATE_TEMPLATE_LAYER_REGION_DATA_ALL } from './constants';
import { getTemplate } from '../../helpers/templates';

export function setTemplate(template) {
  return {
    type: SET_TEMPLATE,
    payload: {
      template,
    },
  };
}

export const updateTemplateByAttributes = () => (dispatch: Function, getState: Function) => {
  const product = getState().product;
  const productData = window.productData;
  const template = getTemplate(productData)(product.attributes);

  // This sets the Template Object in state by dumping the RETURN of the updateTemplate()
  dispatch(setTemplate(template));
};

export const updateTemplateLayerRegionDataAll = (
  layerId: string,
  className: string,
  data: Object
) => ({
  type: UPDATE_TEMPLATE_LAYER_REGION_DATA_ALL,
  payload: {
    layerId,
    className,
    data,
  },
});
