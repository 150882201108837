// @flow
import * as actionTypes from './actionTypes';
import { Gallery } from 'au-js-sdk/lib/models/Gallery';
import { GalleryImage } from 'au-js-sdk/lib/models/GalleryImage';

export const galleriesSelector = (state): Gallery[] => (state.galleries && state.galleries.galleries) || [];

export const gallerySelector = (state, galleryId: string): Gallery =>
  (galleriesSelector(state) && galleriesSelector(state).find((g) => g.id === galleryId)) || null;

export const myPhotosGallerySelector = (state): Gallery | void =>
  (galleriesSelector(state) && galleriesSelector(state).find((g) => g.name === 'My Photos')) || undefined;

export const isGallerySelector = (state, galleryId: string): boolean => !!gallerySelector(state, galleryId);

export const uploadingGalleryImagesCountSelector = (state): number =>
  (state.galleries && state.galleries.pendingActions.filter((pa) => pa.includes(actionTypes.UPLOAD_IMAGES_TO_GALLERY)).length) || 0;

export const galleryImagesSelector = (state, galleryId: string): GalleryImage[] => {
  const gallery = gallerySelector(state, galleryId);

  if (gallery && gallery !== null) {
    return gallery.images;
  }

  return [];
};

export const galleryImageIsProcessing = (state, id: string): boolean =>
  (state.galleries && state.galleries.pendingActions.filter((pa) => pa.includes(id)).length !== 0);

export const layersWithPendingUploadsSelector = (state): string[] => {
  let layersWithPendingUploads = [];
  if (state.galleries && state.galleries.pendingActions.length > 0) {
    layersWithPendingUploads = state.galleries.pendingActions
      .filter((pa) => pa.includes(actionTypes.UPLOAD_IMAGES_TO_GALLERY) || pa.includes(actionTypes.AUTO_ORIENT_IMAGE_IN_GALLERY))
      .map((pa) => {
        const [actionType, imgId, pageId, layerId] = pa.split('|');
        return layerId;
      })
      .filter((layerId) => !!layerId);
  }
  return layersWithPendingUploads;
};
