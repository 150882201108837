export const UI_FACEBOOK_ALBUM = 'UI_FACEBOOK_ALBUM';
export const SET_FACEBOOK_USER = 'SET_FACEBOOK_USER';
export const SET_FACEBOOK_ALBUMS = 'SET_FACEBOOK_ALBUMS';
export const SET_FACEBOOK_ACTIVE = 'SET_FACEBOOK_ACTIVE';
export const SET_FB_MAIN_ALBUM = 'SET_FB_MAIN_ALBUM';
export const SET_FB_VIEWING_ALBUM = 'SET_FB_VIEWING_ALBUM';
export const SET_FACEBOOK_NEXT_URL = 'SET_FACEBOOK_NEXT_URL';
export const SET_FACEBOOK_REQUESTING = 'FACEBOOK_REQUESTING';
export const ADD_FACEBOOK_PHOTOS = 'ADD_FACEBOOK_PHOTOS';
export const UNSET_FACEBOOK_PHOTOS = 'UNSET_FACEBOOK_PHOTOS';
