import { SET_AUTHORIZED } from './constants';
import { SET_NEW_PROJECT_DATA } from '../constants';

export default function authorizedReducer(state = null, action) {
  switch (action.type) {
    case SET_AUTHORIZED:
      const { isAuthorized } = action.payload;
      return isAuthorized;
    case SET_NEW_PROJECT_DATA:
      return true;
    default:
      // Do nothing if we didn't match action type
      return state;
  }
}
