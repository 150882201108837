// @flow

import {
  convertToSpaces,
} from './urlParameters';
import {
  CALENDARS,
  CATEGORIES_WITH_PAGE_MANIPULATION,
  CATEGORIES_WITH_WELCOME,
  CATEGORIES_WITH_TWO_UP,
  CATEGORIES_WITH_INSIDE_COVERS,
  CATEGORIES_WITH_COVERS,
  CATEGORIES_WITH_RANDOM_DESIGNS,
  CATEGORIES_WITH_LAYOUT_FILTER,
  CATEGORIES_WITH_PARTIAL_PAGE_SELECTION,
  CATEGORIES_WITH_MAT_PREFIX,
  CATEGORIES_WITH_PREVIEW,
  CATEGORIES_WITH_ENDSHEETS,
  CATEGORIES_BOOK,
  CATEGORIES_WITH_ENVELOPES,
  PRINTS,
  SPREAD_BOOKS,
  CARDS,
  FOLDED_CARDS,
  CATEGORIES_WITH_WOODEN_BOX,
  SIGNATURE_SPREAD_BOOKS, SKUS_WITH_COVER_TEXT_V3,
} from '../constants/products';
import {
  Category,
  Template,
} from '../types/templates';
import { type Page } from '../types/page';
import {
  split,
} from './strings';
import match from './match';
import { ENVELOPE_ADDRESSING_ATTRIBUTE } from '../constants/envelopes';
import { optionFind, optionGet } from './functions';
import { any } from './conditionals';
import has from '../helpers/has';
import { option } from 'fp-ts';

// SKU list for apply to all toggle
export const applyToAllSkus: Array<string> = [
  'everyday+print+sets',
  'square+print+sets',
  'signatureprints',
];

// Predicate indicating whether or not a project with the given product category should include a cover.
export const shouldHaveCover = (category: Category): boolean => (
  CATEGORIES_WITH_COVERS.includes(category)
);

// Predicate indicating whether or not a given category of product supports the manipulation of pages and page count.
export const canManipulatePages = (category: Category): boolean => (
  CATEGORIES_WITH_PAGE_MANIPULATION.includes(category)
);

// Predicate indicating whether or not a project with the given product category should show a welcome screen.
export const shouldShowWelcome = (category: Category): boolean => (
  CATEGORIES_WITH_WELCOME.includes(category)
);

// Predicate indicating whether or not a project with the given product category should show the inside covers.
export const shouldShowInsideCovers = (category: Category): boolean => (
  CATEGORIES_WITH_INSIDE_COVERS.includes(category)
);

// Predicate indicating whether or not a project with the given product category should show endsheets.
export const shouldShowEndsheets = (category: Category, pages: Array<Page>): boolean => (
  CATEGORIES_WITH_ENDSHEETS.includes(category)
);

// Predicate indicating whether or not a project with the given product category should show two pages side-by-side.
export const shouldShowTwoUp = (category: Category): boolean => (
  CATEGORIES_WITH_TWO_UP.includes(category)
);

// Predicate indicating whether or not a project with the given product category should have random designs.
export const shouldHaveRandomDesigns = (category: Category): boolean => (
  CATEGORIES_WITH_RANDOM_DESIGNS.includes(category)
);

// Predicate indicating whether or not a project with the given product category should show a filter dropdown in the layout picker.
export const shouldShowLayoutFilter = (category: Category): boolean => (
  CATEGORIES_WITH_LAYOUT_FILTER.includes(category)
);

// Predicate indicating whether or not a surface should allow for selection of either half
export const shouldEnableHalfPageSelection = (category: Category): boolean => (
  CATEGORIES_WITH_PARTIAL_PAGE_SELECTION.includes(category)
);

export const shouldAddMatPrefix = (category: Category): boolean => (
  CATEGORIES_WITH_MAT_PREFIX.includes(category)
);

export const shouldShowPreview = (category: Category): boolean => (
  CATEGORIES_WITH_PREVIEW.includes(category)
);

export const shouldAddEnvelope = (category: Category): boolean => (
  CATEGORIES_WITH_ENVELOPES.includes(category)
);

export const shouldAddMaskPage = (template: Template): boolean => has(template)('mask');

export const shouldCategoryBeOpen = (category: Category): boolean => (
  CATEGORIES_WITH_ENVELOPES.includes(category)
);

export const shouldHaveWoodenBox = (category: Category, attributes: Object) =>
  option
    .fromNullable(attributes)
    .map(attrs => attrs.complete_your_set)
    .map(val => val && val.toLowerCase() === 'box')
    .map(hasBox => hasBox && CATEGORIES_WITH_WOODEN_BOX.includes(category))
    .getOrElseValue(false);

export const isBookCategory = (category: Category): boolean => CATEGORIES_BOOK.includes(category);

export const isCardCategory = (category: Category): boolean => category === CARDS;

export const isFoldedCardCategory = (category: Category): boolean => category === FOLDED_CARDS;

const isPrintCategory = (category: Category): boolean => category === PRINTS;

const isCalendarCategory = (category: Category): boolean => category === CALENDARS;

export const isSignatureSpreadBook = (category: string): boolean => category === SIGNATURE_SPREAD_BOOKS;

// For all intents and purposes, a `signature spread book` behaves exactly like `spread book`, plus a few extra features
export const isSpreadBook = (category: string): boolean => category === SPREAD_BOOKS || category === SIGNATURE_SPREAD_BOOKS;

export const isAutofillable = (category: string, numOfPages: number): boolean =>
  numOfPages > 1 && any([isBookCategory(category), isCalendarCategory(category), isPrintCategory(category)]);

type AttributesArray = Array < {
  key: string,
  value: mixed,
} > ;

/* Converts an attributes object of key value pairs into an array of objects,
   and adds in the page_count option if a page count is specified. */
export const attributesToArray = (attributes: Object, pageCountMinusCover ? : number, category: string = '', keyName = 'key'): AttributesArray => {
  /* baby-board-book is treated as a spread book, and we do not include the page_count in spread book attributes
    thus causing us to include page_count twice where one of them is incorrect */
  let includesPageCount = false;

  return (
    Object.entries(attributes)
      .map(([key, value]: [string, mixed]) => {
        if (key === 'page_count') {
          includesPageCount = true;
        }

        return ({
          [keyName]: key,
          value: convertToSpaces(value),
        });
      })
      .concat(
        !includesPageCount && pageCountMinusCover && canManipulatePages(category) ?
          {
            [keyName]: 'page_count',
            value: pageCountMinusCover,
          } :
          []
      )
  );
};

// Breaks open a card quantity string and pulls out the quantity, as a number.
const qtyFromCardQuantity = (cardQuantity: mixed): number => parseInt(split(' ')(cardQuantity)[0], 10);

// Reduces an attributes array, resulting in either a specified quantity number, or 1.
// qtyFromAttributesArray :: [Object] -> Number
export const qtyFromAttributesArray = (attributes: AttributesArray) => (
  attributes.reduce((qty, {
    key,
    value,
  }) => (
    match(
      'card_quantity', () => qtyFromCardQuantity(value),
      match.default, () => qty,
    )(key)
  ), 1)
);

export const getEnvelopeAddressingAttribute = (attributes: AttributesArray) => (
  optionFind(({
    key,
  }) => key === ENVELOPE_ADDRESSING_ATTRIBUTE, attributesToArray(attributes))

);

export const hasEnvelopeAddressing = (attributes: AttributesArray) => (
  getEnvelopeAddressingAttribute(attributes).alt(optionGet('productData.envelope')(window)).map(() => true).getOrElseValue(false)
);

export const ATTRIBUTES_THAT_AFFECT_COVER = [
  'foil_color',
  'fabric_color',
  'leather_color',
  'dust_jacked',
  'album_size',
  'hardcover_book_size',
  'softcover_book_size',
];


// Parses a product category into a grammatically correct string for use in the AutoFill modals
export const parseCategoryToStr = (category: string): string => {
  if (isBookCategory(category)) {
    return 'book';
  }

  switch (category) {
    case CALENDARS: {
      return 'calendar';
    }

    case PRINTS: {
      return 'prints';
    }

    default: {
      return category;
    }
  }
};

export const productNeedsCoverTextV3 = (productData): boolean => SKUS_WITH_COVER_TEXT_V3.includes(productData.sku);
