// @flow
import React, { PureComponent, type Node as ReactNode, type ElementRef } from 'react';
import { connect, type Dispatch } from 'react-redux';
import { AutoSizer, Grid, InfiniteLoader } from 'react-virtualized';
import { optionGet } from '../../../helpers/functions';
import { setDrawerGridScrollCache as _setDrawerGridScrollCache } from '../../../store/ui/actions';
import { drawerGridScrollCacheValueSelector, isCollapsedView as _isCollapsedView } from '../../../store/ui/selectors';

import './DrawerGrid.css';

type CellRendererProps = {
  columnIndex: number,
  key: string,
  rowIndex: number,
  style: Object,
};

const cellRenderer = (children, columns) => ({ columnIndex, key, rowIndex, style }: CellRendererProps) => (
  <div
    key={key}
    style={style}
  >
    {children[(columns * rowIndex) + columnIndex]}
  </div>
);

const onSectionRendered = (onRowsRendered: Function, numColumns: number) => (
  (args: {
    columnStartIndex: number,
    columnStopIndex: number,
    rowStartIndex: number,
    rowStopIndex: number,
  }) => {
    const { columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex } = args;

    onRowsRendered({
      startIndex: (rowStartIndex * numColumns) + columnStartIndex,
      stopIndex: (rowStopIndex * numColumns) + columnStopIndex,
    });
  }
);

type DrawerGridProps = {
  children: Array<ReactNode>,
  itemWidth: number,
  itemHeight: number,
  centerContainer: boolean,
  setDrawerGridScrollCache: (key: string, value: number) => void,
  cacheKey: string,
  scrollToElement: number,
  fixedHeight: ?number,
  fixedWidth: ?number,
  isCollapsedView: boolean,
  loader: ?(index: number) => void,
  itemCount?: number,
};

type GridRef = ElementRef<typeof Grid>;

type DrawerGridState = {
  scrollToElement: ?number,
};

export class DrawerGrid extends PureComponent<DrawerGridProps, DrawerGridState> {
  grid: ?GridRef;
  isCellLoaded: ({ index: number }) => boolean;
  loadMoreCells: ({ startIndex: number, stopIndex: number }) => Promise<void>;

  static defaultProps = {
    children: [],
    scrollToElement: 0,
    centerContainer: true,
    fixedHeight: undefined,
    fixedWidth: undefined,
    setDrawerGridScrollCache: () => {},
    isCollapsedView: false,
    loader: undefined,
  };

  constructor(props: DrawerGridProps) {
    super(props);

    const { scrollToElement } = props;

    /* Putting scrollToElement into state so we can mount the Grid element scrolled so that the given element is visible,
       and then reset this value so that it doesn't override the Grid's internal scroll value when this component is updated. */
    this.state = { scrollToElement };

    this.isCellLoaded = this.isCellLoaded.bind(this);
    this.loadMoreCells = this.loadMoreCells.bind(this);
  }

  componentDidMount() {
    if (this.grid && this.grid.focus && this.props.isCollapsedView) {
      // Focus on the grid element to improve ability to scroll on touch devices
      this.grid.focus();
    }

    /* We're unsetting the scrollToElement state value in a setTimeout here because it provides a better experience
       (and is more reliable) than waiting for componentDidUpdate to fire. The AutoSizer child takes a little while
       to measure the available space, but it gets the right measurements well before 150ms have elapsed since initial
       component mount, even on very slow CPUs. */
    setTimeout(() => {
      this.setState(() => ({ scrollToElement: undefined }));
    }, 150);
  }

  componentWillUnmount() {
    const { cacheKey, setDrawerGridScrollCache } = this.props;

    const firstElement = this.getFirstVisibleElement();

    /* Save the first visible element to the scroll cache, so that when the user returns to a DrawerGrid with the same cacheKey,
       they'll be looking at roughly the same location in the grid they were last. */
    setDrawerGridScrollCache(cacheKey, firstElement);
  }

  getFirstVisibleElement() {
    const { itemHeight } = this.props;

    // Get the scroll top from the Grid component's state.
    const scrollTop = optionGet('state.scrollTop')(this.grid).getOrElseValue(0);

    // Get the number of columns from the Grid component's props.
    const columnCount = optionGet('props.columnCount')(this.grid).getOrElseValue(1);

    // Calculate the current row by dividing the scrollTop by the item height (both are in pixels).
    const currentRow = Math.round(scrollTop / itemHeight);

    // Find the index of the first element in the current row.
    const firstElement = Math.floor(currentRow * columnCount);

    return firstElement;
  }

  isCellLoaded(args: { index: number }) {
    const { index } = args;
    const { children } = this.props;

    return children.length > index;
  }

  loadMoreCells(args: { startIndex: number, stopIndex: number }) {
    const { loader } = this.props;
    const { startIndex } = args;
    if (loader) {
      loader(startIndex);
    }

    // Returning a resolved Promise here because InfiniteLoader expects one.
    return Promise.resolve();
  }

  render() {
    const {
      children,
      itemWidth,
      itemHeight,
      centerContainer,
      fixedWidth,
      fixedHeight,
      itemCount,
      gallerySortBy
    } = this.props;
    const { scrollToElement } = this.state;
    return (
      <div className="DrawerGrid" key={`DrawerGrid${gallerySortBy}`}>
        <InfiniteLoader
          isRowLoaded={this.isCellLoaded}
          loadMoreRows={this.loadMoreCells} 
          rowCount={itemCount}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width: _width, height: _height }) => {
                let width = _width;
                let height = _height;

                // For tests only, this is yanked from production bundles
                if (process.env.NODE_ENV === 'test') {
                  width = 200;
                  height = 200;
                }
                /* Get the maximum number of columns that will fit within the current space, or the number of columns needed to display
                  every item on one row, whichever is less. This allows smaller arrays of items to be centered. */
                const numColumns = Math.min(Math.floor((fixedWidth || width) / itemWidth), children.length || 1);

                /* Compute the scrollToRow value, if the scrollToElement value is defined in state. This should always be undefined
                   unless the component has recently mounted. Since the Grid component scrolls to the row given in the scrollToRow prop
                   every time it re-renders, we have to reset this value to undefined ourselves to prevent it from overriding the Grid's
                   internal scroll value whenever the component receives new children. */
                const scrollToRow = scrollToElement ? Math.floor(scrollToElement / numColumns) : undefined;

                return (
                  <Grid
                    cellRenderer={cellRenderer(children, numColumns)}
                    width={fixedWidth || width}
                    /* This height adjustment is needed to ensure the slider doesn't cut off some of the last row of items.
                      50px is the height of each drawer's header. */
                    height={(fixedHeight || height) - 50}
                    columnCount={numColumns}
                    rowCount={Math.ceil(children.length / numColumns)}
                    rowHeight={itemHeight}
                    columnWidth={itemWidth}
                    overscanRowCount={3}
                    scrollToRow={scrollToRow}
                    scrollToAlignment="start"
                    onSectionRendered={onSectionRendered(onRowsRendered, numColumns)}
                    containerStyle={{
                      display: 'block',
                      margin: centerContainer ? 'auto' : 0,
                    }}
                    style={{ outline: 'none' }}
                    ref={(r: GridRef) => {
                      registerChild(r);
                      this.grid = r;
                    }}
                  />
                );
              }}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </div>
    );
  }
}

const mapStateToProps = (state: Object, { cacheKey }: { cacheKey: string }) => ({
  scrollToElement: drawerGridScrollCacheValueSelector(cacheKey)(state),
  isCollapsedView: _isCollapsedView(state),
  gallerySortBy: state.ui.gallerySortBy
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setDrawerGridScrollCache: (k: string, v: number) => dispatch(_setDrawerGridScrollCache(k, v)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerGrid);
