// @flow

import findIndex from 'lodash.findindex';
import get from 'lodash.get';
import update from 'immutability-helper';
import { findIndex as _findIndex } from 'fp-ts/lib/Array';
import { option } from 'fp-ts';
import deepClone from 'lodash.clonedeep';

import {
  PROJECT_PAGES_APPLY_CROP_DATA_TO_LAYER,
  PROJECT_PAGES_APPLY_DESIGN_TO_PAGE,
  PROJECT_PAGES_APPLY_PHOTO_TO_LAYER,
  PROJECT_PAGES_APPLY_TEMPLATE,
  PROJECT_PAGES_REMOVE_IMAGE,
  PROJECT_PAGES_APPLY_PAGE_COUNT,
  SET_LAYER_DATA,
  SET_LAYER_REGION_DATA,
  SET_LAYER_REGION_DATA_ALL,
  PROJECT_PAGES_REMOVE_USERPHOTOS,
  SET_IMAGE_META_TO_LAYER,
  PROJECT_PAGES_REMOVE_PAGES,
  PROJECT_PAGES_INSERT_PAGES,
  PROJECT_PAGES_ORDER,
  defaultPages,
  SET_LAYER_DIMS,
  SET_LAYER_AUTO_ENHANCE,
  USER_PHOTOS_REMOVE_ALL,
  PROJECT_PAGES_APPLY_PHOTO_MOD_TO_LAYER,
  SET_DATA_ON_LAYERS,
  SET_LAYER_IMAGE,
} from './constants';

import {
  layersInDesign,
  updateLayers,
  matchingDesignOrDefault,
  renderEditableTextLayers,
  renderEditableTextLayer,
  markLayerIdCustomized,
  markLayerIdInArrayCustomized,
  createDynamicLayer,
  updateEditableTextLayerData,
} from '../../../helpers/layers';

import {
  updateRegionInLayer,
  findWoodenBoxPage,
  layerPathMatches,
  getSurfaceForDesign,
  stripHiddenPages,
  getPageCountWithCover,
  insertHiddenPagesForBooks,
  getDesignsForPage,
  getPageCountMinusCover,
  generatePageId,
  modifyInitialPagesByCategory,
  getNewPageIndex,
} from '../../../helpers/pages';
import { prop } from '../../../helpers/functions';
import {
  changeElementWithId,
  changeArrayElement,
  splice,
  fillArray,
} from '../../../helpers/arrays';
import { UI_FONTS_LOADED } from '../../ui/constants';
import { generatePages } from '../../defaultState';
import type { Surface } from '../../../types/templates';
import { Template } from 'au-js-sdk/lib/models/Template';
import { getPageNameForCategory } from '../../../helpers/templates';
import { withUpdatedTags } from '../../../helpers/tags';
import { type Page } from '../../../types/page';
import { wrapIf } from '../../../helpers/conditionals';
import has from '../../../helpers/has';
import { shouldHaveWoodenBox } from '../../../helpers/product';
import { WOODEN_BOX_PAGE_ID } from '../../../constants/products';
import { generateInitCropData } from '../../../helpers/crop';
import photoHelper from '../../../types/photo'

// Takes the array of pages and applies a target page count to it.
const resizePagesArray = (
  currentPages: Array<Object>,
  targetPageCount: number,
  currentPageCount: number,
  template: Template,
  category: ?string,
  attributes: Object,
  fontsLoaded: boolean
) => {
  if (targetPageCount > currentPageCount) {
    // Generate a new array of pages
    const freshPages = generatePages(
      template,
      category || '',
      attributes,
      targetPageCount,
      fontsLoaded
    );

    // Slice out the subset of the newly-generated pages array that will be added in to the existing pages array.
    const additionalPages = freshPages.slice(
      currentPageCount - targetPageCount
    );

    // Splice together the new pages and the existing pages and return.
    return splice(currentPages, currentPageCount, 0, ...additionalPages);
  } else if (targetPageCount < currentPageCount) {
    // Get the smaller array of pages and return.
    return currentPages.slice(0, targetPageCount);
  }

  // If no changes are needed, just return
  return currentPages;
};

const customizeLayer = (pageId: string, layerId: string, dims: Object) => (
  state: Array<Page>
) =>
  changeElementWithId((page: Page) => ({
    ...page,
    layers: changeElementWithId(layer =>
      // Modify the specified layer with the given dimensions, and re-render it if the width and/or height is being changed.
      wrapIf({
        ...layer,
        id: markLayerIdCustomized(layerId),
        ...dims,
      })(renderEditableTextLayer(page.surface))(
        has(dims)('width') || has(dims)('height')
      )
    )(layerId)(page.layers),
    surface: {
      ...page.surface,
      design: {
        ...page.surface.design,
        // Mark this design as "customized"
        customized: true,
        // Update the array with the customized layer id.
        layers: markLayerIdInArrayCustomized(layerId)(
          page.surface.design.layers
        ),
      },
    },
  }))(pageId)(state);

export default function projectReducer(
  state: Array<Object> = defaultPages,
  action: { type: string, payload: Object }
) {
  switch (action.type) {
    case PROJECT_PAGES_APPLY_TEMPLATE: {
      const {
        template: originalTemplate,
        fontsLoaded,
        attributes,
        userPhotos,
        category,
      } = action.payload;
      // @todo come back to this, there's some mutation happening on template somewhere
      const template = deepClone(originalTemplate);

      // Strip any "hidden" (from a data perspective) pages from the existing pages array
      const visiblePages = stripHiddenPages(state);
      const pageCountWithCover = visiblePages.length;
      const pageCountWithoutCover = getPageCountMinusCover(category)(pageCountWithCover);

      const updatedPages = visiblePages.map((page, i) => {
        // Get the array of designs that are available for this page on the new template

        const designsForPage = getDesignsForPage(
          template.designs,
          pageCountWithCover,
          i + 1,
          pageCountWithoutCover
        );

        // Get the surface prior to any design change.
        const prevSurface: Surface = page.surface;

        /* Get a design from the available designs in the new template that matches the
           existing page's design, or get the default design. */
        const [design, additionalLayers] = matchingDesignOrDefault(
          designsForPage,
          page.surface.design,
          template.layers,
          prop('layers')(page).getOrElseValue([]),
          prevSurface,
          template.surface,
          template.additionalSurfaces
        );

        // Get the layers in the selected design
        const designLayers = layersInDesign(
          design,
          template.layers.concat(additionalLayers)
        );

        // Get the surface as specified by the new design.
        const newSurface = getSurfaceForDesign(design, template);

        // Set the proper surface and design on the page
        return {
          ...page,
          name: template?.insideCoversAreEditable ? page.name : getPageNameForCategory(category)(i, attributes), // for editableInsideCovers, names have already been adjusted
          surface: {
            ...newSurface,
            design,
          },
          layers: updateLayers(
            designLayers,
            page.layers,
            i,
            prevSurface,
            newSurface,
            fontsLoaded,
            attributes,
            userPhotos
          ),
        };
      });

      // Handle any wooden box page logic for signature layflats
      const existingWoodenBoxPage = findWoodenBoxPage(state);
      if (existingWoodenBoxPage && shouldHaveWoodenBox(category, attributes)) {
        // If we have one and the updated template calls for a box, update the page data and tack it onto the end of pages

        const updatedWoodenBoxPage = option
          .fromNullable(existingWoodenBoxPage)
          .map((wbp) => {
            const _wbp = deepClone(wbp);
            _wbp.layers = wbp.layers.map((layer) => {
              const dynamicLayer = createDynamicLayer(layer)(null, attributes);
              const data = updateEditableTextLayerData(
                layer,
                dynamicLayer,
                template.woodenBox.surface,
                true
              );

              const newLayer = {
                ...layer,
                data,
              };

              return newLayer;
            });

            return _wbp;
          })
          .getOrElseValue(existingWoodenBoxPage);

        return modifyInitialPagesByCategory(
          updatedPages,
          category,
          template,
          attributes,
          false
        ).concat(updatedWoodenBoxPage);
      }

      // Otherwise, re-insert the "hidden" pages, if necessary
      return modifyInitialPagesByCategory(
        updatedPages,
        category,
        template,
        attributes,
        shouldHaveWoodenBox(category, attributes) // This will insert a blank wooden box page
      );
    }

    case SET_IMAGE_META_TO_LAYER: {
      const { pageId, layerId, imgMeta } = action.payload;

      const pageIndex = findIndex(state, page => page.id === pageId);

      const page = state[pageIndex];
      const layerIndex = findIndex(page.layers, layer => layerId === layer.id);

      return update(state, {
        [pageIndex]: {
          $apply: currentPage =>
            update(currentPage || {}, {
              layers: {
                [layerIndex]: {
                  $apply: layer =>
                    update(layer || {}, {
                      // Update layer with data object
                      data: {
                        $apply: data =>
                          update(data || {}, {
                            $merge: {
                              imageMetadata: imgMeta,
                            },
                          }),
                      },
                    }),
                },
              },
            }),
        },
      });
    }

    case PROJECT_PAGES_APPLY_PAGE_COUNT: {
      const {
        template,
        desiredPageCount,
        attributes,
        category,
        fontsLoaded,
      } = action.payload;

      // Strip out the "hidden" (from a data perspective) "inside cover" pages from the state.
      const currentPages = stripHiddenPages(state);

      // Get the length of the "visible" (from a data perspective) pages array
      const currentPageCount = currentPages.length;

      // "Clamp" the desiredPageCount by the pages.max and pages.min values in the template
      const targetPageCount = getPageCountWithCover(template)(category)(desiredPageCount);

      const updatedPages = resizePagesArray(
        currentPages,
        targetPageCount,
        currentPageCount,
        template,
        category,
        attributes,
        fontsLoaded
      );

      // Re-insert the "inner cover" pages, if necessary, and return
      // Check for an existing wooden box page
      const existingWoodenBoxPage = findWoodenBoxPage(state);

      if (existingWoodenBoxPage) {
        return modifyInitialPagesByCategory(
          updatedPages,
          category,
          template,
          attributes,
          false
        ).concat(existingWoodenBoxPage);
      }

      // Re-insert the "hidden" pages, if necessary
      return modifyInitialPagesByCategory(
        updatedPages,
        category,
        template,
        attributes,
        shouldHaveWoodenBox(category, attributes) // This will insert a blank wooden box page
      );
    }

    case PROJECT_PAGES_INSERT_PAGES: {
      const {
        template,
        desiredPageCount,
        category,
        attributes,
        pageId,
        pagesToAdd,
        fontsLoaded,
      } = action.payload;
      const currentPages = stripHiddenPages(state);
      // the index to insert new pages after
      const insertIndex = option
        .fromNullable(pageId)
        .chain(id => _findIndex(x => x.id === id)(currentPages))
        .map(x => x + 1);

      // ids of the new pages to insert
      const newPageIds = insertIndex
        .map(index =>
          fillArray(null)(pagesToAdd).map((_, i) => `page_${index + i}`)
        )
        .getOrElseValue([]);

      // the new pages
      const newPages = generatePages(
        template,
        category,
        attributes,
        desiredPageCount,
        fontsLoaded
      ).filter(x => newPageIds.includes(x.id));

      // the first half of our pages, up untill our insert index
      const first = insertIndex.map(index => currentPages.slice(0, index));
      const firstLen = first.map(xs => xs.length).getOrElseValue(0);

      // tail of our pages after inserted pages w/ updated ids/names
      const last = insertIndex
        .map(index => currentPages.slice(index))
        .map(xs =>
          xs.map((x, i) => ({
            ...x,
            id: generatePageId(firstLen + pagesToAdd + i + 1, category),
            name: getPageNameForCategory(category)(
              i + firstLen + pagesToAdd,
              {}
            ),
          }))
        );

      const updatedPages = [
        ...first.getOrElseValue([]),
        ...newPages,
        ...last.getOrElseValue([]),
      ];

      return insertHiddenPagesForBooks(updatedPages, category);
    }

    case PROJECT_PAGES_APPLY_DESIGN_TO_PAGE: {
      const {
        design,
        pageId,
        template,
        fontsLoaded,
        attributes,
        userPhotos,
        additionalLayers,
      } = action.payload;

      const selectedPage = state.find((page) => page.id === pageId);

      // Only modify the state if there is a page existing with the given pageId
      if (selectedPage) {
        // Gets the layers in the design from the template's layers array, as well as the additionalLayers array, if any.
        const designLayers = layersInDesign(
          design,
          template.layers.concat(additionalLayers)
        );
        const existingLayers = selectedPage.layers;

        const updatedState: Array<Page> = state.map((page, pageNum) =>
          (page.id === pageId
            ? {
              ...page,
              layers: updateLayers(
                designLayers,
                existingLayers,
                pageNum,
                page.surface,
                page.surface,
                fontsLoaded,
                attributes,
                userPhotos
              ),
              surface: {
                ...getSurfaceForDesign(design, template),
                design,
              },
            }
            : page)
        );

        return updatedState;
      }

      return state;
    }

    case PROJECT_PAGES_APPLY_PHOTO_TO_LAYER: {
      const { userPhotoId, pageId, layerId, p } = action.payload;
      const extractedPhoto = photoHelper.extract(p)
      const updatedState: Array<Page> = state.map((page) => {
        if (page.id === pageId) {
          return {
            ...page,
            layers: page.layers.map((layer) => {
              if (layerId === layer.id) {
                return {
                  ...layer,
                  data: {
                    ...layer.data,
                    cropData: (layer.data && layer.data.cropData) ? layer.data.cropData : generateInitCropData(extractedPhoto, layer, page.surface),
                    userPhotoId,
                    photoModifications: {},
                  },
                };
              }
              return layer;
            }),
          };
        }
        return page;
      });
      return updatedState;
    }

    case PROJECT_PAGES_REMOVE_USERPHOTOS: {
      const { layerPaths } = action.payload;

      const updatedState: Array<Page> = state.map((page) => ({
        ...page,
        layers: page.layers.map((layer) =>
          (layerPathMatches(page.id)(layer.id)(...layerPaths)
            ? {
              ...layer,
              data: {},
            }
            : layer)
        ),
      }));

      return updatedState;
    }

    case USER_PHOTOS_REMOVE_ALL: {
      const updatedState: Array<Page> = state.map(page => ({
        ...page,
        layers: page.layers.map(layer =>
          (layer.type === 'user_photo'
            ? {
              ...layer,
              data: {
                userPhotoId: null,
              },
            }
            : layer)
        ),
      }));
      return updatedState;
    }

    case PROJECT_PAGES_REMOVE_PAGES: {
      const { pageIds, category } = action.payload;

      const filteredPages = stripHiddenPages(state)
        .filter(x => pageIds.indexOf(x.id) === -1)
        .map((x, i) => ({
          ...x,
          id: generatePageId(i + 1, category),
          name: getPageNameForCategory(category)(i, {}),
        }));

      const existingWoodenBoxPage = findWoodenBoxPage(state);
      if (existingWoodenBoxPage) {
        return insertHiddenPagesForBooks(filteredPages, category).concat(existingWoodenBoxPage);
      }

      return insertHiddenPagesForBooks(filteredPages, category);
    }

    case PROJECT_PAGES_REMOVE_IMAGE: {
      const { pageId, layerId } = action.payload;

      /**
       * Retrieve page index and layer index so we can update the appropriate page and layer
       */
      const pageIndex = findIndex(state, page => page.id === pageId);

      const page = state[pageIndex];
      const layerIndex = findIndex(page.layers, layer => layerId === layer.id);

      /**
       * This is about as complex as we will get with our reducer updates.
       * The apply function gets the value of the key it is assigned to and
       * returns the result of the anonymous function.
       * @type {Object}
       */
      return update(state, {
        [pageIndex]: {
          $apply: currentPage =>
            update(currentPage || {}, {
              layers: {
                [layerIndex]: {
                  $apply: layer =>
                    update(layer || {}, {
                      // Update layer with data object
                      $merge: {
                        data: {
                          // Eventually, when we store more data, this should be a merge too
                          userPhotoId: null,
                        },
                      },
                    }),
                },
              },
            }),
        },
      });
    }

    case PROJECT_PAGES_APPLY_CROP_DATA_TO_LAYER: {
      const { cropData, pageId, layerId } = action.payload;

      /**
       * Retrieve page index and layer index so we can update the appropriate page and layer
       */
      const pageIndex = findIndex(state, page => page.id === pageId);

      const page = state[pageIndex];
      const layerIndex = findIndex(page.layers, layer => layerId === layer.id);

      if ('name' in cropData) {
        return state;
      }
      /**
       * This is about as complex as we will get with our reducer updates.
       * The apply function gets the value of the key it is assigned to and
       * returns the result of the anonymous function.
       * @type {Object}
       */
      return update(state, {
        [pageIndex]: {
          $apply: currentPage =>
            update(currentPage || {}, {
              layers: {
                [layerIndex]: {
                  $apply: layer =>
                    update(layer || {}, {
                      // Update layer with data object
                      data: {
                        $apply: data =>
                          update(data || {}, {
                            $merge: {
                              cropData,
                            },
                          }),
                      },
                    }),
                },
              },
            }),
        },
      });
    }

    case PROJECT_PAGES_APPLY_PHOTO_MOD_TO_LAYER: {
      const { photoModifications, pageId, layerId } = action.payload;
      const pageIndex = findIndex(state, page => page.id === pageId);

      const page = state[pageIndex];
      const layerIndex = findIndex(page.layers, layer => layerId === layer.id);
      const filterObj = all =>
        prop('filter')(photoModifications)
          .map((filter) => {
            switch (filter) {
              case 'BlackAndWhite':
                return {
                  ...all,
                  proFilter: false,
                  grayscaleFilter: true,
                };
              case 'Light':
                return {
                  ...all,
                  proFilter: true,
                  grayscaleFilter: false,
                };
              default:
                return {
                  ...all,
                  proFilter: false,
                  grayscaleFilter: false,
                };
            }
          })
          .getOrElseValue({
            proFilter: false,
            grayscaleFilter: false,
          });

      const photoMods = Object.keys(photoModifications).reduce(
        (acc, key) =>
          (key === 'brightness'
            ? { ...filterObj(acc), [key]: photoModifications[key] }
            : filterObj(acc)),
        {}
      );

      return update(state, {
        [pageIndex]: {
          $apply: currentPage =>
            update(currentPage || {}, {
              layers: {
                [layerIndex]: {
                  $apply: layer =>
                    update(layer || {}, {
                      // Update layer with data object
                      data: {
                        $apply: data =>
                          update(data || {}, {
                            $merge: {
                              photoModifications: {
                                ...photoMods,
                                ...photoModifications,
                              },
                            },
                          }),
                      },
                    }),
                },
              },
            }),
        },
      });
    }

    case SET_LAYER_REGION_DATA: {
      const { pageId, layerId, className, data } = action.payload;

      return changeElementWithId(page => ({
        ...page,
        layers: changeElementWithId(layer => ({
          ...layer,
          regions: changeArrayElement(region => ({
            ...region,
            data,
          }))(region => region.className === className)(layer.regions),
        }))(layerId)(page.layers),
      }))(pageId)(state);
    }

    case SET_LAYER_REGION_DATA_ALL: {
      const { layerId, className, data } = action.payload;
      return state.map(page => updateRegionInLayer({
        page,
        layerId,
        className,
      }, { data }));
    }

    case SET_LAYER_IMAGE: {
      const { pageId, layerId, image } = action.payload;
      const updatedState: Array<Page> = changeElementWithId(page => (
        {
          ...page,
          layers: changeElementWithId(layer => (
            {
              ...layer,
              image,
            }
          ))(layerId)(page.layers),
        }
      ))(pageId)(state);

      return updatedState;
    }

    /* Create new state with a given layer's data object equal to its previous contents (if any),
       with properties overwritten with the contents of the given data object */
    case SET_LAYER_DATA: {
      const { pageId, layerId, data } = action.payload;
      const updatedState: Array<Page> = changeElementWithId(page => ({
        ...page,
        layers: changeElementWithId(layer => ({
          ...layer,
          data: withUpdatedTags(layer.defaultData)({
            ...get(layer, 'data', {}),
            ...data,
          }),
        }))(layerId)(page.layers),
      }))(pageId)(state);

      return updatedState;
    }

    /* Same as SET_LAYER_DATA but for multiple layers */
    case SET_DATA_ON_LAYERS: {
      const { layers } = action.payload;
      const updatedState = layers.reduce((accum, layerChange) => {
        const [data, pageId, layerId] = layerChange;
        return changeElementWithId(page => (
          {
            ...page,
            layers: changeElementWithId(layer => (
              {
                ...layer,
                data: withUpdatedTags(layer.defaultData)({
                  ...(get(layer, 'data', {})),
                  ...data,
                }),
              }
            ))(layerId)(page.layers),
          }
        ))(pageId)(accum);
      }, state);

      return updatedState;
    }

    // Once the fonts are loaded, re-render all existing text layers.
    case UI_FONTS_LOADED: {
      const updatedState: Array<Page> = state.map(page => ({
        ...page,
        layers: renderEditableTextLayers(page.layers)(page.surface),
      }));

      return updatedState;
    }

    case SET_LAYER_DIMS: {
      const { pageId, layerId, dims } = action.payload;

      return customizeLayer(pageId, layerId, dims)(state);
    }

    case PROJECT_PAGES_ORDER: {
      const { newIndex, pageIds, category } = action.payload;
      const newPageIndex = getNewPageIndex(category, newIndex, pageIds);
      const currentPages = stripHiddenPages(state);
      const pagesNotMoving = currentPages.filter(x => !pageIds.includes(x.id));
      const pagesMoving = currentPages
        .filter(x => pageIds.includes(x.id))
        .map((x, i) => ({
          ...x,
          id: generatePageId(newPageIndex + i + 1, category),
          name: getPageNameForCategory(category)(newPageIndex + i, {}),
        }));

      const first = pagesNotMoving.slice(0, newPageIndex).map((x, i) => ({
        ...x,
        id: generatePageId(i + 1, category),
        name: getPageNameForCategory(category)(i, {}),
      }));

      const last = pagesNotMoving.slice(newPageIndex).map((x, i) => ({
        ...x,
        id: generatePageId(newPageIndex + pageIds.length + i + 1, category),
        name: getPageNameForCategory(category)(
          newPageIndex + pageIds.length + i,
          {}
        ),
      }));

      // Don't forget about the wooden box page
      const woodenBoxPage = state.filter(x => x.id === WOODEN_BOX_PAGE_ID).filter(x => x !== undefined);

      const orderedPages = [...first, ...pagesMoving, ...last, ...woodenBoxPage];

      return modifyInitialPagesByCategory(orderedPages, category, null);
    }

    case SET_LAYER_AUTO_ENHANCE: {
      const { pageId, layerId, isAutoEnhanced } = action.payload;

      const updatedState: Array<Page> = changeElementWithId(page => ({
        ...page,
        layers: changeElementWithId(layer => ({
          ...layer,
          data: withUpdatedTags(layer.defaultData)({
            ...get(layer, 'data', {}),
            isAutoEnhanced,
          }),
        }))(layerId)(page.layers),
      }))(pageId)(state);

      return updatedState;
    }

    default: {
      // Do nothing if we didn't match action type
      return state;
    }
  }
}
