// @flow

export const methods = {
  GET_CDN_URL: 'GET_CDN_URL',
  POST_IMAGE: 'POST_IMAGE',
  DELETE_IMAGE: 'DELETE_IMAGE',
  GET_IMAGE: 'GET_IMAGE',
  GET_IMAGE_FROM_S3: 'GET_IMAGE_FROM_S3',
  GET_IMAGES: 'GET_IMAGES',
  DELETE_ALBUM: 'DELETE_ALBUM',
  POST_ALBUM: 'POST_ALBUM',
  GET_ALBUMS: 'GET_ALBUMS',
  GET_SIGNED_CSV_UPLOAD: 'GET_SIGNED_CSV_UPLOAD',
  GET_SIGNED_PHOTO_UPLOAD: 'GET_SIGNED_PHOTO_UPLOAD',
};

export type APImethod = $Keys<typeof methods>;

export const JSON_FETCH_ERROR = 'JSON_FETCH_ERROR';
