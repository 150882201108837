/* eslint-disable */
import { IdentifyValidator, AccountCreatedValidator, ImageAddedValidator, ImageEditedValidator, ImageUploadedValidator, PageViewedValidator, ProductAddedValidator, ProjectEditedValidator, ProjectStartedValidator, SignedInValidator, SignInDisplayedValidator, ContextValidator } from './validators';

class SegmentBrowserAdapter {
  get segment() {
    const s = typeof self === 'object' && self.self === self && self;
    return s && s.analytics;
  }

  constructor(options, apiKey) {
    if (!this.segment) {
      // Segment (https://segment.com/docs/sources/website/analytics.js/quickstart/)
      !(function () {
        var analytics = (window.analytics = window.analytics || []);
        if (!analytics.initialize) {
          if (analytics.invoked)
            window.console &&
              console.error &&
              console.error('Segment snippet included twice.');
          else {
            analytics.invoked = !0;
            analytics.methods = [
              'trackSubmit',
              'trackClick',
              'trackLink',
              'trackForm',
              'pageview',
              'identify',
              'reset',
              'group',
              'track',
              'ready',
              'alias',
              'debug',
              'page',
              'once',
              'off',
              'on',
            ];
            analytics.factory = function (t) {
              return function () {
                var e = Array.prototype.slice.call(arguments);
                e.unshift(t);
                analytics.push(e);
                return analytics;
              };
            };
            for (var t = 0; t < analytics.methods.length; t++) {
              var e = analytics.methods[t];
              analytics[e] = analytics.factory(e);
            }
            analytics.load = function (t, e) {
              var n = document.createElement('script');
              n.type = 'text/javascript';
              n.async = !0;
              n.src =
                'https://cdn.segment.com/analytics.js/v1/' +
                t +
                '/analytics.min.js';
              var a = document.getElementsByTagName('script')[0];
              a.parentNode.insertBefore(n, a);
              analytics._loadOptions = e;
            };
            analytics.SNIPPET_VERSION = '4.1.0';
          }
        }
      })();
    }
    this.segment.load(
      apiKey,
      options.destinations &&
        options.destinations.segment &&
        options.destinations.segment.config,
    );
  }

  init() {
    // N/A for Segment
  }

  alias(userId, previousId) {
    this.segment.alias({ userId, previousId });
  }

  identify(userId, properties) {
    if (userId) {
      this.segment.identify(userId, properties);
    } else {
      this.segment.identify(properties);
    }
  }

  group(userId, groupId, properties) {
    this.segment.group(groupId, properties);
  }

  page(userId, category, name, properties) {
    this.segment.page(category, name, properties);
  }

  track(userId, eventName, properties) {
    this.segment.track(eventName, properties);
  }

  reset() {
    this.segment.reset();
  }
}

class Itly {
  constructor() {
    this.options = undefined;
    this.adapters = [];
  }

  /*
   * Initialize the Itly SDK. Call once when your application starts.
   * @param {Object} options Configuration options to initialize the Itly SDK with.
   * @param {Object|Function} options.context Set of properties to add to every event.
   * @param {boolean} [options.disabled=false] Flag to completely disable the Itly SDK.
   * @param {string} [options.environment=development] The environment the Itly SDK is running in (development or production).
   * @param {Object} [options.logger] A custom logger with debug, info, warn, and error methods.
   * @param {Object} [options.destinations] Analytics provider-specific configuration.
   */
  init(options) {
    if (!options.context) {
      throw new Error('Your tracking plan contains at least one context property but a `context` object or resolver function was not provided on `options`.');
    }

    if (this.options) {
      throw new Error('Itly is already initialized.');
    }

    this.options = options;

    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.adapters.push(new SegmentBrowserAdapter(options, this.options.environment === 'production' ? 'FiAHpeHX0sUOoW4qtncLwpLmDofisVao' : 'xkJFNI7piCweJWstKQktvx8zmqSOxmcM'));

    this.adapters.forEach(d => d.init());
  }

  /**
   * Alias a user ID to another user ID.
   * @param {string} userId The user's new ID.
   * @param {string} previousId The user's previous ID.
   */
  alias(userId, previousId) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.adapters.forEach(adapter => adapter.alias(userId, previousId));
  }

  /**
   * Identify a user and set or update that user's properties.
   * @param {string} [userId] The user's ID.
   * @param {Object} properties The user's properties.
   * @param {string} properties.email Property has no description in tracking plan.
   * @param {string} properties.first_name Property has no description in tracking plan.
   * @param {string} properties.last_name Property has no description in tracking plan.
   * @param {boolean} properties.unsubscribed Used downstream for Blueshift. Set to false in the case of a Gleam pop-up or other indicator that they should be resubscribed to our mailing list.
   */
  identify(userId, properties) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    if (Object.prototype.toString.call(userId) === '[object Object]') {
      properties = userId;
      userId = undefined;
    }

    if (!properties) {
      throw new Error('Your tracking plan contains at least one user property but `properties` were not passed as an argument.');
    }

    this.validateObject('user', IdentifyValidator, properties);

    this.adapters.forEach(adapter => adapter.identify(userId, properties));
  }

  /**
   * Associate the current user with a group.
   * @param {string} groupId The group's ID.
   */
  group(groupId) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.adapters.forEach(adapter => adapter.group(undefined, groupId, undefined));
  }
  
  /**
   * Track a page view.
   * @param {string} category The page's category.
   * @param {string} name The page's name.
   */
  page(category, name) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.adapters.forEach(adapter => adapter.page(undefined, category, name, undefined));
  }

  /**
   * User created a new account
   *
   * Owner: carly@artifactuprising.com
   * @param {Object} properties The event's properties.
   * @param {string} properties.email The customer's email address
   * @param {string} properties.site_id Used by Blueshift; default to us:web or us:iphoneapp
   * @param {number} properties.user_id The customer's Magento ID
   */
  trackAccountCreated(properties) {
    if (!properties) {
      throw new Error('There is at least one property defined on this event in your tracking plan but `properties` were not passed as an argument.');
    }

    this.trackEvent(
      undefined,
      'Account Created',
      properties,
      '9499caed-19f2-4505-92fb-7827df9fea38',
      '1.0.0',
      AccountCreatedValidator,
    );
  }

  /**
   * Fires whenever an image is added to a project
   *
   * Owner: carly@artifactuprising.com
   * @param {Object} properties The event's properties.
   * @param {string} properties.au_project_version Editor version (1 or 2). Required for any events in Editor
   * @param {string} properties.au_split_editorV2_books INACTIVE
   * @param {boolean} properties.au_split_editorV2_feature_flag_autofill Property has no description in tracking plan.
   * @param {string} properties.au_split_editorV2_guestbookset Property has no description in tracking plan.
   * @param {string} properties.au_split_editorV2_spread_books Split for Spread Books testing
   * @param {boolean} properties.au_split_featureflag-chooseimagemodalflow Property has no description in tracking plan.
   * @param {boolean} properties.au_split_feature_flag_google_sign_in Property containing the split treatment for whether the Google log in/sign up buttons are shown to the user
   * @param {boolean} properties.au_split_feature_flag_product_search Property containing the split treatment for the new product search experience at commerce
   * @param {string} properties.category The reporting product category in Magento
   * @param {string} properties.editor_version Editor minor version (e.g. 1.62.1)
   * @param {boolean} properties.is_customizable_product True if the product can be customized in editor; false if it goes straight to the cart
   * @param {boolean} properties.is_mobile_view Is the user using a mobile device?
   * @param {string} properties.name Product name for the parent product
   * @param {string} properties.platform User's operating system
   * @param {number} properties.price Price as displayed to the customer; in some events, such as Product Viewed and Product List Viewed, this will be set to the Lowest Price since price can change based on options selected
   * @param {number} properties.product_id The product_id for the parent product
   * @param {string} properties.product_line The reporting product line in Magento
   * @param {Object[]} properties.products Products in the event. This is used by at least Blueshift to manage campaigns. Should contain the following properties:  product\_id sku name price category (aka: Reporting Product Category) product\_line (aka: Reporting Product Line)
   * @param {string} properties.product_type The reporting product type in Magento
   * @param {string} properties.project_id Property has no description in tracking plan.
   * @param {string} properties.sku SKU for the parent product
   */
  trackImageAdded(properties) {
    if (!properties) {
      throw new Error('There is at least one property defined on this event in your tracking plan but `properties` were not passed as an argument.');
    }

    this.trackEvent(
      undefined,
      'Image Added',
      properties,
      '2a25c908-8492-40fc-a36e-82fc1bd57c7b',
      '15.0.1',
      ImageAddedValidator,
    );
  }

  /**
   * Fire this event whenever a user edits an image within a project
   *
   * Owner: carly@artifactuprising.com
   * @param {Object} properties The event's properties.
   * @param {string} properties.au_project_version Editor version (1 or 2). Required for any events in Editor
   * @param {string} properties.au_split_editorV2_books INACTIVE
   * @param {boolean} properties.au_split_editorV2_feature_flag_autofill Property has no description in tracking plan.
   * @param {string} properties.au_split_editorV2_guestbookset Property has no description in tracking plan.
   * @param {string} properties.au_split_editorV2_spread_books Split for Spread Books testing
   * @param {boolean} properties.au_split_featureflag-chooseimagemodalflow Property has no description in tracking plan.
   * @param {boolean} properties.au_split_feature_flag_google_sign_in Property containing the split treatment for whether the Google log in/sign up buttons are shown to the user
   * @param {boolean} properties.au_split_feature_flag_product_search Property containing the split treatment for the new product search experience at commerce
   * @param {string} properties.category The reporting product category in Magento
   * @param {string} properties.editor_version Editor minor version (e.g. 1.62.1)
   * @param {boolean} properties.is_customizable_product True if the product can be customized in editor; false if it goes straight to the cart
   * @param {boolean} properties.is_mobile_view Is the user using a mobile device?
   * @param {string} properties.name Product name for the parent product
   * @param {string} properties.platform User's operating system
   * @param {number} properties.price Price as displayed to the customer; in some events, such as Product Viewed and Product List Viewed, this will be set to the Lowest Price since price can change based on options selected
   * @param {number} properties.product_id The product_id for the parent product
   * @param {string} properties.product_line The reporting product line in Magento
   * @param {Object[]} properties.products Products in the event. This is used by at least Blueshift to manage campaigns. Should contain the following properties:  product\_id sku name price category (aka: Reporting Product Category) product\_line (aka: Reporting Product Line)
   * @param {string} properties.product_type The reporting product type in Magento
   * @param {string} properties.project_id Property has no description in tracking plan.
   * @param {string} properties.sku SKU for the parent product
   */
  trackImageEdited(properties) {
    if (!properties) {
      throw new Error('There is at least one property defined on this event in your tracking plan but `properties` were not passed as an argument.');
    }

    this.trackEvent(
      undefined,
      'Image Edited',
      properties,
      '8dc5d337-4029-4ef8-9363-1bdca51743d8',
      '14.0.1',
      ImageEditedValidator,
    );
  }

  /**
   * Called when an image is uploaded to a gallery
   *
   * Owner: carly@artifactuprising.com
   * @param {Object} properties The event's properties.
   * @param {string} properties.au_project_version Editor version (1 or 2). Required for any events in Editor
   * @param {string} properties.au_split_editorV2_books INACTIVE
   * @param {boolean} properties.au_split_editorV2_feature_flag_autofill Property has no description in tracking plan.
   * @param {string} properties.au_split_editorV2_guestbookset Property has no description in tracking plan.
   * @param {string} properties.au_split_editorV2_spread_books Split for Spread Books testing
   * @param {boolean} properties.au_split_featureflag-chooseimagemodalflow Property has no description in tracking plan.
   * @param {boolean} properties.au_split_feature_flag_google_sign_in Property containing the split treatment for whether the Google log in/sign up buttons are shown to the user
   * @param {boolean} properties.au_split_feature_flag_product_search Property containing the split treatment for the new product search experience at commerce
   * @param {string} properties.category The reporting product category in Magento
   * @param {string} properties.editor_version Editor minor version (e.g. 1.62.1)
   * @param {string} properties.gallery_name Property has no description in tracking plan.
   * @param {string} properties.image_source Facebook, Google Photos, Instagram, Direct Upload, etc.
   * @param {boolean} properties.is_customizable_product True if the product can be customized in editor; false if it goes straight to the cart
   * @param {boolean} properties.is_mobile_view Is the user using a mobile device?
   * @param {string} properties.name Product name for the parent product
   * @param {string} properties.platform User's operating system
   * @param {number} properties.price Price as displayed to the customer; in some events, such as Product Viewed and Product List Viewed, this will be set to the Lowest Price since price can change based on options selected
   * @param {number} properties.product_id The product_id for the parent product
   * @param {string} properties.product_line The reporting product line in Magento
   * @param {Object[]} properties.products Products in the event. This is used by at least Blueshift to manage campaigns. Should contain the following properties:  product\_id sku name price category (aka: Reporting Product Category) product\_line (aka: Reporting Product Line)
   * @param {string} properties.product_type The reporting product type in Magento
   * @param {string} properties.project_id Property has no description in tracking plan.
   * @param {string} properties.sku SKU for the parent product
   */
  trackImageUploaded(properties) {
    if (!properties) {
      throw new Error('There is at least one property defined on this event in your tracking plan but `properties` were not passed as an argument.');
    }

    this.trackEvent(
      undefined,
      'Image Uploaded',
      properties,
      'f6cebb85-e9c1-483b-a103-2cba93e32d50',
      '16.0.1',
      ImageUploadedValidator,
    );
  }

  /**
   * Called when any page is viewed. Should be called whenever "Page" is called. Only call once when in the editor.
   *
   * Owner: carly@artifactuprising.com
   * @param {Object} properties The event's properties.
   * @param {string} properties.page_name Home, Floating Picture Frame, Shopping Cart, etc.
   * @param {string} properties.page_type Product Description Page, Category Listing Page, My Account, Checkout, HomePage
   */
  trackPageViewed(properties) {
    if (!properties) {
      throw new Error('There is at least one property defined on this event in your tracking plan but `properties` were not passed as an argument.');
    }

    this.trackEvent(
      undefined,
      'Page Viewed',
      properties,
      'e484fb13-be71-4393-bb4b-c3f144b90f4b',
      '1.0.0',
      PageViewedValidator,
    );
  }

  /**
   * User added a product to their shopping cart
   *
   * Owner: carly@artifactuprising.com
   * @param {Object} properties The event's properties.
   * @param {*} properties.ab_test List of AB tests in this format: upload_experience = "new_experience"
   * @param {string} properties.au_project_version Editor version (1 or 2). Required for any events in Editor
   * @param {string} properties.au_split_editorV2_books INACTIVE
   * @param {boolean} properties.au_split_editorV2_feature_flag_autofill Property has no description in tracking plan.
   * @param {string} properties.au_split_editorV2_guestbookset Property has no description in tracking plan.
   * @param {string} properties.au_split_editorV2_spread_books Split for Spread Books testing
   * @param {boolean} properties.au_split_featureflag-chooseimagemodalflow Property has no description in tracking plan.
   * @param {boolean} properties.au_split_feature_flag_google_sign_in Property containing the split treatment for whether the Google log in/sign up buttons are shown to the user
   * @param {boolean} properties.au_split_feature_flag_product_search Property containing the split treatment for the new product search experience at commerce
   * @param {string} properties.category The reporting product category in Magento
   * @param {string} properties.editor_version Editor minor version (e.g. 1.62.1)
   * @param {boolean} properties.is_customizable_product True if the product can be customized in editor; false if it goes straight to the cart
   * @param {boolean} properties.is_mobile_view Is the user using a mobile device?
   * @param {string} properties.name Product name for the parent product
   * @param {string} properties.platform User's operating system
   * @param {number} properties.price Price as displayed to the customer; in some events, such as Product Viewed and Product List Viewed, this will be set to the Lowest Price since price can change based on options selected
   * @param {number} properties.product_id The product_id for the parent product
   * @param {string} properties.product_line The reporting product line in Magento
   * @param {Object[]} properties.products Products in the event. This is used by at least Blueshift to manage campaigns. Should contain the following properties:  product\_id sku name price category (aka: Reporting Product Category) product\_line (aka: Reporting Product Line)
   * @param {string} properties.product_type The reporting product type in Magento
   * @param {string} properties.project_id Property has no description in tracking plan.
   * @param {string} properties.sku SKU for the parent product
   */
  trackProductAdded(properties) {
    if (!properties) {
      throw new Error('There is at least one property defined on this event in your tracking plan but `properties` were not passed as an argument.');
    }

    this.trackEvent(
      undefined,
      'Product Added',
      properties,
      'f4f28363-2440-4b09-b6df-1e260301307e',
      '15.0.1',
      ProductAddedValidator,
    );
  }

  /**
   * User edited a project
   *
   * Owner: carly@artifactuprising.com
   * @param {Object} properties The event's properties.
   * @param {string} properties.category The reporting product category in Magento
   * @param {boolean} properties.is_customizable_product True if the product can be customized in editor; false if it goes straight to the cart
   * @param {string} properties.name Product name for the parent product
   * @param {number} properties.price Price as displayed to the customer; in some events, such as Product Viewed and Product List Viewed, this will be set to the Lowest Price since price can change based on options selected
   * @param {number} properties.product_id The product_id for the parent product
   * @param {string} properties.product_line The reporting product line in Magento
   * @param {Object[]} properties.products Products in the event. This is used by at least Blueshift to manage campaigns. Should contain the following properties:  product\_id sku name price category (aka: Reporting Product Category) product\_line (aka: Reporting Product Line)
   * @param {string} properties.product_type The reporting product type in Magento
   * @param {string} properties.project_id Property has no description in tracking plan.
   * @param {string} properties.sku SKU for the parent product
   */
  trackProjectEdited(properties) {
    if (!properties) {
      throw new Error('There is at least one property defined on this event in your tracking plan but `properties` were not passed as an argument.');
    }

    this.trackEvent(
      undefined,
      'Project Edited',
      properties,
      'ef270809-efdb-4679-9ba3-ae98858b62e8',
      '2.0.1',
      ProjectEditedValidator,
    );
  }

  /**
   * User started a new project
   *
   * Owner: carly@artifactuprising.com
   * @param {Object} properties The event's properties.
   * @param {string} properties.au_project_version Editor version (1 or 2). Required for any events in Editor
   * @param {string} properties.au_split_editorV2_books INACTIVE
   * @param {boolean} properties.au_split_editorV2_feature_flag_autofill Property has no description in tracking plan.
   * @param {string} properties.au_split_editorV2_guestbookset Property has no description in tracking plan.
   * @param {string} properties.au_split_editorV2_spread_books Split for Spread Books testing
   * @param {boolean} properties.au_split_featureflag-chooseimagemodalflow Property has no description in tracking plan.
   * @param {boolean} properties.au_split_feature_flag_google_sign_in Property containing the split treatment for whether the Google log in/sign up buttons are shown to the user
   * @param {boolean} properties.au_split_feature_flag_product_search Property containing the split treatment for the new product search experience at commerce
   * @param {string} properties.category The reporting product category in Magento
   * @param {string} properties.editor_version Editor minor version (e.g. 1.62.1)
   * @param {boolean} properties.is_customizable_product True if the product can be customized in editor; false if it goes straight to the cart
   * @param {boolean} properties.is_mobile_view Is the user using a mobile device?
   * @param {string} properties.name Product name for the parent product
   * @param {string} properties.platform User's operating system
   * @param {number} properties.price Price as displayed to the customer; in some events, such as Product Viewed and Product List Viewed, this will be set to the Lowest Price since price can change based on options selected
   * @param {number} properties.product_id The product_id for the parent product
   * @param {string} properties.product_line The reporting product line in Magento
   * @param {Object[]} properties.products Products in the event. This is used by at least Blueshift to manage campaigns. Should contain the following properties:  product\_id sku name price category (aka: Reporting Product Category) product\_line (aka: Reporting Product Line)
   * @param {string} properties.product_type The reporting product type in Magento
   * @param {string} properties.project_id Property has no description in tracking plan.
   * @param {string} properties.sku SKU for the parent product
   */
  trackProjectStarted(properties) {
    if (!properties) {
      throw new Error('There is at least one property defined on this event in your tracking plan but `properties` were not passed as an argument.');
    }

    this.trackEvent(
      undefined,
      'Project Started',
      properties,
      '2d9fcfb3-8e06-4908-a528-72e30a937226',
      '19.0.1',
      ProjectStartedValidator,
    );
  }

  /**
   * User signs in
   *
   * Owner: carly@artifactuprising.com
   * @param {Object} properties The event's properties.
   * @param {string} properties.au_project_version Editor version (1 or 2). Required for any events in Editor
   * @param {string} properties.au_split_editorV2_books INACTIVE
   * @param {boolean} properties.au_split_editorV2_feature_flag_autofill Property has no description in tracking plan.
   * @param {string} properties.au_split_editorV2_guestbookset Property has no description in tracking plan.
   * @param {string} properties.au_split_editorV2_spread_books Split for Spread Books testing
   * @param {boolean} properties.au_split_featureflag-chooseimagemodalflow Property has no description in tracking plan.
   * @param {boolean} properties.au_split_feature_flag_google_sign_in Property containing the split treatment for whether the Google log in/sign up buttons are shown to the user
   * @param {boolean} properties.au_split_feature_flag_product_search Property containing the split treatment for the new product search experience at commerce
   * @param {string} properties.editor_version Editor minor version (e.g. 1.62.1)
   * @param {boolean} properties.is_mobile_view Is the user using a mobile device?
   * @param {string} properties.platform User's operating system
   */
  trackSignedIn(properties) {
    if (!properties) {
      throw new Error('There is at least one property defined on this event in your tracking plan but `properties` were not passed as an argument.');
    }

    this.trackEvent(
      undefined,
      'Signed In',
      properties,
      'a95d58b0-782d-4c55-b5ce-014684ca315d',
      '13.0.0',
      SignedInValidator,
    );
  }

  /**
   * Fire this event when the Sign In screen is displayed
   *
   * Owner: carly@artifactuprising.com
   */
  trackSignInDisplayed() {
    this.trackEvent(
      undefined,
      'Sign In Displayed',
      undefined,
      '727a89de-9edb-493b-a795-c6fb7eb7c5d7',
      '1.0.0',
      SignInDisplayedValidator,
    );
  }

  reset() {
    this.adapters.forEach(adapter => adapter.reset());
  }

  isInitializedAndEnabled() {
    if (!this.options) {
      throw new Error('Itly is not yet initialized. Have you called `itly.init()` on app start?');
    }

    return !this.options.disabled;
  }

  validateObject(name, validator, object) {
    if (object && !validator(object)) {
      const errors = validator.errors.map(e => {
        let extra = '';
        if (e.keyword === 'additionalProperties') {
          extra = ` (${e.params.additionalProperty})`;
        }
        return `\`properties${e.dataPath}\` ${e.message}${extra}.`;
      }).join(' ');
      this.handleValidationError(`Passed in ${name} properties did not validate against your tracking plan. ${errors}`);
    }
  }

  trackEvent(userId, eventName, properties, eventId, eventVersion, eventValidator) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    if (this.options.logger && this.options.logger.debug) {
      this.options.logger.debug(`[Itly] Tracking event "${eventName}"`);
    }

    this.validateObject(eventName, eventValidator, properties);

    const context = (typeof this.options.context === 'object') ? this.options.context : this.options.context();
    this.validateObject('context', ContextValidator, context || {});

    this.adapters.forEach(adapter => adapter.track(userId, eventName, { ...context, ...properties }, eventId, eventVersion));
  }

  handleValidationError(message) {
    if (this.options.environment === 'production') {
      if (this.options.logger && this.options.logger.error) {
        this.options.logger.error(message);
      }
      else {
        console.error(message);
      }
    }
    else {
      throw new Error(message);
    }
  }
}

var itly = new Itly();

export default itly;
