// @flow
import { getQueryParams } from './urlParameters';

export const __DEV__ = process.env.NODE_ENV !== 'production';
export const __PRODUCTION__ = !__DEV__;
export const __TEST__ = process.env.NODE_ENV === 'test';


export const __ENV_PROD__ = process.env.REACT_APP_ENV === 'prod';

// Modify behavior in production modes with flags set via query params
// ex: show spines in staging with ?debug[artifacts]=true
export const __DEBUG__ = getQueryParams().debug;

