// @flow

/**
 * getPropertyFromParamStore gets the reponse from our AWS lambda function / API
 * gateway integration that pulls key/value pairs from the Parameter Store
 *
 * @export
 * @param {string} key
 * @returns {Promise}
 */
export function getPropertyFromParamStore(key: String): Promise<Object> {
  return fetch(`${process.env.REACT_APP_AWS_PROPS_API_URL}?key=${key}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
    },
  }).then(response =>
    response.json().then(json => ({ json, response })).catch(() => {
      window.newrelic.noticeError(`Unable to fetch property from AWS with key: ${key}`);
    }),
  ).then(({ response, json }) => {
    if (response.status >= 200 && response.status < 300) {
      if (json.errors === true) {
        window.newrelic.noticeError(`Unable to fetch property from AWS with key: ${key}`);
      }
      return json;
    }
    window.newrelic.noticeError(`Unable to fetch property from AWS with key: ${key}`);
    return { key: null };
  }).catch((e) => {
    window.newrelic.noticeError(`Unable to fetch property from AWS with key: ${key}, exception: ${e}`);
    return { key: null };
  });
}
