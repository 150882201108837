// @flow
import {
  ADD_TO_CART_PROCESSING,
  ADD_TO_CART_SUCCESS,
  UPDATE_ATTRIBUTE,
  CUSTOMER_ACK_EMPTY_PAGE,
  UPDATE_MAGENTO_DATA,
  UPDATE_PRODUCT_CHILD_REQUEST_STATUS,
  DO_NOT_ADD_TO_CART,
  FINAL_SAVE_FAILED_CART_STATE,
  PROCESSING_CART_STATE,
  PRODUCT_UPDATE_PAGE_COUNT,
  UPDATE_QTY,
  REMOVE_ATTRIBUTE,
  UPDATE_SHOULD_ADD_TO_CART_AND_START_NEW_PROJECT,
} from './constants';

import {
  FORCE_PROJECT_SAVE_SUCCESS,
  FORCE_PROJECT_SAVE_FAILURE,
  PROJECT_STATE_ADMIN_EDITING,
  PROJECT_STATE_IN_PROGRESS,
} from '../project/constants';

import {
  PROJECT_PAGES_APPLY_DESIGN_TO_PAGE,
  PROJECT_PAGES_APPLY_TEMPLATE,
} from '../project/pages/constants';
import { designShouldAffectAttributes } from '../project/pages/actions';

const defaultProduct = {
  attributes: {},
  cartState: '',
  cartStateMessage: null,
  skippedChecks: [],
  childRequestStatus: '',
  pageCount: null,
  category: '',
  priceTiers: [],
  qty: null,
};

export default function currentProduct(state = defaultProduct, action) {
  switch (action.type) {
    case UPDATE_ATTRIBUTE: {
      const { attributes } = action.payload;

      return {
        ...state,
        attributes: {
          ...state.attributes,
          ...attributes,
        },
      };
    }

    case REMOVE_ATTRIBUTE: {
      const { newAttributes } = state.attributes;
      return {
        ...state,
        attributes: {
          ...newAttributes,
        },
      };
    }

    case UPDATE_QTY: {
      const { qty } = action.payload;

      return {
        ...state,
        qty,
      };
    }

    case UPDATE_MAGENTO_DATA: {
      const { magento, childSku, reportingProductProperties } = action.payload;

      return {
        ...state,
        childSku,
        magento,
        reportingProductProperties,
      };
    }

    case ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        inCart: true,
      };
    }

    case CUSTOMER_ACK_EMPTY_PAGE: {
      return {
        ...state,
        emptyPageAck: true,
      };
    }

    case ADD_TO_CART_PROCESSING: {
      const { payload } = action;

      return {
        ...state,
        cartState: payload.cartState,
        cartStateMessage: payload.cartStateMessage,
        skippedChecks:
          typeof payload.skipCheck === 'string' && payload.skipCheck.length > 0
            ? [...state.skippedChecks, payload.skipCheck]
            : payload.cartState === DO_NOT_ADD_TO_CART
              ? []
              : state.skippedChecks,
      };
    }

    case UPDATE_PRODUCT_CHILD_REQUEST_STATUS: {
      return {
        ...state,
        childRequestStatus: action.payload.status,
      };
    }

    // Adds design attribute name to a new 'design' attribute, if the template is marked as needing that.
    case PROJECT_PAGES_APPLY_DESIGN_TO_PAGE: {
      const { template, designId, pageId, category } = action.payload;
      if (
        template.needsDesignAttribute === true &&
        designShouldAffectAttributes(pageId)(category)
      ) {
        return {
          ...state,
          attributes: {
            ...state.attributes,
            design: designId,
          },
        };
      }

      return state;
    }

    case FORCE_PROJECT_SAVE_SUCCESS:
      return {
        ...state,
        cartState:
          state.cartState === PROJECT_STATE_ADMIN_EDITING
            ? PROJECT_STATE_IN_PROGRESS
            : PROCESSING_CART_STATE,
      };

    case FORCE_PROJECT_SAVE_FAILURE:
      return {
        ...state,
        cartState: FINAL_SAVE_FAILED_CART_STATE,
      };

    case PRODUCT_UPDATE_PAGE_COUNT: {
      const { pageCount } = action.payload;

      return {
        ...state,
        pageCount,
      };
    }

    // Update the product category, in case it's changed.
    case PROJECT_PAGES_APPLY_TEMPLATE: {
      const { category } = action.payload;

      return {
        ...state,
        category,
      };
    }

    // update the toggle to see if we should open a new window with a default project of the same sku
    case UPDATE_SHOULD_ADD_TO_CART_AND_START_NEW_PROJECT: {
      const { shouldAddToCartAndOpenNewDefaultProject } = action.payload;

      return {
        ...state,
        shouldAddToCartAndOpenNewDefaultProject,
      };
    }

    default: {
      // Do nothing if we didn't match action type
      return state;
    }
  }
}

export const productStateFilter = (state) => ({
  ...state,
  cartState: null,
  cartStateMessage: null,
  skippedChecks: [],
  quoteItem: null,
  childRequestStatus: '',
});
