import has from '../helpers/has';

import clonedeep from 'lodash.clonedeep';
import { storeFilter } from '../store/storeFilter';

const ERROR_PROJECT_FAILED_TO_LOCK = 'The project was unable to be locked';
const ERROR_PROJECT_NOT_CREATED = 'The project was not created with project service';
const ERROR_PROJECT_FAILED_TO_SAVE_AND_UNLOCK = 'The project failed to save and unlock in project service';
const ERROR_PROJECT_FAILED_TO_SAVE = 'The project failed to save in project service';
const ERROR_PROJECT_FAILED_TO_GET = 'Failed to get project from project service';

const BAD_REQUEST_PROJECT_SERVICE = 'BAD_REQUEST_PROJECT_SERVICE';

const CREATE_PROJECT = 'POST - /create/project';
const LOCK_PROJECT = 'POST - /project/$projectId?user=$flashId&overrideLock=true';
const SAVE_AND_UNLOCK_PROJECT = 'POST - /project/$projectId?user=$flashId&overrideLock=true';
const SAVE_PROJECT = 'POST - /project/$projectId?user=$flashId&overrideLock=$overrideLock';
const GET_PROJECT = 'GET - /project/$projectId?user=$flashId';

const NR_PAGE_ACTION_ERROR_PROJECT_SERVICE = 'auErrorProjectService';
const ACTION_CREATE_PROJECT = 'CreateProject';
const ACTION_LOCK_PROJECT = 'LockProject';
const ACTION_SAVE_AND_UNLOCK_PROJECT = 'SaveAndUnlockProject';
const ACTION_SAVE_PROJECT = 'SaveProject';
const ACTION_GET_PROJECT = 'GetProject';
const REASON_MISSING_ID = 'MissingProjectId';
const REASON_BAD_REQUEST = 'BadRequest';

const psNR = (flashId, projectId, statusCode, json, action, reason) => {
  window.newrelic.addPageAction(`${NR_PAGE_ACTION_ERROR_PROJECT_SERVICE}-${action}-${reason}`, {
    flashId,
    projectId,
    statusCode,
    json,
  });
};

const ProjectApiError = (message, flashId, projectId) =>
  new Error(`${message} - flashId: ${flashId} - projectId: ${projectId}`);

const ProjectApiBadRequest = (endpoint, flashId, projectId) =>
  new Error(`${BAD_REQUEST_PROJECT_SERVICE}/${endpoint} - flashId: ${flashId} - projectId: ${projectId}`);

const goodResponse = response => response.status >= 200 && response.status < 300;
const jsonHasProjectId = json => has(json)('projectId');

const getHeaders = flashToken => ({
  Authorization: `Bearer ${flashToken}`,
  'Content-Type': 'application/json',
});

export function createProjectForUser(flashId, flashToken) {
  return fetch(
    `${process.env.REACT_APP_PROJECT_SERVICE_BASE_URL}/project/create?user=${flashId}`,
    {
      headers: getHeaders(flashToken),
      method: 'POST',
    },
  ).then(response =>
    response.json().then(json => ({ json, response })),
  ).then(({ json, response }) => {
    if (goodResponse(response)) {
      if (!jsonHasProjectId(json)) {
        psNR(flashId, null, response.status, json, ACTION_CREATE_PROJECT, REASON_MISSING_ID);
        throw ProjectApiError(ERROR_PROJECT_NOT_CREATED, flashId, null);
      }
      return json;
    }

    psNR(flashId, null, response.status, json, ACTION_CREATE_PROJECT, REASON_BAD_REQUEST);
    throw ProjectApiBadRequest(CREATE_PROJECT, flashId, null);
  });
}

export function setProjectLock(projectId, flashId, flashToken, state) {
  return fetch(
    `${process.env.REACT_APP_PROJECT_SERVICE_BASE_URL}/project/${projectId}?user=${flashId}&overrideLock=true`,
    {
      headers: getHeaders(flashToken),
      method: 'POST',
      body: JSON.stringify({
        projectId,
        user: flashId,
        state,
      }),
    },
  ).then(response =>
    response.json().then(json => ({ json, response })),
  ).then(({ json, response }) => {
    if (goodResponse(response)) {
      if (!jsonHasProjectId(json)) {
        psNR(flashId, projectId, response.status, json, ACTION_LOCK_PROJECT, REASON_MISSING_ID);
        throw ProjectApiError(ERROR_PROJECT_FAILED_TO_LOCK, flashId, projectId);
      }
      return json;
    }

    psNR(flashId, projectId, response.status, json, ACTION_LOCK_PROJECT, REASON_BAD_REQUEST);
    throw ProjectApiBadRequest(LOCK_PROJECT, flashId, projectId);
  });
}

export function saveProjectAndUnlock(projectId, flashId, flashToken, body) {
  return fetch(
    // I just realized this is the exact same endpoint as used in setProjectLock... what the hell
    `${process.env.REACT_APP_PROJECT_SERVICE_BASE_URL}/project/${projectId}?user=${flashId}&overrideLock=true`,
    {
      headers: getHeaders(flashToken),
      method: 'POST',
      body: JSON.stringify(body),
    },
  ).then(response =>
    response.json().then(json => ({ json, response })),
  ).then(({ json, response }) => {
    if (goodResponse(response)) {
      if (!jsonHasProjectId(json)) {
        psNR(flashId, projectId, response.status, json, ACTION_SAVE_AND_UNLOCK_PROJECT, REASON_MISSING_ID);
        throw ProjectApiError(ERROR_PROJECT_FAILED_TO_SAVE_AND_UNLOCK, flashId, projectId);
      }
      return json;
    }

    psNR(flashId, projectId, response.status, json, ACTION_SAVE_AND_UNLOCK_PROJECT, REASON_BAD_REQUEST);
    throw ProjectApiBadRequest(SAVE_AND_UNLOCK_PROJECT, flashId, projectId);
  });
}

export const saveProject = (state, overrideLock = false) => {
  const flashId = state.user.flashId;
  const projectId = state.project.id;

  const body = {};
  body.projectId = projectId;
  body.user = flashId;

  const cleanState = storeFilter(clonedeep(state));

  body.projectData = JSON.stringify({ data: cleanState });
  return fetch(
    `${process.env.REACT_APP_PROJECT_SERVICE_BASE_URL}/project/${state.project.id}?user=${state.user.flashId}&overrideLock=${overrideLock}`,
    {
      headers: getHeaders(state.user.flashToken),
      method: 'POST',
      body: JSON.stringify(body),
    },
  ).then(response =>
    response.json().then(json => ({ json, response })),
  ).then(({ json, response }) => {
    if (goodResponse(response)) {
      if (!jsonHasProjectId(json)) {
        psNR(flashId, projectId, response.status, json, ACTION_SAVE_PROJECT, REASON_MISSING_ID);
        throw ProjectApiError(ERROR_PROJECT_FAILED_TO_SAVE, flashId, projectId);
      }
      return json;
    }

    psNR(flashId, projectId, response.status, json, ACTION_SAVE_PROJECT, REASON_BAD_REQUEST);
    throw ProjectApiBadRequest(SAVE_PROJECT, flashId, projectId);
  });
};

export function getProject(projectId, flashId, flashToken) {
  return fetch(
    `${process.env.REACT_APP_PROJECT_SERVICE_BASE_URL}/project/${projectId}?user=${flashId}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: new Headers(getHeaders(flashToken)),
    }
  ).then(response =>
    response.json().then(json => ({ json, response })),
  ).then(({ json, response }) => {
    if (goodResponse(response)) {
      if (!jsonHasProjectId(json)) {
        psNR(flashId, projectId, response.status, json, ACTION_GET_PROJECT, REASON_MISSING_ID);
        throw ProjectApiError(ERROR_PROJECT_FAILED_TO_GET, flashId, projectId);
      }
      return json;
    }

    psNR(flashId, projectId, response.status, json, ACTION_GET_PROJECT, REASON_BAD_REQUEST);
    throw ProjectApiBadRequest(GET_PROJECT, flashId, projectId);
  });
}
