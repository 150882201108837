export const ALBUMS_GET_REQUEST = 'userAlbums/get/request';
export const ALBUMS_GET_SUCCESS = 'userAlbums/get/success';
export const ALBUMS_GET_FAILURE = 'userAlbums/get/failure';

export const ALBUM_GET_PHOTOS_REQUEST = 'userAlbums/albumPhotos/get/request';
export const ALBUM_GET_PHOTOS_SUCCESS = 'userAlbums/albumPhotos/get/success';
export const ALBUM_GET_PHOTOS_FAILURE = 'userAlbums/albumPhotos/get/failure';

export const ALBUM_SET_PHOTOS = 'userAlbums/albumPhotos/set';
export const ALBUM_ADD_ALBUM = 'ALBUM_ADD_ALBUM';
export const ALBUM_USER_DEFAULT_ALBUM = 'My Photos';
export const UI_LOCAL_ALBUM_GET_MORE_PHOTOS = 'UI_LOCAL_ALBUM_GET_MORE_PHOTOS';
// export const ADD_IMAGE_ACTION_FOR_ANALYTICS = 'ADD_IMAGE_ACTION_FOR_ANALYTICS';
export const SORT_ALBUM_PHOTOS_ACTION = 'userAlbums/album/sort';
export const MANUALLY_ADD_NEW_ALBUM_TO_ARRAY = 'MANUALLY_ADD_NEW_ALBUM_TO_ARRAY';
export const USER_ALBUMS_PHOTOS_REPLACE_PHOTO = 'USER_ALBUMS_PHOTOS_REPLACE_PHOTO';