import LoginOrSignUpModal from '../../components/Notifications/LoginOrSignUp';
import UploadErrorsNotification from '../../components/Notifications/UploadErrorsNotification';
import CmykNotification from '../../components/Notifications/CmykNotification';
import { NewAssetsNotification } from '../../components/Notifications/NewAssetsNotification';

// Notification Action types
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';

// Notification Constants
export const SUCCESS_NOTIFICATION_TYPE = 'SUCCESS';
export const ERROR_NOTIFICATION_TYPE = 'ERROR';
export const GENERIC_NOTIFICATION_TYPE = 'GENERIC';
export const UPLOAD_ERRORS_NOTIFICATION_TYPE = 'UPLOAD_ERROR';
export const CMYK_WARNING_TYPE = 'CMYK_WARNING';

// Notification Messages
export const LOW_RES_NOTIFICATION_MESSAGE = 'The resolution of this photo is too low to print at this size, and may not print clearly. Try choosing a different layout or replacing the photo.';
export const PLEASE_LOGIN_OR_SIGNUP_CONSTANT = LoginOrSignUpModal;
export const UPLOAD_IMAGE_ERRORS_MESSAGE = UploadErrorsNotification;
export const NEW_SERVICE_WORKER_ASSETS_NOTIFICATION = NewAssetsNotification;
export const PROMPT_ANON_USER_LOGIN = 'PROMPT_ANON_USER_LOGIN';
export const PROJECT_CART_LOCK_MESSAGE = 'This project has been added to your cart. Any changes made here will be reflected in the cart.';
export const PROJECT_ADMIN_LOCK_MESSAGE = 'This project is being viewed by the Artifact Uprising team. No changes can be made. If you have questions, please visit our help desk.';
export const CMYK_WARNING_MESSAGE = CmykNotification;
export const LOW_RES_NOTIFICATION_TITLE = 'Low resolution image on {name}';

// Notification Scopes
export const LEFT_NOTIFICATIONS = 'left'
export const BTN_NOTIFICATIONS = 'btn';
