import {
  SET_AUTH_ERRORS,
  SET_AUTH_STATE,
  TOGGLE_AUTH_PROCESSING,
} from './constants';

export const defaultUserAuthentication = {
  state: null,
  errors: null,
  processing: false,
  addToCartOnSuccess: false,
}

export default function UserAuthenticationReducer(state = defaultUserAuthentication, action) {
  switch (action.type) {
    case SET_AUTH_STATE:
      const { authState, addToCartOnSuccess } = action.payload;
      return {
        ...state,
        state: authState,
        ...(addToCartOnSuccess !== undefined ? { addToCartOnSuccess } : {}),
        errors: null,
      };
    case SET_AUTH_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      };
    case TOGGLE_AUTH_PROCESSING:
      return {
        ...state,
        processing: action.payload.processing,
      };

    default:
      return state;
  }
}
