import { isWithinSmall } from '../../helpers/breakpoints';

// GTM Event Data Keys
export const GTM_KEY_PROJECT_ID = 'projectId';
export const GTM_KEY_SCREEN_SIZE = 'screenSize';
export const GTM_KEY_WINDOW_SIZE = 'windowSize';
export const GTM_KEY_SITE_ID = 'siteId';
export const GTM_KEY_PRODUCT_SKU = 'productSku';
export const GTM_KEY_PRODUCT_CONFIGURABLE_SKU = 'productConfigurableSku';
export const GTM_KEY_PRODUCT_NAME = 'productName';
export const GTM_KEY_LOCATION = 'location';
export const GTM_KEY_REFERRER = 'editorReferrer';
export const GTM_KEY_PRODUCT_CATEGORY = 'productCategory';

// GTM Event Names
export const GTM_EVENT_USER_CREATED_GALLERY = 'auUserCreatedGallery';
export const GTM_EVENT_USER_CLICKED_EDIT_PAGES = 'auUserClickedEditPages';
export const GTM_EVENT_USER_CLICKED_AUTOFILL = 'auUserClickedAutofill';
export const GTM_EVENT_USER_AUTOFILLED_PHOTOS = 'auUserAutofilledPhotos';
export const GTM_EVENT_USER_CLICKED_ADD_RECIPIENT_ADDRESS = 'auUserClickedAddRecipientAddress';
export const GTM_EVENT_USER_ADDED_ADDRESS = 'auUserAddedAddress';
export const GTM_EVENT_USER_UPLOADED_ADDRESSES = 'auUserUploadedAddresses';
export const GTM_EVENT_FAILED_ADDRESS_UPLOAD = 'auFailedAddressUpload';
export const GTM_EVENT_REMOVED_RETURN_ADDRESS = 'auRemovedReturnAddress';
export const GTM_EVENT_REMOVED_CSV = 'auRemovedCsv';
export const GTM_EVENT_USER_SELECTED_ATTRIBUTE = 'auUserSelectedAttribute';
export const GTM_EVENT_USER_RECEIVED_CART_WARNING = 'auCartWarning';
export const GTM_EVENT_USER_EDITED_ADDRESS = 'auUserEditAddress';
export const GTM_EVENT_USER_REMOVED_PHOTO = 'auUserRemovedPhoto';
export const GTM_EVENT_CONVERTED_PROJECTED = 'auEditorAnonymousProjectConverted';
export const GTM_EVENT_PHOTO_SOURCE_SELECTED = 'auEditorUserSelectedSource';
export const GTM_EVENT_LOW_RES_NOTIFICATION = 'auEditorLowResNotification';
export const GTM_EVENT_USER_APPLIED_LAYOUT = 'auEditorLayoutChanged';
export const GTM_EVENT_USER_RENAMED_PROJECT = 'auEditorUserRenamedProject';
export const GTM_EVENT_EDITOR_REMOVED_PAGE = 'auEditorRemovedPage';
export const GTM_EVENT_EDITOR_ADDED_PAGE = 'auEditorAddedPage';

export const DEFAULT_GTM_DATA = {
  [GTM_KEY_PROJECT_ID]: '{state.project.id}',
  [GTM_KEY_SCREEN_SIZE]: `${window.screen.width}x${window.screen.height}`,
  [GTM_KEY_WINDOW_SIZE]: `${window.outerWidth}x${window.outerHeight}`,
  [GTM_KEY_SITE_ID]: isWithinSmall() ? 'mobile-web' : 'desktop',
  [GTM_KEY_PRODUCT_SKU]: '{state.product.childSku}',
  [GTM_KEY_PRODUCT_CONFIGURABLE_SKU]: '{state.product.sku}',
  [GTM_KEY_PRODUCT_NAME]: '{state.product.name}',
  [GTM_KEY_LOCATION]: 'editor',
  [GTM_KEY_REFERRER]: document.referrer,
  [GTM_KEY_PRODUCT_CATEGORY]: '{state.product.category}',
};

// /$$   /$$     /$$
// |__/  | $$    | $$
//  /$$ /$$$$$$  | $$ /$$   /$$
// | $$|_  $$_/  | $$| $$  | $$
// | $$  | $$    | $$| $$  | $$
// | $$  | $$ /$$| $$| $$  | $$
// | $$  |  $$$$/| $$|  $$$$$$$
// |__/   \___/  |__/ \____  $$
//                    /$$  | $$
//                   |  $$$$$$/
//                    \______/


// Itly Event names
export const ITLY_EVENT_USER_LOGIN = 'itlyUserLoggedIn';
export const ITLY_EVENT_USER_SIGN_UP = 'itlyUserSignedUp';
export const ITLY_EVENT_PROJECT_STARTED = 'itlyProjectStarted';
export const ITLY_EVENT_ADD_TO_CART = 'itlyProductAddedToCart';
export const ITLY_IDENTIFY = 'itlyIdentify';
export const ITLY_PAGE_VIEWED = 'itlyPageViewed';
export const ITLY_IMAGE_EDITED = 'itlyImageEdited';
export const ITLY_IMAGE_UPLOADED = 'itlyImageUploaded';
export const ITLY_EVENT_PROJECT_EDITED = 'itlyProjectEdited';
export const ITLY_IMAGE_ADDED = 'itlyImageAdded';

// Itly property values
export const ITLY_PAGE_TYPE = 'Editor';

