/* eslint-disable no-use-before-define */
// @flow
import type { User } from '../types/photo';
import { Gallery } from 'au-js-sdk/lib/models/Gallery';

const uploadsBaseUrl = process.env.REACT_APP_CRUD_CORE_BASE_URL || '';
const API_URL = `${uploadsBaseUrl}/api/galleries`;

export const createNewGallery = async (user: User, galleryName: string, projectId?: string): Promise<Gallery> => {
  const params = buildRequestParams(user.flashToken, 'POST', {
    flashId: user.flashId,
    galleryName,
    projectId,
  });
  const response = await fetch(API_URL, params);
  const responseJson = await response.json();
  return hydrateGalleryType(responseJson);
};

export const getGallery = async (user: User, galleryId: string): Promise<Gallery> => {
  const queryStr = `?flashId=${user.flashId}&galleryId=${galleryId}`;
  const params = buildRequestParams(user.flashToken, 'GET');
  const response = await fetch(API_URL + queryStr, params);
  const responseJson = await response.json();
  return hydrateGalleryType(responseJson);
};

export const getGalleries = async (user: User): Promise<Gallery[]> => {
  if (!user.authorized) {
    // User not logged in, don't bother requesting galleries
    return [];
  }

  let queryStr = `?flashId=${user.flashId}&migrate=true`;
  let params = buildRequestParams(user.flashToken, 'GET');
  let response = await fetch(API_URL + queryStr, params);
  let responseJson = await response.json();
  let galleries = responseJson && responseJson.length ? responseJson.map((g) => hydrateGalleryType(g)) : [];

  if (galleries.length === 0) {
    queryStr = `?flashId=${user.flashId}&migrate=true`;
    params = buildRequestParams(user.flashToken, 'GET');
    response = await fetch(API_URL + queryStr, params);
    responseJson = await response.json();
    galleries = responseJson && responseJson.length ? responseJson.map((g) => hydrateGalleryType(g)) : [];
    window.newrelic.addPageAction('s3_direct_upload_migrationEvent', {
      userId: user.flashId,
      userEmail: user.email,
    });
  }

  return galleries;
};

export const updateGallery = async (user: User, galleryId: string, update: { name?: string, thumbnailUrl?: string }): Promise<Gallery> => {
  const params = buildRequestParams(user.flashToken, 'PATCH', {
    flashId: user.flashId,
    galleryId,
    update,
  });
  const response = await fetch(API_URL, params);
  const responseJson = await response.json();
  return hydrateGalleryType(responseJson);
};

export const buildRequestParams = (flashToken: string, method: 'GET' | 'POST' | 'PATCH', body?: any) => ({
  headers: {
    Origin: process.env.REACT_APP_PUBLIC_URL,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${flashToken}`,
  },
  method,
  mode: 'cors',
  body: body ? JSON.stringify(body) : undefined,
});

const hydrateGalleryType = (obj: any): Gallery => {
  if (!(obj.id && obj.createdBy && obj.name)) {
    window.newrelic.addPageAction('s3_direct_upload_error', {
      error: 'Invalid gallery object.',
      action: 'hydrateGalleryType',
      responseJson: JSON.stringify(obj),
    });
    throw new Error('Invalid gallery object.');
  }
  const gallery: Gallery = {
    id: obj.id,
    createdBy: obj.createdBy, // user flashId
    createdAt: new Date(obj.createdAt),
    name: obj.name,
    lastModifiedAt: new Date(obj.lastModifiedAt),
    thumbnailUrl: obj.thumbnailUrl,
    images: [],
  };
  return gallery;
};
