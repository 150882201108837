export function callApi(url, options = {}, schema) {
  const fullOptions = {
    ...options,
    mode: 'cors',
    method: options.method || 'GET',
    // Add other default options here
  };
  return fetch(url, fullOptions)
    .then((response) => response.json().then((json) => ({ json, response })))
    .then(({ json, response }) => {
      if (response.status > 399) {
        window.newrelic.addPageAction('auApiMiddlewareInvalidResponse', { url });
        throw new Error('Something went wrong');
      }
      window.newrelic.addPageAction('auApiMiddlewareRequestComplete', { url, method: options.method || 'GET' });
      // Do things with the json before returning here.
      // Eventually we might normalize responses (camelize keys etc)
      return json;
    }).catch((e) => {
      window.newrelic.noticeError(e);
      window.newrelic.addPageAction('auApiMiddlewareExceptionCaught');
      throw new Error('Something went wrong');
    });
}

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'CALL_API';

// API Middleware that handles any action with the "CALL_API" key.
export default () => (next) => (action) => {
  const callAPI = action[CALL_API];

  // Move along if the CALL_API key is absent
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { url } = callAPI;
  const { types, options, meta } = callAPI;

  // Action types must be strings
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  // Action creator that creates the action with the proper type and data
  // Use for request, success, and failures
  function actionWith(data) {
    const finalAction = {
      ...action,
      ...data,
    };
    delete finalAction[CALL_API];
    return finalAction;
  }

  // Get the types from the action!
  const [requestType, successType, failureType] = types;

  // Hey redux, we are starting the request for this action
  next(actionWith({ type: requestType }));

  /**
     * On success, dispatch a success action with the response data.
     * On error, dispatch an error action with the error data and status code
     */
  return callApi(url, options).then(
    (response) => {
      next(actionWith({
        type: successType,
        payload: response,
        meta,
      }));
    },
    (error) => next(actionWith({
      type: failureType,
      payload: {
        status: error.httpStatusCode || 404,
        error: error.message || 'Something went wrong',
      },
      meta,
    })),
  );
};
