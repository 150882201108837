// @flow
import type { Dispatch, GetState } from 'redux';
import {
  ADD_PHOTOS_CHANGE_STEP,
  ADD_PHOTOS_SET_ACTIVE_BUTTON,
  ADD_TO_STORED_IMAGES,
  UI_TEMPORARY_ALBUM,
  ADD_PHOTOS_RESET_TITLE_AND_ERROR,
  ADD_PHOTOS_SET_GALLERY_TITLE,
  ADD_PHOTOS_SET_GALLERY_TITLE_ERROR,
  ADD_PHOTOS_SET_SELECTED_OPTION,
  SHOW_UPLOAD_IMAGE_ERROR_MODAL,
  PHOTO_SOURCE_ABOUT,
  ABOUT_PHOTOS_URL,
} from './constants';
import { buildBasicAlbum, albumAddPhotos } from '../userAlbums/actions';
import { hideUploadPhotosModal, setCurrentAlbum } from '../../store/ui/actions';
import photo, { LocalPhoto, type File } from '../../types/photo';
import { getFileDimensions } from '../../helpers/images';
import { addPhoto, selectedSource, uploadPhoto } from '../userPhotos/actions';
import has from '../../helpers/has';
import uuid4 from 'uuid/v4';
import { createGalleryAndUploadImages, uploadImagesToGallery } from '../v2/galleries/actions';

export const changeStep = (step: number) => ({
  type: ADD_PHOTOS_CHANGE_STEP,
  payload: step,
});

const resetTitleAndError = () => ({ type: ADD_PHOTOS_RESET_TITLE_AND_ERROR });

export const addPhotosSetGalleryTitle = (title) => async (dispatch) => {
  await dispatch({ type: ADD_PHOTOS_SET_GALLERY_TITLE, payload: title });
};

export const addPhotosSetSelectedOption = (option) => async (dispatch) => {
  await dispatch({ type: ADD_PHOTOS_SET_SELECTED_OPTION, payload: option });
};

export const addPhotosSetGalleryTitleError = (value) => (dispatch) => {
  dispatch({ type: ADD_PHOTOS_SET_GALLERY_TITLE_ERROR, payload: value });
};

const handleExistingClick = () => (dispatch, getState) => {
  const { albumPhotos } = getState().userAlbums;
  const { gallerySelectedOption } = getState().addPhotos;
  const keys = Object.keys(albumPhotos);
  if (!gallerySelectedOption) {
    dispatch(addPhotosSetSelectedOption(keys[0]));
  }
};

export const setActiveButton = (name) => async (dispatch) => {
  let text = 'Quickly add photos to your current project and start editing.';
  if (name === 'newGallery') {
    text = 'Creating a Gallery lets you save and organize your photos in one place to access them later for other projects.';
  } else if (name === 'existingGallery') {
    text = 'Already using Galleries to save and organize your photos? Add photos to a Gallery you already have created.';
  }

  await dispatch({ type: ADD_PHOTOS_SET_ACTIVE_BUTTON, payload: { activeButton: name, activeText: text } });
  await dispatch(resetTitleAndError());
  if (name === 'existingGallery') {
    await dispatch(handleExistingClick());
  }
};

/* Takes an array of blob urls and adds matching Photos to userPhotos, and adds albumPhoto objects to the
   albumPhotos array that corresponds to the given albumId, each of which points at their corresponding Photo. */
const storePhotos = (files: Array<File>, albumId: string) => async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const userLoggedIn = state.user.authorized;
  const { albumPhotos } = state.userAlbums;
  const targetAlbum = albumPhotos[albumId];

  const formattedPhotosArray = await Promise.all(
    files.map(async (file) => {
      const dimensions = await getFileDimensions(file);

      // When uploading directly to S3, the client is responsible for generating
      // the mediaId as this will be passed along to the persistMetadata lambda
      const generatedMediaId = uuid4();

      const p = LocalPhoto({
        file,
        dimensions,
        albumId,
      });
      const userPhotoId = photo.getId(p);

      // Save the localPhoto to State.
      dispatch(addPhoto(p));

      // This will upload the photo, if possible.
      dispatch(uploadPhoto(p, albumId, true));

      return {
        source: 'upload',
        isLoaded: true,
        thumbURL: file.preview,
        cdnUrl: file.preview,
        id: userPhotoId,
        userPhotoId,
      };
    })
  );

  // If there is no existing temporary album, create one and assign the cdnUrl of the first item as the thumbnail.
  if (!targetAlbum && albumId === UI_TEMPORARY_ALBUM) {
    await dispatch(buildBasicAlbum(albumId, formattedPhotosArray[0].cdnUrl));
  }

  if (!userLoggedIn) {
    dispatch(albumAddPhotos(...formattedPhotosArray)(albumId));
  }

  await dispatch(setCurrentAlbum(albumId));

  dispatch(hideUploadPhotosModal());
};

const resetAddPhotos = () => (dispatch) => {
  dispatch(changeStep(1));
  dispatch(setActiveButton('existingGallery'));
};

export const showUploadErrorModal = (value: boolean) => ({ type: SHOW_UPLOAD_IMAGE_ERROR_MODAL, payload: value });

const setLoggedOutUserPhotos = () => (dispatch: Dispatch, getState: GetState) => {
  const { storedImages } = getState().addPhotos;
  const { albumPhotos } = getState().userAlbums;
  const currentTempAlbumLength = has(albumPhotos)(UI_TEMPORARY_ALBUM) ? albumPhotos[UI_TEMPORARY_ALBUM].length : 0;
  const remainder = 10 - currentTempAlbumLength;

  dispatch(storePhotos(storedImages.slice(0, remainder), UI_TEMPORARY_ALBUM));
};

export const setAddedPhotos = (albumId: ?string) => async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const userLoggedIn = state.user.authorized;
  const { galleries } = state.galleries;
  const { storedImages } = state.addPhotos;

  if (userLoggedIn) {
    const isNewGallery = galleries.find((g) => g.id === albumId) == null;

    const photos = await Promise.all(
      storedImages.map(async (file) => {
        const dimensions = await getFileDimensions(file);
        const p = LocalPhoto({
          file,
          dimensions,
          albumId,
        });
        return p;
      })
    );
    if (isNewGallery) {
      // in this case we know that the albumId is actually the gallery name
      dispatch(createGalleryAndUploadImages(albumId, photos));
    } else {
      // in this case we know that the albumId is actually the galleryId
      dispatch(uploadImagesToGallery(albumId, photos));
    }
  } else {
    dispatch(setLoggedOutUserPhotos());
  }

  dispatch(resetAddPhotos());
};

export const addGenericPhotoUploads = (files: Array<File>) => (dispatch: Dispatch, getState: GetState) => {
  dispatch({ type: ADD_TO_STORED_IMAGES, payload: files });

  const state = getState();
  const uploadStraightToAlbum = state.ui.uploadStraightToAlbum;
  const albumTitle = state.ui.currentAlbum;

  if (uploadStraightToAlbum) {
    // This never happens (that we know of...)
    dispatch(setAddedPhotos(albumTitle));
  } else {
    dispatch(changeStep(2));
  }
};

export const openAboutPage = () => (dispatch: Dispatch) => {
  dispatch(selectedSource(PHOTO_SOURCE_ABOUT));
  const win = window.open(ABOUT_PHOTOS_URL, '_blank');
  win.focus();
};
