// @flow

import { type Node as ReactNode } from 'react';

import { showIf } from '../../../helpers/conditionals';

type ShowProps = {
  children: ?ReactNode,
  when: ?any,
};

// HOC for conditionally displaying children when a given value is truthy.
const Show = ({ children, when }: ShowProps) => showIf(children)(when);

Show.defaultProps = {
  children: null,
  when: false,
};

export default Show;
