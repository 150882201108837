// @flow

import React from 'react';
import { connect } from 'react-redux';

import { showUploadErrorModal } from '../../store/addPhotos/actions';
import { failedPhotosSelector } from '../../store/userPhotos/selectors';
import { type Photo } from '../../types/photo';


const message = (failedPhotos: Array<Photo>) => `${failedPhotos.length} photo${failedPhotos.length > 1 ? 's' : ''} failed to upload.`;

type UploadErrorsNotificationProps = {
  failedPhotos: Array<Photo>,
  showUploadErrorModal: Function,
};

const UploadErrorsNotification = (props: UploadErrorsNotificationProps) => (
  <span>
    {message(props.failedPhotos)}&nbsp;
    <button className="btn btn--link" onClick={() => props.showUploadErrorModal(true)}>
      View Details
    </button>.
  </span>
);

const mapStateToProps = state => ({
  failedPhotos: failedPhotosSelector(state),
});

const mapDispatchToProps = dispatch => ({
  showUploadErrorModal: val => dispatch(showUploadErrorModal(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadErrorsNotification);
