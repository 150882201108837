// @flow

import React from 'react';
import type { Dispatch, GetState } from 'redux';
import { type Option } from 'fp-ts/lib/Option';

import LoginOrSignUpModal from '../../components/Notifications/LoginOrSignUp';
import {
  DISMISS_NOTIFICATION,
  ERROR_NOTIFICATION_TYPE,
  GENERIC_NOTIFICATION_TYPE,
  LOW_RES_NOTIFICATION_MESSAGE,
  PROJECT_CART_LOCK_MESSAGE,
  PROMPT_ANON_USER_LOGIN,
  SEND_NOTIFICATION,
  SUCCESS_NOTIFICATION_TYPE,
  UPLOAD_ERRORS_NOTIFICATION_TYPE,
  PROJECT_ADMIN_LOCK_MESSAGE,
  UPLOAD_IMAGE_ERRORS_MESSAGE,
  CMYK_WARNING_MESSAGE,
  LOW_RES_NOTIFICATION_TITLE,
} from './constants';
import { NewAssetsNotification } from '../../components/Notifications/NewAssetsNotification';
import { getNotificationByKey } from './selectors';
import { prop, equals } from '../../helpers/functions';
import { sendAnalyticsForLowRes } from '../analytics/actions';
import type { Notification } from '../../types/notification';

// Helpers
const notificationIsDismissed = (n: Option<Object>) => n.chain(prop('dismissed')).map(equals(true)).getOrElseValue(false);
const notificationKey = 0;
const nextAvailable = () => notificationKey + 1;
const CmykWarningMessage = CMYK_WARNING_MESSAGE;

export const sendNotification = notification => ({
  type: SEND_NOTIFICATION,
  payload: notification,
});

export const createNotification = (
  type, message, key = null, dismissed, icon, className, iconColor, title, photoId,
) => ({
  type,
  message,
  key: key === null ? nextAvailable() : key,
  dismissed: false,
  icon,
  className,
  iconColor,
  title,
  photoId,
});

export const createGenericNotification = (message, key) =>
  createNotification(GENERIC_NOTIFICATION_TYPE, message, key, false, 'info', 'notification__generic', '#fff');

export const dismissNotification = (key: string) => ({
  type: DISMISS_NOTIFICATION,
  payload: { notificationKey: key },
});

export const createAndSendNotification = (
  type, message, key = null, dismissed, icon, className, iconColor, title, photoId,
) => sendNotification(createNotification(
  type, message, key, dismissed, icon, className, iconColor, title, photoId)
);

// The following three functions are notification creators
export const sendSuccessNotification = (message, key = null) =>
  createAndSendNotification(SUCCESS_NOTIFICATION_TYPE, message, key);

export const sendErrorNotification = (title, message, key = null, photoId) => createAndSendNotification(
  ERROR_NOTIFICATION_TYPE, message, key, false, 'warning', 'notification__error', '#fff', title, photoId,
);

export const sendGenericNotification = (type, message, key = null) => createAndSendNotification(
  GENERIC_NOTIFICATION_TYPE, message, key, false, 'info', 'notification__generic', '#fff'
);

export const sendMultitabWarningNotification = () => createAndSendNotification(
  GENERIC_NOTIFICATION_TYPE,
  'In order for your project to autosave, please make sure only one Editor window or tab is open at once.',
  'multi-tab-warning',
  false,
  'info',
  'notification__generic',
  '#fff',
  'Unable to save with multiple Editor tabs'
);

// LOW RESOLUTION NOTIFICATION
const lowResolutionNotificationKey = (userPhotoId: string | number, pageId: string, layerId: string) => (
  `low_res_${userPhotoId}_${pageId}_${layerId}`
);

// Action creator for sending low resolution notifications.
export const sendLowResolutionNotification = (userPhotoId: string | number, page: { name: string, id: string }, layerId: string) => (
  (dispatch: Dispatch, getState: GetState) => {
    dispatch(sendAnalyticsForLowRes());
    const { name, id } = page;

    const key = lowResolutionNotificationKey(userPhotoId, page.id, layerId);

    const dismissed = notificationIsDismissed(getNotificationByKey(getState())(key));
    if (!dismissed) {
      dispatch(sendErrorNotification(
        {
          text: LOW_RES_NOTIFICATION_TITLE,
          data: { name, id },
        },
        LOW_RES_NOTIFICATION_MESSAGE,
        key,
        userPhotoId,
      ));
    }
  }
);

// ADMIN CHECK NOTIFICATION
export const adminCheckNotificationKey = (userPhotoId: string | number, pageId: string, layerId: string) => (
  `admincheck_${userPhotoId}_${pageId}_${layerId}`
);

// Action creator for sending low resolution notifications.
export const sendAdminCheckNotification = () => (
  (dispatch: Dispatch, getState: GetState) => {
    if (!getState().user.admin) {
      // Don't notify if not admin
      return;
    }
    getState().project.pages.forEach((page) => {
      page.layers.forEach((layer) => {
        if (layer.type === 'user_photo' && layer.data && layer.data.cropData && layer.data.cropData.adminCheck) {
          const { name, id } = page;
          const key = adminCheckNotificationKey(layer.data.userPhotoId, page.id, layer.id);
          dispatch(sendErrorNotification(
            {
              text: `${page.name}`,
              data: { name, id },
            },
            `Admin check needed for page ${page.name}`,
            key,
            layer.data.userPhotoId,
          ));
        }
      });
    });
  }
);

// Action creator for dismissing low resolution notifications.
export const dismissLowResolutionNotification = (userPhotoId: number | string, pageId: string, layerId: string) => (dispatch: Dispatch) => {
  const key = lowResolutionNotificationKey(userPhotoId, pageId, layerId);

  dispatch(dismissNotification(key));
};

// Filters current notifications array to find ones with keys that contain the given userPhotoId, and dismisses all of them.
export const dismissAllNotificationsForPhoto = (userPhotoId: number | string) => (dispatch: Dispatch, getState: GetState) => {
  const { notifications } = getState();

  const photoNotifications = notifications.filter((notification: Notification) =>
    prop('key')(notification)
      .map(x =>
        (typeof x === 'string'
          ? x.includes(`low_res_${userPhotoId}`) || x.includes(`CMYK_${userPhotoId}`)
          : false)
      ).getOrElseValue(false)
  );

  photoNotifications.forEach((notification: Notification) => {
    dispatch(dismissNotification(notification.key));
  });
};

// ANONYMOUS USER NOTIFICATION
export const sendAnonymousUserNotification = (
  type,
  message,
  key,
  dismissed,
  icon,
  className,
) => sendGenericNotification(
  GENERIC_NOTIFICATION_TYPE,
  <LoginOrSignUpModal />,
  PROMPT_ANON_USER_LOGIN,
  false,
  'info',
  'notification__generic',
  'white'
);

// UPLOAD ERROR NOTIFICATION
export const dismissUploadErrorsNotification = () => (dispatch: Dispatch) => {
  dispatch(dismissNotification('UPLOAD_ERRORS'));
};

export const sendUploadErrorsNotification = () => (dispatch: Dispatch, getState: GetState) => {
  const UploadImageErrorsMessage = UPLOAD_IMAGE_ERRORS_MESSAGE;
  const { notifications } = getState();

  const errorShowing = notifications.some(notification => notification.type === 'UPLOAD_ERROR' && !notification.dismissed);

  if (!errorShowing) {
    dispatch(createAndSendNotification(
      UPLOAD_ERRORS_NOTIFICATION_TYPE,
      <UploadImageErrorsMessage />,
      'UPLOAD_ERRORS',
      false,
      'warning',
      'notification__error',
      '#fff',
      'Image upload issue',
    ));
  }
};


// CMYK WARNING NOTIFICATION
const cmykWarningNotificationKey = (userPhotoId: string | number, pageId: string, layerId: string) => (
  `CMYK_${userPhotoId}_${pageId}_${layerId}`
);

export const dismissCmykWarningNotification = (userPhotoId: string | number, pageId: string, layerId: string) => (
  (dispatch: Dispatch) => {
    const key = cmykWarningNotificationKey(userPhotoId, pageId, layerId);

    dispatch(dismissNotification(key));
  }
);

export const cmykWarningNotification = (userPhotoId: string | number, pageId: string, layerId: string) => (
  (dispatch: Dispatch, getState: GetState) => {
    const key = cmykWarningNotificationKey(userPhotoId, pageId, layerId);

    const dismissed = notificationIsDismissed(getNotificationByKey(getState())(key));

    if (!dismissed) {
      dispatch(createAndSendNotification(
        GENERIC_NOTIFICATION_TYPE, <CmykWarningMessage />, key, false, 'info', 'notification__generic', '#fff', null, userPhotoId)
      );
    }
  }
);

export const createCartLockNotification = () =>
  createGenericNotification(PROJECT_CART_LOCK_MESSAGE, 'CART_LOCK');

export const createAdminLockNotification = () =>
  createGenericNotification(PROJECT_ADMIN_LOCK_MESSAGE, 'ADMIN_LOCK');

export const sendSuccessfulRegisterNotification = (
  message,
  key
) => sendSuccessNotification(
  "You've successfully logged in.",
  'notify_register'
);

export const sendNewSwAssetsNotification = () =>
  sendGenericNotification(GENERIC_NOTIFICATION_TYPE, <NewAssetsNotification />, 'NEW_SERVICE_WORKER_ASSETS');
