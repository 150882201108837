// @flow

import { type Dispatch, type GetState } from 'redux';
import { option, task } from 'fp-ts';
import { index } from 'fp-ts/lib/Array';
import { liftA2 } from 'fp-ts/lib/Apply';
import { Task as _Task, type Task } from 'fp-ts/lib/Task';
import { type Lazy } from 'fp-ts/lib/function';

import { placeholderPhotoArray } from '../../helpers/api';
import { optionFind, prop, deref } from '../../helpers/functions';
import { applyPhotoToLayer, addPage } from '../project/pages/actions';
import {
  givenPageSelector,
  getLayerFromPage,
  availablePhotoLayersSelector,
  photoLayersInProjectSelector,
  projectPagesSelector,
  canAddPagesSelector,
  nextAppropriateEmptyPhotoLayerSelector,
} from '../project/pages/selectors';
import { UI_INSTAGRAM_ALBUM, UI_DRAWER_MODE_SHRUNKEN, UI_DRAWER_MODE_EXPANDED } from '../ui/constants';
import { UI_GOOGLE_PHOTOS_ALBUM } from '../googlePhotos/constants';
import { paginateInstagram } from '../instagramPhotos/actions';
import { paginateGooglePhotos } from '../googlePhotos/actions';
import { paginateFacebook } from '../facebook/actions';
import { showModal } from '../ui/modal/actions';
import { type UserAlbumType } from '../../types/albums';
import {
  UI_MODAL_AUTOFILL_ADDED_PAGES,
  UI_MODAL_AUTOFILL_TOO_MANY_IMAGES,
} from '../ui/modal/constants';

import {
  getAlbumsForUser,
  getPhotosFromAlbum,
  getAlbumThumbnails,
  getDeletedPhotosByUser,
} from '../../services/albums';

import {
  ALBUMS_GET_REQUEST,
  ALBUMS_GET_SUCCESS,
  ALBUMS_GET_FAILURE,
  ALBUM_GET_PHOTOS_REQUEST,
  ALBUM_GET_PHOTOS_FAILURE,
  ALBUM_GET_PHOTOS_SUCCESS,
  ALBUM_SET_PHOTOS,
  ALBUM_ADD_ALBUM,
  UI_LOCAL_ALBUM_GET_MORE_PHOTOS,
  // ADD_IMAGE_ACTION_FOR_ANALYTICS,
  SORT_ALBUM_PHOTOS_ACTION,
} from './constants';

import { setCurrentPage, setPartialSelectionAction, setAutoFillImgCount, setDrawerMode, incAutoFillImgCount } from '../ui/actions';
import { addPhoto, uploadPhoto, removeAllPhotosfromProject } from '../userPhotos/actions';
import { galleryImagesSelector, myPhotosGallerySelector } from '../v2/galleries/selectors';
import { photoIsInProject, usedPhotosSelector } from '../userPhotos/selectors';
import photoHelper, { type PhotoSortOp, sortPhotosBy, DATE_TAKEN_ASC, PHOTO_SORT_OPERATIONS_SELECT_OPTIONS } from '../../types/photo';
import { fromNullable } from 'fp-ts/lib/Option';
import { determinePhotoLayerSide } from '../../helpers/layers';
import { isBookCategory } from '../../helpers/product';
import { pageHasCompositeDesign, pagesPerOperation } from '../../helpers/pages';
import { productShouldEnableHalfPageSelectionSelector, productCategorySelector } from '../product/selectors';
import {
  currentAlbumPhotosSelector,
  currentAlbumSelector,
  albumIsSortableSelector,
  currentAlbumSortOperationSelector,
} from './selectors';
import {
  sendAnalyticsForStartedAutofill,
  itlyImageAdded,
} from '../analytics/actions';
import { getQueryParams } from '../../helpers/urlParameters';
import { strIncludes } from '../../helpers/strings';
import { getPhotoFromId } from '../../helpers/images';
import { uploadImagesToGallery, createGalleryAndUploadImages, paginateLocalPhotos, tryAutoOrientGalleryImage } from '../../store/v2/galleries/actions';
import { magentoIsReferrer } from '../../helpers/DOM';
import { getSortedPhotos } from '../../components/Drawer/AlbumsBrowser/AlbumViewer';

// Action creators
const albumsGetRequest = () => ({ type: ALBUMS_GET_REQUEST });
const albumsGetFailure = () => ({ type: ALBUMS_GET_FAILURE });
const albumsGetSuccess = albums => ({
  type: ALBUMS_GET_SUCCESS,
  payload: albums,
});

const albumGetPhotosRequest = albumId => ({
  type: ALBUM_GET_PHOTOS_REQUEST,
  payload: { albumId },
});
const albumGetPhotosFailure = () => ({ type: ALBUM_GET_PHOTOS_FAILURE });
const albumGetPhotosSuccess = (albumId, photos) => ({
  type: ALBUM_GET_PHOTOS_SUCCESS,
  payload: { albumId, photos },
});

const addAlbumToUserAlbums = newAlbum => (dispatch, getState) => {
  const { albums } = getState().userAlbums;
  dispatch({ type: ALBUM_ADD_ALBUM, payload: [...albums, newAlbum] });
};

export const albumSetPhotos = (albumId: string, photos: Array<Object>) => ({ type: ALBUM_SET_PHOTOS, payload: { albumId, photos } });

// Add any number of albumPhoto objects to a given albumId's albumPhotos array.
export const albumAddPhotos = (
  ...albumPhotos: Array<Object>
) => (
  albumId: string
) => (
  dispatch: Dispatch, getState: GetState
) => {
  const currentPhotos = getState().userAlbums.albumPhotos;
  const { flashId } = getState().user;
  const sortOperation: PhotoSortOp = option.fromNullable(currentAlbumSelector(getState()))
    .chain(prop('sortOperation'))
    .getOrElseValue(DATE_TAKEN_ASC);

  const albumSupportsSort = albumIsSortableSelector(getState());

  // Add the "isLoaded" flag to each passed albumPhoto.
  const formattedAlbumPhotos = albumPhotos.map(albumPhoto => ({
    ...albumPhoto,
    thumbURL: albumPhoto.thumbURL ? albumPhoto.thumbURL : getAlbumThumbnails(flashId, 'small', albumPhoto.mediaId),
    isLoaded: true,
  }));

  const combined = [
    ...formattedAlbumPhotos,
    ...(currentPhotos[albumId] || []),
  ];

  const photos = albumSupportsSort ? sortPhotosBy(sortOperation)(combined) : combined;

  dispatch(albumSetPhotos(albumId, photos));
};

// Action wrappers
export const DELETED_ALBUM_ID_FOR_ADMIN = 'USERDELETED';
const generateDeletedAlbum = flashId => albums => ({
  albumId: DELETED_ALBUM_ID_FOR_ADMIN,
  thumbURL: 'https://placehold.it/500x500?text=DELETED',
  user: flashId,
  imageCount: 1,
  thumbId: '',
  version: '1.1',
  createdAt: albums[0].createdAt,
  lastModifiedAt: albums[0].lastModifiedAt,
});

export const getAlbums = () => async (dispatch: Function, getState: Function) => {
  const { flashId, flashToken, anonymous, admin } = getState().user;
  dispatch(albumsGetRequest());
  if (!anonymous) {
    await getAlbumsForUser(flashId, flashToken)
      .then((albums: Array<UserAlbumType>) => {
        if (admin) {
          // Push a fake album into the albums array to allow admins to retrieve a users deleted images
          albums.push(generateDeletedAlbum(flashId)(albums));
        }
        const thumbURLs = getAlbumThumbnails(flashId, 'small', ...albums.map(album => album.thumbId));
        dispatch(albumsGetSuccess({
          albums: albums.map((album, i) => ({
            ...album,
            // Retain the albums current thumbnail if it's the deleted photos album generated for admins
            thumbURL: album.albumId === DELETED_ALBUM_ID_FOR_ADMIN ? album.thumbURL : thumbURLs[i],
          })),
          albumPhotos: albums.reduce((albumPhotos, album) => ({
            ...albumPhotos,
            [album.albumId]: placeholderPhotoArray(album.imageCount),
          }), {}),
        }));

        /* TESTING AUTO ADD */
        const queryParams = getQueryParams() || {};
        const { autoAddAlbum } = deref(
          'autoAddAlbum',
        )(queryParams);
        const accountPageIsNotReferrer = !strIncludes('customer')(document.referrer);
        // we want the cart page to be the referrer here!
        const cartPageIsNotReferrer = strIncludes('checkout')(document.referrer);
        const isInitialProjectStart = magentoIsReferrer() && accountPageIsNotReferrer && cartPageIsNotReferrer;
        if (isInitialProjectStart && autoAddAlbum.isSome()) {
          dispatch(getAlbumPhotos(autoAddAlbum.getOrElseValue('')));
        }
      })
      .catch((e) => {
        dispatch(albumsGetFailure());
        console.error(e);
      });
  }
};

export const autoAddMediaIfPresent = photos => (dispatch: Function, getState: Function) => {
  const queryParams = getQueryParams() || {};
  const { autoAddMedia } = deref(
    'autoAddMedia',
  )(queryParams);

  if (autoAddMedia.isSome()) {
    const found = photos.find(p => p.mediaId === autoAddMedia.getOrElseValue(''));
    dispatch(changePageAndAddPhoto(found, nextAppropriateEmptyPhotoLayerSelector(getState()), false));
  }
}

export const sortAlbumPhotosAction = (sortOp: PhotoSortOp) => (albumId: String) => (photos: Array<Object>) => ({
  type: SORT_ALBUM_PHOTOS_ACTION,
  payload: {
    albumId,
    photos: sortPhotosBy(sortOp)(photos),
    sortOp,
  },
})

export const sortAlbumPhotos = (sortOp: PhotoSortOp) => (dispatch: Function, getState: Function) => {
  const state = getState();
  const { currentAlbum } = state.ui;
  const albumPhotos = currentAlbumPhotosSelector(state);
  dispatch(sortAlbumPhotosAction(sortOp)(currentAlbum)(albumPhotos));
};

export const getAlbumPhotos = (albumId: string) => (dispatch: Function, getState: Function) => {
  const { flashId, flashToken, anonymous, admin } = getState().user;
  const { facebookActive } = getState().facebook;

  /**
   * Given a response from albumGetPhotosRequest, mutate the result and dispatch it to state
   * @param {*} photoObj
   */
  const handlePhotosResponse = (photoObj: Object) => {
    const photos = photoObj || [];
    const cdnUrls = getAlbumThumbnails(flashId, 'small', ...photos.map(x => x.mediaId));

    const photosWithCDNurls = photos.map((x, i) => ({
      ...x,
      thumbURL: cdnUrls[i],
      isLoaded: true,
    }));
    dispatch(albumGetPhotosSuccess(albumId, photosWithCDNurls));

    const accountPageIsNotReferrer = !strIncludes('customer')(document.referrer);
    const cartPageIsNotReferrer = strIncludes('checkout')(document.referrer);
    const isInitialProjectStart = magentoIsReferrer() && accountPageIsNotReferrer && cartPageIsNotReferrer;
    if (isInitialProjectStart) {
      dispatch(autoAddMediaIfPresent(photosWithCDNurls));
    }
  };

  // Handle request for deleted images album created for admin
  if (albumId === DELETED_ALBUM_ID_FOR_ADMIN && admin) {
    dispatch(albumGetPhotosRequest(albumId));
    getDeletedPhotosByUser(flashId, flashToken).then(resp => handlePhotosResponse(resp))
      .catch((e) => {
        dispatch(albumGetPhotosFailure());
        console.error(e);
      });
    return;
  }

  if (albumId !== UI_INSTAGRAM_ALBUM && !anonymous && !facebookActive && albumId !== UI_GOOGLE_PHOTOS_ALBUM) {
    dispatch(albumGetPhotosRequest(albumId));

    getPhotosFromAlbum(flashId, flashToken)(albumId)
      .then(resp => handlePhotosResponse(resp))
      .catch((e) => {
        dispatch(albumGetPhotosFailure());
        console.error(e);
      });
  }
};

const getMoreInstagramPhotos = () => async (dispatch, getState) => {
  const { IgRequesting } = getState().instagramPhotos;

  if (!IgRequesting) {
    await dispatch(paginateInstagram());
  }
};

const getMoreGooglePhotos = () => (dispatch, getState) => {
  const { googlePhotosRequesting } = getState().googlePhotos;
  if (!googlePhotosRequesting) {
    dispatch(paginateGooglePhotos());
  }
};

const getMoreFacebookPhotos = () => (dispatch, getState) => {
  const { facebookRequesting } = getState().facebook;
  if (!facebookRequesting) {
    dispatch(paginateFacebook());
  }
};

const getMoreLocalPhotos =  () => (dispatch, getState) => {
  const newPageImages = dispatch(paginateLocalPhotos());
  dispatch({type: UI_LOCAL_ALBUM_GET_MORE_PHOTOS, payload: newPageImages})
};

export const getMoreAlbumPhotos = (albumId: string) => async (dispatch: Dispatch, getState:any) => {
  const state = getState();
  if (albumId === UI_INSTAGRAM_ALBUM) {
    await dispatch(getMoreInstagramPhotos());
  } else if (albumId === UI_GOOGLE_PHOTOS_ALBUM) {
    dispatch(getMoreGooglePhotos());
  } else  if (state.facebook.facebookActive) {
    dispatch(getMoreFacebookPhotos());
  } else if (!state.dropbox.dropboxActive) {
      //@todo: add pagination work for Dropbox
    dispatch(getMoreLocalPhotos())
  }
};

/* NOTE: This is the point where albumPhotos transition to a userPhoto object, and is the closest thing to a
   border between the two parts of the editor's image handling. */
export const addAlbumPhotoToProject = (albumPhoto: Object, layerId: string = '', pageId: string, sendAnalytics: boolean = false) => (
  async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const { userPhotos } = state;
    const { userPhotoId } = albumPhoto;

    const safePageId = fromNullable(pageId).getOrElseValue(state.ui.currentPage);
    const page = givenPageSelector(state)(safePageId);
    let targetLayer = page.layers.find((l) => l.id === layerId);
    if (!targetLayer) {
      targetLayer = page.layers[0];
    }

    const p = await getPhotoFromId(userPhotos)(userPhotoId).getOrElse(async () => {
      const pOption = await photoHelper.attemptConstructFromAlbumPhoto(albumPhoto);
      return pOption.toNullable();
    });

    if (albumPhoto.source !== 'local' && albumPhoto.cdnUrl) {
      const myPhotosGallery = myPhotosGallerySelector(state);
      if (!myPhotosGallery) {
        await dispatch(createGalleryAndUploadImages('My Photos', [p], safePageId, targetLayer.id));
      } else {
        await dispatch(uploadImagesToGallery(myPhotosGallery.id, [p], safePageId, targetLayer.id));
      }
    } else if (!albumPhoto.autoOriented) {
      dispatch(tryAutoOrientGalleryImage(albumPhoto, safePageId, targetLayer.id));
    } else {
      dispatch(addPhoto(p));
      dispatch(applyPhotoToLayer(p, safePageId, targetLayer.id));
    }

    if (sendAnalytics) {
      dispatch(itlyImageAdded('drawer'));
    }

    // Re-expands the album drawer if shrunken
    const drawerMode = state.ui.drawerMode;
    if (drawerMode === UI_DRAWER_MODE_SHRUNKEN) {
      dispatch(setDrawerMode(UI_DRAWER_MODE_EXPANDED));
    }
  }
);

export const changePageAndAddPhoto = (
  albumPhoto: Object,
  nextAvailable: Object,
  sendAnalytics: boolean = false,
) => (
  dispatch: Dispatch,
  getState: GetState,
): Promise<void> => new Promise((resolve) => {
  dispatch(setCurrentPage(nextAvailable.pageId));
  if (sendAnalytics) {
    dispatch(itlyImageAdded('drawer'));
  }
  resolve(dispatch(addAlbumPhotoToProject(albumPhoto, nextAvailable.layerId, nextAvailable.pageId)).then(() => {
    if (productShouldEnableHalfPageSelectionSelector(getState())) {
      const nextAvailableLayer = getLayerFromPage(nextAvailable.pageId)(nextAvailable.layerId)(getState());
      const targetPageUsesCompositeDesign = pageHasCompositeDesign(givenPageSelector(getState())(nextAvailable.pageId));
      if (targetPageUsesCompositeDesign) {
        dispatch(setPartialSelectionAction(determinePhotoLayerSide(nextAvailableLayer)));
      }
    }
  }));
});

const getAvailiableLayers = (fromScratch: boolean) => state =>
  (fromScratch ? photoLayersInProjectSelector(state) : availablePhotoLayersSelector(state));

/* will try to fill the pages with all of the images in a gallery.
  If the product is a book and there are not enough pages to fit all the photos in the gallery it will try to add pages with
  a reasonable amount of page layers until all photos have been used.
  if scratch flag is set to true then it will overwrite any photos already in the pages.
*/
export const autoFillPhotos = (albumId: string, scratch: boolean = false, sendAnalytics: boolean = false) =>
  (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const initNumberOfPages: number = projectPagesSelector(state).length;
  const category: string = productCategorySelector(state);
  const isBook = isBookCategory(category);
  const pagesToAddPer: number = pagesPerOperation(category);
  const usedPhotos = usedPhotosSelector(state);
  const currentAlbumId = state.ui.currentAlbum;

  dispatch(setAutoFillImgCount(0));

  if (scratch) {
    dispatch(removeAllPhotosfromProject());
  }

  if (sendAnalytics) {
    dispatch(sendAnalyticsForStartedAutofill({
      autofillType: scratch ? 'start from scratch' : 'finish my book for me',
    }));
  }

  let galleryPhotos = scratch
    ? galleryImagesSelector(getState(), currentAlbumId)
    : galleryImagesSelector(getState(), currentAlbumId).filter(p => !photoIsInProject(p)(usedPhotos));

  let availableLayers = scratch // Layers not currently being used.
    ? photoLayersInProjectSelector(state)
    : availablePhotoLayersSelector(state);

  // while there are empty layers, add a page that has up to 4 user photo slots.
  if (isBook) {
    while (
      canAddPagesSelector(getState()) &&
      (galleryPhotos.length - availableLayers.length) > 0 &&
      availableLayers.length < galleryPhotos.length
    ) {
      dispatch(addPage(pagesToAddPer));
      const _state = getState();
      availableLayers = scratch ? photoLayersInProjectSelector(_state) : availablePhotoLayersSelector(_state);
    }
  }

  // add photos to empty layers;
  galleryPhotos = getSortedPhotos(galleryPhotos, currentAlbumSortOperationSelector(getState()) || PHOTO_SORT_OPERATIONS_SELECT_OPTIONS[0].value);
  const photosAdded = galleryPhotos
    .map((p, i: number) => {
      if (!p.isPlaceholder) {
        const _availableLayers = getAvailiableLayers(scratch)(getState());

        const throttleAddPhoto = id => pageId => new _Task(() =>
          new Promise((res) => {
            dispatch(incAutoFillImgCount());
            return setTimeout(() => res(dispatch(addAlbumPhotoToProject(p, id, pageId))), 0);
          })
        );

        const layer = index(i)(_availableLayers);
        return liftA2(option.option)(throttleAddPhoto)(layer.chain(prop('id')))(layer.chain(prop('pageId')))
          .getOrElseValue(new _Task(() => Promise.resolve()));
      }
      return Promise.resolve();
    });

  return photosAdded
    .reduce((acc: Task<Lazy<Promise<void>>>, x: Task<Lazy<Promise<void>>>): Task<Lazy<Promise<void>>> => acc.chain(() => x), task.of())
    .run()
    .then(() => {
      const newNumberOfPages = projectPagesSelector(getState()).length;
      const pagesAdded = newNumberOfPages - initNumberOfPages;
      if (pagesAdded > 0 && galleryPhotos.length - getState().ui.autoFillImgCount > 0) {
        dispatch(showModal(
          UI_MODAL_AUTOFILL_TOO_MANY_IMAGES,
          null,
          null,
          { pagesAdded, imgNumber: galleryPhotos.length - getState().ui.autoFillImgCount, productCategory: getState().product.category })
        );
        dispatch(setAutoFillImgCount(0));
      } else if (galleryPhotos.length - getState().ui.autoFillImgCount > 0) {
        dispatch(showModal(
          UI_MODAL_AUTOFILL_TOO_MANY_IMAGES,
          null,
          null,
          { pagesAdded, imgNumber: galleryPhotos.length - getState().ui.autoFillImgCount, productCategory: getState().product.category })
        );
        dispatch(setAutoFillImgCount(0));
      } else if (pagesAdded > 0) {
        dispatch(showModal(UI_MODAL_AUTOFILL_ADDED_PAGES, null, null, { pagesAdded }));
        dispatch(setAutoFillImgCount(0));
      } else {
        dispatch(showModal());
        dispatch(setAutoFillImgCount(0));
      }
      return pagesAdded;
    });
};

export const buildBasicAlbum = (albumId: string, thumbURL: string) => async (dispatch: Dispatch) => {
  const newAlbum = { albumId, thumbURL };
  await dispatch(addAlbumToUserAlbums(newAlbum));
};
