import { buildObjectFromPaths } from '../../helpers/objects';
import {
  DEFAULT_GTM_DATA,
  GTM_EVENT_USER_CREATED_GALLERY,
  GTM_EVENT_USER_SELECTED_ATTRIBUTE,
  GTM_EVENT_USER_CLICKED_EDIT_PAGES,
  GTM_EVENT_USER_CLICKED_AUTOFILL,
  GTM_EVENT_USER_AUTOFILLED_PHOTOS,
  GTM_EVENT_USER_CLICKED_ADD_RECIPIENT_ADDRESS,
  GTM_EVENT_USER_ADDED_ADDRESS,
  GTM_EVENT_FAILED_ADDRESS_UPLOAD,
  GTM_EVENT_REMOVED_RETURN_ADDRESS,
  GTM_EVENT_REMOVED_CSV,
  GTM_EVENT_USER_RECEIVED_CART_WARNING,
  GTM_EVENT_USER_EDITED_ADDRESS,
  GTM_EVENT_USER_REMOVED_PHOTO,
  GTM_EVENT_CONVERTED_PROJECTED,
  GTM_EVENT_PHOTO_SOURCE_SELECTED,
  GTM_EVENT_LOW_RES_NOTIFICATION,
  GTM_EVENT_USER_APPLIED_LAYOUT,
  GTM_EVENT_USER_RENAMED_PROJECT,
  GTM_EVENT_EDITOR_REMOVED_PAGE,
  GTM_EVENT_EDITOR_ADDED_PAGE,
  GTM_EVENT_USER_UPLOADED_ADDRESSES,
  ITLY_EVENT_PROJECT_STARTED,
  ITLY_EVENT_USER_LOGIN,
  ITLY_EVENT_USER_SIGN_UP,
  ITLY_EVENT_ADD_TO_CART,
  ITLY_IDENTIFY,
  ITLY_PAGE_VIEWED,
  ITLY_PAGE_TYPE,
  ITLY_EVENT_PROJECT_EDITED,
  ITLY_IMAGE_EDITED,
  ITLY_IMAGE_UPLOADED,
  ITLY_IMAGE_ADDED,
} from './constants';
import { currentVisiblePageCountMinusCoverSelector } from '../project/pages/selectors';
import { optionGet } from '../../helpers/functions';
import Itly from '../../itly';
import { requestMagentoChildProduct, convertChildProductResponse } from '../../services/magento';
import { setMagentoProductData } from '../product/actions';
import { productAttributesSelector } from '../product/selectors';
import { getCookie, setCookie } from '../../helpers/cookies';
import axios from 'axios';


const { REACT_APP_ENV } = process.env;
const isDev = REACT_APP_ENV === 'local' || REACT_APP_ENV === 'dev';

// Safely send GTM events based on presence of GTM in DOM
export const sendGtmEvent = (eventName: string) => (eventData: Object) => (dispatch: Function, getState: Function<Object>) => {
  if (isDev || !window.dataLayer) {
    return;
  }
  try {
    const formattedEventData = {
      ...DEFAULT_GTM_DATA,
      ...eventData,
      event: eventName,
    };
    const gtmData = buildObjectFromPaths(formattedEventData)({
      // This wrapping allows callers to use {state.product.sku} instead of {product.sku} for clarity
      state: getState(),
    });
    window.dataLayer.push(gtmData);
  } catch (e) {
    // Do nothing, just don't crash because of analytics.
    window.newrelic.noticeError(e);
  }
};

const createSendAnalyticsFn = (eventName: string) => (eventData: Object) =>
  sendGtmEvent(eventName)(eventData);

// GTM - to be removed

export const sendAnalyticsForGalleryCreation = createSendAnalyticsFn(GTM_EVENT_USER_CREATED_GALLERY);
export const sendAnalyticsForAttributeChange = createSendAnalyticsFn(GTM_EVENT_USER_SELECTED_ATTRIBUTE);
export const sendAnalyticsForUserEditPages = createSendAnalyticsFn(GTM_EVENT_USER_CLICKED_EDIT_PAGES);
export const sendAnalyticsForClickedAutofill = createSendAnalyticsFn(GTM_EVENT_USER_CLICKED_AUTOFILL);
export const sendAnalyticsForStartedAutofill = createSendAnalyticsFn(GTM_EVENT_USER_AUTOFILLED_PHOTOS);
export const sendAnalyticsForAddRecipientAddress = createSendAnalyticsFn(GTM_EVENT_USER_CLICKED_ADD_RECIPIENT_ADDRESS);
export const sendAnalyticsForAddAddress = createSendAnalyticsFn(GTM_EVENT_USER_ADDED_ADDRESS);
export const sendAnalyticsForUploadAddress = createSendAnalyticsFn(GTM_EVENT_USER_UPLOADED_ADDRESSES);
export const sendAnalyticsForFailedCsvUpload = createSendAnalyticsFn(GTM_EVENT_FAILED_ADDRESS_UPLOAD);
export const sendAnalyticsForRemoveReturnAddress = createSendAnalyticsFn(GTM_EVENT_REMOVED_RETURN_ADDRESS);
export const sendAnalyticsForRemoveCsv = createSendAnalyticsFn(GTM_EVENT_REMOVED_CSV);
export const sendAnalyticsForCartWarning = createSendAnalyticsFn(GTM_EVENT_USER_RECEIVED_CART_WARNING);
export const sendAnalyticsForUserEditAddress = createSendAnalyticsFn(GTM_EVENT_USER_EDITED_ADDRESS);
export const sendAnalyticsForUserRemovedPhoto = createSendAnalyticsFn(GTM_EVENT_USER_REMOVED_PHOTO);
export const sendAnalyticsForProjectConversion = createSendAnalyticsFn(GTM_EVENT_CONVERTED_PROJECTED);
export const sendAnalyticsForSelectedMediaSource = (source: string) => createSendAnalyticsFn(GTM_EVENT_PHOTO_SOURCE_SELECTED)({ source });
export const sendAnalyticsForLowRes = createSendAnalyticsFn(GTM_EVENT_LOW_RES_NOTIFICATION);
export const sendAnalyticsForLayoutChange = createSendAnalyticsFn(GTM_EVENT_USER_APPLIED_LAYOUT);
export const sendAnalyticsForRenameProject = createSendAnalyticsFn(GTM_EVENT_USER_RENAMED_PROJECT);
export const sendAnalyticsForRemovedPage = createSendAnalyticsFn(GTM_EVENT_EDITOR_REMOVED_PAGE);
export const sendAnalyticsForAddedPage = createSendAnalyticsFn(GTM_EVENT_EDITOR_ADDED_PAGE);

// leaving this for reference
// const PRINTS_SPLIT_COOKIE = 'au_split_editorV2_prints';

const getSplitCookie = (category: string) => getCookie(`au_split_editorV2_${category}`) || '';

export const getIP = async (): Promise<string> => {
  const res = await axios.get('https://api.ipify.org/?format=json');
  return res.data.ip;
};

// /$$   /$$     /$$
// |__/  | $$    | $$
//  /$$ /$$$$$$  | $$ /$$   /$$
// | $$|_  $$_/  | $$| $$  | $$
// | $$  | $$    | $$| $$  | $$
// | $$  | $$ /$$| $$| $$  | $$
// | $$  |  $$$$/| $$|  $$$$$$$
// |__/   \___/  |__/ \____  $$
//                    /$$  | $$
//                   |  $$$$$$/
//                    \______/

const itlyEnabled = process.env.REACT_APP_ITLY_ENABLED === 'true';

// Itly Helpers

const getReportingProductPropertiesAsync = async (currentState, dispatch) => {
  const attributes = productAttributesSelector(currentState);
  const pageCountMinusCover = currentVisiblePageCountMinusCoverSelector(currentState);
  const productData = await requestMagentoChildProduct(currentState.product, pageCountMinusCover);
  const childResponse = convertChildProductResponse(productData);
  dispatch(setMagentoProductData(childResponse, Object.keys(attributes)));
  return childResponse.reportingProductProperties;
};

const getReportingProductPropertiesSync = (currentState) => {
  const productProperties = optionGet('product.reportingProductProperties')(currentState)
    .getOrElseValue({
      reportingProductType: '',
      reportingProductLine: '',
      reportingProductCategory: '',
      basePrice: '',
      name: '',
      sku: '',
      productId: '',
    });
  return productProperties;
};

const formatIdentifyPropertiesFromEventData = (eventData) => ({
  first_name: eventData.firstname,
  last_name: eventData.lastname,
  email: eventData.email,
});

const formatPageViewedProperties = (properties) => ({
  page_name: properties.name,
  page_type: ITLY_PAGE_TYPE,
});

const formatImageUploadedProperties = (eventData) => ({
  gallery_name: eventData.gallery_name,
  image_source: eventData.image_source,
});

// Itly Helpers
const formatItlyEventProperties = (projectId, properties) => {
  const result = {
    project_id: projectId,
    sku: properties.reportingProductSku || '',
    name: properties.name,
    price: properties.basePrice,
    product_id: properties.productId,
    category: properties.reportingProductCategory,
    product_line: properties.reportingProductLine,
    product_type: properties.reportingProductType,
    is_customizable_product: true,
    platform: 'web',
  };

  if (result.sku) {
    result.products = [{
      category: result.category,
      name: result.name,
      price: result.price,
      product_id: result.product_id,
      product_line: result.product_line,
      sku: result.sku,
    }];
  }
  return result;
};

const checkSplitCookieSet = (category) => {
  switch (category) {
    // leaving this an example usage
    /* case 'prints': {
      if (!getSplitCookie('prints')) {
        setCookie(PRINTS_SPLIT_COOKIE, false);
      }
      break;
    } */

    default:
      break;
  }
};

const sendItlyEvent = (eventName: string, eventData = {}) => async (dispatch, getState: Function) => {
  if (itlyEnabled) {
    const currentState = getState();
    const projectId = currentState.project.id;

    // Itly Event Router
    switch (eventName) {
      case ITLY_EVENT_PROJECT_STARTED: {
        const reportingProductProperties = await getReportingProductPropertiesAsync(currentState, dispatch);
        checkSplitCookieSet(reportingProductProperties.reportingProductCategory);
        let properties = formatItlyEventProperties(projectId, reportingProductProperties);
        properties = {
          ...properties,
          email: currentState.user.email,
          // todo will fix this at a later date to handle for 3rd party api being down, for now return empty string
          // customer_ip: await getIP(),
          customer_ip: '',
        };

        if (window.newrelic) {
          window.newrelic.addPageAction('editorv1_project_created', {
            version: '1',
            userId: currentState.user.flashId,
            projectId,
            sku: reportingProductProperties.sku,
            productId: reportingProductProperties.productId,
            category: reportingProductProperties.reportingProductCategory,
            productLine: reportingProductProperties.reportingProductLine,
            productType: reportingProductProperties.reportingProductType,
          });
        }

        Itly.trackProjectStarted(properties);
        break;
      }

      case ITLY_EVENT_USER_LOGIN: {
        const identifyProperties = formatIdentifyPropertiesFromEventData(eventData);
        Itly.identify(eventData.id, identifyProperties);
        break;
      }

      case ITLY_EVENT_USER_SIGN_UP: {
        const identifyProperties = formatIdentifyPropertiesFromEventData(eventData);
        const accountCreatedProperties = {
          user_id: eventData.id,
          email: eventData.email,
          site_id: 'us:web',
        };
        Itly.trackAccountCreated(accountCreatedProperties);
        Itly.identify(eventData.id, identifyProperties);
        break;
      }

      case ITLY_EVENT_ADD_TO_CART: {
        const reportingProductProperties = getReportingProductPropertiesSync(currentState);
        checkSplitCookieSet(reportingProductProperties.reportingProductCategory);
        let productAddedProperties = formatItlyEventProperties(projectId, reportingProductProperties);
        productAddedProperties = {
          ...productAddedProperties,
          email: currentState.user.email,
          location: 'editor',
          // todo will fix this at a later date to handle for 3rd party api being down, for now return empty string
          // customer_ip: await getIP(),
          customer_ip: '',
        };
        if (window.newrelic) {
          window.newrelic.addPageAction('editorv1_project_added_to_cart', {
            version: '1',
            userId: currentState.user.flashId,
            projectId,
            sku: reportingProductProperties.sku,
            productId: reportingProductProperties.productId,
            category: reportingProductProperties.reportingProductCategory,
            productLine: reportingProductProperties.reportingProductLine,
            productType: reportingProductProperties.reportingProductType,
          });
        }
        Itly.trackProductAdded(productAddedProperties);
        break;
      }

      case ITLY_EVENT_PROJECT_EDITED: {
        const reportingProductProperties = getReportingProductPropertiesSync(currentState);
        const properties = formatItlyEventProperties(projectId, reportingProductProperties);
        Itly.trackProjectEdited(properties);
        break;
      }

      case ITLY_IDENTIFY: {
        const identifyProperties = formatIdentifyPropertiesFromEventData(eventData);
        Itly.identify(eventData.id, identifyProperties);
        break;
      }

      case ITLY_PAGE_VIEWED: {
        // Below line is commented out due to EN-2565 to avoid /:sku/child endpoint to be called twice
        // Instead we are passing currentState.product to get the name of the page
        // const reportingProductProperties = await getReportingProductPropertiesAsync(currentState, dispatch);
        const pageViewedProperties = formatPageViewedProperties(currentState.product);
        Itly.page(pageViewedProperties.page_type, pageViewedProperties.page_name);
        Itly.trackPageViewed(pageViewedProperties);
        break;
      }

      case ITLY_IMAGE_EDITED: {
        const reportingProductProperties = getReportingProductPropertiesSync(currentState);
        const properties = formatItlyEventProperties(projectId, reportingProductProperties);
        Itly.trackImageEdited(properties);
        break;
      }

      case ITLY_IMAGE_UPLOADED: {
        const properties = formatImageUploadedProperties(eventData);
        Itly.trackImageUploaded(properties);
        break;
      }

      case ITLY_IMAGE_ADDED: {
        const reportingProductProperties = getReportingProductPropertiesSync(currentState);

        checkSplitCookieSet(reportingProductProperties.reportingProductCategory);

        const properties = formatItlyEventProperties(projectId, reportingProductProperties);

        Itly.trackImageAdded(properties);
        break;
      }

      default:
        break;
    }
  }
};

// Itly Actions

export const itlyIdentify = (eventData) => sendItlyEvent(ITLY_IDENTIFY, eventData);
export const itlyUserLoginEvent = (eventData) => sendItlyEvent(ITLY_EVENT_USER_LOGIN, eventData);
export const itlyUserSignUpEvent = (eventData) => sendItlyEvent(ITLY_EVENT_USER_SIGN_UP, eventData);
export const itlyProjectStartedEvent = () => sendItlyEvent(ITLY_EVENT_PROJECT_STARTED);
export const itlyProductAddToCartEvent = () => sendItlyEvent(ITLY_EVENT_ADD_TO_CART);
export const itlyPageViewed = () => sendItlyEvent(ITLY_PAGE_VIEWED);
export const itlyProjectEditedEvent = () => sendItlyEvent(ITLY_EVENT_PROJECT_EDITED);
export const itlyImageEditedEvent = () => sendItlyEvent(ITLY_IMAGE_EDITED);
export const itlyImageUploaded = (eventData) => sendItlyEvent(ITLY_IMAGE_UPLOADED, eventData);
export const itlyImageAdded = () => sendItlyEvent(ITLY_IMAGE_ADDED);

