// @flow

import { createSelector } from 'reselect';
import { option } from 'fp-ts';
import { type Option } from 'fp-ts/lib/Option';
import { not } from 'fp-ts/lib/function';

import { optionGet, optionsEqual, prop, equals } from '../../helpers/functions';
import { meetsAll } from '../../helpers/conditionals';
import photo, { type Photo } from '../../types/photo';
import { userAuthorizedSelector } from '../user/selectors';
import { userPhotoIdsInProjectSelector } from '../project/pages/selectors';
import { getUserPhotoFromId } from '../../helpers/images';

// (STATE, PROPS) SELECTORS
// functions that take the arguments (state, props), made to be used with createSelector for usage in components.
export const getUserPhotos = (state: { userPhotos: Array<Object> }) => state.userPhotos;

export const getAllPhotos = createSelector(
  getUserPhotos,
  userPhotos => userPhotos.map(userPhoto => photo.construct(userPhoto))
);

export const getUploadablePhotos = (state: { userPhotos: Array<Object> }) => getAllPhotos(state).filter(photo.isUploadable);

// Returns all Photos that are uploadable and not failed.
export const getNonFailedUploadablePhotos = (state: { userPhotos: Array<Object> }) => (
  getAllPhotos(state).filter(meetsAll(photo.isUploadable, not(photo.isFailed)))
);

export const getSavablePhotos = (state: { userPhotos: Array<Object>, project: Object }) => (
  getAllPhotos(state)
    .filter((p) => userPhotoIdsInProjectSelector(state).includes(photo.getId(p)))
    .filter(photo.isSavable)
);

export const getFailedPhotos = (state: { userPhotos: Array<Object> }) => getAllPhotos(state).filter(photo.isFailed);

export const getUserPhotoId = (_: Object, props: { layer: Object }) => optionGet('data.userPhotoId')(props.layer);

export const albumPhotoSelector = (_: Object, props: { albumPhoto: Object }) => props.albumPhoto;

// Predicate that indicates whether or not the given albumPhoto matches the given Photo.
export const matchesAlbumPhoto = (albumPhoto: Object) => (p: Photo) => (
  // photo.originId === albumPhoto.id
  optionsEqual(photo.getOriginId(p))(prop('id')(albumPhoto)) ||
  // photo.mediaId === albumPhoto.id
  optionsEqual(photo.getMediaId(p))(prop('id')(albumPhoto)) ||
  // albumPhoto.userPhotoId === photo.id
  prop('userPhotoId')(albumPhoto).map(equals(photo.getId(p))).getOrElseValue(false) ||
  // albumPhoto.mediaId === photo.mediaId
  prop('mediaId')(albumPhoto).map(equals(photo.getMediaId(p).toNullable())).getOrElseValue(false)
);

// Predicate that indicates whether or not the given albumPhoto matches some Photo in the given usedPhotos array.
export const photoIsInProject = (albumPhoto: Object) => (usedPhotos: Array<Photo>) => usedPhotos.some(matchesAlbumPhoto(albumPhoto));

// RESELECT SELECTORS
export const uploadCountSelector = createSelector(
  [getAllPhotos, userAuthorizedSelector],
  (photos, userAuthorized) => (
    userAuthorized ? (
      photos.filter(p => photo.isUploadable(p) && !photo.isFailed(p)).length
    ) : (
        0
      )
  ),
);

export const failedPhotosSelector = createSelector(
  getAllPhotos,
  photos => (
    photos.filter(p => photo.isUploadable(p) && photo.isFailed(p))
  ),
);

// Returns an array of Photos that have been used in the project.
export const usedPhotosSelector = createSelector(
  getAllPhotos,
  userPhotoIdsInProjectSelector,
  (allPhotos: Array<Photo>, userPhotoIdsInProject: Array<string>) => (
    allPhotos.filter(p => userPhotoIdsInProject.includes(photo.getId(p)))
  ),
);

// Creates a selector that takes the albumPhoto object from a component's props and checks if it matches a Photo is in use.
export const makeAlbumPhotoIsUsedSelector = (): (state: Object, props: Object) => boolean => createSelector(
  usedPhotosSelector,
  albumPhotoSelector,
  (usedPhotos, albumPhoto) => photoIsInProject(albumPhoto)(usedPhotos),
);

// Creates a Photo selector that uses a layer's data to get an option of the layer's Photo.
export const makeUserPhotoSelector = (): (state: Object, props: Object) => Option<Photo> => createSelector(
  [getUserPhotoId, getUserPhotos],
  (userPhotoId, userPhotos) => (
    userPhotoId
      .chain(id => getUserPhotoFromId(id, userPhotos))
      .chain((o: Object) => option.fromNullable(photo.construct(o)))
  ),
);
