export const SET_NEW_PROJECT_DATA = 'SET_NEW_PROJECT_DATA';
export const MANUAL_SAVE = 'PROJECT/MANUAL_SAVE';

export const FORCE_PROJECT_SAVE_REQUEST = 'FORCE_PROJECT/SAVE/REQUEST';
export const FORCE_PROJECT_SAVE_SUCCESS = 'FORCE_PROJECT/SAVE/SUCCESS';
export const FORCE_PROJECT_SAVE_FAILURE = 'FORCE_PROJECT/SAVE/FAILURE';

export const PROJECT_STATE_UPDATED_IN_CART = 'UPDATED_IN_CART';
export const PROJECT_STATE_IN_CART = 'IN_CART';
export const PROJECT_STATE_PRINTED = 'PRINTED';
export const PROJECT_STATE_ADMIN_EDITING = 'ADMIN_EDITING';
export const PROJECT_STATE_IN_PROGRESS = 'IN_PROGRESS';

export const ADMIN_SAVE_PROJECT_REQUEST = 'ADMIN_SAVE_PROJECT_REQUEST';
export const ADMIN_SAVE_PROJECT_SUCCESS = 'ADMIN_SAVE_PROJECT_SUCCESS';
export const ADMIN_SAVE_PROJECT_FAILURE = 'ADMIN_SAVE_PROJECT_FAILURE';

export const FINAL_SAVE_ADD_TO_CART_REQUEST = 'FINAL_SAVE_ADD_TO_CART_REQUEST';
export const FINAL_SAVE_ADD_TO_CART_SUCCESS = 'FINAL_SAVE_ADD_TO_CART_SUCCESS';
export const FINAL_SAVE_ADD_TO_CART_FAILURE = 'FINAL_SAVE_ADD_TO_CART_FAILURE';

export const ENABLE_ADD_TO_ALL = 'ENABLE_ADD_TO_ALL';

export const SET_IS_WDS_PROJECT = "SET_IS_WDS_PROJECT";
