import React from 'react';
// import { showUploadErrorModal } from '../../store/addPhotos/actions';
import { connect } from 'react-redux';
import PdfPreviewLink from '../PdfPreview/PdfPreviewLink'

const CmykNotification = (props) => {
  const CMYK_WARNING = 'The image selected uses a CMYK color profile and may not look accurate here. Don’t worry, your project will print with the correct color profile, which can be previewed here:';
  function renderWarningMessage() {
    return `${CMYK_WARNING} `;
  }

  return (
    <span>
      {renderWarningMessage()}

      <PdfPreviewLink onClose={this.toggleMenu} renderButton={({pdfPreviewToggle}) => (
        <button key={1} className="btn btn--link" onClick={event => {
        event.preventDefault();
        pdfPreviewToggle();
      }}>
        View Page Proof
        </button>
      )} />
    </span>
  );
};

function mapStateToProps({ notifications }) {
  return {
    notifications,
  };
}

export default connect(mapStateToProps)(CmykNotification);
