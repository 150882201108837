// @flow
import { array, option } from 'fp-ts';
import chunk from 'lodash.chunk';

/* Takes a maximum number and returns a function that takes a number and
   itself returns either the maximum or the number. */
export const cap = (max: number) => (num: number) => Math.min(num, max);

/* Takes a minimum number and returns a function that takes a number and
   itself returns either the number or the minimum. */
export const floor = (min: number) => (num: number) => Math.max(num, min);

/* Takes a min and max and a number and returns:
    - If the number is in between min and max, the number.
    - If the number is less than min, the min.
    - If the number is more than max, the max. */
export const clamp = (min: number, max: number) => (num: number) => floor(min)(cap(max)(num));

// Takes a boolean and returns either 1 or -1.
export const direction = (b: boolean) => (((b ? 1 : 0) * 2) + -1);

/* Takes two numbers, and returns a function that takes a float between 0 and 1,
   which in turn yields an interpolated number between the start and end numbers. */
export const interpolate = (start: number, end: number) => (t: number) => (start + ((end - start) * t));

// Takes a min, a max, and a number and returns a boolean indicating whether or not the number is in range.
export const inRange = (min: number, max: number) => (num: number) => ((num >= min) && (num <= max));

/* Takes a number and, if the number is negative, returns the next lower (most-negative) integer.
   Otherwise, returns the next highest integer. */
export const floorCeiling = (num: number) => (num > 0 ? Math.ceil(num) : Math.floor(num));

// Takes a number x and a precision n and rounds the number to that precision
export const round = (x: number, n: number = 0) => Math.round(x * (10 ** n)) / (10 ** n);

// Easing function
export const easeOutCubic = (t: number) => ((t - 1) ** 3) + 1;

// Gets a random number between a min and max
export const getRandomArb = (min: number, max: number) => (Math.random() * (max - min)) + min;

// Functional operators
export const multiply = (x: number) => (y: number) => x * y;
export const divide = (x: number) => (y: number) => x / y;
export const add = (x: number) => (y: number) => x + y;
export const subtract = (x: number) => (y: number) => x - y;
export const modulus = (x: number) => (y: number) => y % x;

// Takes a decimal number and truncates everything beyond the decimal
export const trunc = (x: number) => Math.trunc(x);

// Matches a value against provided ranges and returns corresponding value
export const matchNumberInRange = (...rangeSpec: Array<mixed>) => (val: number) => {
  if (rangeSpec.length % 2 !== 0) {
    throw new Error('An even number of arguments should be passed to matchNumberInRange');
  }
  return array.findFirst((x) => {
    const [range] = x;
    return inRange(...range)(val);
  })(chunk(rangeSpec, 2)).map(x => x[1]);
};

export const deriveNumberFromString = (val: string) =>
  option.fromNullable(parseInt(val.replace(/\D/g, ''), 10))
    .chain(option.fromPredicate(x => !isNaN(x)));
