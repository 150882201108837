// @flow

import { methods } from '../constants/api';
import { endpointFromBaseURL, options, fetchJSON } from '../helpers/api';
import { getAlbumThumbnailUrls, IMAGE_URL_BASE } from '../helpers/images';

const { GET_IMAGE_FROM_S3, GET_IMAGES, GET_ALBUMS } = methods;

const endpoint = endpointFromBaseURL(process.env.REACT_APP_S3_IMAGE_BASE_URL);

export const getAlbumsForUser = async (flashId: string, flashToken: string): Promise<UserAlbumType[]> =>
  fetchJSON(endpoint(GET_ALBUMS)(flashId), options(GET_ALBUMS)(flashToken));

export const getAlbumThumbnails = (flashId: string, thumbSize: string, ...mediaIds: string[]): string[] =>
  mediaIds.map((mediaId) => getAlbumThumbnailUrls(flashId)(mediaId, thumbSize));

export const getPhotosFromAlbum = (flashId: string, flashToken: string) => (albumId: string) =>
  fetchJSON(endpoint(GET_IMAGES)(flashId, albumId), options(GET_IMAGES)(flashToken));

export const getDeletedPhotosByUser = (flashId: string, flashToken: string) =>
  fetchJSON(endpoint(GET_IMAGES)(flashId, '', true), options(GET_IMAGES)(flashToken));
