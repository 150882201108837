import {
  USER_PHOTOS_ADD_PHOTO,
  USER_PHOTOS_REMOVE_PHOTO_BY_ID,
  USER_PHOTOS_REMOVE_ALL,
} from './constants';

import { getSavablePhotos } from './selectors';

import photo from '../../types/photo';

const defaultImagesState = [];

export default function userPhotosReducer(state = defaultImagesState, action) {
  switch (action.type) {
    case USER_PHOTOS_ADD_PHOTO: {
      const { p } = action.payload;

      // Ensure uniqueness of the new photo by filtering out any existing photos with that same id.
      const stateWithoutMatchingPhoto = state.filter(userPhoto => userPhoto.id !== p.id);

      return [
        ...stateWithoutMatchingPhoto,
        p,
      ];
    }

    case USER_PHOTOS_REMOVE_PHOTO_BY_ID: {
      const { userPhotoId } = action.payload;

      return state.filter(p => p.id !== userPhotoId);
    }

    case USER_PHOTOS_REMOVE_ALL: {
      return [];
    }

    default: {
      // Do nothing if we didn't match action type
      return state;
    }
  }
}

export const userPhotoStateFilter = state => getSavablePhotos(state).map(photo.extract);
