import { UPLOAD_STATUS } from '../../userPhotos/constants';

export default function uploadStatusReducer(state = '', action) {
  switch (action.type) {
    case UPLOAD_STATUS:
      const { status } = action.payload;
      return status;

    default:
      // Do nothing if we didn't match action type
      return state;
  }
}
