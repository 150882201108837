// @flow

import { option } from 'fp-ts';

import { prop, optionGet } from '../functions';
import { templateStr } from '../strings';
import { mapObj } from '../objects';
import match from '../match';
import { fontPxToPtMatcher } from '../fonts';

const funcNameRegex = RegExp(/^\$/);

type Callables = {
  [string]: Object => string,
};

const applyTextTransformation = (x: string) => (textTransformValue?: "none" | "uppercase" | "lowercase") => {
  const value = (textTransformValue || '').trim();
  switch (value) {
    case 'uppercase':
      return x.toUpperCase();
    case 'lowercase':
      return x.toLowerCase();
    case 'none':
    default:
      return x;
  }
};

export const getStyledLayerContent = (layerData) => (
  optionGet('style.textTransform')(layerData)
    .map(x => x.toLowerCase()) // normalize css value
    .map(applyTextTransformation(layerData.visibleText || layerData.content || ''))
    .getOrElseValue(layerData.visibleText || layerData.content || '')
);

export const getLayflatXmlFont = (data: Object): string => {
  // check if it should be all caps or not
  const caps = optionGet('style.fontSize')(data)
    .map(fontPxToPtMatcher)
    .filter(x => x === '14pt')
    .map(() => ' Caps')
    .getOrElseValue('');

  return (
    optionGet('style.fontFamily')(data)
      .map(match(
        "'HelveticaAU', sans-serif", () => 'New Modern'.concat(caps),
        "'Miller Display', serif", () => 'Contemporary'.concat(caps),
        match.default, () => 'Traditional'.concat(caps),
      ))
      .getOrElseValue('Traditional'.concat(caps))
  );
};

export const getLayflatXmlFontSize = (data: Object) => optionGet('style.fontSize')(data)
  .map(fontPxToPtMatcher)
  .getOrElseValue('');

/* Object containing functions that take a data object and return a string, for transforming a
   layer's data into an XML value that will eventually get sent to a print partner. */
export const callables: Callables = {
  layflatXmlFont: (data: Object): string => getLayflatXmlFont(data),
  layflatXmlFontSize: (data: Object): string => getLayflatXmlFontSize(data),
  layflatSpinePosition: (data: Object): string => (
    optionGet('style.textAlign')(data)
      .map(match(
        'center', () => 'Center',
        'right', () => 'Bottom',
        'left', () => 'Top',
        match.default, () => 'Bottom',
      ))
      .getOrElseValue('Bottom')
  ),
  layflatXmlContent: (data: Object): string => getStyledLayerContent(data),
  woodboxFoilText: (data: Object): string => getStyledLayerContent(data),
};

/* Takes a data object and a template string and returns a string containing one of:
    - the value in the data path specified by the template string
    - the value returned by a specified callable function when given the data object
    - the string itself, if there is no template placeholder present */
export const tagReplace = (funcs: Callables) => (data: Object): (string => string) => (
  templateStr(
    (_: string, inside: string) => (
      option.fromPredicate(str => funcNameRegex.test(str))(inside)
        .chain((funcName: string) => prop(funcName.slice(1))(funcs))
        .map((f: Object => string) => f(data))
        .alt(optionGet(inside)(data))
        .getOrElseValue('')
    )
  )
);

/* Takes a defaultData object - possible containing a 'tags' object - and a data object, and returns a data
   object containing a copy of the 'tags' object with any template strings inside filled in
   with the corresponding values from the data object. */
export const withUpdatedTags = (defaultData: { tags?: Object }) => (data: Object): Object => (
  prop('tags')(defaultData)
    .map(mapObj(([key, value]: [string, string]) => [key, tagReplace(callables)(data)(value)]))
    .map(tags => ({
      ...data,
      tags,
    }))
    .getOrElseValue(data)
);
