/* @flow */
// Based on https://gist.github.com/hallettj/d371a2246a9f776e4e4b2dbe760ece21

import { option } from 'fp-ts';
import { type Option } from 'fp-ts/lib/Option';
import uuid4 from 'uuid/v4';

import match from '../helpers/match';
import Sema from '../vendor/zeit/async-sema';
import { matchAdt } from './adt';
import { prop, mult2 } from '../helpers/functions';
import { concat, startCase } from '../helpers/strings';
import { getImageURL, getDimensionsFromUrl } from '../helpers/images';
import { isWithinSmall } from '../helpers/breakpoints';
import { sortBy } from '../helpers/arrays';
import { multiply } from '../helpers/numbers';
import { methods } from '../constants/api';
import { fetchJSON, options, endpointFromBaseURL } from '../helpers/api';

// Constants
export const PHOTO_TYPE_FLASH = 'PHOTO_TYPE_FLASH';
export const PHOTO_TYPE_LOCAL = 'PHOTO_TYPE_LOCAL';
export const PHOTO_TYPE_THIRD_PARTY = 'PHOTO_TYPE_THIRD_PARTY';
export const DIRECT_UPLOAD_TYPE = 'direct-upload';

export const FlashImageOrientation = {
  NORMAL: 'Horizontal (normal)',
  ROTATE_90: 'Rotate 90 CW',
  ROTATE_180: 'Rotate 180',
  ROTATE_270: 'Rotate 270 CW',
};

const REACT_APP_FLASH_BASE_URL: string = process.env.REACT_APP_FLASH_BASE_URL || '';

const flashUploadBase = (uploadSource: string) => `${REACT_APP_FLASH_BASE_URL}/image?uploadSource=${uploadSource}`;

// When updating this, also update the type definition of the 'service' property in the ThirdPartyPhoto type definition.
export const THIRD_PARTY_TYPES = [
  'instagram',
  'dropbox',
  'facebook',
  'google',
];

// Semaphore
// Used by Photo's uploader functions to ensure that no more than five image uploads can be in flight at once.
const s = new Sema(5);

// Helpers
// Gets the id from the given props object, or generates a new UUID.
const retrieveId = (props: Object): string => prop('id')(props).getOrElseValue(uuid4());

// Gets the value of 'failed' from the given props object, or the default of false.
const retrieveFailed = (props: Object): boolean => prop('failed')(props).getOrElseValue(false);

// Multiplies the width and height properties of the given object to get a total resolution for a photo.
// Returns None if either or both of the properties are undefined.
const calculateResolution = (dimensions: Option<Object>): Option<number> => (
  dimensions.chain(d => mult2(prop('width')(d))(prop('height')(d)))
);

export type File = {
  lastModified: number,
  lastModifiedDate?: Object,
  name: string,
  preview: string,
  size: number,
  type: string,
};

export type Dimensions = {
  height: number,
  width: number,
};

export type Metadata = {
  resolution: ?string,
  colorProfile: ?string,
  colorSpace: ?string,
  orientation: ?string,
  createdAt: ?string,
  location: ?string,
  format: ?string,
  size: ?string,
  width: ?string,
  height: ?string,
  fileName: ?string,
  rawMetadata: ?any,
};

export type User = {
  flashId: string,
  flashToken: string,
  email: string
};

// Type constructed by functions that match against the `Photo` type.
// This is the type that describes what an photo actually looks like.
// The matcher type does not necessarily have to be exported.
type PhotoMatcher<T> = {
  LegacyPhoto: (_: {
    id: number,
    mediaId: string,
  }) => T,

  FlashPhoto: (_: {
    id: string,
    mediaId: string,
    type: PHOTO_TYPE_FLASH,
    dimensions: Dimensions,
    metadata?: Metadata,
    originId?: string,
    service?: 'instagram' | 'dropbox' | 'facebook' | 'google',
  }) => T,

  LocalPhoto: (_: {
    id: string,
    type: PHOTO_TYPE_LOCAL,
    file: File,
    dimensions: Dimensions,
    failed: boolean,
    errorMessage?: string,
    failedAlbumId?: string,
    mediaId?: string
  }) => T,

  ThirdPartyPhoto: (_: {
    id: string,
    type: PHOTO_TYPE_THIRD_PARTY,
    externalUrl: string,
    dimensions: Dimensions,
    originId?: string,
    service: 'instagram' | 'dropbox' | 'facebook' | 'google',
    failed: boolean,
    errorMessage?: string,
    failedAlbumId?: string,
  }) => T,
};

// The algebraic data type.
// This is the type that we use for photo values and function arguments.
export type Photo = <T>(_: PhotoMatcher<T>) => T; // eslint-disable-line no-undef

// Value constructors for the type `Photo`
export function LegacyPhoto(props: *): Photo {
  return <T>/* </> */(matcher: PhotoMatcher<T>): T => matcher.LegacyPhoto(props);
}

export function FlashPhoto(props: *): Photo {
  const id = retrieveId(props);

  return <T>/* </> */(matcher: PhotoMatcher<T>): T => (
    matcher.FlashPhoto({
      ...props,
      ...(props.imageMetadata ? { metadata: (props.imageMetadata: Metadata | any) } : {}),
      id,
      type: PHOTO_TYPE_FLASH,
    })
  );
}

export function LocalPhoto(props: *): Photo {
  const id = retrieveId(props);
  const failed = retrieveFailed(props);

  return <T>/* </> */(matcher: PhotoMatcher<T>): T => (
    matcher.LocalPhoto({
      ...props,
      id,
      type: PHOTO_TYPE_LOCAL,
      failed,
    })
  );
}

export function ThirdPartyPhoto(props: *): Photo {
  const id = retrieveId(props);
  const failed = retrieveFailed(props);

  return <T>/* </> */(matcher: PhotoMatcher<T>): T => (
    matcher.ThirdPartyPhoto({
      ...props,
      id,
      type: PHOTO_TYPE_THIRD_PARTY,
      failed,
    })
  );
}

// CONSTRUCTORS AND EXTRACTOR
/* Match off of a passed object's 'type' attribute, returning a new Photo constructed from that object.
   Used for wrapping objects from the redux store. */
const construct = (o: Object) => match(
  PHOTO_TYPE_FLASH, () => FlashPhoto(o),
  PHOTO_TYPE_LOCAL, () => LocalPhoto(o),
  PHOTO_TYPE_THIRD_PARTY, () => ThirdPartyPhoto(o),
  match.default, () => LegacyPhoto(o),
)(o.type);

/* Match off of a passed albumPhoto object's various properties, returning an appropriate new Photo. */
const attemptConstructFromAlbumPhoto = async (albumPhoto: Object): Promise<Option<Photo>> => {
  const {
    mediaId,
    imageMetadata,
    cdnUrl,
    source,
    id,
    galleryId,
  } = albumPhoto;

  if (galleryId) {
    // V2 Gallery Image
    return option.fromNullable(
      FlashPhoto({
        id: albumPhoto.id,
        galleryId: albumPhoto.galleryId,
        createdAt: albumPhoto.createdAt,
        mediaId: albumPhoto.id,
        metadata: albumPhoto.metadata,
        autoOriented: !!albumPhoto.autoOriented,
        service: source,
        dimensions: {
          width: parseInt(albumPhoto.metadata.width, 10),
          height: parseInt(albumPhoto.metadata.height, 10),
        },
      })
    );
  } else if (mediaId && mediaId.length > 0) {
    return option.fromNullable(
      FlashPhoto({
        mediaId,
        metadata: imageMetadata,
        dimensions: {
          width: parseInt(imageMetadata.width, 10),
          height: parseInt(imageMetadata.height, 10),
        },
      })
    );
  } else if (THIRD_PARTY_TYPES.includes(source)) {
    const dimensions = await getDimensionsFromUrl(cdnUrl);

    return option.fromNullable(
      ThirdPartyPhoto({
        externalUrl: cdnUrl,
        dimensions,
        originId: id,
        service: source,
      })
    );
  }

  return option.none;
};

const attemptToConstructFlashPhoto = (albumPhoto: Object) => (
  albumPhoto.mediaId && albumPhoto.mediaId !== null ? option.some(FlashPhoto(albumPhoto)) : option.none
);

// Return the contents of the passed Photo, as a raw Javascript object.
const extract: ((_: Photo) => Object) = matchAdt({
  LegacyPhoto: p => p,
  FlashPhoto: (p) => ({ 
    ...p,
    metadata: { orientation: (p.metadata && p.metadata.orientation) ? p.metadata.orientation : 0 },
  }),
  LocalPhoto: p => p,
  ThirdPartyPhoto: p => p,
});

export type Filter = 'Original' | 'Light' | 'BlackAndWhite';

export type PhotoModifications = {
  brightness: number,
  filter: Filter
};

// CONSUMERS
// Get a url string from the Photo, which can be used in img elements for display.
const getDisplayUrl = (
  flashId: string,
  size: string = 'small'
): (_: Photo) => Option<string> => (
  matchAdt({
    LegacyPhoto: (photo) => option.fromNullable(photo).map((photo) => getImageURL(flashId, photo, size)),
    FlashPhoto: (photo) => option.fromNullable(photo).map((photo) => getImageURL(flashId, photo, size)),
    LocalPhoto: ({ file }) => prop('preview')(file),
    ThirdPartyPhoto: ({ externalUrl }) => option.fromNullable(externalUrl),
  })
);

// Get the Photo's ID
const getId: ((_: Photo) => (number | string)) = matchAdt({
  LegacyPhoto: ({ id }) => id,
  FlashPhoto: ({ id }) => id,
  LocalPhoto: ({ id }) => id,
  ThirdPartyPhoto: ({ id }) => id,
});

// Get the Photo's File object
const getFile: ((_: Photo) => Option<File>) = matchAdt({
  LegacyPhoto: () => option.none,
  FlashPhoto: () => option.none,
  LocalPhoto: ({ file }) => option.fromNullable(file),
  ThirdPartyPhoto: () => option.none,
});

// Get the size of the Photo's File object, in bytes.
const getFileSize: ((_: Photo) => Option<any>) = matchAdt({
  LegacyPhoto: () => option.none,
  FlashPhoto: () => option.none,
  /* Have to access 'size' this way because it's an inherited property of File objects,
     and hasOwnProperty returns false for it. */
  LocalPhoto: ({ file }) => option.fromNullable(file).map(f => f.size),
  ThirdPartyPhoto: () => option.none,
});

const getName: ((_: Photo) => Option<string>) = matchAdt({
  LegacyPhoto: () => option.none,
  FlashPhoto: () => option.none,

  /* Have to access 'name' this way because it's an inherited property of File objects,
     and hasOwnProperty returns false for it. */
  LocalPhoto: ({ file }) => option.fromNullable(file).map(f => f.name),

  // Option of 'Facebook Image', 'Instagram Image', etc.
  ThirdPartyPhoto: ({ service }) => option.fromNullable(service).map(concat(' image')).map(startCase),
});

const getService: ((_: Photo) => Option<string>) = matchAdt({
  LegacyPhoto: () => option.none,
  FlashPhoto: ({ service }) => option.fromNullable(service),
  LocalPhoto: () => option.none,
  ThirdPartyPhoto: ({ service }) => option.fromNullable(service),
});

const getOriginId: ((_: Photo) => Option<string>) = matchAdt({
  LegacyPhoto: () => option.none,
  FlashPhoto: ({ originId }) => option.fromNullable(originId),
  LocalPhoto: () => option.none,
  ThirdPartyPhoto: ({ originId }) => option.fromNullable(originId),
});

const getMediaId: ((_: Photo) => Option<string>) = matchAdt({
  LegacyPhoto: ({ mediaId }) => option.fromNullable(mediaId),
  FlashPhoto: ({ mediaId }) => option.fromNullable(mediaId),
  LocalPhoto: ({ mediaId }) => option.fromNullable(mediaId),
  ThirdPartyPhoto: () => option.none,
});

const getErrorMessage: ((_: Photo) => Option<string>) = matchAdt({
  LegacyPhoto: () => option.none,
  FlashPhoto: () => option.none,
  LocalPhoto: ({ errorMessage }) => option.fromNullable(errorMessage),
  ThirdPartyPhoto: ({ errorMessage }) => option.fromNullable(errorMessage),
});

const getFailedAlbumId: ((_: Photo) => Option<string>) = matchAdt({
  LegacyPhoto: () => option.none,
  FlashPhoto: () => option.none,
  LocalPhoto: ({ failedAlbumId }) => option.fromNullable(failedAlbumId),
  ThirdPartyPhoto: ({ failedAlbumId }) => option.fromNullable(failedAlbumId),
});

const getAlbumId: ((_: Photo) => Option<string>) = matchAdt({
  LegacyPhoto: () => null,
  FlashPhoto: ({ album }) => album,
  LocalPhoto: null,
  ThirdPartyPhoto: null,
});

// Get an upload function, which takes an optional albumId argument and uploads the photo to s3.
const getUploader = (user: User) => (projectId: string): ((_: Photo) => (albumId: ?string) => Promise<*>) =>
  matchAdt({
    LegacyPhoto: () => () => Promise.reject(new Error('Photo already uploaded.')),
    FlashPhoto: () => () => Promise.reject(new Error('Photo already uploaded.')),

    LocalPhoto: ({ file }) => async (albumId: ?string, generatedPhotoId: ?string) => {
      const body = new FormData();

      body.append('file', file);
      body.append('user', user.flashId);
      body.append('autoOrient', isWithinSmall());
      body.append('thumbnailSizes', [300, 600, 1200]);

      if (albumId) {
        body.append('album', albumId);
      }

      try {
        // Acquire lock
        await s.v();

        const response = await fetch(flashUploadBase(DIRECT_UPLOAD_TYPE), {
          mode: 'cors',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.flashToken}`,
          },
          body,
        });

        // Release lock
        s.p();

        return response;
      } catch (e) {
        // Release lock if an error occurs during upload
        s.p();

        throw new Error(e);
      }
    },

    ThirdPartyPhoto: ({ externalUrl, service }) => (
      async (albumId: ?string) => {
        const body = new FormData();

        body.append('user', user.flashId);
        body.append('autoOrient', isWithinSmall());
        body.append('url', externalUrl);
        body.append('thirdPartyImage', 'true');

        if (albumId) {
          body.append('album', albumId);
        }

        // Acquire lock
        await s.v();

        try {
          const response = await fetch(flashUploadBase(service), {
            mode: 'cors',
            method: 'POST',
            headers: {
              Authorization: `Bearer ${user.flashToken}`,
            },
            body,
          });

          // Release lock
          s.p();

          return response;
        } catch (e) {
          // Release lock
          s.p();

          throw new Error(e);
        }
      }
    ),
  });

// Get the Photo's Dimensions object.
const getDimensions: ((_: Photo) => Option<Dimensions>) = matchAdt({
  LegacyPhoto: () => option.none,
  FlashPhoto: ({ dimensions }) => option.fromNullable(dimensions),
  LocalPhoto: ({ dimensions }) => option.fromNullable(dimensions),
  ThirdPartyPhoto: ({ dimensions }) => option.fromNullable(dimensions),
});

// Optionally calculate the resolution of the Photo, based off of its dimensions.
const getResolution: ((_: Photo) => Option<number>) = matchAdt({
  LegacyPhoto: () => option.none,
  FlashPhoto: ({ dimensions }) => calculateResolution(option.fromNullable(dimensions)),
  LocalPhoto: ({ dimensions }) => calculateResolution(option.fromNullable(dimensions)),
  ThirdPartyPhoto: ({ dimensions }) => calculateResolution(option.fromNullable(dimensions)),
});

/* Takes an errorMessage and a Photo and returns a copy of that Photo that includes
   the given error message and has the 'failed' flag set. */
const setToFailed = (errorMessage: string, failedAlbumId: string): (_: Photo) => Photo => matchAdt({
  LegacyPhoto: p => LegacyPhoto(p),
  FlashPhoto: p => FlashPhoto(p),

  LocalPhoto: p => LocalPhoto({
    ...p,
    failed: true,
    errorMessage,
    failedAlbumId,
  }),

  ThirdPartyPhoto: p => ThirdPartyPhoto({
    ...p,
    failed: true,
    errorMessage,
    failedAlbumId,
  }),
});

// Takes a Photo and returns a copy of that Photo that has the 'failed' flag set to false, and the errorMessage removed.
const unsetFailed: ((_: Photo) => Photo) = matchAdt({
  LegacyPhoto: p => LegacyPhoto(p),
  FlashPhoto: p => FlashPhoto(p),

  LocalPhoto: p => LocalPhoto({
    ...p,
    failed: false,
    errorMessage: undefined,
  }),

  ThirdPartyPhoto: p => ThirdPartyPhoto({
    ...p,
    failed: false,
    errorMessage: undefined,
  }),
});

// Get the Photo's Metadata object.
const getMetadata: ((_: Photo) => Option<Metadata>) = matchAdt({
  LegacyPhoto: () => option.none,
  FlashPhoto: ({ metadata }) => option.fromNullable(metadata),
  LocalPhoto: () => option.none,
  ThirdPartyPhoto: () => option.none,
});

// Get key from Photo's Metadata Object.
export const getMetadataKey: (key: string) => (photo: Photo) => Option<string> = key => photo => getMetadata(photo).chain(prop(key));

// SUB-TYPE INDICATORS
const isLegacy: ((_: Photo) => boolean) = matchAdt({
  LegacyPhoto: () => true,
  FlashPhoto: () => false,
  LocalPhoto: () => false,
  ThirdPartyPhoto: () => false,
});

const isFlash: ((_: Photo) => boolean) = matchAdt({
  LegacyPhoto: () => false,
  FlashPhoto: () => true,
  LocalPhoto: () => false,
  ThirdPartyPhoto: () => false,
});

const isLocal: ((_: Photo) => boolean) = matchAdt({
  LegacyPhoto: () => false,
  FlashPhoto: () => false,
  LocalPhoto: () => true,
  ThirdPartyPhoto: () => false,
});

const isThirdParty: ((_: Photo) => boolean) = matchAdt({
  LegacyPhoto: () => false,
  FlashPhoto: () => false,
  LocalPhoto: () => false,
  ThirdPartyPhoto: () => true,
});

const isUploadable: ((_: Photo) => boolean) = matchAdt({
  LegacyPhoto: () => false,
  FlashPhoto: () => false,
  LocalPhoto: () => true,
  ThirdPartyPhoto: () => true,
});

// Boolean for whether or not the given Photo can be saved to the project service.
const isSavable: ((_: Photo) => boolean) = matchAdt({
  LegacyPhoto: p => prop('mediaId')(p).isSome(),
  FlashPhoto: p => prop('mediaId')(p).isSome(),
  LocalPhoto: () => false,
  ThirdPartyPhoto: () => false,
});

const isFailed: ((_: Photo) => boolean) = matchAdt({
  LegacyPhoto: () => false,
  FlashPhoto: () => false,
  LocalPhoto: ({ failed }) => failed === true,
  ThirdPartyPhoto: ({ failed }) => failed === true,
});

export const CREATED_AT_ASC = 'CREATED_AT_ASC';
export const CREATED_AT_DESC = 'CREATED_AT_DESC';

export const DATE_TAKEN_ASC = 'DATE_TAKEN_ASC';
export const DATE_TAKEN_DESC = 'DATE_TAKEN_DESC';

export const FILE_NAME_ASC = 'FILE_NAME_ASC';

export const PHOTO_SORT_OPERATIONS = [
  CREATED_AT_ASC,
  CREATED_AT_DESC,
  DATE_TAKEN_ASC,
  DATE_TAKEN_DESC,
  FILE_NAME_ASC,
];

export const PHOTO_SORT_OPERATIONS_SELECT_OPTIONS = [
  { value: CREATED_AT_DESC, label: 'Sort by date uploaded' },
  { value: CREATED_AT_ASC, label: 'Date uploaded, oldest first' },
  { value: DATE_TAKEN_DESC, label: 'Sort by date taken' },
  { value: DATE_TAKEN_ASC, label: 'Date taken, oldest first' },
  { value: FILE_NAME_ASC, label: 'Sort by file name' },
];

export type PhotoSortOp = 'CREATED_AT_ASC' |
  'CREATED_AT_DESC' |
  'DATE_TAKEN_ASC' |
  'DATE_TAKEN_DESC' |
  'FILE_NAME_ASC';

// Given an operation identifier, returns the sort operation function
const photoSortOpMatcher = match(
  // sort oldest to newest
  DATE_TAKEN_ASC, () =>
    (x: Object): number => attemptToConstructFlashPhoto(x)
    .chain(getMetadataKey('dateTaken'))
    .map(y => parseInt(y, 10))
    // photos without dateTaken should be at the end by defaulting to 9s
    .getOrElseValue(9999999999999999999),

  DATE_TAKEN_DESC, () => (x: Object): number => attemptToConstructFlashPhoto(x)
  .chain(getMetadataKey('dateTaken'))
  .map(y => parseInt(y, 10))
  .map(multiply(-1))
  // photos without dateTaken should be at the end by defaulting to 0
  .getOrElseValue(0),

  // sort newest created to oldest
  CREATED_AT_ASC, () => (x: Object): number => prop('createdAt')(x)
  .map(y => parseInt(y, 10))
  .getOrElseValue(9999999999999999999),

  // sort old created to newest
  CREATED_AT_DESC, () => (x: Object): number => prop('createdAt')(x)
  .map(y => parseInt(y, 10))
  .map(multiply(-1))
  .getOrElseValue(0),

  FILE_NAME_ASC, () => (x: Object): string => attemptToConstructFlashPhoto(x)
  .chain(getMetadataKey('fileName'))
  .getOrElseValue(''),

  // default to order provided by Flash should we ever end up in a state that the provided sort doesn't exist
  match.default, () => (x: Object): number => prop('createdAt')(x)
  .map(y => parseInt(y, 10))
  .getOrElseValue(9999999999999999999),
);

// Given a sort function that takes an element operation and a collection, returns array of sorted user photos
const sortPhotosByFn = (sortFn: Function) => (sortOp?: PhotoSortOp) => (
  option.fromNullable(sortOp)
  .map(photoSortOpMatcher)
  .map(sortFn)
  .getOrElseValue(x => x)
);

export const sortPhotosBy = sortPhotosByFn(sortBy);

const { GET_SIGNED_PHOTO_UPLOAD } = methods;
const endpoint = endpointFromBaseURL(process.env.REACT_APP_FLASH_BASE_URL);

const uploadPhotoToS3SignedUrl = (
  albumId: string,
  flashId: string,
  flashToken: string,
  objectKey: string,
  file: File,
  uploadSource: string,
) =>
  // eslint-disable-next-line no-use-before-define
  getSignedUploadUrl(flashId)(flashToken)(albumId, objectKey, uploadSource)
  .then(prop('url'))
  .then((signedUrlO) => {
    const signedUrl = signedUrlO.getOrElseValue(false);

    if (signedUrl) {
      try {
        return fetch(signedUrl, {
          method: 'PUT',
          mode: 'cors',
          body: file,
        });
      } catch (err) {
        console.error('Something happened when uploading to signed URL.');
        console.error(err);
      }
    } else {
      // handle GET /image/signed-url error
    }
  });

const getSignedUploadUrl = (flashId: string) => (flashToken: string) => (
  albumId: string,
  objectKey: string,
  uploadSource: string,
) =>
  fetchJSON(
    endpoint(GET_SIGNED_PHOTO_UPLOAD)(objectKey, flashId, albumId, uploadSource),
    options(GET_SIGNED_PHOTO_UPLOAD)(flashToken)
  );


// EXPORT
export default {
  construct,
  attemptConstructFromAlbumPhoto,
  extract,

  getDisplayUrl,
  getId,
  getFile,
  getFileSize,
  getUploader,
  getDimensions,
  getResolution,
  getMetadata,
  getName,
  getService,
  getOriginId,
  getMediaId,
  getErrorMessage,
  getFailedAlbumId,
  getAlbumId,

  setToFailed,

  isLegacy,
  isFlash,
  isLocal,
  isThirdParty,
  isUploadable,
  isFailed,
  isSavable,
  unsetFailed,
};