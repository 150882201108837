import React from 'react';
import PropTypes from 'prop-types';
import PdfPreviewModal from './PdfPreviewModal'

class PdfPreviewLink extends React.Component {
  static propTypes = {
    renderButton: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.toggleVisibility = this.toggleVisibility.bind(this);

    this.state = {
      isPdfPreviewVisible: false,
    }
  }

  toggleVisibility() {
    this.setState({isPdfPreviewVisible: !this.state.isPdfPreviewVisible});
  }

  render() {
    const { isPdfPreviewVisible } = this.state;
    const pdfPreviewToggle = this.toggleVisibility;
    return ([
        this.props.renderButton({pdfPreviewToggle}),
        isPdfPreviewVisible ? <PdfPreviewModal key="preview_modal" onClose={this.props.onClose} toggleVisibility={this.toggleVisibility} />: null,
    ])
  }
}

export default PdfPreviewLink;