// @flow
import {
  type UndoableActions,
  UNDO_USER_ACTION,
  REDO_USER_ACTION,
  ADD_HISTORY_ITEM,
  UNDOABLE_USER_ACTION_UPDATE,
  UNDOABLE_REMOVE_HISTORY_ITEM,
  UNDOABLE_USER_ACTION_ADD_PATCHES_TO_APPLY,
  REMOVE_UNDOABLE_ITEM,
} from './constants';
import { type PatchOperation, applyPatch } from '../../helpers/store';
import { splice } from '../../helpers/arrays';
import { findFirst } from 'fp-ts/lib/Array';
import { prop } from '../../helpers/functions';

type HistoryItem = {
  userAction: UndoableActions,
  id: string,
  patch: PatchOperation,
  changePatch: PatchOperation,
  patchesToApply: Array<string>, // Patches to apply when async effect resolves
  pageId: ?string, // page id that the results of this action affects
}

type HistoryState = {
  past: Array<HistoryItem>,
  future: Array<HistoryItem>,
}

const defaultHistoryState = {
  past: [],
  future: [],
};

export const getNextUndoableItem = (items: UndoableActions) => findFirst(x => x.patch.length > 0)(items);

export const rootHistoryReducer = (appReducer: Function) => (state: Object, action: Object) => {
  const { type } = action;
  switch (type) {
    case UNDO_USER_ACTION: {
      const nextPatch = findFirst(x => x.patch.length)(state.history.past).chain(prop('patch')).getOrElseValue([]);
      const newState = applyPatch(nextPatch)(state);
      return appReducer({
        ...newState,
        // @todo
        // If we don't keep the original state's template here, a bug
        // arises where layouts disappear/change on undo/redo. This is
        // caused by a mutation somewhere but I couldn't track it down.
        template: state.template,
      }, action);
    }
    case REDO_USER_ACTION: {
      const nextPatch = findFirst(x => x.patch.length)(state.history.future).chain(prop('patch')).getOrElseValue([]);
      const newState = applyPatch(nextPatch)(state);
      return appReducer({
        ...newState,
        // See @todo above
        template: state.template,
      }, action);
    }
    default:
      return appReducer(state, action);
  }
};

const historyReducer = (state: HistoryState = defaultHistoryState, action: Object) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_HISTORY_ITEM: {
      const { item } = payload;
      return {
        future: (
          item.userAction === UNDO_USER_ACTION
            ? [item, ...state.future]
            : item.userAction !== REDO_USER_ACTION ? [] : state.future
        ),
        past: item.userAction !== UNDO_USER_ACTION ? [item, ...state.past] : state.past,
      };
    }

    case UNDOABLE_USER_ACTION_ADD_PATCHES_TO_APPLY: {
      const { patchesToApply } = payload;
      return {
        ...state,
        past: [
          ...state.past.map(x => ({
            ...x,
            ...(
              x.patch.length === 0
                ? { patchesToApply: [
                  ...x.patchesToApply,
                  ...patchesToApply.filter(y => y !== x.id),
                ] }
                : {}
            ),
          })),
        ],
      };
    }

    case UNDOABLE_USER_ACTION_UPDATE: {
      const { undoableId, patch, changes } = payload;
      return {
        ...state,
        past: [
          ...state.past.map((x) => {
            if (x.id === undoableId) {
              return {
                ...x,
                patch,
                changePatch: changes,
              };
            }
            return x;
          })],
      };
    }

    case UNDOABLE_REMOVE_HISTORY_ITEM: {
      const { undoableId } = payload;
      return {
        ...state,
        past: [
          ...state.past.filter(x => x.id !== undoableId),
        ],
      };
    }

    case REMOVE_UNDOABLE_ITEM: {
      const { undoableId } = payload;
      return {
        ...state,
        past: [...state.past.filter(x => x.id !== undoableId)],
      };
    }

    case UNDO_USER_ACTION:
      // the patch application will be handled by root reducer
      return {
        ...state,
        past: splice(state.past, 0, 1),
      };

    case REDO_USER_ACTION:
      // the patch application will be handled by root reducer
      return {
        ...state,
        future: splice(state.future, 0, 1),
      };
    default:
      return state;
  }
};

export default historyReducer;
