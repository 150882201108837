export const UI_SET_CURRENT_PAGE = 'UI_SET_CURRENT_PAGE';
export const UI_SET_TOOL_VISIBILITY = 'UI_SET_TOOL_VISIBILITY';
export const UI_UNSET_TOOL_VISIBILITY = 'UI_UNSET_TOOL_VISIBILITY';
export const UI_SET_CROP_REGION_VISIBILITY = 'UI_SET_CROP_REGION_VISIBILITY';
export const UI_UNSET_CROP_REGION_VISIBILITY =
  'UI_UNSET_CROP_REGION_VISIBILITY';
export const UI_TOGGLE_KABOB_MENU_ON = 'UI_TOGGLE_KABOB_MENU_ON';
export const UI_TOGGLE_KABOB_MENU_OFF = 'UI_TOGGLE_KABOB_MENU_OFF';
export const UI_SET_CURRENT_ALBUM = 'UI_SET_CURRENT_ALBUM';
export const UI_UNSET_CURRENT_ALBUM = 'UI_UNSET_CURRENT_ALBUM';
export const UI_PRODUCT_DPI_THRESHOLD = 180;
export const UI_SET_PREVIEW_VISIBILITY = 'UI_SET_PREVIEW_VISIBILITY';
export const UI_UNSET_PREVIEW_VISIBILITY = 'UI_UNSET_PREVIEW_VISIBILITY';
export const UI_SET_UPLOAD_PHOTOS_VISIBILITY =
  'UI_SET_UPLOAD_PHOTOS_VISIBILITY';
export const UI_UNSET_UPLOAD_PHOTOS_VISIBILITY =
  'UI_UNSET_UPLOAD_PHOTOS_VISIBILITY';
export const UI_SET_PROMPT_USER_TO_LOGIN_MODAL =
  'UI_SET_PROMPT_USER_TO_LOGIN_MODAL';
export const UI_INSTAGRAM_ALBUM = 'UI_INSTAGRAM_ALBUM';
export const UI_SET_SIDEBAR_EXPANDED = 'UI_SET_SIDEBAR_EXPANDED';
export const UI_SET_SIDEBAR_LOCKED = 'UI_SET_SIDEBAR_LOCKED';
export const UI_SET_TEXT_EDITOR_VISIBILITY = 'UI_SET_TEXT_EDITOR_VISIBILITY';
export const UI_RESET_CURRENT_GALLERY_VALUES = 'UI_RESET_CURRENT_GALLERY_VALUES';
export const UI_UNSET_TEXT_EDITOR_VISIBILITY =
  'UI_UNSET_TEXT_EDITOR_VISIBILITY';
export const UI_CONFIRMATION_SET_PAGE_REMOVAL =
  'UI_CONFIRMATION_SET_PAGE_REMOVAL';
export const UI_CONFIRMATION_SET_USER_PAGE_REMOVAL =
  'UI_CONFIRMATION_SET_USER_PAGE_REMOVAL';
export const UI_DROPBOX_ALBUM = 'UI_DROPBOX_ALBUM';
export const UI_LOCAL_GALLERY_HAS_MORE_PHOTOS = 'UI_LOCAL_GALLERY_HAS_MORE_PHOTOS';
export const UI_SET_GALLERY_PAGE_NUMBER = 'UI_SET_GALLERY_PAGE_NUMBER'; 
export const UI_CONFIRMATION_SET_CROP_RESET = 'UI_CONFIRMATION_SET_CROP_RESET';
export const UI_CONFIRMATION_UNSET = 'UI_CONFIRMATION_UNSET';
export const UI_SHOW_DROP_TARGETS = 'UI_SHOW_DROP_TARGETS';
export const UI_SHOW_DROP_TARGETS_HIGHLIGHT = 'UI_SHOW_DROP_TARGETS_HIGHLIGHT';
export const UI_MODAL_PHOTO_EDITOR = 'UI_MODAL_PHOTO_EDITOR';
export const UI_FONTS_LOADED = 'UI_FONTS_LOADED';
export const UI_SET_DRAWER_VISIBILITY = 'UI_SET_DRAWER_VISIBILITY';
export const UI_SET_DRAWER_MODE = 'UI_SET_DRAWER_MODE';
export const UI_SET_FULL_GALLERY_VIEW = 'UI_SET_FULL_GALLERY_VIEW';
export const UI_UNSET_FULL_GALLERY_VIEW = 'UI_UNSET_FULL_GALLERY_VIEW';
export const UI_SET_TOOLTIP_POSITION = 'UI_SET_TOOLTIP_POSITION';
export const UI_SET_TOOLTIP_DATA = 'UI_SET_TOOLTIP_DATA';
export const UI_SET_TOOLTIP_TYPE = 'UI_SET_TOOLTIP_TYPE';
export const UI_SET_TOOLTIP_STATE = 'UI_SET_TOOLTIP_STATE';
export const UI_TOOLTIP_OPENED = 'UI_TOOLTIP_OPENED';
export const UI_TOOLTIP_CLOSED = 'UI_TOOLTIP_CLOSED';
export const UI_OPEN_TOOLTIP_WITH_DATA = ' UI_OPEN_TOOLTIP_WITH_DATA';
export const UI_TOOLTIP_DESIGN_GROUP = 'UI_TOOLTIP_DESIGN_GROUP';
export const UI_TOOLTIP_IMAGE_PREVIEW = 'UI_TOOLTIP_IMAGE_PREVIEW';
export const UI_SET_MANAGE_PAGES_VISIBILITY = 'UI_SET_MANAGE_PAGES_VISIBILITY';
export const UI_SET_DRAWERGRID_SCROLL_CACHE = 'UI_SET_DRAWERGRID_SCROLL_CACHE';
export const UI_SET_PARTIAL_SELECTION = 'UI_SET_PARTIAL_SELECTION';
export const UI_SET_SURFACE_CENTER_POINT = 'UI_SET_SURFACE_CENTER_POINT';
export const UI_PAGE_SELECTION_SIDE_LEFT = 'left';
export const UI_PAGE_SELECTION_SIDE_RIGHT = 'right';
export const UI_SET_PHOTO_SORT_OPERATION = 'UI_SET_PHOTO_SORT_OPERATION';
export const UI_UNSET_PHOTO_SORT_OPERATION = 'UI_UNSET_PHOTO_SORT_OPERATION';
export const UI_SET_PREVIOUS_PAGE = 'UI_SET_PREVIOUS_PAGE';
export const UI_SET_SORT_BY = 'UI_SET_SORT_BY';
export const UI_SET_SELECTED_LAYER = 'UI_SET_SELECTED_LAYER';
export const UI_SET_AUTOFILL_IMG_COUNT = 'UI_SET_AUTOFILL_IMG_COUNT';
export const UI_INC_AUTOFILL_IMG_COUNT = 'UI_INC_AUTOFILL_IMG_COUNT';
export const UI_SET_SCREEN_SIZE = 'UI_SET_SCREEN_SIZE';
export const UI_SET_ZENDESK_LOADED = 'UI_SET_ZENDESK_LOADED';
export const UI_SET_SKIP_CONFIRM_FOR_STATUS = 'UI_SET_SKIP_CONFIRM_FOR_STATUS';
export const UI_TOGGLE_SKIP_CONFIRM_FOR_STATUS =
  'UI_TOGGLE_SKIP_CONFIRM_FOR_STATUS';
export const UI_TOGGLE_HIDE_USED_IMAGES = 'UI_TOGGLE_SHOW_USED_IMAGES';
export const UI_SET_USER_INPUT_DATA = 'UI_SET_USER_INPUT_DATA';
export const UI_OPEN_SIDEBAR_TUT = 'UI_OPEN_SIDEBAR_TUT';
export const UI_CLOSE_SIDEBAR_TUT = 'UI_CLOSE_SIDEBAR_TUT';
export const SET_APPLY_TO_ALL_TOGGLE = 'SET_APPLY_TO_ALL_TOGGLE';
export const SET_LOADING_APPLY_TO_ALL = 'SET_LOADING_APPLY_TO_ALL';
export const UI_LAYOUT_DROPDOWN = 'UI_LAYOUT_DROPDOWN';
//TODO^^ might want to change this name

// Drawer Modes
export const UI_DRAWER_MODE_DEFAULT = 'DEFAULT';
export const UI_DRAWER_MODE_EXPANDED = 'EXPANDED';
export const UI_DRAWER_MODE_SHRUNKEN = 'SHRUNKEN';

// LAYOUT FILTERING
export const LAYOUT_FILTER_DEFAULT_OPTION = 'LAYOUT_FILTER_DEFAULT_OPTION';

// Update Project / Add to cart tooltip
export const UI_SET_UPDATE_PROJECT_TOOLTIP = 'UI_SET_UPDATE_PROJECT_TOOLTIP';

export const EDITOR_SCREENREADER_TEXT = 'Here’s a time-saving tip...Sign up for Design Services to have one of our experts create a Layflat Photo Album for you. Contact designservices@artifactuprising.com to begin';