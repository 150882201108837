import {
  SET_AUTH_ERRORS,
  TOGGLE_AUTH_PROCESSING,
  VIEW_LOGIN_AFTER_PASSWORD_RESET,
  VIEW_LOGIN_SUCCESS,
  VIEW_REGISTER_SUCCESS,
  SET_AUTH_STATE,
  VIEW_FORGOT,
  VIEW_LOGIN,
  VIEW_REGISTER,
} from './constants';
import {
  requestMagentoCustomer,
  requestMagentoLogin,
  requestMagentoRegister,
  requestPasswordReset,
} from '../../services/magento';

import { createProjectAndUploadPhotos } from '../project/actions';
import { sendSuccessfulRegisterNotification, dismissNotification } from '../notifications/actions';
import { getAlbums } from '../../store/userAlbums/actions';
import { setUserData } from '../user/actions';
import validator from '../../components/User/Validator';
import { validateAndAddToCart } from '../product/actions';
import { fromNullable } from 'fp-ts/lib/Option';
import { prop } from '../../helpers/functions';
import { setEnvelopeAddressingData, getDefaultAddress } from '../envelopeAddressing/actions';
import {
  RETURN_ADDRESS_STATE_KEY,
  addressingValuesWithReturnAddress,
} from '../envelopeAddressing/constants';
import {
  envelopeAddressingValueSelector,
  returnAddressIdSelector,
} from '../envelopeAddressing/selectors';
import { itlyUserLoginEvent, itlyUserSignUpEvent } from '../analytics/actions';
import { fetchGalleries } from '../v2/galleries/actions';

/**
 * setAuthState set's the state of the authentication form.
 * @param {*} authState
 * @param {*} addToCartOnSuccess
 */
export function setAuthState(authState, addToCartOnSuccess = null) {
  return {
    type: SET_AUTH_STATE,
    payload: {
      authState,
      ...(addToCartOnSuccess !== null ? { addToCartOnSuccess } : {}),
    },
  };
}

export function showRegisterForm() {
  return dispatch => dispatch(setAuthState(VIEW_REGISTER));
}

export function showLoginForm(addToCartOnSuccess = false) {
  return dispatch => dispatch(setAuthState(VIEW_LOGIN, addToCartOnSuccess));
}


export function showForgotPasswordForm() {
  return dispatch =>
    dispatch(setAuthState(VIEW_FORGOT));
}

export function hideLoginView(addToCartOnSuccess = null) {
  return (dispatch) => {
    dispatch(setAuthState(null, addToCartOnSuccess));
  };
}

export function toggleAuthProcessing(processing = true) {
  return {
    type: TOGGLE_AUTH_PROCESSING,
    payload: {
      processing,
    },
  };
}

export function setAuthErrors(errors) {
  return (dispatch) => {
    dispatch(toggleAuthProcessing(false));
    dispatch({
      type: SET_AUTH_ERRORS,
      payload: {
        errors,
      },
    });
  };
}

export const hideLoginViewAndMaybeAddToCart = () => (dispatch, getState) => {
  const hideLogin = (_dispatch, state) => () => {
    if (state.userAuthentication.addToCartOnSuccess === true) {
      _dispatch(validateAndAddToCart());
    }
    _dispatch(hideLoginView());
  };

  setTimeout(hideLogin(dispatch, getState()), 1000);
};

const dismissLoginNotification = () => (dispatch, getState) => {
  const { notifications } = getState();
  if (notifications) {
    notifications.forEach((notification) => {
      if (notification.key === 'PROMPT_ANON_USER_LOGIN') {
        dispatch(dismissNotification('PROMPT_ANON_USER_LOGIN'));
      }
    });
  }
};

export function loginToMagento(email, password) {
  return (dispatch, getState) => {
    dispatch(toggleAuthProcessing());
    validator.validate('userLoginSchema', { email, password });
    if (validator.errors !== null) {
      dispatch(setAuthErrors(validator.errors));
      return;
    }

    requestMagentoLogin(email, password).then((resp) => {
      dispatch(setAuthState(VIEW_LOGIN_SUCCESS));
      dispatch(setAuthErrors(null));
      return resp;
    }).then((loginResponse) => {
      requestMagentoCustomer().then((resp) => {
        dispatch(itlyUserLoginEvent(resp))
        dispatch(setUserData(resp));
        const envelopeAddressingValue = envelopeAddressingValueSelector(getState()).getOrElseValue();
        const returnAddressId = returnAddressIdSelector(getState());

        // We should not set a returnAddress automatically if the user has explicitly chosen "None"
        if (addressingValuesWithReturnAddress.includes(envelopeAddressingValue) && returnAddressId === -1) {
          dispatch(
            setEnvelopeAddressingData(
              RETURN_ADDRESS_STATE_KEY
            )(
              getDefaultAddress(resp.addresses, resp.default_billing)
            )
          );
        }
        dispatch(createProjectAndUploadPhotos());
        dispatch(fetchGalleries());
        dispatch(dismissLoginNotification());
        dispatch(hideLoginViewAndMaybeAddToCart());
      });
    }).catch(() => {
      const errors = [{ message: 'Incorrect username or password' }];
      dispatch(setAuthErrors(errors));
    });
  };
}

export function sendPasswordResetEmail(email) {
  return (dispatch) => {
    dispatch(toggleAuthProcessing());
    validator.validate('email', { email });
    if (validator.errors !== null) {
      dispatch(setAuthErrors(validator.errors));
      return;
    }

    requestPasswordReset(email).then(() => {
      dispatch(setAuthState(VIEW_LOGIN_AFTER_PASSWORD_RESET));
      dispatch(setAuthErrors(null));
    }).catch(() => {
      dispatch(setAuthState(VIEW_LOGIN_AFTER_PASSWORD_RESET));
      dispatch(setAuthErrors(null));
    });
  };
}

export function registerMagentoUser(userData) {
  return (dispatch) => {
    dispatch(toggleAuthProcessing());
    validator.validate('userRegisterSchema', userData);
    if (userData.password !== userData.confirmPassword) {
      const errors = [{ message: 'The passwords you submitted do not match.' }];
      dispatch(setAuthErrors(errors));
      return;
    }
    if (validator.errors !== null) {
      dispatch(setAuthErrors(validator.errors));
      return;
    }

    requestMagentoRegister(userData).then(() => {
      requestMagentoLogin(userData.email, userData.password).then((loginResp) => {
        dispatch(setAuthState(VIEW_REGISTER_SUCCESS));
        dispatch(setAuthErrors(null));
        return loginResp;
      }).then(() => {
        requestMagentoCustomer().then((resp) => {
          dispatch(itlyUserSignUpEvent(resp))
          dispatch({ ...setUserData(resp)(dispatch), registered: true });
          dispatch(createProjectAndUploadPhotos());
          dispatch(sendSuccessfulRegisterNotification());
          dispatch(dismissLoginNotification());
          dispatch(hideLoginViewAndMaybeAddToCart());
        });
      });
    }).catch((e: Error) => {
      const errors = fromNullable(e)
        .chain(prop('message'))
        .map(err => [{ message: err }])
        .getOrElseValue(
          [{ message: 'Please make sure your password is at least 7 characters long and includes at least one letter and one number.' }]
        );

      dispatch(setAuthErrors(errors));
    });
  };
}

