// @flow

import React, { type Node as ReactNode } from 'react';
import memoizeOne from 'memoize-one';
import isEqual from 'lodash.isequal';

import { classNames } from '../../../helpers/strings';
import PhotoItem from './AlbumViewer/PhotoItem';
import { prop } from '../../../helpers/functions';

const noop = (): void => {};

const getKey = (albumPhoto: { id?: string, mediaId?: string, userPhotoId?: string }): string =>
  [
    prop('id')(albumPhoto).getOrElseValue(''),
    prop('mediaId')(albumPhoto).getOrElseValue(''),
    prop('userPhotoId')(albumPhoto).getOrElseValue(''),
  ].join('_');

type PhotoElementsProps = {
  photos: Array<Object>,
  selectPhoto: Function,
  flashId: string,
  renderPhotoActions: Function,
  imgClass: string,
  inGalleryView?: boolean,
  setTooltip: ?Function,
  hideTooltip: ?Function,
  usedPhotos: Array<Object>,
};

// Custom equality predicate for PhotoElements memoization.
const comparePhotoElementsProps = (a: PhotoElementsProps, b: PhotoElementsProps): boolean =>
  isEqual(a.photos, b.photos) && a.flashId === b.flashId && isEqual(a.usedPhotos, b.usedPhotos);

const photoInUse = (photo, usedPhotos) => {
  if (!photo.id && !photo.mediaId) {
    return !usedPhotos.map((u) => u.id).includes(photo.userPhotoId);
  }
  return !usedPhotos.map((u) => u.originId || u.mediaId).includes(photo.id || photo.mediaId);
};

export const PhotoElements = memoizeOne(
  ({
    photos,
    selectPhoto,
    flashId,
    imgClass = 'AlbumsBrowser__item--photo',
    inGalleryView = true,
    setTooltip,
    hideTooltip,
    usedPhotos,
  }: PhotoElementsProps): Array<ReactNode> =>
    photos
      .filter((p) =>
        !p.id && !p.mediaId
          ? !usedPhotos.map((u) => u.id).includes(p.userPhotoId)
          : !usedPhotos.map((u) => u.originId || u.mediaId).includes(p.id || p.mediaId)
      )
      .map((albumPhoto: Object) => (
        <div
          key={getKey(albumPhoto)}
          className={classNames(
            'AlbumsBrowser__item',
            imgClass,
            !albumPhoto.isLoaded ? 'AlbumsBrowser__item--placeholder' : 'AlbumsBrowser__item--loaded' // TODO
          )}
        >
          <PhotoItem
            albumPhoto={albumPhoto}
            selectPhoto={selectPhoto}
            flashId={flashId}
            showIcon
            connectDrag={inGalleryView}
            onMouseOver={inGalleryView && setTooltip ? setTooltip({ photo: albumPhoto, inGalleryView }) : noop}
            onMouseLeave={inGalleryView && hideTooltip ? hideTooltip : noop}
          />
        </div>
      )),
  comparePhotoElementsProps
);
