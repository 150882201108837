import get from 'lodash.get';
import { fromNullable } from 'fp-ts/lib/Option';
import type { EditorState } from 'draft-js';
import { prop } from '../functions';
import { any } from '../conditionals';
import type { TextRestrictions } from '../../types/style';
import type { Layer } from '../../types/templates';

import { arrayNotEmptyOr } from '../arrays';

// restrictionTriggered :: Object -> String -> Any -> Boolean
export const restrictionTriggered = restriction => attributeName => attributeValue => (
  restriction.if.attribute === attributeName &&
  restriction.if.equals === attributeValue
);

// restrictionsTriggeredByChange :: [Object] -> String -> Any -> [Object]
export const restrictionsTriggeredByChange = restrictions => attributeName => attributeValue => (
  restrictions && restrictions.length > 0 ? (
    restrictions.filter(restriction => restrictionTriggered(restriction)(attributeName)(attributeValue))
  ) : (
    []
  )
);

// restrictedOptionsForAttribute :: [Object] -> String -> [String]
export const restrictedOptionsForAttribute = restrictions => attributeName => (
  get(restrictions.find(restriction => restriction.disable.optionsFor === attributeName), 'disable.options', [])
);

// getAttributeOptions :: Object -> [Object]
export const getAttributeOptions = attribute => get(attribute, 'options', []);

// restrictAttributeOptions :: Object -> [Any] -> Object
export const restrictAttributeOptions = attribute => restrictedOptions => (
  (!attribute.passThrough && restrictedOptions.length > 0) ? (
    attribute.options.map(option => ({
      ...option,
      disabled: restrictedOptions.includes(option.value),
    }))
  ) : (
    attribute.options || []
  )
);

// getAllowedOptions :: [Object] -> [Any] -> [Object]
export const getAllowedOptions = options => restrictedOptions => {
  return options.filter(option => {
    return !restrictedOptions.find(restriction => restriction?.disable?.options?.includes(option.value));
    });
};

// conformAttribute :: Any -> String -> [Object] -> [Object] -> Any
export const conformAttribute = attributeValue => attributeName => restrictions => attributeOptions => (
  arrayNotEmptyOr(restrictedOptionsForAttribute(restrictions)(attributeName))(
    restrictedOptions => (restrictedOptions.includes(attributeValue)
      ? get(getAllowedOptions(attributeOptions)(restrictions), '0.value', '')
      : attributeValue)
  )(
    () => attributeValue
  )
);

// updateAttributesWithRestrictions :: Object -> [Object] -> String -> Any -> Object -> Object
export const updateAttributesWithRestrictions = attributes => restrictions => attrToUpdate => newAttrValue => templateAttributes => (
  Object.keys(attributes).reduce((updatedAttributes, name) => ({
    ...updatedAttributes,
    [name]: (
      name !== attrToUpdate ? (
        conformAttribute(attributes[name])(name)(restrictions)(getAttributeOptions(templateAttributes[name]))
      ) : (
        newAttrValue
      )
    ),
  }), {})
);

const removeInvalidChars = (invalidChars: string) => (str: string): string => str
  .split('')
  .reduce((acc, char) => (invalidChars.includes(char) ? acc : acc.concat(char)), '');

export const trimCharLength = (maxLen: number) => (str: string): string => (
  str.length > maxLen ? str.slice(0, maxLen) : str
);

export const applyTextRestrictionsToString = (text: string) => (restrictions: TextRestrictions): string =>
  fromNullable(text)
    .chain(_text =>
      prop('invalidChars')(restrictions)
        .map(invalidChars => removeInvalidChars(invalidChars)(_text))
        .chain(updatedText => prop('charLength')(restrictions)
          .map(x => trimCharLength(x)(updatedText))
        )
    );

// INVALID_FOIL_STAMP_REGEX will also catch emoji unicode chars
// eslint-disable-next-line
export const INVALID_FOIL_STAMP_REGEX = (/[()!$#%*\/<>=?@[\]\\^_`{}]+|[^\x00-\x7F]+/gu);
export const SUPPORTED_CHARS = [
  '!', '"', '#', '$', '%', '&', "'", '(', ')', '*', '+', ',', '-', '.', '/', ' ',
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ';', '<', '=', '>', '?',
  '@', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
  'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '[', '\\', ']', '^', '_',
  '`', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o',
  'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '{', '|', '}', '~', '’',
  '¡', '¢', '£', '¥', '¦', '§', '©', '«', '¬', '®', '–', '—', '\n', '…', '”', '“',
  '±', '¶', '·', '¸', '»', '¿', '‘', '’',
  'À', 'Á', 'Â', 'Ã', 'Ä', 'Å', 'Æ', 'Ç', 'È', 'É', 'Ê', 'Ë', 'Ì', 'Í', 'Î', 'Ï',
  'Ð', 'Ñ', 'Ò', 'Ó', 'Ô', 'Õ', 'Ö', '×', 'Ø', 'Ù', 'Ú', 'Û', 'Ü', 'Ý', 'Þ', 'ß',
  'à', 'á', 'â', 'ã', 'ä', 'å', 'æ', 'ç', 'è', 'é', 'ê', 'ë', 'ì', 'í', 'î', 'ï',
  'ð', 'ñ', 'ò', 'ó', 'ô', 'õ', 'ö', '÷', 'ø', 'ù', 'ú', 'û', 'ü', 'ý', 'þ', 'ÿ',
];

export const interpretRestrictions = (restrictions: Option<TextRestrictions>, layer: Layer, sku: string) => (
  text: string
) => (
  editorState: EditorState
) => {
  const validations = [
    text.length > 1 ? any(text.split('').map((y: string) => !SUPPORTED_CHARS.includes(y))) : !SUPPORTED_CHARS.includes(text),
    restrictions
      .chain(prop('charLength'))
      .map((x) => editorState.getCurrentContent().getPlainText(' ').length + text.length > parseInt(x, 10))
      .getOrElseValue(false),
  ];

  // Foil stamp character validation
  if (layer.type === 'foil_stamp' && sku !== 'color-series-photo-book') {
    validations.push(INVALID_FOIL_STAMP_REGEX.test(text));
  }

  return any(validations) ? 'handled' : 'not-handled';
};
