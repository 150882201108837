import type { User } from '../types/photo';
import { GalleryImage } from 'au-js-sdk/lib/models/GalleryImage';
import { sleep } from '../helpers/sleep';
import { debugLog } from '../helpers/DOM';
import getImage from 'au-js-sdk/lib/api/requests/images/getImage';

export const waitForAutoOrientedImage = async (user: User, image: GalleryImage) => {
  const MAX_NUM_RETRY = 10;
  const TIMEOUT_MULTIPLIER = 1.2;

  let initTimeout = 1000;
  let retries = 0;

  // Initial timeout to allow cutThumbnail lambda to do its thing
  await sleep(1000);

  while (retries < MAX_NUM_RETRY) {
    debugLog('Checking if image has been auto oriented...');

    // eslint-disable-next-line no-await-in-loop
    const galleryImage = await getImage(user.flashToken, user.flashId, image.galleryId, image.id);
    if (galleryImage.autoOriented) {
      return galleryImage;
    }

    retries += 1;
    initTimeout *= TIMEOUT_MULTIPLIER;
    debugLog(`image has not been auto oriented, retrying after ${initTimeout}ms...`);

    // eslint-disable-next-line no-await-in-loop
    await sleep(initTimeout);
  }

  debugLog('Maximum retries reached.');

  window.newrelic.addPageAction('s3_auto_orient_max_retry', {
    ...image,
  });
  throw new Error('Failed to auto orient image');
};
