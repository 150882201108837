// @flow
/* eslint-disable no-use-before-define */
import { Dispatch, GetState } from 'redux-thunk';
import createGalleryImages, { CreateGalleryImagePayload, RootEvent } from 'au-js-sdk/lib/api/requests/images/createGalleryImages';
import { GalleryImage } from 'au-js-sdk/lib/models/GalleryImage';


import * as actionTypes from './actionTypes';
import * as galleryApi from '../../../services/galleryApi';
import * as galleryImagesApi from '../../../services/galleryImageApi';

import photoHelper, { LocalPhoto, type File, type Photo } from '../../../types/photo';
import { addPhoto } from '../../userPhotos/actions';
import { sendUploadErrorsNotification } from '../../notifications/actions';
import { hideUploadPhotosModal, setCurrentAlbum, setCurrentGalleryPageNumber, resetCurrentGalleryValues } from '../../ui/actions';
import { applyPhotoToLayer } from '../../project/pages/actions';
import { failedPhotosSelector } from '../../userPhotos/selectors';
import { galleryImagesSelector } from './selectors';
import { currentSortBySelector } from '../../ui/selectors';
import { debugLog } from '../../../helpers/DOM';
import v4 from 'uuid/v4';
import { IMAGE_URL_BASE } from '../../../helpers/images';

export const galleryActionSucceeded = (actionType: string, payload: any): actionTypes.Action => ({
  type: actionTypes.GALLERY_ACTION_SUCCEEDED,
  payload: {
    actionType,
    ...payload,
  },
});

export const galleryActionFailed = (actionType: string, error: any): actionTypes.Action => ({
  type: actionTypes.GALLERY_ACTION_FAILED,
  payload: {
    actionType,
    error,
  },
});

export const fetchGalleries = () => {
  const actionType = actionTypes.FETCH_GALLERIES;
  return async (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: actionType });
    const user = getState().user;
    try {
      const galleries = await galleryApi.getGalleries(user);

      dispatch(galleryActionSucceeded(actionType, { galleries }));
    } catch (error) {
      window.newrelic.addPageAction('s3_direct_upload_error', {
        error: error.toString(),
        errorStack: error.stack,
        action: actionType,
        userId: user.flashId,
      });
      window.newrelic.noticeError(error);
      dispatch(galleryActionFailed(actionType, error));
    }
  };
};

export const createGallery = (galleryName: string, projectId?: string) => {
  const actionType = actionTypes.CREATE_GALLERY;
  return async (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: actionType });
    const user = getState().user;
    try {
      const gallery = await galleryApi.createNewGallery(user, galleryName, projectId);
      dispatch(galleryActionSucceeded(actionType, { gallery }));
    } catch (error) {
      window.newrelic.addPageAction('s3_direct_upload_error', {
        error: error.toString(),
        errorStack: error.stack,
        action: actionType,
        userId: user.flashId,
        projectId,
      });
      window.newrelic.noticeError(error);
      dispatch(galleryActionFailed(actionType, error));
    }
  };
};

export const updateGallery = (galleryId: string, name?: string, thumbnailUrl?: string) => {
  const actionType = actionTypes.UPDATE_GALLERY;
  return async (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: actionType });
    const user = getState().user;
    try {
      const gallery = await galleryApi.updateGallery(user, galleryId, { name, thumbnailUrl });
      dispatch(galleryActionSucceeded(actionType, { gallery }));
    } catch (error) {
      window.newrelic.addPageAction('s3_direct_upload_error', {
        error: error.toString(),
        errorStack: error.stack,
        action: actionType,
        userId: user.flashId,
        galleryId,
      });
      window.newrelic.noticeError(error);
      dispatch(galleryActionFailed(actionType, error));
    }
  };
};

export const paginateLocalPhotos = () => {
  const actionType = actionTypes.GET_MORE_PHOTOS;
  return async (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: actionType });
    const state = getState();
    const currentGalleryImages = galleryImagesSelector(state, state.ui.currentAlbum);
    const user = state.user;
    const galleryId = state.ui.currentAlbum;
    const nextPage = state.ui.galleryPageNumber + 1;
    const sortBy = state.ui.gallerySortBy;
    try {
      const localImages = state.galleries.galleries.find((gallery) => gallery.id === state.ui.currentAlbum);
      if (localImages.images.length % 64 === 0) {
        const newPageGalleryImages = await galleryImagesApi.getGalleryImages(user, galleryId, nextPage, sortBy);
        dispatch(setCurrentGalleryPageNumber(nextPage));
        const galleryHasMorePhotos = (newPageGalleryImages && newPageGalleryImages.length < 64) || false;
        dispatch(galleryActionSucceeded(actionType, { newImages: currentGalleryImages.concat(newPageGalleryImages), galleryId }));
      }
    } catch (error) {
      window.newrelic.addPageAction('s3_paginate_local_gallery_error', {
        error: error.toString(),
        errorStack: error.stack,
        action: actionType,
        userId: user.flashId,
        galleryId,
        page: nextPage,
      });
      window.newrelic.noticeError(error);
      dispatch(galleryActionFailed(actionType, error));
    }
  };
};

export const fetchGalleryImages = (galleryId: string, newGallerySortBy?: string) => {
  const actionType = actionTypes.FETCH_GALLERY_IMAGES;
  return async (dispatch: Dispatch, getState: GetState) => {
    dispatch(resetCurrentGalleryValues());
    dispatch({ type: actionType });
    const user = getState().user;
    const sortBy = currentSortBySelector(getState());
    try {
      const galleryImages = await galleryImagesApi.getGalleryImages(user, galleryId, 1, newGallerySortBy || sortBy);
      // 64 is the batch number of images setted un crud-core per request
      const galleryHasMorePhotos = (galleryImages && galleryImages.length < 64) || false;
      dispatch(galleryActionSucceeded(actionType, { galleryImages }));
    } catch (error) {
      window.newrelic.addPageAction('s3_direct_upload_error', {
        error: error.toString(),
        errorStack: error.stack,
        action: actionType,
        userId: user.flashId,
        galleryId,
      });
      window.newrelic.noticeError(error);
      dispatch(galleryActionFailed(actionType, error));
    }
  };
};

export const uploadImagesToGallery = (galleryId: string, images: Photo[], targetPageId?: string, targetLayerId?: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const { user, galleries, project } = state;

    // If local images are being uploaded, set the current gallery to the one that is being uploaded to
    // and hide the upload modal
    if (images.length && photoHelper.isLocal(images[0])) {
      dispatch(setCurrentAlbum(galleryId));
      dispatch(hideUploadPhotosModal());
    }

    type ExtraData = { image: Photo, actionType: string, targetPageId?: string, targetLayerId?: string };
    const payloads: CreateGalleryImagePayload<ExtraData>[] = images.map((image) => {
      const extractedImage = photoHelper.extract(image);
      const imgId = extractedImage.originId || extractedImage.id;
      const actionType = buildTargetedActionTypeFromParts(actionTypes.UPLOAD_IMAGES_TO_GALLERY, imgId, targetPageId, targetLayerId);

      // If pending actions doesn't already include the action time (which is the case for local uploads)
      // Add the pending action to the state
      if (!galleries.pendingActions.includes(actionType)) {
        dispatch({ type: actionType });
      }

      return {
        requestId: v4(),
        galleryId,
        file: extractedImage.file,
        imgSource: extractedImage.service,
        thirdPartyUrl: extractedImage.externalUrl,
        extra: {
          image,
          targetPageId,
          targetLayerId,
          actionType,
        },
      };
    });

    const uploader = createGalleryImages({
      flashToken: user.flashToken,
      flashId: user.flashId,
      payloads,
      retryFailures: true,
    });

    uploader.eventStream$.subscribe(async (e: RootEvent<ExtraData>) => {
      if (e.eventType === 'SUCCEEDED') {
        // Update gallery thumbnail to the last uploaded img.
        const thumbnailUrl = `${IMAGE_URL_BASE}/${e.image.createdBy}/${e.image.id}_original?width=300&auto=webp`;
        dispatch(updateGallery(galleryId, undefined, thumbnailUrl));

        dispatch(galleryActionSucceeded(e.extra.actionType, { galleryImage: e.image }));

        // IF pageId and targetLayerId are defined, we know the user is trying to add a 3rd party image
        // directly to their project.  So, After we successfully upload the 3rd party image, dispatch the addPhoto and
        // addPhoto to layer actions to make sure we are adding the correct type of image to the project.
        if (e.extra.targetPageId && e.extra.targetLayerId) {
          const p = (await photoHelper.attemptConstructFromAlbumPhoto(e.image)).toNullable();
          dispatch(addPhoto(p));
          dispatch(applyPhotoToLayer(p, e.extra.targetPageId, e.extra.targetLayerId));
        }
      } else if (e.eventType === 'FAILED') {
        // Get a copy of the Photo p, with a failed flag set to to true.
        const failedPhoto = photoHelper.setToFailed(e.imageError.error.message, galleryId)(e.extra.image);
        // Update the existing photo in state to be marked as failed.
        dispatch(addPhoto(failedPhoto));
        dispatch(sendUploadErrorsNotification());

        dispatch(galleryActionFailed(e.extra.actionType, e.imageError.error));
        window.newrelic.noticeError(e.imageError.error);
      }
    });

    uploader.start();
  };
};

export const createGalleryAndUploadImages = (galleryName: string, images: Photo[], pageId?: string, targetLayerId?: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const user = getState().user;
  try {
    dispatch({ type: actionTypes.CREATE_GALLERY });
    const gallery = await galleryApi.createNewGallery(user, galleryName);
    dispatch(galleryActionSucceeded(actionTypes.CREATE_GALLERY, { gallery }));
    dispatch(uploadImagesToGallery(gallery.id, images, pageId, targetLayerId));
  } catch (error) {
    window.newrelic.addPageAction('s3_direct_upload_error', {
      error: error.toString(),
      errorStack: error.stack,
      action: actionTypes.CREATE_GALLERY,
      userId: user.flashId,
    });
    window.newrelic.noticeError(error);
    dispatch(galleryActionFailed(actionTypes.CREATE_GALLERY, error));
  }
};

export const retryFailedGalleryPhotosUpload = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const userLoggedIn = state.user.authorized;
  if (userLoggedIn) {
    const failedPhotos = failedPhotosSelector(state);
    // Since the failed photos maybe be destined for different galleries, dispatch uploadImagesToGallery
    // for photos in each distinct gallery
    getDistinctGalleriesForFailedPhotos(failedPhotos).forEach((galleryId) => {
      const photosInGallery = failedPhotos
        .filter((failedPhoto) => photoHelper.getFailedAlbumId(failedPhoto).getOrElse('') === galleryId)
        .map((failedPhoto) => photoHelper.unsetFailed(failedPhoto));
      dispatch(uploadImagesToGallery(galleryId, photosInGallery));
    });
  }
};

export const getDistinctGalleriesForFailedPhotos = (failedPhotos) => [
  ...new Set(failedPhotos.map((failedPhoto) => photoHelper.getFailedAlbumId(failedPhoto).getOrElse(''))),
];

export const tryAutoOrientGalleryImage = (galleryImage: GalleryImage, pageId: string, targetLayerId: string) => {
  if (!galleryImage) {
    throw new Error('Cannot auto orient null');
  }

  const actionType = buildTargetedActionTypeFromParts(actionTypes.AUTO_ORIENT_IMAGE_IN_GALLERY, galleryImage.id, pageId, targetLayerId);
  return async (dispatch: Dispatch, getState: GetState) => {
    const user = getState().user;

    let autoOrientedGalleryImage = galleryImage;
    try {
      autoOrientedGalleryImage = await galleryImagesApi.autoOrientImage(user, galleryImage);
      dispatch(galleryActionSucceeded(actionType, { galleryImage: autoOrientedGalleryImage }));
    } catch (error) {
      debugLog(error);
      window.newrelic.addPageAction('s3_direct_upload_error', {
        error: error.toString(),
        errorStack: error.stack,
        action: actionType,
        userId: user.flashId,
        galleryId: galleryImage.galleryId,
        galleryImageId: galleryImage.id,
      });
      window.newrelic.noticeError(error);
      dispatch(galleryActionFailed(actionType, error));
    }

    const p = (await photoHelper.attemptConstructFromAlbumPhoto(autoOrientedGalleryImage)).toNullable();
    dispatch(addPhoto(p));
    dispatch(applyPhotoToLayer(p, pageId, targetLayerId));
  };
};

export const buildTargetedActionTypeFromParts = (actionType: string, id: string, pageId?: string, layerId?: string) => {
  let actionComponents = [];
  actionComponents = actionComponents.concat(actionType);
  actionComponents = actionComponents.concat(id);
  actionComponents = pageId ? actionComponents.concat(pageId) : actionComponents;
  actionComponents = layerId ? actionComponents.concat(layerId) : actionComponents;

  return actionComponents.join('|');
};
