import { PROJECT_DATA_SET_PROJECT_ID } from './constants';
import { SET_NEW_PROJECT_DATA } from '../constants';

export default function projectIdReducer(state = '', action) {
  switch (action.type) {
    case PROJECT_DATA_SET_PROJECT_ID:
      const { projectId } = action.payload;
      return projectId;
    case SET_NEW_PROJECT_DATA: {
      const { projectId } = action.payload.project;
      return projectId;
    }

    default:
      // Do nothing if we didn't match action type
      return state;
  }
}
