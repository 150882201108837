
// @flow
import type { Dispatch, GetState } from 'redux-thunk';

import { option } from 'fp-ts';
import { MANUALLY_ADD_NEW_ALBUM_TO_ARRAY } from '../userAlbums/constants';
import { currentAlbumSelector, albumExistsSelector } from '../userAlbums/selectors';
import {
  ALL_UPLOADS_COMPLETE_STATUS,
  UPLOAD_IN_FLIGHT_STATUS,
  UPLOAD_STATUS,
  USER_SELECTED_UPLOAD_SOURCE,
  ANALYTICS_SELECTED_SOURCE,
  USER_PHOTOS_ADD_PHOTO,
  USER_PHOTOS_REMOVE_PHOTO_BY_ID,
  LARGE_FILE_ERROR_MESSAGE,
  USER_PHOTOS_REMOVE_ALL,
  SPLIT_IO_S3_DIRECT_UPLOAD,
  SPLIT_IO_S3_DIRECT_UPLOAD_ON,
  SPLIT_IO_S3_DIRECT_UPLOAD_OFF,
} from './constants';
import { ADD_TO_CART_FAILURE, ADD_TO_CART_PROCESSING, UPLOADS_IN_FLIGHT_CART_STATE } from '../product/constants';
import { replacePhotoOnLayers } from '../../store/project/pages/actions';
import { albumAddPhotos, getAlbums } from '../../store/userAlbums/actions';
import { isFileSmallEnough, getFileDimensions } from '../../helpers/images';
import { saveProject } from '../../services/projectApi';
import { sendUploadErrorsNotification, dismissAllNotificationsForPhoto, dismissUploadErrorsNotification } from '../notifications/actions';
import { projectInCartSelector } from '../../store/project/pages/selectors';
import { updateProjectInCart, validateAndAddToCart } from '../product/actions';
import type { Photo } from '../../types/photo';
import photo, { DIRECT_UPLOAD_TYPE, FlashPhoto, PHOTO_TYPE_LOCAL, PHOTO_TYPE_THIRD_PARTY } from '../../types/photo';
import { failedPhotosSelector, getUploadablePhotos, getFailedPhotos, getNonFailedUploadablePhotos } from './selectors';
import { optionGet, optionFromEmpty } from '../../helpers/functions';
import { fetchGalleries } from '../v2/galleries/actions';

const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const addPhoto = (p: Photo) => ({
  type: USER_PHOTOS_ADD_PHOTO,
  payload: {
    p: photo.extract(p),
  },
});

export const removePhoto = (p: Photo) => (dispatch: Dispatch) => {
  const userPhotoId = photo.getId(p);

  dispatch({
    type: USER_PHOTOS_REMOVE_PHOTO_BY_ID,
    payload: {
      userPhotoId,
    },
  });

  dispatch(dismissAllNotificationsForPhoto(userPhotoId));
};

export const removeAllPhotosfromProject = () => (dispatch: Dispatch) => {
  dispatch({
    type: USER_PHOTOS_REMOVE_ALL,
  });
};
export const setUploadStatus = (status: string) => ({
  type: UPLOAD_STATUS,
  payload: {
    status,
  },
});

export const analyticsSelectedSource = (source: string) => ({
  type: ANALYTICS_SELECTED_SOURCE,
  payload: {
    source,
  },
});

export const replacePhoto = (oldPhoto: Photo, newPhoto: Photo) => (dispatch: Dispatch) => {
  dispatch(addPhoto(newPhoto));
  dispatch(replacePhotoOnLayers(oldPhoto, newPhoto));
  dispatch(removePhoto(oldPhoto));
};

export const uploadDone = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();

  const nonFailedUploadablePhotos = getNonFailedUploadablePhotos(state);
  const failedPhotos = getFailedPhotos(state);

  if (failedPhotos.length === 0) {
    // If there are no failed photos of any kind, dismiss the upload errors notification, if it's present.
    dispatch(dismissUploadErrorsNotification());
  }

  /* If there are no uploads in flight (i.e. uploadable Photos in state that have not failed),
     call validateAndAddToCart or updateProjectInCart (again). This will also check for and confirm
     any photos that failed during the pre-cart upload process. */
  if (nonFailedUploadablePhotos.length === 0) {
    if (optionGet('ui.currentAlbum')(state).chain(optionFromEmpty).isNone()) {
      dispatch(fetchGalleries());
    }

    dispatch(setUploadStatus(ALL_UPLOADS_COMPLETE_STATUS));

    if (state.product.cartState === UPLOADS_IN_FLIGHT_CART_STATE) {
      if (!projectInCartSelector(getState())) {
        saveProject(getState(), true).then(() => {
          dispatch(validateAndAddToCart());
        });
      } else {
        dispatch(updateProjectInCart());
      }
    }
  }
};

const imageSource = (p) => {
  const photoType = photo.extract(p).type;
  let photoSource;
  if (photoType === PHOTO_TYPE_THIRD_PARTY) {
    photoSource = photo.extract(p).service;
  } else if (photoType === PHOTO_TYPE_LOCAL) {
    photoSource = DIRECT_UPLOAD_TYPE;
  }
  return photoSource;
};

const uploadPhotoToFlash = async (user, albumId, p, response, dispatch: Dispatch) => {
  const parsedFlashResponse = await response.json().catch(() => {
    throw new Error('Server error.');
  });

  // Destructure the parsedFlashResponse object.
  const { mediaId, imageMetadata } = parsedFlashResponse;

  if (!imageMetadata) {
    window.newrelic.addPageAction('flash_response_missing_metadata', {
      mediaId,
      parsedFlashResponse,
      user,
    });
    throw new Error('Image upload failed');
  }

  // Construct a new FlashPhoto object from the data returned by the image service.
  const newFlashPhoto = FlashPhoto({
    mediaId,
    dimensions: {
      height: parseInt(imageMetadata.height, 10),
      width: parseInt(imageMetadata.width, 10),
    },
    metadata: imageMetadata,
    originId: photo.getOriginId(p).toNullable(),
    service: photo.getService(p).toNullable(),
  });

  dispatch(replacePhoto(p, newFlashPhoto));

  if (albumId) {
    dispatch(albumAddPhotos(parsedFlashResponse)(albumId));
  }

  dispatch(addPhoto(newFlashPhoto));
};

export const uploadPhoto = (p: Photo, albumId: string = '') => async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const { user, ui, project } = state;

  if (user.authorized && photo.isUploadable(p)) {
    try {
      const isUnderLimit = photo.getFile(p).map(isFileSmallEnough(26214400)).getOrElseValue(true);

      if (!isUnderLimit) {
        throw new Error(LARGE_FILE_ERROR_MESSAGE);
      }

      // This will extract the previously generated (see storePhotos method) mediaId
      // from the LocalPhoto being uploaded
      const photoMediaId = photo.getMediaId(p).getOrElseValue('');

      const uploader = photo.getUploader(user)(project.id)(p)(albumId, photoMediaId);

      const response = await uploader.catch((error) => {
        window.newrelic.noticeError(error);
        // @todo collect upload failures here instead of get albums
        throw new Error('Network error.');
      });

      await uploadPhotoToFlash(user, albumId, p, response, dispatch);
    } catch (error) {
      window.newrelic.noticeError(error);

      const cartLoadingStates = [UPLOAD_IN_FLIGHT_STATUS, ADD_TO_CART_PROCESSING, UPLOADS_IN_FLIGHT_CART_STATE];

      if (cartLoadingStates.includes(getState().product.cartState)) {
        dispatch({ type: ADD_TO_CART_FAILURE });
      }

      // Get a copy of the Photo p, with a failed flag set to to true.
      const failedPhoto = photo.setToFailed(error.message, albumId)(p);

      // Update the existing photo in state to be marked as failed.
      dispatch(addPhoto(failedPhoto));

      dispatch(sendUploadErrorsNotification());
    }
  }
};

export const retryFailedPhotosUpload = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const userLoggedIn = state.user.authorized;

  if (userLoggedIn) {
    const failedPhotos = failedPhotosSelector(state);
    failedPhotos.forEach((failedPhoto) => {
      const albumId = photo.getFailedAlbumId(failedPhoto).getOrElseValue('');
      const p = photo.unsetFailed(failedPhoto);
      dispatch(addPhoto(p));
      dispatch(uploadPhoto(p, albumId));
    });
  }
};

// Uploads local and thirdParty photos. Used when a user transitions from being logged-out to logged-in.
export const uploadLocalPhotos = () => async (dispatch: Dispatch, getState: GetState) => {
  const uploadablePhotos = getUploadablePhotos(getState());

  uploadablePhotos.forEach((p) => dispatch(uploadPhoto(p)));
};

export function selectedSource(source: string) {
  return {
    type: USER_SELECTED_UPLOAD_SOURCE,
    source,
  };
}
