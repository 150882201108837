// @flow
export const UNDOABLE_USER_ACTION_START = 'history/UNDOABLE_USER_ACTION_START';
export const UNDOABLE_USER_ACTION_END = 'history/UNDOABLE_USER_ACTION_END';
export const UNDOABLE_USER_ACTION_UPDATE = 'history/UNDOABLE_USER_ACTION_UPDATE';
export const UNDOABLE_USER_ACTION_ADD_PATCHES_TO_APPLY = 'history/UNDOABLE_ADD_PATCHES_TO_APPLY';
export const UNDOABLE_USER_ACTION_CANCEL = 'history/UNDOABLE_USER_ACTION_CANCEL';
export const UNDOABLE_REMOVE_HISTORY_ITEM = 'history/REMOVE_UNDOABLE_ITEM';
export const UNDOABLE_CHANGE_QUANTITY = 'history/UNDOABLE_CHANGE_QUANTITY';
export const UNDO_USER_ACTION = 'history/UNDO_USER_ACTION';
export const REDO_USER_ACTION = 'history/REDO_USER_ACTION';
export const REMOVE_UNDOABLE_ITEM = 'history/REMOVE_UNDOABLE_ITEM';
export const UNDOABLE_AUTOFILL = 'user/autofill';
export const UNDOABLE_EDIT_TEXT = 'user/edittext';
export const UNDOABLE_APPLY_LAYOUT = 'user/changedlayout';
export const UNDOABLE_ADD_IMAGE_TO_LAYER = 'user/addImageToLayer';
export const UNDOABLE_REMOVE_IMAGE_FROM_LAYER = 'user/removeImage';
export const UNDOABLE_EDIT_IMAGE_ON_LAYER = 'user/editImageOnLayer';
export const UNDOABLE_REORDER_PAGES = 'user/reorderPages';
export const UNDOABLE_REMOVE_PAGE = 'user/removePage';
export const UNDOABLE_INSERT_PAGE = 'user/insertPage';
export const UNDOABLE_CHANGE_ATTRIBUTE = 'user/changeAttribute';
export const UNDOABLE_CHANGE_COLORBLOCK = 'user/changeColorBlock';
export const UNDOABLE_MOVE_TEXT = 'user/movedText';
export const ADD_HISTORY_ITEM = 'history/ADD_HISTORY_ITEM';

export const UNDOABLE_USER_ACTION_NAMES = {
  UNDOABLE_AUTOFILL,
  UNDOABLE_APPLY_LAYOUT,
  UNDOABLE_ADD_IMAGE_TO_LAYER,
  UNDOABLE_EDIT_TEXT,
};

export type UndoableActionStartAction = 'history/UNDOABLE_USER_ACTION_START';
export type UndoableActionEndAction = 'history/UNDOABLE_USER_ACTION_END';
export type UndoableActionCancelAction = 'history/UNDOABLE_USER_ACTION_CANCEL';
export type UndoableActions = $Keys<typeof UNDOABLE_USER_ACTION_NAMES>;
