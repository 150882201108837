// @flow

import {
  __BREAKPOINT_TINY__,
  __BREAKPOINT_SMALL__,
  __BREAKPOINT_MEDIUM__,
  __BREAKPOINT_LARGE__,
} from '../../constants/breakpoints';

// Returns true when the window.innerWidth is less than or equal to the given maxWidth.
export const isInMaxWidth = (w: number): boolean => window.innerWidth <= w;

/* If the window width is less than or equal to the maximum width, in pixels,
   call and return the result of the first passed function.
   Otherwise return the result of the second passed function. */
export const maxWidth = (w: number) => (i: () => any) => (o: () => any): any => (
  isInMaxWidth(w) ? i() : o()
);

export const tinyBreakpoint = maxWidth(__BREAKPOINT_TINY__);
export const smallBreakpoint = maxWidth(__BREAKPOINT_SMALL__);
export const mediumBreakpoint = maxWidth(__BREAKPOINT_MEDIUM__);
export const largeBreakpoint = maxWidth(__BREAKPOINT_LARGE__);

export const isWithinTiny = () => isInMaxWidth(__BREAKPOINT_TINY__);
export const isWithinSmall = () => isInMaxWidth(__BREAKPOINT_SMALL__);
export const isWithinMedium = () => isInMaxWidth(__BREAKPOINT_MEDIUM__);
export const isWithinLarge = () => isInMaxWidth(__BREAKPOINT_LARGE__);
