import { createSelector } from 'reselect';
import { THIRD_PARTY_TYPES } from '../../types/photo';
import { UI_TEMPORARY_ALBUM } from '../addPhotos/constants';
import { prop, optionGet } from '../../helpers/functions';
import { option } from 'fp-ts';

export const currentAlbumIdSelector = state => state.ui.currentAlbum;

export const currentAlbumSelector = state => state.userAlbums.albums.find(album => album.albumId === state.ui.currentAlbum);

export const albumExistsSelector = (state, albumId) => (
  option.fromNullable(state.userAlbums.albums.find(album => album.albumId === albumId))
);

export const containingAlbumIdSelector = state => mediaId => (
  // ick
  optionGet('userAlbums.albumPhotos')(state)
    .map(Object.entries)
    .mapNullable(objArray => objArray.find(o => o[1].find(p => p.mediaId === mediaId) != null))
    .map(result => result[0])
);

// Predicate that returns a boolean indicating whether or not the given album is sortable.
export const albumIsSortable = (
  albumId: string,
  album: Object,
  photosRetrieved?: boolean = false,
) => (
  albumId &&
  !THIRD_PARTY_TYPES.includes(albumId) &&
  albumId !== UI_TEMPORARY_ALBUM &&
  album &&
  (photosRetrieved || (album.photosRetrieved === true))
);

export const currentAlbumPhotosSelector = ({ ui, userAlbums, facebook, instagramPhotos, dropbox, googlePhotos }) => {
  if (facebook && 'facebookActive' in facebook && facebook.facebookActive) {
    const formattedFbPhotos = facebook.images.map(image => ({
      source: 'facebook',
      isLoaded: true,
      id: image.id,
      thumbURL: image.source,
      cdnUrl: image.source,
    }));

    return formattedFbPhotos;
  } else if (googlePhotos !== undefined && googlePhotos.googlePhotosActive) {
    return googlePhotos.images;
  } else if (instagramPhotos !== undefined && instagramPhotos.instagramActive) {
    return instagramPhotos.images;
  } else if (dropbox !== undefined && dropbox !== null && dropbox.dropboxActive) {
    return dropbox.dropboxImgArray;
  }

  return userAlbums ? userAlbums.albumPhotos[ui.currentAlbum] || [] : [];
};

export const albumPhotoCountSelector = ({ instagramPhotos, googlePhotos, facebook, ui, galleries }) => {
  if (instagramPhotos && instagramPhotos.instagramActive) {
    return instagramPhotos.nextUrl ? instagramPhotos.images.length + 1 : 0;
  }

  if (true && googlePhotos.googlePhotosActive) {
  // if (googlePhotos && googlePhotos.googlePhotosActive) {
    return googlePhotos.nextUrl ? googlePhotos.images.length + 1 : 0;
  }

  if (facebook && facebook.facebookActive) {
    return facebook.nextUrl ? facebook.images.length + 1 : 0;
  }

  if(ui.currentAlbum !== 'UI_DROPBOX_ALBUM' && galleries){
    const currentGalleryPhotos = galleries.galleries.find(gallery => gallery.id === ui.currentAlbum);
    if(currentGalleryPhotos && currentGalleryPhotos.images && currentGalleryPhotos.images.length > 0){
      return currentGalleryPhotos.images.length +1;
    }
  }
  return 0;
};

// Since we only have metadata for images directly uploaded to flash, we should only try to sort on album that is not third party.
export const albumIsSortableSelector = createSelector(
  currentAlbumIdSelector,
  currentAlbumSelector,
  albumIsSortable,
);


export const currentAlbumSortOperationSelector = state => prop('sortOperation')(currentAlbumSelector(state)).toNullable();
