// @flow

import { optionFind } from '../../helpers/functions';
import { createSelector } from 'reselect';

export const notifications = (state: Object): Array<Object> => state.notifications;
export const activeNotificationCount = createSelector(
  notifications,
  notifications => notifications ? notifications.filter(x => !x.dismissed).length : 0
);

export const getNotificationByKey = (state: Object) => (key: string) => (
  optionFind(n => n.key === key, notifications(state))
);
