// @flow

import { parseBleedValue, parseSafeOffset } from '../templates';
import { clamp } from '../numbers';
import { type Surface } from '../../types/templates';

// Resize Handle Positions
const TOP_CENTER = 'TOP_CENTER';
const TOP_RIGHT = 'TOP_RIGHT';
const MIDDLE_RIGHT = 'MIDDLE_RIGHT';
const BOTTOM_RIGHT = 'BOTTOM_RIGHT';
const BOTTOM_CENTER = 'BOTTOM_CENTER';
const BOTTOM_LEFT = 'BOTTOM_LEFT';
const MIDDLE_LEFT = 'MIDDLE_LEFT';
const TOP_LEFT = 'TOP_LEFT';

// Array of handle positions, for use in render methods.
export const HANDLE_LOCATIONS = [
  TOP_CENTER,
  TOP_RIGHT,
  MIDDLE_RIGHT,
  BOTTOM_RIGHT,
  BOTTOM_CENTER,
  BOTTOM_LEFT,
  MIDDLE_LEFT,
  TOP_LEFT,
];

// Minimum size of 0.25 inches, so users can't resize a layer to be any smaller than 0.25x0.25 inches.
const SAFE_MIN_SIZE = 0.25;

// Clamp an X or Y value, so that it doesn't place a minimum-sized layer outside of the safe region.
const clampPos = (axis: 'x' | 'y') => (surface: Surface) => (offset: number) => (n: number) => {
  const bleed = parseBleedValue(surface);
  const safeOffset = parseSafeOffset(surface);

  const surfaceDim = axis === 'x' ? surface.width : surface.height;
  const safeStart = (axis === 'x' ? (bleed[3] + safeOffset[3]) : (bleed[0] + safeOffset[0]));
  const safeEnd = (axis === 'x' ? (bleed[1] + safeOffset[1]) : (bleed[2] + safeOffset[2]));

  const safeMin = safeStart / surfaceDim;
  const safeW = (surfaceDim - safeEnd) / surfaceDim;

  return clamp(safeMin, safeW - offset)(n);
};

/* Clamp a Width or Height value, so that it doesn't cause a layer, with a left or top
   value of `offset`, to extend outside of the safe region. */
const clampSize = (axis: 'w' | 'h') => (surface: Surface) => (offset: number) => (n: number) => {
  const bleed = parseBleedValue(surface);
  const safeOffset = parseSafeOffset(surface);

  const safeStart = (axis === 'w' ? (bleed[3] + safeOffset[3]) : (bleed[0] + safeOffset[0]));
  const safeEnd = (axis === 'w' ? (bleed[1] + safeOffset[1]) : (bleed[2] + safeOffset[2]));

  const surfaceDim = axis === 'w' ? surface.width : surface.height;

  const safeMin = SAFE_MIN_SIZE / surfaceDim;
  const safeW = (surfaceDim - safeEnd - safeStart - (Math.max(0, (offset * surfaceDim) - safeStart))) / surfaceDim;

  return clamp(safeMin, safeW)(n);
};

// Partially-applied versions of the clampSize and clampPos, for ease of use.
export const clampX = clampPos('x');
export const clampY = clampPos('y');
export const clampW = clampSize('w');
export const clampH = clampSize('h');

// Get the new Width and Left values for the layer from a resize event.
export const calcXforResize = (location: string) => (surface: Surface, surfaceWidthPx: number) => (
  (oldWidth: number, oldLeft: number) => (deltaX: number, x: number): [number, number] => {
    // If the handle is in the center of the top or bottom, the user can only resize vertically, so just return [oldWidth, oldLeft].
    if ([TOP_CENTER, BOTTOM_CENTER].includes(location)) {
      return [oldWidth, oldLeft];
    }

    // If the handle is on the left side of the box, we need to calculate the new left value first, then base the width off of that.
    if ([TOP_LEFT, MIDDLE_LEFT, BOTTOM_LEFT].includes(location)) {
      const xPc = x / surfaceWidthPx;
      const newLeft = clampX(surface)(SAFE_MIN_SIZE / surface.width)(oldLeft + xPc);
      const newWidth = newLeft !== oldLeft ? clampW(surface)(newLeft)(oldWidth + (oldLeft - newLeft)) : oldWidth;

      /* If the difference between the oldWidth and the newWidth is less than the difference
         between the oldLeft and the newLeft, use the smaller of the two deltas. */
      if (Math.abs(oldWidth - newWidth) < Math.abs(oldLeft - newLeft)) {
        return [newWidth, oldLeft + (oldWidth - newWidth)];
      }

      return [newWidth, newWidth !== oldWidth ? newLeft : oldLeft];
    }

    // Otherwise, the left position doesn't change.
    const deltaXpc = deltaX / surfaceWidthPx;
    const newWidth = clampW(surface)(oldLeft)(oldWidth + deltaXpc);

    return [newWidth, oldLeft];
  }
);

// Get the new Height and Top values for the layer from a resize event.
export const calcYforResize = (location: string) => (surface: Surface, surfaceHeightPx: number) => (
  (oldHeight: number, oldTop: number) => (deltaY: number, y: number): [number, number] => {
    // If the handle is in the middle of the left or right sides, the height doesn't change, so just return [oldHeight, oldTop].
    if ([MIDDLE_RIGHT, MIDDLE_LEFT].includes(location)) {
      return [oldHeight, oldTop];
    }

    // If the handle is on the top side of the box, we need to calculate the new top value first, then base the height off of that.
    if ([TOP_LEFT, TOP_CENTER, TOP_RIGHT].includes(location)) {
      const yPc = y / surfaceHeightPx;
      const newTop = clampY(surface)(SAFE_MIN_SIZE / surfaceHeightPx)(oldTop + yPc);
      const newHeight = newTop !== oldTop ? clampH(surface)(newTop)(oldHeight + (oldTop - newTop)) : oldHeight;

      /* If the difference between the oldHeight and the newHeight is less than the difference
         between the oldTop and the newTop, use the smaller of the two deltas. */
      if (Math.abs(oldHeight - newHeight) < Math.abs(oldTop - newTop)) {
        return [newHeight, oldTop + (oldHeight - newHeight)];
      }

      return [newHeight, newHeight !== oldHeight ? newTop : oldTop];
    }

    // Otherwise, the top position doesn't change.
    const deltaYpc = deltaY / surfaceHeightPx;
    const newHeight = clampH(surface)(oldTop)(oldHeight + deltaYpc);

    return [newHeight, oldTop];
  }
);

export const objectLiteralMatcher = (options: Object, selected: string) => options[selected];

//Took this colors from Product.css, where they are being hardcoded for updating after selection
const colorSeriesPhotoBookCovers = {
  'fog': '#c5c3c4',
  'arctic': '#a4b0b4',
  'navy': '#2f3239',
  'moss': '#6c6b24',
  'canary': '#ecb032',
  'petal': '#dfa49c'
};

const photoBooksFoilColors = {
  'gold': '#D5BA75',
  'silver': '#d8d8d8',
  'white': '#ffffff',
  'copper': '#CB8453',
  'black': '#000000'
};

//If the selected value includes a '#' we assume its already an hex
export const getHexColorSeriesPBCoverColor = (optionSelected :string) => optionSelected.includes('#') && optionSelected || objectLiteralMatcher(colorSeriesPhotoBookCovers, optionSelected);
export const getHexPBFoilColor = (optionSelected :string) => optionSelected.includes('#') && optionSelected || objectLiteralMatcher(photoBooksFoilColors, optionSelected);
