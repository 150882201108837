import React from 'react';
import PropTypes from 'prop-types';

import './GenericOverlay.css';

const WHITE_OUT = 'white-out';
const AUTH_WHITE_OUT = 'white-out white-out--auth';

const clickHandler = (e, onClickOutside) => {
  if (
    e.target.className &&
    typeof e.target.className === 'string' &&
    e.target.className.includes(WHITE_OUT) &&
    onClickOutside
  ) {
    onClickOutside();
  }
};

export const GenericOverlay = ({
  children,
  className,
  title,
  onClickOutside,
  wrapperClassName,
  isAuth,
  width,
}) => (
  <div
    className={isAuth ? AUTH_WHITE_OUT : WHITE_OUT}
    onMouseDown={(e) => { clickHandler(e, onClickOutside); }}
    role="presentation"
  >
    <div className={`generic-overlay__wrapper ${wrapperClassName.length ? wrapperClassName : ''}`}>
      <section
        className={`generic-overlay ${className.length ? className : ''}`}
        style={width ? { width } : {}}
      >
        {title ?
          <h3 className="generic-overlay__title title-3">{title}</h3>
          :
          null
        }

        {children}
      </section>
    </div>
  </div>
);

GenericOverlay.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  title: PropTypes.string,
  onClickOutside: PropTypes.func,
  isAuth: PropTypes.bool,
  width: PropTypes.any,
};

GenericOverlay.defaultProps = {
  children: null,
  className: '',
  wrapperClassName: '',
  title: '',
  onClickOutside: null,
  isAuth: false,
  width: null,
};

export default GenericOverlay;
